import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { CompanyUserInterface, companyUserStore, userStore } from "stores";
import _ from 'lodash';

const useCompanyUserByParams = () => {
    const [user, setUser] = useState<null | CompanyUserInterface>(null);
    const { id } = useParams<{ id: string }>();

    useEffect(() => {
        const getUser = async() => {
            const me = userStore.signedInUser;
            await companyUserStore.getUsers(me.company.id);
            const user = _.find(companyUserStore.users, { id: id });
            setUser(user || null);
        }

        getUser();
    }, [id])

    return user;
}

export default useCompanyUserByParams;