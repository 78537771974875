import request from 'api/request';
import { formattedTimeZoneForElasticSearch } from './DateHelper';

export interface ReportCriteriaQueryInterface {
    bool?: {
        must?: Array<any>,
        must_not?: Array<any>,
    },
    query_string?: any
}

class ReportCriteriaBuilder {

    query: ReportCriteriaQueryInterface = {};
    filters: Array<string> = [];

    private makeSureBoolMustExists() {
        if (!this.query.bool) {
            this.query.bool = {};
        }
        if (!this.query.bool.must) {
            this.query.bool.must = [];
        }
    }

    private makeSureBoolMustNotExists() {
        if (!this.query.bool) {
            this.query.bool = {};
        }
        if (!this.query.bool.must_not) {
            this.query.bool.must_not = [];
        }
    }

    addMatchPhrase(requestField: string, phrase: string, wildcards: boolean = false, mustNot: boolean = false) {
        this.makeSureBoolMustExists();

        const value = wildcards ? `*${phrase}*` : phrase;

        const entity = {
            "match_phrase_prefix": {
                [requestField]: value
            }
        }

        if (mustNot) {
            this.makeSureBoolMustNotExists();
            this.query.bool!.must_not!.push(entity);
        }
        else {
            this.makeSureBoolMustExists();
            this.query.bool!.must!.push(entity);
        }
    }

    addFieldMustExist(requestField: string) {
        this.makeSureBoolMustExists();

        let entity = {
            "exists": {
                "field": requestField
            }
        } as any;

        this.query.bool!.must!.push(entity);
    }

    addFieldMustNotExist(requestField: string) {
        this.makeSureBoolMustNotExists();

        const entity = {
            "exists": {
                "field": requestField
            }
        }

        this.query.bool!.must_not!.push(entity);
    }

    addMatch(requestField: string, keyword: string | number, wildcards: boolean = false) {
        this.makeSureBoolMustExists();

        const value = wildcards ? `${keyword}*` : keyword;

        const entity = {
            "match": {
                [requestField]: value
            }
        }

        this.query.bool!.must!.push(entity);

    }

    addMatchMustNotExist(requestField: string, keyword: string | number, wildcards: boolean = false) {
        this.makeSureBoolMustNotExists();

        const value = wildcards ? `${keyword}*` : keyword;

        const entity = {
            "match": {
                [requestField]: value
            }
        }

        this.query.bool!.must_not!.push(entity);

    }

    addRange(type: 'gt' | 'lt', requestField: string, value: string | number) {
        this.makeSureBoolMustExists();

        const entity = {
            "range": {
                [requestField]: {
                    [type]: value,
                    "time_zone": formattedTimeZoneForElasticSearch(value)
                }
            }
        }

        this.query.bool!.must!.push(entity);
    }

    addIdArrayQuery(ids: Array<string>) {
        this.query = { ids: { values: ids } as any } as any;
    }

    addQueryString(requestField: string, searchValue: string, wildcards: boolean = false) {
        this.query.query_string = {};

        const value = wildcards ? `${searchValue}*` : searchValue;
        this.query.query_string.query = `${requestField}:${value}`;
    }

    resetQuery() {
        this.query = {};
    }

    setFilters(filters: Array<string>) {
        this.filters = filters;
    }

    resetFilters() {
        this.filters = [];
    }

    executeQuery(contractEndpoint: string, pageSize: number = 9999, onlyActive: boolean = false, source: string = '*'): Promise<Array<any>> {
        return new Promise(async (resolve, reject) => {
            try {
                const queryString = JSON.stringify(this.query);
                this.resetQuery();

                const res: any = await request.post(`contracts/${contractEndpoint}/search`, {
                    searchBy: queryString,
                    pageSize: pageSize,
                    pageNumber: 1,
                    isJsonFormat: true,
                    onlyActive: onlyActive,
                    source: source,
                    // filters: ['hits.hits._source.id']
                    filters: this.filters.map((filter) => `hits.hits._source.` + filter)
                });

                this.resetFilters();

                const hits = res.data.hits.hits;
                const data = hits.map((entry: any) => entry._source);

                resolve(data);
            }
            catch (error) {
                reject(error);
            }

        })
    }

}

export const reportCriteriaBuilder = new ReportCriteriaBuilder();