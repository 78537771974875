import * as React from 'react';
import { observer } from 'mobx-react';
import { DashboardContractTypeEnum, customFieldStore, languageStore, CustomFieldTypeEnum, userStore, CustomFieldTemplateFieldTypeEnum, CustomFieldInterface, CustomFieldSchemaInterface,locationStore as locStore, languageStore as lanStore, departmentStore as deptStore, categoryStore as catStore, LocationInterface } from 'stores';
import DetailsPage from '../DetailsPage';
import { FormState, FieldState } from 'formstate';
import TableHeader from 'components/Table/TableHeader';
import * as _ from 'lodash';
import { ModalColumn } from 'components/Modal';
import Input from 'components/Forms/Input';
import SelectCustomFieldType from './SelectCustomFieldType';
import DropdownValues from './DropdownValues';
import { required } from 'helpers/FormValidation';
import Dropdown, { DropdownOptionInterface } from 'components/Forms/Dropdown';

interface Props {
    contractType: DashboardContractTypeEnum
}

interface State {
    isNewEntity: boolean,
    isShowModal: boolean,
    overrideModal: boolean
}

@observer class CustomFieldsPage extends React.Component<Props, State> {

    form = new FormState({
        id: new FieldState<string | null>(null),
        label: new FieldState('').validators(required),
        description: new FieldState(''),
        fieldType: new FieldState<number | null>(null),
        locationId: new FieldState<number | null>(null),
        categoryId: new FieldState<number | null>(null),
        departmentId: new FieldState<number | null>(null),
        dropdownValues: new FieldState<Array<string>>([])
    })

    constructor(props: Props) {
        super(props);
        deptStore.getDepartmentsByRole();
        locStore.getLocations(true);
        catStore.getAllCategories();
        this.state = {
            isNewEntity: false,
            isShowModal: false,
            overrideModal: false
        }
    }

    componentDidMount() {
        this.getData();
    }

    componentWillUnmount() {
        customFieldStore.resetStore();
    }

    getData() {
        const { contractType } = this.props;
        customFieldStore.getCustomFields(contractType);
    }

    onHideModal = () => {
        this.setState({ isNewEntity: false, isShowModal: false });
        this.form.reset();
    }
    
    onShowModal = (id: string | null) => {
        const form = this.form.$;

        if (!id) {
            this.setState({ isNewEntity: true });
            form.dropdownValues.reset([]);
        }
        else {
            const entity = _.find(customFieldStore.customFields, { id: id }) as CustomFieldInterface;
            form.label.reset(entity.customFieldSchema.label);
            form.description.reset(entity.description);
            form.fieldType.reset(entity.customFieldSchema.fieldType!);
            form.dropdownValues.reset(entity.customFieldSchema.availableValues);
        }

    }
    
    onSubmit = async (data: CustomFieldInterface) => {
        const form = this.form.$;

        if (form.fieldType.$ === CustomFieldTypeEnum.DROPDOWN && form.dropdownValues.$.length === 0) {
            alert(languageStore.get('dropDownMustHaveValues'));
            return;
        }
        else
        {
            const customFieldSchema = {
                id: form.id.$,
                fieldType: form.fieldType.$,
                companyId: userStore.signedInUser.company.id,
                contractType: this.props.contractType,
                label: form.label.$,
                desciption: form.description.$,
                availableValues: form.dropdownValues.$,
                categoryId: form.categoryId.$,
                departmentId: form.departmentId.$
            } as CustomFieldSchemaInterface

            if (this.isNewEntity()) {
                customFieldStore.createCustomField(customFieldSchema);
            }
            else {
                await customFieldStore.updateCustomField(customFieldSchema);
                this.getData();
            }
            
            this.setState({ overrideModal: true, isShowModal: false });
            this.setState({ overrideModal: false, isShowModal: false });
        }

        
    }

    isNewEntity = () => {
        const form = this.form.$;
        return form && !!!form.id.$;
    }

    onDeleteCustomField = (id: string) => {
        if (window.confirm(languageStore.get('areYouSureYouWantToDeleteThisCustomField'))) {
            customFieldStore.deleteCustomField(id);
        }
    }

    customFieldTypeToReadableString = (fieldType: CustomFieldTypeEnum) => {
        switch (fieldType) {
            case CustomFieldTypeEnum.STRING: return languageStore.get('string');
            case CustomFieldTypeEnum.STRING_MAX: return languageStore.get('longString');
            case CustomFieldTypeEnum.INT: return languageStore.get('integer');
            case CustomFieldTypeEnum.DECIMAL: return languageStore.get('decimal');
            case CustomFieldTypeEnum.CHECKBOX: return languageStore.get('checkbox');
            case CustomFieldTypeEnum.DROPDOWN: return languageStore.get('dropdown');
            case CustomFieldTypeEnum.MULTIPLE_CHOICE: return languageStore.get('multipleChoice');
            default: return null;
        }
    }

    render() {
        
        const { isNewEntity } = this.state;
        
        let availLocations: Array<DropdownOptionInterface> = [];
        
        locStore.locations && _.orderBy(locStore.locations, 'name').forEach((loc: LocationInterface) => {
            availLocations.push({
                value: loc.id,
                name: loc.name
            })
        })

        let availDepartments: Array<any> = [];
        
        deptStore.departments && _.orderBy(deptStore.departments, 'name').forEach((d: any) => {
            availDepartments.push({
                value: d.id,
                name: d.name,
                locationId: d.locationId
            })
        })

        let availCategories: Array<any> = [];

        catStore.allCategories && _.orderBy(catStore.allCategories, 'name').forEach((d: any) => {
            
            if(d.contractType == this.props.contractType)
            {
                availCategories.push({
                    value: d.id,
                    name: d.name,
                    locationId: d.locationId
                })
            }
            
        })

        const form = this.form.$;
        let data = customFieldStore.customFields || [];
        data = _.filter(data, (d) => d.templateFieldType === CustomFieldTemplateFieldTypeEnum.CUSTOM);

        const columns = [
            {
                Header: <TableHeader value={languageStore.get('label')} />,
                id: 'label',
                accessor: (d: CustomFieldInterface) => {
                    return d.customFieldSchema.label;
                }
            },
            {
                Header: <TableHeader value={languageStore.get('key')} />,
                accessor: 'key'
            },
            {
                Header: <TableHeader value={languageStore.get('description')} />,
                accessor: 'description'
            },
            {
                Header: <TableHeader value={languageStore.get('location')} />,
                id: "location",
                accessor: (d: CustomFieldInterface) => {

                    let dep = availDepartments.filter(x => x.value == d.customFieldSchema.departmentId);
                    
                    if(!dep || dep.length < 1)
                    {
                        return languageStore.get("allLocations");
                    }

                    let c = availLocations.filter(x => x.value == dep[0].locationId);
                    if(c[0] && c[0] !== undefined)
                        return c[0].name;
                    else
                        return languageStore.get("allLocations")
                }
            },
            {
                Header: <TableHeader value={languageStore.get('department')} />,
                id: "department",
                accessor: (d: CustomFieldInterface) => {
                    let c = availDepartments.filter(x => x.value == d.customFieldSchema.departmentId);

                    if(c[0] && c[0] !== undefined)
                        return c[0].name;
                    else
                        return languageStore.get("allDepartments")
                }
            }
            
        ]

        if(this.props.contractType != DashboardContractTypeEnum.STAFF)
        {
            columns.push({
                Header: <TableHeader value={languageStore.get('category')} />,
                id: 'category',
                accessor: (d: CustomFieldInterface) => {
                    let c = availCategories.filter(x => x.value == d.customFieldSchema.categoryId);
                    if(c[0] && c[0] !== undefined)
                        return c[0].name;
                    else
                        return languageStore.get("allCategories")
                }
            });
        }

        columns.push({
            Header: <TableHeader value={languageStore.get('type')} />,
            id: 'fieldType',
            accessor: (d: CustomFieldInterface) => this.customFieldTypeToReadableString(d.customFieldSchema.fieldType)
        });

        let modalTitle = languageStore.get('customField');
        if (form.fieldType.value !== null) {
            modalTitle += ` (${this.customFieldTypeToReadableString(form.fieldType.value!)})`;
        }

        

        return (
            <DetailsPage
                fullWidth={true}
                generalTitle={languageStore.get('customFields')}
                modalTitle={modalTitle}
                form={this.form}
                columns={columns}
                isShowModal={this.state.isShowModal}
                overrideModal={this.state.overrideModal}
                onShowModal={this.onShowModal}
                onHideModal={this.onHideModal}
                onUpdate={this.onSubmit}
                onSubmit={this.onSubmit}
                onDelete={this.onDeleteCustomField}
                collection={data}>

                <ModalColumn>

                    {
                        isNewEntity &&
                        <SelectCustomFieldType fieldState={form.fieldType} />
                    }

                    {
                        form.fieldType.value === CustomFieldTypeEnum.DROPDOWN &&
                        <DropdownValues fieldState={form.dropdownValues} />
                    }

                    {
                        form.fieldType.value === CustomFieldTypeEnum.MULTIPLE_CHOICE &&
                        <DropdownValues fieldState={form.dropdownValues} />
                    }

                    {
                        isNewEntity &&
                        <Dropdown
                            label={lanStore.get('location')}
                            id='location'
                            fieldState={form.locationId}
                            placeholderText={lanStore.get('allLocations')}
                            options={availLocations}
                            disabled={false}
                        />
                    }

                    {
                        isNewEntity &&
                        form.locationId.value !== null &&
                        <Dropdown
                            label={lanStore.get('department')}
                            id='department'
                            fieldState={form.departmentId}
                            placeholderText={lanStore.get('allDepartments')}
                            options={availDepartments.filter(x => x.locationId == form.locationId.$)}
                            disabled={false}
                        />
                    }

                    {
                        isNewEntity &&
                        this.props.contractType != DashboardContractTypeEnum.STAFF &&
                        form.locationId.value !== null &&
                        <Dropdown
                            label={lanStore.get('category')}
                            id='category'
                            fieldState={form.categoryId}
                            placeholderText={lanStore.get('allCategories')}
                            options={availCategories.filter(x => x.locationId == form.locationId.$)}
                            disabled={false}
                        />
                    }

                   

                    <Input
                        fieldState={form.label}
                        id='label'
                        maxLength={36}
                        label={languageStore.get('label')}
                    />

                    <Input
                        fieldState={form.description}
                        id='description'
                        label={languageStore.get('description')}
                    />

                </ModalColumn>

            </DetailsPage>

        )
    }

}

export default CustomFieldsPage;