import axios, { AxiosRequestConfig, AxiosError, AxiosResponse } from 'axios';
import { authStore, transactionsStore, toastStore, languageStore } from 'stores';
import { loadingStore } from 'stores/LoadingStore';
import { Cache } from './Cache';

const request = axios.create({
    baseURL: process.env.REACT_APP_API_BASEURL + '/',
    headers: {
        'client-key': process.env.REACT_APP_CLIENT_KEY,
        'cache-control': 'no-cache'
    }
});

keepBearerTokenUpdated();
applyGeneralResponseLogic();

function isCacheableMethod(config: AxiosRequestConfig) {
    if (config.method) {
        return ~['GET', 'HEAD'].indexOf(config.method.toUpperCase());
    }
    return false;
}

function getCacheByAxiosConfig(config: AxiosRequestConfig) {
    if (config.url) {
        return Cache.get(config.url);
    }
    return null;
}

function setIfNoneMatchHeader(config: AxiosRequestConfig) {
    if (isCacheableMethod(config)) {
        const uuid = config.url;
        if (uuid) {
            const cacheUrl = process.env.REACT_APP_API_BASEURL + '/' + uuid;
            const lastCachedResult = Cache.get(cacheUrl);
            if (lastCachedResult) {
                config.headers = { ...config.headers, 'If-None-Match': lastCachedResult.etag };
            }
        }
    }
}

function keepBearerTokenUpdated() {
    request.interceptors.request.use(async (config) => {
        setIfNoneMatchHeader(config);
        
        loadingStore.addRequest();

        if (authStore.bearerToken) {
            setAuthorizationHeader(config, authStore.bearerToken);
        }

        if (config.method !== 'get' && transactionsStore.requiresTransactionKey && !config.headers._byPassTransactionKey) {
            if (!transactionsStore.transactionKey) {
                await transactionsStore.getTransactionKey();
            }
            setTransactionHeader(config, transactionsStore.transactionKey!);
        }

        return config;
    })

    function setAuthorizationHeader(config: AxiosRequestConfig, bearerToken: string) {
        config.headers['Authorization'] = 'bearer ' + bearerToken;
    }

    function setTransactionHeader(config: AxiosRequestConfig, transactionKey: string) {
        config.headers['transaction-key'] = transactionKey;
    }
}

function applyGeneralResponseLogic() {
    request.interceptors.response.use(
        successResponseHandler,
        errorResponseHandler
    )

    function successResponseHandler(response: AxiosResponse) {
        loadingStore.removeRequest();

        if (isCacheableMethod(response.config)) {
            const responseETAG = response.headers.etag;
            if (responseETAG && response.config.url) {
                Cache.set(response.config.url, responseETAG, response.data);
            }
        }

        return response;
    }

    function errorResponseHandler(error: AxiosError): any {
        loadingStore.removeRequest();

        if (error.response) {
            switch (error.response.status) {
                case 302:
                    const getCachedResult = getCacheByAxiosConfig(error.response.config);
                    if (!getCachedResult) {
                        return Promise.reject(error);
                    }
                    const newResponse = error.response;
                    newResponse.status = 200;
                    newResponse.data = getCachedResult.value;
                    return Promise.resolve(newResponse);

                case 400:
                    if (error.response.data.error) {
                        toastStore.addError(error.response.data.error);
                    }
                    else if (error.response?.data && Object.keys(error.response.data).length > 0) {
                        Object.keys(error.response.data).forEach((val) => {
                            toastStore.addError(error.response!.data[val]);
                        })
                    }
                    else {
                        toastStore.addError(languageStore.get('badRequest'));
                    }

                    break;

                case 401:
                    if (authStore.isAuthenticated) {
                        authStore.forceRelogin(true);
                    }
                    break;

                case 403:
                    toastStore.addError(languageStore.get(languageStore.get('youDontHaveTheNecessaryPermissions')));
                    break;

                case 404:
                    // toastStore.addError(languageStore.get('notFound'));
                    break;

                case 503:
                    toastStore.addError(languageStore.get('systemIsBeingUpdatedTryAgainInAMoment'));
                    break;

            }

        }

        return Promise.reject(error);
    }
}

export default request;