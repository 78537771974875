
import { CurrentContractStore, ContractDocumentInterface, ContractAlarmInterface, VerificationEntryInterface, CurrentContractBaseInterface } from './CurrentContractStore';
import { computed, action, makeObservable } from 'mobx';
import { ContractNoteInterface } from './BaseContractsStore';
import { DepartmentInterface } from './DepartmentStore';
import request from 'api/request';
import { replaceById } from 'helpers/CollectionHelper';
import * as _ from 'lodash';
import { BenefitInterface } from './BenefitStore';
import { EquipmentInterface } from './EquipmentStore';
import { CurrencyInterface } from './CurrencyStore';
import { AgreementInterface } from './AgreementStore';
import { hasRole } from 'helpers/RoleHelper';
import { RoleEnum } from './RolesStore';
import { DashboardContractTypeEnum } from './DashboardStore';
import { ContactPeopleInterface } from './SupplierStore';

export enum StaffContractStatusEnum {
    Hired = 1,
    Dismissed = 2,
    Rejected = 3,
    Resigned = 4,
    Cancelled = 5,
    Applicant = 6,
    Draft = 7
}

export interface StaffUserInterface {
    "id": string,
    "fullName": string,
    "companyId": string
}

export enum AppraisalInterviewStatusEnum {
    SCHEDULED = 1,
    HELD = 2,
    DELETED = 3
}

export enum AppraisalStatusEnum {
    SATISFIED = 1,
    LESS_SATISFIED = 2,
    LESS_DISSATISFIED = 3,
    DIS_SATISFIED = 4
}

export interface StaffAppraisalInterviewInterface {
    "id": string,
    "heldOn": string,
    "conclusion": string,
    "appraisalInterviewStatus": AppraisalInterviewStatusEnum,
    "satisfaction": AppraisalStatusEnum,
    "documentId": string,
    "document": ContractDocumentInterface,
    "heldWithUserId": string,
    "heldWithUser": StaffUserInterface
}

export interface StaffBenefitsInterface {
    "id": string,
    "price": string,
    "taxationValue": string,
    "handedOut": string,
    "handedIn": string,
    "note": string,
    "staffContractDetailsStatus": StaffContractDetailsStatusEnum,
    "staffContractId": string,
    "fullname": string,
    "benefitId": string,
    "benefit": BenefitInterface,
    "accountableUserId": string,
    "accountableUser": StaffUserInterface
}

export enum StaffContractDetailsStatusEnum {
    AGREED = 1,
    COMPLETED = 2,
    FOR_RENEWAL = 3,
    DELETED = 4
}

export interface StaffEducationInterface {
    "id": string,
    "passed": string,
    "price": string,
    "renewalDate": string,
    "staffContractDetailsStatus": StaffContractDetailsStatusEnum
    "documentId": string,
    "documents": Array<ContractDocumentInterface>,
    "educationLevelId": string,
    "educationLevel": {
        "name": string,
        "locationId": string
    },
    "accountableUserId": string,
    "accountableUser": StaffUserInterface,
    "staffContractId": string
}

export interface StaffEquipmentInterface {
    "id": string,
    "accepted": boolean,
    "handedOut": string,
    "handedIn": string,
    "note": string,
    "staffContractDetailsStatus": number,
    "equipmentId": string,
    "equipment": EquipmentInterface,
    "staffContractId": string,
    "fullname": string
}

export interface StaffSkillInterface {
    "id": string,
    "staffContractDetailsStatus": number,
    "skillId": string,
    "skill": {
        "name": string,
        "locationId": string
    },
    "documentId": string,
    "documents": Array<ContractDocumentInterface>,
    "accountableUserId": string,
    "accountableUser": StaffUserInterface,
    "staffContractId": string
}

export interface FullStaffContractInterface extends CurrentContractBaseInterface {
    "id": string,
    "appraisalInterviews": Array<StaffAppraisalInterviewInterface>,
    "benefits": Array<StaffBenefitsInterface>,
    "educations": Array<StaffEducationInterface>,
    "equipment": Array<StaffEquipmentInterface>,
    "skills": Array<StaffSkillInterface>,
    "cvs": Array<ContractDocumentInterface>,
    "name": string,
    "ssn": string,
    "employeeNo": string,
    "address": string,
    "phone": string,
    "postCode": string,
    "city": string,
    "emergencyContactName": string,
    "emergencyContractPhone": string,
    "position": string,
    "salary": number,
    "hourlyWage": number,
    "birthday": string,
    "seniorityDate": string,
    "employmentDate": string,
    "renegotiationDate": string,
    "terminationDate": string,
    "resignationDate": string,
    "bonusNegotiationDate": string,
    "bonusAgreement": boolean,
    "pensionStartDate": string,
    "pensionCompany": string,
    "deletionDate": string,
    "deletionNotice": number,
    "deletionProlongReason": string,
    "employerShare": string,
    "employeeShare": string,
    "additionalDeposits": string,
    "healthInsurance": boolean,
    "validWorkPermit": boolean,
    "bankRegistrationNo": string,
    "accountNo": string,
    "introCourse": boolean,
    "staffNotes": string,
    "email": string,
    "contractStatus": StaffContractStatusEnum,
    "employmentForm": number,
    "employmentType": number,
    "agreementId": string,
    "agreement": AgreementInterface,
    "bossId": string,
    "boss": StaffUserInterface,
    "departmentId": string,
    "department": DepartmentInterface,
    "currencyId": string,
    "currency": CurrencyInterface,
    "responsibleUserId": string,
    "responsibleUser": {
        "id": string,
        "fullName": string,
        "companyId": string
    },
    "picture": ContractDocumentInterface,
    "pictureId": string,
    "notes": Array<ContractNoteInterface>,
    "alarms": Array<ContractAlarmInterface>,
    "contractDocuments": Array<ContractDocumentInterface>,
    "contractCorrespondances": Array<ContractDocumentInterface>,
    "contractVerificationEntries": Array<VerificationEntryInterface>,
    "contractContractReferences": Array<any>,
    "createdOn": string,
    "createdById": string,
    "modifiedById": string,
    "contactPeople": Array<ContactPeopleInterface>
}

export class CurrentStaffContractStore extends CurrentContractStore<FullStaffContractInterface> {

    constructor() {
        super();

        makeObservable(this);

        this.contractEndpoint = 'staff';
        this.contractType = DashboardContractTypeEnum.STAFF;
    }

    @computed
    get isDraft() {
        return !!this.contract && this.contract.contractStatus === StaffContractStatusEnum.Draft;
    }

    @computed
    get canEdit() {
        return hasRole(RoleEnum.HR_WRITE) || hasRole(RoleEnum.HR_ADMIN);
    }

    addDocumentToContract(document: ContractDocumentInterface, endpoint: string, target: Array<any>): Promise<void> {

        return new Promise(async (resolve, reject) => {
            try {
                const res = await request.post(`contracts/staff/${this.contract!.id}/${endpoint}`, document);

                if (res.status === 201 && target !== null) {
                    target.push(document);
                }

                resolve();
            }
            catch (error) {
                reject(error);
            }
        })
    }

    updateDocument(document: ContractDocumentInterface, target: Array<any>): Promise<void> {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await request.put(`documents/${document.id}`, document);

                if (res.status === 200) {
                    replaceById(document.id, target, document);
                }

                resolve();
            }
            catch (error) {
                reject(error);
            }
        })
    }

    @action
    async createContact(contact: ContactPeopleInterface) {
        if (contact.isPrimary) {
            const res = await this.create(`contracts/staff/${this.contract!.id}/contacts`, contact, this.contract!.contactPeople);
            this.contract!.contactPeople = this.contract!.contactPeople.map((item) => {
                if (item.id !== contact.id) {
                    item.isPrimary = false;
                }
                return item;
            })
            return res;
        }
        else {
            return this.create(`contracts/staff/${this.contract!.id}/contacts`, contact, this.contract!.contactPeople);
        }
    }

    @action
    async updateContact(contact: ContactPeopleInterface) {
        if (contact.isPrimary) {
            const res = await this.update(`contracts/staff/${this.contract!.id}/contacts/${contact.id}`, contact, this.contract!.contactPeople);
            this.contract!.contactPeople = this.contract!.contactPeople.map((item) => {
                if (item.id !== contact.id) {
                    item.isPrimary = false;
                }
                return item;
            })
            return res;
        }
        else {
            return this.update(`contracts/staff/${this.contract!.id}/contacts/${contact.id}`, contact, this.contract!.contactPeople);
        }
    }

    @action
    deleteContact(contactId: string) {
        return this.delete(`contracts/staff/${this.contract!.id}/contacts/${contactId}`, contactId, this.contract!.contactPeople);
    }

    @action
    createAppraisalInterview(appraisalInterview: StaffAppraisalInterviewInterface) {
        return this.create(`contracts/staff/${this.contract!.id}/appraisalinterviews`, appraisalInterview, this.contract!.appraisalInterviews);
    }

    @action
    updateAppraisalInterview(appraisalInterview: StaffAppraisalInterviewInterface) {
        return this.update(`contracts/staff/${this.contract!.id}/appraisalinterviews/${appraisalInterview.id}`, appraisalInterview, this.contract!.appraisalInterviews);
    }

    @action
    deleteAppraisalInterview(appraisalInterviewId: string) {
        return this.delete(`contracts/staff/${this.contract!.id}/appraisalinterviews/${appraisalInterviewId}`, appraisalInterviewId, this.contract!.appraisalInterviews);
    }

    @action
    createEducationDetails(educationDetails: StaffEducationInterface) {
        return this.create(`contracts/staff/${this.contract!.id}/educations`, educationDetails, this.contract!.educations);
    }

    @action
    updateEducationDetails(educationDetails: StaffEducationInterface) {
        return this.update(`contracts/staff/${this.contract!.id}/educations/${educationDetails.id}`, educationDetails, this.contract!.educations, undefined, undefined, true);
    }

    @action
    addDocumentToEducation(educationDetailsId: string, document: ContractDocumentInterface) {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await request.post(`contracts/staff/${this.contract!.id}/educations/${educationDetailsId}/documents`, document);
                const educationDetails = _.find(this.contract!.educations, { id: educationDetailsId });

                if (educationDetails) {
                    if (!educationDetails.documents || educationDetails.documents.length === 0) {
                        educationDetails.documents = [document];
                    }
                    else {
                        educationDetails.documents.push(document);
                    }
                }
                this.contract!.educations = replaceById(educationDetailsId, this.contract!.educations, educationDetails);
                resolve(res);
            }
            catch (error) {
                reject(error);
            }
        })
    }

    @action
    removeDocumentFromEducation(educationDetailsId: string, documentId: string): Promise<void> {
        return new Promise(async (resolve, reject) => {
            try {
                await request.delete(`contracts/staff/${this.contract!.id}/educations/${educationDetailsId}/documents/${documentId}`);

                const educationDetails = _.find(this.contract!.educations, { id: educationDetailsId });

                if (educationDetails) {
                    _.remove(educationDetails.documents, (t) => t.id === documentId);
                    resolve();
                }
            }
            catch (error) {
                reject(error);
            }
        })
    }

    @action
    deleteEducationDetails(educationDetailsId: string) {
        return this.delete(`contracts/staff/${this.contract!.id}/educations/${educationDetailsId}`, educationDetailsId, this.contract!.educations);
    }

    @action
    createBenefit(benefit: StaffBenefitsInterface) {
        return this.create(`contracts/staff/${this.contract!.id}/benefits`, benefit, this.contract!.benefits);
    }

    @action
    updateBenefit(benefit: StaffBenefitsInterface) {
        return this.update(`contracts/staff/${this.contract!.id}/benefits/${benefit.id}`, benefit, this.contract!.benefits);
    }

    @action
    deleteBenefit(benefitId: string) {
        return this.delete(`contracts/staff/${this.contract!.id}/benefits/${benefitId}`, benefitId, this.contract!.benefits);
    }

    @action
    createEquipment(equipment: StaffEquipmentInterface) {
        return this.create(`contracts/staff/${this.contract!.id}/equipment`, equipment, this.contract!.equipment);
    }

    @action
    updateEquipment(equipment: StaffEquipmentInterface) {
        return this.update(`contracts/staff/${this.contract!.id}/equipment/${equipment.id}`, equipment, this.contract!.equipment);
    }

    @action
    deleteEquipment(equipmentId: string) {
        return this.delete(`contracts/staff/${this.contract!.id}/equipment/${equipmentId}`, equipmentId, this.contract!.equipment);
    }

    @action
    createSkill(skill: StaffSkillInterface) {
        return this.create(`contracts/staff/${this.contract!.id}/skills`, skill, this.contract!.skills);
    }

    @action
    addDocumentToSkill(skillId: string, document: ContractDocumentInterface) {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await request.post(`contracts/staff/${this.contract!.id}/skills/${skillId}/documents`, document);
                const skill = _.find(this.contract!.skills, { id: skillId });

                if (skill) {
                    if (!skill.documents || skill.documents.length === 0) {
                        skill.documents = [document];
                    }
                    else {
                        skill.documents.push(document);
                    }
                }
                this.contract!.skills = replaceById(skillId, this.contract!.skills, skill);
                resolve(res);
            }
            catch (error) {
                reject(error);
            }
        })
    }

    @action
    removeDocumentFromSkill(skillId: string, documentId: string): Promise<void> {
        return new Promise(async (resolve, reject) => {
            try {
                await request.delete(`contracts/staff/${this.contract!.id}/skills/${skillId}/documents/${documentId}`);

                const skill = _.find(this.contract!.skills, { id: skillId });

                if (skill) {
                    _.remove(skill.documents, (t) => t.id === documentId);
                    resolve();
                }
            }
            catch (error) {
                reject(error);
            }
        })
    }

    @action
    updateSkill(skill: StaffSkillInterface) {
        return this.update(`contracts/staff/${this.contract!.id}/skills/${skill.id}`, skill, this.contract!.skills, undefined, undefined, true);
    }

    @action
    deleteSkill(skillId: string) {
        return this.delete(`contracts/staff/${this.contract!.id}/skills/${skillId}`, skillId, this.contract!.skills);
    }

}

export const currentStaffContractStore = new CurrentStaffContractStore();