import { Modal, ModalColumn } from 'components/Modal';
import { ContractStatusHelper } from 'helpers/ContractStatusHelper';
import { formatDate } from 'helpers/DateHelper';
import _ from 'lodash';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { CurrentContractBaseInterface, CurrentContractStore, DashboardContractTypeEnum, languageStore } from 'stores';
import { contractAuditStore } from 'stores/ContractAuditStore';

interface Props {
    store: CurrentContractStore<CurrentContractBaseInterface>,
    type: DashboardContractTypeEnum
}

const ContractStatusHistoryModal = observer((props: Props) => {
    const [showModal, setShowModal] = useState(false);

    const endpoint = props.store.contractEndpoint;
    const contractId = props.store.contract?.id;

    useEffect(() => {
        if (showModal && endpoint && contractId) {
            contractAuditStore.getContractStatusAudits(endpoint, contractId);
        }

        return () => {
            contractAuditStore.resetStore();
        }

    }, [endpoint, contractId, showModal])

    const getStatusByContractType = (contractStatusNumber: number) => {
        const type = props.type;

        let data = ContractStatusHelper.BasicContractStatus(true);

        if (type === DashboardContractTypeEnum.STAFF) {
            data = ContractStatusHelper.StaffContractStatus(true);
        }

        const result = _.find(data, d => d.value === contractStatusNumber);
        if (!result) return '-';

        return result.name;
    }

    const data = contractAuditStore.contracts;

    return (
        <div className='text-left'>
            <button
                onClick={() => setShowModal(true)}
                type='button'
                className='text-xs underline mt-2'>{languageStore.get('viewHistory')}</button>
            {
                showModal &&
                <Modal
                    forceWide={true}
                    title={languageStore.get('viewHistory')}
                    secondaryButton={{ title: languageStore.get('close'), onClick: () => setShowModal(false) }}>

                    <ModalColumn>
                        <table className='w-full'>
                            <thead>
                                <tr>
                                    <th>{languageStore.get('date')}</th>
                                    <th>{languageStore.get('user')}</th>
                                    <th>{languageStore.get('status')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    data.map((audit, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{formatDate(audit.modifiedOn ?? audit.createdOn, true)}</td>
                                                <td>{audit.auditedByName ?? audit.modifiedById ?? audit.createdById}</td>
                                                <td>{getStatusByContractType(audit.contractStatus)}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </ModalColumn>
                </Modal>
            }
        </div>
    )
})

export default ContractStatusHistoryModal;