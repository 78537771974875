import * as React from 'react';

interface ModalColumnProps {
    children?: React.ReactNode
}

export const ModalColumn: React.SFC = (props: ModalColumnProps) => {
    const { children } = props;

    return (
        <div className='w-full px-2'>
            {children}
        </div>
    )
}