import * as React from 'react';
import { observer } from 'mobx-react';
import { ContractDocumentInterface, currentStaffContractStore as store, languageStore, toastStore } from 'stores';
import { FormState, FieldState } from 'formstate';
import SectionEnum from '../SectionEnum';
import ContractDetailsGroup from 'components/ContractDetailsGroup';
import ContractDetailsColumn from 'components/ContractDetailsColumn';
import ContractDetailsItem from 'components/ContractDetailsItem';
import { required } from 'helpers/FormValidation';
import Location from 'components/Forms/Location';
import ContractDateItem from 'components/ContractDateItem';
import SelectUser from './SelectUser';
import SelectEmploymentForm from './SelectEmploymentForm';
import SelectEmploymentType from './SelectEmploymentType';
import BaseContract from '../BaseContract';
import Department from 'components/Forms/Department';
import SelectTrueFalse from 'components/Forms/SelectTrueFalse';
import ContractImageItem from 'components/ContractImageItem';
import Country from 'components/Forms/Country';

interface State {
    uploadedPicture: ContractDocumentInterface | null,
}

@observer class Overview extends React.Component<any, State> {

    form = new FormState({
        locationId: new FieldState(store.contract!.department && store.contract!.department.locationId).validators(required),
        departmentId: new FieldState(store.contract!.departmentId).validators(required),
        countryId: new FieldState(store.contract!.countryId),
        name: new FieldState(store.contract!.name).validators(required),
        birthday: new FieldState(store.contract!.birthday).validators(required),
        address: new FieldState(store.contract!.address),
        postCode: new FieldState(store.contract!.postCode),
        ssn: new FieldState(store.contract!.ssn).validators(required),
        employeeNo: new FieldState(store.contract!.employeeNo),
        phone: new FieldState(store.contract!.phone),
        city: new FieldState(store.contract!.city),
        position: new FieldState(store.contract!.position),
        employmentForm: new FieldState(store.contract!.employmentForm).validators(required),
        employmentType: new FieldState(store.contract!.employmentType).validators(required),
        bossId: new FieldState(store.contract!.bossId),
        bankRegistrationNo: new FieldState(store.contract!.bankRegistrationNo),
        accountNo: new FieldState(store.contract!.accountNo),
        staffNotes: new FieldState(store.contract!.staffNotes),
        email: new FieldState(store.contract!.email),
        requireDocumentTypes: new FieldState(store.contract!.requireDocumentTypes),
        pictureId: new FieldState(store.contract!.pictureId)
    })

    constructor(props: any) {
        super(props);

        this.state = {
            uploadedPicture: null
        }
    }

    onPictureUploaded = (document: ContractDocumentInterface | null) => {
        this.setState({
            uploadedPicture: document
        })
    }

    showAlarmWarning = () => {
        const { bossId } = this.form.$;

        // We only want to show the alarm warning,
        // if a current responsible user is begin changed,
        // and only the first time.
        if (bossId.value && !bossId.dirty) {
            toastStore.addToast({
                level: 'warning',
                title: languageStore.get('warning'),
                message: languageStore.get('rememberToUpdateResponsibleUserForAlarms')
            })
        }
    }

    render() {
        const { uploadedPicture } = this.state;

        const form = this.form.$;
        const editMode = store.isEditMode(SectionEnum.OVERVIEW);

        return (
            <BaseContract
                store={store}
                form={this.form}
                showDraftRibbon={store.isDraft}
                hideCancelButton={true}
                sectionEnum={SectionEnum.OVERVIEW}>

                <ContractDetailsGroup title={languageStore.get('overview')}>
                    <ContractDetailsColumn>

                        <ContractDetailsItem
                            label={languageStore.get('name')}
                            id='name'
                            fieldState={form.name}
                            editMode={editMode} />

                        <ContractDateItem
                            label={languageStore.get('birthday')}
                            id='birthday'
                            fieldState={form.birthday}
                            editMode={editMode} />

                        <ContractDetailsItem
                            label={languageStore.get('address')}
                            id='address'
                            fieldState={form.address}
                            editMode={editMode}
                            textArea={true} />

                        <ContractDetailsItem
                            label={languageStore.get('postCode')}
                            id='postCode'
                            fieldState={form.postCode}
                            editMode={editMode} />

                        <ContractDetailsItem
                            label={languageStore.get('city')}
                            id='city'
                            fieldState={form.city}
                            editMode={editMode} />

                        <Country
                            fieldState={form.countryId}
                            editMode={editMode} 
                        />

                        <SelectTrueFalse
                            label={languageStore.get('requiredDocuments')}
                            id='requiredDocuments'
                            fieldState={form.requireDocumentTypes}
                            editMode={store.isDraft && editMode} />

                    </ContractDetailsColumn>

                    <ContractDetailsColumn>

                        <ContractImageItem
                            onImageUploaded={this.onPictureUploaded}
                            label={languageStore.get('picture')}
                            id='pictureId'
                            picture={uploadedPicture || store.contract!.picture}
                            fieldState={form.pictureId}
                            editMode={editMode}
                        />

                        <ContractDetailsItem
                            label={languageStore.get('ssn')}
                            id='ssn'
                            fieldState={form.ssn}
                            editMode={editMode} />

                        <ContractDetailsItem
                            label={languageStore.get('phone')}
                            id='phone'
                            fieldState={form.phone}
                            editMode={editMode} />

                        <ContractDetailsItem
                            label={languageStore.get('email')}
                            id='email'
                            fieldState={form.email}
                            editMode={editMode} />

                        <ContractDetailsItem
                            label={languageStore.get('bankRegistrationNo')}
                            id='bankRegistrationNo'
                            fieldState={form.bankRegistrationNo}
                            editMode={editMode} />

                        <ContractDetailsItem
                            label={languageStore.get('accountNo')}
                            id='accountNo'
                            fieldState={form.accountNo}
                            editMode={editMode} />

                    </ContractDetailsColumn>
                </ContractDetailsGroup>

                <ContractDetailsGroup title={languageStore.get('employment')}>

                    <ContractDetailsColumn>

                        <Location
                            store={store}
                            fieldState={form.locationId}
                            editMode={editMode} />

                        <Department
                            store={store}
                            locationId={form.locationId.value}
                            fieldState={form.departmentId}
                            fallbackValue={store.contract!.department && store.contract!.department.name}
                            editMode={editMode} />

                        <ContractDetailsItem
                            label={languageStore.get('position')}
                            id='position'
                            fieldState={form.position}
                            editMode={editMode} />

                        <SelectUser
                            beforeChange={this.showAlarmWarning}
                            label={languageStore.get('boss')}
                            id='boss'
                            fieldState={form.bossId}
                            editMode={editMode} />

                        <ContractDetailsItem
                            label={languageStore.get('employeeNo')}
                            id='employeeNo'
                            fieldState={form.employeeNo}
                            editMode={editMode} />

                    </ContractDetailsColumn>

                    <ContractDetailsColumn>

                        <SelectEmploymentForm
                            fieldState={form.employmentForm}
                            editMode={editMode} />

                        <SelectEmploymentType
                            fieldState={form.employmentType}
                            editMode={editMode} />

                        <ContractDetailsItem
                            label={languageStore.get('other')}
                            id='staffNotes'
                            fieldState={form.staffNotes}
                            editMode={editMode}
                            textArea={true} />

                    </ContractDetailsColumn>

                </ContractDetailsGroup>

            </BaseContract>
        )

    }

}

export default Overview;