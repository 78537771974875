import * as React from 'react';
import { observer } from 'mobx-react';
import { currentSalesContractStore as store, languageStore, DashboardContractTypeEnum, customFieldStore, userStore } from 'stores';
import Menu from './Menu';
import Overview from './Overview';
import Customer from 'components/Forms/Customer';
import SectionEnum from './SectionEnum';
import Notes from 'components/Notes';
import FinanceAccount from 'components/Forms/FinanceAccount';
import Services from 'components/Forms/Services';
import Alarms from 'components/Alarms';
// import VerifiedBy from 'components/Forms/VerifiedBy';
import Documents from './Documents';
import Correspondence from './Correspondence';
import ContractMetaStatus from 'components/Forms/ContractMetaStatus';
import ContractDetailsTop from 'components/ContractDetailsTop';
import ContractToContract from 'components/ContractToContract';
import ContractDetailsContainer from 'components/ContractDetailsContainer';
import ContractDetailsPage from 'components/ContractDetailsPage';
import SalesContractSum from './SalesContractSum';
import CustomFields from 'components/CustomFields';

interface Props {
    match?: any
}

@observer class SalesContractDetailsPage extends React.Component<Props> {

    render() {
        return (
            <ContractDetailsPage store={store} contractId={this.props.match.params.id}>
                {
                    store.contract &&
                    <>
                        <ContractDetailsTop
                            helpUrl='https://help.comasystem.dk/sales/new-contract'
                            backlinkTitle={languageStore.get('salesContracts')}
                            title={languageStore.get('showSalesContract')}
                            store={store} />

                        <ContractDetailsContainer>

                            <Menu />
                            <Overview />
                            <Customer store={store} hash={SectionEnum.CUSTOMER} />
                            <Notes store={store} hash={SectionEnum.NOTES} />
                            <FinanceAccount
                                onCreate={store.createFinanceAccount}
                                onUpdate={store.updateFinanceAccount}
                                onDelete={store.deleteFinanceAccount}
                                financeAccounts={store.contract!.contractFinanceAccounts}
                                store={store}
                                hash={SectionEnum.FINANCE_ACCOUNT} />
                            <Services
                                onCreate={store.createFinancialService}
                                onUpdate={store.updateFinancialService}
                                onDelete={store.deleteFinancialService}
                                services={store.contract!.financialServices}
                                store={store}
                                hash={SectionEnum.SERVICES} />
                            <SalesContractSum />
                            <Alarms store={store} hash={SectionEnum.EXTRA_ALARMS} />
                            {/* <VerifiedBy store={store} hash={SectionEnum.VERIFIED_BY} /> */}
                            <Documents />
                            <ContractToContract store={store} hash={SectionEnum.LINKED_CONTRACTS} />
                            <Correspondence />

                            {
                                (userStore.signedInUser!.company.allowCustomFields && customFieldStore.hasCustomFields[DashboardContractTypeEnum.SALES]) &&
                                <CustomFields store={store} hash={SectionEnum.CUSTOM_FIELDS} />
                            }
                            <ContractMetaStatus
                                store={store}
                                hash={SectionEnum.STATUS}
                                type={DashboardContractTypeEnum.SALES} />

                        </ContractDetailsContainer>
                    </>
                }

            </ContractDetailsPage>
        )
    }

}

export default SalesContractDetailsPage;