import { observer } from "mobx-react";
import { languageStore, navigationStore } from "stores";
import SidebarNavigation from 'components/AdminPage/SidebarNavigation';
import useCompanyLocationByParams from "./useCompanyLocationByParams";
import { useLocation, useParams } from "react-router";

interface Props {
    children: React.ReactNode
}

const LocationDetailsPage = observer((props: Props) => {

    const location = useCompanyLocationByParams();
    const { pathname } = useLocation();
    const { id } = useParams<{ id: string }>();

    if (!location) return null;

    return (
        <div>
            <div className='text-left flex-1'>
                <h2 className='mt-2 mb-5'>
                    <button className='mr-2 text-red' onClick={() => navigationStore.push('/admin/locations')}>
                        &larr;
                    </button>
                    {`${languageStore.get('edit')} ${languageStore.get('location', false)}`} ({location.name})
                </h2>
            </div>

            <div className='flex'>
                <SidebarNavigation
                    items={[
                        {
                            title: languageStore.get('location'),
                            defaultExpanded: true,
                            subItems: [
                                {
                                    label: languageStore.get('general'),
                                    onClick: () => navigationStore.push(`/admin/locations/${id}`),
                                    isSelected: pathname === `/admin/locations/${id}`
                                },
                                {
                                    label: languageStore.get('users'),
                                    onClick: () => navigationStore.push(`/admin/locations/${id}/users`),
                                    isSelected: pathname === `/admin/locations/${id}/users`
                                },
                                {
                                    label: languageStore.get('departments'),
                                    onClick: () => navigationStore.push(`/admin/locations/${id}/departments`),
                                    isSelected: pathname === `/admin/locations/${id}/departments`
                                },
                            ]
                        }
                    ]}
                />

                <div className='flex-1 ml-6'>
                    {props.children}
                </div>
            </div>
        </div>
    )

})

export default LocationDetailsPage;