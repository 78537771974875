import * as React from 'react';
import ContractsTable from 'components/ContractsTable';
import TableSearch from 'components/TableSearch';
import HorizontalCategoryMenu, { HorizontalCategoryMenuItem } from 'components/HorizontalCategoryMenu';
import { observer } from 'mobx-react';
import { ViewByEnum, BaseContractsStore, languageStore, linkContractStore, linkInboxStore, ContractStatusEnum, StaffContractStatusEnum } from 'stores';
import TableSearchResults from 'components/TableSearchResults';
import { MdClose } from 'react-icons/md';
import { Prompt } from 'react-router-dom';

export interface ContractPageGroupInterface {
    title: string,
    enum: ViewByEnum,
    onSubRowClicked?: Function,
    disableFilterButtons?: boolean,
    defaultSortingId?: string
}

export interface ContractPageStatusEnum {
    title: string,
    enum: ContractStatusEnum | StaffContractStatusEnum
}

interface Props {
    store: BaseContractsStore<any>,
    groups: Array<ContractPageGroupInterface>,
    statuses?: Array<ContractPageStatusEnum>
}

@observer class ContractsPage extends React.Component<Props> {

    onCategoryClicked = (item: HorizontalCategoryMenuItem) => {
        const { store } = this.props;
        const payloadAsEnum = item.payload as ViewByEnum;
        store.setViewBy(payloadAsEnum);
        store.getGroups(payloadAsEnum, store.onlyMine, store.expiredOnly);
    }

    onResponsibleClicked = (item: HorizontalCategoryMenuItem) => {
        const { store } = this.props;
        const onlyMine = !!item.payload;
        store.setOnlyMine(onlyMine);
        store.getGroups(store.viewBy, onlyMine, store.expiredOnly);
    }

    onExpiredOnlyClicked = (item: HorizontalCategoryMenuItem) => {
        const { store } = this.props;
        const expiredOnly = !!item.payload;
        store.setExpiredOnly(expiredOnly);
        store.getGroups(store.viewBy, store.onlyMine, expiredOnly);
    }

    onStatusChanged = (value: any) => {
        const { store } = this.props;
        if (value === "-1") {
            store.setSelectedStatus(null);
        }
        else {
            store.setSelectedStatus(value);
        }
        store.getGroups(store.viewBy, store.onlyMine, store.expiredOnly);
    }

    componentWillUnmount() {
        const { store } = this.props;
        store.setSelectedStatus(null);
    }

    render() {
        const { store, groups, statuses } = this.props;
        const isSearchMode = !!store.filteredContracts;

        let groupItems = [] as any;
        let onSubRowClicked = undefined;
        let disableFilterButtons = false;
        let defaultSortingId = undefined;

        groups.forEach((group) => {
            const isActive = store.viewBy === group.enum;

            if (isActive && group.onSubRowClicked) {
                onSubRowClicked = group.onSubRowClicked;
            }

            if (isActive && group.disableFilterButtons) {
                disableFilterButtons = true;
            }

            if (isActive && group.defaultSortingId) {
                defaultSortingId = group.defaultSortingId;
            }

            groupItems.push({
                title: group.title,
                onClick: this.onCategoryClicked,
                payload: group.enum,
                active: isActive
            })
        })

        return (
            <div>
                <div className='flex mt-2 mb-6'>
                    <div className='flex-1 items-center justify-start flex-wrap'>

                        <HorizontalCategoryMenu size='large' items={groupItems} disabled={isSearchMode} />


                        <span className='ml-5'>
                            <HorizontalCategoryMenu disabled={isSearchMode || disableFilterButtons} size='large' items={[
                                {
                                    title: languageStore.get('all'),
                                    payload: false,
                                    active: store.onlyMine !== true,
                                    onClick: this.onResponsibleClicked
                                },
                                {
                                    title: languageStore.get('mine'),
                                    payload: true,
                                    active: store.onlyMine === true,
                                    onClick: this.onResponsibleClicked
                                }
                            ]} />
                        </span>

                        {
                            statuses &&
                            <select
                                defaultValue={-1}
                                style={{ height: '35px' }}
                                className="border ml-5 py-1 w-32 text-green-darker leading-normal rounded bg-transparent"
                                onChange={(e) => this.onStatusChanged(e.target.value as any)}>
                                    <option value="-1">{languageStore.get('active')}</option>
                                {
                                    statuses.map((status, index) => (
                                        <option key={index} value={status.enum}>{status.title}</option>
                                    ))
                                }
                            </select>
                        }

                    </div>
                    <div className='w-1/3 flex items-center justify-end'>
                        <TableSearch store={store} />
                    </div>
                </div>

                {
                    linkContractStore.linkContractIsActive &&
                    <>
                        <h1 className='bg-yellow px-4 py-6 rounded border mb-4 relative'>
                            {languageStore.get('selectContractToLinkTo')}:
                            <MdClose onClick={linkContractStore.resetStore} className='absolute cursor-pointer' style={{ top: '25px', right: '10px' }} />
                        </h1>
                        <Prompt
                            message={(location, action) => {
                                if (
                                    !location.pathname.startsWith(`/${linkContractStore.targetType}`)
                                    && !location.pathname.startsWith(`/${linkContractStore.sourceType}/${linkContractStore.sourceId}`)
                                ) {
                                    linkContractStore.resetStore();
                                }

                                return true;
                            }}
                        />
                    </>
                }

                {
                    linkInboxStore.linkInboxIsActive &&
                    <h1 className='bg-yellow px-4 py-6 rounded border mb-4 relative'>
                        {languageStore.get('selectContractToAddFileTo')}:
                        <MdClose onClick={linkInboxStore.resetSource} className='absolute cursor-pointer' style={{ top: '25px', right: '10px' }} />
                    </h1>
                }

                {
                    isSearchMode &&
                    <TableSearchResults store={store} />
                }

                {
                    !isSearchMode &&
                    <ContractsTable
                        store={store}
                        onSubRowClicked={onSubRowClicked}
                        defaultSortingId={defaultSortingId} />
                }

            </div >
        )
    }

}

export default ContractsPage;