import { useEffect, useState } from 'react';
import { observer } from "mobx-react";
import { languageStore, companyUserStore, userStore, navigationStore, CompanyUserInterface } from 'stores';
import TableHeader from 'components/Table/TableHeader';
import * as _ from 'lodash';
import { formatDate } from 'helpers/DateHelper';
import ShowOnPrint from 'components/ShowOnPrint';
import ReactTable from 'react-table';
import moment from 'moment';

const UsersPage = observer(() => {
    const [searchQuery, setSearchQuery] = useState<string>('');

    useEffect(() => {
        const me = userStore.signedInUser;
        if (!me) return;

        companyUserStore.getUsers(me.company.id);
    }, [])

    const gotoCreateUser = () => {
        navigationStore.push('/admin/users/create');
    }

    const getColumns = () => {
        return [
            {
                Header: <TableHeader value={languageStore.get('name')} />,
                id: 'fullName',
                accessor: (d: CompanyUserInterface) => {
                    return (
                        <span>
                            {d.fullName}
                            {
                                d.disabled &&
                                <span className='font-semibold inline text-red text-xs'><br />{languageStore.get('inactive')}</span>
                            }
                        </span>
                    )
                }
            },
            {
                Header: <TableHeader value={languageStore.get('email')} />,
                accessor: 'email'
            },
            {
                Header: <TableHeader value={languageStore.get('lastLogin')} />,
                id: 'lastLogIn',
                accessor: (d: any) => formatDate(d.lastLogIn),
                sortMethod: (a: string, b: string) => {
                    const dateA = moment(a, 'DD/MM/YYYY', true);
                    const dateB = moment(b, 'DD/MM/YYYY', true);

                    if (dateA.isValid() || dateB.isValid()) {
                        if (!dateA.isValid()) return -1;
                        if (!dateB.isValid()) return 1;

                        return dateA.isAfter(dateB) ? 1 : -1;
                    }
                    else {
                        return a > b ? 1 : -1;
                    }
                }
            }
        ]
    }

    let data = _.orderBy(companyUserStore.users, 'fullName') || [];

    if (searchQuery.length > 2) {
        data = _.filter(data, (u) => {
            return u.fullName.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1
                || u.email.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1
        })
    }

    const columns = getColumns();

    return (
        <div className={`w-full mx-auto`}>

            <div className='flex'>
                <div className='text-left flex-1'>
                    <input
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className='border py-1 pl-2  mr-4 pr-3 text-green-darker leading-normal rounded'
                        id='search'
                        type='text'
                        placeholder={`${languageStore.get('search')}...`}
                    />
                </div>

                <div className='text-right'>
                    <div className='mb-5 text-right inline-block'>
                        <button onClick={() => gotoCreateUser()} className='bg-comablue-label text-white py-2 px-8 rounded'>
                            {languageStore.get('addUser')}
                        </button>
                    </div>
                </div>
            </div>
            <div className='border'>
                <ShowOnPrint>
                    <ReactTable
                        data={data}
                        columns={columns}
                        defaultPageSize={99999}
                        defaultSorted={[{ id: columns[0]!.id as any }]}
                        showPagination={false}
                        NoDataComponent={() => null}
                        getTdProps={(state: any, rowInfo: any, column: any, instance: any) => {
                            return {
                                onClick: (e: React.MouseEvent, handleOriginal: any) => {
                                    const id = rowInfo.original.id;
                                    navigationStore.push(`/admin/users/${id}`);
                                }
                            };
                        }}
                        minRows={0} />
                </ShowOnPrint>
            </div>
        </div>
    );
})

export default UsersPage;