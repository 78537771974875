import * as React from 'react';
import { MdChevronRight } from 'react-icons/md';
import { navigationStore } from 'stores';
import { observer } from 'mobx-react';

interface Props {
    backlinkTo?: string,
    backlinkFunc?: Function,
    backlinkTitle?: string,
    title: string,
    children?: React.ReactNode
}

@observer class Breadcrumb extends React.Component<Props> {

    goto(e: React.MouseEvent) {
        const { backlinkTo, backlinkFunc } = this.props;
        e.preventDefault();
        if (backlinkTo) {
            navigationStore.push(backlinkTo);
        }
        else if (backlinkFunc) {
            backlinkFunc();
        }
    }

    render() {
        const { backlinkTitle, title, children } = this.props;

        return (
            <div>
                <ol className='list-reset'>
                    {
                        backlinkTitle &&
                        <li className='inline-block underline'><a href=" " onClick={(e) => this.goto(e)} className='text-grey-darker no-underline'>{backlinkTitle}</a></li>
                    }

                    <MdChevronRight className='w-5 h-5 absolute text-grey' />
                    <li className='inline-block ml-5'>{title}</li>
                </ol>
                {children}
            </div>
        );
    }

}

export default Breadcrumb;