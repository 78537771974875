import * as React from 'react';
import { observer } from 'mobx-react';
import { FieldState } from 'formstate';
import ContractDropdownItem from 'components/ContractDropdownItem';
import { DropdownOptionInterface } from 'components/Forms/Dropdown';
import { languageStore, categoryStore } from 'stores';
import * as _ from 'lodash';

interface Props {
    fieldState: FieldState<any>,
    fallbackValue?: string,
    mainCategoryId: string,
    editMode?: boolean
}

@observer class SubCategory extends React.Component<Props> {

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        if (nextProps.mainCategoryId !== this.props.mainCategoryId) {
            this.props.fieldState.onChange('');
        }
    }

    render() {
        const { fieldState, fallbackValue, mainCategoryId, editMode } = this.props;

        let availCategories: Array<DropdownOptionInterface> = [];

        const category = _.find(categoryStore.categories, { id: mainCategoryId });
        category && category.subCategories.forEach((subCat) => {
            availCategories.push({
                value: subCat.id,
                name: subCat.name
            })
        });

        return <ContractDropdownItem
            id='subCategory'
            label={languageStore.get('subCategory')}
            placeholderText={languageStore.get('selectSubCategory')}
            fieldState={fieldState}
            options={availCategories}
            editMode={editMode}
            fallbackValue={fallbackValue} />

    }

}

export default SubCategory;