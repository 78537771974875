import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { companyUserStore, DepartmentInterface, departmentStore, languageStore, locationStore, toastStore } from "stores";
import useCompanyUserByParams from "../useCompanyUserByParams";
import _ from 'lodash';

interface DataInterface {
    location: string,
    departments: Array<DepartmentInterface>
}

const Departments = observer(() => {
    const [selectedIds, setSelectedIds] = useState<Array<string>>([]);

    const user = useCompanyUserByParams();

    useEffect(() => {
        if (user) {
            departmentStore.getDepartmentsByCompany(user!.companyId);
            locationStore.getLocationsByCompany(user!.companyId);
            setSelectedIds(user!.departments.map((d) => d.id));
        }
    }, [user])

    const deleteFromDepartment = (e: React.MouseEvent, department: DepartmentInterface) => {
        e.preventDefault();
        setSelectedIds(selectedIds.filter((depId) => depId !== department.id));
    }

    const addToDepartment = (e: React.MouseEvent, department: DepartmentInterface) => {
        e.preventDefault();

        const depIds = _.clone(selectedIds);
        depIds.push(department.id);
        setSelectedIds(depIds);

    }

    const onSave = async () => {
        const departments = departmentStore.departments.filter((d) => selectedIds.indexOf(d.id) !== -1);
        await companyUserStore.setDepartments(user!.companyId, user!.id, departments);

        toastStore.addToast({
            level: 'success',
            title: languageStore.get('success'),
            message: languageStore.get('changesSaved')
        })
    }

    if (!user) return null;

    const departments = departmentStore.departments || [];
    const locations = _.orderBy(locationStore.locations, 'name') || [];

    const data: Array<DataInterface> = [];
    locations.forEach((location) => {
        const deps = departments.filter((dep) => dep.locationId === location.id);
        if (deps.length > 0) {
            data.push({
                location: location.name,
                departments: deps
            })
        }
    })

    return (
        <div>
            {
                data.map((entity, index) => {

                    return (
                        <div key={index} className='mb-8'>
                            <h3>
                                {entity.location}
                            </h3>
                            {
                                entity.departments.map((department, index) => {
                                    const hasItem = selectedIds.indexOf(department.id) !== -1;
                                    
                                    let className = 'mt-2';
                                    if (!hasItem) {
                                        className += ' text-grey';
                                    }

                                    return (<div key={index} className={className}>
                                        {department.name}

                                        {
                                            hasItem &&
                                            <button className='ml-2 px-2 py-1 text-xs text-red rounded bg-red-lightest hover:bg-red-lighter' onClick={(e) => deleteFromDepartment(e, department)}>{languageStore.get('remove')}</button>
                                        }

                                        {
                                            !hasItem &&
                                            <button className='ml-2 px-2 py-1 text-xs text-green rounded bg-green-lightest hover:bg-green-lighter' onClick={(e) => addToDepartment(e, department)}>{languageStore.get('add')}</button>
                                        }
                                    </div>)
                                })
                            }
                        </div>
                    )
                })
            }

            <div className='mt-8'>
                <button type="submit" onClick={(e) => onSave()} className="bg-comablue-label hover:bg-comablue text-white py-2 px-8 rounded">
                    {languageStore.get('saveChanges')}
                </button>
            </div>
        </div>
    )
})

export default Departments;