import * as React from 'react';
import { observer } from 'mobx-react';
import { FieldState } from 'formstate';
import { ContractDocumentInterface, documentStore, DocumentUrlInterface, languageStore } from 'stores';
import UploadFile from 'components/Forms/UploadFile';

interface Props {
    label: string,
    fieldState: FieldState<any>,
    id?: string,
    editMode?: boolean,
    picture?: ContractDocumentInterface,
    onImageUploaded: (document: ContractDocumentInterface | null) => void
}

interface State {
    pictureUrl: string | null
}

@observer class ContractImageItem extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            pictureUrl: null
        }
    }

    componentDidMount() {
        const { picture } = this.props;

        if (picture) {
            this.getImageUrl(picture.minioName);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        if (nextProps.picture && nextProps.picture.id !== this.props.picture?.id) {
            this.getImageUrl(nextProps.picture.minioName);
        }
    }

    getImageUrl = async (minioName: string) => {
        const url = await documentStore.getDocumentUrl(minioName) as DocumentUrlInterface;
        this.setState({
            pictureUrl: url.url
        })
    }

    uploadImage = (document: ContractDocumentInterface | null) => {
        const { fieldState, onImageUploaded } = this.props;

        fieldState.onChange(document?.id || null);
        this.setState({ pictureUrl: null });
        onImageUploaded(document || null);
    }

    render() {
        const { label, fieldState, id, editMode } = this.props;
        const { pictureUrl } = this.state;


        if (editMode && fieldState && id) {
            return (
                <>
                    <dt className='mt-2 pb-2 text-sm text-comablue-label font-semibold truncate'>
                        {label}
                        {
                            pictureUrl &&
                            <span className='ml-1'>
                                (<button
                                    onClick={() => this.uploadImage(null)}
                                    type='button'
                                    className='underline'>
                                    {languageStore.get('delete')}
                                </button>)
                            </span>
                        }
                    </dt>
                    <UploadFile allowedFileTypes={['png', 'jpg', 'jpeg']} onSubmit={this.uploadImage}>
                        <dd className='pt-2 pl-2 border rounded cursor-pointer' style={{ height: '42px' }}>
                            {
                                pictureUrl &&
                                <img src={pictureUrl} alt="" style={{ maxHeight: '26px' }} />
                            }
                        </dd>
                    </UploadFile>
                </>
            )
        }

        if (pictureUrl) {
            return (
                <dd className='mt-4 pt-1 break-words'>
                    {
                        pictureUrl &&
                        <a href={pictureUrl} target='_blank' rel='noreferrer'>
                            <img src={pictureUrl} alt="" style={{ height: '92px' }} />
                        </a>

                    }

                    {!pictureUrl && '-'}
                </dd>
            )
        }
        else {
            return (
                <>
                    <dt className='mt-5 text-sm text-comablue-label font-semibold truncate'>
                        {label}
                    </dt>
                    <dd className='pt-1 break-words'>
                        -
                    </dd>
                </>
            )
        }
    }
}

export default ContractImageItem;