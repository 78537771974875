import * as React from 'react';
import { observer } from 'mobx-react';
import { languageStore, ContractFinanceAccountInterface, financeAccountStore, FinanceAccountInterface, CurrentContractStore } from 'stores';
import { ModalColumn } from 'components/Modal';
import Input from 'components/Forms/Input';
import { FormState, FieldState } from 'formstate';
import * as _ from 'lodash';
import SelectStatus from './SelectStatus';
import SelectAccount from './SelectAccount';
import { required } from 'helpers/FormValidation';
import TableGreenHeader from 'components/Table/TableGreenHeader';
import BaseContractTable from 'components/StaffContractDetailsPage/BaseContractTable';
import { reaction, IReactionDisposer } from 'mobx';
import numeral from 'numeral';
interface Props {
    onCreate: Function,
    onUpdate: Function,
    onDelete: Function,
    financeAccounts: Array<ContractFinanceAccountInterface>,
    store: CurrentContractStore<any>,
    hash: string
}

@observer class FinanceAccount extends React.Component<Props> {

    form = new FormState({
        id: new FieldState<string | null>(null),
        financeAccountId: new FieldState('').validators(required),
        use: new FieldState(''),
        costEstimate: new FieldState(''),
        actualCost: new FieldState(''),
        financeAccountStatus: new FieldState('').validators(required)
    })

    reactionDisposer: IReactionDisposer | any;

    componentDidMount() {
        const { store } = this.props;

        this.reactionDisposer = reaction(() => store.currentLocationId, (locationId) => {
            if (locationId) {
                financeAccountStore.getAccountsByLocationId(locationId);
            }
        })
    }

    componentWillUnmount() {
        this.reactionDisposer();
    }

    formatNumber = (value: any) => {
        
        if(!value)
        {
            return "";   
        }

        const parsedValue = parseFloat(String(value));
        var number = numeral(parsedValue);
        return number.format("0,0.00")
    }

    render() {
        const { financeAccounts, onCreate, onUpdate, onDelete, store, hash } = this.props;
        const fAccounts = financeAccounts || [];
        const accounts = financeAccountStore.accounts || [];
        const form = this.form.$;

        const columns = [
            {
                Header: <TableGreenHeader value={languageStore.get('accountTitle')} />,
                id: 'accTitle',
                accessor: (d: ContractFinanceAccountInterface) => {
                    if (d.financeAccount && d.financeAccount.accTitle) {
                        return d.financeAccount.accTitle
                    }
                    
                    const acc = _.find(accounts, { id: d.financeAccountId }) as FinanceAccountInterface;
                    return acc ? acc.accTitle : '';
                }
            },
            {
                Header: <TableGreenHeader value={languageStore.get('use')} />,
                accessor: 'use'
            },
            {
                Header: <TableGreenHeader value={languageStore.get('estimatedCost')} />,
                id: 'costEstimate',
                accessor: (d: ContractFinanceAccountInterface) => {
                   return d.costEstimate ? this.formatNumber(d.costEstimate) : "";
                }
            },
            {
                Header: <TableGreenHeader value={languageStore.get('actualCost')} />,
                id: 'actualCost',
                accessor: (d: ContractFinanceAccountInterface) => {
                    return d.actualCost ? this.formatNumber(d.actualCost) : "";
                 }
            },
            {
                Header: <TableGreenHeader value={languageStore.get('status')} />,
                id: 'status',
                accessor: (d: any) => d.financeAccountStatus === 1 ? languageStore.get('active') : languageStore.get('inactive')
            },
        ]

        return (
            <BaseContractTable
                form={this.form}
                onSubmit={(account: FinanceAccountInterface) => onCreate(account)}
                onUpdate={(account: FinanceAccountInterface) => onUpdate(account)}
                onDelete={(account: FinanceAccountInterface) => onDelete(account)}
                collection={fAccounts}
                columns={columns}
                tableTitle={languageStore.get('financeAccount')}
                modalTitle={languageStore.get('financeAccount')}
                store={store}
                hash={hash}>

                <ModalColumn>

                    <SelectAccount
                        store={store}
                        fieldState={form.financeAccountId} />

                    <Input
                        fieldState={form.use}
                        id='use'
                        label={languageStore.get('use')} />

                    <Input
                        fieldState={form.costEstimate}
                        id='costEstimate'
                        type='number'
                        label={languageStore.get('estimatedCost')} />

                    <Input
                        fieldState={form.actualCost}
                        id='actualCost'
                        type='number'
                        label={languageStore.get('actualCost')} />

                    <SelectStatus
                        fieldState={form.financeAccountStatus} />

                </ModalColumn>

            </BaseContractTable>
        )

    }

}

export default FinanceAccount;