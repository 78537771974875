interface Props {
    onClick: () => void,
    label: string,
    className?: string
}

const DefaultButton = (props: Props) => {

    return (
        <button 
            className={`bg-comablue-label text-white py-2 px-8 rounded ${props.className || ''}`}
            onClick={() => props.onClick()}>
            {props.label}
        </button>
    )
}

export default DefaultButton;