import * as React from 'react';
import ReactTable from 'react-table';
import { observer } from 'mobx-react';
import { languageStore, navigationStore, BaseContractsStore, linkContractStore } from 'stores';
import 'react-table/react-table.css';
import columnHelper from 'components/ContractsTable/SubTable/ColumnHelper';
import TableHeader from 'components/Table/TableHeader';
import { redirectToSourceContractIfActive } from 'components/ContractToContract/ContractToContractHelper';
import ShowOnPrint from 'components/ShowOnPrint';

interface Props {
    store: BaseContractsStore<any>
}

@observer class TableSearchResults extends React.Component<Props> {

    gotoContractDetails(id: string) {
        const { store } = this.props;

        if (linkContractStore.linkContractIsActive) {
            redirectToSourceContractIfActive(store.contractEndpoint, id);
            return;
        }

        navigationStore.push(`/${store.contractEndpoint}/${id}`);
    }

    componentWillUnmount() {
        // Reset advanced search when navigating to other page (this component will be unmounted)
        const { store } = this.props;
        if (store.searchQueryIsJsonFormat) {
            this.props.store.search('', 1);
        }
    }

    render() {
        const { store } = this.props;
        const filteredContracts = store.filteredContracts;
        if (!filteredContracts) return null;

        const columns = columnHelper.getColumnsByView(store.viewBy, store.contractEndpoint).slice();

        columns.push({
            id: 'tags',
            Header: <TableHeader value={languageStore.get('tags')} />,
            accessor: (d: any) => {
                const tags = d.tags;
                if (!tags) return null;

                return tags.split(', ');
            }
        })

        return (
            <ShowOnPrint>
                <ReactTable
                    data={filteredContracts}
                    columns={columns}
                    showPagination={false}
                    sortable={true}
                    NoDataComponent={() => <p className='mt-10 text-center'>{languageStore.get('noContractsFound')}</p>}
                    minRows={0}
                    defaultPageSize={99999}
                    getTdProps={(state: any, rowInfo: any, column: any, instance: any) => {
                        return {
                            onClick: (e: React.MouseEvent, handleOriginal: any) => {
                                this.gotoContractDetails(rowInfo.original.id);
                            }
                        };
                    }}
                />
                {
                    store.searchHasMorePages &&
                    <div className='text-center'>
                        <button
                            type='button'
                            onClick={() => store.search(store.searchQuery || '', 9999)}
                            className='
                                text-center
                                mt-16
                                border rounded
                                px-4 py-1
                                bg-grey-lightest
                            '>
                            {languageStore.get('loadMore')}...
                        </button>
                    </div>

                }
            </ShowOnPrint>
        );
    }

}

export default TableSearchResults;