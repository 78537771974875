import * as React from 'react';
import { observer } from 'mobx-react';
import logoImg from 'components/Forms/Login/logoColor.svg';
import Form from 'components/Forms/Form';
import { FormState, FieldState } from 'formstate';
import { validPassword, required } from 'helpers/FormValidation';
import Input from 'components/Forms/Input';
import { languageStore, authStore, navigationStore } from 'stores';
import PasswordRequirements from 'components/PasswordRequirements';

@observer class ResetPasswordPage extends React.Component<any> {

    form = new FormState({
        password: new FieldState('').validators(required, validPassword),
        repeatPassword: new FieldState('').validators(required, validPassword),
    })

    onSubmit = async () => {
        const form = this.form.$;
        const password = form.password.$;
        const repeatPassword = form.repeatPassword.$;

        if (password !== repeatPassword) {
            alert(languageStore.get('passwordsMustMatch'));
            this.form.reset();
            return;
        }

        const token = this.props.match.params.token;

        await authStore.setPassword(token, password);

        navigationStore.replace(authStore.loginPage);
    }

    render() {
        const form = this.form.$;

        return (
            <div className="font-sans text-sm rounded w-full max-w-xs mx-auto mt-20 px-8 pt-8 pb-8">
                <img src={logoImg} alt="COMA System" className="mb-12" />

                <Form onValidSubmit={this.onSubmit} formState={this.form}>

                    <Input fieldState={form.password} id='email' type='password' label={languageStore.get('newPassword')} />
                    <Input fieldState={form.repeatPassword} id='repeatEmail' type='password' label={languageStore.get('repeatPassword')} />

                    <div className="text-center">
                        <button type="submit" className="bg-comablue-label hover:bg-black text-white py-2 px-8 rounded">
                            {languageStore.get('Reset password')}
                        </button>

                        <PasswordRequirements currentValue={form.password.value} />

                    </div>
                </Form>
            </div>
        )
    }

}

export default ResetPasswordPage;