import * as React from 'react';
import { observer } from 'mobx-react';
import ContractSection from 'components/ContractSection';
import { languageStore, CurrentContractStore, CurrentContractBaseInterface } from 'stores';

interface Props {
    store: CurrentContractStore<CurrentContractBaseInterface>,
    hash: string,
    title: string,
    onAdd: Function,
    hideAdd?: boolean
}

@observer class EmptyContractSection extends React.Component<Props> {

    render() {
        const { store, hash, title, onAdd, hideAdd } = this.props; 

        return (
            <ContractSection
                hideSaveButton={true}
                editMode={store && store.canEdit ? true : false}
                hash={hash}
                noFlex={true}>
                {
                    (store && store.canEdit && !hideAdd) &&
                    <button className='px-4 py-2 border rounded text-grey-dark hover:bg-grey-lightest' onClick={() => onAdd()}>{languageStore.get('add')} {title}</button>
                }
                {
                    (hideAdd || (store && !store.canEdit)) &&
                    <p>
                        {languageStore.get('none')} {title}
                    </p>
                }

            </ContractSection>
        )
    }

}

export default EmptyContractSection;
