import { BaseStore } from './BaseStore';
import { observable, action, makeObservable } from 'mobx';
import { LocationInterface } from './LocationStore';

export interface BenefitInterface {
    "id": string,
    "name": string,
    "locationId": string,
    "location": LocationInterface
}

export class BenefitStore extends BaseStore {
    @observable benefits: Array<BenefitInterface> = [];
    @observable allBenefits: Array<BenefitInterface> = [];

    constructor() {
        super();

        makeObservable(this);
    }

    @action
    getBenefits(locationId: string) {
        this.get(`locations/${locationId}/benefits`, 'benefits');
    }

    @action
    getAllBenefits() {
        this.get('benefits', 'allBenefits');
    }

    @action
    createBenefit(benefit: BenefitInterface) {
        return this.create(`locations/${benefit.locationId}/benefits`, benefit, this.allBenefits);
    }

    @action
    updateBenefit(benefit: BenefitInterface) {
        return this.update(`locations/${benefit.locationId}/benefits/${benefit.id}`, benefit, this.allBenefits);
    }

    @action
    deleteBenefit(locationId: string, benefitId: string) {
        return this.delete(`locations/${locationId}/benefits/${benefitId}`, benefitId, this.allBenefits);
    }

    @action
    resetStore() {
        this.benefits = [];
        this.allBenefits = [];
    }
}

export const benefitStore = new BenefitStore();