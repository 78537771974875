import { BaseStore } from './BaseStore';
import { observable, action, makeObservable } from 'mobx';
import request from 'api/request';
import { UserInterface } from './UserStore';
import { UserContractsInterface } from './CompanyUserStore';
import { RoleInterface } from './RolesStore';

export class CreateUserStore extends BaseStore {
    @observable user: UserInterface | null = null;

    constructor() {
        super();

        makeObservable(this);
    }

    @action
    createUser(companyId: string, user: UserInterface) {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await request.post(`companies/${companyId}/users`, user);
                const resUser = res.data;
                this.user = resUser;
                resolve(resUser);
            }
            catch (error) {
                reject(error);
            }
        })
    }

    putLocations(companyId: string, userId: string, locations: Array<any>) {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await request.put(`companies/${companyId}/users/${userId}/locations`, locations);
                resolve(res.data);
            }
            catch (error) {
                reject(error);
            }
        })
    }

    putDepartments(companyId: string, userId: string, departments: Array<any>) {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await request.put(`companies/${companyId}/users/${userId}/departments`, departments);
                resolve(res.data);
            }
            catch (error) {
                reject(error);
            }
        })
    }

    putContracts(companyId: string, userId: string, contracts: Array<UserContractsInterface>) {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await request.put(`companies/${companyId}/users/${userId}/usercontracts`, contracts);
                resolve(res.data);
            }
            catch (error) {
                reject(error);
            }
        })
    }

    putRoles(companyId: string, userId: string, roles: Array<RoleInterface>) {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await request.put(`companies/${companyId}/users/${userId}/roles`, roles);
                resolve(res.data);
            }
            catch (error) {
                reject(error);
            }
        })
    }
}

export const createUserStore = new CreateUserStore();