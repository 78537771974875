import TableHeader from 'components/Table/TableHeader';
import { StaffContractStatusEnum, languageStore, AppraisalInterviewStatusEnum, StaffContractDetailsStatusEnum, userStore } from 'stores';
import { formatDate } from 'helpers/DateHelper';
import Moment from 'moment-timezone';

export function getStaffInterviewColumns() {
    return [
        {
            id: 'fullName',
            Header: <TableHeader value={languageStore.get('name')} />,
            accessor: 'heldWithUser.fullName'
        },
        {
            id: 'heldOn',
            Header: <TableHeader value={languageStore.get('interviewDate')} />,
            accessor: (d: any) => formatDate(d.heldOn)
        },
        {
            id: 'status',
            Header: <TableHeader value={languageStore.get('status')} />,
            accessor: (d: any) => {
                switch (d.appraisalInterviewStatus) {
                    case AppraisalInterviewStatusEnum.SCHEDULED: return languageStore.get('scheduled');
                    case AppraisalInterviewStatusEnum.HELD: return languageStore.get('held');
                    case AppraisalInterviewStatusEnum.DELETED: return languageStore.get('deleted');
                    default: return null;
                }
            }
        }
    ]
}

export function getStaffEquipmentColumns() {
    return [
        {
            id: 'fullName',
            Header: <TableHeader value={languageStore.get('name')} />,
            accessor: 'fullName'
        },
        {
            id: 'handedOut',
            Header: <TableHeader value={languageStore.get('handedOut')} />,
            accessor: (d: any) => formatDate(d.handedOut)
        },
        {
            id: 'creatorName',
            Header: <TableHeader value={languageStore.get('creator')} />,
            accessor: 'creatorName'
        },
        {
            id: 'status',
            Header: <TableHeader value={languageStore.get('status')} />,
            accessor: (d: any) => {
                switch (d.staffContractDetailsStatus) {
                    case StaffContractDetailsStatusEnum.AGREED: return languageStore.get('agreed');
                    case StaffContractDetailsStatusEnum.COMPLETED: return languageStore.get('completed');
                    case StaffContractDetailsStatusEnum.FOR_RENEWAL: return languageStore.get('forRenewal');
                    case StaffContractDetailsStatusEnum.DELETED: return languageStore.get('deleted');
                    default: return null;
                }
            }
        }


    ]
}

export function getStaffBenefitsColumns() {
    return [
        {
            id: 'fullName',
            Header: <TableHeader value={languageStore.get('name')} />,
            accessor: 'fullName'
        },
        {
            id: 'handedOut',
            Header: <TableHeader value={languageStore.get('deliveryDate')} />,
            accessor: (d: any) => formatDate(d.handedOut)
        },
        {
            id: 'creatorName',
            Header: <TableHeader value={languageStore.get('createdBy')} />,
            accessor: 'createdBy.fullName'
        },
        {
            id: 'status',
            Header: <TableHeader value={languageStore.get('status')} />,
            accessor: (d: any) => {
                switch (d.staffContractDetailsStatus) {
                    case StaffContractDetailsStatusEnum.AGREED: return languageStore.get('agreed');
                    case StaffContractDetailsStatusEnum.COMPLETED: return languageStore.get('completed');
                    case StaffContractDetailsStatusEnum.FOR_RENEWAL: return languageStore.get('forRenewal');
                    case StaffContractDetailsStatusEnum.DELETED: return languageStore.get('deleted');
                    default: return null;
                }
            }
        }
    ]
}

export function getStaffColumns() {
    return [
        {
            Header: <TableHeader value={languageStore.get('daysToRenegotiation')} />,
            id: 'daysToRenegotiation',
            accessor: (d: any) => {
                const renegotiationDate = d.renegotiationDate;
                if (!renegotiationDate) return null;
                let timeZone = userStore.signedInUser!.timeZone.id;
                const days = Moment(renegotiationDate).utc().tz(timeZone).diff(Moment().utc().tz(timeZone), 'days');
                if (days < 1) {
                    return <span className='text-red'>{days}</span>
                }
                else return days;
            }            
        },
        {
            Header: <TableHeader value={languageStore.get('renegotiationDate')} />,
            id: 'renegotiationDate',
            accessor: (d: any) => formatDate(d.renegotiationDate)
        },
        {
            Header: <TableHeader value={languageStore.get('name')} />,
            id: 'name',
            accessor: 'name'
        },
        {
            Header: <TableHeader value={languageStore.get('department')} />,
            id: 'departmentName',
            accessor: 'departmentName'
        },
        {
            Header: <TableHeader value={languageStore.get('employmentDate')} />,
            id: 'employmentDate',
            accessor: (d: any) => formatDate(d.employmentDate)
        },
        {
            Header: <TableHeader value={languageStore.get('agreement')} />,
            id: 'staffAgreementName',
            accessor: 'staffAgreementName'
        },
        {
            Header: <TableHeader value={languageStore.get('status')} />,
            id: 'contractStatus',
            accessor: (d: any) => {
                switch (d.contractStatus) {
                    case StaffContractStatusEnum.Hired: return languageStore.get('hired');
                    case StaffContractStatusEnum.Dismissed: return languageStore.get('dismissed');
                    case StaffContractStatusEnum.Rejected: return languageStore.get('rejected');
                    case StaffContractStatusEnum.Resigned: return languageStore.get('resigned');
                    case StaffContractStatusEnum.Cancelled: return languageStore.get('cancelled');
                    case StaffContractStatusEnum.Applicant: return languageStore.get('applicant');
                    case StaffContractStatusEnum.Draft: return languageStore.get('draft');
                    default: return '-';
                }

            }
        },
        {
            Header: <TableHeader value={languageStore.get('location')} />,
            id: 'locationName',
            accessor: 'locationName'
        }
    ]
}

