import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { LocationInterface, locationStore, userStore } from "stores";
import _ from 'lodash';

const useCompanyLocationByParams = () => {
    const [location, setLocation] = useState<null | LocationInterface>(null);
    const { id } = useParams<{ id: string }>();

    useEffect(() => {
        const getLocation = async() => {
            const me = userStore.signedInUser;
            await locationStore.getLocationsByCompany(me.company.id);
        }

        getLocation();
    }, [id])

    useEffect(() => {
        const loc = _.find(locationStore.locations, { id: id });
        setLocation(loc || null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locationStore.locations])

    return location;
}

export default useCompanyLocationByParams;