import { Modal, ModalColumn } from "components/Modal";
import { ContractDocumentInterface, languageStore, toastStore } from "stores";
import _ from 'lodash';
import { FieldState, FormState } from "formstate";
import SelectTrueFalse from "components/Forms/SelectTrueFalse";
import { useEffect, useState } from "react";
import Form from "components/Forms/Form";

interface Props {
    onCancel: () => void,
    documents: Array<ContractDocumentInterface>,
    onDocumentsSelected: (documents: Array<ContractDocumentInterface>) => void
}

const SelectDocuments = (props: Props) => {
    const [formState, setFormState] = useState(new FormState({}));

    const { documents } = props;
    useEffect(() => {
        const form = new FormState({});
        documents.forEach((document, index) => {
            // We only support signing of PDFs and documents, so dont render if none of these
            const ext = document.extension.toLowerCase();
            if (ext.indexOf('pdf') === -1 && ext.indexOf('doc') === -1 && ext.indexOf('docx') === -1) {
                return;
            }

            form.$[document.id] = new FieldState(false)
        })
        setFormState(form);

    }, [documents])

    const onSubmit = () => {
        const selectedDocuments: Array<ContractDocumentInterface> = [];

        Object.keys(formState.$).forEach((documentId) => {
            if (!formState.$[documentId].$) return;

            const document = _.find(props.documents, (d) => d.id === documentId) as ContractDocumentInterface;
            selectedDocuments.push(document);
        })

        if (selectedDocuments.length > 0) {
            props.onDocumentsSelected(selectedDocuments);
        }
        else {
            toastStore.addError(languageStore.get('noDocumentsSelected'));
        }
    }

    if (Object.keys(formState.$).length === 0) return null;

    let data = _.filter(documents, (document) => {
        const ext = document.extension.toLowerCase();
        const documentIsNotSignableType = (ext.indexOf('pdf') === -1 && ext.indexOf('doc') === -1 && ext.indexOf('docx') === -1);
        if (documentIsNotSignableType) {
            return false
        }
        else return true;
    });

    data = _.orderBy(data, (d) => d.fileName.toLowerCase());

    return (
        <Form onValidSubmit={onSubmit} formState={formState} preventResubmitMilliseconds={500}>
            <Modal
                forceWide={true}
                title={languageStore.get('selectDocumentsToBeSigned')}
                primaryButton={{ title: languageStore.get('continue') }}
                secondaryButton={{ title: languageStore.get('cancel'), onClick: props.onCancel }}>

                <ModalColumn>
                    <ul className='list-reset whitespace-no-wrap overflow-hidden'>
                        {
                            data.map((document, index) => {
                                return (
                                    <li key={index} style={{ marginTop: '-40px' }}>
                                        <SelectTrueFalse
                                            editMode={true}
                                            label={document.fileName}
                                            fieldState={formState.$[document.id]}
                                            id={document.id} />
                                    </li>
                                )
                            })
                        }
                    </ul>
                </ModalColumn>

            </Modal>
        </Form>
    )

}

export default SelectDocuments;