import * as React from 'react';
import { observer } from 'mobx-react';
import { FieldState } from 'formstate';
import Dropdown, { DropdownOptionInterface } from 'components/Forms/Dropdown';
import * as _ from 'lodash';

interface Props {
    label: string,
    value?: string,
    fieldState: FieldState<any>,
    id?: string,
    editMode?: boolean,
    placeholderText?: string,
    options?: Array<DropdownOptionInterface>,
    fallbackValue?: string,
    skipSort?: boolean,
    type?: 'text' | 'number',
    disabled?: boolean,
    beforeChange?: Function,
    className?: string,
    multiple?: boolean
}

@observer class ContractDropdownItem extends React.Component<Props> {

    render() {
        const { label, disabled, className, beforeChange, fieldState, id, editMode, placeholderText, options, fallbackValue, skipSort, type, multiple } = this.props;

        if (editMode && id) {
            
            return <Dropdown
                className={`mt-8 ${className || ''}`}
                fieldState={fieldState}
                placeholderText={placeholderText}
                id={id}
                label={label}
                skipSort={skipSort}
                multiple={multiple}
                type={type}
                disabled={disabled}
                beforeChange={beforeChange}
                options={options || []} />

        }

        let readableValue = fallbackValue || '-';
        if (fieldState.value && options) {
            var option: any;
            if(multiple)
            {
                option = fieldState.value.split([";"]).join(", ");
            }
            else
            {
                option = _.find(options, { value: fieldState.value })
            }
            
            if (option) {
                if(multiple)
                {
                    readableValue = option;
                }
                else
                {
                    let o = option as DropdownOptionInterface;
                    readableValue = o.name;
                }
                
            }
        }

        return (
            <>
                <dt className='mt-5 text-sm text-comablue-label font-semibold truncate'>{label}</dt>
                <dd className='pt-1 break-words'>{readableValue}</dd>
            </>
        )
    }

}

export default ContractDropdownItem;