import * as React from 'react';
import { MdClose } from 'react-icons/md';
import { BaseContractsStore, languageStore } from 'stores';
import { observer } from 'mobx-react';

interface Props {
    store: BaseContractsStore<any>
}

@observer class TableSearch extends React.Component<Props> {

    inputRef: any;
    PAGE_SIZE = 99;

    constructor(props: any) {
        super(props);

        this.inputRef = React.createRef();
    }

    componentDidMount() {
        const { store } = this.props;

        const searchQuery = store.searchQuery;
        if (searchQuery) {
            if (!store.searchQueryIsJsonFormat) {
                this.inputRef.current.value = searchQuery;
            }
            store.search(searchQuery, this.PAGE_SIZE);
        }
    }

    onSearch = () => {
        const searchValue = this.inputRef.current.value;

        const { store } = this.props;
        store.search(searchValue, this.PAGE_SIZE);
    }

    onClearSearch = () => {
        const { store } = this.props;
        store.search('', this.PAGE_SIZE);
        this.inputRef.current.value = '';
    }

    render() {
        const { store } = this.props;

        if (store.searchQueryIsJsonFormat) {
            return (
                <div className="w-full text-right cursor-pointer mb-12" style={{ marginRight: '28px' }} onClick={() => store.search('', this.PAGE_SIZE)}>
                    <p className="py-2 px-4 text-white rounded inline-block" style={{ lineHeight: '22px', background: '#e4002b' }}>
                        {languageStore.get('advancedSearchEnabled')}. {languageStore.get('clickHereToViewAllContracts')}
                    </p>
                    <MdClose className='inline-block w-6 h-6 absolute ml-2 cursor-pointer' style={{ marginTop: '8px', color: '#eb5286' }} />
                </div>
            )
        }
        else {
            return (
                <div className='w-1/2 flex'>
                    <div className='inline-block border rounded appearance-none w-full relative'>
                        <input ref={this.inputRef} className='text-sm w-full py-2 pl-2 pr-6 text-green-darker leading-normal rounded' id='search' type='text' placeholder={`${languageStore.get('searchContracts')}...`} />
                        {
                            store.searchQuery &&
                            <MdClose
                                onClick={() => this.onClearSearch()}
                                className='inline-block w-6 h-6 absolute cursor-pointer'
                                style={{ marginTop: '7px', color: '#545454', right: '0' }}
                            />
                        }
                    </div>
                    <button
                        onClick={() => this.onSearch()}
                        type='button'
                        className='
                            ml-1
                            right-0
                            px-3
                            rounded
                            bg-comablue-label
                            text-white
                            text-sm
                    '>{languageStore.get('search')}</button>
                </div>

            )
        }
    }

}

export default TableSearch;