import * as React from 'react';
import { observer } from 'mobx-react';
import { CurrentContractStore, CurrentContractBaseInterface, languageStore, ContractParentInterface, navigationStore } from 'stores';

interface Props {
    store: CurrentContractStore<CurrentContractBaseInterface>
}

@observer class PrimaryContractLinks extends React.Component<Props> {

    redirect (endpoint: string, contractId: string) {
        navigationStore.push(`/${endpoint}/${contractId}`, () => {
            window.location.reload();
        })
    }

    gotoContract = (e: React.MouseEvent, entity: ContractParentInterface) => {
        e.preventDefault();

        if (entity.purchaseContractId) this.redirect('purchase', entity.purchaseContractId);
        else if (entity.staffContractId) this.redirect('staff', entity.staffContractId);
        else if (entity.salesContractId) this.redirect('sales', entity.salesContractId);
        else if (entity.serviceContractId) this.redirect('service', entity.serviceContractId);
        else return;
    }

    getReadableContractType = (entity: ContractParentInterface) => {
        if (entity.purchaseContractId) return languageStore.get('purchaseContract');
        else if (entity.staffContractId) return languageStore.get('staffContract');
        else if (entity.salesContractId) return languageStore.get('salesContract');
        else if (entity.serviceContractId) return languageStore.get('serviceContract');
        else return null;
    }

    getReadableContractIdentification = (entity: ContractParentInterface) => {
        if (entity.purchaseContractId) return entity.purchaseContract.identification;
        else if (entity.staffContractId) return entity.staffContract.identification;
        else if (entity.salesContractId) return entity.salesContract.identification;
        else if (entity.serviceContractId) return entity.serviceContract.identification;
        else return null;
    }

    render() {
        const { store } = this.props;
        if (!store.contract) return null;

        const parents = store.contract.parents;
        if (!parents || parents.length === 0) return null;

        return (
            <div>
                <p className='inline-block'>
                    {languageStore.get('primaryContract')}:
                </p>

                {
                    parents.map((parent, index) => {
                        return (
                            <a onClick={(e) => this.gotoContract(e, parent)} key={index} href=" " className='ml-2 mt-2 py-1 px-4 text-white rounded bg-red inline-block no-underline'>
                                {this.getReadableContractType(parent)} ({this.getReadableContractIdentification(parent)})
                            </a>
                        )
                    })
                }

            </div>
        )
    }

}

export default PrimaryContractLinks;