import * as React from 'react';
import { observer } from 'mobx-react';
import { languageStore, superAdminStore, toastStore } from 'stores';

interface Props {
    companyId: string
}

@observer class SyncElasticSearchButton extends React.Component<Props> {

    onClick = () => {
        const { companyId } = this.props;
        superAdminStore.resyncElasticSeach(companyId);
        toastStore.addToast({
            level: 'success',
            title: 'success',
            message: languageStore.get('completed')
        })
    }

    render() {
        return (
            <button 
                onClick={this.onClick} 
                className='hover:bg-grey-lightest mt-4 px-4 py-2 border rounded ml-2'>
                    {languageStore.get('syncElasticSearch')}
                </button>
        )
    }

}

export default SyncElasticSearchButton;