import * as React from 'react';
import { observer } from 'mobx-react';
import { FieldState } from 'formstate';
import { DropdownOptionInterface } from 'components/Forms/Dropdown';
import ContractDropdownItem from 'components/ContractDropdownItem';
import { languageStore as lanStore, userStore, CurrentContractStore } from 'stores';
import { departmentStore } from 'stores';

interface Props {
    fieldState: FieldState<any>,
    fallbackValue?: string,
    editMode?: boolean,
    locationId?: string | null,
    disabled?: boolean,
    store?: CurrentContractStore<any>,
}

@observer class Department extends React.Component<Props> {

    constructor(props: Props) {
        super(props);

        // 
        // LocationId is widely used across the contractpage, so
        // everytime the value changes in component, set it as a property
        // in store
        //
        const fieldState = props.fieldState;
        const store = props.store;
        if (store) {
            store.setCurrentDepartmentId(fieldState.value);
            fieldState.onUpdate((fs: any) => {
                store.setCurrentDepartmentId(fs.value);
            })
        }

    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        if (nextProps.locationId !== this.props.locationId) {
            this.props.fieldState.onChange('');
        }

        if (nextProps.fieldState.value !== this.props.fieldState.value) {
            const { store } = this.props;
            if (store) {
                store.setCurrentDepartmentId(nextProps.fieldState.value);
            }

        }
    }

    componentWillUnmount() {
        const { store } = this.props;
        if (store) {
            store.resetCurrentDepartmentId();
        }
    }

    componentDidMount() {
        const me = userStore.signedInUser;
        
        departmentStore.getDepartmentsByUser(me.company.id, me.id);
    }

    render() {
        const { fieldState, disabled, fallbackValue, editMode, locationId } = this.props;

        const availDepartments: Array<DropdownOptionInterface> = [];
        departmentStore.departments && departmentStore.departments.forEach((dep) => {
            if (locationId && dep.locationId === locationId) {
                availDepartments.push({
                    value: dep.id,
                    name: dep.name
                })
            }
        })

        return (
            <ContractDropdownItem
                label={lanStore.get('department')}
                id='department'
                fieldState={fieldState}
                placeholderText={lanStore.get('selectDepartment')}
                options={availDepartments}
                editMode={editMode}
                disabled={disabled}
                fallbackValue={fallbackValue} />
        )
    }

}

export default Department;