import { UserInterface, StaffContractDetailsStatusEnum, languageStore } from 'stores';
import * as _ from 'lodash';

export function formatUser(users: Array<UserInterface>, userId: string) {
    const user = _.find(users, { id: userId }) as UserInterface;
    return user ? user.fullName : null;
}

export function formatStaffContractDetailsStatus(status: StaffContractDetailsStatusEnum) {
    switch (status) {
        case StaffContractDetailsStatusEnum.AGREED: return languageStore.get('agreed');
        case StaffContractDetailsStatusEnum.COMPLETED: return languageStore.get('completed');
        case StaffContractDetailsStatusEnum.FOR_RENEWAL: return languageStore.get('forRenewal');
        case StaffContractDetailsStatusEnum.DELETED: return languageStore.get('deleted');
        default: return null;
    }
}