import { FieldState, FormState } from "formstate";

export const isFormStateDirty = (formState: { [K: string]: FieldState<any> | FormState<any> }): boolean => {
    return Object.entries(formState)
        .some(([name, field]) => {
            if (field instanceof FieldState) {
                return field.dirty || false;
            } else if (field instanceof FormState) {
                return isFormStateDirty(field.$);
            }

            return false;
        });
}

export const setFormPristine = (formState: { [K: string]: FieldState<any> | FormState<any> }): void => {
    Object.entries(formState)
        .some(([name, field]) => {
            if (field instanceof FieldState) {
                field.dirty = false;
            } else if (field instanceof FormState) {
                setFormPristine(field.$);
            }

            return true;
        });
}