import React from 'react';
import { observer } from 'mobx-react';
import { inboxStore, languageStore, RoleInterface, rolesStore, toastStore, userStore } from 'stores';

interface Props {
    inboxId: string,
    roles: Array<RoleInterface>
}

interface State {
    selectedIds: Array<string>
}

@observer class InboxRoles extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            selectedIds: []
        }
    }

    componentDidMount() {
        const { roles } = this.props;
        rolesStore.getRoles(userStore.signedInUser!.company.id);

        this.setState({
            selectedIds: roles.map((d) => d.id)
        })
    }

    delete = async (e: React.MouseEvent, role: RoleInterface) => {
        e.preventDefault();

        this.setState({
            selectedIds: this.state.selectedIds.filter((id) => id !== role.id)
        })
    }

    add = async (e: React.MouseEvent, role: RoleInterface) => {
        e.preventDefault();

        const ids = this.state.selectedIds;
        ids.push(role.id);

        this.setState({
            selectedIds: ids
        })
    }

    onSave = async () => {
        const { inboxId } = this.props;

        const roles = rolesStore.roles.filter((d) => this.state.selectedIds.indexOf(d.id) !== -1);
        await inboxStore.putRoles(inboxId, roles);
        toastStore.addToast({ 
            level: 'success', 
            title: languageStore.get('success'),
            message: languageStore.get('changesSaved')
        });
    }

    render() {
        const { selectedIds } = this.state;
        const roles = rolesStore.roles || [];
        if (!roles) return null;

        return (
            <div className='w-1/2 m-auto'>
                <h1 className='mt-5'>
                    {languageStore.get('requiredRoles')}
                </h1>
                <div className='mt-10 flex'>
                    <div className='w-1/2 inline-block'>
                        {
                            roles.map((role: RoleInterface, index: number) => {

                                const hasItem = selectedIds.indexOf(role.id) !== -1;

                                let className = 'mt-2';
                                if (!hasItem) {
                                    className += ' text-grey';
                                }

                                return (<div key={index} className={className}>
                                    {role.name}

                                    {
                                        hasItem &&
                                        <button className='ml-2 px-2 py-1 text-xs text-red rounded bg-red-lightest hover:bg-red-lighter' onClick={(e) => this.delete(e, role)}>{languageStore.get('remove')}</button>
                                    }

                                    {
                                        !hasItem &&
                                        <button className='ml-2 px-2 py-1 text-xs text-green rounded bg-green-lightest hover:bg-green-lighter' onClick={(e) => this.add(e, role)}>{languageStore.get('add')}</button>
                                    }
                                </div>)
                            })
                        }
                    </div>

                </div>
                <div className='text-right'>
                    <button
                        onClick={this.onSave}
                        className='bg-comablue-label text-white py-2 px-8 mt-4 rounded'
                        type='button'>
                        {languageStore.get('saveChanges')}
                    </button>
                </div>
            </div>
        )
    }
}

export default InboxRoles;