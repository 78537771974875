import * as React from 'react';
import TableHeader from '../TableHeader';

interface Props {
    value?: string,
}

const TableGreenHeader: React.SFC<Props> = (props) => {
    return <TableHeader {...props} className='text-comablue-label' />
}

export default TableGreenHeader;