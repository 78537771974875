import React from 'react';
import { observer } from 'mobx-react';
import { MdHelp } from 'react-icons/md';
import { Modal, ModalColumn } from 'components/Modal';
import { globalSupportStore, languageStore, toastStore, UserInterface, userStore } from 'stores';
import { FieldState, FormState } from 'formstate';
import { required } from 'helpers/FormValidation';
import Textarea from 'components/Forms/Textarea';
import Form from 'components/Forms/Form';

interface State {
    modalVisible: boolean
}

@observer class GlobalSupportOverlay extends React.Component<any, State> {

    state = { modalVisible: false }

    form = new FormState({
        body: new FieldState('').validators(required)
    })

    onSubmit = async () => {

        const user = userStore.signedInUser as UserInterface;
        if (!user) return; // We will always have a user object when logged in, so this shouldn't happen.

        let message = `<strong>Company:</strong> ${user.company.name}<br/><strong>Name:</strong> ${user.fullName} (${user.email})<br/><strong>Url:</strong> ${window.location.href}<br/><br/><strong>Issue:</strong><br/>${this.form.$.body.$}`;
        message = message.replace(/\n/g, "<br />"); // Replace line changes in description/body with html

        await globalSupportStore.sendTicket('COMA Support Ticket', message);

        this.closeModal();
        toastStore.addToast({
            title: languageStore.get('weHaveReceivedYourRequest'),
            message: languageStore.get('weWillGetBackToYouAsSoonAsPossible'),
            level: 'success'
        })
    }

    closeModal = () => {
        this.form.reset();
        this.setState({ modalVisible: false });
    }

    render() {
        const { modalVisible } = this.state;
        const form = this.form.$;

        return (
            <>
                <div
                    onClick={() => this.setState({ modalVisible: true })}
                    className='
                fixed 
                bg-white 
                p-2
                rounded-full
                cursor-pointer
            '
                    style={{ bottom: '0', left: '0' }}>
                    <MdHelp style={{ color: '#00105c' }} size='42px' />
                </div>

                {
                    modalVisible &&
                    <Form onValidSubmit={this.onSubmit} formState={this.form}>
                        <Modal
                            title={languageStore.get('contactSupport')}
                            primaryButton={{ title: languageStore.get('sendToSupport') }}
                            secondaryButton={{ title: languageStore.get('cancel'), onClick: () => this.closeModal() }}>

                            <ModalColumn>
                                <Textarea
                                    label={languageStore.get('describeYourIssue')}
                                    fieldState={form.body}
                                    id='globalsupport-body'
                                />
                            </ModalColumn>

                        </Modal>
                    </Form>
                }

            </>
        )
    }
}

export default GlobalSupportOverlay;