import * as React from 'react';
import { observer } from 'mobx-react';

interface Props {
    title: string,
    subTitle?: React.ReactNode,
    children: React.ReactNode,
    fullWidth?: boolean
}

@observer class ContractDetailsGroup extends React.Component<Props> {

    render() {
        const { title, subTitle, children, fullWidth } = this.props;

        return (
            <div className={`${fullWidth ? '' : 'w-1/2'}`} >
                <h2 className=''>
                    {title}
                </h2>
                {subTitle}
                <div className='flex flex-wrap'>
                    {children}
                </div>
            </div>
        );
    }

}

export default ContractDetailsGroup;