import { BaseStore, BaseContractTypeEnum } from './BaseStore';
import { observable, action, computed, makeObservable } from 'mobx';

export class LinkContractStore extends BaseStore {
    @observable sourceType: BaseContractTypeEnum | null = null;
    @observable sourceId: string | null = null;

    @observable targetType: BaseContractTypeEnum | null = null;
    @observable targetId: string | null = null;

    @action
    setSource = (sourceType: BaseContractTypeEnum, sourceId: string) => {
        this.sourceType = sourceType;
        this.sourceId = sourceId;
    }

    @action
    setTarget = (targetType: BaseContractTypeEnum, targetId: string) => {
        this.targetType = targetType;
        this.targetId = targetId;
    }

    @action
    resetSource = () => {
        this.sourceType = null;
        this.sourceId = null;
    }

    @action
    resetTarget = () => {
        this.targetType = null;
        this.targetId = null;
    }

    @action
    resetStore = () => {
        this.resetSource();
        this.resetTarget();
    }

    constructor() {
        super();

        makeObservable(this);
    }

    @computed
    get linkContractIsActive() {
        return !!this.sourceId;
    }
}

export const linkContractStore = new LinkContractStore();