import * as React from 'react';
import { observer } from 'mobx-react';
import { FieldState } from 'formstate';
//import numeral from 'numeral';

import './style.css';

type Props = {
    id: string,
    label: string,
    fieldState: FieldState<any>,
    type?: 'text' | 'password' | 'number',
    className?: string,
    readOnly?: boolean,
    maxLength?: number,
    required?: boolean,
    onBlur?: () => void
}

interface State {
    inputHasFocus: boolean
}

@observer class Input extends React.Component<Props, State> {

    inputRef: any;

    constructor(props: Props) {
        super(props);

        this.inputRef = React.createRef();

        this.state = {
            inputHasFocus: false
        }
    }

    componentDidMount(): void {
        const { fieldState, type } = this.props;

        if(type === "number")
        {
            fieldState.value = this.formatNumberForInput(fieldState.value);
        }
    }

    onChange(e: React.ChangeEvent<HTMLInputElement>) {
        const { fieldState, type } = this.props;
        let val = e.target.value;
        fieldState.onChange(type === 'number' ? !val ? null : Number(val) : val);
    }

    focusInputField = () => {
        this.inputRef.current.focus();
    }

    onFocus = () => {
        this.setState({ inputHasFocus: true });
    }

    onBlur = () => {
        this.setState({ inputHasFocus: false });

        const { onBlur } = this.props;
        if (onBlur) {
            onBlur();
        }
    }

    formatNumberForInput = (value: any) => {
        const parsedValue = parseFloat(String(value));
        return parsedValue;
    }

    render() {
        const { fieldState, id, label, type, className, readOnly, maxLength, required } = this.props;
        const { inputHasFocus } = this.state;

        let inputHasValue = !!fieldState.value;

        if (fieldState.value === 0) {
            inputHasValue = true;
        }

        return (
            <div
                style={fieldState.error ? {border: '1px solid #e4002b'} : undefined} 
                className={`relative border rounded mb-8 appearance-none ${inputHasFocus || inputHasValue ? 'label-floating' : ''} ${className}`}>
                <input 
                    required={required} 
                    maxLength={maxLength} 
                    readOnly={readOnly} 
                    onFocus={this.onFocus} 
                    onBlur={this.onBlur} 
                    ref={this.inputRef} 
                    className={`w-full py-2 px-2 text-green-darker leading-normal rounded label-floating-input bg-transparent ${readOnly ? 'input-readOnly' : ''}`} 
                    id={id} 
                    type={type || 'text'} 
                    value={fieldState.value === null ? '' : fieldState.value} 
                    onChange={(e) => this.onChange(e)} />
                <label onClick={this.focusInputField} className="whitespace-no-wrap overflow-hidden absolute block text-grey-darker pin-t pin-l w-full px-2 py-2 leading-normal cursor-pointer" htmlFor={id}>
                    {label} {fieldState.error && <span className="text-red text-xs pl-1">({fieldState.error})</span>}
                </label>
            </div>
        );
    }
}

export default Input;