import * as React from 'react';
import { observer } from 'mobx-react';
import ContractDetailsItem from 'components/ContractDetailsItem';
import { languageStore, customerStore, CustomerInterface } from 'stores';
import { FieldState } from 'formstate';
import * as _ from 'lodash';

interface Props {
    customerId: string | null,
    fieldState: FieldState<any>,
    editMode?: boolean
}

@observer class CustomerName extends React.Component<Props> {

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        if (nextProps.customerId !== this.props.customerId) {
            const { fieldState } = this.props;

            if (nextProps.customerId) {
                const customer = _.find(customerStore.customers, { id: nextProps.customerId }) as CustomerInterface;
                const name = customer ? customer.name : '';
                fieldState.onChange(name);
            }
            else {
                fieldState.onChange('');
            }
        }
    }

    render() {
        const { fieldState, editMode } = this.props;

        return <ContractDetailsItem
            label={languageStore.get('customerName')}
            id='customerName'
            fieldState={fieldState}
            editMode={editMode}
        />
    }

}

export default CustomerName;