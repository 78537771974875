import React from 'react';
import { observer } from 'mobx-react';

interface Props {
    isSelected?: boolean,
    label: string
    onClick: () => void
}

@observer class SidebarNavigationItem extends React.Component<Props> {

    render() {
        const { isSelected, label, onClick } = this.props;
        const selectedClass = 'no-underline text-red text-left font-semibold ';
        const defaultClass = 'no-underline text-black text-left';

        return (
            <li
                className='ml-1 my-2'>
                <button
                    onClick={onClick}
                    className={isSelected ? selectedClass : defaultClass}>
                    {label}
                </button>
            </li>
        )
    }
}

export default SidebarNavigationItem;