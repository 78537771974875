import * as React from 'react';
import { observer } from 'mobx-react';
import { FieldState } from 'formstate';
import ContractDropdownItem from 'components/ContractDropdownItem';
import { companyUserStore, languageStore } from 'stores';
import { DropdownOptionInterface } from 'components/Forms/Dropdown';

interface Props {
    fieldState: FieldState<any>,
    label: string,
    id: string,
    beforeChange?: Function,
    skipIds?: Array<string>,
    companyId: string
}

@observer class SelectCompanyUser extends React.Component<Props> {

    componentDidMount() {
        const { companyId } = this.props;
        companyUserStore.getUsers(companyId);
    }

    render() {
        const { fieldState, label, id, beforeChange, skipIds } = this.props;

        let availUsers: Array<DropdownOptionInterface> = [];
        companyUserStore.users.forEach((user) => {
            if (skipIds?.includes(user.id)) return;
            
            availUsers.push({
                value: user.id,
                name: user.fullName
            })
        })

        return (
            <ContractDropdownItem
                label={label}
                id={id}
                placeholderText={languageStore.get('selectUser')}
                fieldState={fieldState}
                options={availUsers}
                beforeChange={beforeChange}
                editMode={true} />
        )
    }

}

export default SelectCompanyUser;