import * as React from 'react';
import { observer } from 'mobx-react';
import './style.css';
import { languageStore } from 'stores';

@observer class DraftRibbon extends React.Component {

    render() {
        return (
            <div className="DraftRibbon-wrapper">
                <div className="DraftRibbon">
                    {languageStore.get('draft').toUpperCase()}
                </div>
            </div>
        )
    }

}

export default DraftRibbon;