import * as React from 'react';
import { observer } from 'mobx-react';
import { FieldState } from 'formstate';
import ContractDropdownItem from 'components/ContractDropdownItem';
import { languageStore, AddoTemplateInterface } from 'stores';

interface Props {
    fieldState: FieldState<any>,
    templates: Array<AddoTemplateInterface>,
    label: string
}

@observer class SelectTemplate extends React.Component<Props> {

    render() {
        const { fieldState, templates, label } = this.props;

        const options = templates.map((template) => {
            return {
                value: template.id,
                name: template.friendlyName
            }
        })

        return <ContractDropdownItem
            label={label}
            placeholderText={languageStore.get('select')}
            id='billingInterval'
            fieldState={fieldState}
            options={options}
            skipSort={true}
            editMode={true} />
    }

}

export default SelectTemplate;