import * as React from 'react';
import { observer } from 'mobx-react';
import { FieldState } from 'formstate';
import ContractDropdownItem from 'components/ContractDropdownItem';
import { languageStore, ContactPeopleTypeEnum } from 'stores';
import { DropdownOptionInterface } from 'components/Forms/Dropdown';

interface Props {
    fieldState: FieldState<any>,
    editMode?: boolean,
}

@observer class SelectContactType extends React.Component<Props> {

    render() {
        const { fieldState, editMode } = this.props;

        const dropdownOpts = [
            {
                value: ContactPeopleTypeEnum.Unspecified,
                name: languageStore.get('notSet')
            },
            {
                value: ContactPeopleTypeEnum.Wife,
                name: languageStore.get('wifeHusband')
            },
            {
                value: ContactPeopleTypeEnum.Girlfriend,
                name: languageStore.get('girlfriend')
            },
            {
                value: ContactPeopleTypeEnum.Mother,
                name: languageStore.get('mother')
            },
            {
                value: ContactPeopleTypeEnum.Father,
                name: languageStore.get('father')
            },
            {
                value: ContactPeopleTypeEnum.Manager,
                name: languageStore.get('manager')
            },
            {
                value: ContactPeopleTypeEnum.Brother,
                name: languageStore.get('brother')
            },
            {
                value: ContactPeopleTypeEnum.Sister,
                name: languageStore.get('sister')
            },
            {
                value: ContactPeopleTypeEnum.Son,
                name: languageStore.get('son')
            },
            {
                value: ContactPeopleTypeEnum.Daughter,
                name: languageStore.get('daughter')
            },
        ] as Array<DropdownOptionInterface>

        return (
            <ContractDropdownItem
                label={languageStore.get('relation')}
                id='contactType'
                fieldState={fieldState}
                options={dropdownOpts}
                skipSort={true}
                type='number'
                editMode={editMode} />
        )
    }

}

export default SelectContactType;