import * as React from 'react';
import { observer } from 'mobx-react';
import { FieldState } from 'formstate';
import ContractDropdownItem from 'components/ContractDropdownItem';
import { languageStore, categoryStore, MainCategoryInterface, customFieldStore, DashboardContractTypeEnum, currentPurchaseContractStore, currentSalesContractStore, currentServiceContractStore, currentStaffContractStore } from 'stores';
import { DropdownOptionInterface } from 'components/Forms/Dropdown';
import { BaseContractTypeEnum } from 'stores/BaseStore';

interface Props {
    fieldState: FieldState<any>,
    locationId?: string,
    fallbackValue?: string,
    editMode?: boolean,
    contractType: BaseContractTypeEnum,
    dashContractType: DashboardContractTypeEnum
}

@observer class MainCategory extends React.Component<Props> {

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        if (nextProps.locationId !== this.props.locationId) {
            nextProps.fieldState.onChange('');

            if (nextProps.locationId) {
                categoryStore.getCategories(this.props.contractType, nextProps.locationId);
            }
            else {
                categoryStore.resetStore();
            }

        }
    }

    componentDidMount() {
        const { locationId,dashContractType, contractType } = this.props;

        if (locationId) {
            categoryStore.getCategories(contractType, locationId);
        }
        
        customFieldStore.getHasCustomFields( dashContractType );

    }

    componentWillUnmount() {
        categoryStore.resetStore();
        customFieldStore.resetStore();

    }

    
    render() {
        const { fieldState, fallbackValue, editMode } = this.props;

        let availCategories: Array<DropdownOptionInterface> = [];
        categoryStore.categories && categoryStore.categories.forEach((cat: MainCategoryInterface) => {
            availCategories.push({
                value: cat.id,
                name: cat.name!
            })
        })

        const beforeChange = (e: any) => {
            const { contractType } = this.props;

            let ccstore = null;

            switch (contractType) {
                case BaseContractTypeEnum.PURCHASE:
                    ccstore = currentPurchaseContractStore;
                break;
                case BaseContractTypeEnum.SALES:
                    ccstore = currentSalesContractStore;
                break;
                case BaseContractTypeEnum.SERVICE:
                    ccstore = currentServiceContractStore;
                break;
                case BaseContractTypeEnum.STAFF:
                    ccstore = currentStaffContractStore;
                break;
            }

            if(ccstore.contract!.customFields.length > 0)
            {
                alert(languageStore.get("youHaveCustomFieldsAttachedToThisContract"));
            }
        }

        return <ContractDropdownItem
                    id='mainCategory'
                    label={languageStore.get('mainCategory')}
                    placeholderText={languageStore.get('selectMainCategory')}
                    fieldState={fieldState}
                    options={availCategories}
                    editMode={editMode}
                    fallbackValue={fallbackValue}
                    beforeChange={beforeChange} />
    }

}

export default MainCategory;