import * as React from 'react';
import { observer } from 'mobx-react';
import { FieldState } from 'formstate';
import { DropdownOptionInterface } from 'components/Forms/Dropdown';
import ContractDropdownItem from 'components/ContractDropdownItem';
import _ from 'lodash';

import {
    locationStore as locStore,
    languageStore as lanStore,
    LocationInterface,
    CurrentContractStore
} from 'stores';

interface Props {
    fieldState: FieldState<any>,
    editMode?: boolean,
    disabled?: boolean,
    store?: CurrentContractStore<any>,
    companyId?: string,
    label?: string
}

@observer class Location extends React.Component<Props> {

    constructor(props: Props) {
        super(props);

        // 
        // LocationId is widely used across the contractpage, so
        // everytime the value changes in component, set it as a property
        // in store
        //
        const fieldState = props.fieldState;
        const store = props.store;
        if (store) {
            store.setCurrentLocationId(fieldState.value);
            fieldState.onUpdate((fs: any) => {
                store.setCurrentLocationId(fs.value);
            })
        }

    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        if (nextProps.fieldState.value !== this.props.fieldState.value) {
            const { store } = this.props;
            if (store) {
                store.setCurrentLocationId(nextProps.fieldState.value);
            }

        }
    }

    componentWillUnmount() {
        const { store } = this.props;
        if (store) {
            store.resetCurrentLocationId();
        }
    }

    componentDidMount() {
        const { companyId } = this.props;

        if (companyId) {
            locStore.getLocationsByCompany(companyId);
        }
        else {
            locStore.getLocations(true);
        }
    }

    render() {
        const { fieldState, editMode, disabled, label } = this.props;

        let availLocations: Array<DropdownOptionInterface> = [];
        locStore.locations && _.orderBy(locStore.locations, 'name').forEach((loc: LocationInterface) => {
            availLocations.push({
                value: loc.id,
                name: loc.name
            })
        })

        return (
            <ContractDropdownItem
                label={label || lanStore.get('location')}
                id='location'
                fieldState={fieldState}
                placeholderText={lanStore.get('selectLocation')}
                options={availLocations}
                disabled={disabled}
                editMode={editMode} />
        )
    }

}

export default Location;