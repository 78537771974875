import { languageStore, userStore } from 'stores';
import Moment from  'moment-timezone';
import { FieldState } from 'formstate';

export function monthToShortname(month: number) {

    switch (month) {
        case 1: return languageStore.get('januaryShort');
        case 2: return languageStore.get('februaryShort');
        case 3: return languageStore.get('marchShort');
        case 4: return languageStore.get('aprilShort');
        case 5: return languageStore.get('mayShort');
        case 6: return languageStore.get('juneShort');
        case 7: return languageStore.get('julyShort');
        case 8: return languageStore.get('augustShort');
        case 9: return languageStore.get('septemberShort');
        case 10: return languageStore.get('octoberShort');
        case 11: return languageStore.get('novemberShort');
        case 12: return languageStore.get('decemberShort');
        default: return '';
    }

}

export function formatDate(date: string, includeTime: boolean = false) {
    if (!date) return null;

    let format = 'DD/MM/YYYY';

    if (includeTime) {
        format += ' HH:mm';
    }
    
    let timeZone = userStore.signedInUser!.timeZone.id;
    return Moment.utc(date).tz(timeZone).format(format);
}

export function formattedTimeZoneForElasticSearch(dateString: string | number) {

    const { signedInUser } = userStore;

    const x = Moment.utc(dateString).tz(signedInUser!.timeZone.id).utcOffset() / 60;
    let timeZone = '' + x;

    // Format should be double digit. 1 becomes 01:00 etc.
    if (x >= 0 && x <= 9) {
        timeZone = '+0' + x;
    }
    else if (x >= 10) {
        timeZone = '+' + x;
    }
    // we're below 0. If between -1 and -9 add 0 in front (-02:00 etc)
    else {
        timeZone = timeZone.slice(1); // remove '-';

        if (x > -10) {
            timeZone = '-0' + timeZone;
        }
        else {
            timeZone = '-' + timeZone;
        }
    }

    timeZone = timeZone + ':00';
    return timeZone;
}

export function addDaysIfNotSet(value: string, targetFs: FieldState<any>, days: number) {
    if (!targetFs.value && value) {
        const date = Moment(value).utc().add(-90, 'days');
        let timeZone = userStore.signedInUser!.timeZone.id;
        targetFs.onChange(date.tz(timeZone).toISOString());
    }
}