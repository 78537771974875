import * as React from 'react';
import { toastStore } from 'stores';
import { observer } from 'mobx-react';
import { getAllowedFileTypesBySignedInCompany, uploadFile } from 'helpers/FileHelper';

interface Props {
    onSubmit: Function,
    className?: string,
    customUpload?: boolean,
    allowedFileTypes?: Array<string>
}

@observer class UploadFile extends React.Component<Props> {

    hiddenUploadFieldRef: React.RefObject<any>;

    constructor(props: Props) {
        super(props);

        this.hiddenUploadFieldRef = React.createRef();
    }

    selectFile = (e: React.MouseEvent) => {
        e.preventDefault();

        this.hiddenUploadFieldRef.current.click();
    }

    uploadFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        e.stopPropagation();
        var { onSubmit, customUpload } = this.props;

        // eslint-disable-next-line eqeqeq
        if (e.target.value != "") {
            // e.dataTransfer.files is a FileList, which is not an array
            // even though they have common similarities.
            // We cast to array to be able to iterate, and use for of, to await each upload
            for (let file of Array.from(e.target.files!)) {
                if (customUpload) {
                    await onSubmit(file);
                    return;
                }

                try {
                    const doc = await uploadFile(file);
                    onSubmit(doc);
                }
                catch (error) {
                    toastStore.addError(error);
                }
            }
        }
    }

    render() {
        const { className, children, allowedFileTypes } = this.props;
        const fileTypes = allowedFileTypes || getAllowedFileTypesBySignedInCompany();
        const acceptFiles = fileTypes.map(type => "." + type).toString();

        const hideStyle = {
            height: '0',
            width: '0',
            position: "absolute",
            top: "-9999px",
            overflow: 'hidden'
        } as React.CSSProperties

        return (
            <span>
                <input onChange={this.uploadFile} style={hideStyle} ref={this.hiddenUploadFieldRef} type="file" multiple={true} accept={acceptFiles} />
                <a className={className} onClick={e => this.selectFile(e)} href=' '>
                    {children}
                </a>
            </span>
        );
    }

}

export default UploadFile;