import * as React from 'react';
import { observer } from 'mobx-react';
import { languageStore, userStore, CompanyInterface, toastStore, inboxStore, RoleEnum, DashboardContractTypeEnum } from 'stores';
import { FormState, FieldState } from 'formstate';
import Form from 'components/Forms/Form';
import SelectTrueFalse from 'components/Forms/SelectTrueFalse';
import SelectLanguage from 'components/ProfilePage/Profile/SelectLanguage';
import { hasRole } from 'helpers/RoleHelper';
import Input from 'components/Forms/Input';
import { canAccess, ModuleEnum } from 'helpers/ModuleHelper';

@observer class CompanyPage extends React.Component {

    form = new FormState({
        "dontAllowDocs": new FieldState(!userStore.signedInUser!.company.dontAllowDocs),
        "dontAllowExcel": new FieldState(!userStore.signedInUser!.company.dontAllowExcel),
        "dontAllowImages": new FieldState(!userStore.signedInUser!.company.dontAllowImages),
        "dontAllowPDF": new FieldState(!userStore.signedInUser!.company.dontAllowPDF),
        "allowInbox": new FieldState(userStore.signedInUser!.company.allowInbox),
        "defaultLanguage": new FieldState(userStore.signedInUser!.company.defaultLanguage),
        "purchaseDefaultDaysBeforeDelete": new FieldState(userStore.signedInUser!.company.purchaseDefaultDaysBeforeDelete),
        "staffDefaultDaysBeforeDelete": new FieldState(userStore.signedInUser!.company.staffDefaultDaysBeforeDelete),
        "serviceDefaultDaysBeforeDelete": new FieldState(userStore.signedInUser!.company.serviceDefaultDaysBeforeDelete),
        "salesDefaultDaysBeforeDelete": new FieldState(userStore.signedInUser!.company.salesDefaultDaysBeforeDelete),
        "deactivteAutomaticBirthdayAlarms": new FieldState(userStore.signedInUser!.company.deactivteAutomaticBirthdayAlarms),
        "deactivteAutomaticSeniorityAlarms": new FieldState(userStore.signedInUser!.company.deactivteAutomaticSeniorityAlarms),
        "webhookBaseUrl": new FieldState(userStore.signedInUser!.company.webhookBaseUrl),
    })

    componentDidMount() {
        this.getInboxData();
    }

    getInboxData = () => {
        const company = userStore.signedInUser!.company as CompanyInterface;

        if (company.allowInbox && company.inboxId) {
            inboxStore.getInbox(company.inboxId);
        }
    }

    onSubmit = async () => {
        const form = this.form.$;

        const company = userStore.signedInUser!.company as CompanyInterface;
        company.dontAllowDocs = !form.dontAllowDocs.$;
        company.dontAllowExcel = !form.dontAllowExcel.$;
        company.dontAllowImages = !form.dontAllowImages.$;
        company.dontAllowPDF = !form.dontAllowPDF.$;
        company.allowInbox = form.allowInbox.$;
        company.webhookBaseUrl = form.webhookBaseUrl.$;
        company.deactivteAutomaticBirthdayAlarms = form.deactivteAutomaticBirthdayAlarms.$;
        company.deactivteAutomaticSeniorityAlarms = form.deactivteAutomaticSeniorityAlarms.$;

        if (hasRole(RoleEnum.PRIMARY_ADMIN || hasRole(RoleEnum.PURCHASE_ADMIN))) {
            company.purchaseDefaultDaysBeforeDelete = form.purchaseDefaultDaysBeforeDelete.$;
        }
        if (hasRole(RoleEnum.PRIMARY_ADMIN || hasRole(RoleEnum.HR_ADMIN))) {
            company.staffDefaultDaysBeforeDelete = form.staffDefaultDaysBeforeDelete.$;
        }
        if (hasRole(RoleEnum.PRIMARY_ADMIN || hasRole(RoleEnum.SERVICE_ADMIN))) {
            company.serviceDefaultDaysBeforeDelete = form.serviceDefaultDaysBeforeDelete.$;
        }
        if (hasRole(RoleEnum.PRIMARY_ADMIN || hasRole(RoleEnum.SALES_ADMIN))) {
            company.salesDefaultDaysBeforeDelete = form.salesDefaultDaysBeforeDelete.$;
        }

        if (form.defaultLanguage.value && company.defaultLanguage !== form.defaultLanguage.value) {
            if (form.defaultLanguage.value !== userStore.signedInUser!.language) {
                alert(languageStore.get('companyLanguageDiffersFromUserLanguageWarning'));
            }
        }

        company.defaultLanguage = form.defaultLanguage.$;

        await userStore.updateSignedInUserCompany(company);
        toastStore.addToast({
            level: 'success',
            title: languageStore.get('success'),
            message: languageStore.get('changesSaved')
        })

        // Simple hack instead of setting state. Do it to get inbox hash and domain.
        if (company.allowInbox) {
            window.location.reload();
        }
    }

    canSetDefaultDays = (contractType: DashboardContractTypeEnum) => {
        switch (contractType) {
            case DashboardContractTypeEnum.PURCHASE:
                if (!canAccess(ModuleEnum.PURCHASE)) return false;
                return hasRole(RoleEnum.PRIMARY_ADMIN) || hasRole(RoleEnum.PURCHASE_ADMIN);

            case DashboardContractTypeEnum.STAFF:
                if (!canAccess(ModuleEnum.STAFF)) return false;
                return hasRole(RoleEnum.PRIMARY_ADMIN) || hasRole(RoleEnum.HR_ADMIN);

            case DashboardContractTypeEnum.SERVICE:
                if (!canAccess(ModuleEnum.SERVICE)) return false;
                return hasRole(RoleEnum.PRIMARY_ADMIN) || hasRole(RoleEnum.SERVICE_ADMIN);

            case DashboardContractTypeEnum.SALES:
                if (!canAccess(ModuleEnum.SALES)) return false;
                return hasRole(RoleEnum.PRIMARY_ADMIN) || hasRole(RoleEnum.SALES_ADMIN);
        }

    }

    render() {
        const form = this.form.$;

        return (
            <div>
                <Form onValidSubmit={this.onSubmit} formState={this.form}>

                    <div className='w-1/2 mx-auto' style={{ maxWidth: '300px' }}>
                        <SelectLanguage hideDefault={false} fieldState={form.defaultLanguage} />

                        <h2>
                            {languageStore.get('allowedFileTypes')}
                        </h2>
                        <div className='text-left mt-8'>
                            <div style={{ marginTop: '-20px' }}>
                                <SelectTrueFalse
                                    label='pdf'
                                    fieldState={form.dontAllowPDF}
                                    editMode={true}
                                    id='pdf' />
                            </div>

                            <div style={{ marginTop: '-40px' }}>
                                <SelectTrueFalse
                                    label={languageStore.get('documents')}
                                    fieldState={form.dontAllowDocs}
                                    editMode={true}
                                    id='doc' />
                            </div>

                            <div style={{ marginTop: '-40px' }}>
                                <SelectTrueFalse
                                    label={languageStore.get('images')}
                                    fieldState={form.dontAllowImages}
                                    editMode={true}
                                    id='images' />
                            </div>

                            <div style={{ marginTop: '-40px' }}>
                                <SelectTrueFalse
                                    label={languageStore.get('spreadsheets')}
                                    fieldState={form.dontAllowExcel}
                                    editMode={true}
                                    id='spreadsheets' />
                            </div>

                            {
                                canAccess(ModuleEnum.STAFF) &&
                                <>
                                    <h2>
                                        {languageStore.get('deactivateAutomaticAlarms')}
                                    </h2>
                                    <div style={{ marginTop: '-20px' }}>
                                        <SelectTrueFalse
                                            label={languageStore.get('birthday')}
                                            fieldState={form.deactivteAutomaticBirthdayAlarms}
                                            editMode={true}
                                            id='deactivteAutomaticBirthdayAlarms' />
                                    </div>
                                    <div style={{ marginTop: '-40px', marginBottom: '-20px' }}>
                                        <SelectTrueFalse
                                            label={languageStore.get('seniority')}
                                            fieldState={form.deactivteAutomaticSeniorityAlarms}
                                            editMode={true}
                                            id='deactivteAutomaticSeniorityAlarms' />
                                    </div>
                                </>
                            }

                            <h2>
                                {languageStore.get('deletionDays')}
                            </h2>

                            <p className='mt-2 text-sm mb-8'>
                                {languageStore.get('deletionDatesExplanation')}.
                            </p>

                            <Input
                                id='purchaseDefaultDaysBeforeDelete'
                                type='number'
                                readOnly={!this.canSetDefaultDays(DashboardContractTypeEnum.PURCHASE)}
                                label={`${languageStore.get('purchaseContract')}`}
                                fieldState={form.purchaseDefaultDaysBeforeDelete}
                            />

                            <Input
                                id='staffDefaultDaysBeforeDelete'
                                type='number'
                                readOnly={!this.canSetDefaultDays(DashboardContractTypeEnum.STAFF)}
                                label={`${languageStore.get('staffContract')}`}
                                fieldState={form.staffDefaultDaysBeforeDelete}
                            />

                            <Input
                                id='serviceDefaultDaysBeforeDelete'
                                type='number'
                                readOnly={!this.canSetDefaultDays(DashboardContractTypeEnum.SERVICE)}
                                label={`${languageStore.get('serviceContract')}`}
                                fieldState={form.serviceDefaultDaysBeforeDelete}
                            />

                            <Input
                                id='salesDefaultDaysBeforeDelete'
                                type='number'
                                readOnly={!this.canSetDefaultDays(DashboardContractTypeEnum.SALES)}
                                label={`${languageStore.get('salesContract')}`}
                                fieldState={form.salesDefaultDaysBeforeDelete}
                            />

                            <h2>
                                {languageStore.get('inbox')}
                            </h2>

                            <p className='mt-2 text-sm'>
                                {languageStore.get('inboxPresentation')}
                            </p>

                            <div style={{ marginTop: '-20px', marginBottom: '-20px' }}>
                                <SelectTrueFalse
                                    label={languageStore.get('inboxActive')}
                                    fieldState={form.allowInbox}
                                    editMode={true}
                                    id='allowInbox' />
                            </div>

                            {
                                !form.allowInbox.value &&
                                <p>
                                    {languageStore.get('enableInboxDisclaimer')}
                                </p>
                            }

                        </div>

                        <h2 className='mb-6'>
                            {languageStore.get('webhook')}
                        </h2>

                        <Input
                            id='webhookBaseUrl'
                            label={languageStore.get('baseUrl')}
                            fieldState={form.webhookBaseUrl}
                        />

                        <div className='text-center'>
                            <button className='bg-comablue-label text-white py-2 px-8 mt-12 rounded' type='submit'>
                                {languageStore.get('saveChanges')}
                            </button>
                        </div>
                    </div>
                </Form>
            </div>
        )
    }

}

export default CompanyPage;