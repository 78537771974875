import * as React from 'react';
import { observer } from 'mobx-react';
import { FormState, FieldState } from 'formstate';
import { currentStaffContractStore as store, languageStore, equipmentStore, EquipmentInterface, StaffEquipmentInterface } from 'stores';
import TableGreenHeader from 'components/Table/TableGreenHeader';
import * as _ from 'lodash';
import { formatDate } from 'helpers/DateHelper';
import { formatStaffContractDetailsStatus } from 'helpers/FormatHelper';
import BaseContractTable from '../BaseContractTable';
import SectionEnum from '../SectionEnum';
import { ModalColumn } from 'components/Modal';
import SelectEquipment from './SelectEquipment';
import SelectTrueFalse from 'components/Forms/SelectTrueFalse';
import ContractDateItem from 'components/ContractDateItem';
import ContractDetailsItem from 'components/ContractDetailsItem';
import SelectStaffContractDetailsStatus from '../SelectStaffContractDetailsStatus';
import { required } from 'helpers/FormValidation';
import { reaction, IReactionDisposer } from 'mobx';

@observer class Equipment extends React.Component {

    form = new FormState({
        id: new FieldState<string | null>(null),
        equipmentId: new FieldState(null).validators(required),
        accepted: new FieldState(null),
        handedOut: new FieldState(null).validators(required),
        handedIn: new FieldState(null),
        note: new FieldState(null),
        staffContractDetailsStatus: new FieldState(null).validators(required)
    })

    reactionDisposer: IReactionDisposer | any;

    componentDidMount() {
        if (store.contract && store.contract.department) {
            const locationId = store.contract!.department.locationId;
            equipmentStore.getEquipment(locationId);
            
        }

        this.reactionDisposer = reaction(() => store.currentLocationId, (locationId) => {
            if (locationId === null) {
                equipmentStore.resetStore();
                return;
            }

            if (locationId && store.contract && 
                ((store.contract.department !== null && store.contract.department.locationId !== locationId) ||
                (store.contract && store.contract!.department === null))) {

                equipmentStore.getEquipment(locationId!);
            }
        })

    }

    componentWillUnmount() {
        this.reactionDisposer();
    }

    beforeSubmit = (obj: StaffEquipmentInterface) => {
        if (obj.accepted === null || obj.accepted === undefined) {
            obj.accepted = false;
        }
    }

    render() {
        const data = store.contract!.equipment || [];
        const equipment = equipmentStore.equipment || [];
        const form = this.form.$;

        const columns = [
            {
                Header: <TableGreenHeader value={languageStore.get('equipment')} />,
                id: 'equipmentId',
                accessor: (d: any) => {
                    const value = _.find(equipment, { id: d.equipmentId }) as EquipmentInterface;
                    return value ? value.name : '-';
                }
            },
            {
                Header: <TableGreenHeader value={languageStore.get('accepted')} />,
                id: 'accepted',
                accessor: (d: any) => d.accepted ? languageStore.get('yes') : languageStore.get('no')
            },
            {
                Header: <TableGreenHeader value={languageStore.get('handedOut')} />,
                id: 'handedOut',
                accessor: (d: any) => formatDate(d.handedOut)
            },
            {
                Header: <TableGreenHeader value={languageStore.get('handedIn')} />,
                id: 'handedIn',
                accessor: (d: any) => formatDate(d.handedIn)
            },
            {
                Header: <TableGreenHeader value={languageStore.get('note')} />,
                accessor: 'note'
            },
            {
                Header: <TableGreenHeader value={languageStore.get('status')} />,
                id: 'status',
                accessor: (d: any) => formatStaffContractDetailsStatus(d.staffContractDetailsStatus)
            }
        ]

        return (
            <BaseContractTable
                form={this.form}
                beforeSubmit={this.beforeSubmit}
                onUpdate={(eq: StaffEquipmentInterface) => store.updateEquipment(eq)}
                onSubmit={(eq: StaffEquipmentInterface) => store.createEquipment(eq)}
                onDelete={(id: string) => store.deleteEquipment(id)}
                collection={data}
                columns={columns}
                tableTitle={languageStore.get('personalEquipment')}
                modalTitle={languageStore.get('personalEquipment')}
                store={store}
                hash={SectionEnum.EQUIPMENT}>

                <ModalColumn>

                    <SelectEquipment
                        fieldState={form.equipmentId}
                        editMode={true} />

                    <SelectTrueFalse
                        fieldState={form.accepted}
                        label={languageStore.get('accepted')}
                        id='accepted'
                        editMode={true} />

                    <ContractDateItem
                        fieldState={form.handedOut}
                        label={languageStore.get('handedOut')}
                        id='handedOut'
                        editMode={true} />

                    <ContractDateItem
                        fieldState={form.handedIn}
                        label={languageStore.get('handedIn')}
                        id='handedIn'
                        editMode={true} />

                    <ContractDetailsItem
                        fieldState={form.note}
                        label={languageStore.get('note')}
                        id='note'
                        editMode={true} />

                    <SelectStaffContractDetailsStatus
                        fieldState={form.staffContractDetailsStatus}
                        editMode={true} />

                </ModalColumn>

            </BaseContractTable>
        )
    }

}

export default Equipment;