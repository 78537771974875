import * as React from 'react';
import { observer } from 'mobx-react';
import * as _ from 'lodash';
import { FieldState } from 'formstate';
import { ReportCompareTypeEnum, BaseReportStore, ReportCriteriaInterface } from 'stores';

interface Props {
    requestField: string,
    fieldType?: 'text',
    fieldState: FieldState<any>,
    type: ReportCompareTypeEnum,
    store: BaseReportStore
}

interface State {
    suggestions: Array<string>,
    hasFocus: boolean
}

@observer class Input extends React.Component<Props, State> {

    inputRef: any;

    constructor(props: Props) {
        super(props);

        this.state = {
            suggestions: [],
            hasFocus: false
        }

        this.searchQueryChanged = _.debounce(this.searchQueryChanged, 100);
        this.inputRef = React.createRef();

        // props.fieldState.onUpdate((fs: FieldState<any>) => {
        //     if (this.props.fieldState.value !== fs.value) {
        //         this.searchQueryChanged();
        //     }
        // })
    }

    onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { fieldState } = this.props;
        fieldState.onChange(e.target.value);

        if (this.state.hasFocus) {
            this.searchQueryChanged();
        }
    }

    searchQueryChanged = async () => {
        const { fieldState, store, type, requestField } = this.props;
        let value = fieldState.value;
        let resultsCount = 5;

        if (type !== ReportCompareTypeEnum.TEXT) {
            return;
        }

        const match = _.find(store.data, { requestField: requestField }) as ReportCriteriaInterface;

        if (match && match.autoCompleteOnFocus) {
            if (!value || value.length < 1) {
                value = '*';
                resultsCount = 50;
            }
        }
        else {
            if (!value || value.length < 1) {
                this.resetSuggestions();
                return;
            }
        }

        const res = await store.autoComplete(requestField, value, resultsCount) as Array<any>;

        let suggestions: Array<string> = [];
        res.forEach((obj) => {
            let suggestion = _.get(obj, requestField) as string;

            if (suggestion === undefined) {
                // maybe it's an array like notes.title, where notes is an array
                // Start by splitting the string, and see if first part is an array (has length)
                const parts = requestField.split('.');
                const col = _.get(obj, parts[0]);
                if (col && col.length !== undefined) {
                    // It's an array! Loop through, and add child objects to suggestions.
                    col.forEach((val: any) => {
                        const partsCopy = [...parts];
                        while (partsCopy.length > 0){
                            partsCopy.shift();
                            if (partsCopy.length > 0) {
                                const innerSuggestion = _.get(val, partsCopy.join('.'));
                                if (innerSuggestion && suggestions.indexOf(innerSuggestion) === -1 && innerSuggestion.toLowerCase().startsWith(value.toLowerCase())) {
                                    suggestions.push(innerSuggestion);
                                }
                            }
                        }    
                    })
                }
            }
            else {
                if (suggestion && suggestions.indexOf(suggestion) === -1 && suggestion.toLowerCase() !== value.toLowerCase()) {
                    suggestions.push(suggestion);
                }
            }

        })

        if (match.autoCompleteOnFocus) {
            suggestions = suggestions.sort();
        }

        this.setState({
            suggestions: suggestions
        })
    }

    setValue = (e: React.MouseEvent, value: string) => {
        e.preventDefault();
        e.stopPropagation();

        // this.inputRef.current.value = value;
        this.resetSuggestions();

        const { fieldState } = this.props;
        fieldState.onChange(value)
    }

    resetSuggestions = () => {
        if (this.props.type === ReportCompareTypeEnum.TEXT) {
            this.setState({ suggestions: [] });
        }
    }

    onBlur = () => {
        this.setState({ hasFocus: false });
        this.resetSuggestions();
    }

    onFocus = () => {
        this.setState({ hasFocus: true });
        this.searchQueryChanged();
    }

    render() {
        const { fieldState } = this.props;
        const { suggestions } = this.state;

        return (
            <div className='relative'>
                <input
                    className='my-8 w-64 py-2 px-2 text-green-darker leading-normal border rounded label-floating-input bg-transparent'
                    autoComplete='off'
                    ref={this.inputRef}
                    value={fieldState.value || ''}
                    onChange={this.onChange}
                    id='search' type='search'
                    onBlur={this.onBlur}
                    onFocus={this.onFocus}
                    placeholder='' />

                {
                    suggestions.length > 0 &&
                    <ul style={{ zIndex: 9999, top: '75px' }} className='absolute w-64 list-reset border-l border-b border-r rounded bg-white'>
                        {
                            suggestions.map((suggestion, index) => {
                                return (<li className='p-2 truncate cursor-pointer hover:bg-grey-lightest' key={index} onMouseDown={(e) => this.setValue(e, suggestion)}>{suggestion}</li>)
                            })
                        }
                    </ul>
                }
            </div>

        );
    }

}

export default Input;