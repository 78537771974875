import { BaseStore } from './BaseStore';
import { observable, action, makeObservable } from 'mobx';
import { LocationInterface } from './LocationStore';

export interface AgreementInterface {
    "id": string,
    "name": string,
    "locationId": string,
    "location": LocationInterface
}

export class AgreementStore extends BaseStore {
    @observable agreements: Array<AgreementInterface> = [];

    constructor() {
        super();

        makeObservable(this);
    }

    @action
    getAgreements() {
        return this.get('agreements', 'agreements');
    }

    @action
    getAgreementsByLocationId(locationId: string) {
        this.get(`locations/${locationId}/agreements`, 'agreements');
    }

    @action
    createAgreement(agreement: AgreementInterface) {
        return this.create(`locations/${agreement.locationId}/agreements`, agreement, this.agreements);
    }

    @action
    updateAgreement(agreement: AgreementInterface) {
        return this.update(`locations/${agreement.locationId}/agreements/${agreement.id}`, agreement, this.agreements);
    }

    @action
    deleteAgreement(locationId: string, agreementId: string) {
        return this.delete(`locations/${locationId}/agreements/${agreementId}`, agreementId, this.agreements);
    }
}

export const agreementStore = new AgreementStore();