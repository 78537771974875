import * as React from 'react';
import { observer } from 'mobx-react';
import Files from 'components/PurchaseContractDetailsPage/Files';
import { languageStore, ContractDocumentInterface } from 'stores';
import SectionEnum from 'components/StaffContractDetailsPage/SectionEnum';
import { currentStaffContractStore as store } from 'stores';

@observer class ApplicantCV extends React.Component {

    render() {
        const endpoint = 'cvs';
        const storeFiles = store.contract!.cvs;

        return (
            <Files
                hasDocumentTypes={false}
                title={languageStore.get('applicantCV')}
                store={store}
                hash={SectionEnum.APPLICANT_CV}
                files={store.contract!.cvs}
                onAddFileToContract={(document: ContractDocumentInterface) => store.addDocumentToContract(document, endpoint, storeFiles)}
                onUpdateFile={(document: ContractDocumentInterface) => store.updateDocument(document, storeFiles)}
                onDeleteFileFromContract={(document: ContractDocumentInterface) => store.deleteDocument(document, endpoint, storeFiles)}
            />
        )
    }

}

export default ApplicantCV;