import * as React from 'react';
import { observer } from 'mobx-react';
import { FormState } from 'formstate';
import { languageStore } from 'stores';
import ReactTable from 'react-table';
import Form from 'components/Forms/Form';
import { Modal } from 'components/Modal';
import * as _ from 'lodash';
import { mapField } from 'helpers/FormState';
import ShowOnPrint from 'components/ShowOnPrint';

interface T {
    id: string
}

interface Props {
    form?: FormState<any>,
    columns: Array<any>,
    collection: Array<T>,
    generalTitle?: string,
    modalTitle: string,
    onUpdate?: Function,
    onSubmit?: Function,
    onDelete?: Function,
    beforeSubmit?: Function
    children?: React.ReactNode,
    hidePrimaryModalButton?: boolean,
    fullWidth?: boolean,
    isReadOnly?: boolean,
    onHideModal?: Function,
    onShowModal?: Function,
    isShowModal?: boolean,
    overrideModal?: boolean,
    customActionButton?: {
        onClick: Function,
        text: string
    }
}

interface State {
    isShowModal: boolean
}

@observer class DetailsPage extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);

        this.state = {
            isShowModal: false
        }
    }

    componentWillReceiveProps(props: any) {
        if(this.props.overrideModal)
        {
            this.setState({ isShowModal: props.isShowModal });
        }
        
      }

    resetFormByValues(values: Array<string>, haystack: any) {
        const { form } = this.props;
        if (!form) return;

        values.forEach((value) => {
            form.$[value].reset(haystack ? haystack[value] : null);
        })
    }

    resetForm = (id: string | null) => {
        const { form, collection } = this.props;
        if (!form) return;

        const entity = id ? _.find(collection, { id: id }) : null;
        this.resetFormByValues(Object.keys(form.$), entity)
    }

    showModal = (id: string | null) => {
        const { onShowModal } = this.props;
        this.resetForm(id);

        if (onShowModal) {
            onShowModal(id);
        }

        this.setState({ isShowModal: true });
    }

    hideModal = () => {
        const { onHideModal } = this.props;

        if (onHideModal) {
            onHideModal();
        }

        this.setState({ isShowModal: false });
    }

    onSubmit = (): Promise<void> => {
        return new Promise(async (resolve, reject) => {
            const { form, onSubmit, beforeSubmit, onUpdate, collection } = this.props;
            if (!form) return;

            const id = form.$.id.$;
            const match = _.find(collection, { id: id });
            let obj = {};
            Object.keys(form.$).forEach((key) => {
                obj[key] = mapField(form.$[key], match ? match[key] : null);
            })

            if (beforeSubmit) {
                beforeSubmit(obj);
            }

            if (id && onUpdate) {
                onUpdate(obj);
            }
            else if (!id && onSubmit) {
                onSubmit(obj);
            }

            
            resolve();
        })
    }

    onDelete = async (e: React.MouseEvent) => {
        e.preventDefault();

        if (window.confirm(`${languageStore.get('areYouSureYouWantToDelete')}?`)) {
            const { form, onDelete } = this.props;
            const id = form!.$.id.$;
            if (id && onDelete) {
                await onDelete(id);
            }
        }
        
        this.hideModal();
    }


    render() {
        const { isShowModal } = this.state;
        const { form, customActionButton, isReadOnly, fullWidth, hidePrimaryModalButton, columns, modalTitle, collection, children, onDelete, onSubmit } = this.props;
        const isNewEntity = form && !!!form.$.id.$;
        const tableWidth = fullWidth ? 'w-full' : 'w-1/2';

        return (
            <div>
                <div className={`${tableWidth} mx-auto`}>
                    <div className='text-right'>
                        {
                            customActionButton &&
                            <div className={`mb-5 text-right inline-block ${onSubmit ? 'mr-2' : ''}`}>
                                <button onClick={(e) => customActionButton.onClick()} className='bg-comablue-label text-white py-2 px-8 rounded'>
                                    {customActionButton.text}
                                </button>
                            </div>
                        }

                        {
                            onSubmit &&
                            <div className='mb-5 text-right inline-block'>
                                <button onClick={() => this.showModal(null)} className='bg-comablue-label text-white py-2 px-8 rounded'>
                                    {languageStore.get('add')}
                                </button>
                            </div>
                        }
                    </div>

                    <div className='border'>
                        <ShowOnPrint>
                            <ReactTable
                                data={collection.slice()}
                                className={isReadOnly ? 'ReactTable-readonly' : ''}
                                columns={columns}
                                defaultPageSize={99999}
                                defaultSorted={[
                                    {
                                        id: columns[0].id || columns[0].accessor
                                    }
                                ]}
                                showPagination={false}
                                NoDataComponent={() => null}
                                getTdProps={(state: any, rowInfo: any, column: any, instance: any) => {
                                    return {
                                        onClick: (e: React.MouseEvent, handleOriginal: any) => {
                                            if (!isReadOnly) {
                                                const id = rowInfo.original.id;
                                                this.showModal(id);
                                            }
                                        }
                                    };
                                }}
                                minRows={0} />
                        </ShowOnPrint>
                    </div>

                </div>

                {
                    form && isShowModal &&
                    <Form onValidSubmit={this.onSubmit} formState={form}>
                        <Modal
                            title={modalTitle}
                            primaryButton={!hidePrimaryModalButton ? { title: languageStore.get('save') } : undefined}
                            secondaryButton={{ title: languageStore.get(hidePrimaryModalButton ? 'close' : 'cancel'), onClick: this.hideModal }}>

                            {children}

                            {
                                !isNewEntity && onDelete &&
                                <a className='bg-red-dark text-white py-2 px-4 rounded no-underline' href=" " style={{ position: 'absolute', bottom: '33px', left: '40px' }} onClick={(e) => this.onDelete(e)}>{languageStore.get('delete')}</a>
                            }

                        </Modal>
                    </Form>
                }
            </div>
        )
    }

}

export default DetailsPage;