import * as React from 'react';
import { observer } from 'mobx-react';
import { FieldState } from 'formstate';

import './style.css';

interface Props {
    id: string,
    label: string,
    fieldState: FieldState<string>,
    className?: string,
    onBlur?: () => void
}

@observer class Textarea extends React.Component<Props> {

    render() {
        const { fieldState, id, label, className, onBlur } = this.props;

        return (
            <div
                style={fieldState.error ? {border: '1px solid #e4002b'} : undefined}
                className={`relative border rounded mb-8 appearance-none ${className}`}>
                <textarea onBlur={onBlur} style={{ height: '112px' }} className="w-full py-2 px-2 text-green-darker leading-normal rounded bg-transparent" id={id} placeholder={label} value={fieldState.value === null ? undefined : fieldState.value} onChange={(e) => fieldState.onChange(e.target.value)} />
                <label className="whitespace-no-wrap overflow-hidden absolute block text-comablue-label pin-t leading-normal cursor-pointer Textarea-label-floating" htmlFor={id}>
                    {label} {fieldState.error && <span className="text-red text-xs pl-1">({fieldState.error})</span>}
                </label>
            </div>
        )
    }

}

export default Textarea;