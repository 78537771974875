import { observable, action, makeObservable } from 'mobx';
import { BaseStore, BaseContractTypeEnum } from './BaseStore';
import { SubCategoryInterface } from './SubCategoryStore';
import { LocationInterface } from './LocationStore';
import request from 'api/request';
import { DocumentTypeContractTypeEnum } from './BaseContractsStore';

export interface MainCategoryInterface {
    name?: string,
    contractType?: number,
    locationId?: string,
    id: string,
    subCategories: Array<SubCategoryInterface>
}

export interface CreateSubCategoryInterface {
    name: string
}

export interface CreateCategoryInterface {
    name: string,
    contractType: DocumentTypeContractTypeEnum,
    subCategories?: Array<CreateSubCategoryInterface>
}

export class CategoryStore extends BaseStore {
    @observable categories: Array<MainCategoryInterface> = [];
    @observable allCategories: Array<MainCategoryInterface> = [];

    constructor() {
        super();

        makeObservable(this);
    }

    @action
    getCategories(contractType: BaseContractTypeEnum, locationId: string) {
        this.get(`contracts/${contractType}/categories?locationId=${locationId}`, 'categories');
    }

    @action
    getAllCategories() {
        this.get(`categories`, 'allCategories');
    }

    @action
    updateCategory(category: MainCategoryInterface) {
        return this.update(`locations/${category.locationId}/categories/${category.id}`, category, this.allCategories);
    }

    @action
    deleteCategory(locationId: string, categoryId: string) {
        return this.delete(`locations/${locationId}/categories/${categoryId}`, categoryId, this.allCategories);
    }

    @action
    createCategory(category: MainCategoryInterface) {
        return this.create(`locations/${category.locationId}/categories`, category, this.allCategories);
    }

    @action
    createCategories(location: LocationInterface, categories: Array<CreateCategoryInterface>) {
        return new Promise(async(resolve, reject) => {
            try {
                const res = await request.post(`companies/${location.companyId}/locations/${location.id}/categories`, categories);
                resolve(res.data);
            }
            catch(error) {
                reject();
            }
        })
    }

    @action
    duplicateCategories(sourceLocationId: string, targetLocationId: string) {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await request.post(`locations/${targetLocationId}/categories/duplicate`, {
                    sourceLocationId: sourceLocationId
                });

                if (res.data) {
                    this.allCategories = this.allCategories.concat(res.data);
                    resolve(res.data);
                }
                else reject();
            }
            catch(error) {
                reject(error);
            }
        })
    }

    @action
    resetStore() {
        this.categories = [];
        this.allCategories = [];
    }
}

export const categoryStore = new CategoryStore();