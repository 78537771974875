import { BaseStore } from './BaseStore';
import { observable, action, makeObservable } from 'mobx';

export interface TagInterface {
    "id": string,
    "name": string,
    "companyId"?: string,
}

export class TagStore extends BaseStore {
    @observable tags: Array<TagInterface> = [];

    constructor() {
        super();

        makeObservable(this);
    }

    @action
    getTags() {
        this.get('tags', 'tags');
    }

    @action
    createTag(tag: TagInterface) {
        return this.create(`companies/${tag.companyId}/tags`, tag, this.tags);
    }

    @action
    updateTag(tag: TagInterface) {
        return this.update(`companies/${tag.companyId}/tags/${tag.id}`, tag, this.tags);
    }

    @action
    deleteTag(tag: TagInterface) {
        return this.delete(`companies/${tag.companyId}/tags/${tag.id}`, tag.id, this.tags);
    }
}

export const tagStore = new TagStore();