import React from 'react';
import { observer } from 'mobx-react';
import DoughnutWidget from '../DoughnutWidget';
import { DashboardContractTypeEnum, dashboardStore, DistributionInterface, languageStore } from 'stores';
import _ from 'lodash';

interface Props {
    contractType: DashboardContractTypeEnum
}

enum GroupByEnum {
    CATEGORY = 'category',
    DEPARTMENT = 'department',
    RESPONSIBLE = 'responsible',
    SUPPLIER = 'supplier',
    LOCATION = 'location',
    CUSTOMER = 'customer'
}

@observer class DistributionDoughnutWidget extends React.Component<Props> {

    selectRef: any = React.createRef();

    contractTypeToText = (contractType: DashboardContractTypeEnum) => {
        switch (contractType) {
            case DashboardContractTypeEnum.PURCHASE: return languageStore.get('purchaseContracts');
            case DashboardContractTypeEnum.STAFF: return languageStore.get('staffContracts');
            case DashboardContractTypeEnum.SERVICE: return languageStore.get('serviceContracts');
            case DashboardContractTypeEnum.SALES: return languageStore.get('salesContracts');
            default: return '';
        }
    }

    componentDidMount() {
        this.getData(this.props.contractType, GroupByEnum.LOCATION);
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        if (nextProps.contractType !== this.props.contractType) {
            this.getData(nextProps.contractType, GroupByEnum.LOCATION);
            const select = this.selectRef.current;
            select.selectedIndex = 0;
        }
    }

    getData(contractType: DashboardContractTypeEnum, groupBy: GroupByEnum) {
        dashboardStore.getDistribution(contractType, groupBy, false);
    }

    onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const groupBy = e.target.value as GroupByEnum;
        this.getData(this.props.contractType, groupBy);
    }

    mapCollection(distribution: Array<DistributionInterface>) {

        const labels: Array<string> = [];
        const inlineLabels: Array<string> = [];
        const data: Array<number> = [];



        _.orderBy(distribution, 'count', 'desc').forEach((item, i) => {
            data.push(item.count);
            inlineLabels.push(item.count.toString());
            labels.push(item.name);
        })

        return {
            labels,
            inlineLabels,
            data
        }
    }

    render() {
        const { contractType } = this.props;
        const distribution = dashboardStore.distribution;

        const data = this.mapCollection(distribution);
        const totalCount = _.sumBy(distribution, 'count');

        return (
            <DoughnutWidget
                maxItems={5}
                centerLabel={totalCount.toString()}
                contractType={contractType}
                title={<>
                    {this.contractTypeToText(contractType)}
                    <span style={{ float: 'right' }} className='font-thin text-sm uppercase'>
                        <select onChange={this.onChange} ref={this.selectRef}>
                            <option value={GroupByEnum.LOCATION}>{languageStore.get('location')}</option>
                            <option value={GroupByEnum.DEPARTMENT}>{languageStore.get('department')}</option>

                            {
                                contractType !== DashboardContractTypeEnum.STAFF &&
                                <>
                                    <option value={GroupByEnum.CATEGORY}>{languageStore.get('category')}</option>
                                    <option value={GroupByEnum.RESPONSIBLE}>{languageStore.get('responsible')}</option>
                                    {
                                        contractType === DashboardContractTypeEnum.PURCHASE &&
                                        <option value={GroupByEnum.SUPPLIER}>{languageStore.get('supplier')}</option>
                                    }
                                    {
                                        contractType === DashboardContractTypeEnum.SALES &&
                                        <option value={GroupByEnum.CUSTOMER}>{languageStore.get('customer')}</option>
                                    }
                                </>
                            }

                        </select>
                    </span>
                </>}
                labels={data.labels}
                inlineLabels={data.inlineLabels}
                data={data.data}
            />
        )
    }
}

export default DistributionDoughnutWidget;