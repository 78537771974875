import React from 'react';
import { observer } from 'mobx-react';
import { InboxListInterface, languageStore } from 'stores';

interface Props {
    selectedInboxId: string | null,
    inboxes: Array<InboxListInterface>,
    onInboxSelected: (inboxId: string) => void
}

@observer class SidebarNavigation extends React.Component<Props> {

    render() {
        const { selectedInboxId, inboxes, onInboxSelected } = this.props;

        return (
            <div className='w-64'>
                <h3 className='text-sm'>
                    {languageStore.get('inboxes')}
                </h3>
                <ul className='list-reset text-sm'>
                    {
                        inboxes.map((inbox, index) => {
                            const isSelected = inbox.id === selectedInboxId;
                            const selectedClass = 'no-underline text-red text-left font-semibold ';
                            const defaultClass = 'no-underline text-black text-left';

                            return (
                                <li 
                                    key={index}
                                    className='ml-1 my-2'>
                                    <button 
                                        onClick={() => onInboxSelected(inbox.id)}
                                        className={isSelected ? selectedClass : defaultClass}>
                                        {inbox.name} ({inbox.messageCount})
                                    </button>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
        )
    }
}

export default SidebarNavigation;