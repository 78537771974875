import * as React from 'react';
import { observer } from 'mobx-react';
import { FieldState } from 'formstate';
import ContractDropdownItem from 'components/ContractDropdownItem';
import { languageStore } from 'stores';

interface Props {
    fieldState: FieldState<any>
}

export enum ServiceTypeEnum {
    NONE = 0,
    ONE_TIME_PAYMENT = 1,
    FIXED_PAYMENTS = 2,
    RUNNING = 3
}

@observer class SelectServiceType extends React.Component<Props> {

    options = [
        {
            value: ServiceTypeEnum.ONE_TIME_PAYMENT,
            name: languageStore.get('oneTimePayment')
        },
        {
            value: ServiceTypeEnum.FIXED_PAYMENTS,
            name: languageStore.get('fixedPayments')
        },
        {
            value: ServiceTypeEnum.RUNNING,
            name: languageStore.get('running')
        }
    ]

    render() {
        const { fieldState } = this.props;

        return <ContractDropdownItem
            label={languageStore.get('serviceType')}
            placeholderText={languageStore.get('selectServiceType')}
            id='serviceType'
            fieldState={fieldState}
            options={this.options}
            skipSort={true}
            editMode={true} />
    }

}

export default SelectServiceType;