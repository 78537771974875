import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { adminCategoryDetailsStore, MainCategoryInterface } from "stores";

const useCategoryByParams = () => {
    const [category, setCategory] = useState<null | MainCategoryInterface>(null);
    const { id } = useParams<{ id: string }>();

    useEffect(() => {
        const getCategory = async () => {
            if (!adminCategoryDetailsStore.category || adminCategoryDetailsStore.category.id !== id) {
                await adminCategoryDetailsStore.getCategory(id);
            }
            setCategory(adminCategoryDetailsStore.category);
        }

        getCategory();

        return () => {
            adminCategoryDetailsStore.resetStore();
        }
    }, [id])

    return category;
}

export default useCategoryByParams;