import * as React from 'react';
import { observer } from 'mobx-react';
import { FieldState } from 'formstate';
import ContractDropdownItem from 'components/ContractDropdownItem';
import { companyUserStore, languageStore } from 'stores';
import { DropdownOptionInterface } from 'components/Forms/Dropdown';

interface Props {
    fieldState: FieldState<any>,
    label: string,
    id: string,
    companyId: string,
    skipUserIds: Array<string>
}

@observer class SelectCompanyUser extends React.Component<Props> {

    componentDidMount() {
        const { companyId } = this.props;
        companyUserStore.getUsers(companyId);
    }

    render() {
        const { fieldState, id, skipUserIds } = this.props;

        let availUsers: Array<DropdownOptionInterface> = [];
        companyUserStore.users.forEach((user) => {
            if (skipUserIds.indexOf(user.id) !== -1) return;
            
            availUsers.push({
                value: user.id,
                name: user.fullName
            })
        })

        return (
            <ContractDropdownItem
                label={languageStore.get('selectUser')}
                id={id}
                placeholderText={languageStore.get('selectUser')}
                fieldState={fieldState}
                options={availUsers}
                editMode={true} />
        )
    }

}

export default SelectCompanyUser;