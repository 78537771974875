import { observable, action, makeObservable } from 'mobx';
import { BaseStore } from './BaseStore';

export interface SubCategoryInterface {
    name: string,
    categoryId: string,
    id: string
}

export class SubCategoryStore extends BaseStore {
    @observable subCategories: Array<SubCategoryInterface> = [];

    constructor() {
        super();

        makeObservable(this);
    }

    @action
    getAllSubCategories() {
        this.get(`subcategories`, 'subCategories');
    }

    updateSubCategory(locationId: string, category: SubCategoryInterface) {
        return this.update(`locations/${locationId}/categories/${category.categoryId}/subcategories/${category.id}`, category, this.subCategories);
    }

    deleteSubCategory(locationId: string, categoryId: string, subCategoryId: string) {
        return this.delete(`locations/${locationId}/categories/${categoryId}/subcategories/${subCategoryId}`, subCategoryId, this.subCategories);
    }

    createSubCategory(locationId: string, category: SubCategoryInterface) {
        return this.create(`locations/${locationId}/categories/${category.categoryId}/subcategories`, category, this.subCategories);
    }

    @action
    resetStore() {
        this.subCategories = [];
    }
}

export const subCategoryStore = new SubCategoryStore();