import * as React from 'react';
import { observer } from 'mobx-react';
import { Modal, ModalColumn } from 'components/Modal';
import { languageStore, CompanyUserInterface, RoleInterface, rolesStore, createUserStore, userStore, RoleEnum } from 'stores';
import * as _ from 'lodash';
import { hasRole } from 'helpers/RoleHelper';

interface Props {
    onClose: () => void,
    user: CompanyUserInterface | null,

}

interface State {
    selectedIds: Array<string>
}

@observer class AccessRightsModal extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            selectedIds: []
        }
    }

    componentDidMount() {
        const { user } = this.props;
        rolesStore.getRoles(user!.companyId);

        this.setState({
            selectedIds: user!.roles.map((d) => d.id)
        })
    }

    delete = async (e: React.MouseEvent, role: RoleInterface) => {
        e.preventDefault();

        this.setState({
            selectedIds: this.state.selectedIds.filter((depId) => depId !== role.id)
        })
    }

    add = async (e: React.MouseEvent, role: RoleInterface) => {
        e.preventDefault();

        const depIds = this.state.selectedIds;
        depIds.push(role.id);

        this.setState({
            selectedIds: depIds
        })
    }

    onSave = async () => {
        const { user, onClose } = this.props;

        const roles = rolesStore.roles.filter((d) => this.state.selectedIds.indexOf(d.id) !== -1);
        await createUserStore.putRoles(user!.companyId, user!.id, roles);
        user!.roles = roles;

        onClose();
    }

    signedInUserHasRole = (role: RoleEnum) => {
        return !!_.find(userStore.signedInUser.roles, { role: Number(role) });
    }

    addRole = (collection: Array<RoleInterface>, role: RoleEnum) => {
        const existingRole = _.find(rolesStore.roles, { role: Number(role) });
        if (existingRole && !_.some(collection, { role: Number(role) })) {
            collection.push(existingRole);
        }
    }

    filterRolesBySignedInUser = (roles: Array<RoleInterface>) => {
        if (hasRole(RoleEnum.SUPER_ADMIN)) {
            // Superadmins can add and edit all roles
            return roles;
        }
        else {
            // Regular admins can edit the roles that they have themselves.
            // But if they are something like PURCHASE_ADMINS, but not having READ and WRITE
            // privileges, they can still add those roles.
            const filteredRoles = _.clone(userStore.signedInUser.roles);

            if (this.signedInUserHasRole(RoleEnum.PURCHASE_ADMIN)) {
                this.addRole(filteredRoles, RoleEnum.PURCHASE_READ);
                this.addRole(filteredRoles, RoleEnum.PURCHASE_WRITE);
            }
            if (this.signedInUserHasRole(RoleEnum.SALES_ADMIN)) {
                this.addRole(filteredRoles, RoleEnum.SALES_READ);
                this.addRole(filteredRoles, RoleEnum.SALES_WRITE);
            }
            if (this.signedInUserHasRole(RoleEnum.HR_ADMIN)) {
                this.addRole(filteredRoles, RoleEnum.HR_READ);
                this.addRole(filteredRoles, RoleEnum.HR_WRITE);
            }
            if (this.signedInUserHasRole(RoleEnum.SERVICE_ADMIN)) {
                this.addRole(filteredRoles, RoleEnum.SERVICE_READ);
                this.addRole(filteredRoles, RoleEnum.SERVICE_WRITE);
            }

            return _.uniq(filteredRoles) as Array<RoleInterface>;
        }
    }

    render() {
        const { onClose, user } = this.props;
        const { selectedIds } = this.state;

        if (!user) return null;

        let canEdit = false;
        if (userStore.signedInUser.company.hasBillingPlan || hasRole(RoleEnum.SUPER_ADMIN)) {
            canEdit = true;
        }

        let roles = rolesStore.roles || [];
        if (!roles) return null;
        roles = this.filterRolesBySignedInUser(roles);

        return (
            <Modal
                title={languageStore.get('accessRights')}
                primaryButton={{ title: languageStore.get('save'), onClick: this.onSave }}
                secondaryButton={{ title: languageStore.get('close'), onClick: onClose }}>

                <ModalColumn>

                    {
                        roles.map((role: RoleInterface, index: number) => {

                            const hasItem = selectedIds.indexOf(role.id) !== -1;

                            let className = 'mt-2';
                            if (!hasItem) {
                                className += ' text-grey';
                            }

                            return (<div key={index} className={className}>
                                {role.name}

                                {
                                    (canEdit && hasItem) &&
                                    <button className='ml-2 px-2 py-1 text-xs text-red rounded bg-red-lightest hover:bg-red-lighter' onClick={(e) => this.delete(e, role)}>{languageStore.get('remove')}</button>
                                }

                                {
                                    (canEdit && !hasItem) &&
                                    <button className='ml-2 px-2 py-1 text-xs text-green rounded bg-green-lightest hover:bg-green-lighter' onClick={(e) => this.add(e, role)}>{languageStore.get('add')}</button>
                                }
                            </div>)
                        })
                    }

                </ModalColumn>

            </Modal>
        )
    }

}

export default AccessRightsModal;