import * as React from 'react';
import { observer } from 'mobx-react';
import { ADFSHostEnum } from 'stores';
import ADFS from '../ADFS';
import logoImg from './logo.png';

interface State {
    signInError: boolean
}

@observer class VaekstfondenLoginPage extends React.Component<any, State> {

    resource = process.env.REACT_APP_FRONTEND_BASEURL!;
    redirectUri = process.env.REACT_APP_FRONTEND_BASEURL + '/login/vf';
    endpoint = process.env.REACT_APP_ADFS_VF_ENDPOINT;
    clientId = process.env.REACT_APP_ADFS_VF_CLIENT_ID;
    providerId = process.env.REACT_APP_ADFS_VF_PROVIDER_ID!;

    render() {
        return (
            <ADFS
                logoImgSrc={logoImg}
                logoImgAlt='Vækstfonden'
                loginEndpoint={`${this.endpoint}/adfs/oauth2/authorize?response_type=code&client_id=${this.clientId}&resource=${encodeURIComponent(this.resource)}&redirect_uri=${encodeURIComponent(this.redirectUri)}&RedirectToIdentityProvider=AD+AUTHORITY`}
                adfsHost={ADFSHostEnum.VAEKSTFONDEN}
                redirectUri={this.redirectUri}
                providerId={this.providerId}
            />
        )
    }
}

export default VaekstfondenLoginPage;