import { ContractAlarmInterface, ContractDocumentInterface, VerificationEntryInterface, CurrentContractBaseInterface } from './CurrentContractStore';
import { ServiceInterface } from './ServiceStore';
import { SupplierInterface, ContactPeopleInterface } from './SupplierStore';
import { MainCategoryInterface } from './CategoryStore';
import { SubCategoryInterface } from './SubCategoryStore';
import { DepartmentInterface } from './DepartmentStore';
import { ContractNoteInterface, ContractStatusEnum } from './BaseContractsStore';
import { CurrencyInterface } from './CurrencyStore';
import { computed, action, makeObservable } from 'mobx';
import { HasCustomerContractStore } from './HasCustomerContractStore';
import { hasRole } from 'helpers/RoleHelper';
import { RoleEnum } from './RolesStore';
import { DashboardContractTypeEnum } from './DashboardStore';

export enum ServiceStatusEnum {
    AGREED = 1,
    DELETED_OR_HIDDEN = 2
}

export interface ServiceContractServiceInterface {
    "id": string,
    "serviceId": string,
    "service": ServiceInterface,
    "placement": string,
    "model": string,
    "inspection": string,
    "presureTestning": string,
    "remark": string,
    "serviceStatus": ServiceStatusEnum,
    "serviceContractId": string,
    "locationName": string,
    "serviceName": string
}

export interface FullServiceContractInterface extends CurrentContractBaseInterface {
    "serviceContractServices": Array<ServiceContractServiceInterface>,
    "dealNumber": string,
    "serialNumber": string,
    "serviceDate": string,
    "serviceNoticeDays": number,
    "address": string,
    "postCode": string,
    "city": string,
    "warranty": true,
    "warrantyDescription": string,
    "customerId": string,
    "customer": SupplierInterface,
    "description": string,
    "contractSum": number,
    "indexAdjustment": number,
    "paymentTerms": string,
    "contractDateCreated": string,
    "renewalDate": string,
    "renewalNoticeDays": number,
    "expirationDate": string,
    "expirationNoticeDays": number,
    "specialCircumstances": true,
    "specialCircumstancesDiscription": string,
    "contractStatus": ContractStatusEnum,
    "contractTypeId": string,
    "contractType": {
        "id": string,
        "name": string,
        "eType": number,
        "locationId": string
    },
    "categoryId": string,
    "category": MainCategoryInterface,
    "subCategoryId": string,
    "subCategory": SubCategoryInterface,
    "contactPeople": Array<ContactPeopleInterface>,
    "departmentId": string,
    "department": DepartmentInterface,
    "currencyId": string,
    "currency": CurrencyInterface,
    "responsibleUserId": string,
    "responsibleUser": {
        "id": string,
        "fullName": string,
        "companyId": string
    },
    "notes": Array<ContractNoteInterface>,
    "alarms": Array<ContractAlarmInterface>,
    "contractDocuments": Array<ContractDocumentInterface>,
    "contractCorrespondances": Array<ContractDocumentInterface>,
    "contractVerificationEntries": Array<VerificationEntryInterface>,
    "contractContractReferences": Array<any>,
    "createdOn": string,
    "createdById": string,
    "modifiedById": string,
}

export class CurrentServiceContractStore extends HasCustomerContractStore<FullServiceContractInterface> {

    constructor() {
        super();

        makeObservable(this);

        this.contractEndpoint = 'service';
        this.contractType = DashboardContractTypeEnum.SERVICE;
    }

    @computed
    get isDraft() {
        return !!this.contract && this.contract.contractStatus === ContractStatusEnum.Draft;
    }

    @computed
    get canEdit() {
        return hasRole(RoleEnum.SERVICE_WRITE) || hasRole(RoleEnum.SERVICE_ADMIN);
    }

    @action
    createService(service: ServiceContractServiceInterface) {
        return this.create(`contracts/service/${this.contract!.id}/services`, service, this.contract!.serviceContractServices);
    }

    @action
    updateService(service: ServiceContractServiceInterface) {
        return this.update(`contracts/service/${this.contract!.id}/services/${service.id}`, service, this.contract!.serviceContractServices);
    }

    @action
    deleteService(serviceId: string) {
        return this.delete(`contracts/service/${this.contract!.id}/services/${serviceId}`, serviceId, this.contract!.serviceContractServices);
    }

}

export const currentServiceContractStore = new CurrentServiceContractStore();