import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ContractStatusEnum, currentPurchaseContractStore, currentSalesContractStore, currentServiceContractStore, currentStaffContractStore, documentStore, DocumentUrlInterface, FullPurchaseContractInterface, FullSalesContractInterface, FullServiceContractInterface, FullStaffContractInterface, InboxMessageInterface, inboxMessageStore, inboxStore, languageStore, linkInboxStore, navigationStore, RoleEnum, StaffContractStatusEnum } from "stores";
import Breadcrumb from 'components/Breadcrumb';
import { canAccess, ModuleEnum } from "helpers/ModuleHelper";
import { BaseContractTypeEnum } from "stores/BaseStore";
import { hasRole } from "helpers/RoleHelper";

enum StepEnum {
    START,
    SELECT_NEW_CONTRACT_TYPE
}

const InboxMessagePage = observer(() => {
    const { inboxId, messageId } = useParams<{ inboxId: string, messageId: string }>();
    const [step, setStep] = useState(StepEnum.START);
    const [documentUrl, setDocumentUrl] = useState<string | null>(null);

    useEffect(() => {
        async function getMessagesAsync() {
            const message = await inboxMessageStore.getMessage(inboxId, messageId) as InboxMessageInterface;
            if (!message) return;
            const doc = await documentStore.getDocumentUrl(message.document.minioName) as DocumentUrlInterface;
            setDocumentUrl(doc.url);
        }

        getMessagesAsync();

        return () => {
            inboxMessageStore.resetStore();
        }
    }, [inboxId, messageId])

    const message = inboxMessageStore.message;
    if (!message || !documentUrl) return null;

    const createNewContract = async (contractType: BaseContractTypeEnum) => {
        let contract;

        switch (contractType) {
            case BaseContractTypeEnum.PURCHASE:
                contract = await currentPurchaseContractStore.postDraftContract(ContractStatusEnum.Draft) as FullPurchaseContractInterface;
                await currentPurchaseContractStore.addDocumentToContract(message!.document, 'documents', currentPurchaseContractStore.contract!.contractDocuments);
                break;
            case BaseContractTypeEnum.STAFF:
                contract = await currentStaffContractStore.postDraftContract(StaffContractStatusEnum.Draft) as FullStaffContractInterface;
                await currentStaffContractStore.addDocumentToContract(message!.document, 'documents', currentStaffContractStore.contract!.contractDocuments);
                break;
            case BaseContractTypeEnum.SERVICE:
                contract = await currentServiceContractStore.postDraftContract(ContractStatusEnum.Draft) as FullServiceContractInterface;
                await currentServiceContractStore.addDocumentToContract(message!.document, 'documents', currentServiceContractStore.contract!.contractDocuments);
                break;
            case BaseContractTypeEnum.SALES:
                contract = await currentSalesContractStore.postDraftContract(ContractStatusEnum.Draft) as FullSalesContractInterface;
                await currentSalesContractStore.addDocumentToContract(message!.document, 'documents', currentSalesContractStore.contract!.contractDocuments);
                break;
        }

        if (contract) {
            await inboxStore.deleteMessage(message.inboxId, message.id);
            linkInboxStore.resetSource();
            navigationStore.push(`/${contractType}/${contract.id}`);
        }
    }

    const deleteMessage = async () => {
        if (!window.confirm(languageStore.get('areYouSureYouWantToDeleteThisMessage'))) return;

        await inboxStore.deleteMessage(message.inboxId, message.id);
        navigationStore.push('/inbox');
    }

    const attachToExistingContract = () => {
        linkInboxStore.setSource(message);

        if (canAccess(ModuleEnum.PURCHASE)) navigationStore.push('/purchase');
        else if (canAccess(ModuleEnum.STAFF)) navigationStore.push('/staff');
        else if (canAccess(ModuleEnum.SALES)) navigationStore.push('/sales');
        else if (canAccess(ModuleEnum.SERVICE)) navigationStore.push('/service');
    }

    return (
        <div>
            <Breadcrumb
                backlinkTo='/inbox'
                backlinkTitle={languageStore.get('inbox')}
                title={message.subject}
            />

            <div className='mt-10 mx-auto' style={{ width: '900px' }}>
                {
                    step === StepEnum.START &&
                    <div className='mb-6 text-center'>
                        <button
                            className='bg-comablue-label text-white py-2 px-8 rounded'
                            onClick={() => setStep(StepEnum.SELECT_NEW_CONTRACT_TYPE)}>
                            {languageStore.get('createNewContract')}
                        </button>
                        <button
                            className='bg-comablue-label text-white py-2 px-8 rounded ml-2'
                            onClick={() => attachToExistingContract()}>
                            {languageStore.get('attachToExistingContract')}
                        </button>
                        <button
                            className='bg-red-dark text-white py-2 px-8 rounded ml-2'
                            onClick={() => deleteMessage()}>
                            {languageStore.get('delete')}
                        </button>
                    </div>
                }

                {
                    step === StepEnum.SELECT_NEW_CONTRACT_TYPE &&
                    <div className='mb-6 text-center'>
                        <h2 className='mt-0 mb-6'>
                            {languageStore.get('contractType')}:
                        </h2>
                        {
                            (canAccess(ModuleEnum.PURCHASE) && (hasRole(RoleEnum.PURCHASE_ADMIN) || hasRole(RoleEnum.PURCHASE_WRITE))) &&
                            <button
                                className='bg-comablue-label text-white py-2 px-8 rounded ml-1 mr-1'
                                onClick={() => createNewContract(BaseContractTypeEnum.PURCHASE)}>
                                {languageStore.get('purchaseContract')}
                            </button>
                        }
                        {
                            (canAccess(ModuleEnum.STAFF) && (hasRole(RoleEnum.HR_ADMIN) || hasRole(RoleEnum.HR_WRITE))) &&
                            <button
                                className='bg-comablue-label text-white py-2 px-8 rounded ml-1 mr-1'
                                onClick={() => createNewContract(BaseContractTypeEnum.STAFF)}>
                                {languageStore.get('staffContract')}
                            </button>
                        }
                        {
                            (canAccess(ModuleEnum.SERVICE) && (hasRole(RoleEnum.SERVICE_ADMIN) || hasRole(RoleEnum.SERVICE_WRITE))) &&
                            <button
                                className='bg-comablue-label text-white py-2 px-8 rounded ml-1 mr-1'
                                onClick={() => createNewContract(BaseContractTypeEnum.SERVICE)}>
                                {languageStore.get('serviceContract')}
                            </button>
                        }
                        {
                            (canAccess(ModuleEnum.SALES) && (hasRole(RoleEnum.SALES_ADMIN) || hasRole(RoleEnum.SALES_WRITE))) &&
                            <button
                                className='bg-comablue-label text-white py-2 px-8 rounded ml-1 mr-1'
                                onClick={() => createNewContract(BaseContractTypeEnum.SALES)}>
                                {languageStore.get('salesContract')}
                            </button>
                        }
                        <button
                            className='bg-grey-darker text-white py-2 px-8 rounded ml-1 mr-1'
                            onClick={() => setStep(StepEnum.START)}>
                            {languageStore.get('back')}
                        </button>
                    </div>
                }

                <iframe
                    width={900}
                    height={1230}
                    className='rounded border'
                    src={documentUrl}
                    allowFullScreen
                    title='inbox'
                />
            </div>
        </div>
    )

})

export default InboxMessagePage;