import { BaseStore } from './BaseStore';
import { observable, computed, action, makeObservable } from 'mobx';
import { InboxMessageInterface } from './InboxStore';

export class LinkInboxStore extends BaseStore {
    @observable sourceInboxMessage: InboxMessageInterface | null = null;

    @action
    setSource = (message: InboxMessageInterface) => {
        this.sourceInboxMessage = message;
    }

    @action
    resetSource = () => {
        this.sourceInboxMessage = null;
    }

    constructor() {
        super();

        makeObservable(this);
    }

    @computed
    get linkInboxIsActive() {
        return !!this.sourceInboxMessage;
    }
}

export const linkInboxStore = new LinkInboxStore();