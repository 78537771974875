import axios from 'axios';

const request = axios.create();

export interface CVRApiInterface {
    name: string,
    address: string,
    zipcode: string,
    city: string,
}

export function getCVRdetails(vatNumber: string) {
    return new Promise(async (resolve, reject) => {
        
        try {
            const res = await request.get(`https://cvrapi.dk/api?vat=${vatNumber}&country=dk&token=rptkvmkxrktgfrmzp26jvcec4smwwfbj`);
            if (res.data) {
                resolve(res.data as CVRApiInterface);
            }
            else reject();
        }
        catch(error) {
            reject(error);
        }
    })
}