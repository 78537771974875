import * as React from 'react';
import { observer } from 'mobx-react';
import { languageStore } from 'stores';
import Requirement from 'components/ForceNewPassword/Requirement';

interface Props {
    className?: string,
    style?: Object,
    currentValue: string
}

@observer class PasswordRequirements extends React.Component<Props> {

    render() {
        const { className, style, currentValue } = this.props;

        return (
            <div className={className} style={style}>
                <p className="mt-12 mb-4 font-bold">
                    {languageStore.get('passwordRequirements')}:
                </p>
                <ul className="text-xs list-reset">
                    <Requirement regexPattern='[A-Z]+' currentValue={currentValue} label={languageStore.get('atLeastOneUppercase')} />
                    <Requirement regexPattern='[a-z]+' currentValue={currentValue} label={languageStore.get('atLeastOneLowercase')} />
                    <Requirement regexPattern='[0-9]+' currentValue={currentValue} label={languageStore.get('atLeastOneDigit')} />
                    <Requirement regexPattern='[\W|_]' currentValue={currentValue} label={languageStore.get('atLeastOneSpecialCharacter')} />
                    <Requirement regexPattern='[^]{8,}' currentValue={currentValue} label={languageStore.get('minimumEightCharacters')} />
                </ul>
            </div>
        )
    }

}

export default PasswordRequirements;