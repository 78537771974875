import * as React from 'react';

interface SplitterProps {
    className?: string
}

/** Vertical line for illustrating content seperation in header */
const Splitter: React.SFC<SplitterProps> = (props) => {
    return (
        <svg width="2px" height="25px" viewBox="0 0 2 25" version="1.1" className={`opacity-25 ${props.className}`}>
            <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="TopMenu" transform="translate(-1300.000000, -12.000000)" fill="#FFFFFF">
                    <rect id="Rectangle-Copy" x="1300" y="12" width="2" height="25"></rect>
                </g>
            </g>
        </svg>
    )
}

export default Splitter;