import * as React from 'react';
import { observer } from 'mobx-react';
import logoImg from 'components/Forms/Login/logoColor.svg';
import Form from 'components/Forms/Form';
import { FormState, FieldState } from 'formstate';
import { email, required } from 'helpers/FormValidation';
import Input from 'components/Forms/Input';
import { languageStore, navigationStore, authStore } from 'stores';

interface State {
    emailSent: boolean
}

@observer class ForgotPasswordPage extends React.Component<any, State> {

    constructor(props: any) {
        super(props);

        this.state = {
            emailSent: false
        }
    }

    form = new FormState({
        email: new FieldState('').validators(required, email)
    })

    gotoLogin(e: React.MouseEvent) {
        e.preventDefault();

        navigationStore.replace(authStore.loginPage);
    }

    onSubmit = () => {
        const email = this.form.$.email.$;
        authStore.resetPassword(email);

        this.setState({ emailSent: true })
    }

    render() {
        const { emailSent } = this.state;

        const form = this.form.$;

        return (
            <div className="font-sans text-sm rounded w-full max-w-xs mx-auto mt-20 px-8 pt-8 pb-8">
                <img src={logoImg} alt="COMA System" className="mb-12" />

                <Form onValidSubmit={this.onSubmit} formState={this.form}>

                    {
                        !emailSent &&
                        <>
                            <Input fieldState={form.email} id='email' label={languageStore.get('email')} />

                            <div className="flex items-center justify-between">
                                <button type="submit" className="bg-comablue-label hover:bg-comablue text-white py-2 px-8 rounded">
                                    {languageStore.get('Reset password')}
                                </button>
                                <a onClick={(e) => this.gotoLogin(e)} className="inline-block align-baseline text-grey-dark hover:text-grey-darker" href=" ">
                                    {languageStore.get('backToLogin')}
                                </a>
                            </div>
                        </>
                    }

                    {
                        emailSent &&

                        <div className="text-center">
                            <h1>
                                {languageStore.get('anEmailWithFurtherInstructionsHasBeenSent')}.
                            </h1>
                            <a onClick={(e) => this.gotoLogin(e)} className="inline-block align-baseline text-grey hover:text-grey-darker mt-4" href=" ">
                                {languageStore.get('backToLogin')}
                            </a>
                        </div>
                    }


                </Form>
            </div>
        )
    }

}

export default ForgotPasswordPage;