import * as React from 'react';
import { observer } from 'mobx-react';
import SectionEnum from '../SectionEnum';
import { currentSalesContractStore as store, ContractDocumentInterface, languageStore, DocumentTypeContractTypeEnum, userStore } from 'stores';
import Files from 'components/PurchaseContractDetailsPage/Files';

@observer class Documents extends React.Component {

    render() {
        const company = userStore.signedInUser.company;
        const allowAddo = company.allowAddo;
        const allowTemplates = company.allowTemplates;

        return (
            <Files
                hasDocumentTypes={true}
                contractType={DocumentTypeContractTypeEnum.SALES}
                store={store}
                title={languageStore.get('contracts')}
                hash={SectionEnum.FILES_CONTRACT}
                files={store.contract!.contractDocuments}
                onAddFileToContract={(document: ContractDocumentInterface) => store.addDocumentToContract(document, 'documents', store.contract!.contractDocuments)}
                onUpdateFile={(document: ContractDocumentInterface) => store.updateDocument(document, store.contract!.contractDocuments)}
                onDeleteFileFromContract={(document: ContractDocumentInterface) => store.deleteDocument(document, 'documents', store.contract!.contractDocuments)}
                allowAddo={allowAddo}
                allowTemplates={allowTemplates} />
        )
    }

}

export default Documents;