import * as React from 'react';
import { observer } from 'mobx-react';
import { FormState, FieldState } from 'formstate';
import { userStore, languageStore, educationStore, EducationInterface, documentStore, DocumentUrlInterface, StaffEducationInterface, ContractDocumentInterface } from 'stores';
import { currentStaffContractStore as store } from 'stores';
import TableGreenHeader from 'components/Table/TableGreenHeader';
import * as _ from 'lodash';
import BaseContractTable from '../BaseContractTable';
import SectionEnum from '../SectionEnum';
import { ModalColumn } from 'components/Modal';
import SelectEducation from './SelectEducation';
import ContractDateItem from 'components/ContractDateItem';
import ContractDetailsItem from 'components/ContractDetailsItem';
import SelectUser from '../Overview/SelectUser';
import UploadFile from 'components/Forms/UploadFile';
import { required } from 'helpers/FormValidation';
import { formatDate } from 'helpers/DateHelper';
import { formatUser, formatStaffContractDetailsStatus } from 'helpers/FormatHelper';
import SelectStaffContractDetailsStatus from '../SelectStaffContractDetailsStatus';
import { reaction, IReactionDisposer } from 'mobx';
import numeral from 'numeral';

interface State {
    documents: Array<ContractDocumentInterface>
}

@observer class EducationDetails extends React.Component<any, State> {

    form = new FormState({
        id: new FieldState<string | null>(null),
        educationLevelId: new FieldState(null).validators(required),
        passed: new FieldState(null),
        price: new FieldState(null),
        renewalDate: new FieldState(null),
        accountableUserId: new FieldState(null).validators(required),
        documentId: new FieldState<string | null>(null),
        staffContractDetailsStatus: new FieldState(null).validators(required)
    })

    reactionDisposer: IReactionDisposer | any;

    constructor(props: any) {
        super(props);

        this.state = {
            documents: []
        }
    }

    componentDidMount() {
        userStore.getUsers(false);

        if (store.contract && store.contract.department) {
            const locationId = store.contract!.department.locationId;
            educationStore.getEducations(locationId);
        }

        this.reactionDisposer = reaction(() => store.currentLocationId, (locationId) => {
            if (!locationId) {
                educationStore.resetStore();
                return;
            }

            if (locationId && store.contract &&
                ((store.contract.department !== null && store.contract.department.locationId !== locationId) ||
                (store.contract && store.contract!.department === null))) {

                educationStore.getEducations(locationId!);
            }
        })

    }

    componentWillUnmount() {
        this.reactionDisposer();
    }

    openFile = async (e: React.MouseEvent, minioName: string) => {
        e.preventDefault();
        e.stopPropagation();

        const endpoint = await documentStore.getDocumentUrl(minioName) as DocumentUrlInterface;
        window.open(endpoint.url);
    }

    uploadFile = async (document: ContractDocumentInterface) => {
        this.setState({ documents: [...this.state.documents, document] });
    }

    onClose = () => {
        this.setState({ documents: [] })
    }

    onCreateEducationDetails = async (details: StaffEducationInterface) => {
        const createdEducation = await store.createEducationDetails(details) as StaffEducationInterface;
        await this.addDocumentsToEducation(createdEducation.id);
    }

    addDocumentsToEducation = async (educationId: string) => {
        const { documents } = this.state;

        for (const doc of documents) {
            await store.addDocumentToEducation(educationId, doc);
        }
    }

    onUpdateEducationDetails = async (details: StaffEducationInterface) => {
        await store.updateEducationDetails(details);
        await this.addDocumentsToEducation(details.id);
    }

    deleteDocument = async (educationId: string, documentId: string) => {
        if (window.confirm(languageStore.get('areYouSureYouWantToArchiveDocument'))) {
            await store.removeDocumentFromEducation(educationId, documentId);
        }
    }

    formatNumber = (value: any) => {
        
        if(!value)
        {
            return "";   
        }

        const parsedValue = parseFloat(String(value));
        var number = numeral(parsedValue);
        return number.format("0,0.00")
    }

    render() {
        const { documents } = this.state;
        const data = store.contract!.educations || [];
        const users = userStore.users;
        const educations = educationStore.educations || [];
        const form = this.form.$;

        let existingDocs: Array<ContractDocumentInterface> = [];
        if (form.id.$) {
            const education = _.find(data, { id: form.id.$ }) as StaffEducationInterface;
            if (education) {
                existingDocs = education.documents || [];
            }
        }

        const columns = [
            {
                Header: <TableGreenHeader value={languageStore.get('educationDetails')} />,
                id: 'educationDetails',
                accessor: (d: any) => {
                    const education = _.find(educations, { id: d.educationLevelId }) as EducationInterface;
                    return education ? education.name : '-';
                }
            },
            {
                Header: <TableGreenHeader value={languageStore.get('passed')} />,
                id: 'passed',
                accessor: (d: any) => formatDate(d.passed)
            },
            {
                Header: <TableGreenHeader value={languageStore.get('price')} />,
                id: 'price',
                accessor: (d: any) => {
                    const education = _.find(data, { id: d.id }) as StaffEducationInterface;
                    return education.price ? this.formatNumber(education.price) : '';
                }
            },
            {
                Header: <TableGreenHeader value={languageStore.get('renewalDate')} />,
                id: 'renewalDate',
                accessor: (d: any) => formatDate(d.renewalDate)
            },
            {
                Header: <TableGreenHeader value={languageStore.get('files')} />,
                id: 'files',
                accessor: (d: any) => {
                    const education = _.find(data, { id: d.id }) as StaffEducationInterface;
                    return education.documents && education.documents.length > 0 ? education.documents.map((d) => d.fileName).join(', ') : '';
                }
            },
            {
                Header: <TableGreenHeader value={languageStore.get('responsible')} />,
                id: 'accountableUserId',
                accessor: (d: any) => formatUser(users, d.accountableUserId)
            },
            {
                Header: <TableGreenHeader value={languageStore.get('status')} />,
                id: 'status',
                accessor: (d: any) => formatStaffContractDetailsStatus(d.staffContractDetailsStatus)
            }
        ]

        return (
            <BaseContractTable
                form={this.form}
                onUpdate={this.onUpdateEducationDetails}
                onSubmit={this.onCreateEducationDetails}
                onDelete={(id: string) => store.deleteEducationDetails(id)}
                collection={data}
                columns={columns}
                onClose={this.onClose}
                tableTitle={languageStore.get('educationDetails')}
                modalTitle={languageStore.get('educationDetails')}
                store={store}
                hash={SectionEnum.EDUCATION_DETAILS}>

                <ModalColumn>

                    <SelectEducation
                        fieldState={form.educationLevelId}
                        editMode={true} />

                    <ContractDateItem
                        fieldState={form.passed}
                        id='passed'
                        editMode={true}
                        label={languageStore.get('passed')} />

                    <ContractDetailsItem
                        fieldState={form.price}
                        id='price'
                        type='number'
                        editMode={true}
                        label={languageStore.get('price')} />

                    <ContractDateItem
                        fieldState={form.renewalDate}
                        id='renewalDate'
                        editMode={true}
                        label={languageStore.get('renewalDate')} />

                    <SelectUser
                        label={languageStore.get('responsible')}
                        id='accountableUserId'
                        fieldState={form.accountableUserId}
                        editMode={true} />


                    <label className='text-comablue-label text-sm block font-bold mb-1'>{languageStore.get('files')}</label>

                    {
                        existingDocs.map((document, index) => {
                            return (
                                <p
                                    key={index}
                                    className='text-black mt-2 cursor-pointer'>
                                    <span onClick={(e) => this.openFile(e, document!.minioName)}>
                                        {document.fileName}
                                    </span>
                                    <span className='text-xs ml-4 text-red' onClick={() => this.deleteDocument(form.id.value!, document.id)}>
                                        ({languageStore.get('delete')})
                                    </span>
                                </p>
                            )
                        })
                    }

                    {
                        documents.map((document, index) => {
                            return (
                                <p
                                    key={index}
                                    className='text-black mt-2 cursor-pointer'>
                                    <span onClick={(e) => this.openFile(e, document!.minioName)}>
                                        {document.fileName}
                                    </span>
                                    <span className='text-xs ml-4'>
                                        ({languageStore.get('readyToBeSaved')})
                                    </span>
                                </p>
                            )
                        })
                    }

                    <UploadFile onSubmit={this.uploadFile}>

                        <button onClick={(e) => e.preventDefault()} className='bg-green text-white py-2 px-8 rounded block mt-2'>
                            {languageStore.get('upload')}
                        </button>

                    </UploadFile>

                    <SelectStaffContractDetailsStatus
                        fieldState={form.staffContractDetailsStatus}
                        editMode={true} />

                </ModalColumn>

            </BaseContractTable>
        )
    }

}

export default EducationDetails;