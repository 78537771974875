// import request from 'api/request';
import { BaseStore } from './BaseStore';
import { observable, action, makeObservable } from 'mobx';
import { DepartmentInterface } from './DepartmentStore';
import { LocationInterface } from './LocationStore';
import request from 'api/request';
import * as _ from 'lodash';
import { RoleInterface } from './RolesStore';
import { replaceOrAddObjectById } from 'helpers/CollectionHelper';
import { UserTwoFactorAuthTypeEnum } from './AuthStore';
import { PagingResult } from './BaseContractsStore';
import { ActivityInterface } from './ActivityStore';

export interface UserContractsInterface {
    "identification": string,
    "contractType": number,
    "purchaseContractId": string | null,
    "staffContractId": string | null,
    "salesContractId": string | null,
    "serviceContractId": string | null,
    "departmentId": string,
    "description": string | null
}

export interface CompanyUserInterface {
    "fullName": string,
    "email": string,
    "companyId": string,
    "allowSuperAdminLogin": boolean,
    "external": boolean,
    "lastLogIn": string,
    "language": number,
    "id": string,
    "departments": Array<DepartmentInterface>,
    "locations": Array<LocationInterface>,
    "roles": Array<RoleInterface>,
    "userContracts": Array<UserContractsInterface>,
    "requestedDelete": boolean,
    "disabled": boolean,
    "deleteDate": string,
    "twoFactorAuthRequired": boolean,
    "twoFactorAuthType": UserTwoFactorAuthTypeEnum,
}

export interface UserAssignedDepartmentInterface {
    "id": string,
    "name": string,
    "locationId": string,
    "location": LocationInterface
}

export interface UserReassignDepartmentInterface {
    "departmentId": string,
    "reassingToUserId": string
}

export class CompanyUserStore extends BaseStore {
    @observable users: Array<CompanyUserInterface> = [];
    @observable availableUserContracts: Array<UserContractsInterface> = [];
    @observable assignedDepartments: Array<UserAssignedDepartmentInterface> = [];
    @observable activityLogs: PagingResult<ActivityInterface> | null = null;

    constructor() {
        super();
        makeObservable(this);
    }

    @action
    resetStore() {
        this.users = [];
        this.availableUserContracts = [];
        this.assignedDepartments = [];
        this.activityLogs = null;
    }

    @action
    getUsers(companyId: string, force: boolean = true) {
        return this.get(`companies/${companyId}/users`, 'users', force);
    }

    @action
    setUser(user: CompanyUserInterface) {
        const users = this.users;
        replaceOrAddObjectById(user.id, users, user);
        this.users = users;
    }

    @action
    updateUser(user: CompanyUserInterface) {
        return this.update(`companies/${user.companyId}/users/${user.id}`, user, this.users);
    }

    @action
    getAssignedDepartments(companyId: string, userId: string) {
        return this.get(`companies/${companyId}/users/${userId}/assigned`, 'assignedDepartments');
    }

    @action
    resetAssignedDepartments() {
        this.assignedDepartments = [];
    }

    @action
    reassignDepartment(companyId: string, userId: string, values: Array<UserReassignDepartmentInterface>) {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await request.post(`companies/${companyId}/users/${userId}/reassign`, values);
                resolve(res.data);
            }
            catch (error) {
                reject(error);
            }

        })
    }

    @action
    requestDeleteUser(companyId: string, userId: string) {
        return this.delete(`companies/${companyId}/users/${userId}`, userId, this.users);
    }

    @action
    getUserById(companyId: string, userId: string) {
        return this.get(`companies/${companyId}/users/${userId}`, 'users')
    }

    @action
    getAvailableUserContracts(companyId: string, userId: string) {
        return this.get(`companies/${companyId}/users/${userId}/availableusercontracts`, 'availableUserContracts');
    }

    @action
    resetAvailableUserContracts() {
        this.availableUserContracts = [];
    }

    @action
    deleteUser(user: CompanyUserInterface, force: boolean = false) {
        return this.delete(`companies/${user.companyId}/users/${user.id}?force=${force}`, user.id, this.users);
    }

    @action
    setDepartments(companyId: string, userId: string, departments: Array<DepartmentInterface>) {
        return new Promise(async (resolve, reject) => {
            try {
                const user = _.find(this.users, { id: userId }) as CompanyUserInterface;
                const res = await request.put(`companies/${companyId}/users/${userId}/departments`, departments.map((d) => d.id));

                user.departments = departments;
                resolve(res.data);
            }
            catch (error) {
                reject(error);
            }
        })
    }

    @action
    setLocations(companyId: string, userId: string, locations: Array<LocationInterface>) {
        return new Promise(async (resolve, reject) => {
            try {
                const user = _.find(this.users, { id: userId }) as CompanyUserInterface;
                const res = await request.put(`companies/${companyId}/users/${userId}/locations`, locations.map((l) => l.id));

                user.locations = locations;
                resolve(res.data);
            }
            catch (error) {
                reject(error);
            }
        })
    }

    @action
    setUserContracts(companyId: string, userId: string, contracts: Array<UserContractsInterface>) {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await request.put(`companies/${companyId}/users/${userId}/usercontracts`, contracts);

                resolve(res.data);
            }
            catch (error) {
                reject(error);
            }
        })
    }

    @action
    deleteFromContract(companyId: string, userId: string, contract: UserContractsInterface) {
        return new Promise(async (resolve, reject) => {
            try {
                const user = _.find(this.users, { id: userId }) as CompanyUserInterface;
                user.userContracts = user.userContracts.filter((uc) => {
                    return !(uc.contractType === contract.contractType
                        && uc.identification === contract.identification
                        && uc.purchaseContractId === contract.purchaseContractId
                        && uc.salesContractId === contract.salesContractId
                        && uc.serviceContractId === contract.serviceContractId
                        && uc.staffContractId === contract.staffContractId)
                })

                const res = await request.put(`companies/${companyId}/users/${userId}/usercontracts`, user.userContracts);

                resolve(res.data);
            }
            catch (error) {
                reject(error);
            }
        })
    }

    @action
    resetTwoFactorAuthentication = (companyId: string, userId: string) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await request.post(`companies/${companyId}/users/${userId}/reset-2fa`);
                resolve(res);
            }
            catch(error) {
                reject(error);
            }
        })
    }

    @action
    getActivityLogs = (userId: string, pagenumber: number, pagesize: number, fromDate?: string, toDate?: string): Promise<void> => {
        return new Promise(async (resolve, reject) => {
            try {
                let url = `activities?userId=${userId}&pagenumber=${pagenumber}&pagesize=${pagesize}`;
                if (fromDate) {
                    url += `&from=${fromDate}`;
                }

                if (toDate) {
                    url += `&to=${toDate}`;
                }

                const res = await request.get(url);
                if (pagenumber === 1) {
                    this.activityLogs = res.data;
                }
                else {
                    const data = this.activityLogs!.results;
                    const newData = data.concat(res.data.results)
                    this.activityLogs = res.data;
                    this.activityLogs!.results = newData;
                }

                resolve();
            }
            catch(error) {
                reject(error);
            }
        })
    }

}

export const companyUserStore = new CompanyUserStore();