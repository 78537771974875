import * as i18next from 'i18next';
import * as XHR from 'i18next-xhr-backend';
import { observable, action, when, makeObservable, runInAction } from 'mobx';
import { userStore } from './UserStore';

export enum LanguageEnum {
    NOT_SET = 0,
    ENGLISH = 1,
    DANISH = 2,
    NORWEGIAN = 3,
    SWEDISH = 4,
    FINNISH = 5,
    FRENCH = 6,
    GERMAN = 7,
    SPANISH = 8
}

export class LanguageStore {

    @observable t: Function | any;

    constructor() {
        makeObservable(this);
        i18next.use(XHR).init({
            load: 'languageOnly',
            fallbackLng: 'en', // If no translation is set for a specific word, we will simply fallback to english
            // lng: 'da',
            // debug: true
        });

        i18next.on('languageChanged', (language: string) => {
            runInAction(() => {
                this.t = i18next.getFixedT(language);
            })
        })

        this.setLanguage('en')

        // placeholder while initializing.
        // this.t = (e: string) => null;
    }

    get(key: string, ucFirst: boolean = true): string {
        if (!this.t) return '';
        var val: string = this.t(key);
        if (!val) return '';

        return ucFirst ? this.capitalizeFirstLetter(val) : val.toLowerCase();
    }

    @action setLanguage(language: string) {
        i18next.changeLanguage(language);
    }

    private capitalizeFirstLetter(val: string) {
        return val.charAt(0).toUpperCase() + val.slice(1);
    }
}

export const languageStore = new LanguageStore();

when(() => userStore.signedInUser, () => {
    if (userStore.signedInUser) {
        let language = userStore.signedInUser.language;
        if (!language || language === LanguageEnum.NOT_SET) {
            language = userStore.signedInUser.company.defaultLanguage;
        }

        if (!language) {
            language = LanguageEnum.ENGLISH
        }

        switch (language) {
            case LanguageEnum.ENGLISH:
                languageStore.setLanguage('en');
                break;
            case LanguageEnum.DANISH:
                languageStore.setLanguage('da');
                break;
            case LanguageEnum.NORWEGIAN:
                languageStore.setLanguage('no');
                break;
            case LanguageEnum.SWEDISH:
                languageStore.setLanguage('se');
                break;
            case LanguageEnum.FINNISH:
                languageStore.setLanguage('fi');
                break;
            case LanguageEnum.FRENCH:
                languageStore.setLanguage('fr');
                break;
            case LanguageEnum.GERMAN:
                languageStore.setLanguage('de');
                break;
            case LanguageEnum.SPANISH:
                languageStore.setLanguage('es');
                break;
            default:
                languageStore.setLanguage('en');
                break;
        }
    }
})