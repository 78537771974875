import * as React from 'react';
import { observer } from 'mobx-react';
import Input from 'components/Forms/Input';
import { FormState, FieldState } from 'formstate';
import Form from 'components/Forms/Form';
import { required } from 'helpers/FormValidation';
import { languageStore } from 'stores';

import logoImg from './logoColor.svg';

interface Props {
    onSubmit: (code: string) => void,
    onBackToLogin?: () => void
}

@observer class LoginTwoFactor extends React.Component<Props> {

    form = new FormState({
        code: new FieldState('').validators(required)
    })

    componentDidMount() {
        this.form.$.code.reset('');
    }

    onSubmit = () => {
        const { onSubmit } = this.props;
        const form = this.form.$;

        onSubmit(form.code.$.replace(' ', ''));
        this.form.$.code.reset('');
    }

    render() {
        const { onBackToLogin } = this.props;
        const form = this.form.$;

        return (
            <div className="font-sans text-sm rounded w-full max-w-sm mx-auto mt-20 px-8 pt-8 pb-8">
                <img src={logoImg} alt="COMA System" className="mb-12" width={300} />

                <Form onValidSubmit={this.onSubmit} formState={this.form}>

                    <h1>{languageStore.get('twoFactorAuthentication')}</h1>
                    {/* <p className='mb-8 mt-4'>
                        {languageStore.get('enterDigitsToCompleteTwoFactorLogin')}:
                    </p> */}
                    <Input
                        className='mt-8'
                        fieldState={form.code}
                        id='code'
                        label={languageStore.get('code')}
                    />

                    <div className="flex items-center justify-between">
                        <button type="submit" className="bg-comablue-label hover:bg-comablue text-white py-2 px-8 rounded">
                            {languageStore.get('signIn')}
                        </button>
                        {
                            onBackToLogin &&
                            <button type='button' onClick={() => onBackToLogin()} className="inline-block align-baseline text-grey-dark hover:text-grey-darker">
                                {languageStore.get('backToLogin')}
                            </button>
                        }
                    </div>
                </Form>
            </div>
        )
    }

}

export default LoginTwoFactor;