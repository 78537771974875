import * as React from 'react';
import { observer } from 'mobx-react';
import { addoSigningStatusStore } from 'stores/AddoSigningStatusStore';
import { Modal, ModalColumn } from 'components/Modal';
import { languageStore, DocumentSigningTransactionStateEnum } from 'stores';
import TableGreenHeader from 'components/Table/TableGreenHeader';
import ReactTable from 'react-table';

@observer class AddoSigningStatusModal extends React.Component {

    render() {
        const signing = addoSigningStatusStore.signing;
        const status = addoSigningStatusStore.status;

        if (!status) return null;

        const columns = [
            {
                Header: <TableGreenHeader value={'name'} />,
                accessor: 'name'
            },
            {
                Header: <TableGreenHeader value={'email'} />,
                accessor: 'email'
            },
            {
                Header: <TableGreenHeader value={'status'} />,
                id: 'state',
                accessor: (d: any) => {
                    const returnVal = d.transactions.map((transaction: any) => {
                        switch (transaction.state as DocumentSigningTransactionStateEnum) {
                            case DocumentSigningTransactionStateEnum.Failed: return languageStore.get('failed');
                            case DocumentSigningTransactionStateEnum.Created: return languageStore.get('created');
                            case DocumentSigningTransactionStateEnum.Pending: return languageStore.get('pending');
                            case DocumentSigningTransactionStateEnum.Started: return languageStore.get('started');
                            case DocumentSigningTransactionStateEnum.Signed: return languageStore.get('signed');
                            case DocumentSigningTransactionStateEnum.ReadyForDistribution: return languageStore.get('readyForDistribution');
                            case DocumentSigningTransactionStateEnum.Completed: return languageStore.get('completed');
                            case DocumentSigningTransactionStateEnum.Expired: return languageStore.get('expired');
                            case DocumentSigningTransactionStateEnum.Canceled: return languageStore.get('cancelled');
                            case DocumentSigningTransactionStateEnum.DataCollecting: return languageStore.get('dataCollecting');
                            case DocumentSigningTransactionStateEnum.Rejected: return languageStore.get('rejected');
                            default: return languageStore.get('unknown')
                        };
                    })

                    return returnVal.join(', ');
                }
            }
        ];

        return (
            <Modal
                forceWide={true}
                title={languageStore.get('status')}
                secondaryButton={{ title: languageStore.get('close'), onClick: () => addoSigningStatusStore.resetStore() }}>

                <ModalColumn>
                    <ReactTable
                        columns={columns}
                        className='ReactTable-readonly'
                        data={status.recipients}
                        defaultPageSize={99999}
                        showPagination={false}
                        NoDataComponent={() => null}
                        minRows={0}
                    />

                    {
                        signing?.recipients.map((recipient) => {
                            if (!recipient.rejectionComment) return null;

                            return (
                                <div className='mx-1 my-4 p-2 bg-red-lighter rounded'>
                                    {recipient.name} ({recipient.email}):
                                    <p className='mt-4'>
                                        {recipient.rejectionComment}
                                    </p>
                                </div>
                            )
                        })
                    }

                </ModalColumn>
            </Modal>
        )
    }
}

export default AddoSigningStatusModal;