import { languageStore, DocumentSigningStatusEnum } from 'stores';

export class AddoHelper {

    static SigningStatusToText(status: DocumentSigningStatusEnum) {
        switch(status) {
            case DocumentSigningStatusEnum.Completed: return languageStore.get('completed');
            case DocumentSigningStatusEnum.Created: return languageStore.get('created');
            case DocumentSigningStatusEnum.Expired: return languageStore.get('expired');
            case DocumentSigningStatusEnum.Failed: return languageStore.get('failed');
            case DocumentSigningStatusEnum.Rejected: return languageStore.get('rejected');
            case DocumentSigningStatusEnum.Started: return languageStore.get('started');
            case DocumentSigningStatusEnum.Stopped: return languageStore.get('stopped');
            default: return null;
        }
    }

}