import Form from "components/Forms/Form";
import Location from "components/Forms/Location";
import { Modal, ModalColumn } from "components/Modal";
import { FieldState, FormState } from "formstate";
import { required } from "helpers/FormValidation";
import { useState } from "react";
import { categoryStore, languageStore } from "stores";

const DuplicateCategoriesModal = () => {
    const [showModal, setShowModal] = useState(false);

    const form = new FormState({
        sourceLocationId: new FieldState('').validators(required),
        targetLocationId: new FieldState('').validators(required),
    })

    const onSubmit = async () => {
        const fromId = form.$.sourceLocationId.$;
        const toId = form.$.targetLocationId.$;
        if (fromId === toId) return;

        await categoryStore.duplicateCategories(fromId, toId);
        
        form.reset();
        setShowModal(false);

        window.location.reload();

    }

    return (
        <div className='text-left'>
            <button onClick={() => setShowModal(true)} className='bg-grey-dark text-white py-2 px-8 rounded mr-2'>
                {languageStore.get('copy')}
            </button>
            {
                showModal &&
                <Form onValidSubmit={onSubmit} formState={form}>
                    <Modal
                        title={`${languageStore.get('copy')} ${languageStore.get('categories', false)}`}
                        primaryButton={{ title: languageStore.get('save') }}
                        secondaryButton={{ title: languageStore.get('cancel'), onClick: () => setShowModal(false) }}>

                        <ModalColumn>

                            <Location
                                label={`${languageStore.get('from')} ${languageStore.get('location', false)}`}
                                fieldState={form.$.sourceLocationId}
                                editMode={true}
                            />

                            <Location
                                label={`${languageStore.get('to')} ${languageStore.get('location', false)}`}
                                fieldState={form.$.targetLocationId}
                                editMode={true}
                            />

                        </ModalColumn>

                    </Modal>
                </Form>
            }
        </div>
    )
}

export default DuplicateCategoriesModal;

