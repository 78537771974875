import * as React from 'react';
import { purchaseContractsStore, ViewByEnum, languageStore } from 'stores';
import { observer } from 'mobx-react';
import ContractsPage, { ContractPageGroupInterface } from 'components/ContractsPage';
import { ContractStatusHelper } from 'helpers/ContractStatusHelper';

@observer class PurchaseContractsPage extends React.Component {

    render() {

        const groups = [
            {
                title: languageStore.get('category'),
                enum: ViewByEnum.CATEGORY,
            },
            {
                title: languageStore.get('supplier'),
                enum: ViewByEnum.SUPPLIER,
            },
            {
                title: languageStore.get('location'),
                enum: ViewByEnum.LOCATION
            },
            {
                title: languageStore.get('department'),
                enum: ViewByEnum.DEPARTMENT,
            },
            {
                title: languageStore.get('responsible'),
                enum: ViewByEnum.RESPONSIBLE,
            },
            {
                title: languageStore.get('expiration'),
                enum: ViewByEnum.EXPIRATION_DATE,
                defaultSortingId: 'expirationDate'
            }
        ] as Array<ContractPageGroupInterface>

        const statuses = ContractStatusHelper.BasicContractStatus().map((status) => ({
            title: status.name,
            enum: status.value
        }))

        return (
            <ContractsPage
                store={purchaseContractsStore}
                groups={groups}
                statuses={statuses}
            />
        )
    }

}

export default PurchaseContractsPage;