import * as React from 'react';
import { observer } from 'mobx-react';
import SectionEnum from '../SectionEnum';
import { currentServiceContractStore as store, ContractDocumentInterface, languageStore } from 'stores';
import Files from 'components/PurchaseContractDetailsPage/Files';
import EmailCorrespondence from 'components/EmailCorrespondence';

@observer class Correspondence extends React.Component {

    render() {
        return (
            <Files
                hasDocumentTypes={false}
                title={languageStore.get('correspondence')}
                store={store}
                hash={SectionEnum.FILES_CORRESPONDENCE}
                files={store.contract!.contractCorrespondances}
                editDescription={<EmailCorrespondence contractId={store.contract!.id} />}
                onAddFileToContract={(document: ContractDocumentInterface) => store.addDocumentToContract(document, 'correspondance', store.contract!.contractCorrespondances)}
                onUpdateFile={(document: ContractDocumentInterface) => store.updateDocument(document, store.contract!.contractCorrespondances)}
                onDeleteFileFromContract={(document: ContractDocumentInterface) => store.deleteDocument(document, 'correspondance', store.contract!.contractCorrespondances)} />
        )
    }

}

export default Correspondence;