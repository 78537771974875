import request from 'api/request';
import { action, makeObservable, runInAction } from 'mobx';
import * as _ from 'lodash';
import { replaceOrAddObjectById } from 'helpers/CollectionHelper';
import { AxiosRequestConfig } from 'axios';
import SaveableStore from './SaveableStore';

export enum BaseContractTypeEnum {
    PURCHASE = 'purchase',
    STAFF = 'staff',
    SALES = 'sales',
    SERVICE = 'service'
}

export class BaseStore extends SaveableStore {
    constructor(name?: string) {
        super(name);
        makeObservable(this);
    }

    @action
    get(endpoint: string, classProperty: string, force: boolean = true): any {
        return new Promise(async (resolve, reject) => {
            try {
                if (force || (!force && (!this[classProperty] || this[classProperty].length === 0))) {
                    let res = await request.get(endpoint);
                    runInAction(() => {
                        this[classProperty] = res.data;
                    })
                }

                resolve(this[classProperty]);
            }
            catch (error) {
                reject(error);
            }
        });
    }

    put(endpoint: string, classProperty: string, data: object, useReponseObject?: boolean): any {
        return this.call('put', endpoint, classProperty, data, useReponseObject);
    }

    post(endpoint: string, classProperty: string, data: object): any {
        return this.call('post', endpoint, classProperty, data);
    }

    private call(method: 'post' | 'put', endpoint: string, classProperty: string | null, data: any, useReponseObject: boolean = true): any {
        return new Promise(async (resolve, reject) => {
            try {
                let res = await request[method](endpoint, data);
                if (res.data) {
                    if (useReponseObject) {
                        data.id = res.data.id;

                        if (classProperty) {
                            if (this[classProperty] instanceof Array) {
                                runInAction(() => {
                                    replaceOrAddObjectById(data.id, this[classProperty], data);
                                })

                            }
                            else {
                                runInAction(() => {
                                    this[classProperty] = data;
                                })
                            }
                        }
                    }

                    resolve(data);
                }
                else {
                    reject();
                }
            }
            catch (error) {
                reject(error);
            }
        });
    }

    @action
    create(endpoint: string, obj: any, target: Array<any>) {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await request.post(endpoint, obj);

                if (res.data.id) {
                    runInAction(() => {
                        obj.id = res.data.id;
                    })
                }

                if (target instanceof Array) {
                    runInAction(() => {
                        target.push(obj);
                    })
                }

                resolve(obj);
            }
            catch (error) {
                reject(error);
            }
        })
    }

    update(endpoint: string, obj: any, targetArray: Array<any> | null, targetObj: any | null = null, config: AxiosRequestConfig | undefined = undefined, useResponseObject?: boolean) {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await request.put(endpoint, obj, config);
                if (targetArray) {
                    const index = _.findIndex(targetArray, { id: obj.id });
                    runInAction(() => {
                        targetArray.splice(index, 1, useResponseObject ? res.data : obj);
                    })
                }
                else {
                    runInAction(() => {
                        targetObj = obj;
                    })
                }

                resolve(obj);
            }
            catch (error) {
                reject(error);
            }
        })
    }

    getById(endpoint: string, target: Array<any>) {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await request.get(endpoint);
                const foundUser = res.data;
                const index = _.findIndex(target, { id: foundUser.id });
                if (index) {
                    runInAction(() => {
                        target.splice(index, 1, foundUser);
                    })
                }
                else {
                    runInAction(() => {
                        target.push(foundUser);
                    })
                }

                resolve(foundUser);
            }
            catch (error) {
                reject(error);
            }
        })
    }

    delete(endpoint: string, id: string, target: Array<any>): Promise<void> {
        return new Promise(async (resolve, reject) => {
            try {
                await request.delete(endpoint);
                runInAction(() => {
                    _.remove(target, (t) => t.id === id);
                })
                resolve();
            }
            catch (error) {
                reject(error);
            }
        });
    }
}