import { BaseStore } from './BaseStore';
// import request from 'api/request';
import { ReportCompareEnum } from 'components/ReportsPage/CompareDropdown';
import { observable, action, makeObservable } from 'mobx';
import * as _ from 'lodash';
import { reportCriteriaBuilder } from 'helpers/ReportCriteriaBuilder';
import { DropdownOptionInterface } from 'components/Forms/Dropdown';
import { CurrentContractCustomFieldInterface } from './CurrentContractStore';
import { CustomFieldInterface, CustomFieldTemplateFieldTypeEnum } from './CustomFieldStore';
import { userStore } from './UserStore';
import { toastStore } from './ToastStore';
import { languageStore } from './LanguageStore';

export interface SearchCriteriaInterface {
    requestField: string,
    compareType: ReportCompareEnum,
    searchValue: string,
    customField: boolean
}

export interface ReportCriteriaInterface {
    label: string,
    requestField: string,
    compareType: ReportCompareTypeEnum,
    tableVisibility?: boolean,
    customConverter?: Function,
    dropdownOptions?: Array<DropdownOptionInterface>,
    autoCompleteOnFocus?: boolean,
    isCustomField?: boolean,
    id?: string,
    addMatch?: Array<{
        requestField: string,
        searchValue?: string
    }>,
    addMatchPhrase?: Array<{
        requestField: string,
        searchValue?: string
    }>
}

export enum ReportCompareTypeEnum {
    NONE,
    TEXT,
    NUMBER,
    DATE,
    DROPDOWN
}

export abstract class BaseReportStore extends BaseStore {
    @observable results: Array<any> = [];
    @observable data: Array<ReportCriteriaInterface> = [];
    contractEndpoint: string = '';

    constructor() {
        super();

        makeObservable(this);

        // this.initSessionStorage(this, ['results', 'data'])
    }

    @action
    async getResults(criterias: Array<SearchCriteriaInterface>) {

        criterias.forEach((criteria) => {
            let d = _.find(this.data, { id: criteria.requestField }) as ReportCriteriaInterface;
            if (!d) {
                d = _.find(this.data, { requestField: criteria.requestField }) as ReportCriteriaInterface;
            }

            const dataType = d.compareType;

            if (criteria.compareType === ReportCompareEnum.EXISTS) {
                if (criteria.requestField.startsWith('@cf_')) {
                    // this is a custom field
                    reportCriteriaBuilder.addMatch('customFields.customFieldSchema.key', criteria.requestField);
                }
                else {
                    reportCriteriaBuilder.addFieldMustExist(criteria.requestField);
                }
                return;
            }

            if (criteria.compareType === ReportCompareEnum.DOES_NOT_EXIST) {
                if (criteria.requestField.startsWith('@cf_')) {
                    // this is a custom field
                    reportCriteriaBuilder.addMatchMustNotExist('customFields.customFieldSchema.key', criteria.requestField);
                }
                else {
                    reportCriteriaBuilder.addFieldMustNotExist(criteria.requestField);
                }

                return;
            }

            if (d.addMatch) {
                d.addMatch.forEach((match) => {
                    reportCriteriaBuilder.addMatch(match.requestField, match.searchValue || criteria.searchValue)
                })
            }

            if (d.addMatchPhrase) {
                d.addMatchPhrase.forEach((match) => {
                    reportCriteriaBuilder.addMatchPhrase(match.requestField, match.searchValue || criteria.searchValue)
                })
            }

            if (d.addMatch || d.addMatchPhrase) {
                return;
            }

            if (dataType === ReportCompareTypeEnum.DROPDOWN) {
                reportCriteriaBuilder.addMatch(criteria.requestField, criteria.searchValue);
                return;
            }

            if (criteria.compareType === ReportCompareEnum.CONTAIN) {
                reportCriteriaBuilder.addMatchPhrase(criteria.requestField, criteria.searchValue);
            }

            else if (criteria.compareType === ReportCompareEnum.DOES_NOT_CONTAIN) {
                reportCriteriaBuilder.addMatchPhrase(criteria.requestField, criteria.searchValue, false, true);
            }

            else if (criteria.compareType === ReportCompareEnum.EQUALS) {
                let val: string | number = criteria.searchValue;
                if (dataType === ReportCompareTypeEnum.NUMBER) {
                    // Replace comma with dots. Elastic Search doesn't like comma.
                    val = Number(val.replace(/,/g, '.'));
                }

                reportCriteriaBuilder.addMatch(criteria.requestField, val);

            }

            else if (criteria.compareType === ReportCompareEnum.GREATER_THAN) {
                let val: string | number = criteria.searchValue;
                if (dataType === ReportCompareTypeEnum.NUMBER) {
                    // Replace comma with dots. Elastic Search doesn't like comma.
                    val = Number(val.replace(/,/g, '.'));
                }

                reportCriteriaBuilder.addRange("gt", criteria.requestField, val);

            }

            else if (criteria.compareType === ReportCompareEnum.LOWER_THAN) {
                let val: string | number = criteria.searchValue;
                if (dataType === ReportCompareTypeEnum.NUMBER) {
                    // Replace comma with dots. Elastic Search doesn't like comma.
                    val = Number(val.replace(/,/g, '.'));
                }

                reportCriteriaBuilder.addRange("lt", criteria.requestField, val);

            }

        })

        const res = await reportCriteriaBuilder.executeQuery(this.contractEndpoint);
        this.results = res;
        if (this.results.length === 0) {
            toastStore.addError(languageStore.get('thereAreNoContractsMatchingYourCriterias'));
        }

    }

    @action
    setResults(results: Array<any>) {
        this.results = results;
    }

    autoComplete(field: string, searchValue: string, results: number = 5) {
        return new Promise(async (resolve, reject) => {
            if (searchValue.indexOf(' ') !== -1) {
                reportCriteriaBuilder.addMatchPhrase(field, searchValue);
            }
            else {
                reportCriteriaBuilder.addQueryString(field, searchValue, true);
            }

            try {
                const res = await reportCriteriaBuilder.executeQuery(this.contractEndpoint, results, false, field);
                resolve(res);
            }
            catch (error) {
                reject(error);
            }
        })
    }

    @action resetStore() {
        this.results = [];
        this.data = [];
        reportCriteriaBuilder.resetQuery();
    }

    @action
    setVisibility(requestField: string, tableVisibility: boolean) {
        if (!this.data || this.data.length === 0) return;

        let res = _.find(this.data, { id: requestField });
        if (!res) {
            res = _.find(this.data, { requestField: requestField });
        }

        if (res) {
            res.tableVisibility = tableVisibility;
        }
    }

    customFieldConverter(fields: Array<CurrentContractCustomFieldInterface>, value: string) {
        const field = _.find(fields, (x) => x.customFieldSchema.key === value);
        if (field) {

            if(field.customFieldSchema.fieldType == 6)
            {
                if(field.value)
                {
                    return field.value.split(";").join(", ");
                }
                
            }
            return field.value;
        }
        else return null;
    }

    getCustomFieldsAsReportCriteriaInterfaces(customFields: Array<CustomFieldInterface>) {
        if (!userStore.signedInUser.company.allowCustomFields) return [];

        const customFieldsCreatedByUser = customFields.filter((field: CustomFieldInterface) => field.templateFieldType === CustomFieldTemplateFieldTypeEnum.CUSTOM)

        const data: Array<ReportCriteriaInterface> = [];

        customFieldsCreatedByUser.forEach((field: CustomFieldInterface) => {
            data.push({
                label: field.customFieldSchema.label,
                requestField: 'customFields.value',
                id: field.key,
                compareType: ReportCompareTypeEnum.TEXT,
                isCustomField: true,
                addMatch: [
                    {
                        requestField: 'customFields.customFieldSchema.key',
                        searchValue: field.key
                    }
                ],
                addMatchPhrase: [
                    {
                        requestField: 'customFields.value'
                    }
                ],
                customConverter: (d: any) => this.customFieldConverter(d.customFields, field.key)
            })
        })

        return data;
    }

    abstract setData(): void;
}
