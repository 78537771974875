import * as React from 'react';
import { observer } from 'mobx-react';
import { languageStore, ContractFinancialCompanyServiceInterface, serviceStore, DocumentTypeContractTypeEnum, ServiceInterface, CurrentContractStore, currencyStore, ContractFinancialCompanyServicePeriodEnum } from 'stores';
import TableGreenHeader from 'components/Table/TableGreenHeader';
import { ModalColumn } from 'components/Modal';
import { FormState, FieldState } from 'formstate';
import * as _ from 'lodash';
import Input from 'components/Forms/Input';
import SelectServiceType, { ServiceTypeEnum } from './SelectServiceType';
import ContractDateItem from 'components/ContractDateItem';
import SelectPeriod from 'components/Forms/FinanceAccount/SelectPeriod';
import SelectStatus from './SelectStatus';
import SelectService from './SelectService';
import BaseContractTable from 'components/StaffContractDetailsPage/BaseContractTable';
import { formatCurrencyByCurrentContract } from 'helpers/CurrencyHelper';
import { required } from 'helpers/FormValidation';
import { ExpirationEnum } from './ServiceHelper';
import SelectInterval from './SelectInterval';
import ToggleExpiration from './ToggleExpiration';
import { newServiceHelper } from './NewServiceHelper';
import numeral from 'numeral';

interface Props {
    onCreate: Function,
    onUpdate: Function,
    onDelete: Function,
    services: Array<ContractFinancialCompanyServiceInterface>,
    store: CurrentContractStore<any>,
    hash: string
}

interface State {
    expiration: ExpirationEnum
}

@observer class Services extends React.Component<Props, State> {

    state = { expiration: ExpirationEnum.CONTRACT_EXPIRATION_DATE }

    form = new FormState({
        id: new FieldState<string | null>(null),
        serviceId: new FieldState<string | null>(null).validators(required),
        quantity: new FieldState<number | null>(null).validators(required),
        price: new FieldState<number | null>(null).validators(required),
        serviceType: new FieldState<number | null>(null).validators(required),
        startDate: new FieldState<string | null>(null).validators(required),
        periods: new FieldState<number | null>(null),
        servicePeriod: new FieldState<number | null>(null),
        serviceStatus: new FieldState<number | null>(null).validators(required),
        billingInterval: new FieldState<number | null>(null)
    })

    constructor(props: Props) {
        super(props);

        const form = this.form.$;

        form.serviceType.onUpdate((fs) => {
            if (fs.value === fs.$) return;

            const value = Number(fs.value);
            if (value === ServiceTypeEnum.RUNNING || value === ServiceTypeEnum.FIXED_PAYMENTS) {
                form.servicePeriod.validators(required);
                form.startDate.validators(required);
            }
            else {
                form.servicePeriod.validators();
                form.startDate.validators();
            }
        })
    }

    formatNumber = (value: any) => {
        
        if(!value)
        {
            return "";   
        }

        const parsedValue = parseFloat(String(value));
        var number = numeral(parsedValue);
        return number.format("0,0.00")
    }

    beforeSubmit = (service: ContractFinancialCompanyServiceInterface) => {
        service.serviceType = Number(service.serviceType);
        service.servicePeriod = Number(service.servicePeriod);

        if (!service.billingInterval) {
            service.billingInterval = 1;
        }

    }

    componentDidMount() {
        const { store } = this.props;
        const locationId = store.currentLocationId;
        currencyStore.getCurrencies();

        if (!store.contract!.expirationDate) {
            this.setState({ expiration: ExpirationEnum.CURRENT_YEAR });
        }

        let endpoint = null;
        switch (store.contractEndpoint) {
            case 'purchase': endpoint = DocumentTypeContractTypeEnum.PURCHASE; break;
            case 'staff': endpoint = DocumentTypeContractTypeEnum.STAFF; break;
            case 'sales': endpoint = DocumentTypeContractTypeEnum.SALES; break;
            case 'service': endpoint = DocumentTypeContractTypeEnum.SERVICE; break;
            default: break;
        }

        if (endpoint && locationId) {
            serviceStore.getServices(locationId, endpoint);
        }
    }

    isOneTimePayment() {
        return Number(this.form.$.serviceType.value) === ServiceTypeEnum.ONE_TIME_PAYMENT
    }

    calculateTotalServiceSum = () => {
        const { store, services } = this.props;
        const { expiration } = this.state;
        if (expiration === ExpirationEnum.CONTRACT_EXPIRATION_DATE && !store.contract!.expirationDate) return 0;

        return newServiceHelper.calculateTotalServiceSum(store, services, expiration);
    }

    expirationToLabel = () => {
        const { expiration } = this.state;

        if (expiration === ExpirationEnum.CONTRACT_EXPIRATION_DATE) return languageStore.get('toExpirationDate');
        else if (expiration === ExpirationEnum.CURRENT_YEAR) return languageStore.get('currentYear');
        else return languageStore.get('nextYear');
    }

    render() {
        const { services, onCreate, onUpdate, onDelete, store, hash } = this.props;
        const { expiration } = this.state;
        const everyService = serviceStore.services || [];
        const form = this.form.$;
        const currencies = currencyStore.currencies;
        const cservices = JSON.parse(JSON.stringify(services));
        
        const columns = [
            {
                Header: <TableGreenHeader value={languageStore.get('service')} />,
                id: 'service',
                accessor: (d: any) => {
                    const item = _.find(everyService, { id: d.serviceId }) as ServiceInterface;
                    if (item) {
                        return item.name
                    }
                    else if (d.service) {
                        return d.service.name
                    }
                    else return '';
                }
            },
            {
                Header: <TableGreenHeader value={languageStore.get('number')} />,
                accessor: 'quantity'
            },
            {
                Header: <TableGreenHeader value={languageStore.get('price')} />,
                id: 'price',
                accessor: (d: ContractFinancialCompanyServiceInterface) => {
                    return d.price ? this.formatNumber(d.price) : "";
                }
            },
            {
                Header: <TableGreenHeader value={languageStore.get('periods')} />,
                id: 'periods',
                accessor: (d: any) => {
                    switch (d.servicePeriod) {
                        case ContractFinancialCompanyServicePeriodEnum.DELIVERIES: return languageStore.get('deliveries');
                        case ContractFinancialCompanyServicePeriodEnum.WEEKS: return languageStore.get('weeks');
                        case ContractFinancialCompanyServicePeriodEnum.MONTHS: return languageStore.get('months');
                        case ContractFinancialCompanyServicePeriodEnum.YEARS: return languageStore.get('years');
                        default: return '';
                    }
                }
            },
            {
                Header: <TableGreenHeader>
                    {languageStore.get('total')} ({this.expirationToLabel()})
                </TableGreenHeader>,
                id: 'total',
                accessor: (d: any) => {
                    const total = newServiceHelper.calculateServiceSum(store, d, expiration);
                    // const total = serviceHelper.calculateServiceSum(store, d, expiration);
                    if (expiration === ExpirationEnum.CONTRACT_EXPIRATION_DATE && !store.contract!.expirationDate) return languageStore.get('noExpirationDate');
                    // const total = d.quantity * d.price;
                    if (store.contract && store.contract!.currencyId) {
                        if (currencies) {
                            return formatCurrencyByCurrentContract(store, total);
                        }
                    }

                    return total;
                }
            },
            {
                Header: <TableGreenHeader value={languageStore.get('status')} />,
                id: 'serviceStatus',
                accessor: (d: any) => d.serviceStatus === 1 ? languageStore.get('agreed') : languageStore.get('deletedOrHidden')
            }
        ]

        return (
            <BaseContractTable
                form={this.form}
                beforeSubmit={this.beforeSubmit}
                totalSum={this.calculateTotalServiceSum()}
                onSubmit={async (service: ContractFinancialCompanyServiceInterface) => await onCreate(service)}
                onUpdate={(service: ContractFinancialCompanyServiceInterface) => onUpdate(service)}
                onDelete={(service: ContractFinancialCompanyServiceInterface) => onDelete(service)}
                collection={cservices}
                columns={columns}
                tableTitle={languageStore.get('services')}
                tableSubTitle={
                    <div className='mt-2 text-sm'>
                        <ToggleExpiration
                            expiration={ExpirationEnum.CONTRACT_EXPIRATION_DATE}
                            label={languageStore.get('toExpirationDate')}
                            activeExpiration={expiration}
                            onClick={(exp) => this.setState({ expiration: exp })}
                        />
                        <span className='mx-2'>|</span>
                        <ToggleExpiration
                            expiration={ExpirationEnum.CURRENT_YEAR}
                            label={languageStore.get('currentYear')}
                            activeExpiration={expiration}
                            onClick={(exp) => this.setState({ expiration: exp })}
                        />
                        <span className='mx-2'>|</span>
                        <ToggleExpiration
                            expiration={ExpirationEnum.NEXT_YEAR}
                            label={languageStore.get('nextYear')}
                            activeExpiration={expiration}
                            onClick={(exp) => this.setState({ expiration: exp })}
                        />
                    </div>
                }
                modalTitle={languageStore.get('services')}
                store={store}
                hash={hash}>

                <ModalColumn>

                    <SelectService store={store} fieldState={form.serviceId} />

                    <div className='flex' style={{ marginTop: '0', marginBottom: '-20px' }}>
                        <div className='flex-1 mr-2'>
                            <Input
                                fieldState={form.quantity}
                                type='number'
                                id='quantity'
                                label={languageStore.get('number')} />
                        </div>
                        <div className='flex-1 ml-2'>
                            <Input
                                fieldState={form.price}
                                type='number'
                                id='price'
                                label={languageStore.get('price')} />
                        </div>
                    </div>

                    <SelectServiceType
                        fieldState={form.serviceType} />

                    <ContractDateItem
                        fieldState={form.startDate}
                        label={languageStore.get('date')}
                        id='startDate'
                        editMode={true} />

                    {
                        !this.isOneTimePayment() &&
                        <>
                            {
                                Number(form.serviceType.value) !== ServiceTypeEnum.RUNNING &&
                                <>
                                    <div className='pt-2' />
                                    <Input
                                        fieldState={form.periods}
                                        type='number'
                                        id='periods'
                                        label={languageStore.get('numberOfPayments')} />
                                </>
                            }

                            <div className='flex' style={{ marginTop: '-20px', marginBottom: '-20px' }}>
                                <div className='flex-1 mr-2'>
                                    <SelectInterval
                                        fieldState={form.billingInterval} />
                                </div>
                                <div className='flex-1 ml-2'>
                                    <SelectPeriod
                                        fieldState={form.servicePeriod} />
                                </div>
                            </div>

                        </>
                    }

                    <SelectStatus
                        fieldState={form.serviceStatus} />

                </ModalColumn>

            </BaseContractTable>
        )

    }

}

export default Services;