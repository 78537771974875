import * as React from 'react';
import { observer } from 'mobx-react';
import Input from 'components/Forms/Input';
import { FormState, FieldState } from 'formstate';
import Form from 'components/Forms/Form';
import { required } from 'helpers/FormValidation';
import { navigationStore, authStore, languageStore } from 'stores';

import logoImg from './logoColor.svg';

interface Props {
    onSubmit: (email: string, password: string) => void,
    hideForgotPassword?: boolean,
}

@observer class Login extends React.Component<Props> {

    form = new FormState({
        email: new FieldState('').validators(required),
        password: new FieldState('').validators(required)
    })

    constructor(props: any) {
        super(props);

        if (authStore.isAuthenticated) {
            authStore.resetUserData();
        }
    }

    componentDidMount() {
        this.form.$.email.reset('');
    }

    onSubmit = () => {
        const { onSubmit } = this.props;
        const form = this.form.$;
        
        onSubmit(form.email.$, form.password.$);

        this.form.$.password.reset('');
    }

    gotoForgotPassword(e: React.MouseEvent) {
        e.preventDefault();
        navigationStore.push('/forgot-password');
    }

    render() {
        const { hideForgotPassword } = this.props;
        const form = this.form.$;

        return (
            <div className="font-sans text-sm rounded w-full max-w-xs mx-auto mt-20 px-8 pt-8 pb-8">
                <img src={logoImg} alt="COMA System" className="mb-12" />

                <Form onValidSubmit={this.onSubmit} formState={this.form}>

                    <Input fieldState={form.email} id='email' label={languageStore.get('email')} />
                    <Input fieldState={form.password} id='password' label={languageStore.get('password')} type='password' />

                    <div className="flex items-center justify-between">
                        <button type="submit" className="bg-comablue-label hover:bg-comablue text-white py-2 px-8 rounded">
                            {languageStore.get('signIn')}
                        </button>
                        {
                            !hideForgotPassword &&
                            <a onClick={(e) => this.gotoForgotPassword(e)} className="inline-block align-baseline text-grey-dark hover:text-grey-darker" href=" ">
                                {languageStore.get('forgotPassword')}?
                            </a>
                        }
                    </div>
                </Form>
            </div>
        )
    }

}

export default Login;