import { ContractNoteInterface, languageStore, ContractAlarmInterface, MainCategoryInterface, FinanceAccountInterface, CustomerInterface, ContractDocumentInterface, ContractFinancialCompanyServiceInterface, ServiceContractServiceInterface, SkillInterface, StaffAppraisalInterviewInterface, BenefitInterface, EducationInterface, EquipmentInterface, SubCategoryInterface, SupplierInterface, TagInterface } from 'stores';
import { ContactPersonInterface } from 'stores/ContactStore';
import { formatDate } from './DateHelper';

function addOptional(value: string | null | undefined) {
    if (value) {
        return ` (${value})`;
    }
    return '';
}

export function formatTag(tag: TagInterface) {
    return languageStore.get('tag');
}

export function formatNote(note: ContractNoteInterface) {
    let logText = languageStore.get('note', false);
    logText += addOptional(note.title);
    return logText;
}

export function formatAlarm(alarm: ContractAlarmInterface) {
    let logText = languageStore.get('alarm', false);
    logText += addOptional(formatDate(alarm.date));
    return logText;
}

export function formatCategory(category: MainCategoryInterface) {
    let logText = languageStore.get('category', false);
    logText += addOptional(category.name);
    return logText;
}

export function formatContactPerson(contact: ContactPersonInterface) {
    let logText = languageStore.get('contact', false);
    logText += addOptional(contact.name);
    return logText;
}

export function formatContractContactPerson() {
    let logText = languageStore.get('contact', false);
    return logText;
}

export function formatFinanceAccount(fAccount: FinanceAccountInterface) {
    let logText = languageStore.get('financeAccount', false);
    logText += addOptional(fAccount.accTitle);
    return logText;
}

export function formatCustomer(customer: CustomerInterface) {
    let logText = languageStore.get('customer', false);
    logText += addOptional(customer.name);
    return logText;
}

export function formatDocument(document: ContractDocumentInterface) {
    let logText = languageStore.get('document', false);
    logText += addOptional(document.fileName);
    return logText;
}

export function formatFinancialService(fService: ContractFinancialCompanyServiceInterface) {
    let logText = languageStore.get('financialService', false);
    if (fService.startDate) {
        logText += addOptional(formatDate(fService.startDate));
    }
    return logText;
}

export function formatBaseContract() {
    let logText = languageStore.get('contract', false);
    return logText;
}

export function formatServiceContractService(service: ServiceContractServiceInterface) {
    let logText = languageStore.get('service', false);
    logText += addOptional(service.serviceName);
    return logText;
}

export function formatSkill(skill: SkillInterface) {
    let logText = languageStore.get('skill', false);
    logText += addOptional(skill.name);
    return logText;
}

export function formatStaffAppraisalInterview(interview: StaffAppraisalInterviewInterface) {
    let logText = languageStore.get('appraisalInterview', false);
    if (interview.heldOn) {
        logText += addOptional(formatDate(interview.heldOn));
    }
    return logText;
}

export function formatStaffBenefit(benefit: BenefitInterface) {
    let logText = languageStore.get('benefit', false);
    logText += addOptional(benefit.name);
    return logText;
}

export function formatEducation(education: EducationInterface) {
    let logText = languageStore.get('education', false);
    logText += addOptional(education.name);
    return logText;
}

export function formatEquipment(equipment: EquipmentInterface) {
    let logText = languageStore.get('equipment', false);
    logText += addOptional(equipment.name);
    return logText;
}

export function formatStaffSkill() {
    let logText = languageStore.get('skill', false);
    return logText;
}

export function formatSubCategory(subCategory: SubCategoryInterface) {
    let logText = languageStore.get('subCategory', false);
    logText += addOptional(subCategory.name);
    return logText;
}

export function formatSupplier(supplier: SupplierInterface) {
    let logText = languageStore.get('supplier', false);
    logText += addOptional(supplier.name);
    return logText;
}