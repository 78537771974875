import React from 'react';
import { observer } from 'mobx-react';
import { FieldState } from 'formstate';
import Flatpickr from 'react-flatpickr';
import Moment from 'moment';
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect';
require('flatpickr/dist/plugins/monthSelect/style.css');

interface Props {
    fieldState: FieldState<any>,
    label: string,
    selectMonthOnly?: boolean,
    allowInput?: boolean
}

@observer class SelectDate extends React.Component<Props> {

    render() {
        const { fieldState, label, selectMonthOnly, allowInput } = this.props;

        return (
            <div className={`relative border py-2 px-2 rounded mb-6 mt-8`} style={{ height: '41px' }}>
                <Flatpickr
                    options={{
                        plugins: selectMonthOnly ? [monthSelectPlugin({shorthand: false, dateFormat: 'd/m/Y', altFormat: "M Y"})] : [],
                        dateFormat: 'd/m/Y', allowInput: allowInput !== undefined ? allowInput : true, onClose: (selectedDates, dateStr, instance) => {
                            if (!dateStr) {
                                fieldState.onChange(null);
                                return;
                            }

                            const newDate = Moment(dateStr, 'DD/MM/YYYY');
                            const currentDate = Moment(fieldState.value!, 'DD/MM/YYYY')

                            if (!newDate.isSame(currentDate)) {
                                fieldState.onChange([newDate.toDate()][0]);
                            }
                        }
                    }}
                    className='w-full bg-transparent text-green-darker leading-normal rounded'
                    value={fieldState.value ? Moment.utc(fieldState.value).toISOString() : undefined} />
                <label className="absolute block text-comablue-label pin-t leading-normal cursor-pointer Dropdown-label-floating">
                    {label} {fieldState.error && <span className="text-red text-xs pl-1">({fieldState.error})</span>}
                </label>
            </div>
        )
    }
}

export default SelectDate;