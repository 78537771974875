import React from 'react';
import { observer } from 'mobx-react';
import { languageStore, RoleInterface, userStore } from 'stores';
import _ from 'lodash';

@observer class AccessRights extends React.Component {

    render() {
        const me = userStore.signedInUser;
        if (!me) return null

        const roles = _.orderBy(me.roles, 'name');

        return (
            <div className='m-auto' style={{ maxWidth: '600px' }}>
                <h1 className='mb-6'>{languageStore.get('accessRights')}</h1>
                <ul>
                    {
                        roles.map((role: RoleInterface, index: number) => (
                            <li key={index} className='my-2'>{role.name}</li>
                        ))
                    }
                </ul>
            </div>
        )
    }
}

export default AccessRights;