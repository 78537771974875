import * as React from 'react';
import { observer } from 'mobx-react';
import { Modal } from 'components/Modal';
import { CurrentContractStore, CurrentContractBaseInterface, languageStore, ContractStatusEnum, StaffContractStatusEnum, toastStore, documentTypeStore, userStore } from 'stores';
import * as _ from 'lodash';
import { setFormPristine } from 'helpers/FormHelper';

interface SaveDraftOptionInterface {
    title: string,
    enum: ContractStatusEnum | StaffContractStatusEnum
}

interface Props {
    store: CurrentContractStore<CurrentContractBaseInterface>
}

interface State {
    isShowModal: boolean
}

@observer class SaveDraftButton extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);

        this.state = {
            isShowModal: false
        }
    }

    save = async (e: React.MouseEvent, newStatus: ContractStatusEnum | StaffContractStatusEnum) => {
        e.preventDefault();
        this.hideModal();

        const { store } = this.props;
        const arr = new Array<any>();

        let formsCanSubmit = true;
        let errorMsg = '';

        for (const form of store.submitForms) {
            if (!formsCanSubmit) break;

            const res = await form.validate();

            if (res.hasError) {
                // eslint-disable-next-line no-loop-func
                Object.keys(form.$).forEach((key) => {

                    if (form.$[key].hasError) {
                        let name = key;
                        if (name.indexOf('Id') !== -1) {
                            name = name.slice(0, name.length - 2);
                        }

                        errorMsg += `${languageStore.get(name)} (${form.$[key].error})\n`;
                    }
                })

                formsCanSubmit = false;
            }

        }

        if (!formsCanSubmit) {
            alert(`${languageStore.get('oneOrMoreFieldsIncorrect')}:\n\n${errorMsg}`);
            return;
        }

        store.submitPromises.forEach((prom: Function) => {
            arr.push(prom());
        })

        const wasDraft = store.isDraft;
        const statusBeforeSave = store.contract!.contractStatus;

        try {
            await Promise.all(arr);
            const contract = store.contract!;

            contract.contractStatus = newStatus;
            let shouldSave = true;

            // We're trying to move away from draft to production. We need to check for required files
            if (wasDraft && !store.isDraft && contract.requireDocumentTypes) {
                await documentTypeStore.getDocumentTypes(userStore.signedInUser.company.id);
                const requiredDocumentTypes = _.filter(documentTypeStore.documentTypes || [], { contractType: store.contractType!, required: true });

                requiredDocumentTypes.forEach((documentType) => {
                    if (shouldSave && !_.find(contract.contractDocuments, { documentTypeId: documentType.id })) {
                        alert(languageStore.get('followingDocumentsAreRequiredToChangeStatusFromDraft') + ': \n\n' + requiredDocumentTypes.map((d) => d.name).join('\n'));
                        store.contract!.contractStatus = statusBeforeSave;
                        shouldSave = false;
                    }
                })
            }

            if (shouldSave) {
                await store.putContract(contract);

                toastStore.addToast({
                    level: 'success',
                    title: 'Saved',
                    message: 'contract saved'
                })

                store.submitForms.forEach((f) => setFormPristine(f.$));
                store.setIsDraftJustCreated(false);
            }

        }
        catch (error) {
            alert(languageStore.get('oneOrMoreFieldsIncorrect'));
            store.contract!.contractStatus = statusBeforeSave;
        }
    }

    hideModal = () => {
        this.setState({ isShowModal: false })
    }

    showModal = () => {
        this.setState({ isShowModal: true })
    }

    getStaffOptions(): Array<SaveDraftOptionInterface> {
        return [
            {
                title: languageStore.get('hired'),
                enum: StaffContractStatusEnum.Hired
            },
            {
                title: languageStore.get('applicant'),
                enum: StaffContractStatusEnum.Applicant
            },
            {
                title: languageStore.get('dismissed'),
                enum: StaffContractStatusEnum.Dismissed
            },
            {
                title: languageStore.get('rejected'),
                enum: StaffContractStatusEnum.Rejected
            },
            {
                title: languageStore.get('resigned'),
                enum: StaffContractStatusEnum.Resigned
            },
            {
                title: languageStore.get('cancelled'),
                enum: StaffContractStatusEnum.Cancelled
            },
            {
                title: languageStore.get('draft'),
                enum: StaffContractStatusEnum.Draft
            },
        ]
    }

    getDefaultOptions(): Array<SaveDraftOptionInterface> {
        return [
            {
                title: languageStore.get('acceptedInOperation'),
                enum: ContractStatusEnum.AcceptedInOperation
            },
            {
                title: languageStore.get('offer'),
                enum: ContractStatusEnum.Offer
            },
            {
                title: languageStore.get('renetiation'),
                enum: ContractStatusEnum.Renetiation
            },
            {
                title: languageStore.get('discontinued'),
                enum: ContractStatusEnum.Discontinued
            },
            {
                title: languageStore.get('terminated'),
                enum: ContractStatusEnum.Terminated
            },
            {
                title: languageStore.get('deleted'),
                enum: ContractStatusEnum.Deleted
            },
            {
                title: languageStore.get('draft'),
                enum: ContractStatusEnum.Draft
            },
        ]
    }

    render() {
        const { store } = this.props;
        const { isShowModal } = this.state;
        if (!store.isDraft || !store.canEdit) return null;

        const opts = store.contractEndpoint === 'staff' ? this.getStaffOptions() : this.getDefaultOptions();

        return (
            <>
                <button onClick={this.showModal} type='submit' className='bg-comablue-label text-white py-2 px-8 rounded'>
                    {languageStore.get('saveChanges')}
                </button>

                {
                    isShowModal &&
                    <div className='text-left'>
                        <Modal
                            centerButtons={true}
                            title={languageStore.get('selectStatus')}
                            secondaryButton={{ title: languageStore.get('cancel'), onClick: this.hideModal }}>

                            <div className='mx-auto'>
                                {
                                    opts.map((opt, index) => {
                                        return (
                                            <a
                                                href=' '
                                                onClick={(e => this.save(e, opt.enum))}
                                                key={index}
                                                className={`
                                                    block 
                                                    w-64 
                                                    text-comablue-label
                                                    border 
                                                    rounded 
                                                    px-4 
                                                    py-2 
                                                    no-underline
                                                    mb-4 
                                                    text-center
                                                    hover:bg-grey-lightest
                                                    cursor-pointer`}
                                            >
                                                {opt.title}
                                            </a>
                                        )
                                    })
                                }
                            </div>

                        </Modal>
                    </div>
                }
            </>
        )
    }

}

export default SaveDraftButton;