import React from 'react';
import { observer } from 'mobx-react';
import { CustomerSupplierEnum } from 'components/SuppliersCustomersTable';
import { customerDetailsStore, CustomerInterface, languageStore, supplierDetailsStore, SupplierInterface, toastStore } from 'stores';
import { FieldState, FormState } from 'formstate';
import Input from 'components/Forms/Input';
import Form from 'components/Forms/Form';
import Breadcrumb from 'components/Breadcrumb';
import ContactPeopleTable from './ContactPeopleTable';
import { required } from 'helpers/FormValidation';
import DeleteSupplierCustomer from './DeleteSupplierCustomer';
import MergeSupplierCustomer from './MergeSupplierCustomer';
import { CVRApiInterface, getCVRdetails } from 'helpers/CVRApiHelper';
import Country from 'components/Forms/Country';

interface Props {
    type: CustomerSupplierEnum
}

@observer class SupplierCustomerPage extends React.Component<Props> {

    form = new FormState({
        name: new FieldState('').validators(required),
        vat: new FieldState(''),
        address: new FieldState(''),
        zipCode: new FieldState(''),
        city: new FieldState(''),
        customerNumber: new FieldState(''),
        supplierNumber: new FieldState(''),
        countryId: new FieldState(''),
    })

    getIdByParams = () => {
        const props = this.props as any;
        return props.match.params.id;
    }

    async componentDidMount() {
        const { type } = this.props;

        const id = this.getIdByParams();
        const form = this.form.$;
        let data: CustomerInterface | SupplierInterface | null = null;

        if (type === CustomerSupplierEnum.CUSTOMER) {
            data = await customerDetailsStore.getCustomer(id);
        }
        else {

            data = await supplierDetailsStore.getSupplier(id);
        }

        if (data) {
            form.name.reset(data.name);
            form.vat.reset(data.vat);
            form.address.reset(data.address);
            form.zipCode.reset(data.zipCode);
            form.city.reset(data.city);
            form.countryId.reset(data.countryId);
            if (type === CustomerSupplierEnum.CUSTOMER) {
                form.customerNumber.reset((data as CustomerInterface).customerNumber);
            }
            if (type === CustomerSupplierEnum.SUPPLIER) {
                form.supplierNumber.reset((data as SupplierInterface).supplierNumber);
            }
        }
    }

    componentWillUnmount() {
        supplierDetailsStore.resetStore();
        customerDetailsStore.resetStore();
    }

    onSubmit = async () => {
        const { type } = this.props;
        const form = this.form.$;

        if (type === CustomerSupplierEnum.SUPPLIER) {
            const supplier = supplierDetailsStore.supplier!;
            supplier.name = form.name.$;
            supplier.vat = form.vat.$;
            supplier.address = form.address.$;
            supplier.zipCode = form.zipCode.$;
            supplier.city = form.city.$;
            supplier.supplierNumber = form.supplierNumber.$;
            supplier.countryId = form.countryId.$;

            await supplierDetailsStore.putSupplier(supplier);
        }
        else {
            const customer = customerDetailsStore.customer!;
            customer.name = form.name.$;
            customer.vat = form.vat.$;
            customer.address = form.address.$;
            customer.zipCode = form.zipCode.$;
            customer.city = form.city.$;
            customer.countryId = form.countryId.$;
            customer.customerNumber = form.customerNumber.$;

            await customerDetailsStore.putCustomer(customer);
        }

        toastStore.addToast({
            level: 'success',
            title: languageStore.get('success'),
            message: languageStore.get('changesSaved')
        })
    }

    onVatBlur = async () => {
        const form = this.form.$;
        const vatValue = form.vat.value;

        if (vatValue?.length === 8) {
            const res = await getCVRdetails(vatValue) as CVRApiInterface;
            if (res && res.name !== form.name.value) {
                if (window.confirm(languageStore.get('confirmCVRApi'))) {
                    form.name.onChange(res.name);
                    form.address.onChange(res.address);
                    form.zipCode.onChange(res.zipcode);
                    form.city.onChange(res.city);
                }
            }
        }
    }

    render() {
        const { type } = this.props;
        const form = this.form.$;
        const isSupplier = type === CustomerSupplierEnum.SUPPLIER;
        const customerContacts = customerDetailsStore.customer?.contactPeople || [];
        const supplierContacts = supplierDetailsStore.supplier?.contactPeople || [];
        const entityId = isSupplier ? supplierDetailsStore.supplier?.id : customerDetailsStore.customer?.id;

        return (
            <div>
                <div className='flex items-center w-1/2 m-auto px-4' style={{ maxWidth: '900px' }}>
                    <div className='flex-1'>
                        <Breadcrumb
                            backlinkTo={`/admin/${isSupplier ? 'suppliers' : 'customers'}`}
                            backlinkTitle={languageStore.get(isSupplier ? 'suppliers' : 'customers')}
                            title={languageStore.get('edit')} />
                    </div>
                    <div>
                        {
                            entityId &&
                            <>
                                <DeleteSupplierCustomer
                                    type={type}
                                    id={entityId}
                                />
                                <MergeSupplierCustomer
                                    type={type}
                                    id={entityId}
                                />
                            </>
                        }
                    </div>
                </div>

                <Form onValidSubmit={this.onSubmit} formState={this.form}>
                    <div className='w-1/2 m-auto border-t border-gray-300 mt-8 pt-8' style={{ maxWidth: '900px' }}>
                        <div className='mt-10 flex'>
                            <div className='w-1/2 inline-block p-4'>
                                <Input
                                    fieldState={form.vat}
                                    id='vat'
                                    onBlur={this.onVatBlur}
                                    label={languageStore.get('vatNumber')} />
                                {
                                    type === CustomerSupplierEnum.CUSTOMER &&
                                    <Input
                                        fieldState={form.customerNumber}
                                        id='customerNumber'
                                        label={languageStore.get('customerNumber')} />
                                }
                                {
                                    type === CustomerSupplierEnum.SUPPLIER &&
                                    <Input
                                        fieldState={form.supplierNumber}
                                        id='supplierNumber'
                                        label={languageStore.get('supplierNumber')} />
                                }
                                <Input
                                    fieldState={form.name}
                                    id='name'
                                    label={languageStore.get('name')} />

                                <Country
                                    fieldState={form.countryId}
                                    editMode={true} />
                            </div>
                            <div className='w-1/2 inline-block p-4'>
                                <Input
                                    fieldState={form.address}
                                    id='address'
                                    label={languageStore.get('address')} />
                                <Input
                                    fieldState={form.zipCode}
                                    id='zipCode'
                                    label={languageStore.get('zipCode')} />
                                <Input
                                    fieldState={form.city}
                                    id='city'
                                    label={languageStore.get('city')} />
                            </div>
                        </div>

                        <div className='text-right'>

                            <button
                                className='bg-comablue-label text-white py-2 px-8 m-4 rounded'
                                type='submit'>
                                {languageStore.get('saveChanges')}
                            </button>
                        </div>

                    </div>
                </Form>

                <div>
                    <div className='w-1/2 m-auto border-t border-gray-300 mt-8 pt-8' style={{ maxWidth: '900px' }}>
                        <h1 className='mt-5 m-4'>
                            {languageStore.get('contactPerson')}
                        </h1>
                    </div>
                    <div className='p-4'>
                        <ContactPeopleTable
                            type={type}
                            data={isSupplier ? supplierContacts : customerContacts}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default SupplierCustomerPage;