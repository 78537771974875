import React from 'react';
import { observer } from 'mobx-react';
import { CurrencyInterface, currencyStore, DashboardContractTypeEnum, dashboardStore, languageStore } from 'stores';
import DoughnutWidget from '../DoughnutWidget';
import _ from 'lodash';

interface Props {
    contractType: DashboardContractTypeEnum,
}

enum TypeEnum {
    EXPIRED_CONTRACT_SUM_BY_CURRENCY_ID = 'expiredContractSumByCurrencyId',
    ACTIVE_CONTRACT_SUM_BY_CURRENCY_ID = 'activeContractSumByCurrencyId',
    EXPIRED_CONTRACTS_COUNT = 'expiredContracts',
    ACTIVE_CONTRACTS_COUNT = 'activeContracts'
}

@observer class ActiveExpiredDoughnutWidgets extends React.Component<Props> {

    componentDidMount() {
        const { contractType } = this.props;
        currencyStore.getCurrencies();
        this.getData(contractType);
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        if (nextProps.contractType !== this.props.contractType) {
            this.getData(nextProps.contractType);
        }
    }

    getData(contractType: DashboardContractTypeEnum) {
        dashboardStore.getStatus(contractType);
    }

    getCollection(type: TypeEnum) {
        const status = dashboardStore.status;
        if (!status) return null;

        const currencies = currencyStore.currencies || [];

        const labels: Array<string> = [];
        const inlineLabels: Array<string> = [];
        const data: Array<number> = [];
        const collection = status[type];

        Object.keys(collection).forEach((currencyId: string) => {
            const currency = _.find(currencies, { id: currencyId }) as CurrencyInterface;
            // eslint-disable-next-line array-callback-return
            if (!currency) return;
            const amount = collection[currencyId];
            if (amount > 0) {
                labels.push(`${currency.code} (${Number(amount.toFixed()).toLocaleString()})`);
                data.push(amount);
                inlineLabels.push(currency.code);
            }
        })

        return {
            labels,
            inlineLabels,
            data
        }
    }

    render() {
        const { contractType } = this.props;
        const status = dashboardStore.status;
        if (!status) return null;

        const activeData = this.getCollection(TypeEnum.ACTIVE_CONTRACT_SUM_BY_CURRENCY_ID);
        const expiredData = this.getCollection(TypeEnum.EXPIRED_CONTRACT_SUM_BY_CURRENCY_ID);

        const activeCount = status[TypeEnum.ACTIVE_CONTRACTS_COUNT];
        const expiredCount = status[TypeEnum.EXPIRED_CONTRACTS_COUNT];

        return (
            <>
                {
                    activeData &&
                    <div className='w-1/3'>
                        <DoughnutWidget
                            contractType={contractType}
                            title={languageStore.get('isActive')}
                            labels={activeData.labels}
                            centerLabel={activeCount.toString()}
                            inlineLabels={activeData.inlineLabels}
                            data={activeData.data}
                        />
                    </div>
                }
                {
                    expiredData &&
                    <div className='w-1/3'>
                        <DoughnutWidget
                            contractType={contractType}
                            title={languageStore.get('isExpired')}
                            labels={expiredData.labels}
                            centerLabel={expiredCount.toString()}
                            inlineLabels={expiredData.inlineLabels}
                            data={expiredData.data}
                        />
                    </div>
                }
            </>
        )
    }
}

export default ActiveExpiredDoughnutWidgets;