import { useEffect, useState } from 'react';
import { MdCheckCircle } from 'react-icons/md';
import { MdCancel } from 'react-icons/md';

interface Props {
    label: string,
    regexPattern?: string,
    currentValue: string
}

const Requirement = (props: Props) => {
    const [ fulfilled, setFulfilled ] = useState(false);

    useEffect(() => {
        if (props.regexPattern) {
            const regexp = new RegExp(props.regexPattern!);
            setFulfilled(regexp.test(props.currentValue));
        }
    }, [props.currentValue, props.regexPattern])

    return (
        <li className="my-2">
            {
                fulfilled &&
                <MdCheckCircle className='text-green mr-1 h-full' />
            }
            {
                !fulfilled &&
                <MdCancel className='text-red mr-1 h-full' />
            }
            {props.label}
        </li>
    )
}

export default Requirement;