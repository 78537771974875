import * as React from 'react';
import { observer } from 'mobx-react';
import { languageStore, userStore, customFieldStore, DashboardContractTypeEnum } from 'stores';
import { currentStaffContractStore } from 'stores';
import Menu from './Menu';
import Overview from './Overview';
import EmergencyContact from './EmergencyContact';
import Dates from './Dates';
import Agreement from './Agreement';
import ApplicantCV from './Overview/ApplicantCV';
import AppraisalInterviews from './AppraisalInterviews';
import EducationDetails from './EducationDetails';
import Benefits from './Benefits';
import Equipment from './Equipment';
import Skills from './Skills';
import Documents from './Documents';
import Correspondence from './Correspondence';
import Notes from 'components/Notes';
import SectionEnum from './SectionEnum';
import Alarms from 'components/Alarms';
import ContractMetaStatus from 'components/Forms/ContractMetaStatus';
import ContractDetailsTop from 'components/ContractDetailsTop';
import ContractToContract from 'components/ContractToContract';
import ContractDetailsContainer from 'components/ContractDetailsContainer';
import ContractDetailsPage from 'components/ContractDetailsPage';
import CustomFields from 'components/CustomFields';

interface Props {
    match?: any
}

@observer class StaffContractDetailsPage extends React.Component<Props> {

    render() {
        return (
            <ContractDetailsPage store={currentStaffContractStore} contractId={this.props.match.params.id}>

                {
                    currentStaffContractStore.contract &&
                    <>
                        <ContractDetailsTop
                            helpUrl='https://help.comasystem.dk/staff/new-staff-contract'
                            backlinkTitle={languageStore.get('staffContracts')}
                            title={languageStore.get('showStaffContract')}
                            store={currentStaffContractStore} />

                        <ContractDetailsContainer>

                            <Menu />
                            <Overview />
                            <EmergencyContact />
                            <Dates />
                            <Agreement />
                            <AppraisalInterviews />
                            <EducationDetails />
                            <Benefits />
                            <Equipment />
                            <Skills />
                            <Notes store={currentStaffContractStore} hash={SectionEnum.NOTES} />
                            <Alarms store={currentStaffContractStore} hash={SectionEnum.EXTRA_ALARMS} />
                            {/* <VerifiedBy store={currentStaffContractStore} hash={SectionEnum.VERIFIED_BY} /> */}
                            <ApplicantCV />
                            <Documents />
                            <ContractToContract store={currentStaffContractStore} hash={SectionEnum.LINKED_CONTRACTS} />
                            <Correspondence />

                            {
                                (userStore.signedInUser!.company.allowCustomFields && customFieldStore.hasCustomFields[DashboardContractTypeEnum.STAFF]) &&
                                <CustomFields store={currentStaffContractStore} hash={SectionEnum.CUSTOM_FIELDS} />
                            }
                            
                            <ContractMetaStatus
                                type={DashboardContractTypeEnum.STAFF}
                                store={currentStaffContractStore}
                                hash={SectionEnum.STATUS} />

                        </ContractDetailsContainer>
                    </>
                }

            </ContractDetailsPage >
        )
    }

}

export default StaffContractDetailsPage;