import { BaseStore } from './BaseStore';
import request from 'api/request';
import { ContractDocumentInterface } from 'stores';

export interface DocumentUrlInterface {
    url: string,
    expires: string,
    documentId: string
}

export class DocumentStore extends BaseStore {

    getByMinioId(minioName: string) {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await request.get(`documents/${minioName}`);
                resolve(res.data);
            }
            catch(error) {
                reject(error);
            }

        })
    }

    async getDocumentUrl(minioName: string) {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await request.get(`documents/${minioName}`);
                
                resolve(res.data);
            }
            catch(error) {
                reject(error);
            }
        })
    }

    uploadFile(file: any): Promise<ContractDocumentInterface> {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await request.post(`documents/upload`, file, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });

                resolve(res.data);
            }
            catch(error) {
                reject(error);
            }
        })
    }

    deleteDocument(id: string) {
        return this.delete(`documents/${id}`, id, []);
    }

}

export const documentStore = new DocumentStore();