import * as React from 'react';
import { observer } from 'mobx-react';
import { FieldState } from 'formstate';
import ContractDropdownItem from 'components/ContractDropdownItem';
import { languageStore } from 'stores';

interface OptionInterface {
    value: number,
    name: string
}

interface Props {
    fieldState: FieldState<any>,
    editMode?: boolean
    options: Array<OptionInterface>
}

@observer class ContractStatus extends React.Component<Props> {

    render() {
        const { fieldState, editMode, options } = this.props;

        return (
            <ContractDropdownItem
                label={languageStore.get('status')}
                id='status'
                fieldState={fieldState}
                options={options}
                type='number'
                editMode={editMode} />
        )
    }

}

export default ContractStatus;