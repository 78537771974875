import { userStore } from 'stores';
import SidebarNavigation, { SidebarNavigationPropsItemInterface } from 'components/AdminPage/SidebarNavigation';
import { useLocation } from 'react-router';
import { observer } from "mobx-react";
import { AdminMenuHelper } from 'helpers/AdminMenuHelper';

interface Props {
    children: React.ReactNode
}

const AdminPage = observer((props: Props) => {

    const { pathname } = useLocation();

    const company = userStore.signedInUser.company;
    const allowTemplates = company.allowTemplates;
    const allowCustomFields = company.allowCustomFields;

    const items = [
        AdminMenuHelper.getCommonMenu(pathname),
        AdminMenuHelper.getPurchaseMenu(pathname, allowTemplates, allowCustomFields),
        AdminMenuHelper.getStaffMenu(pathname, allowTemplates, allowCustomFields),
        AdminMenuHelper.getServiceMenu(pathname, allowTemplates, allowCustomFields),
        AdminMenuHelper.getSalesMenu(pathname, allowTemplates, allowCustomFields),
        AdminMenuHelper.getSuperAdminMenu(pathname)
    ].filter(item => item) as Array<SidebarNavigationPropsItemInterface>;

    return (
        <div className='flex my-12 100vh'>
            <SidebarNavigation
                items={items}
            />

            <div className='flex-1'>
                {props.children}
            </div>
        </div>
    )

})

export default AdminPage;