import { observer } from "mobx-react";
import { languageStore } from "stores";

const DefaultPage = observer(() => {

    return (
        <div className='w-1/2 lg:w-1/3'>
            <h1>
                {languageStore.get('administration')}
            </h1>
            <p className='mt-4'>
                {languageStore.get('administrationDescription')}.
            </p>
        </div>
    )
});

export default DefaultPage;