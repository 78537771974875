import * as React from 'react';
import { Switch, Route, Router } from 'react-router-dom';
import { navigationStore, DocumentTypeContractTypeEnum, DashboardContractTypeEnum } from 'stores';
import DashboardPage from 'components/DashboardPage';
import LoginPage from 'components/LoginPage';
import PurchaseContractsPage from 'components/PurchaseContractsPage';
import StaffContractsPage from 'components/StaffContractsPage';
import PurchaseContractDetailsPage from 'components/PurchaseContractDetailsPage';
import AdminPage from 'components/AdminPage';
import CategoriesPage from 'components/AdminPage/CategoriesPage';
import DepartmentsPage from 'components/AdminPage/DepartmentsPage';
import UsersPage from 'components/AdminPage/UsersPage';
import ProfilePage from 'components/ProfilePage';
import AccountNumbersPage from 'components/AdminPage/AccountNumbersPage';
import VerifiedByDepartmentsPage from 'components/AdminPage/VerifiedByDepartmentsPage';
import CreateNewUserPage from 'components/AdminPage/UsersPage/CreateNewUserPage';
import OverviewPage from 'components/OverviewPage';
import ForgotPasswordPage from 'components/ForgotPasswordPage';
import ResetPasswordPage from 'components/ResetPasswordPage';
import StaffContractDetailsPage from 'components/StaffContractDetailsPage';
import BenefitsPage from 'components/AdminPage/BenefitsPage';
import AgreementPage from 'components/AdminPage/AgreementPage';
import EquipmentPage from 'components/AdminPage/EquipmentPage';
import EducationPage from 'components/AdminPage/EducationPage';
import SkillPage from 'components/AdminPage/SkillPage';
import ServiceContractsPage from 'components/ServiceContractsPage';
import SalesContractsPage from 'components/SalesContractsPage';
import ServiceContractDetailsPage from 'components/ServiceContractDetailsPage';
import SalesContractDetailsPage from 'components/SalesContractDetailsPage';
import Toast from 'components/Toast';
import LoadingBar from 'components/LoadingBar';
import DocumentTypes from 'components/AdminPage/DocumentTypes';
import ServicesPage from 'components/AdminPage/ServicesPage';
import CompanyPage from 'components/AdminPage/CompanyPage';
import TagsPage from 'components/AdminPage/TagsPage';
import CompaniesPage from 'components/AdminPage/SuperAdmin/CompaniesPage';
import SuperAdminCompanyPage from 'components/AdminPage/SuperAdmin/SuperAdminCompanyPage';
import ReportsPage from 'components/ReportsPage';
import CreateNewCompanyPage from 'components/AdminPage/SuperAdmin/CreateNewCompanyPage';
import ContractsUpForDeletionPage from 'components/AdminPage/SuperAdmin/ContractsUpForDeletionPage';
import ActivitiesPage from 'components/AdminPage/SuperAdmin/ActivitiesPage';
import InboxPage from 'components/InboxPage';
import TemplatesPage from 'components/AdminPage/TemplatesPage';
import CustomFieldsPage from 'components/AdminPage/CustomFieldsPage';
import JPPolLoginPage from 'components/LoginPage/JPPolLoginPage';
import SefLoginPage from 'components/LoginPage/SefLoginPage';
import VaekstfondenLoginPage from 'components/LoginPage/VaeksfondenLoginPage';
import SiccadaniaLoginPage from 'components/LoginPage/SiccadaniaLoginPage';
import SuppliersPage from 'components/SuppliersPage';
import CustomersPage from 'components/CustomersPage';
import SupplierCustomerPage from 'components/SupplierCustomerPage';
import { CustomerSupplierEnum } from 'components/SuppliersCustomersTable';
import PlansPage from 'components/AdminPage/SuperAdmin/PlansPage';
import AdminInboxesPage from 'components/AdminPage/AdminInboxesPage';
import AdminInboxPage from 'components/AdminPage/AdminInboxPage';
import LocationsPage from 'components/AdminPage/LocationsPage';
import DefaultPage from 'components/AdminPage/DefaultPage';
import UserDetailsPage from 'components/AdminPage/UserDetailsPage';
import UserDetailsPageGeneral from 'components/AdminPage/UserDetailsPage/General';
import UserDetailsPageAccessRights from 'components/AdminPage/UserDetailsPage/AccessRights';
import UserDetailsPageDepartments from 'components/AdminPage/UserDetailsPage/Departments';
import UserDetailsPageLocations from 'components/AdminPage/UserDetailsPage/Locations';
import UserDetailsPageContracts from 'components/AdminPage/UserDetailsPage/Contracts';
import UserDetailsPageReassign from 'components/AdminPage/UserDetailsPage/Reassign';
import UserDetailsPageLog from 'components/AdminPage/UserDetailsPage/Log';
import LocationDetailsPage from 'components/AdminPage/LocationDetailsPage';
import LocationDetailsPageGeneral from 'components/AdminPage/LocationDetailsPage/General';
import LocationDetailsPageDepartments from 'components/AdminPage/LocationDetailsPage/Departments';
import LocationDetailsPageUsers from 'components/AdminPage/LocationDetailsPage/Users';
import DepartmentDetailsPage from 'components/AdminPage/DepartmentDetailsPage';
import DepartmentDetailsPageGeneral from 'components/AdminPage/DepartmentDetailsPage/General';
import DepartmentDetailsPageUsers from 'components/AdminPage/DepartmentDetailsPage/Users';
import CategoryDetailsPageGeneral from 'components/AdminPage/CategoryDetailsPage/General';
import CategoryDetailsPageSubcategories from 'components/AdminPage/CategoryDetailsPage/Subcategories';
import NomecoLoginPage from 'components/LoginPage/NomecoLoginPage';
import InboxMessagePage from 'components/InboxMessagePage';
import CategoryDetailsPage from 'components/AdminPage/CategoryDetailsPage';
import ActivateTwoFactorPage from 'components/ActivateTwoFactorPage';
import TemplateFieldsPage from 'components/AdminPage/TemplateFieldsPage';
import DuplicateCompanyPage from 'components/AdminPage/SuperAdmin/DuplicateCompanyPage';
// import DevTools from 'mobx-react-devtools';
import numeral from 'numeral';
class App extends React.Component<any, object> {

	getNumberFormatSeparators = () => {
        const numberFormat = new Intl.NumberFormat();
      
        // Get the decimal separator
        const parts = numberFormat.formatToParts(12345.67);

        let decimalSeparator = ',';
        let thousandSeparator = '.';

        // Loop through the parts to find separators
        for (const part of parts) 
        {
            if (part.type === 'decimal')
            {
                decimalSeparator = part.value;
            } 
            else if (part.type === 'group') 
            {
                thousandSeparator = part.value;
            }
        }
      
        return { decimalSeparator, thousandSeparator };
      };


	public render() {
		let numbersSeperators = this.getNumberFormatSeparators();
		// load a locale
		if(!numeral.locales.coma)
		{
			numeral.register('locale', 'coma', {
				delimiters: {
					thousands: numbersSeperators.thousandSeparator,
					decimal: numbersSeperators.decimalSeparator
				},
				abbreviations: {
					thousand: 'k',
					million: 'm',
					billion: 'b',
					trillion: 't'
				},
				ordinal : function (number) {
					return number === 1 ? '' : 's';
				},
				currency: {
					symbol: '€'
				}
			});
		}

		sessionStorage.setItem("thousandseparator", numbersSeperators.thousandSeparator);
		sessionStorage.setItem("decimalseparator", numbersSeperators.decimalSeparator);
		numeral.locale("coma");

		return (
			<Router history={navigationStore.history}>
				<div className="font-sans">
					<Toast />
					<LoadingBar />
					<Switch>
						<Route exact path="/login" component={LoginPage} />
						<Route exact path='/login/jp' component={JPPolLoginPage} />
						<Route exact path='/login/vf' component={VaekstfondenLoginPage} />
						<Route exact path='/login/sicca' component={SiccadaniaLoginPage} />
						<Route exact path='/login/nomeco' component={NomecoLoginPage} />
						<Route exact path='/login/sef' component={SefLoginPage} />
						<Route exact path="/forgot-password" component={ForgotPasswordPage} />
						<Route exact path="/reset-password/:token" component={ResetPasswordPage} />
						<Route exact path="/activate-twofactor" component={ActivateTwoFactorPage} />

						<DashboardPage>
							<Route exact path="/" component={OverviewPage} />
							<Route exact path="/purchase" component={PurchaseContractsPage} />
							<Route exact path="/purchase/:id" component={PurchaseContractDetailsPage} />
							<Route exact path="/staff" component={StaffContractsPage} />
							<Route exact path="/staff/:id" component={StaffContractDetailsPage} />
							<Route exact path="/service" component={ServiceContractsPage} />
							<Route exact path="/service/:id" component={ServiceContractDetailsPage} />
							<Route exact path="/sales" component={SalesContractsPage} />
							<Route exact path="/sales/:id" component={SalesContractDetailsPage} />
							<Route exact path="/profile" component={ProfilePage} />
							<Route exact path="/reports/:results?" component={ReportsPage} />
							<Route exact path="/inbox" component={InboxPage} />
							<Route exact path="/inbox/:inboxId/messages/:messageId" component={InboxMessagePage} />

							{/* Admin */}
							<Route path={['/admin', '/superadmin']}>
								<AdminPage>
									<Route exact path="/admin" component={DefaultPage} />
									<Route exact path="/admin/suppliers" component={SuppliersPage} />
									<Route exact path="/admin/suppliers/:id" render={(props) => <SupplierCustomerPage type={CustomerSupplierEnum.SUPPLIER} {...props} />} />
									<Route exact path="/admin/customers" component={CustomersPage} />
									<Route exact path="/admin/customers/:id" render={(props) => <SupplierCustomerPage type={CustomerSupplierEnum.CUSTOMER} {...props} />} />
									<Route exact path="/admin/purchasecategories" render={(props) => <CategoriesPage contractType={DocumentTypeContractTypeEnum.PURCHASE} />} />
									<Route exact path="/admin/servicecategories" render={(props) => <CategoriesPage contractType={DocumentTypeContractTypeEnum.SERVICE} />} />
									<Route exact path="/admin/salescategories" render={(props) => <CategoriesPage contractType={DocumentTypeContractTypeEnum.SALES} />} />
									<Route exact path="/admin/locations" component={LocationsPage} />

									<Route path='/admin/locations/:id'>
										<LocationDetailsPage>
											<Route exact path='/admin/locations/:id' component={LocationDetailsPageGeneral} />
											<Route exact path='/admin/locations/:id/departments' component={LocationDetailsPageDepartments} />
											<Route exact path='/admin/locations/:id/users' component={LocationDetailsPageUsers} />
										</LocationDetailsPage>
									</Route>

									<Route path='/admin/departments/:id'>
										<DepartmentDetailsPage>
											<Route exact path='/admin/departments/:id' component={DepartmentDetailsPageGeneral} />
											<Route exact path='/admin/departments/:id/users' component={DepartmentDetailsPageUsers} />
										</DepartmentDetailsPage>
									</Route>

									<Route path='/admin/categories/:id'>
										<CategoryDetailsPage>
											<Route exact path='/admin/categories/:id' component={CategoryDetailsPageGeneral} />
											<Route exact path='/admin/categories/:id/subcategories' component={CategoryDetailsPageSubcategories} />
										</CategoryDetailsPage>
									</Route>

									<Route exact path="/admin/departments" component={DepartmentsPage} />
									<Route exact path="/admin/users" component={UsersPage} />

									<Route path='/admin/users/:id'>
										<UserDetailsPage>
											<Route exact path="/admin/users/:id" component={UserDetailsPageGeneral} />
											<Route exact path="/admin/users/:id/accessrights" component={UserDetailsPageAccessRights} />
											<Route exact path="/admin/users/:id/departments" component={UserDetailsPageDepartments} />
											<Route exact path="/admin/users/:id/locations" component={UserDetailsPageLocations} />
											<Route exact path="/admin/users/:id/contracts" component={UserDetailsPageContracts} />
											<Route exact path="/admin/users/:id/reassign" component={UserDetailsPageReassign} />
											<Route exact path="/admin/users/:id/log" component={UserDetailsPageLog} />

										</UserDetailsPage>
									</Route>
									<Route exact path="/admin/users/create/:companyId?" component={CreateNewUserPage} />
									<Route exact path="/admin/accountnumbers" component={AccountNumbersPage} />
									<Route exact path="/admin/verifiedbydepartments" component={VerifiedByDepartmentsPage} />
									<Route exact path="/admin/purchasedocumenttypes" render={(props) => <DocumentTypes contractType={DocumentTypeContractTypeEnum.PURCHASE} />} />
									<Route exact path="/admin/staffdocumenttypes" render={(props) => <DocumentTypes contractType={DocumentTypeContractTypeEnum.STAFF} />} />
									<Route exact path="/admin/servicedocumenttypes" render={(props) => <DocumentTypes contractType={DocumentTypeContractTypeEnum.SERVICE} />} />
									<Route exact path="/admin/salesdocumenttypes" render={(props) => <DocumentTypes contractType={DocumentTypeContractTypeEnum.SALES} />} />
									<Route exact path="/admin/benefits" component={BenefitsPage} />
									<Route exact path="/admin/agreements" component={AgreementPage} />
									<Route exact path="/admin/equipment" component={EquipmentPage} />
									<Route exact path="/admin/educations" component={EducationPage} />
									<Route exact path="/admin/skills" component={SkillPage} />
									<Route exact path="/admin/company" component={CompanyPage} />
									<Route exact path="/admin/tags" component={TagsPage} />
									<Route exact path="/admin/inboxes" component={AdminInboxesPage} />
									<Route exact path="/admin/inboxes/:id" render={(props) => <AdminInboxPage createMode={false} {...props} />} />
									<Route exact path="/admin/create-inbox" render={(props) => <AdminInboxPage createMode={true} {...props} />} />

									<Route exact path='/admin/purchasetemplates' render={(props) => <TemplatesPage contractType={DashboardContractTypeEnum.PURCHASE} />} />
									<Route exact path='/admin/purchasetemplates/fields' render={(props) => <TemplateFieldsPage contractType={DashboardContractTypeEnum.PURCHASE} />} />

									<Route exact path='/admin/salestemplates' render={(props) => <TemplatesPage contractType={DashboardContractTypeEnum.SALES} />} />
									<Route exact path='/admin/salestemplates/fields' render={(props) => <TemplateFieldsPage contractType={DashboardContractTypeEnum.SALES} />} />

									<Route exact path='/admin/servicetemplates' render={(props) => <TemplatesPage contractType={DashboardContractTypeEnum.SERVICE} />} />
									<Route exact path='/admin/servicetemplates/fields' render={(props) => <TemplateFieldsPage contractType={DashboardContractTypeEnum.SERVICE} />} />
									
									<Route exact path='/admin/stafftemplates' render={(props) => <TemplatesPage contractType={DashboardContractTypeEnum.STAFF} />} />
									<Route exact path='/admin/stafftemplates/fields' render={(props) => <TemplateFieldsPage contractType={DashboardContractTypeEnum.STAFF} />} />

									<Route exact path='/admin/purchasecustomfields' render={(props) => <CustomFieldsPage contractType={DashboardContractTypeEnum.PURCHASE} />} />
									<Route exact path='/admin/salescustomfields' render={(props) => <CustomFieldsPage contractType={DashboardContractTypeEnum.SALES} />} />
									<Route exact path='/admin/servicecustomfields' render={(props) => <CustomFieldsPage contractType={DashboardContractTypeEnum.SERVICE} />} />
									<Route exact path='/admin/staffcustomfields' render={(props) => <CustomFieldsPage contractType={DashboardContractTypeEnum.STAFF} />} />

									<Route exact path="/admin/salesservices" render={(props) => <ServicesPage contractType={DocumentTypeContractTypeEnum.SALES} />} />
									<Route exact path="/admin/serviceservices" render={(props) => <ServicesPage contractType={DocumentTypeContractTypeEnum.SERVICE} />} />
									<Route exact path="/admin/purchaseservices" render={(props) => <ServicesPage contractType={DocumentTypeContractTypeEnum.PURCHASE} />} />

									{/* SuperAdmin */}
									<Route exact path="/superadmin/companies" component={CompaniesPage} />
									<Route exact path="/superadmin/plans" component={PlansPage} />
									<Route exact path="/superadmin/createcompany" component={CreateNewCompanyPage} />
									<Route exact path="/superadmin/duplicatecompany" component={DuplicateCompanyPage} />
									<Route exact path="/superadmin/companies/:id" component={SuperAdminCompanyPage} />
									<Route exact path="/superadmin/contractsupfordeletion" component={ContractsUpForDeletionPage} />
									<Route exact path="/superadmin/activities/users/:userId" component={ActivitiesPage} />
								</AdminPage>
							</Route>
						</DashboardPage>
						{/* <DevTools /> */}
					</Switch>
				</div>
			</Router>
		);
	}
}

export default App;