import { languageStore } from 'stores';
import { DropdownOptionInterface } from 'components/Forms/Dropdown';
import * as _ from 'lodash';

export class ContractTypeHelper {

    static ContractTypes() {
        const values = [
            {
                value: 'agreement',
                name: languageStore.get('contractType_agreement')
            },
            {
                value: 'cash',
                name: languageStore.get('contractType_cash')
            },
            {
                value: 'framework',
                name: languageStore.get('contractType_framework')
            },
            {
                value: 'internalmonitoring',
                name: languageStore.get('contractType_internalmonitoring')
            },
            {
                value: 'leasing',
                name: languageStore.get('contractType_leasing')
            },
            {
                value: 'mixedtender',
                name: languageStore.get('contractType_mixedtender')
            },
            {
                value: 'rental',
                name: languageStore.get('contractType_rental')
            },
            {
                value: 'subscription',
                name: languageStore.get('contractType_subscription')
            },
            {
                value: 'warranty',
                name: languageStore.get('contractType_warranty')
            },
            {
                value: 'purchaseofgoods',
                name: languageStore.get('contractType_purchaseofgoods')
            },
            {
                value: 'service',
                name: languageStore.get('contractType_service')
            },
            {
                value: 'inspection',
                name: languageStore.get('contractType_inspection')
            },
            {
                value: 'serviceagreement',
                name: languageStore.get('contractType_serviceagreement')
            },
        ]

        return values;
    }

    static ContractTypeName(data: Array<DropdownOptionInterface>, value: string) {
        if (!value) return '';

        const val = _.find(data, { value: value.toLowerCase() }) as DropdownOptionInterface;
        return val ? val.name : value
    }

}