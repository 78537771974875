// import * as React from 'react';
import { observer } from "mobx-react";
import { languageStore, locationStore, categoryStore, DocumentTypeContractTypeEnum, userStore, navigationStore, LocationInterface } from 'stores';
import TableHeader from 'components/Table/TableHeader';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import TableDetails from '../TableDetails';
import ReactTable from 'react-table';
import SubTableNoDataComponent from 'components/SubTableNoDataComponent';
import AddCategoryModal from './AddCategoryModal';
import DuplicateCategoriesModal from './DuplicateCategoriesModal';

interface Props {
    contractType: DocumentTypeContractTypeEnum
}

const CategoriesPage = observer((props: Props) => {
    const [locations, setLocations] = useState<Array<LocationInterface>>([]);
    const [data, setData] = useState<Array<any>>([]);

    useEffect(() => {
        categoryStore.getAllCategories();
        const companyId = userStore.signedInUser.company.id;

        async function getLocations() {
            await locationStore.getLocationsByCompany(companyId);
            setLocations(locationStore.locations);
        }

        getLocations();

        return () => {
            categoryStore.resetStore();
        }
    }, [])

    const categories = categoryStore.allCategories;

    useEffect(() => {
        const mappedData = _.clone(locations);
        categories.forEach((cat) => {
            if (cat.contractType !== props.contractType) return;

            let loc = _.find(mappedData, { id: cat.locationId }) as any;
            if (!loc) return;

            if (loc.subItems === undefined) {
                loc.subItems = [];
            }

            loc.subItems.push(cat);

        })

        setData(mappedData);

    }, [locations, categories, props.contractType]);

    return (
        <TableDetails
            actions={
                <div className='flex'>
                    <DuplicateCategoriesModal />
                    <AddCategoryModal contractType={props.contractType} />
                </div>
            }
            data={data.slice()}
            columns={[
                {
                    Header: <TableHeader value={languageStore.get('location')} />,
                    id: 'name',
                    accessor: 'name'
                }
            ]}
            filterBy={['name']}
            onRowClicked={() => null} // Make cells appear clickable
            subComponent={row => <ReactTable
                style={{ marginLeft: '35px' }}
                data={row.original.subItems?.slice()}
                columns={[
                    {
                        Header: <TableHeader value={languageStore.get('category')} />,
                        id: 'name',
                        accessor: 'name'
                    }
                ]}
                defaultPageSize={99999}
                defaultSorted={[{ id: 'name' }]}
                showPagination={false}
                NoDataComponent={() => <SubTableNoDataComponent />}
                getTdProps={(state: any, rowInfo: any, column: any, instance: any) => {
                    return {
                        onClick: (e: React.MouseEvent, handleOriginal: any) => {
                            const id = rowInfo.original.id;
                            navigationStore.push(`/admin/categories/${id}`)
                        }
                    };
                }}
                minRows={0} />}
        />
    )
})

export default CategoriesPage;