import { FieldState } from 'formstate';

export const mapField = (field: FieldState<any>, fallback: any, isNumber?: boolean): any => {
    if (field.dirty) {
        if (field.$ === '') {
            return null
        }
        else {
            return isNumber ? Number(field.$) : field.$;
        }
    }
    else {
        return fallback;
    }
}