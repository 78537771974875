import { observable, action, makeObservable } from 'mobx';
import { BaseStore } from './BaseStore';

export interface LocationInterface {
    "id": string,
    "companyId": string,
    "name": string,
    "countryId": string,
    "country": {
        "id": string,
        "isoCode": string,
        "name": string,
        "currencyId": string,
        "currency": {
            "id": string,
            "name": string,
            "code": string,
            "symbol": string,
            "displayFormat": string
        }
    },
}

export class LocationStore extends BaseStore {
    @observable locations: Array<LocationInterface> = [];

    constructor() {
        super();

        makeObservable(this);
    }

    @action
    getLocations(force: boolean) {
        return this.get('locations', 'locations', force);
    }

    @action
    getLocationsByCompany(companyId: string) {
        return this.get(`companies/${companyId}/locations`, 'locations');
    }

    @action
    createCompanyLocation(location: LocationInterface) {
        return this.create(`companies/${location.companyId}/locations`, location, this.locations);
    }

    @action
    updateCompanyLocation(location: LocationInterface) {
        return this.update(`companies/${location.companyId}/locations/${location.id}`, location, this.locations);
    }

    @action
    deleteCompanyLocation(companyId: string, locationId: string) {
        return this.delete(`companies/${companyId}/locations/${locationId}`, locationId, this.locations);
    }

    @action
    resetStore() {
        this.locations = [];
    }
}

export const locationStore = new LocationStore();