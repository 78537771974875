import * as React from 'react';
import { observer } from 'mobx-react';
import ContractDetailsGroup from 'components/ContractDetailsGroup';
import ContractDetailsColumn from 'components/ContractDetailsColumn';
import ContractDetailsItem from 'components/ContractDetailsItem';
import { FormState, FieldState } from 'formstate';
import { languageStore, currentPurchaseContractStore as store, DashboardContractTypeEnum } from 'stores';
import Location from 'components/Forms/Location';
import SectionEnum from 'components/PurchaseContractDetailsPage/SectionEnum';
import { required, isNumber } from 'helpers/FormValidation';
import ContractDateItem from 'components/ContractDateItem';
import { BaseContractTypeEnum } from 'stores/BaseStore';
import ContractType from 'components/Forms/ContractType';
import MainCategory from 'components/Forms/MainCategory';
import Department from 'components/Forms/Department';
import SubCategory from 'components/Forms/SubCategory';
import ResponsibleUser from 'components/Forms/ResponsibleUser';
import BaseContract from 'components/StaffContractDetailsPage/BaseContract';
import RenewalDate from 'components/Forms/RenewalDate';
import { addDaysIfNotSet } from 'helpers/DateHelper';
import SelectTrueFalse from 'components/Forms/SelectTrueFalse';

@observer class Overview extends React.Component {

    form = new FormState({
        locationId: new FieldState(store.contract!.department && store.contract!.department.locationId).validators(required),
        contractTypeId: new FieldState(store.contract!.contractTypeId).validators(required),
        categoryId: new FieldState(store.contract!.categoryId).validators(required),
        subCategoryId: new FieldState(store.contract!.subCategoryId).validators(required),
        description: new FieldState(store.contract!.description),
        departmentId: new FieldState(store.contract!.departmentId).validators(required),
        dealNumber: new FieldState(store.contract!.dealNumber),
        responsibleUserId: new FieldState(store.contract!.responsibleUserId).validators(required),
        contractDateCreated: new FieldState(store.contract!.contractDateCreated),
        renewalDate: new FieldState(store.contract!.renewalDate),
        expirationDate: new FieldState(store.contract!.expirationDate),
        renewalNoticeDays: new FieldState(store.contract!.renewalNoticeDays).validators(isNumber),
        expirationNoticeDays: new FieldState(store.contract!.expirationNoticeDays).validators(isNumber),
        requireDocumentTypes: new FieldState(store.contract!.requireDocumentTypes)
    })

    onExpirationDateChanged = (value: any) => {
        addDaysIfNotSet(value, this.form.$.renewalDate, -90);
    }

    render() {
        const form = this.form.$;
        const editMode = store.isEditMode(SectionEnum.OVERVIEW);

        return (
            <BaseContract
                
                store={store}
                form={this.form}
                showDraftRibbon={store.isDraft}
                sectionEnum={SectionEnum.OVERVIEW}>

                <ContractDetailsGroup title={languageStore.get('overview')}>
                    <ContractDetailsColumn>

                        <Location store={store} fieldState={form.locationId} editMode={editMode} />

                        <ContractType
                            fieldState={form.contractTypeId}
                            locationId={form.locationId.value}
                            fallbackValue={store.contract!.contractType && store.contract!.contractType.name}
                            editMode={editMode}
                            contractType={BaseContractTypeEnum.PURCHASE}
                        />

                        <MainCategory
                            fieldState={form.categoryId}
                            locationId={form.locationId.value}
                            fallbackValue={store.contract!.category && store.contract!.category.name}
                            editMode={editMode}
                            contractType={BaseContractTypeEnum.PURCHASE}
                            dashContractType={DashboardContractTypeEnum.PURCHASE}
                        />

                        <ContractDetailsItem
                            label={languageStore.get('description')}
                            id='description'
                            fieldState={form.description}
                            editMode={editMode}
                            textArea={true}
                        />
                    </ContractDetailsColumn>
                    <ContractDetailsColumn>

                        <Department
                            store={store}
                            locationId={form.locationId.value}
                            fieldState={form.departmentId}
                            fallbackValue={store.contract!.department && store.contract!.department.name}
                            editMode={editMode} />

                        <ContractDetailsItem
                            label={languageStore.get('dealNumber')}
                            id='dealNumber'
                            fieldState={form.dealNumber}
                            editMode={editMode} />

                        <SubCategory
                            fieldState={form.subCategoryId}
                            mainCategoryId={form.categoryId.value}
                            fallbackValue={store.contract!.subCategory && store.contract!.subCategory.name}
                            editMode={editMode} />

                        <ResponsibleUser
                            showAlarmWarning={true}
                            fieldState={form.responsibleUserId}
                            fallbackValue={store.contract!.responsibleUser && store.contract!.responsibleUser.fullName}
                            editMode={editMode} />

                        <SelectTrueFalse
                            label={languageStore.get('requiredDocuments')}
                            id='requiredDocuments'
                            fieldState={form.requireDocumentTypes}
                            editMode={store.isDraft && editMode} />

                    </ContractDetailsColumn>
                </ContractDetailsGroup>

                <ContractDetailsGroup title={languageStore.get('dates')}>

                    <ContractDetailsColumn>

                        <ContractDateItem
                            fieldState={form.contractDateCreated}
                            label={languageStore.get('creationDate')}
                            id='createdOn'
                            editMode={editMode} />

                        <ContractDateItem
                            beforeChange={this.onExpirationDateChanged}
                            fieldState={form.expirationDate}
                            label={languageStore.get('expirationDate')}
                            id='expireOn'
                            editMode={editMode} />

                        <RenewalDate
                            fieldState={form.renewalDate}
                            editMode={editMode}
                            expirationDate={form.expirationDate.value} />

                    </ContractDetailsColumn>

                    <ContractDetailsColumn>

                        <div style={{ marginTop: editMode ? '6.5rem' : '5rem' }}>
                            <ContractDetailsItem
                                fieldState={form.expirationNoticeDays}
                                label={languageStore.get('expirationNoticeDays')}
                                id='expirationNoticeDays'
                                editMode={editMode} />

                            <ContractDetailsItem
                                fieldState={form.renewalNoticeDays}
                                label={languageStore.get('renewalNoticeDays')}
                                id='renewalNoticeDays'
                                editMode={editMode} />
                        </div>

                    </ContractDetailsColumn>

                </ContractDetailsGroup>

            </BaseContract>
        )
    }

}

export default Overview;