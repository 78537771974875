import * as React from 'react';
import { observer } from 'mobx-react';
import Form from 'components/Forms/Form';
import { FormState, FieldState } from 'formstate';
import { languageStore, ContactPeopleInterface, customerStore, CustomerInterface, userStore } from 'stores';
import ContractSection from 'components/ContractSection';
import ContractDetailsGroup from 'components/ContractDetailsGroup';
import ContractDetailsColumn from 'components/ContractDetailsColumn';
import * as _ from 'lodash';
import { mapField } from 'helpers/FormState';
import { required, email } from 'helpers/FormValidation';
import SelectedCustomer from './SelectedCustomer';
import SelectedContact from 'components/PurchaseContractDetailsPage/Supplier/SelectedContact';
import CustomerName from './CustomerName';
import CustomerVatNumber from './CustomerVatNumber';
import SelectedContactDetails from 'components/PurchaseContractDetailsPage/Supplier/SelectedContactDetails';
import { HasCustomerContractStore } from 'stores/HasCustomerContractStore';
import CustomerField from './CustomerField';
import { CVRApiInterface, getCVRdetails } from 'helpers/CVRApiHelper';
import Country from '../Country';


interface Props {
    store: HasCustomerContractStore<any>,
    hash: string,
}

@observer class Customer extends React.Component<Props> {

    form = new FormState({
        customerId: new FieldState(this.props.store.contract!.customerId),
        customerName: new FieldState(this.props.store.contract!.customer && this.props.store.contract!.customer.name).validators(required),
        customerNumber: new FieldState(this.props.store.contract!.customer && this.props.store.contract!.customer.customerNumber),
        vat: new FieldState(this.props.store.contract!.customer && this.props.store.contract!.customer.vat),
        address: new FieldState(this.props.store.contract!.customer && this.props.store.contract!.customer.address),
        zipCode: new FieldState(this.props.store.contract!.customer && this.props.store.contract!.customer.zipCode),
        city: new FieldState(this.props.store.contract!.customer && this.props.store.contract!.customer.city),
        url: new FieldState(this.props.store.contract!.customer && this.props.store.contract!.customer.url),
        countryId: new FieldState(this.props.store.contract!.customer && this.props.store.contract!.customer.countryId),
        contact: new FormState({
            id: new FieldState(this.props.store.contract!.contactPeople && this.props.store.contract!.contactPeople[0].id),
            name: new FieldState(this.props.store.contract!.contactPeople && this.props.store.contract!.contactPeople[0].name),
            title: new FieldState(this.props.store.contract!.contactPeople && this.props.store.contract!.contactPeople[0].title),
            email: new FieldState(this.props.store.contract!.contactPeople && this.props.store.contract!.contactPeople[0].email).validators(email),
            phone: new FieldState(this.props.store.contract!.contactPeople && this.props.store.contract!.contactPeople[0].phone),
            mobile: new FieldState(this.props.store.contract!.contactPeople && this.props.store.contract!.contactPeople[0].mobile),
        })
    })

    contactHasFieldsForSaving() {
        const contact = this.form.$.contact.$;

        return (
            !!contact.name.$ ||
            !!contact.title.$ ||
            !!contact.email.$ ||
            !!contact.phone.$ ||
            !!contact.mobile.$
        )
    }

    onSubmit = (): Promise<void> => {
        return new Promise(async (resolve, reject) => {
            const res = await this.form.validate();
            if (res.hasError) {
                reject();
                return;
            }

            const { store, hash } = this.props;
            const form = this.form.$;
            const contract = store.contract!;
            const isNewCustomer = !!!form.customerId.$;

            let customer: CustomerInterface = {
                id: isNewCustomer ? null : mapField(form.customerId, contract.customerId),
                name: mapField(form.customerName, contract.customer && contract.customer.name),
                vat: mapField(form.vat, contract.customer && contract.customer.vat),
                address: mapField(form.address, contract.customer && contract.customer.address),
                zipCode: mapField(form.zipCode, contract.customer && contract.customer.zipCode),
                city: mapField(form.city, contract.customer && contract.customer.city),
                url: mapField(form.city, contract.customer && contract.customer.url),
                countryId: mapField(form.countryId, contract.customer && contract.customer.countryId),
                customerNumber: mapField(form.customerNumber, contract.customer && contract.customer.customerNumber),
                contactPeople: []
            }

            const isNewContact = isNewCustomer || !!!form.contact.$.id.$;
            let contact: ContactPeopleInterface = {
                id: isNewCustomer || isNewContact ? null : form.contact.$.id.$,
                name: form.contact.$.name.$,
                title: form.contact.$.title.$,
                email: !!form.contact.$.email.$ ? form.contact.$.email.$ : null,
                phone: form.contact.$.phone.$,
                mobile: form.contact.$.mobile.$,
            }

            // Add or update customer backend
            if (isNewCustomer) {
                const addedCustomer = await customerStore.postCustomer(customer);
                customer.id = addedCustomer.id;
            }
            else {
                customer.companyId = userStore.signedInUser!.company.id;
                customerStore.putCustomer(customer);
            }

            // Link customer to contract
            if (isNewCustomer || customer.id !== contract.customerId) {
                store.contract!.customer = customer;
                store.contract!.customerId = customer.id;

                // If store is draft, every onSubmit function on page
                // will be added to a Promise.all function, and afterwards
                // the contract will be saved. So we only need to save in this function
                // if the contract is not in draft mode, where one button should save all promises.
                if (!store.isDraft) {
                    await store.putContract(store.contract!);
                }

            }

            // Link contact to customer
            contact.customerId = customer.id;
            let shouldAddContactToContract = true;

            // Add or update contact backend
            if (isNewContact) {
                if (this.contactHasFieldsForSaving()) {
                    const addedContact = await customerStore.postContact(contact);
                    contact.id = addedContact.id;
                }
                else {
                    shouldAddContactToContract = false;
                }
            }
            else {
                customerStore.putContact(contact);
            }

            // Finally add contact to the contract active on screen
            if (shouldAddContactToContract && !_.find(store.contract!.contactPeople, { id: contact.id! })) {
                store.addContactPersonToContract(contact!);
            }

            store.setEditMode(hash, false);
            resolve();
        })
    }

    componentDidMount() {
        const { store } = this.props;
        if (store.isDraft) {
            store.addSubmitPromise(this.onSubmit);
            store.addSubmitForm(this.form);
        }

        // validate form on first render, to highlight required fields.
        this.form.validate();
    }

    onReset = () => {
        const { store, hash } = this.props;
        store.setEditMode(hash, false);
    }

    onVatBlur = async (vatValue: string) => {
        const form = this.form.$;

        if (vatValue.length === 8) {
            const existingCustomer = _.find(customerStore.customers, { vat: vatValue });
            if (existingCustomer && existingCustomer.id !== form.customerId.$) {
                if (window.confirm(languageStore.get('CVRAlreadyInUseUseInstead'))) {
                    form.customerId.onChange(existingCustomer.id);
                    return;
                }
            }

            const res = await getCVRdetails(vatValue) as CVRApiInterface;
            if (res && res.name !== form.customerName.value) {
                if (window.confirm(languageStore.get('confirmCVRApi'))) {
                    form.customerName.onChange(res.name);
                    form.address.onChange(res.address);
                    form.zipCode.onChange(res.zipcode);
                    form.city.onChange(res.city);
                }
            }
        }
    }

    render() {
        const { store, hash } = this.props;
        const form = this.form.$;
        const editMode = store.isEditMode(hash);

        const emailComponent = <SelectedContactDetails
            label={languageStore.get('email')}
            id='contactPersonEmail'
            fieldState={form.contact.$.email}
            editMode={editMode}
            customerId={form.customerId.value}
            contactId={form.contact.$.id.value}
            onUpdateFieldState={(contact: ContactPeopleInterface) => {
                form.contact.$.email.onChange(contact ? contact.email : '');
            }} />

        const phoneComponent = <SelectedContactDetails
            label={languageStore.get('phone')}
            id='contactPersonPhone'
            fieldState={form.contact.$.phone}
            editMode={editMode}
            customerId={form.customerId.value}
            contactId={form.contact.$.id.value}
            onUpdateFieldState={(contact: ContactPeopleInterface) => {
                form.contact.$.phone.onChange(contact ? contact.phone : '');
            }} />

        const mobileComponent = <SelectedContactDetails
            label={languageStore.get('mobile')}
            id='contactPersonMobile'
            fieldState={form.contact.$.mobile}
            editMode={editMode}
            customerId={form.customerId.value}
            contactId={form.contact.$.id.value}
            onUpdateFieldState={(contact: ContactPeopleInterface) => {
                form.contact.$.mobile.onChange(contact ? contact.mobile : '');
            }} />

        const vatNumberComponent = <CustomerVatNumber
            customerId={form.customerId.value}
            fieldState={form.vat}
            onBlur={this.onVatBlur}
            editMode={editMode} />

        const customerNumberComponent = <CustomerField
            customerId={form.customerId.value}
            fieldState={form.customerNumber}
            field='customerNumber'
            label={languageStore.get('customerNumber')}
            editMode={editMode}
            collection={customerStore.customers}
        />

        // const addressComponent = <ContractDetailsItem
        //     label={languageStore.get('address')}
        //     fieldState={form.address}
        //     editMode={true} />

        const addressComponent = <CustomerField
            customerId={form.customerId.value}
            fieldState={form.address}
            field='address'
            label={languageStore.get('address')}
            editMode={editMode}
            collection={customerStore.customers}
        />

        // const zipCodeComponent = <ContractDetailsItem
        //     label={languageStore.get('zipCode')}
        //     fieldState={form.zipCode}
        //     editMode={editMode} />

        const zipCodeComponent = <CustomerField
            customerId={form.customerId.value}
            fieldState={form.zipCode}
            field='zipCode'
            label={languageStore.get('zipCode')}
            editMode={editMode}
            collection={customerStore.customers}
        />

        // const cityComponent = <ContractDetailsItem
        //     label={languageStore.get('city')}
        //     fieldState={form.city}
        //     editMode={editMode} />

        const cityComponent = <CustomerField
            customerId={form.customerId.value}
            fieldState={form.city}
            field='city'
            label={languageStore.get('city')}
            editMode={editMode}
            collection={customerStore.customers}
        />

        const urlComponent = <CustomerField
            customerId={form.customerId.value}
            fieldState={form.url}
            field='url'
            label={languageStore.get('url')}
            editMode={editMode}
            collection={customerStore.customers}
            onClick={() => window.open(form.url.value?.startsWith('http') ? form.url.value : `http://${form.url.value}`)}
        />

        const countryComponent = <Country
            fieldState={form.countryId}
            editMode={editMode} 
        />

        return (
            <Form onValidSubmit={this.onSubmit} onReset={this.onReset} formState={this.form}>
                <ContractSection
                    canEdit={store.canEdit}
                    hash={hash}
                    onEdit={() => store.setEditMode(hash, !editMode)}
                    editMode={editMode}
                    hideSaveButton={!!store.isDraft}>

                    <ContractDetailsGroup title={languageStore.get('customer')}>

                        {
                            editMode &&
                            <ContractDetailsColumn>
                                <SelectedCustomer fieldState={form.customerId} fallbackValue={store.contract!.customer && store.contract!.customer.name} editMode={editMode} />
                            </ContractDetailsColumn>
                        }

                        <ContractDetailsColumn>

                            {editMode && vatNumberComponent}

                            <CustomerName
                                customerId={form.customerId.value}
                                fieldState={form.customerName}
                                editMode={editMode} />

                            {
                                editMode &&
                                <>
                                    {customerNumberComponent}
                                    {addressComponent}
                                    {zipCodeComponent}
                                    {cityComponent}
                                    {countryComponent}
                                    {urlComponent}
                                </>
                            }

                        </ContractDetailsColumn>

                        {
                            !editMode &&
                            <ContractDetailsColumn>
                                {vatNumberComponent}
                                {customerNumberComponent}
                                {addressComponent}
                                {zipCodeComponent}
                                {cityComponent}
                                {countryComponent}
                                {urlComponent}
                            </ContractDetailsColumn>
                        }

                    </ContractDetailsGroup>

                    <ContractDetailsGroup title={languageStore.get('contactInformation')}>
                        {
                            editMode &&
                            <ContractDetailsColumn>
                                <SelectedContact
                                    fieldState={form.contact.$.id}
                                    customerId={form.customerId.value}
                                    fallbackValue={store.contract!.contactPeople && store.contract!.contactPeople[0].name}
                                    editMode={editMode} />
                            </ContractDetailsColumn>
                        }

                        <ContractDetailsColumn>
                            <SelectedContactDetails
                                label={languageStore.get('name')}
                                id='contactPersonName'
                                fieldState={form.contact.$.name}
                                editMode={editMode}
                                customerId={form.customerId.value}
                                contactId={form.contact.$.id.value}
                                onUpdateFieldState={(contact: ContactPeopleInterface) => {
                                    form.contact.$.name.onChange(contact ? contact.name : '');
                                }} />

                            <SelectedContactDetails
                                label={languageStore.get('title')}
                                id='contactPersonTitle'
                                fieldState={form.contact.$.title}
                                editMode={editMode}
                                customerId={form.customerId.value}
                                contactId={form.contact.$.id.value}
                                onUpdateFieldState={(contact: ContactPeopleInterface) => {
                                    form.contact.$.title.onChange(contact ? contact.title : '');
                                }} />

                            {
                                editMode &&
                                <>
                                    {emailComponent}
                                    {phoneComponent}
                                    {mobileComponent}
                                </>
                            }

                        </ContractDetailsColumn>

                        {
                            !editMode &&
                            <ContractDetailsColumn>
                                {emailComponent}
                                {phoneComponent}
                                {mobileComponent}
                            </ContractDetailsColumn>
                        }

                    </ContractDetailsGroup>

                </ContractSection>
            </Form>
        )

    }

}

export default Customer;