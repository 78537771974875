import * as React from 'react';
import { observer } from 'mobx-react';
import { FieldState } from 'formstate';
import ContractDropdownItem from 'components/ContractDropdownItem';
import { languageStore, countryStore } from 'stores';
import { DropdownOptionInterface } from 'components/Forms/Dropdown';

interface Props {
    fieldState: FieldState<any>,
    editMode?: boolean
}

@observer class Country extends React.Component<Props> {

    componentDidMount() {
        countryStore.getCountries();
    }

    render() {
        const { fieldState, editMode } = this.props;

        let opts: Array<DropdownOptionInterface> = [];
        countryStore.countries.forEach((country) => {
            opts.push({
                value: country.id,
                name: country.name
            })
        })

        return (
            <ContractDropdownItem
                label={languageStore.get('country')}
                id='country'
                placeholderText={languageStore.get('select')}
                fieldState={fieldState}
                options={opts}
                editMode={editMode} />
        )
    }

}

export default Country;