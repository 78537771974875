import { ContractFinancialCompanyServiceStatusEnum, CurrentContractStore, CurrentContractBaseInterface, ContractFinancialCompanyServiceInterface, ContractFinancialCompanyServicePeriodEnum, userStore } from 'stores';
import Moment from 'moment-timezone';
import { ServiceTypeEnum } from './SelectServiceType';

export enum ExpirationEnum {
    CONTRACT_EXPIRATION_DATE,
    CURRENT_YEAR,
    NEXT_YEAR
}

class ServiceHelper {

    calculateServiceSum = (store: CurrentContractStore<CurrentContractBaseInterface>, service: ContractFinancialCompanyServiceInterface, expiration: ExpirationEnum): number => {
        let timeZone = userStore.signedInUser!.timeZone.id;
        let totalSum = 0;
        let startDate: Moment.Moment | null = null;
        let expirationDate: Moment.Moment | null = null;
        const contractExpirationDate: Moment.Moment | null = (store.contract && store.contract.expirationDate) ? Moment.utc(store.contract.expirationDate).tz(timeZone) : null;
        const serviceStartDate = Moment.utc(service.startDate).tz(timeZone);

        if (expiration === ExpirationEnum.CONTRACT_EXPIRATION_DATE) {
            if (contractExpirationDate) {
                startDate = serviceStartDate;
                expirationDate = contractExpirationDate;
            }
            else return 0;
        }
        else if (expiration === ExpirationEnum.CURRENT_YEAR) {
            let newExpiration = Moment().utc().endOf('year');
            let newStart = Moment().utc().startOf('year');

            if (contractExpirationDate && contractExpirationDate.isBefore(newExpiration)) {
                expirationDate = contractExpirationDate;
            }

            if (!expirationDate) {
                expirationDate = newExpiration;
            }

            if (newStart.isBefore(serviceStartDate)) {
                startDate = serviceStartDate;
            }

            if (!startDate) {
                startDate = newStart;
            }

        }
        else if (expiration === ExpirationEnum.NEXT_YEAR) {
            let newExpiration = Moment().utc().endOf('year').add(1, 'year');
            let newStart = Moment().utc().startOf('year').add(1, 'year');

            if (contractExpirationDate && contractExpirationDate.isBefore(newExpiration)) {
                expirationDate = contractExpirationDate;
            }

            if (!expirationDate) {
                expirationDate = newExpiration;
            }

            if (newStart.isBefore(serviceStartDate)) {
                startDate = serviceStartDate;
            }

            if (!startDate) {
                startDate = newStart;
            }

        }

        if (service.serviceStatus === ContractFinancialCompanyServiceStatusEnum.DELETED_OR_HIDDEN) {
            return totalSum;
        }

        const amount = service.quantity * Number(service.price);

        // Fallback for COMA 3, where serviceType was never stored in db
        if (service.serviceType === ServiceTypeEnum.NONE) {
            service.serviceType = ServiceTypeEnum.FIXED_PAYMENTS
        }

        switch (service.serviceType) {
            case ServiceTypeEnum.ONE_TIME_PAYMENT:
                if (expiration === ExpirationEnum.CURRENT_YEAR) {
                    if (Moment(service.startDate).year() === Moment().year()) {
                        totalSum = amount;
                    }
                }
                else if (expiration === ExpirationEnum.NEXT_YEAR) {
                    if (Moment(service.startDate).year() === Moment().year() + 1) {
                        totalSum = amount;
                    }
                }
                else {
                    totalSum = amount;
                }

                break;

            case ServiceTypeEnum.FIXED_PAYMENTS: {
                if (!expirationDate) return 0;

                const billingInterval = service.billingInterval || 1;
                let allPeriods = 0;
                if (expiration === ExpirationEnum.CONTRACT_EXPIRATION_DATE) {
                    allPeriods = service.periods * billingInterval;
                }
                else {
                    const calcPeriods = (identifier: 'months' | 'years' | 'weeks') => {
                        let sum = 0;
                        const periodsBefore = Math.round(Math.abs(startDate!.diff(serviceStartDate, 'months', true))) / billingInterval;
                        const currentPeriods = Math.round(expirationDate!.diff(startDate!, 'months', true)) / billingInterval;

                        if ((currentPeriods + periodsBefore) <= service.periods) {
                            if (currentPeriods <= service.periods) {
                                sum = currentPeriods * billingInterval;
                            }
                            else {
                                sum = service.periods;
                            }

                        }
                        return sum;
                    }

                    switch (service.servicePeriod) {
                        case ContractFinancialCompanyServicePeriodEnum.DELIVERIES:
                            allPeriods = service.periods || 1;
                            break;
                        case ContractFinancialCompanyServicePeriodEnum.MONTHS:
                            allPeriods = calcPeriods('months');
                            break;
                        case ContractFinancialCompanyServicePeriodEnum.YEARS:
                            allPeriods = calcPeriods('years');
                            break;
                        case ContractFinancialCompanyServicePeriodEnum.WEEKS:
                            allPeriods = calcPeriods('weeks');
                            break;

                        default: break;
                    }
                }
                
                if (allPeriods < 0) {
                    return 0;
                }

                const period = Math.round(allPeriods / billingInterval);
                totalSum = period * amount;

                break;

            }
            case ServiceTypeEnum.RUNNING:
                // const startDate = Moment(service.startDate).utc();
                if (!expirationDate) return 0;

                const billingInterval = service.billingInterval || 1;
                let allPeriods = 0;
                switch (service.servicePeriod) {
                    case ContractFinancialCompanyServicePeriodEnum.DELIVERIES:
                        allPeriods = service.periods || 1;
                        break;
                    case ContractFinancialCompanyServicePeriodEnum.MONTHS:
                        allPeriods = (expirationDate.diff(startDate!, 'months', true));
                        break;
                    case ContractFinancialCompanyServicePeriodEnum.YEARS:
                        allPeriods = (expirationDate.diff(startDate!, 'years', true));
                        break;
                    case ContractFinancialCompanyServicePeriodEnum.WEEKS:
                        allPeriods = (expirationDate.diff(startDate!, 'weeks', true));
                        break;

                    default: break;
                }

                if (service.periods && service.periods < allPeriods) {
                    allPeriods = service.periods;
                }

                if (allPeriods < 0) {
                    return 0;
                }

                const period = Math.round(allPeriods / billingInterval);
                totalSum = period * amount;

                break;
        }

        return totalSum;
    }

    calculateTotalServiceSum = (store: CurrentContractStore<CurrentContractBaseInterface>, services: Array<ContractFinancialCompanyServiceInterface>, expiration: ExpirationEnum) => {
        let totalSum = 0;

        services.forEach((service) => {
            totalSum += this.calculateServiceSum(store, service, expiration);
        })

        return totalSum;
    }

}

export const serviceHelper = new ServiceHelper();