import { observable, action, makeObservable } from 'mobx';
import { BaseStore } from './BaseStore';
import request from 'api/request';

export interface FinanceAccountInterface {
    "accNumber": string,
    "accTitle": string,
    "locationId": string,
    "id": string
}

export class FinanceAccountStore extends BaseStore {
    @observable accounts: Array<FinanceAccountInterface> = [];

    constructor() {
        super();

        makeObservable(this);
    }

    @action
    getAccounts(force: boolean = false) {
        this.get('financeaccounts', 'accounts', force);
    }

    @action
    getAccountsByLocationId(locationId: string, force: boolean = false) {
        this.get(`locations/${locationId}/financeaccounts`, 'accounts', force);
    }

    @action
    createAccount(account: FinanceAccountInterface) {
        return this.create(`locations/${account.locationId}/financeaccounts`, account, this.accounts);
    }

    @action
    updateAccount(account: FinanceAccountInterface) {
        return this.update(`locations/${account.locationId}/financeaccounts/${account.id}`, account, this.accounts);
    }

    @action
    deleteAccount(locationId: string, accountId: string) {
        return this.delete(`locations/${locationId}/financeaccounts/${accountId}`, accountId, this.accounts);
    }

    uploadFinanceAccounts(companyId: string, file: any) {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await request.post(`companies/${companyId}/financeaccounts`, file, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });

                resolve(res.data);
            }
            catch(error) {
                reject(error);
            }
        })
    }
}

export const financeAccountStore = new FinanceAccountStore();