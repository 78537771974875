import * as React from 'react';
import { observer } from 'mobx-react';
import ContractSum from 'components/Forms/ContractSum';
import { currentSalesContractStore as store } from 'stores';
import SectionEnum from '../SectionEnum';

@observer class SalesContractSum extends React.Component {

    render() {
        return (
            <ContractSum
                store={store}
                hasWarranty={false}
                hash={SectionEnum.CONTRACT_SUM} />
        )
    }

}

export default SalesContractSum;