import * as React from 'react';
import { observer } from 'mobx-react';
import { FieldState } from 'formstate';
import ContractDropdownItem from 'components/ContractDropdownItem';
import { languageStore, skillStore } from 'stores';
import { DropdownOptionInterface } from 'components/Forms/Dropdown';
import { currentStaffContractStore as store } from 'stores';
import { reaction, IReactionDisposer } from 'mobx';

interface Props {
    fieldState: FieldState<any>,
    editMode?: boolean
}

@observer class SelectSkill extends React.Component<Props> {

    reactionDisposer: IReactionDisposer | any;

    componentDidMount() {
        if (store.contract && store.contract.department) {
            const locationId = store.contract!.department.locationId;
            skillStore.getSkills(locationId);
        }

        this.reactionDisposer = reaction(() => store.currentLocationId, (locationId) => {
            if (locationId && store.contract && 
                ((store.contract.department !== null && store.contract.department.locationId !== locationId) ||
                (store.contract && store.contract!.department === null))) {

                skillStore.getSkills(locationId!);
            }
        })
    }

    componentWillUnmount() {
        this.reactionDisposer();
    }

    render() {
        const { fieldState, editMode } = this.props;

        let ops: Array<DropdownOptionInterface> = [];
        skillStore.skills.forEach((skill) => {
            ops.push({
                value: skill.id,
                name: skill.name
            })
        })

        return (
            <ContractDropdownItem
                label={languageStore.get('skill')}
                id='skill'
                placeholderText={languageStore.get('selectSkill')}
                fieldState={fieldState}
                options={ops}
                editMode={editMode} />
        )
    }

}

export default SelectSkill;