import * as React from 'react';
import { observer } from 'mobx-react';
import HorizontalCategoryMenu from 'components/HorizontalCategoryMenu';
import { languageStore, DashboardContractTypeEnum, dashboardStore, OverviewTypeEnum } from 'stores';
import Moment from 'moment-timezone';
import _ from 'lodash';
import Month from './Month';

interface Props {
    contractType: DashboardContractTypeEnum
}

interface State {
    activeTab: OverviewTypeEnum,
    onlyMine: boolean
}

@observer class ExpireRenewalWidget extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            activeTab: OverviewTypeEnum.EXPIRY,
            onlyMine: false
        }
    }

    setTab(tab: OverviewTypeEnum) {
        this.setState({
            activeTab: tab
        }, () => {
            this.getData(this.props.contractType);
        })
    }

    componentDidMount() {
        const { contractType } = this.props;
        this.getData(contractType);
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        if (nextProps.contractType !== this.props.contractType) {
            this.getData(nextProps.contractType);
        }
    }

    getData(contractType: DashboardContractTypeEnum) {
        const { activeTab, onlyMine } = this.state;

        if (contractType === DashboardContractTypeEnum.STAFF && activeTab !== OverviewTypeEnum.RENEGOTIATION) {
            this.setTab(OverviewTypeEnum.RENEGOTIATION);
            return;
        }

        if (contractType === DashboardContractTypeEnum.PURCHASE && activeTab !== OverviewTypeEnum.EXPIRY && activeTab !== OverviewTypeEnum.RENEWAL) {
            this.setTab(OverviewTypeEnum.EXPIRY);
            return;
        }

        if (contractType === DashboardContractTypeEnum.SERVICE && activeTab !== OverviewTypeEnum.EXPIRY && activeTab !== OverviewTypeEnum.SERVICE) {
            this.setTab(OverviewTypeEnum.EXPIRY);
            return;
        }

        if (contractType === DashboardContractTypeEnum.SALES && activeTab !== OverviewTypeEnum.EXPIRY && activeTab !== OverviewTypeEnum.RENEWAL) {
            this.setTab(OverviewTypeEnum.EXPIRY);
            return;
        }

        dashboardStore.getOverview(contractType, activeTab, onlyMine);
    }

    setOnlyMine(value: boolean) {
        this.setState({ onlyMine: value }, () => {
            this.getData(this.props.contractType);
        });
    }

    render() {
        const { activeTab, onlyMine } = this.state;
        const { contractType } = this.props;

        let thisMonth = Moment().month() + 1; // Months are 0 indexed
        let firstRowOfMonths = [];
        let secondRowOfMonths = [];
        let currentStep = 1;

        while (currentStep <= 12) {
            // eslint-disable-next-line no-loop-func
            const monthData = _.find(dashboardStore.overview, (item) => {
                const month = Moment.utc(item.month).month() + 1;
                return month === thisMonth;
            });

            const month = <Month key={thisMonth} month={thisMonth} count={monthData ? monthData.count : 0} contractType={contractType} activeTab={activeTab} />;

            if (currentStep <= 6) {
                firstRowOfMonths.push(month);
            }
            else {
                secondRowOfMonths.push(month);
            }

            if (thisMonth === 12) {
                thisMonth = 1;
            }
            else {
                thisMonth++;
            }

            currentStep++;
        }

        const activeTabClass = "bg-white inline-block border-l border-t border-r rounded-t py-2 px-4 text-black font-semibold no-underline";
        const defaultTabClass = "bg-white inline-block py-2 px-4 text-grey-darker no-underline";

        return (
            <div className="w-full">
                <ul className="list-reset flex ">
                    <li className="-mb-px mr-1">
                        {
                            (contractType !== DashboardContractTypeEnum.STAFF) &&
                            <button className={activeTab === OverviewTypeEnum.EXPIRY ? activeTabClass : defaultTabClass}
                                onClick={(e) => this.setTab(OverviewTypeEnum.EXPIRY)}>
                                {languageStore.get('expiring')}
                            </button>
                        }

                        {
                            contractType === DashboardContractTypeEnum.STAFF &&
                            <button className={activeTab === OverviewTypeEnum.RENEGOTIATION ? activeTabClass : defaultTabClass}
                                onClick={(e) => this.setTab(OverviewTypeEnum.RENEGOTIATION)}>
                                {languageStore.get('renegotiation')}
                            </button>
                        }
                    </li>
                    <li className="-mb-px mr-1">
                        {
                            (contractType !== DashboardContractTypeEnum.STAFF && contractType !== DashboardContractTypeEnum.SERVICE) &&
                            <button className={activeTab === OverviewTypeEnum.RENEWAL ? activeTabClass : defaultTabClass}
                                onClick={(e) => this.setTab(OverviewTypeEnum.RENEWAL)}>
                                {languageStore.get('toBeRenewed')}
                            </button>
                        }

                        {
                            contractType === DashboardContractTypeEnum.SERVICE &&
                            <button className={activeTab === OverviewTypeEnum.SERVICE ? activeTabClass : defaultTabClass}
                                onClick={(e) => this.setTab(OverviewTypeEnum.SERVICE)}>
                                {languageStore.get('service')}
                            </button>
                        }

                    </li>
                </ul>
                <div className="border p-4 rounded-b rounded-tr">

                    <div className="flex w-full mb-6">
                        <div className="w-1/2" style={{ marginTop: '2px' }}>
                            {languageStore.get('next12months')}
                        </div>
                        <div className="w-1/2 text-right">
                            <HorizontalCategoryMenu
                                size='small'
                                items={[
                                    {
                                        title: languageStore.get('all'),
                                        payload: false,
                                        active: !onlyMine,
                                        onClick: (item: any) => this.setOnlyMine(false)
                                    },
                                    {
                                        title: languageStore.get('mine'),
                                        payload: true,
                                        active: onlyMine,
                                        onClick: (item: any) => this.setOnlyMine(true)
                                    }
                                ]} />
                        </div>
                    </div>

                    <table className="w-full">
                        <tbody>
                            <tr className="flex w-full mb-8">
                                {firstRowOfMonths}
                            </tr>
                            <tr className="flex w-full">
                                {secondRowOfMonths}
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }

}

export default ExpireRenewalWidget;