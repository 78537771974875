import * as React from 'react';
import { observer } from 'mobx-react';
import { when } from 'mobx';
import moment from 'moment-timezone';
import { Modal, ModalColumn } from 'components/Modal';
import Input from 'components/Forms/Input';
import { required } from 'helpers/FormValidation';
import { FormState, FieldState } from 'formstate';
import { navigationStore, languageStore, authStore, ADFSHostEnum, toastStore } from 'stores';
import Form from 'components/Forms/Form';

enum StepEnum {
    LOGIN,
    TWOFACTOR
}

interface State {
    step: StepEnum
}

@observer class ForceRelogin extends React.Component<any, State> {

    state = {
        step: StepEnum.LOGIN
    }

    timer: NodeJS.Timeout | any;

    passwordForm = new FormState({
        password: new FieldState('').validators(required)
    })

    twoFactorForm = new FormState({
        code: new FieldState('').validators(required)
    })

    onSubmitPassword = async () => {
        const form = this.passwordForm.$;

        try {
            await authStore.login(authStore.email!, form.password.$);
            if (authStore.bearerToken) {
                authStore.forceRelogin(false);
            }
            else {
                this.setState({
                    step: StepEnum.TWOFACTOR
                })
            }

            // window.location.reload();
        }
        catch (error) {
            alert(languageStore.get('wrongCredentials'));
        }
    }

    onLogout() {
        authStore.logout();
        navigationStore.push(authStore.loginPage);
    }

    private triggerForceReloginOnauthStoreExpiresChange() {
        when(() => authStore.expires !== null, () => {
            authStore.forceRelogin(false);

            const now = moment.utc();
            const expires = moment(authStore.expires!).add(-30, 'minutes').utc();
            const expiresIn = expires.diff(now, 'milliseconds');

            if (expiresIn < 0) {
                clearTimeout(this.timer);

                if (authStore.adfsHost === ADFSHostEnum.NONE) {
                    authStore.forceRelogin(true);
                }
                else {
                    navigationStore.push(authStore.loginPage)
                }
            }
            else {
                this.timer = setTimeout(() => {
                    if (authStore.adfsHost === ADFSHostEnum.NONE) {
                        authStore.forceRelogin(true);
                    }
                    else {
                        navigationStore.push(authStore.loginPage)
                    }
                }, expiresIn);
            }
        });

        when(() => authStore.expires === null, () => {
            clearTimeout(this.timer);
        });
    }

    componentDidMount() {
        this.triggerForceReloginOnauthStoreExpiresChange();
    }

    onSubmitTwoFactorCode = async () => {
        try {
            await authStore.loginByTwoFactor(authStore.twoFactorAuthToken!, this.twoFactorForm.$.code.$);
            authStore.forceRelogin(false);
            this.setState({ step: StepEnum.LOGIN });
        }
        catch (error) {
            toastStore.addError(languageStore.get('wrongCredentials'));
        }
    }

    render() {
        const { step } = this.state;
        if (!authStore.forceReloginToContinue) return null;

        if (step === StepEnum.LOGIN) {
            return (
                <Form
                    onValidSubmit={this.onSubmitPassword}
                    formState={this.passwordForm}>
                    <Modal
                        title={languageStore.get('reLoginToContinue')}
                        primaryButton={{ title: languageStore.get('signIn') }}
                        secondaryButton={{ title: languageStore.get('logOut'), onClick: this.onLogout }}>

                        <ModalColumn>
                            <Input fieldState={this.passwordForm.$.password} id='password' label={languageStore.get('password')} type='password' />
                        </ModalColumn>

                    </Modal >
                </Form>
            )
        }
        else if (step === StepEnum.TWOFACTOR) {
            return (
                <Form
                    onValidSubmit={this.onSubmitTwoFactorCode}
                    formState={this.twoFactorForm}>
                    <Modal
                        title={languageStore.get('twoFactorAuthentication')}
                        primaryButton={{ title: languageStore.get('signIn') }}
                        secondaryButton={{ title: languageStore.get('logOut'), onClick: this.onLogout }}>

                        <ModalColumn>
                            <Input fieldState={this.twoFactorForm.$.code} id='password' label={languageStore.get('code')} type='password' />
                        </ModalColumn>
                    </Modal >
                </Form>
            )
        }

        return null;
    }

}

export default ForceRelogin;