import * as React from 'react';
import { observer } from 'mobx-react';
import Breadcrumb from 'components/Breadcrumb';
import { languageStore, superAdminStore, CompanyInterface, companyUserStore, locationStore } from 'stores';
import * as _ from 'lodash';
import Locations from './Locations';
import Users from './Users';
import Departments from './Departments';
import EditCompanyModal from './EditCompanyModal';
import UploadFinanceAccounts from './UploadFinanceAccounts';
import SyncElasticSearchButton from './SyncElasticSearchButton';
import DeleteCompanyModal from './DeleteCompanyModal';
import BillingModal from './BillingModal';

@observer class SuperAdminCompanyPage extends React.Component<any> {

    componentDidMount() {
        superAdminStore.getCompanies(true);
        companyUserStore.getUsers(this.getCompanyId());
        locationStore.getLocationsByCompany(this.getCompanyId());
    }

    getCompanyId = () => {
        return this.props.match.params.id;
    }

    getCompany = () => {
        const companies = superAdminStore.companies;
        if (!companies) return null;

        const companyId = this.getCompanyId();
        return _.find(companies, { id: companyId }) as CompanyInterface;
    }

    componentWillUnmount() {
        locationStore.resetStore();
    }

    render() {
        const companyId = this.getCompanyId();
        const company = this.getCompany();
        if (!company) return null;

        return (
            <div>
                <Breadcrumb
                    backlinkTitle={languageStore.get('companies')}
                    backlinkTo='/superadmin/companies'
                    title={company.name} />
                
                <EditCompanyModal company={company} />
                <BillingModal company={company} />
                <UploadFinanceAccounts company={company} />
                <SyncElasticSearchButton companyId={companyId} />
                <DeleteCompanyModal company={company} />
                <Users companyId={companyId} />
                <Locations companyId={companyId} />
                <Departments companyId={companyId} />
                
            </div>
        );
    }

}

export default SuperAdminCompanyPage;