import * as React from 'react';
import { observer } from 'mobx-react';
import { FieldState } from 'formstate';
import * as _ from 'lodash';
import Select  from 'react-select';
import Option from 'react-select';
import makeAnimated from 'react-select/animated';

import './style.css';

export interface DropdownOptionInterface {
    value: any,
    name: string
}

interface Props {
    id: string,
    className?: string,
    fieldState: FieldState<any>,
    placeholderText?: string,
    options: Array<DropdownOptionInterface>,
    label: string,
    skipSort?: boolean,
    type?: 'text' | 'number',
    disabled?: boolean,
    beforeChange?: Function,
    multiple?: boolean
}

@observer class Dropdown extends React.Component<Props> {

    PLACEHOLDER_VALUE = -1;

    onChange(value: any) {

        const { fieldState, type, beforeChange } = this.props;

        // Placeholder value can be string as well as number,
        // so we need to be able to compare "-1" with -1.
        // Therefore the two equals (==) is not a bug!
        // eslint-disable-next-line eqeqeq
        if (value == this.PLACEHOLDER_VALUE) {
            if (beforeChange) beforeChange(null);
            fieldState.onChange(null);
        }
        else {
            if (type === 'number') {
                if (beforeChange) beforeChange(Number(value));
                fieldState.onChange(Number(value));
            }
            else {
                if (beforeChange) beforeChange(value);
                fieldState.onChange(value);
            }
        }
    }

    getFormattedOptions = () => {
        const { skipSort, options } = this.props;

        let opts = options;
        if (!skipSort) {
            opts = _.sortBy(options, 'name');
        }

        return opts;
    }

    componentDidMount() {
        const { fieldState, placeholderText } = this.props;
        let value = fieldState.value;

        let opts = this.getFormattedOptions();

        if (!value && placeholderText === undefined && opts.length > 0) {
            value = opts[0].value;
            fieldState.onChange(value);
        }
    }

    render() {
        const { className, disabled, fieldState, options, placeholderText, id, label, multiple } = this.props;

        let opts = this.getFormattedOptions();

        let value = fieldState.value;
        if (!value && placeholderText) {
            value = this.PLACEHOLDER_VALUE;
        }

        

        // If placeholder, add first
        if (placeholderText && !_.find(options, { value: this.PLACEHOLDER_VALUE })) {
            opts.unshift({
                value: -1,
                name: placeholderText
            })
        }
        if(!multiple)
        {
            return (
                <div
                    id={id}
                    className={`relative border rounded mb-8 ${className}`}
                    style={{ height: '41px', border: fieldState.error ? '1px solid #e4002b' : undefined }}>
                    <select multiple={multiple} className={`w-full h-full py-2 text-green-darker leading-normal rounded bg-transparent`} value={value || undefined} onChange={(e) => this.onChange(e.target.value)} disabled={disabled}>
                        {
                            opts.map((option: DropdownOptionInterface, index: number) => {
                                return <option value={option.value} key={index} style={{ height: '42px' }}>{option.name}</option>
                            })
                        }
                    </select>
                    {
                        (label && label.length > 0) &&
                        <label className="absolute truncate block text-comablue-label pin-t leading-normal cursor-pointer Dropdown-label-floating" htmlFor={id}>
                            {label} {fieldState.error && <span className="text-red text-xs pl-1">({fieldState.error})</span>}
                        </label>
                    }
    
                </div>
            )
        }
        else
        {
            const handleChange = (selectedOption: any) => {
                this.onChange(selectedOption.map((a: any) => a.value).join(";"));
              };

            var _options: any = [];
            opts.forEach(element => {
                _options.push({label: element.name, value: element.value});
            });

            var _value: ReadonlyArray<Option> = value;
            if(value && value !== -1) {
                var _sValue: any = value.split([";"]);
                var _tmpValue: any = []
                
                _sValue.forEach((element: any) => {
                    var o: any = {label: element, value: element};
                    _tmpValue.push(o);
                });

                _value = _tmpValue;

            }

            const animatedComponents = makeAnimated();
            return (
                <div
                    id={id}
                    className={`relative border rounded mb-8 ${className}`}
                    style={{ height: '41px', border: fieldState.error ? '1px solid #e4002b' : undefined }}>
                    <Select closeMenuOnSelect={false} isMulti components={animatedComponents} value={_value} options={_options} onChange={handleChange} />
                    {
                        (label && label.length > 0) &&
                        <label className="absolute truncate block text-comablue-label pin-t leading-normal cursor-pointer Dropdown-label-floating" htmlFor={id}>
                            {label} {fieldState.error && <span className="text-red text-xs pl-1">({fieldState.error})</span>}
                        </label>
                    }
    
                </div>
            )
        }
        
    }

}

export default Dropdown;