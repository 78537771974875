import * as React from 'react';
import { observer } from 'mobx-react';
import ReactTable from 'react-table';
import TableGreenHeader from 'components/Table/TableGreenHeader';
import { BaseReportStore, languageStore, navigationStore } from 'stores';
import ShowOnPrint from 'components/ShowOnPrint';
import moment from 'moment';

interface Props {
    store: BaseReportStore
}

@observer class ResultsTable extends React.Component<Props> {

    gotoContract(row: any) {
        const { store } = this.props;
        navigationStore.push(`/${store.contractEndpoint}/${row.id}`)
    }

    render() {
        const { store } = this.props;
        const data = store.results;
        if (!data || data.length === 0) return null;

        let columns = [] as any;
        store.data.forEach((entry, index) => {
            if (entry.tableVisibility) {
                columns.push({
                    Header: <TableGreenHeader value={languageStore.get(entry.label)} />,
                    id: index,
                    accessor: entry.customConverter || entry.requestField,
                    Cell: (row: any) => row.value ? row.value : <span className='text-grey-dark'>-</span>,
                    sortMethod: (a: string, b: string) => {
                        const dateA = moment(a, 'DD/MM/YYYY', true);
                        const dateB = moment(b, 'DD/MM/YYYY', true);

                        if (dateA.isValid() || dateB.isValid()) {
                            if (!dateA.isValid()) return -1;
                            if (!dateB.isValid()) return 1;
                            
                            return dateA.isAfter(dateB) ? 1 : -1;
                        }
                        else {
                            return a > b ? 1 : -1;
                        }
                    }
                })
            }
        })

        return (
            <div className='border rounded p-4 mt-4'>
                <ShowOnPrint>
                    <ReactTable
                        data={data}
                        columns={columns}
                        showPagination={false}
                        minRows={0}
                        NoDataComponent={() => null}
                        defaultPageSize={99999}
                        collapseOnDataChange={false}
                        getTdProps={(state: any, rowInfo: any, column: any, instance: any) => {
                            return {
                                onClick: (e: React.MouseEvent, handleOriginal: any) => {
                                    this.gotoContract(rowInfo.original);
                                }
                            };
                        }} />
                </ShowOnPrint>
            </div>
        )
    }

}

export default ResultsTable;