import * as React from 'react';
import { observer } from 'mobx-react';
import { Modal, ModalColumn } from 'components/Modal';
import { languageStore, DepartmentInterface, departmentStore } from 'stores';
import { FormState, FieldState } from 'formstate';
import Input from 'components/Forms/Input';
import Form from 'components/Forms/Form';
import { required } from 'helpers/FormValidation';
import Location from 'components/Forms/Location';

interface Props {
    onClose: Function,
    companyId: string
}

@observer class CreateDepartmentModal extends React.Component<Props> {

    form = new FormState({
        name: new FieldState('').validators(required),
        locationId: new FieldState('').validators(required)
    })


    createDepartment = async () => {
        const form = this.form.$;

        const { onClose } = this.props;

        const department = {
            name: form.name.$,
            locationId: form.locationId.$
        } as DepartmentInterface

        await departmentStore.createDepartment(department, departmentStore.departments) as DepartmentInterface;

        onClose();
    }

    render() {
        const { onClose, companyId } = this.props;
        const form = this.form.$;

        return (
            <Form formState={this.form} onValidSubmit={this.createDepartment}>
                <Modal
                    title={languageStore.get('createDepartment')}
                    primaryButton={{ title: languageStore.get('create') }}
                    secondaryButton={{ title: languageStore.get('close'), onClick: onClose }}>

                    <ModalColumn>

                        <Input
                            id='name'
                            label={languageStore.get('name')}
                            fieldState={form.name}
                            type='text' />

                        <Location
                            companyId={companyId}
                            fieldState={form.locationId}
                            editMode={true} />

                    </ModalColumn>

                </Modal>
            </Form>
        )

    }

}

export default CreateDepartmentModal;