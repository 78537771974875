import * as React from 'react';
import { observer } from 'mobx-react';
import ContractSum from 'components/Forms/ContractSum';
import { currentPurchaseContractStore as store } from 'stores';
import SectionEnum from '../SectionEnum';

@observer class PurchaseContractSum extends React.Component {


    render() {
        return (
            <ContractSum
                store={store}
                hasWarranty={true}
                hash={SectionEnum.CONTRACT_SUM} />
        )
    }

}

export default PurchaseContractSum;