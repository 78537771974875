import * as React from 'react';
import { observer } from 'mobx-react';
import { FieldState } from 'formstate';
import Input from 'components/Forms/Input';
import Textarea from 'components/Forms/Textarea';
import numeral from 'numeral';

interface Props {
    label: string,
    value?: string,
    fieldState: FieldState<any>,
    id?: string,
    editMode?: boolean,
    textArea?: boolean,
    type?: 'text' | 'number',
    onBlur?: () => void,
    onClick?: () => void
}

@observer class ContractDetailsItem extends React.Component<Props> {

    //private numberFormatted = false;

    formatNumber = (value: any) => {
        
        if(!value)
        {
            return "";   
        }

        const parsedValue = parseFloat(String(value));
        var number = numeral(parsedValue);
        return number.format("0,0.00")
    }

    componentDidMount(): void {
        //const { editMode, type } = this.props;

        /*if(editMode && type === "number")
        {
            fieldState.value = parseFloat(String(fieldState.value).replace(/,/g, ''));
        }*/
    }
   
    render() {
        const { label, fieldState, id, editMode, textArea, type, onBlur, onClick } = this.props;
        
        if (editMode && fieldState && id) {
            if (textArea) {
                return <Textarea onBlur={onBlur} className='mt-8' fieldState={fieldState} id={id} label={label} />
            }
            else {
                return <Input onBlur={onBlur} type={type === 'number' ? 'number' : 'text'} className='mt-8' fieldState={fieldState} id={id} label={label} />
            }
        }

        return (
            <>
                <dt className='mt-5 text-sm text-comablue-label font-semibold truncate'>
                    {label}
                </dt>
                <dd className='pt-1 break-words'>
                    {onClick && fieldState.value ? (
                        <button onClick={onClick} className='underline'>
                            {type === 'number' ? this.formatNumber(fieldState.value) : fieldState.value}
                        </button>
                    ) : onClick && !fieldState.value ? (
                        '-'
                    ) : (
                        <>{fieldState.value !== undefined && fieldState.value != null ? type === 'number' ? this.formatNumber(fieldState.value) : fieldState.value : '-'}</>
                    )}
                </dd>
            </>
        )
    }

}

export default ContractDetailsItem;