import { ContractFinancialCompanyServiceInterface, ContractFinanceAccountInterface, ContractAlarmInterface, ContractDocumentInterface, VerificationEntryInterface, CurrentContractBaseInterface } from './CurrentContractStore';
import { computed, action, makeObservable } from 'mobx';
import { CustomerInterface } from './CustomerStore';
import { ContractStatusEnum, ContractNoteInterface } from './BaseContractsStore';
import { ContractTypeInterface } from './ContractTypeStore';
import { MainCategoryInterface } from './CategoryStore';
import { SubCategoryInterface } from './SubCategoryStore';
import { ContactPeopleInterface } from './SupplierStore';
import { DepartmentInterface } from './DepartmentStore';
import { CurrencyInterface } from './CurrencyStore';
import { HasCustomerContractStore } from './HasCustomerContractStore';
import { hasRole } from 'helpers/RoleHelper';
import { RoleEnum } from './RolesStore';
import { DashboardContractTypeEnum } from './DashboardStore';

export interface FullSalesContractInterface extends CurrentContractBaseInterface {
    "id": string,
    "customerId": string,
    "customer": CustomerInterface,
    "salesNumber": string,
    "financialServices": Array<ContractFinancialCompanyServiceInterface>,
    "contractFinanceAccounts": Array<ContractFinanceAccountInterface>,
    "description": string,
    "contractSum": number,
    "indexAdjustment": number,
    "paymentTerms": string,
    "contractDateCreated": string,
    "renewalDate": string,
    "renewalNoticeDays": number,
    "expirationDate": string,
    "expirationNoticeDays": number,
    "specialCircumstances": boolean,
    "specialCircumstancesDiscription": string,
    "contractStatus": ContractStatusEnum,
    "contractTypeId": string,
    "contractType": ContractTypeInterface,
    "categoryId": string,
    "category": MainCategoryInterface,
    "subCategoryId": string,
    "subCategory": SubCategoryInterface,
    "contactPeople": Array<ContactPeopleInterface>,
    "departmentId": string,
    "department": DepartmentInterface,
    "currencyId": string,
    "currency": CurrencyInterface,
    "responsibleUserId": string,
    "responsibleUser": {
        "id": string,
        "fullName": string,
        "companyId": string
    },
    "deletionDate": string,
    "deletionNotice": number,
    "deletionProlongReason": string,
    "notes": Array<ContractNoteInterface>,
    "alarms": Array<ContractAlarmInterface>,
    "contractDocuments": Array<ContractDocumentInterface>,
    "contractCorrespondances": Array<ContractDocumentInterface>,
    "contractVerificationEntries": Array<VerificationEntryInterface>,
    "createdOn": string,
    "createdById": string,
    "modifiedById": string,
}

export class CurrentSalesContractStore extends HasCustomerContractStore<FullSalesContractInterface> {

    constructor() {
        super();

        makeObservable(this);

        this.contractEndpoint = 'sales';
        this.contractType = DashboardContractTypeEnum.SALES;
    }

    @action
    createFinanceAccount = (financeAccount: ContractFinanceAccountInterface) => {
        return this.create(`contracts/${this.contractEndpoint}/${this.contract!.id}/financeaccounts`, financeAccount, this.contract!.contractFinanceAccounts);
    }

    @action
    updateFinanceAccount = (financeAccount: ContractFinanceAccountInterface) => {
        return this.update(`contracts/${this.contractEndpoint}/${this.contract!.id}/financeaccounts/${financeAccount.id}`, financeAccount, this.contract!.contractFinanceAccounts);
    }

    @action
    deleteFinanceAccount = (id: string) => {
        return this.delete(`contracts/${this.contractEndpoint}/${this.contract!.id}/financeaccounts/${id}`, id, this.contract!.contractFinanceAccounts);
    }

    @action
    createFinancialService = (service: ContractFinancialCompanyServiceInterface) => {
        return this.create(`contracts/${this.contractEndpoint}/${this.contract!.id}/services`, service, this.contract!.financialServices);
    }

    @action
    updateFinancialService = (service: ContractFinancialCompanyServiceInterface) => {
        return this.update(`contracts/${this.contractEndpoint}/${this.contract!.id}/services/${service.id}`, service, this.contract!.financialServices);
    }

    @action
    deleteFinancialService = (id: string) => {
        return this.delete(`contracts/${this.contractEndpoint}/${this.contract!.id}/services/${id}`, id, this.contract!.financialServices);
    }

    @computed
    get isDraft() {
        return !!this.contract && this.contract.contractStatus === ContractStatusEnum.Draft;
    }

    @computed
    get canEdit() {
        return hasRole(RoleEnum.SALES_WRITE) || hasRole(RoleEnum.SALES_ADMIN);
    }

}

export const currentSalesContractStore = new CurrentSalesContractStore();