import React from 'react';
import { observer } from 'mobx-react';
import SidebarNavigation from 'components/AdminPage/SidebarNavigation';
import Profile from 'components/ProfilePage/Profile';
import { ForceNewPasswordDismissTypeEnum, languageStore, userStore } from 'stores';
import AccessRights from './Profile/AccessRights';
import Departments from './Profile/Departments';

enum ActivePageEnum {
    PROFILE,
    ACCESS_RIGHTS,
    DEPARTMENTS
}

interface State {
    activePage: ActivePageEnum
}

@observer class ProfilePage extends React.Component<any, State> {

    state = { activePage: ActivePageEnum.PROFILE }

    render() {
        const { activePage } = this.state;

        return (
            <div className='flex mt-12'>
                <SidebarNavigation
                    defaultExpanded={true}
                    items={[
                        {
                            title: languageStore.get('profile'),
                            subItems: [
                                {
                                    label: languageStore.get('general'),
                                    onClick: () => this.setState({ activePage: ActivePageEnum.PROFILE }),
                                    isSelected: activePage === ActivePageEnum.PROFILE
                                },
                                {
                                    label: languageStore.get('accessRights'),
                                    onClick: () => this.setState({ activePage: ActivePageEnum.ACCESS_RIGHTS }),
                                    isSelected: activePage === ActivePageEnum.ACCESS_RIGHTS
                                },
                                {
                                    label: languageStore.get('departments'),
                                    onClick: () => this.setState({ activePage: ActivePageEnum.DEPARTMENTS }),
                                    isSelected: activePage === ActivePageEnum.DEPARTMENTS
                                },
                                {
                                    label: languageStore.get('updatePassword'),
                                    onClick: () => userStore.forceNewPasswordForSignedInUser(true, ForceNewPasswordDismissTypeEnum.CANCEL),
                                }
                            ]
                        }
                    ]}
                />

                <div className='flex-1'>
                    {
                        activePage === ActivePageEnum.PROFILE &&
                        <Profile />
                    }

                    {
                        activePage === ActivePageEnum.ACCESS_RIGHTS &&
                        <AccessRights />
                    }

                    {
                        activePage === ActivePageEnum.DEPARTMENTS &&
                        <Departments />
                    }
                </div>
            </div>
        )
    }
}

export default ProfilePage;