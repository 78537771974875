import { BaseStore, BaseContractTypeEnum } from "./BaseStore";
import { observable, action, makeObservable, runInAction } from 'mobx';
import { DocumentTypeInterface, documentTypeStore } from './DocumentTypeStore';
import request from 'api/request';
import { ContractStatusEnum, ContractNoteInterface } from './BaseContractsStore';
import { ServiceInterface } from './ServiceStore';
import { userStore, UserInterface } from './UserStore';
import { TagInterface } from './TagStore';
import { locationStore, LocationInterface } from './LocationStore';
import * as _ from 'lodash';
import { CurrencyInterface } from './CurrencyStore';
import { StaffContractStatusEnum } from './CurrentStaffContractStore';
import { DepartmentInterface } from './DepartmentStore';
import { FormState } from 'formstate';
import { CustomFieldSchemaInterface, CustomFieldTypeEnum } from './CustomFieldStore';
import { DashboardContractTypeEnum } from './DashboardStore';
import { replaceById } from 'helpers/CollectionHelper';
import { languageStore } from './LanguageStore';

export enum ContractAlarmTypeEnum {
    ALARM = 0,
    EXPIRATION = 1,
    RENEWAL = 2,
    STAFF_BIRTHDAY = 3,
    STAFF_SENIORITY = 4,
    STAFF_RENEGOTIATION = 5,
    SERVICE_SERVICEDATE = 6
}

export interface ContractToContractInterface {
    "id": string,
    "identification": string,
    "description": string,
    "departmentName": string,
    "categoryName": string,
    "subCategoryName": string,
    "sum": string,
}

export interface ContractToContractReferenceInterface {
    "id": string,
    "purchaseContractId": string,
    "purchaseContract": ContractToContractInterface,
    "staffContractId": string,
    "staffContract": ContractToContractInterface,
    "serviceContractId": string,
    "serviceContract": ContractToContractInterface,
    "salesContractId": string,
    "salesContract": ContractToContractInterface,
    "sum": number
}

export enum ContractFinancialCompanyServiceStatusEnum {
    AGREED = 1,
    DELETED_OR_HIDDEN = 2
}

export enum ContractFinancialCompanyServicePeriodEnum {
    DELIVERIES = 1,
    WEEKS = 2,
    MONTHS = 3,
    YEARS = 4
}

export interface ContractFinancialCompanyServiceInterface {
    id: string,
    investment: number,
    serviceId: string,
    service: ServiceInterface,
    periods: number,
    billingInterval: number,
    price: string,
    purchaseContractId: string,
    quantity: number,
    revision: number,
    salesContractId: string,
    servicePeriod: ContractFinancialCompanyServicePeriodEnum,
    serviceStatus: ContractFinancialCompanyServiceStatusEnum,
    serviceType: number,
    startDate: string
}

export interface ContractFinanceAccountInterface {
    "use": string,
    "costEstimate": string,
    "actualCost": string,
    "financeAccountStatus": number,
    "financeAccountId": string,
    "id": string,
    "purchaseContractId": string,
    "salesContractId": string,
    "financeAccount": {
        "accNumber": string,
        "accTitle": string,
        "locationId": string,
        "id": string,
    },
}

export interface VerificationEntryInterface {
    "verifiedById": string,
    "id": string,
    "departmentId": string,
    "verifiedOn": string
}

export interface ContractAlarmInterface {
    "date": string,
    "noticeDays": number,
    "note": string,
    "type": ContractAlarmTypeEnum,
    "responsibleUserId": string,
    "purchaseContractId": string,
    "salesContractId": string,
    "serviceContractId": string,
    "id": string
}

export enum DocumentSigningTransactionStateEnum {
    Failed = -1,
    Created = 1,
    Pending = 2,
    Started = 3,
    Signed = 4,
    ReadyForDistribution = 5,
    Completed = 6,
    Expired = 7,
    Canceled = 8,
    DataCollecting = 9,
    Rejected = 10
}

export enum DocumentSigningStatusEnum {
    Failed = -1,
    Created = 1,
    Started = 2,
    Completed = 3,
    Expired = 4,
    Stopped = 5,
    CampaignStarted = 6,
    Rejected = 7,
}

export enum DocumentSigningRecipientAuthenticationMethod {
    None = 0,
    NemIdPrivate = 1,
    NemIdPrivateNoSsn = 2,
    TwoFactorVerification = 3,
    Siths = 4,
    Cpr = 6,
    NemIdEmployee = 7,
    NorwegianBankId = 8,
    SwedishBankId = 9,
    NorwegianBankIdMobile = 10
}

export enum DocumentSigningRecipientSigningMethodEnum {
    NemID = 1,
    Stylus = 2,
    BankID = 3,
    NemIDMoces = 4,
    SwedishBankID = 5,
    Accept = 6,
    NoBankIDMobile = 7,
    NemIdPocesNoSsn = 8,
    FinnishTupas = 9,
    Siths = 10,
}

export interface DocumentSigningRecipientInterface {
    "cvr"?: string,
    "cpr"?: string,
    "norwegianSsn"?: string,
    "swedishSsn"?: string,
    "authenticationMethod": DocumentSigningRecipientAuthenticationMethod,
    "signingMethod": DocumentSigningRecipientSigningMethodEnum,
    "email": string,
    "name": string,
    "title"?: string,
    "tupasSsn"?: string,
    "rejectionComment"?: string,
    "signatureOrder"?: number
}

export interface DocumentSigningInterface {
    "id": string,
    "addoSigningStatus": DocumentSigningStatusEnum,
    "addoSigningId": string,
    "contractId": string,
    "contractType": DashboardContractTypeEnum,
    "documents": Array<ContractDocumentInterface>,
    "recipients": Array<DocumentSigningRecipientInterface>,
    "createdOn": string,
    "modifiedOn": string
}

export interface ContractDocumentInterface {
    "fileName": string,
    "minioName": string,
    "contentType": string,
    "extension": string,
    "verified": boolean,
    "companyId": string,
    "id": string,
    "contentSize": number,
    "documentType": DocumentTypeInterface | null,
    "documentTypeId": string | null,
    "originalDocumentId": string | null,
    "signings": Array<DocumentSigningInterface>,
    "signedLock": boolean,
    "deletable": boolean
}

export interface ContractParentReferenceInterface {
    "id": string,
    "identification": string,
    "description": string,
    "departmentName": string,
    "categoryName": string,
    "subcategoryName": string,
    "sum": number,
    "contractStatus": number
}

export interface ContractParentInterface {
    "id": string,
    "purchaseContractId": string,
    "staffContractId": string,
    "serviceContractId": string,
    "salesContractId": string,
    "contractReferenceId": number,
    "purchaseContract": ContractParentReferenceInterface,
    "staffContract": ContractParentReferenceInterface,
    "serviceContract": ContractParentReferenceInterface,
    "salesContract": ContractParentReferenceInterface
    "contractReference": {
        "id": string,
        "purchaseContractId": string,
        "purchaseContract": ContractParentReferenceInterface,
        "staffContractId": string,
        "staffContract": ContractParentReferenceInterface,
        "serviceContractId": string,
        "serviceContract": ContractParentReferenceInterface,
        "salesContractId": string,
        "salesContract": ContractParentReferenceInterface
    }
}

export interface CurrentContractCustomFieldInterface {
    "id": string,
    "customFieldSchemaId": string,
    "value": string | null,
    "customFieldSchema": CustomFieldSchemaInterface
}

export interface CurrentContractBaseInterface {
    "id": string,
    "contractStatus": number,
    "alarms": Array<ContractAlarmInterface>,
    "notes": Array<ContractNoteInterface>,
    "contractVerificationEntries": Array<VerificationEntryInterface>,
    "createdOn": string,
    "department": DepartmentInterface,
    "modifiedOn": string,
    "createdById": string,
    "modifiedById": string,
    "deletionDate": string | null,
    "deletionProlongReason": string,
    "deletionNotice": number | null,
    "_createdByFullName"?: string,
    "_modifiedByFullName"?: string,
    "tags": Array<TagInterface>,
    "currencyId"?: string,
    "countryId"?: string,
    "currency": CurrencyInterface,
    "contractContractReferences": Array<ContractToContractReferenceInterface>,
    "contractDocuments": Array<ContractDocumentInterface>,
    "contractCorrespondances": Array<ContractDocumentInterface>,
    "parents": Array<ContractParentInterface>,
    "cvs"?: Array<ContractDocumentInterface>,
    "expirationDate"?: string,
    "customFields": Array<CurrentContractCustomFieldInterface>,
    "requireDocumentTypes": boolean,
    "responsibleUserId": string
}

export abstract class CurrentContractStore<T extends CurrentContractBaseInterface> extends BaseStore {
    @observable contract: T | null = null;
    @observable editMode = new Map<string, boolean>();
    @observable currentLocationId: string | null = null;
    @observable currentDepartmentId: string | null = null;
    @observable isDraftJustCreated: boolean = false;

    submitPromises: Array<any> = new Array<any>();
    submitForms: Array<FormState<any>> = [];

    contractEndpoint: string = '';
    contractType: DashboardContractTypeEnum | null = null;


    constructor() {
        super();

        makeObservable(this);
    }

    @action
    setIsDraftJustCreated(value: boolean) {
        this.isDraftJustCreated = value;
    }

    @action
    setCurrentLocationId(locationId: string) {
        this.currentLocationId = locationId;
    }

    @action
    resetCurrentLocationId() {
        this.currentLocationId = null;
    }

    @action
    setCurrentDepartmentId(departmentId: string) {
        this.currentDepartmentId = departmentId;
    }

    @action
    resetCurrentDepartmentId() {
        this.currentDepartmentId = null;
    }

    async setContract(contract: T) {
        if (contract.contractDocuments === null) {
            contract.contractDocuments = [];
        }

        if (contract.contractCorrespondances === null) {
            contract.contractCorrespondances = [];
        }

        if (contract.customFields === null) {
            contract.customFields = [];
        }

        if (contract.cvs === null) {
            contract.cvs = [];
        }

        if (contract!.createdById) {
            const createdBy = await userStore.getUserById(contract!.createdById) as UserInterface;
            contract!._createdByFullName = createdBy ? createdBy.fullName : '';
        }

        if (contract!.modifiedById) {
            const modifiedBy = await userStore.getUserById(contract!.modifiedById) as UserInterface;
            contract!._modifiedByFullName = modifiedBy ? modifiedBy.fullName : '';
        }

        if (this.contractType === DashboardContractTypeEnum.STAFF) {
            if ((contract as any).contactPeople === null) {
                (contract as any).contactPeople = [];
            }
        }

        runInAction(() => {
            this.contract = contract;
        })
    }

    @action
    async getContract(id: string) {
        const res = await request.get(`contracts/${this.contractEndpoint}/${id}`);
        const contract = res.data as T;
        await this.setContract(contract);
    }

    @action
    setEditMode(section: string, value: boolean) {
        this.editMode.set(section, value);
    }

    isEditMode(section: string) {
        return this.isDraft ? true : this.editMode.get(section)
    }

    addSubmitPromise(submitPromise: any) {
        this.submitPromises.push(submitPromise);
    }

    addSubmitForm(submitForm: FormState<any>) {
        this.submitForms.push(submitForm);
    }

    @action
    postDraftContract(draftEnum: ContractStatusEnum | StaffContractStatusEnum) {
        return new Promise(async (resolve, reject) => {
            try {
                var res = await request.post(`contracts/${this.contractEndpoint}`, { contractStatus: draftEnum });
                if (res.status === 201) {
                    await this.setContract(res.data);
                    resolve(res.data);
                }
                else {
                    reject();
                }
            }
            catch (error) {
                reject(error);
            }
        })
    }

    @action
    resetContract() {
        this.contract = null;
        this.submitPromises = [];
        this.submitForms = [];
        this.setIsDraftJustCreated(false);
        this.editMode = new Map<string, boolean>();
    }

    @action
    async putContract(contract: T) {
        if (!contract.currencyId) {
            if (this.currentLocationId) {
                await locationStore.getLocations(false) as any;
                const loc = _.find(locationStore.locations, { id: this.currentLocationId }) as LocationInterface;
                if (loc) {
                    contract.currencyId = loc.country.currencyId;
                }
            }

        }

        contract.customFields.forEach(cf => {
            if(cf.customFieldSchema.fieldType == CustomFieldTypeEnum.DECIMAL || cf.customFieldSchema.fieldType == CustomFieldTypeEnum.INT)
                cf.value = cf.value ? String(cf.value) : null
        });

        const res = await request.put(`contracts/${this.contractEndpoint}/${contract.id}`, contract);
        await this.setContract(res.data);
    }

    @action
    createAlarm(alarm: ContractAlarmInterface) {
        return this.create(`contracts/${this.contractEndpoint}/${this.contract!.id}/alarms`, alarm, this.contract!.alarms);
    }

    @action
    updateAlarm(alarm: ContractAlarmInterface) {
        return this.update(`contracts/${this.contractEndpoint}/${this.contract!.id}/alarms/${alarm.id}`, alarm, this.contract!.alarms);
    }

    @action
    deleteAlarm(id: string) {
        return this.delete(`contracts/${this.contractEndpoint}/${this.contract!.id}/alarms/${id}`, id, this.contract!.alarms);
    }

    @action
    createNote(note: ContractNoteInterface) {
        return this.create(`contracts/${this.contractEndpoint}/${this.contract!.id}/notes`, note, this.contract!.notes);
    }

    @action
    updateNote(note: ContractNoteInterface) {
        return this.update(`contracts/${this.contractEndpoint}/${this.contract!.id}/notes/${note.id}`, note, this.contract!.notes);
    }

    @action
    deleteNote(id: string) {
        return this.delete(`contracts/${this.contractEndpoint}/${this.contract!.id}/notes/${id}`, id, this.contract!.notes);
    }

    @action
    addDocumentToContract(document: ContractDocumentInterface, endpoint: string, collection: Array<any>) {
        return this.create(`contracts/${this.contractEndpoint}/${this.contract!.id}/${endpoint}`, document, collection);
    }

    @action
    updateDocument(document: ContractDocumentInterface, target: Array<any>) {
        return this.update(`documents/${document.id}`, document, target);
    }

    @action
    async deleteDocument(document: ContractDocumentInterface, endpoint: string, collection: Array<any>) {
        if (this.contract!.requireDocumentTypes && !this.isDraft && document.documentTypeId) {
            await documentTypeStore.getDocumentTypes(userStore.signedInUser.company.id);
            const contractTypes = _.filter(documentTypeStore.documentTypes || [], { contractType: this.contractType, required: true }) as Array<DocumentTypeInterface>;

            if (_.find(contractTypes, { id: document.documentTypeId })) {
                alert(languageStore.get('thisIsRequiredDocument') + '!');
                return;
            }
        }

        return this.delete(`contracts/${this.contractEndpoint}/${this.contract!.id}/${endpoint}/${document.id}`, document.id, collection);
    }

    @action
    updateVerificationEntry(entry: VerificationEntryInterface) {
        return this.update(`contracts/${this.contractEndpoint}/${this.contract!.id}/verificationentries/${entry.id}`, entry, this.contract!.contractVerificationEntries);
    }

    @action
    async createTag(tag: TagInterface) {
        if (!this.contract!.tags) {
            this.contract!.tags = [];
        }

        return this.create(`contracts/${this.contractEndpoint}/${this.contract!.id}/tags`, tag, this.contract!.tags);
    }

    @action
    async deleteTag(tagId: string) {
        return this.delete(`contracts/${this.contractEndpoint}/${this.contract!.id}/tags/${tagId}`, tagId, this.contract!.tags);
    }

    @action
    linkContract(targetType: BaseContractTypeEnum, targetId: string): Promise<void> {
        const obj = {
            purchaseContractId: targetType === BaseContractTypeEnum.PURCHASE ? targetId : null,
            staffContractId: targetType === BaseContractTypeEnum.STAFF ? targetId : null,
            serviceContractId: targetType === BaseContractTypeEnum.SERVICE ? targetId : null,
            salesContractId: targetType === BaseContractTypeEnum.SALES ? targetId : null,
        }

        if (!this.contract!.contractContractReferences) {
            this.contract!.contractContractReferences = [];
        }

        return new Promise(async (resolve, reject) => {
            try {
                const res = await request.post(`contracts/${this.contractEndpoint}/${this.contract!.id}/contractreferences`, obj);
                if (res.data) {
                    this.contract!.contractContractReferences.push(res.data);
                }
                resolve();
            }
            catch (error) {
                reject(error);
            }

        })
        // return this.create(`contracts/${this.contractEndpoint}/${this.contract!.id}/contractreferences`, obj, this.contract!.contractContractReferences)

    }

    @action
    deleteLinkToContract(refId: string): Promise<void> {
        return new Promise(async (resolve, reject) => {
            try {
                await request.delete(`contracts/${this.contractEndpoint}/${this.contract!.id}/contractreferences/${refId}`);
                _.remove(this.contract!.contractContractReferences, { id: refId });

                resolve();

            }
            catch (error) {
                reject(error);
            }


        })
    }

    @action
    initializeCustomFields() {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await request.post(`contracts/${this.contractEndpoint}/${this.contract!.id}/initialize-customfields`);
                const fields = res.data;

                this.contract!.customFields = fields;
                resolve(fields);
            }
            catch (error) {
                reject(error);
            }
        })

    }

    @action
    deleteContract(): Promise<void> {
        return new Promise(async (resolve, reject) => {
            try {
                if (this.contract) {
                    await request.delete(`contracts/${this.contractEndpoint}/${this.contract!.id}`);
                    this.resetContract();
                    resolve();
                }

                reject();
            }
            catch (error) {
                reject(error);
            }

        })
    }

    @action
    signDocuments(documents: Array<ContractDocumentInterface>, recipients: Array<DocumentSigningRecipientInterface>, templateId: string, opts?: {
        signByRecipientOrder?: boolean,
        senderComment?: string
    }): Promise<void> {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await request.post(`contracts/${this.contractEndpoint}/${this.contract!.id}/sign-documents`, {
                    documentsForSigning: documents,
                    recipients: recipients,
                    defaultSigningTemplateId: templateId,
                    senderComment: opts?.senderComment,
                    signByRecipientOrder: opts?.signByRecipientOrder
                });

                if (res.status === 200) {

                    const signing = res.data;

                    // Loop through and add placeholder objects for signing status
                    documents.forEach((doc) => {
                        doc.signings = [signing];
                        replaceById(doc.id, this.contract!.contractDocuments, doc);
                    })

                    resolve();
                }
                else reject();
            }
            catch (error) {
                reject(error)
            }
        })
    }

    @action
    cancelDocumentSigning(signingId: string): Promise<void> {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await request.post(`contracts/${this.contractEndpoint}/${this.contract!.id}/signings/${signingId}/cancel`);
                if (res.status === 200) {
                    resolve();
                }
                else reject();
            }
            catch (error) {
                reject(error);
            }
        })
    }

    abstract get isDraft(): boolean;
    abstract get canEdit(): boolean;
}