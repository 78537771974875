import * as React from 'react';

interface ModalButton {
    title: string,
    onClick?: Function
}

interface Props {
    title: string,
    children: React.ReactNode,
    primaryButton?: ModalButton,
    secondaryButton?: ModalButton,
    centerButtons?: boolean,
    forceWide?: boolean
}

export class Modal extends React.Component<Props> {

    secondaryButtonClicked(e: React.MouseEvent) {
        e.preventDefault();
        const { secondaryButton } = this.props;

        if (secondaryButton) {
            secondaryButton.onClick!();
        }
    }

    render() {
        const { children, forceWide, centerButtons, title, primaryButton, secondaryButton } = this.props;
        var numberOfChildren = React.Children.count(children);

        const btnAlignment = centerButtons ? 'text-center' : 'text-right';


        return (
            <div className='fixed pin overflow-auto bg-smoke flex py-10' style={{ zIndex: 999 }}>
                <div className={`relative p-8 bg-white w-full ${numberOfChildren > 1 || forceWide ? "max-w-md" : "max-w-sm"} m-auto flex-col flex rounded`} onClick={(e) => e.stopPropagation()}>
                    <h2 style={{ textOverflow: 'ellipsis' }} className={`font-normal mb-12 overflow-hidden ${centerButtons ? 'text-center' : ''}`}>
                        {title}
                    </h2>
                    <div className='flex'>
                        {children}
                    </div>
                    <div className={`${btnAlignment} mt-5 pr-2`}>
                        {
                            primaryButton &&
                            <button type="submit" onClick={(e) => primaryButton.onClick ? primaryButton.onClick(e) : null} className="bg-comablue-label hover:bg-comablue text-white py-2 px-8 rounded">
                                {primaryButton.title}
                            </button>
                        }

                        {
                            secondaryButton &&
                            <button onClick={(e) => this.secondaryButtonClicked(e)} className="ml-2 border hover:bg-grey-lightest text-black py-2 px-8 rounded">
                                {secondaryButton.title}
                            </button>
                        }
                    </div>
                </div>
            </div>
        )
    }

}