import * as React from 'react';
import { observer } from 'mobx-react';
import { FormState, FieldState } from 'formstate';
import { currentStaffContractStore as store, languageStore } from 'stores';
import BaseContract from '../BaseContract';
import SectionEnum from '../SectionEnum';
import ContractDetailsGroup from 'components/ContractDetailsGroup';
import ContractDetailsColumn from 'components/ContractDetailsColumn';
import ContractDateItem from 'components/ContractDateItem';

@observer class Dates extends React.Component {

    form = new FormState({
        seniorityDate: new FieldState(store.contract!.seniorityDate),
        employmentDate: new FieldState(store.contract!.employmentDate),
        terminationDate: new FieldState(store.contract!.terminationDate),
        renegotiationDate: new FieldState(store.contract!.renegotiationDate),
        resignationDate: new FieldState(store.contract!.resignationDate)
    })

    render() {
        const form = this.form.$;
        const editMode = store.isEditMode(SectionEnum.DATES);

        return (
            <BaseContract
                store={store}
                form={this.form}
                sectionEnum={SectionEnum.DATES}>

                <ContractDetailsGroup title={languageStore.get('dates')}>

                    <ContractDetailsColumn>

                        <ContractDateItem
                            fieldState={form.seniorityDate}
                            label={languageStore.get('seniority')}
                            id='seniority'
                            editMode={editMode} />

                        <ContractDateItem
                            fieldState={form.employmentDate}
                            label={languageStore.get('employmentDate')}
                            id='employmentDate'
                            editMode={editMode} />

                        <ContractDateItem
                            fieldState={form.terminationDate}
                            label={languageStore.get('terminationDate')}
                            id='terminationDate'
                            editMode={editMode} />

                    </ContractDetailsColumn>

                    <ContractDetailsColumn>

                        <ContractDateItem
                            fieldState={form.renegotiationDate}
                            label={languageStore.get('renegotiationDate')}
                            id='renegotiationDate'
                            editMode={editMode} />

                        <ContractDateItem
                            fieldState={form.resignationDate}
                            label={languageStore.get('resignationDate')}
                            id='resignationDate'
                            editMode={editMode} />

                    </ContractDetailsColumn>

                </ContractDetailsGroup>


            </BaseContract>
        )
    }

}

export default Dates;