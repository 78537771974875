import { hasRole } from "helpers/RoleHelper";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { createUserStore, languageStore, RoleEnum, RoleInterface, rolesStore, toastStore, userStore } from "stores";
import useCompanyUserByParams from "../useCompanyUserByParams";
import _ from 'lodash';

const AccessRights = observer(() => {
    const [selectedIds, setSelectedIds] = useState<Array<string>>([]);

    const user = useCompanyUserByParams();

    useEffect(() => {
        if (user) {
            rolesStore.getRoles(user.companyId);
            setSelectedIds(user!.roles.map((d) => d.id));
        }
    }, [user])

    const remove = async (e: React.MouseEvent, role: RoleInterface) => {
        e.preventDefault();
        setSelectedIds(selectedIds.filter((depId) => depId !== role.id))
    }

    const add = async (e: React.MouseEvent, role: RoleInterface) => {
        e.preventDefault();

        const depIds = _.clone(selectedIds);
        depIds.push(role.id);

        setSelectedIds(depIds);
    }

    const onSave = async () => {
        const roles = rolesStore.roles.filter((d) => selectedIds.indexOf(d.id) !== -1);

        await createUserStore.putRoles(user!.companyId, user!.id, roles);
        user!.roles = roles;

        toastStore.addToast({
            level: 'success',
            title: languageStore.get('success'),
            message: languageStore.get('changesSaved')
        })
    }

    const signedInUserHasRole = (role: RoleEnum) => {
        return !!_.find(userStore.signedInUser.roles, { role: Number(role) });
    }

    const addRole = (collection: Array<RoleInterface>, role: RoleEnum) => {
        const existingRole = _.find(rolesStore.roles, { role: Number(role) });
        if (existingRole && !_.some(collection, { role: Number(role) })) {
            collection.push(existingRole);
        }
    }

    const filterRolesBySignedInUser = (roles: Array<RoleInterface>) => {
        if (hasRole(RoleEnum.SUPER_ADMIN)) {
            // Superadmins can add and edit all roles
            return roles;
        }
        else {
            // Regular admins can edit the roles that they have themselves.
            // But if they are something like PURCHASE_ADMINS, but not having READ and WRITE
            // privileges, they can still add those roles.
            const filteredRoles = _.clone(userStore.signedInUser.roles);

            if (signedInUserHasRole(RoleEnum.PURCHASE_ADMIN)) {
                addRole(filteredRoles, RoleEnum.PURCHASE_READ);
                addRole(filteredRoles, RoleEnum.PURCHASE_WRITE);
            }
            if (signedInUserHasRole(RoleEnum.SALES_ADMIN)) {
                addRole(filteredRoles, RoleEnum.SALES_READ);
                addRole(filteredRoles, RoleEnum.SALES_WRITE);
            }
            if (signedInUserHasRole(RoleEnum.HR_ADMIN)) {
                addRole(filteredRoles, RoleEnum.HR_READ);
                addRole(filteredRoles, RoleEnum.HR_WRITE);
            }
            if (signedInUserHasRole(RoleEnum.SERVICE_ADMIN)) {
                addRole(filteredRoles, RoleEnum.SERVICE_READ);
                addRole(filteredRoles, RoleEnum.SERVICE_WRITE);
            }

            return _.uniq(filteredRoles) as Array<RoleInterface>;
        }
    }

    let roles = rolesStore.roles || [];
    if (!roles) return null;
    roles = filterRolesBySignedInUser(roles);
    const canEdit = userStore.signedInUser.company.hasBillingPlan || hasRole(RoleEnum.SUPER_ADMIN);

    return (
        <div>
            {
                roles.map((role: RoleInterface, index: number) => {

                    const hasItem = selectedIds.indexOf(role.id) !== -1;

                    let className = 'mt-2';
                    if (!hasItem) {
                        className += ' text-grey';
                    }

                    return (<div key={index} className={className}>
                        {role.name}

                        {
                            (canEdit && hasItem) &&
                            <button className='ml-2 px-2 py-1 text-xs text-red rounded bg-red-lightest hover:bg-red-lighter' onClick={(e) => remove(e, role)}>{languageStore.get('remove')}</button>
                        }

                        {
                            (canEdit && !hasItem) &&
                            <button className='ml-2 px-2 py-1 text-xs text-green rounded bg-green-lightest hover:bg-green-lighter' onClick={(e) => add(e, role)}>{languageStore.get('add')}</button>
                        }
                    </div>)
                })
            }

            <div className='mt-8'>
                <button type="submit" onClick={(e) => onSave()} className="bg-comablue-label hover:bg-comablue text-white py-2 px-8 rounded">
                    {languageStore.get('saveChanges')}
                </button>
            </div>
        </div>
    )
})

export default AccessRights;