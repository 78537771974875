import * as React from 'react';
import { observer } from 'mobx-react';
import { FormState, FieldState } from 'formstate';
import * as _ from 'lodash';
import { currentStaffContractStore as store, languageStore, userStore, UserInterface, AppraisalStatusEnum, StaffAppraisalInterviewInterface, AppraisalInterviewStatusEnum, ContractDocumentInterface, documentStore, DocumentUrlInterface } from 'stores';
import TableGreenHeader from 'components/Table/TableGreenHeader';
import SectionEnum from '../SectionEnum';
import { ModalColumn } from 'components/Modal';
import ContractDateItem from 'components/ContractDateItem';
import SelectUser from '../Overview/SelectUser';
import ContractDetailsItem from 'components/ContractDetailsItem';
import BaseContractTable from '../BaseContractTable';
import SelectStatus from '../SelectStatus';
import SelectSatisfaction from '../SelectSatisfaction';
import { required } from 'helpers/FormValidation';
import UploadFile from 'components/Forms/UploadFile';
import CellButton from 'components/AdminPage/UsersPage/CellButton';
import { formatDate } from 'helpers/DateHelper';

interface State {
    document: ContractDocumentInterface | null
}

@observer class AppraisalInterviews extends React.Component<any, State> {

    constructor(props: any) {
        super(props);

        this.state = {
            document: null
        }
    }

    form = new FormState({
        id: new FieldState<string | null>(null),
        heldOn: new FieldState(null).validators(required),
        heldWithUserId: new FieldState(null).validators(required),
        satisfaction: new FieldState(null).validators(required),
        conclusion: new FieldState(null),
        documentId: new FieldState<string | null>(null),
        appraisalInterviewStatus: new FieldState(null).validators(required)
    })

    componentDidMount() {
        // We need to make sure that every user is available,
        // to map responsible user id in table to correct full name.
        userStore.getUsers(false);
    }

    beforeSubmit = (interview: StaffAppraisalInterviewInterface) => {
        if (interview.documentId && !interview.document) {
            const { document } = this.state;
            if (document) {
                interview.document = document;
            }
        }
    }

    uploadFile = async (document: ContractDocumentInterface) => {
        this.form.$.documentId.onChange(document.id);
        this.setState({ document: document });
    }

    openFile = async (e: React.MouseEvent, minioName: string) => {
        e.preventDefault();
        e.stopPropagation();

        const endpoint = await documentStore.getDocumentUrl(minioName) as DocumentUrlInterface;
        window.open(endpoint.url);
    }

    onClose = () => {
        this.setState({ document: null })
    }

    render() {
        const { document } = this.state;
        const interviews = store.contract!.appraisalInterviews || [];
        const users = userStore.users;
        const form = this.form.$;

        let existingDoc: ContractDocumentInterface | null = null;
        if (!document && form.id.$) {
            const interview = _.find(interviews, { id: form.id.$ }) as StaffAppraisalInterviewInterface;
            existingDoc = interview.document;
        }

        const columns = [
            {
                Header: <TableGreenHeader value={languageStore.get('heldOn')} />,
                id: 'heldOn',
                accessor: (d: any) => formatDate(d.heldOn)
            },
            {
                Header: <TableGreenHeader value={languageStore.get('heldWith')} />,
                id: 'heldWithUserId',
                accessor: (d: any) => {
                    const userId = d.heldWithUserId;
                    const user = _.find(users, { id: userId }) as UserInterface;
                    return user ? user.fullName : '-';
                }
            },
            {
                Header: <TableGreenHeader value={languageStore.get('satisfaction')} />,
                id: 'satisfaction',
                accessor: (d: any) => {
                    switch (d.satisfaction) {
                        case AppraisalStatusEnum.SATISFIED: return languageStore.get('satisfied');
                        case AppraisalStatusEnum.LESS_SATISFIED: return languageStore.get('lessSatisfied');
                        case AppraisalStatusEnum.LESS_DISSATISFIED: return languageStore.get('lessDissatisfied');
                        case AppraisalStatusEnum.DIS_SATISFIED: return languageStore.get('disSatisfied');
                        default: return null;
                    }
                }
            },
            {
                Header: <TableGreenHeader value={languageStore.get('conclusion')} />,
                accessor: 'conclusion'
            },
            {
                Header: <TableGreenHeader value={languageStore.get('status')} />,
                id: 'appraisalInterviewStatus',
                accessor: (d: any) => {
                    switch (d.appraisalInterviewStatus) {
                        case AppraisalInterviewStatusEnum.SCHEDULED: return languageStore.get('scheduled');
                        case AppraisalInterviewStatusEnum.HELD: return languageStore.get('held');
                        case AppraisalInterviewStatusEnum.DELETED: return languageStore.get('deleted');
                        default: return null;
                    }
                }
            },
            {
                Header: <TableGreenHeader value={languageStore.get('annex')} />,
                id: 'document',
                Cell: (props: any) => {
                    const minioName = props.original.document ? props.original.document.minioName : null;
                    if (!minioName) return '';

                    return (
                        <CellButton
                            onClick={(e) => this.openFile(e, minioName)}
                            text={languageStore.get('show')} />
                    )
                }
            }
        ]

        return (
            <BaseContractTable
                form={this.form}
                onUpdate={(interview: StaffAppraisalInterviewInterface) => store.updateAppraisalInterview(interview)}
                onSubmit={(interview: StaffAppraisalInterviewInterface) => store.createAppraisalInterview(interview)}
                onDelete={(id: string) => store.deleteAppraisalInterview(id)}
                collection={interviews}
                columns={columns}
                onClose={this.onClose}
                beforeSubmit={this.beforeSubmit}
                tableTitle={languageStore.get('appraisalInterview')}
                modalTitle={languageStore.get('appraisalInterview')}
                store={store}
                hash={SectionEnum.APPRAISAL_INTERVIEWS}>
                <ModalColumn>

                    <ContractDateItem
                        label={languageStore.get('heldOn')}
                        id='heldOn'
                        fieldState={form.heldOn}
                        editMode={true} />

                    <SelectUser
                        label={languageStore.get('responsible')}
                        id='heldWithUserId'
                        fieldState={form.heldWithUserId}
                        editMode={true} />

                    <SelectSatisfaction
                        fieldState={form.satisfaction}
                        editMode={true} />

                    <ContractDetailsItem
                        label={languageStore.get('conclusion')}
                        id='conclusion'
                        fieldState={form.conclusion}
                        editMode={true} />


                    <UploadFile onSubmit={this.uploadFile}>

                        <button onClick={(e) => e.preventDefault()} className='bg-comablue-label text-white py-2 px-8 rounded'>
                            {languageStore.get('upload')}
                        </button>

                    </UploadFile>

                    {
                        document &&
                        <p className='mt-2'>{document.fileName}</p>
                    }

                    {
                        existingDoc &&
                        <button className='border text-black py-2 px-8 rounded ml-2' onClick={(e) => this.openFile(e, existingDoc!.minioName)}>{existingDoc.fileName}</button>
                    }

                    <SelectStatus
                        fieldState={form.appraisalInterviewStatus}
                        editMode={true} />

                </ModalColumn>

            </BaseContractTable>
        )
    }

}

export default AppraisalInterviews;