import { observable, action, makeObservable } from 'mobx';
import { createBrowserHistory } from 'history';

export enum HeaderEnum {
    Inbox = 'INBOX',
    Dashboard = 'DASHBOARD',
    Purchase = 'PURCHASE',
    Staff = 'STAFF',
    Service = 'SERVICE',
    Sales = 'SALES',
    Reports = 'REPORTS',
    None = 'NONE'
}

export class NavigationStore {

    @observable activeHeader: HeaderEnum | null = HeaderEnum.Dashboard;
    @observable isScrolling: boolean = false;
    @observable location = null;
    @observable currentHash: string = '';

    history = createBrowserHistory();

    constructor() {
        makeObservable(this);
        this.setActiveHeaderByCurrentBrowserLocation();
    }

    @action
    push(location: string, callback: Function | undefined = undefined) {
        this.history.push(location);
        this.setActiveHeaderByCurrentBrowserLocation();

        this.registerMatomoPageChanged();

        if (callback) {
            callback();
        }
    }

    registerMatomoPageChanged() {
        if (process.env.NODE_ENV === 'development') return;
        // window._paq registered as global script in public/index.html
        const url = process.env.REACT_APP_FRONTEND_BASEURL + this.history.location.pathname;
        var _p = (window as any)._paq || [];
        _p.push(['setCustomUrl', url]);
        _p.push(['setDocumentTitle', this.history.location.pathname]);
        _p.push(['trackPageView']);
    }

    @action
    replace(location: string) {
        this.history.replace(location);
    }

    @action
    setIsScrolling(value: boolean) {
        this.isScrolling = value;
    }

    @action
    setHash(hash: string) {
        this.history.replace({
            pathname: this.history.location.pathname,
            hash: hash,
            key: this.history.location.key
        })
        this.currentHash = hash;
    }

    @action
    go(n: number) {
        this.history.go(n);
    }

    @action
    goBack() {
        this.history.goBack();
    }

    @action
    goForward() {
        this.history.goForward();
    }

    @action
    setActiveHeader(header: HeaderEnum | null) {
        if (header !== this.activeHeader) {
            this.activeHeader = header;
        }
    }

    setActiveHeaderByCurrentBrowserLocation() {
        if (window.location.pathname === '/' || window.location.pathname === '') this.setActiveHeader(HeaderEnum.Dashboard);
        else if (window.location.pathname.startsWith('/purchase')) this.setActiveHeader(HeaderEnum.Purchase);
        else if (window.location.pathname.startsWith('/staff')) this.setActiveHeader(HeaderEnum.Staff);
        else if (window.location.pathname.startsWith('/service')) this.setActiveHeader(HeaderEnum.Service);
        else if (window.location.pathname.startsWith('/sales')) this.setActiveHeader(HeaderEnum.Sales);
        else if (window.location.pathname.startsWith('/reports')) this.setActiveHeader(HeaderEnum.Reports);
        else if (window.location.pathname.startsWith('/inbox') && !window.location.pathname.includes('/admin')) this.setActiveHeader(HeaderEnum.Inbox);
        else this.setActiveHeader(null)
    }
}

export const navigationStore = new NavigationStore();