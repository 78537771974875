import { useEffect } from 'react';
import { observer } from "mobx-react";
import SuppliersCustomersTable, { CustomerSupplierEnum } from 'components/SuppliersCustomersTable';
import { supplierStore } from 'stores';

const SuppliersPage = observer(() => {

    useEffect(() => {
        supplierStore.getSuppliers();
    }, [])

    const suppliers = supplierStore.suppliers || [];

    return (
        <div className='mt-10 mx-auto'>
            <SuppliersCustomersTable
                data={suppliers}
                type={CustomerSupplierEnum.SUPPLIER}
            />

        </div>
    )

})

export default SuppliersPage;