import { action, makeObservable, observable } from 'mobx';
import { BaseStore } from './BaseStore';
import { CurrentContractBaseInterface } from './CurrentContractStore';

export interface AuditInterface extends CurrentContractBaseInterface {
    auditedByName: string | null
}

export class ContractAuditStore extends BaseStore {

    @observable contracts: Array<AuditInterface> = [];

    constructor() {
        super();

        makeObservable(this);
    }

    @action
    resetStore() {
        this.contracts = [];
    }

    @action
    getContractStatusAudits(contractEndpoint: string, contractId: string) {
        return this.get(`contracts/${contractEndpoint}/${contractId}/audits?alwaysIncludeActions=Insert&searchByChangedPropertyName=contractstatus`, 'contracts');
    }

}

export const contractAuditStore = new ContractAuditStore();