import * as React from 'react';
import { observer } from 'mobx-react';
import { FormState, FieldState } from 'formstate';
import { validPassword, required } from 'helpers/FormValidation';
import { when } from 'mobx';
import { userStore, languageStore, authStore, navigationStore, UserTokenInterface, ADFSHostEnum, ForceNewPasswordDismissTypeEnum } from 'stores';
import moment from 'moment-timezone';
import Form from 'components/Forms/Form';
import { Modal, ModalColumn } from 'components/Modal';
import Input from 'components/Forms/Input';
import PasswordRequirements from 'components/PasswordRequirements';

@observer class ForceNewPassword extends React.Component {

    form = new FormState({
        currentPassword: new FieldState('').validators(required),
        newPassword: new FieldState('').validators(required, validPassword),
        newPasswordAgain: new FieldState('').validators(required, validPassword)
    })

    componentWillUnmount() {
        this.form.reset();
    }

    componentDidMount() {
        this.triggerForceNewPasswordWhenCurrentPasswordExpires();
    }

    triggerForceNewPasswordWhenCurrentPasswordExpires() {
        when(() => userStore.signedInUser !== null && userStore.signedInUser !== undefined, () => {
            userStore.forceNewPasswordForSignedInUser(false);

            const now = moment.utc();
            const expires = moment(userStore.signedInUser.passwordExpires).utc();
            const expiresIn = expires.diff(now, 'milliseconds');

            if (expiresIn < 0 && authStore.adfsHost === ADFSHostEnum.NONE) {
                userStore.forceNewPasswordForSignedInUser(true);
            }
        });
    }

    onSubmit = async () => {
        const form = this.form.$;

        try {
            const newToken = await authStore.setNewPassword(form.currentPassword.$, form.newPassword.$) as UserTokenInterface;
            userStore.forceNewPasswordForSignedInUser(false);
            authStore.setSaveableData(newToken);
            await userStore.getSignedInUser();
        }
        catch (error) {
            alert(languageStore.get('wrongCredentials'));
            form.currentPassword.reset('');
            form.newPassword.reset('');
            form.newPasswordAgain.reset('');
        }
    }

    logout = () => {
        authStore.logout();
        navigationStore.push(authStore.loginPage);
    }

    cancel = () => {
        userStore.forceNewPasswordForSignedInUser(false);
    }

    render() {
        if (!userStore.forceNewPasswordForSignedInUserToContinue) return null;

        const form = this.form.$;
        const dismissType = userStore.forceNewPasswordDismissType;

        return (
            <Form
                onValidSubmit={this.onSubmit}
                formState={this.form}>
                <Modal
                    title={languageStore.get(dismissType === ForceNewPasswordDismissTypeEnum.LOG_OUT
                        ? 'pleaseUpdateYourPassword'
                        : 'updatePassword'
                    )}
                    primaryButton={{ title: languageStore.get('updatePassword') }}
                    secondaryButton={dismissType === ForceNewPasswordDismissTypeEnum.LOG_OUT
                        ? { title: languageStore.get('logOut'), onClick: this.logout }
                        : { title: languageStore.get('cancel'), onClick: this.cancel }
                    }>
                    <ModalColumn>
                        <PasswordRequirements
                            currentValue={form.newPassword.value}
                            className='mb-8'
                            style={{ marginTop: '-70px' }}
                        />

                        <Input fieldState={form.currentPassword} id='currentPassword' label={languageStore.get('currentPassword')} type='password' />
                        <Input fieldState={form.newPassword} id='newPassword' label={languageStore.get('newPassword')} type='password' />
                        <Input fieldState={form.newPasswordAgain} id='newPasswordAgain' label={languageStore.get('newPasswordAgain')} type='password' />
                    </ModalColumn>
                </Modal>
            </Form>
        )
    }

}

export default ForceNewPassword;