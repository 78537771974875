import { BaseStore } from './BaseStore';
import { action, observable, makeObservable } from 'mobx';
import { DashboardContractTypeEnum } from './DashboardStore';
import { ContractDocumentInterface } from './CurrentContractStore';
import { CompanyInterface } from './CompanyStore';
import { DepartmentInterface } from './DepartmentStore';
import request from 'api/request';

export interface TemplateInterface {
    "id": string,
    "contractType": DashboardContractTypeEnum,
    "name": string,
    "documentId": string,
    "document": ContractDocumentInterface,
    "companyId": string,
    "company": CompanyInterface,
    "departments": Array<DepartmentInterface>
}

export class TemplateStore extends BaseStore {
    @observable templates: Array<TemplateInterface> = [];

    constructor() {
        super();

        makeObservable(this);
    }

    @action
    resetStore() {
        this.templates = [];
    }

    @action
    getTemplates(contractType: DashboardContractTypeEnum) {
        return this.get(`templates?contractType=${contractType}`, 'templates');
    }

    @action
    createTemplate(template: TemplateInterface) {
        return this.create(`templates`, template, this.templates);
    }

    @action
    updateTemplate(template: TemplateInterface) {
        return this.update(`templates/${template.id}`, template, this.templates);
    }

    @action
    deleteTemplate(templateId: string) {
        return this.delete(`templates/${templateId}`, templateId, this.templates);
    }

    @action
    createDocumentFromTemplate(templateId: string, contractId: string, filename: string): Promise<ContractDocumentInterface> {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await request.post(`templates/${templateId}/fill`, {
                    contractId: contractId,
                    filename: filename
                })

                resolve(res.data);
            }
            catch(error) {
                reject(error);
            }
        })
    }
}

export const templateStore = new TemplateStore();