import { observable, action, makeObservable } from 'mobx';
import { BaseStore } from './BaseStore';
import request from 'api/request';
import * as _ from 'lodash';
import { BaseCustomerSupplierInterface } from './CustomerStore';

export enum ContactPeopleTypeEnum {
    Unspecified = 0,
    Wife = 1,
    Girlfriend = 2,
    Mother = 3,
    Father = 4,
    Manager = 5,
    Brother = 6,
    Sister = 7,
    Son = 8,
    Daughter = 9
}

export interface ContactPeopleInterface {
    "title": string,
    "name": string,
    "mobile": string,
    "phone": string,
    "email": string | null,
    "companyId"?: string,
    "supplierId"?: string,
    "customerId"?: string,
    "id": string | null,
    "isPrimary"?: boolean,
    "type"?: ContactPeopleTypeEnum,
}

export interface SupplierInterface extends BaseCustomerSupplierInterface {
    "supplierNumber"?: string
}

export class SupplierStore extends BaseStore {
    @observable suppliers: Array<SupplierInterface> = [];

    constructor() {
        super();

        makeObservable(this);
    }

    @action
    getSuppliers(force: boolean = true) {
        this.get('suppliers', 'suppliers', force);
    }

    @action
    postSupplier(supplier: SupplierInterface): Promise<SupplierInterface> {
        return this.post('suppliers', 'suppliers', supplier);
    }

    @action
    putSupplier(supplier: SupplierInterface): Promise<SupplierInterface> {
        return this.put(`suppliers/${supplier.id}`, 'suppliers', supplier);
    }

    @action
    deleteSupplier(supplierId: string) {
        return this.delete(`suppliers/${supplierId}`, supplierId, this.suppliers);
    }

    @action
    mergeSupplier(sourceId: string, targetId: string) {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await request.post(`suppliers/merge`, {
                    merge: [sourceId],
                    into: targetId,
                    ignoreContacts: false,
                    ignoreMergeValues: true
                });

                if (res.status === 200) {
                    resolve(res);
                } else reject();
            }
            catch (error) {
                reject(error);
            }
        })
    }

    @action
    postContact(contact: ContactPeopleInterface): Promise<ContactPeopleInterface> {
        return new Promise(async (resolve, reject) => {

            try {
                const res = await request.post(`suppliers/${contact.supplierId!}/contacts`, contact);
                const addedContact = res.data;

                const supplier = _.find(this.suppliers, { id: addedContact.supplierId }) as SupplierInterface;
                if (supplier) {
                    supplier.contactPeople.push(addedContact);
                }

                resolve(addedContact);
            }
            catch (error) {
                reject(error);
            }

        })

    }

    @action
    putContact(contact: ContactPeopleInterface): Promise<ContactPeopleInterface> {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await request.put(`suppliers/${contact.supplierId!}/contacts/${contact.id}`, contact);
                const updatedContact = res.data;

                const supplier = _.find(this.suppliers, { id: updatedContact.supplierId }) as SupplierInterface;
                if (supplier) {
                    const index = _.findIndex(supplier.contactPeople, { id: updatedContact.id });
                    if (index) {
                        supplier.contactPeople.splice(index, 1, updatedContact);
                    }
                }

                resolve(updatedContact);
            }
            catch (error) {
                reject(error);
            }
        })
    }
}

export const supplierStore = new SupplierStore();
