import * as React from 'react';
import { observer } from 'mobx-react';
import { FieldState } from 'formstate';
import { languageStore } from 'stores';

interface Props {
    fieldState: FieldState<Array<string>>,
}

@observer class DropdownValues extends React.Component<Props> {

    delete = (index: number) => {
        const { fieldState } = this.props;
        const arr = fieldState.value || [];

        arr.splice(index, 1);
        fieldState.onChange(arr);
    }

    add = () => {
        let val = window.prompt('Enter value');
        if (!val) return;

        val = val.charAt(0).toUpperCase() + val.slice(1);

        const { fieldState } = this.props;
        const arr = fieldState.value || [];
        arr.push(val);
        fieldState.onChange(arr);
    }

    render() {
        const { fieldState } = this.props;

        return (
            <div className='mb-8'>
                <label className='text-comablue text-sm block font-bold mb-1'>{languageStore.get('values')}</label>
                {
                    fieldState.value.map((value, index) => (
                        <p className='text-black mt-2' key={index}>
                            <span>
                                {value}
                            </span>

                            <span className='text-xs ml-4 text-red cursor-pointer' onClick={() => this.delete(index)}>
                                ({languageStore.get('delete')})
                            </span>
                        </p>
                    ))
                }

                <span onClick={this.add} className='bg-green text-white py-1 px-4 rounded block text-xs mt-4 cursor-pointer inline-block'>Add</span>
            </div>
        )
    }
}

export default DropdownValues;