import { BaseStore } from './BaseStore';
import { ContactPeopleInterface } from './SupplierStore';
import { observable, action, makeObservable } from 'mobx';
import request from 'api/request';
import * as _ from 'lodash';

export interface BaseCustomerSupplierInterface {
    "vat": string,
    "name": string,
    "companyId"?: string,
    "countryId"?: string,
    "contactPeople": Array<ContactPeopleInterface>,
    "id"?: string,
    "address"?: string,
    "zipCode"?: string,
    "city"?: string,
    "url"?: string,
}

export interface CustomerInterface extends BaseCustomerSupplierInterface {
    "customerNumber"?: string
}

export class CustomerStore extends BaseStore {
    @observable customers: Array<CustomerInterface> = [];

    constructor() {
        super();

        makeObservable(this);
    }

    @action
    getCustomers(force: boolean = true) {
        return this.get('customers', 'customers', force);
    }

    @action
    getCustomer(id: string) {
        return this.get(`customers/${id}`, 'customer');
    }

    @action
    postCustomer(customer: CustomerInterface): Promise<CustomerInterface> {
        return this.post('customers', 'customers', customer);
    }

    @action
    putCustomer(customer: CustomerInterface): Promise<CustomerInterface> {
        return this.put(`customers/${customer.id}`, 'customers', customer, false);
    }

    @action
    deleteCustomer(customerId: string) {
        return this.delete(`customers/${customerId}`, customerId, this.customers);
    }

    @action
    mergeCustomer(sourceId: string, targetId: string) {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await request.post(`customers/merge`, {
                    merge: [sourceId],
                    into: targetId,
                    ignoreContacts: false,
                    ignoreMergeValues: true
                });

                if (res.status === 200) {
                    resolve(res);
                } else reject();
            }
            catch (error) {
                reject(error);
            }
        })
    }


    @action
    postContact(contact: ContactPeopleInterface): Promise<ContactPeopleInterface> {
        return new Promise(async (resolve, reject) => {

            try {
                const res = await request.post(`customers/${contact.customerId!}/contacts`, contact);
                const addedContact = res.data;

                const customer = _.find(this.customers, { id: addedContact.customerId }) as CustomerInterface;
                if (customer) {
                    customer.contactPeople.push(addedContact);
                }

                resolve(addedContact);
            }
            catch(error) {
                reject(error);
            }

        })

    }

    @action
    putContact(contact: ContactPeopleInterface): Promise<ContactPeopleInterface> {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await request.put(`customers/${contact.customerId!}/contacts/${contact.id}`, contact);
                const updatedContact = res.data;

                const customer = _.find(this.customers, { id: updatedContact.supplierId }) as CustomerInterface;
                if (customer) {
                    const index = _.findIndex(customer.contactPeople, { id: updatedContact.id });
                    if (index) {
                        customer.contactPeople.splice(index, 1, updatedContact);
                    }
                }

                resolve(updatedContact);
            }
            catch (error) {
                reject(error);
            }
        })
    }
}

export const customerStore = new CustomerStore();