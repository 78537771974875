import * as React from 'react';
import { observer } from 'mobx-react';
import ReactTable from 'react-table';
import { superAdminStore, languageStore, CompanyInterface, navigationStore } from 'stores';
import TableHeader from 'components/Table/TableHeader';
import * as _ from 'lodash';
import { CsvDataService } from 'components/ReportResultsPage/CsvDataService';
import { formatDate } from 'helpers/DateHelper';

@observer class CompaniesTable extends React.Component {

    componentDidMount() {
        superAdminStore.getCompanies(true);
        superAdminStore.getStats(false);
    }

    gotoCompany = (id: string) => {
        navigationStore.push(`/superadmin/companies/${id}`);
    }

    gotoCreateCompany = () => {
        navigationStore.push('/superadmin/createcompany')
    }

    gotoDuplicateCompany = () => {
        navigationStore.push('/superadmin/duplicatecompany')
    }

    createCSV = () => {
        const stats = superAdminStore.stats;
        const data: Array<any> = [];

        const orderedCompanies = _.orderBy(superAdminStore.companies, [company => company.name.toLowerCase()]);
        orderedCompanies.forEach((company: CompanyInterface) => {
            const stat = _.find(stats!.companyRoles, { companyId: company.id });
            if (!stat) return;

            data.push({
                name: company.name,
                purchase: company.hasPurchaseModul ? languageStore.get('yes') : languageStore.get('no'),
                staff: company.hasStaffModul ? languageStore.get('yes') : languageStore.get('no'),
                sales: company.hasSalesModul ? languageStore.get('yes') : languageStore.get('no'),
                service: company.hasServiceModul ? languageStore.get('yes') : languageStore.get('no'),
                plan: company.economicId ? languageStore.get('yes') : languageStore.get('no'),
                operational: stat.operationalCount || 0,
                read: stat.readCount || 0,
            })

        })

        CsvDataService.exportToCsv('report.csv', data);
    }

    render() {
        const companies = superAdminStore.companies;
        const stats = superAdminStore.stats;
        if (!stats || !companies) return null;

        const columns = [
            {
                Header: <TableHeader value={languageStore.get('company')} />,
                accessor: 'name'
            },
            {
                Header: <TableHeader value={languageStore.get('deleteReminder')} />,
                id: 'deleteDateReminder',
                width: 180,
                accessor: (d: CompanyInterface) => {
                    return d.deleteDateReminder ? formatDate(d.deleteDateReminder) : ''
                }
            },
            {
                Header: <TableHeader value='resync' />,
                id: 'needsResync',
                width: 100,
                accessor: (d: CompanyInterface) => {
                    return d.needsResync ? languageStore.get('yes') : languageStore.get('no')
                }
            },
            {
                Header: <TableHeader value={languageStore.get('purchase')} />,
                id: 'purchase',
                width: 100,
                accessor: (d: CompanyInterface) => {
                    return d.hasPurchaseModul ? languageStore.get('yes') : languageStore.get('no')
                }
            },
            {
                Header: <TableHeader value={languageStore.get('staff')} />,
                id: 'staff',
                width: 100,
                accessor: (d: CompanyInterface) => {
                    return d.hasStaffModul ? languageStore.get('yes') : languageStore.get('no')
                }
            },
            {
                Header: <TableHeader value={languageStore.get('sales')} />,
                id: 'sales',
                width: 100,
                accessor: (d: CompanyInterface) => {
                    return d.hasSalesModul ? languageStore.get('yes') : languageStore.get('no')
                }
            },
            {
                Header: <TableHeader value={languageStore.get('service')} />,
                id: 'service',
                width: 100,
                accessor: (d: CompanyInterface) => {
                    return d.hasServiceModul ? languageStore.get('yes') : languageStore.get('no')
                }
            },
            {
                Header: <TableHeader value={languageStore.get('plan')} />,
                id: 'plan',
                width: 100,
                accessor: (d: CompanyInterface) => {
                    return d.economicId ? languageStore.get('yes') : languageStore.get('no')
                }
            },
            {
                Header: <TableHeader value={languageStore.get('operational')} />,
                id: 'operational',
                width: 100,
                Cell: (p: any) => <strong>{p.value}</strong>,
                accessor: (d: CompanyInterface) => {
                    const stat = _.find(stats.companyRoles, { companyId: d.id });
                    return stat ? stat.operationalCount : 0;
                }
            },
            {
                Header: <TableHeader value={languageStore.get('read')} />,
                id: 'reads',
                width: 100,
                Cell: (p: any) => <strong>{p.value}</strong>,
                accessor: (d: CompanyInterface) => {
                    const stat = _.find(stats.companyRoles, { companyId: d.id });
                    return stat ? stat.readCount : 0;
                }
            },
        ]

        return (
            <div>
                <div className='mb-5 text-right'>
                    <button onClick={this.createCSV} className='bg-grey-dark text-white py-2 px-8 rounded mr-2'>
                        CSV
                    </button>
                    <button onClick={this.gotoDuplicateCompany} className='bg-grey-dark text-white py-2 px-8 rounded mr-2'>
                        {languageStore.get('duplicateCompany')}
                    </button>
                    <button onClick={this.gotoCreateCompany} className='bg-comablue-label text-white py-2 px-8 rounded'>
                        {languageStore.get('createCompany')}
                    </button>
                </div>
                <div className='border rounded p-5'>
                    <ReactTable
                        data={companies.slice()}
                        columns={columns}
                        defaultSorted={[{ id: 'name' }]}
                        defaultPageSize={99999}
                        showPagination={false}
                        NoDataComponent={() => null}
                        getTdProps={(state: any, rowInfo: any, column: any, instance: any) => {
                            return {
                                onClick: (e: React.MouseEvent, handleOriginal: any) => {
                                    const id = rowInfo.original.id;
                                    this.gotoCompany(id);
                                }
                            };
                        }}
                        minRows={0} />
                </div>
            </div>
        )
    }

}

export default CompaniesTable;