import * as React from 'react';
import { observer } from 'mobx-react';
import { FormState, FieldState } from 'formstate';
import Input from 'components/Forms/Input';
import Textarea from 'components/Forms/Textarea';
import Form from 'components/Forms/Form';
import { languageStore, userStore, toastStore, timeZoneStore } from 'stores';
import { mapField } from 'helpers/FormState';
import { required } from 'helpers/FormValidation';
import SelectLanguage from './SelectLanguage';
import SelectTrueFalse from 'components/Forms/SelectTrueFalse';
import SelectTimeZone from 'components/Forms/SelectTimeZone';
import * as _ from 'lodash';

@observer class Profile extends React.Component {

    form = new FormState({
        id: new FieldState<number | null>(null),
        fullName: new FieldState('').validators(required),
        title: new FieldState(''),
        skypeName: new FieldState(''),
        // location: new FieldState(''),
        language: new FieldState(null),
        email: new FieldState('').validators(required),
        phone: new FieldState(''),
        mobile: new FieldState(''),
        description: new FieldState(''),
        allowSuperAdminLogin: new FieldState(false),
        timeZoneId: new FieldState(null).validators(required)
    })

    async componentDidMount() {
        let me: any = userStore.signedInUser;
        if (!me) {
            me = await userStore.getSignedInUser();
        }

        const form = this.form.$;
        form.id.reset(me.id);
        form.fullName.reset(me.fullName);
        form.title.reset(me.title);
        form.skypeName.reset(me.skypeName);
        // form.location.reset(me.location)
        form.language.reset(me.language);
        form.email.reset(me.email);
        form.phone.reset(me.phone);
        form.mobile.reset(me.mobile);
        form.description.reset(me.description);
        form.allowSuperAdminLogin.reset(me.allowSuperAdminLogin);
        form.timeZoneId.reset(me.timeZoneId);
    }

    onSubmit = async () => {
        const form = this.form.$;
        let user = userStore.signedInUser;

        user.fullName = mapField(form.fullName, user.fullName);
        user.title = mapField(form.title, user.title);
        user.skypeName = mapField(form.skypeName, user.skypeName);
        // user.location = mapField(form.location, user.location);
        user.language = mapField(form.language, user.language);
        // user.email = mapField(form.email, user.email);
        user.phone = mapField(form.phone, user.phone);
        user.mobile = mapField(form.mobile, user.mobile);
        user.description = mapField(form.description, user.description);
        user.allowSuperAdminLogin = mapField(form.allowSuperAdminLogin, user.allowSuperAdminLogin);

        if (user.timeZoneId !== form.timeZoneId.$) {
            await timeZoneStore.getTimeZones();
            const tz = _.find(timeZoneStore.timeZones, { id: form.timeZoneId.$ });
            user.timeZone = tz;
        }

        user.timeZoneId = mapField(form.timeZoneId, user.timeZoneId);
        await userStore.updateSignedInUser(user);

        toastStore.addToast({
            level: 'success',
            title: languageStore.get('success'),
            message: languageStore.get('settingsSaved')
        })

        window.location.reload();

    }

    render() {
        const form = this.form.$;

        return (
            <div>
                <Form onValidSubmit={this.onSubmit} formState={this.form}>

                    <div className='m-auto flex mb-6' style={{ maxWidth: '600px' }}>
                        <h1>{languageStore.get('general')}</h1>
                    </div>
                    <div className='m-auto flex' style={{ maxWidth: '600px' }}>
                        <div className='w-1/2 inline-block p-4'>
                            <Input fieldState={form.fullName} id='fullName' label={languageStore.get('fullName')} />
                            <Input fieldState={form.title} id='title' label={languageStore.get('title')} />
                            <Input fieldState={form.skypeName} id='skypeName' label='Skype' />
                            {/* <Input fieldState={form.location} id='location' label={languageStore.get('location')} /> */}
                            <SelectLanguage fieldState={form.language} />
                            <SelectTimeZone fieldState={form.timeZoneId} />
                            <SelectTrueFalse
                                id='allowSuperAdminLogin'
                                label={languageStore.get('allowSuperAdminLogin')}
                                fieldState={form.allowSuperAdminLogin}
                                editMode={true}
                                description={languageStore.get('allowSuperAdminLoginDescription')} />
                        </div>
                        <div className='w-1/2 inline-block p-4'>
                            <Input fieldState={form.email} id='email' label={languageStore.get('email')} readOnly={true} />
                            <Input fieldState={form.phone} id='phone' label={languageStore.get('phone')} />
                            <Input fieldState={form.mobile} id='mobile' label={languageStore.get('mobile')} />
                            <Textarea fieldState={form.description} id='description' label={languageStore.get('description')} />
                        </div>

                    </div>
                    <div className="text-center">
                        <button type="submit" className="bg-comablue-label hover:bg-black text-white py-2 px-8 rounded">
                            {languageStore.get('saveChanges')}
                        </button>
                    </div>

                </Form>
            </div>
        )
    }

}

export default Profile;