import { hasLocationAndDepartmentAdminRole } from "helpers/RoleHelper";
import _ from "lodash";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { companyUserStore, languageStore, LocationInterface, locationStore, toastStore } from "stores";
import useCompanyUserByParams from "../useCompanyUserByParams";

const Locations = observer(() => {
    const [selectedIds, setSelectedIds] = useState<Array<string>>([]);

    const user = useCompanyUserByParams();

    useEffect(() => {
        if (user) {
            if (hasLocationAndDepartmentAdminRole()) {
                locationStore.getLocationsByCompany(user!.companyId);
            }
            else {
                locationStore.getLocations(true);
            }
            setSelectedIds(user!.locations.map((l) => l.id));
        }
    }, [user])

    const deleteFromLocation = (e: React.MouseEvent, location: LocationInterface) => {
        e.preventDefault();
        setSelectedIds(selectedIds.filter((locId) => locId !== location.id));
    }

    const addToLocation = (e: React.MouseEvent, location: LocationInterface) => {
        e.preventDefault();

        const locIds = _.clone(selectedIds);
        locIds.push(location.id);

        setSelectedIds(locIds);
    }

    const onSave = async () => {
        const locations = locationStore.locations.filter((l) => selectedIds.indexOf(l.id) !== -1);
        await companyUserStore.setLocations(user!.companyId, user!.id, locations);

        toastStore.addToast({
            level: 'success',
            title: languageStore.get('success'),
            message: languageStore.get('changesSaved')
        })
    }

    const locations = _.orderBy(locationStore.locations, 'name');

    return (
        <div>
            {
                locations.map((location: LocationInterface, index: number) => {

                    const hasItem = selectedIds.indexOf(location.id) !== -1;

                    let className = 'mt-2';
                    if (!hasItem) {
                        className += ' text-grey';
                    }

                    return (
                        <div key={index} className={className}>
                            {location.name}

                            {
                                hasItem &&
                                <button className='ml-2 px-2 py-1 text-xs text-red rounded bg-red-lightest hover:bg-red-lighter' onClick={(e) => deleteFromLocation(e, location)}>{languageStore.get('remove')}</button>
                            }

                            {
                                !hasItem &&
                                <button className='ml-2 px-2 py-1 text-xs text-green rounded bg-green-lightest hover:bg-green-lighter' onClick={(e) => addToLocation(e, location)}>{languageStore.get('add')}</button>
                            }
                        </div>
                    )
                })
            }

            <div className='mt-8'>
                <button type="submit" onClick={(e) => onSave()} className="bg-comablue-label hover:bg-comablue text-white py-2 px-8 rounded">
                    {languageStore.get('saveChanges')}
                </button>
            </div>
        </div>
    )
})

export default Locations;