import * as React from 'react';
import { observer } from 'mobx-react';
// import GeneralInformationStep from './GeneralInformationStep';
import DuplicateCompanyStep from './DuplicateCompanyStep';

@observer class DuplicateCompanyPage extends React.Component {

    render() {
        return (
            <DuplicateCompanyStep />
        )
    }

}

export default DuplicateCompanyPage;