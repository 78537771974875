import * as React from 'react';
import { observer } from 'mobx-react';
import { Modal, ModalColumn } from 'components/Modal';
import { languageStore, CompanyUserInterface, companyUserStore, UserReassignDepartmentInterface } from 'stores';
import * as _ from 'lodash';
import { FormState, FieldState } from 'formstate';
import ResponsibleUser from 'components/Forms/ResponsibleUser';
import Form from 'components/Forms/Form';
import { required } from 'helpers/FormValidation';

interface Props {
    onClose: () => void,
    user: CompanyUserInterface,
    reassignOnly?: boolean
}

@observer class DeleteUserModal extends React.Component<Props> {

    form = new FormState({});

    componentDidMount() {
        const { user } = this.props;

        companyUserStore.getAssignedDepartments(user.companyId, user.id);

        const companyId = user.companyId;
        companyUserStore.getUsers(companyId);
    }

    componentWillUnmount() {
        companyUserStore.resetAssignedDepartments();
    }

    onSubmit = async () => {
        const { user, reassignOnly, onClose } = this.props;
        let data: Array<UserReassignDepartmentInterface> = [];

        for (let departmentId in this.form.$) {
            let value = this.form.$[departmentId] as FieldState<string>;
            const userId = value.$;
            if (userId) {
                data.push({
                    departmentId: departmentId,
                    reassingToUserId: userId
                })
            }
        }

        if (data.length > 0) {
            await companyUserStore.reassignDepartment(user.companyId, user.id, data);
        }

        if (!reassignOnly) {
            await companyUserStore.deleteUser(user);
        }

        onClose(); 

    }

    render() {
        const { onClose, user, reassignOnly } = this.props;
        if (!user) return null;

        const form = this.form.$;
        const departments = companyUserStore.assignedDepartments || [];
        let submitButtonText = languageStore.get('deleteUser');
        let titleText = languageStore.get('delete');

        if (reassignOnly) {
            submitButtonText = languageStore.get('reassign');
            titleText = languageStore.get('reassign');
        }
        else if (departments.length > 0) {
            submitButtonText = languageStore.get('reassignAndDelete');
        }

        const filteredDepartments: Array<React.ReactNode> = [];

        departments.forEach((dep) => {
            let field = new FieldState(null).validators(required);

            if (reassignOnly) {
                field = new FieldState(null);
            }

            form[dep.id] = field;

            let label = `${dep.name} (${dep.location.name})`

            if (reassignOnly && !_.find(user.departments, { id: dep.id })) {
                label += ` *${languageStore.get('notInDepartment')}*`
            }

            filteredDepartments.push(
                <ResponsibleUser
                    companyId={user.companyId}
                    skipUserId={user.id}
                    departmentIds={[dep.id]}
                    key={dep.id}
                    label={label}
                    fieldState={field}
                    force={false}
                    editMode={true} />
            )
        })

        return (
            <Form onValidSubmit={this.onSubmit} formState={this.form}>
                <Modal
                    title={`${titleText} ${user.fullName}`}
                    primaryButton={{ title: submitButtonText }}
                    secondaryButton={{ title: languageStore.get('close'), onClick: onClose }}>

                    <ModalColumn>

                        {
                            filteredDepartments.length === 0 &&
                            <div>
                                {languageStore.get('noDepartmentsToReassign')}
                            </div>
                        }

                        {filteredDepartments}

                    </ModalColumn>

                </Modal>
            </Form>
        )
    }

}

export default DeleteUserModal;