import * as React from 'react';
import { observer } from 'mobx-react';
import { FieldState } from 'formstate';
import ContractDropdownItem from 'components/ContractDropdownItem';
import { languageStore, ContractFinancialCompanyServicePeriodEnum } from 'stores';

interface Props {
    fieldState: FieldState<any>
}

@observer class SelectPeriod extends React.Component<Props> {

    options = [
        {
            value: ContractFinancialCompanyServicePeriodEnum.DELIVERIES,
            name: languageStore.get('deliveries')
        },
        {
            value: ContractFinancialCompanyServicePeriodEnum.WEEKS,
            name: languageStore.get('weeks')
        },
        {
            value: ContractFinancialCompanyServicePeriodEnum.MONTHS,
            name: languageStore.get('months')
        },
        {
            value: ContractFinancialCompanyServicePeriodEnum.YEARS,
            name: languageStore.get('years')
        },
    ]

    render() {
        const { fieldState } = this.props;

        return <ContractDropdownItem
                    label={languageStore.get('periods')}
                    placeholderText={languageStore.get('selectPeriods')}
                    id='period'
                    fieldState={fieldState}
                    options={this.options}
                    skipSort={true}
                    editMode={true} />
    }

}

export default SelectPeriod;