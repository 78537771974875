import React from 'react';
import { observer } from 'mobx-react';
import { DepartmentInterface, departmentStore, languageStore, LocationInterface, locationStore, UserInterface, userStore } from 'stores';
import _ from 'lodash';

interface ItemInterface {
    departmentName: string,
    locationName: string
}

@observer class Departments extends React.Component {

    componentDidMount() {
        const me = userStore.signedInUser as UserInterface;
        departmentStore.getDepartmentsByUser(me.company.id, me.id);
        locationStore.getLocationsByCompany(me.company.id);
    }

    mapData = (departments: Array<DepartmentInterface>, locations: Array<LocationInterface>): Array<ItemInterface> => {
        const data: Array<ItemInterface> = [];

        departments.forEach((department) => {
            data.push({
                departmentName: department.name,
                locationName: _.find(locations, { id: department.locationId })?.name || ''
            })
        })

        return _.orderBy(data, ['locationName', 'departmentName']);
    }

    render() {
        const departments = departmentStore.departments || [];
        const locations = locationStore.locations || [];
        const data = this.mapData(departments, locations);

        return (
            <div className='m-auto' style={{ maxWidth: '600px' }}>
                <h1 className='mb-6'>{languageStore.get('departments')}</h1>
                <ul>
                    {
                        data.map((entry, index) => {
                            return (
                                <li key={index} className='my-2'>{entry.departmentName} ({entry.locationName})</li>
                            )
                        })
                    }
                </ul>
            </div>
        )
    }
}

export default Departments;