import { action, observable, makeObservable } from "mobx";
import { PagingResult } from "./BaseContractsStore";
import { BaseStore } from "./BaseStore";

export interface InboxListInterface {
    "id": string,
    "name": string,
    "email": string,
    "isPublic": boolean,
    "daysBeforeMessageDelete": number,
    "canEdit": boolean,
    "messageCount": number
}

export class InboxesStore extends BaseStore {
    @observable inboxes: PagingResult<InboxListInterface> | null = null;

    resetStore = () => {
        this.inboxes = null;
    }

    @action
    getInboxes = () => {
        return this.get(`inboxes?pagenumber=1&pageSize=9999`, 'inboxes');
    }

    @action
    createInbox = (data: any) => {
        return this.create(`inboxes`, data, this.inboxes?.results || []);
    }

    constructor() {
        super();

        makeObservable(this);
    }
}

export const inboxesStore = new InboxesStore();