import * as React from 'react';
import { observer } from 'mobx-react';
import { FieldState } from 'formstate';
import ContractDropdownItem from 'components/ContractDropdownItem';
import { languageStore, ReportCompareTypeEnum } from 'stores';

export enum ReportCompareEnum {
    EQUALS,
    CONTAIN,
    DOES_NOT_CONTAIN,
    GREATER_THAN,
    LOWER_THAN,
    EXISTS,
    DOES_NOT_EXIST
}

interface Props {
    type: ReportCompareTypeEnum
    fieldState: FieldState<any>,
    disabled?: boolean,
    beforeChange?: (value: any) => void
}

interface State {
    options: Array<any>
}

@observer class CompareDropdown extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            options: []
        }
    }

    componentDidMount() {
        const { type } = this.props;
        this.setOptions(type);
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        const { type } = nextProps;
        if (this.props.type !== type) {
            this.setOptions(type);
        }
    }

    setOptions = (type: ReportCompareTypeEnum) => {
        let opts: any = [];

        switch (type) {
            case ReportCompareTypeEnum.TEXT:
                opts.push({
                    name: languageStore.get('contains'),
                    value: ReportCompareEnum.CONTAIN
                });
                opts.push({
                    name: languageStore.get('doesNotContain'),
                    value: ReportCompareEnum.DOES_NOT_CONTAIN
                });

                break;

            case ReportCompareTypeEnum.NUMBER:
                opts.push({
                    name: languageStore.get('equals'),
                    value: ReportCompareEnum.EQUALS
                })
                opts.push({
                    name: languageStore.get('greaterThan'),
                    value: ReportCompareEnum.GREATER_THAN
                })
                opts.push({
                    name: languageStore.get('lowerThan'),
                    value: ReportCompareEnum.LOWER_THAN
                })

                break;

            case ReportCompareTypeEnum.DATE:
                opts.push({
                    name: languageStore.get('greaterThan'),
                    value: ReportCompareEnum.GREATER_THAN
                })
                opts.push({
                    name: languageStore.get('lowerThan'),
                    value: ReportCompareEnum.LOWER_THAN
                })

                break;

            case ReportCompareTypeEnum.DROPDOWN:
                opts.push({
                    name: languageStore.get('equals'),
                    value: ReportCompareEnum.EQUALS
                })

                break;

            default:
                break;
        }

        if (type) {
            opts.push({
                name: languageStore.get('exists'),
                value: ReportCompareEnum.EXISTS
            });

            opts.push({
                name: languageStore.get('doesNotExist'),
                value: ReportCompareEnum.DOES_NOT_EXIST
            });
        }


        this.setState({ options: opts }, () => {
            const { fieldState } = this.props;

            if (!fieldState.value && opts.length > 0) {
                fieldState.onChange(opts[0].value);
            }
        });
    }

    render() {
        const { options } = this.state;
        const { fieldState, disabled, beforeChange } = this.props;

        if (options.length === 0) return null;

        return (
            <ContractDropdownItem
                disabled={disabled !== undefined ? disabled : false}
                className='mr-2'
                beforeChange={beforeChange}
                label=''
                skipSort={true}
                id='status'
                fieldState={fieldState}
                options={options}
                type='number'
                editMode={true} />
        )
    }

}

export default CompareDropdown;