import * as React from 'react';
import { observer } from 'mobx-react';
import { FieldState } from 'formstate';
import ContractDropdownItem from 'components/ContractDropdownItem';
import { languageStore } from 'stores';

interface Props {
    fieldState: FieldState<any>
}

@observer class SelectStatus extends React.Component<Props> {

    options = [
        {
            value: 1,
            name: languageStore.get('active')
        },
        {
            value: 2,
            name: languageStore.get('inactive')
        }
    ]

    render() {
        const { fieldState } = this.props;

        return <ContractDropdownItem
                    label={languageStore.get('status')}
                    placeholderText={languageStore.get('selectStatus')}
                    id='status'
                    fieldState={fieldState}
                    options={this.options}
                    type='number'
                    editMode={true} />
    }

}

export default SelectStatus;