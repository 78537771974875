import * as React from 'react';
import { observer } from 'mobx-react';
import { FieldState } from 'formstate';
import ContractDropdownItem from 'components/ContractDropdownItem';
import { languageStore, financeAccountStore, FinanceAccountInterface, CurrentContractStore, CurrentContractBaseInterface } from 'stores';
import { DropdownOptionInterface } from 'components/Forms/Dropdown';

interface Props {
    fieldState: FieldState<any>,
    store: CurrentContractStore<CurrentContractBaseInterface>
}

@observer class SelectAccount extends React.Component<Props> {

    componentDidMount() {
        financeAccountStore.getAccounts(true);
    }

    render() {
        const { fieldState, store } = this.props;
        let options: Array<DropdownOptionInterface> = [];
        const locationId = store.currentLocationId;
        
        financeAccountStore.accounts && financeAccountStore.accounts.forEach((acc: FinanceAccountInterface) => {
            if (locationId === acc.locationId) {
                options.push({
                    value: acc.id,
                    name: acc.accTitle
                })
            }
        });

        return <ContractDropdownItem
            label={languageStore.get('accountTitle')}
            placeholderText={languageStore.get('selectAccount')}
            id='account'
            fieldState={fieldState}
            options={options}
            editMode={true} />
    }

}

export default SelectAccount;