import { BaseStore } from './BaseStore';
import { observable, action, makeObservable } from 'mobx';
import { DocumentSigningInterface, DocumentSigningStatusEnum, DocumentSigningTransactionStateEnum } from './CurrentContractStore';

export interface AddoSigningStatusInterface {
    recipients: Array<{
        email: string,
        name: string,
        transactions: Array<{
            state: DocumentSigningTransactionStateEnum,
            transactionToken: string
        }>
    }>,
    state: DocumentSigningStatusEnum,

}

export class AddoSigningStatusStore extends BaseStore {
    @observable status: any = null;
    @observable signing: DocumentSigningInterface | null = null;

    constructor() {
        super();

        makeObservable(this);
    }

    @action
    resetStore() {
        this.status = null;
        this.signing = null;
    }

    @action
    getContractSigning(contractEndpoint: string, contractId: string, signingId: string) {
        return this.get(`contracts/${contractEndpoint}/${contractId}/signings/${signingId}`, 'signing');
    }

    @action
    getContractSigningStatus(contractEndpoint: string, contractId: string, signingId: string) {
        return this.get(`contracts/${contractEndpoint}/${contractId}/signings/${signingId}/addostatus`, 'status');
    }
}

export const addoSigningStatusStore = new AddoSigningStatusStore();