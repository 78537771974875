import * as React from 'react';
import { observer } from 'mobx-react';
import { languageStore, companyUserStore, CompanyUserInterface, userStore, navigationStore, authStore, toastStore } from 'stores';
import * as _ from 'lodash';
import ReactTable from 'react-table';
import TableHeader from 'components/Table/TableHeader';
import { formatDate } from 'helpers/DateHelper';
import CellButton from 'components/AdminPage/UsersPage/CellButton';
import AccessRightsModal from './AccessRightsModal';
import DeleteUserModal from './DeleteUserModal';
import LocationsModal from 'components/AdminPage/UsersPage/LocationsModal';
import DepartmentsModal from 'components/AdminPage/UsersPage/DepartmentsModal';
import { isValidEmailByString } from 'helpers/FormValidation';

interface Props {
    companyId: string
}

enum ActiveModalEnum {
    NONE,
    ACCESS_RIGHTS,
    DELETE_USER,
    LOCATIONS,
    DEPARTMENTS,
    REASSIGN
}

interface State {
    activeModal: ActiveModalEnum,
    activeUserId: string | null
}

@observer class Users extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);

        this.state = {
            activeUserId: null,
            activeModal: ActiveModalEnum.NONE
        }
    }

    componentDidMount() {
        const { companyId } = this.props;
        companyUserStore.getUsers(companyId);

        // Just in case we want to delete,
        // this is a hack to prevent getting users multiple times
        // for the dropdown in responsibleUserComponent
        userStore.getUsers(true);
    }

    getColumn(title: string, accessor: string | Function, id?: string) {
        let obj = {
            Header: <TableHeader value={title} />,
            accessor: accessor
        } as any;

        if (id) {
            obj.id = id;
        }

        return obj;
    }

    showCellModal = (modal: ActiveModalEnum, userId: string) => {
        this.setState({
            activeUserId: userId,
            activeModal: modal
        });
    }

    hideModal = () => {
        this.setState({
            activeModal: ActiveModalEnum.NONE,
            activeUserId: null
        })
    }

    gotoCreateUser() {
        const { companyId } = this.props;
        navigationStore.push(`/admin/users/create/${companyId}`);
    }

    render() {
        const { activeModal, activeUserId } = this.state;
        const users = _.orderBy(companyUserStore.users, 'fullName') || [];
        const activeUser = activeUserId ? _.find(users, { id: activeUserId }) || null : null;

        const columns = [
            {
                Header: <TableHeader value={languageStore.get('name')} />,
                id: 'fullName',
                accessor: (d: CompanyUserInterface) => {
                    return (
                        <span>
                            {d.fullName}
                            {
                                d.disabled &&
                                <span className='font-semibold inline text-red text-xs'><br />{languageStore.get('inactive')}</span>
                            }
                        </span>
                    )
                }
            },
            {
                Header: <TableHeader value={languageStore.get('email')} />,
                id: 'email',
                Cell: (props: any) => {
                    const user = props.original as CompanyUserInterface;

                    const updateUserEmail = async () => {
                        const newEmail = window.prompt(languageStore.get('email'), user.email) || '';
                        if (!newEmail) return;
                        if (newEmail === user.email) return;

                        if (isValidEmailByString(newEmail)) {
                            const orgEmail = user.email;
                            user.email = newEmail;

                            try {
                                await companyUserStore.updateUser(user);
                                toastStore.addDefaultSuccessfulSaveToast();
                            }
                            catch (error) {
                                user.email = orgEmail;
                            }
                        }
                        else {
                            toastStore.addError(languageStore.get('invalidEmail'));
                        }
                    }

                    return (
                        <button
                            className='underline'
                            type='button'
                            onClick={updateUserEmail}>
                            {user.email}
                        </button>
                    )
                }
            },
            this.getColumn(languageStore.get('lastLogin'), (d: CompanyUserInterface) => formatDate(d.lastLogIn, true), 'lastLogIn'),
            {
                Header: <TableHeader value={languageStore.get('action')} />,
                id: 'action',
                width: 600,
                Cell: (props: any) => {
                    const user = props.original as CompanyUserInterface;
                    const signedInUserId = userStore.signedInUser!.id;

                    const isMe = signedInUserId === user.id;

                    const showModal = (e: React.MouseEvent, modalEnum: ActiveModalEnum) => {
                        e.preventDefault();
                        this.showCellModal(modalEnum, user.id);
                    }

                    const onToggleActive = (e: React.MouseEvent) => {
                        e.preventDefault();

                        user.disabled = !user.disabled;
                        companyUserStore.updateUser(user);
                    }

                    const onDeleteUser = (e: React.MouseEvent) => {
                        e.preventDefault();
                        this.showCellModal(ActiveModalEnum.DELETE_USER, user.id);
                    }

                    const onLoginAsUser = async (e: React.MouseEvent) => {
                        e.preventDefault();

                        await authStore.loginAsUser(user.id);
                        navigationStore.push('/', () => {
                            window.location.reload();
                        })
                    }

                    return (
                        <div>
                            {
                                !isMe &&
                                <CellButton
                                    onClick={(e) => showModal(e, ActiveModalEnum.ACCESS_RIGHTS)}
                                    text={languageStore.get('accessRights')} />
                            }

                            <span className='ml-2' />
                            <CellButton
                                onClick={(e) => showModal(e, ActiveModalEnum.LOCATIONS)}
                                text={languageStore.get('locations')} />

                            <span className='ml-2' />
                            <CellButton
                                onClick={(e) => showModal(e, ActiveModalEnum.DEPARTMENTS)}
                                text={languageStore.get('departments')} />

                            <span className='ml-2' />
                            <CellButton
                                onClick={(e) => showModal(e, ActiveModalEnum.REASSIGN)}
                                text={languageStore.get('reassign')} />

                            <span className='ml-2' />
                            <CellButton
                                onClick={(e) => navigationStore.push(`/superadmin/activities/users/${user.id}`)}
                                text={languageStore.get('log')} />


                            {
                                !isMe &&
                                <>
                                    <span className='ml-2' />
                                    <CellButton
                                        onClick={(e) => onDeleteUser(e)}
                                        text={languageStore.get('delete')} />
                                    <span className='ml-2' />
                                    <CellButton
                                        className={user.disabled ? 'bg-red-lightest' : 'bg-green-lightest'}
                                        onClick={(e) => onToggleActive(e)}
                                        text={user.disabled ? languageStore.get('inactive') : languageStore.get('active')} />


                                    {
                                        user.allowSuperAdminLogin &&
                                        <>
                                            <span className='ml-2' />
                                            <CellButton
                                                onClick={(e) => onLoginAsUser(e)}
                                                text={languageStore.get('signIn')} />
                                        </>
                                    }
                                </>
                            }
                        </div>

                    )
                }
            },
        ]

        return (
            <div className='mt-10 mx-auto'>
                <div className='mb-8'>
                    <h2 className='inline'>
                        {languageStore.get('users')}
                    </h2>
                    <div className='float-right'>
                        <button onClick={() => this.gotoCreateUser()} className='bg-comablue-label text-white py-2 px-8 rounded'>
                            {languageStore.get('addUser')}
                        </button>
                    </div>
                </div>

                <div className='border rounded p-5'>
                    <ReactTable
                        data={users.slice()}
                        columns={columns}
                        className='ReactTable-readonly'
                        defaultPageSize={99999}
                        showPagination={false}
                        NoDataComponent={() => null}
                        minRows={0} />
                </div>

                {
                    activeModal === ActiveModalEnum.ACCESS_RIGHTS &&
                    <AccessRightsModal user={activeUser} onClose={this.hideModal} />
                }

                {
                    activeModal === ActiveModalEnum.REASSIGN &&
                    <DeleteUserModal reassignOnly={true} user={activeUser!} onClose={this.hideModal} />
                }

                {
                    activeModal === ActiveModalEnum.DELETE_USER && activeUser &&
                    <DeleteUserModal user={activeUser} onClose={this.hideModal} />
                }

                {
                    (activeModal === ActiveModalEnum.LOCATIONS && activeUser) &&
                    <LocationsModal user={activeUser} onClose={this.hideModal} />
                }

                {
                    (activeModal === ActiveModalEnum.DEPARTMENTS && activeUser) &&
                    <DepartmentsModal user={activeUser} onClose={this.hideModal} />
                }

            </div>
        )
    }

}

export default Users;