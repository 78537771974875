import Form from 'components/Forms/Form';
import Input from 'components/Forms/Input';
import { FieldState, FormState } from 'formstate';
import { categoryContractTypeToAdminRootEndpoint } from 'helpers/CategoryHelper';
import { required } from 'helpers/FormValidation';
import { categoryStore, DocumentTypeContractTypeEnum, languageStore, navigationStore, toastStore } from 'stores';
import useCategoryByParams from '../useCategoryByParams';

const General = () => {
    const category = useCategoryByParams();
    if (!category) return null;

    const form = new FormState({
        name: new FieldState(category.name).validators(required)
    })

    const updateCategory = async () => {
        category.name = form.$.name.$;

        await categoryStore.updateCategory(category);

        toastStore.addToast({
            level: 'success',
            title: languageStore.get('success'),
            message: languageStore.get('changesSaved')
        })
    }

    const deleteCategory = async () => {
        if (!window.confirm(`${languageStore.get('areYouSureYouWantToDelete')}?`)) return;

        await categoryStore.deleteCategory(category.locationId!, category.id);

        navigationStore.push(categoryContractTypeToAdminRootEndpoint(category.contractType as DocumentTypeContractTypeEnum));
    }

    return (
        <div className='mt-4'>
            <Form formState={form} onValidSubmit={updateCategory}>
                <div className='w-64'>
                    <Input
                        fieldState={form.$.name}
                        id='name'
                        label={languageStore.get('name')}
                    />
                </div>

                <button
                    className='bg-comablue text-white px-8 py-2 rounded'>
                    {languageStore.get('saveChanges')}
                </button>
                <button
                    className='ml-4 bg-red-dark text-white px-4 py-2 rounded inline'
                    type='button'
                    onClick={() => deleteCategory()}>
                    {languageStore.get('delete')}
                </button>
            </Form>
        </div>
    )

}

export default General;