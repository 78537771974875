import * as React from 'react';
import { observer } from 'mobx-react';
import { DashboardContractTypeEnum, dashboardStore, languageStore, currencyStore } from 'stores';
import { Bar } from 'react-chartjs-2';
import { monthToShortname } from 'helpers/DateHelper';
import * as _ from 'lodash';
import Moment from 'moment-timezone';
import ForecastModal from './ForecastModal';

interface Props {
    contractType: DashboardContractTypeEnum
}

interface State {
    year: number,
    modalVisible: boolean,
    selectedMonth: number | null,
    selectedCurrencyIndex: number | null
}

@observer class ForecastWidget extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            year: Moment().year(),
            modalVisible: false,
            selectedMonth: null,
            selectedCurrencyIndex: null
        }
    }

    componentDidMount() {
        const { contractType } = this.props;
        this.getData(contractType);
        currencyStore.getCurrencies();
    }

    async getData(contractType: DashboardContractTypeEnum, detailed: boolean = false) {
        const { year } = this.state;
        await dashboardStore.getForecasts(contractType, year, detailed);
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        if (nextProps.contractType !== this.props.contractType) {
            this.getData(nextProps.contractType);
        }
    }

    addYear = () => {
        const { contractType } = this.props;

        this.setState({ year: this.state.year + 1 }, () => {
            this.getData(contractType);
        });

    }

    subtractYear = () => {
        const { contractType } = this.props;

        this.setState({ year: this.state.year - 1 }, () => {
            this.getData(contractType);
        });
    }

    getBackgroundColor(index: number) {
        const colors = [
            '#00105c', '#002d77', '#00488f', '#0063a4', '#007fb7', '#1e9ac8', '#4cb6d8', '#74d2e7'
        ]

        return colors[index] ? colors[index] : _.sample(colors);
    }

    onElementClicked = async (e: any) => {
        if (!e[0]) return;

        this.setState({
            selectedMonth: e[0]._index + 1,
            selectedCurrencyIndex: e[0]._datasetIndex,
            modalVisible: true
        })
    }

    closeModal = () => {
        this.setState({
            modalVisible: false,
            selectedCurrencyIndex: null,
            selectedMonth: null
        })
    }

    render() {
        const { year, modalVisible, selectedMonth, selectedCurrencyIndex } = this.state;
        const { contractType } = this.props;

        const forecast = dashboardStore.forecast;
        if (!forecast) return null;

        let labels = [];
        let datasets = [] as Array<any>;

        Object.keys(forecast.contractSumSumsByCurrencyId).forEach((currencyId: string, index: number) => {
            const currency = _.find(currencyStore.currencies, { id: currencyId });
            if (!currency) return;

            let dataset = {
                label: currency.code,
                data: [] as Array<number>
            }

            // Go through each potential month.
            // If set in object, great. Use sum - If not add sum = 0 to dataset for current month
            for (let i: number = 1; i <= 12; i++) {
                const entity = forecast.contractSumSumsByCurrencyId[currencyId][i];
                if (entity) {
                    const sum = Math.round(entity.contractSumSum);
                    dataset.data.push(sum);
                }
                else {
                    dataset.data.push(0);
                }

            }

            datasets.push(Object.assign({ backgroundColor: this.getBackgroundColor(index) }, dataset));
        })

        for (let i: number = 1; i <= 12; i++) {
            labels.push(monthToShortname(i));
        }

        return (
            <>
                <div className='mt-4 mx-4 border p-4 rounded'>
                    <p className='font-semibold mb-6'>
                        {languageStore.get('servicesForecast')}
                        <span style={{ float: 'right' }}>
                            <span onClick={this.subtractYear} className='pr-2 cursor-pointer'>-</span>
                            {year}
                            <span onClick={this.addYear} className='pl-2 cursor-pointer'>+</span>
                        </span>
                    </p>
                    <Bar
                        onElementsClick={this.onElementClicked}
                        data={{
                            labels: labels,
                            datasets: datasets
                        }}
                        height={150}
                        // legend={{ display: false }}
                        options={{
                            hover: {
                                onHover: function (e: any) {
                                    var point = this.getElementAtEvent(e);
                                    if (point.length) e.target.style.cursor = 'pointer';
                                    else e.target.style.cursor = 'default';
                                }
                            },
                            maintainAspectRatio: true,
                            legend: { position: 'bottom', labels: { usePointStyle: true, fontSize: 12 } }
                        }}

                    />
                </div>
                {
                    modalVisible &&
                    <ForecastModal
                        onClose={this.closeModal}
                        contractType={contractType}
                        year={year}
                        month={selectedMonth!}
                        currencyIndex={selectedCurrencyIndex!}
                    />
                }
            </>
        )
    }

}

export default ForecastWidget;