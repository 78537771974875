import * as React from 'react';
import { observer } from 'mobx-react';
import { FieldState } from 'formstate';
import ContractDropdownItem from 'components/ContractDropdownItem';
import { DropdownOptionInterface } from 'components/Forms/Dropdown';
import * as _ from 'lodash';
import {
    userStore,
    languageStore as lanStore,
    toastStore,
    languageStore,
    companyUserStore
} from 'stores';

interface Props {
    fieldState: FieldState<any>,
    fallbackValue?: string,
    editMode?: boolean,
    showAlarmWarning?: boolean,
    label?: string,
    force?: boolean,
    departmentIds?: Array<string>,
    skipUserId?: string,
    companyId?: string
}

@observer class ResponsibleUser extends React.Component<Props> {

    componentDidMount() {
        const { force, companyId } = this.props;
        const shouldForce = force !== undefined ? force : false;
        if (companyId) {
            companyUserStore.getUsers(companyId, shouldForce);
        }
        else {
            userStore.getUsers(shouldForce);
        }

    }

    showAlarmWarning() {
        const { fieldState } = this.props;

        // We only want to show the alarm warning,
        // if a current responsible user is begin changed,
        // and only the first time.
        if (fieldState.value && !fieldState.dirty) {
            toastStore.addToast({
                level: 'warning',
                title: languageStore.get('warning'),
                message: languageStore.get('rememberToUpdateResponsibleUserForAlarms')
            })
        }
    }

    render() {
        const { fieldState, departmentIds, companyId, skipUserId, label, fallbackValue, editMode, showAlarmWarning } = this.props;

        const availUsers: Array<DropdownOptionInterface> = [];

        const users = companyId ? companyUserStore.users as any : userStore.users as any;

        users && users.forEach((user: any) => {
            if (skipUserId && skipUserId === user.id) {
                return;
            }

            let shouldAdd = true;

            if (departmentIds) {
                if (!user.departments || user.departments.length === 0) {
                    shouldAdd = false;
                }
                else {
                    if (_.intersection(user.departments.map((dep: any) => dep.id), departmentIds).length > 0) {
                        shouldAdd = true;
                    }
                    else {
                        shouldAdd = false;
                    }

                }
            }

            if (shouldAdd === true) {
                availUsers.push({
                    value: user.id,
                    name: user.fullName
                })
            }
        })

        return (
            <ContractDropdownItem
                label={label ? label : lanStore.get('responsible')}
                id='responsible'
                fieldState={fieldState}
                placeholderText={lanStore.get('selectResponsible')}
                options={availUsers}
                editMode={editMode}
                beforeChange={showAlarmWarning ? () => this.showAlarmWarning() : undefined}
                fallbackValue={fallbackValue} />
        )
    }
}

export default ResponsibleUser;