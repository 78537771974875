import * as React from 'react';
import { observer } from 'mobx-react';
import { FieldState } from 'formstate';
import ContractDropdownItem from 'components/ContractDropdownItem';
import { languageStore, AppraisalInterviewStatusEnum } from 'stores';
import { DropdownOptionInterface } from 'components/Forms/Dropdown';

interface Props {
    fieldState: FieldState<any>,
    editMode?: boolean,
}

@observer class SelectStatus extends React.Component<Props> {

    render() {
        const { fieldState, editMode } = this.props;

        const dropdownOpts = [
            {
                value: AppraisalInterviewStatusEnum.SCHEDULED,
                name: languageStore.get('scheduled')
            },
            {
                value: AppraisalInterviewStatusEnum.HELD,
                name: languageStore.get('held')
            },
            {
                value: AppraisalInterviewStatusEnum.DELETED,
                name: languageStore.get('deleted')
            },
        ] as Array<DropdownOptionInterface>

        return (
            <ContractDropdownItem
                label={languageStore.get('status')}
                id='appraisalInterviewStatus'
                placeholderText={languageStore.get('select')}
                fieldState={fieldState}
                options={dropdownOpts}
                skipSort={true}
                type='number'
                editMode={editMode} />
        )
    }

}

export default SelectStatus;