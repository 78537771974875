import * as React from 'react';
import { observer } from 'mobx-react';
import ContractsPage, { ContractPageGroupInterface } from 'components/ContractsPage';
import { languageStore, ViewByEnum, salesContractsStore } from 'stores';
import { ContractStatusHelper } from 'helpers/ContractStatusHelper';

@observer class SalesContractsPage extends React.Component {

    render() {

        const groups = [
            {
                title: languageStore.get('category'),
                enum: ViewByEnum.CATEGORY
            },
            {
                title: languageStore.get('customer'),
                enum: ViewByEnum.CUSTOMER
            },
            {
                title: languageStore.get('location'),
                enum: ViewByEnum.LOCATION
            },
            {
                title: languageStore.get('department'),
                enum: ViewByEnum.DEPARTMENT
            },
            {
                title: languageStore.get('responsible'),
                enum: ViewByEnum.RESPONSIBLE
            },
            {
                title: languageStore.get('expiration'),
                enum: ViewByEnum.EXPIRATION_DATE,
                defaultSortingId: 'expirationDate'
            }
        ] as Array<ContractPageGroupInterface>

        const statuses = ContractStatusHelper.BasicContractStatus().map((status) => ({
            title: status.name,
            enum: status.value
        }))

        return (
            <ContractsPage
                store={salesContractsStore}
                groups={groups}
                statuses={statuses}
            />
        )

    }

}

export default SalesContractsPage;