import * as React from 'react';
import { observer } from 'mobx-react';
import MenuItem from 'components/Header/MenuItem';
import Button from 'components/Header/Button';
import Splitter from 'components/Header/Splitter';
import { HeaderEnum, navigationStore } from 'stores';
import LogoImg from './logoColorWhiteText.svg';
import { languageStore } from 'stores';
import NewContractButton from './NewContractButton';
import Notifications from './Notifications';
import Profile from './Profile';
import { ModuleEnum, canAccess } from 'helpers/ModuleHelper';
import PrintButton from './PrintButton';
import InboxHeaderButton from './InboxHeaderButton';

@observer class Header extends React.Component {

    componentDidMount() {
        navigationStore.setActiveHeaderByCurrentBrowserLocation();
    }

    menuItemClicked(id: HeaderEnum) {
        navigationStore.setActiveHeader(id);

        switch (id) {
            case HeaderEnum.Dashboard:
                navigationStore.push('/');
                break;
            case HeaderEnum.Purchase:
                navigationStore.push('/purchase');
                break;
            case HeaderEnum.Staff:
                navigationStore.push('/staff');
                break;
            case HeaderEnum.Service:
                navigationStore.push('/service');
                break;
            case HeaderEnum.Sales:
                navigationStore.push('/sales');
                break;
            case HeaderEnum.Reports:
                navigationStore.push('/reports');
                break;
            case HeaderEnum.Inbox:
                navigationStore.push('/inbox');
                break;
        }
    }

    render() {
        return (
            <nav className="flex items-center justify-between p-2 px-4" style={{ background: '#00105c' }}>
                <div className="inline">
                    <div className="flex items-center flex-no-shrink text-white mr-6">
                        <img src={LogoImg} className="mr-2" alt="COMA System" height="24" width="142" />
                        <Splitter className="ml-3" />
                    </div>
                </div>

                <div className="flex-grow flex items-center w-auto">
                    <div className="text-sm flex-grow">
                        <MenuItem onClick={this.menuItemClicked} id={HeaderEnum.Dashboard} text={languageStore.get('dashboard')} />

                        {
                            canAccess(ModuleEnum.PURCHASE) &&
                            <MenuItem onClick={this.menuItemClicked} id={HeaderEnum.Purchase} text={languageStore.get('purchaseContract')} />
                        }

                        {
                            canAccess(ModuleEnum.STAFF) &&
                            <MenuItem onClick={this.menuItemClicked} id={HeaderEnum.Staff} text={languageStore.get('staffContract')} />
                        }

                        {
                            canAccess(ModuleEnum.SERVICE) &&
                            <MenuItem onClick={this.menuItemClicked} id={HeaderEnum.Service} text={languageStore.get('serviceContract')} />
                        }

                        {
                            canAccess(ModuleEnum.SALES) &&
                            <MenuItem onClick={this.menuItemClicked} id={HeaderEnum.Sales} text={languageStore.get('salesContract')} />
                        }

                    </div>

                    <PrintButton />

                    <InboxHeaderButton onClick={() => this.menuItemClicked(HeaderEnum.Inbox)} />

                    <NewContractButton />
                    <Button active={navigationStore.activeHeader === HeaderEnum.Reports} text={languageStore.get('reports')} onClick={() => this.menuItemClicked(HeaderEnum.Reports)} />

                    <Splitter className="mx-8 visible" />

                    {/* <img src={PrintImg} width="18" height="18" className="mr-4 cursor-pointer" /> */}
                    <Notifications />
                    <Profile />
                </div>
            </nav>
        );
    }

}

export default Header;