import * as React from 'react';
import { observer } from 'mobx-react';
import { Modal, ModalColumn } from 'components/Modal';
import { languageStore, CompanyInterface, superAdminStore } from 'stores';
import { FormState, FieldState } from 'formstate';
import { required } from 'helpers/FormValidation';
import Input from 'components/Forms/Input';
import SelectTrueFalse from 'components/Forms/SelectTrueFalse';
import Form from 'components/Forms/Form';
import Flatpickr from 'react-flatpickr';
import Moment from 'moment';

interface Props {
    company: CompanyInterface
}

interface State {
    showModal: boolean
}

@observer class EditCompanyModal extends React.Component<Props, State> {

    form = new FormState({
        name: new FieldState(this.props.company.name).validators(required),
        deleteDateReminder: new FieldState<any>(this.props.company.deleteDateReminder),
        hasPurchaseModul: new FieldState(this.props.company.hasPurchaseModul),
        hasStaffModul: new FieldState(this.props.company.hasStaffModul),
        hasSalesModul: new FieldState(this.props.company.hasSalesModul),
        hasServiceModul: new FieldState(this.props.company.hasServiceModul),
        hasAssetManagementModul: new FieldState(this.props.company.hasAssetManagementModul),
        allowAddo: new FieldState(this.props.company.allowAddo),
        allowCustomFields: new FieldState(this.props.company.allowCustomFields),
        allowTemplates: new FieldState(this.props.company.allowTemplates),
        twoFactorAuthRequired: new FieldState(this.props.company.twoFactorAuthRequired),
        signedDocumentsAreDeletable: new FieldState(this.props.company.signedDocumentsAreDeletable),
        numberOfDaysBeforeLogDelete: new FieldState(this.props.company.numberOfDaysBeforeLogDelete),
    })

    constructor(props: Props) {
        super(props);

        this.state = {
            showModal: false
        }
    }

    showModal = () => {
        this.setState({ showModal: true })
    }

    hideModal = () => {
        this.setState({ showModal: false })
    }

    onSubmit = async () => {
        const { company } = this.props;
        const form = this.form.$;

        company.name = form.name.$;
        company.hasPurchaseModul = form.hasPurchaseModul.$;
        company.hasSalesModul = form.hasSalesModul.$;
        company.hasServiceModul = form.hasServiceModul.$;
        company.hasStaffModul = form.hasStaffModul.$;
        company.hasAssetManagementModul = form.hasAssetManagementModul.$;
        company.allowAddo = form.allowAddo.$;
        company.allowCustomFields = form.allowCustomFields.$;
        company.allowTemplates = form.allowTemplates.$;
        company.deleteDateReminder = form.deleteDateReminder.$;
        company.twoFactorAuthRequired = form.twoFactorAuthRequired.$;
        company.signedDocumentsAreDeletable = form.signedDocumentsAreDeletable.$;
        company.numberOfDaysBeforeLogDelete = form.numberOfDaysBeforeLogDelete.$;

        await superAdminStore.updateCompany(company);
        this.hideModal();
    }

    render() {
        const { showModal } = this.state;
        const form = this.form.$;

        return (
            <span>
                {
                    !showModal &&
                    <button onClick={this.showModal} className='hover:bg-grey-lightest mt-4 px-4 py-2 border rounded'>{languageStore.get('editCompany')}</button>
                }

                {
                    showModal &&
                    <Form onValidSubmit={this.onSubmit} formState={this.form}>
                        <Modal
                            title={languageStore.get('editCompany')}
                            primaryButton={{ title: languageStore.get('saveChanges') }}
                            secondaryButton={{ title: languageStore.get('cancel'), onClick: this.hideModal }}>

                            <ModalColumn>

                                <Input fieldState={form.name} id='email' label={languageStore.get('companyName')} />

                                <div className={`relative border py-2 px-2 rounded mb-6 mt-8`} style={{ height: '41px' }}>
                                    <Flatpickr
                                        options={{
                                            dateFormat: 'd/m/Y', allowInput: true, onClose: (selectedDates, dateStr, instance) => {
                                                if (!dateStr) {
                                                    form.deleteDateReminder.onChange(null);
                                                    return;
                                                }

                                                const newDate = Moment(dateStr, 'DD/MM/YYYY');
                                                const currentDate = Moment(form.deleteDateReminder.value!, 'DD/MM/YYYY')

                                                if (!newDate.isSame(currentDate)) {
                                                    form.deleteDateReminder.onChange([newDate.toDate()][0]);
                                                }
                                            }
                                        }}
                                        className='w-full bg-transparent text-green-darker leading-normal rounded'
                                        value={form.deleteDateReminder.value ? Moment.utc(form.deleteDateReminder.value).toISOString() : undefined} />
                                    <label className="absolute block text-comablue-label pin-t leading-normal cursor-pointer Dropdown-label-floating">
                                        {languageStore.get('deleteReminder')}
                                    </label>
                                </div>

                                <Input
                                    fieldState={form.numberOfDaysBeforeLogDelete}
                                    id='numberOfDaysBeforeLogDelete'
                                    label={languageStore.get('numberOfDaysBeforeLogDelete')}
                                />

                                <h3 className='mt-8'>General</h3>
                                <SelectTrueFalse
                                    marginTop='10px'
                                    marginBottom='0px'
                                    id='twoFactorAuthentication'
                                    label={languageStore.get('twoFactorAuthentication')}
                                    fieldState={form.twoFactorAuthRequired}
                                    editMode={true} />

                                <SelectTrueFalse
                                    marginTop='-5px'
                                    marginBottom='25px'
                                    id='signedDocumentsAreDeletable'
                                    label={languageStore.get('signedDocumentsAreDeletable')}
                                    fieldState={form.signedDocumentsAreDeletable}
                                    editMode={true} />

                                <h3 className='mt-8'>Modules</h3>
                                <SelectTrueFalse
                                    marginTop='10px'
                                    marginBottom='0px'
                                    id='purchase'
                                    label={languageStore.get('purchase')}
                                    fieldState={form.hasPurchaseModul}
                                    editMode={true} />
                                <SelectTrueFalse
                                    marginTop='0px'
                                    marginBottom='0px'
                                    id='staff'
                                    label={languageStore.get('staff')}
                                    fieldState={form.hasStaffModul}
                                    editMode={true} />
                                <SelectTrueFalse
                                    marginTop='0px'
                                    marginBottom='0px'
                                    id='service'
                                    label={languageStore.get('service')}
                                    fieldState={form.hasServiceModul}
                                    editMode={true} />
                                <SelectTrueFalse
                                    marginTop='0px'
                                    marginBottom='0px'
                                    id='sales'
                                    label={languageStore.get('sales')}
                                    fieldState={form.hasSalesModul}
                                    editMode={true} />
                                <SelectTrueFalse 
                                    marginTop='0px'
                                    marginBottom='0px'
                                    id='assetManagement'
                                    label={languageStore.get('asset management')}
                                    fieldState={form.hasAssetManagementModul}
                                    editMode={true} />

                                <h3 className='mt-8'>Extensions</h3>

                                <SelectTrueFalse
                                    marginTop='0px'
                                    marginBottom='0px'
                                    id='addo'
                                    label={languageStore.get('addo')}
                                    fieldState={form.allowAddo}
                                    editMode={true} />

                                <SelectTrueFalse
                                    marginTop='0px'
                                    marginBottom='0px'
                                    id='customFields'
                                    label={languageStore.get('customFields')}
                                    fieldState={form.allowCustomFields}
                                    editMode={true} />

                                <SelectTrueFalse
                                    marginTop='0px'
                                    marginBottom='0px'
                                    id='templates'
                                    label={languageStore.get('templates')}
                                    fieldState={form.allowTemplates}
                                    editMode={true} />

                            </ModalColumn>
                        </Modal>
                    </Form>

                }
            </span>

        )


    }

}

export default EditCompanyModal;