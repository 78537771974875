import * as React from 'react';
import { observer } from 'mobx-react';
import './style.css';
import { HeaderEnum, navigationStore } from 'stores';

interface MenuItemProps {
    /** Label */
    text: string,
    id: HeaderEnum,
    onClick: Function
}

/** MenuItems are links used in Header */
@observer class MenuItem extends React.Component<MenuItemProps, any> {

    itemClicked(e: React.MouseEvent) {
        e.preventDefault();

        const { onClick, id } = this.props;
        onClick(id);
    }

    render() {
        const { text, id } = this.props;

        let className: string = `
            MenuItem
            p-2
            mr-0
            lg:mr-4
            rounded
            no-underline
            tracking-wide
            inline-block
            mt-0
            text-white
            hover:p-2
            hover:rounded
            capitalize
            cursor-pointer`;

        if (navigationStore.activeHeader === id) {
            className += `
                rounded
                MenuItem-active-background
                text-black
            `;
        }

        return (
            <a href=' ' onClick={(e) => this.itemClicked(e)} className={className}>
                <div className="opacity-100" />
                {text}
            </a>
        )
    }
}

export default MenuItem;