import * as React from 'react';
import { observer } from 'mobx-react';
import { DashboardContractTypeEnum, purchaseContractsStore, salesContractsStore, staffContractsStore, serviceContractsStore, navigationStore, OverviewTypeEnum, ViewByEnum } from 'stores';
import Moment from 'moment-timezone';
import { monthToShortname, formattedTimeZoneForElasticSearch } from 'helpers/DateHelper';

interface Props {
    month: number,
    count: number,
    contractType: DashboardContractTypeEnum,
    activeTab: OverviewTypeEnum
}

@observer class Month extends React.Component<Props> {

    fieldToSearch = () => {
        const { activeTab } = this.props;
        
        let fieldToSearch = '';

        if (activeTab === OverviewTypeEnum.RENEWAL) {
            fieldToSearch = 'renewalDate';
        }
        else if (activeTab === OverviewTypeEnum.EXPIRY) {
            fieldToSearch = 'expirationDate';
        }
        else if (activeTab === OverviewTypeEnum.RENEGOTIATION) {
            fieldToSearch = 'renegotiationDate';
        }
        else if (activeTab === OverviewTypeEnum.SERVICE) {
            fieldToSearch = 'serviceDate';
        }

        return fieldToSearch;
    }

    currentYear = () => {
        const { month } = this.props;

        const now = Moment();
        let thisYear = now.year();
        const thisMonth = now.month() + 1;

        if (month < thisMonth) {
            thisYear++;
        }

        return thisYear;
    }

    formatMonth(month: number) {
        let formattedMonth = '' + month;
        if (month < 10) {
            formattedMonth = '0' + month
        }
        return formattedMonth;
    }

    onClick = () => {
        const { month, contractType } = this.props;

        const fieldToSearch = this.fieldToSearch();
        const thisYear = this.currentYear();

        const from = `${thisYear}-${this.formatMonth(month)}-01`;
        const to = `${month === 12 ? thisYear + 1 : thisYear}-${month === 12 ? this.formatMonth(1) : this.formatMonth(month + 1)}-01`;
        const timeZoneFrom = formattedTimeZoneForElasticSearch(Moment(from, 'YYYY-MM-DD').toISOString());
        const query = `{"range":{"${fieldToSearch}":{"time_zone":"${timeZoneFrom}","gte":"${from}","lt":"${to}","format":"yyyy-MM-dd"}}}`;

        switch (contractType) {
            case DashboardContractTypeEnum.PURCHASE:
                purchaseContractsStore.setSearchQuery(query, true);
                purchaseContractsStore.setViewBy(ViewByEnum.DEPARTMENT); // Show default by department, to have the supplier column visible. Default is category.
                navigationStore.push('/purchase');
                break;

            case DashboardContractTypeEnum.SALES:
                salesContractsStore.setSearchQuery(query, true);
                salesContractsStore.setViewBy(ViewByEnum.DEPARTMENT); // Show default by department, to have the supplier column visible. Default is category.
                navigationStore.push('/sales');
                break;

            case DashboardContractTypeEnum.SERVICE:
                serviceContractsStore.setSearchQuery(query, true);
                navigationStore.push('/service');
                break;

            case DashboardContractTypeEnum.STAFF:
                staffContractsStore.setSearchQuery(query, true);
                navigationStore.push('/staff');
                break;

            default: break;
        }
    }

    render() {
        const { count, month } = this.props;

        let activeCountStyle = {
            background: '#e4002b',
            borderRadius: '50%',
            color: 'white',
            padding: '5px 10px',
            cursor: 'pointer'
        }

        // To keep the highlighted bubble round, and not becoming oval when double digits oval
        if (count > 9) {
            activeCountStyle.padding = '5px 7px';
        }

        const inactiveCountStyle = {
            padding: '5px 6px'
        }

        return (
            <td className="w-1/6 text-center" onClick={count > 0 ? this.onClick : undefined}>
                <p className="text-grey-dark">
                    {monthToShortname(month)}
                </p>
                <p className="text-sm mt-1 inline-block" style={count > 0 ? activeCountStyle : inactiveCountStyle}>
                    {count < 99 ? count : 99}
                </p>
            </td>
        )
    }

}

export default Month;