import React from 'react';
import { observer } from 'mobx-react';
import { DashboardContractTypeEnum } from 'stores';
import { Doughnut } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import Chart from 'chart.js';
import './style.css';

Chart.plugins.unregister(ChartDataLabels);

Chart.pluginService.register({
    beforeDraw: function (chart: any) {
        var width = chart.chart.width,
            height = chart.chart.height,
            ctx = chart.chart.ctx;

        ctx.restore();
        var fontSize = (height / 114).toFixed(2);
        ctx.font = fontSize + "em sans-serif";
        ctx.textBaseline = "middle";
        ctx.fillStyle = '#BBC6DB';

        var text = chart.data.centerLabel,
            textX = Math.round((width - ctx.measureText(text).width) / 2),
            textY = height / 2;

        if (text) {
            ctx.fillText(text, textX, textY);
            ctx.save();
        }
    }
});

interface Props {
    contractType: DashboardContractTypeEnum,
    labels: Array<string>,
    inlineLabels: Array<string>,
    data: Array<number>,
    title: string | React.ReactNode,
    maxItems?: number,
    centerLabel?: string
}

interface State {
    legend: Array<any>
}

@observer class DoughnutWidget extends React.Component<Props, State> {

    state = { legend: [] }

    doughnut: React.RefObject<any> = React.createRef();

    componentDidMount() {
        this.updateLegend();
    }

    updateLegend() {
        const component = this.doughnut;
        this.setState({ legend: component.current.chartInstance.legend.legendItems });
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        if (prevProps.data !== this.props.data) {
            this.updateLegend();
        }
    }

    render() {
        const { legend } = this.state;
        const { labels, inlineLabels, data, title, maxItems, centerLabel } = this.props;

        let legendItems = legend;
        let totalItemsCount = legend.length;
        if (maxItems && totalItemsCount > maxItems) {
            legendItems = legendItems.slice(0, maxItems);
        }

        return (
            <div className='mx-3 p-4 border rounded' style={{ height: '100%' }}>
                <p className='font-semibold mb-6'>
                    {title}
                </p>
                <Doughnut
                    ref={this.doughnut}
                    height={300}
                    plugins={[ChartDataLabels]}
                    options={{
                        plugins: {
                            datalabels: {
                                display: true,
                                color: 'white',
                                formatter: (value, ctx) => {
                                    return inlineLabels[ctx.dataIndex] || '';
                                }
                            }
                        },
                        layout: {
                            padding: {
                                left: 0,
                                right: 0,
                                top: 0,
                                bottom: 0
                            }
                        },
                        // tooltips: { enabled: false },
                        maintainAspectRatio: true,
                        responsive: true,
                        legend: { display: false }
                        // legend: { position: 'bottom', labels: { usePointStyle: true, fontSize: 12 } }
                    }}
                    data={{
                        centerLabel: centerLabel,
                        labels: labels,
                        datasets: [{
                            dataLabels: inlineLabels,
                            data: data,
                            backgroundColor: ['#00105c', '#002d77', '#00488f', '#0063a4', '#007fb7', '#1e9ac8', '#4cb6d8', '#74d2e7']
                        }],
                    } as any} />

                {
                    (maxItems && legend.length > maxItems) &&
                    <p style={{ marginBottom: '-20px' }} className='text-sm'>Top {maxItems}:</p>
                }

                {
                    data.length > 0 &&
                    <ul className='mt-8 Chartjs-legend-wrapper'>
                        {legend.length &&
                            legendItems.map((item: any) => {
                                return (
                                    <li key={item.text} className='Chartjs-legend'>
                                        <div
                                            className='Chartjs-bullet'
                                            style={{ backgroundColor: item.fillStyle }}
                                        />
                                        <p className='text-sm'>
                                            {item.text}
                                        </p>
                                    </li>
                                );
                            })}
                    </ul>
                }

            </div>
        )
    }
}

export default DoughnutWidget;