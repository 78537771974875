import * as React from 'react';
import { observer } from 'mobx-react';
// import * as ReactDOM from 'react-dom';
import './style.css';

// const printRoot = document.getElementById('print');

interface Props {
    children: React.ReactNode,
    compact?: boolean
}

@observer class ShowOnPrint extends React.Component<Props> {

    render() {
        const { children, compact } = this.props;

        return (
            <span className={`printable ${compact ? '' : 'w-full' }`}>
                {children}
            </span>
        )

        // return ReactDOM.createPortal(
        //     children,
        //     this.el
        // )
    }

}

export default ShowOnPrint;