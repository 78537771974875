import Form from "components/Forms/Form";
import Input from "components/Forms/Input";
import { Modal, ModalColumn } from "components/Modal";
import DeleteButton from "components/Modal/DeleteButton";
import { FieldState, FormState } from "formstate";
import { required } from "helpers/FormValidation";
import { DepartmentInterface, departmentStore, languageStore } from "stores";

interface Props {
    department: DepartmentInterface,
    onClose: () => void
}

const UpdateDepartmentModal = (props: Props) => {

    const form = new FormState({
        name: new FieldState(props.department.name).validators(required)
    })

    const updateDepartment = async () => {
        const department = props.department;
        department.name = form.$.name.$;

        await departmentStore.updateDepartment(department, departmentStore.departments);

        props.onClose();
    }

    const deleteDepartment = async () => {
        if (!window.confirm(`${languageStore.get('areYouSureYouWantToDelete')}?`)) return;

        await departmentStore.deleteDepartment(props.department.locationId, props.department.id, departmentStore.departments);

        props.onClose();
    }

    return (
        <Form formState={form} onValidSubmit={updateDepartment}>
            <Modal
                title={languageStore.get('department')}
                primaryButton={{ title: languageStore.get('saveChanges') }}
                secondaryButton={{ title: languageStore.get('cancel'), onClick: props.onClose }}>

                <ModalColumn>
                    <Input
                        id='name'
                        label={languageStore.get('name')}
                        fieldState={form.$.name}
                        type='text' />
                </ModalColumn>

                <DeleteButton onDelete={deleteDepartment} />
            </Modal>
        </Form>
    )

}

export default UpdateDepartmentModal;