import * as React from 'react';
import { observer } from 'mobx-react';
import { templateStore, DashboardContractTypeEnum, languageStore, TemplateInterface, ContractDocumentInterface, documentStore, DocumentUrlInterface, userStore, departmentStore, DepartmentInterface, locationStore, LocationInterface, navigationStore } from 'stores';
import TableHeader from 'components/Table/TableHeader';
import DetailsPage from '../DetailsPage';
import { ModalColumn } from 'components/Modal';
import { FormState, FieldState } from 'formstate';
import UploadFile from 'components/Forms/UploadFile';
import Input from 'components/Forms/Input';
import { required } from 'helpers/FormValidation';
import _ from 'lodash';

interface Props {
    contractType: DashboardContractTypeEnum
}

interface State {
    uploadedFile: ContractDocumentInterface | null,
    addedDepartments: Array<DepartmentInterface>
}

@observer class TemplatesPage extends React.Component<Props, State> {

    form = new FormState({
        id: new FieldState<string | null>(null),
        name: new FieldState('').validators(required),
        documentId: new FieldState<string | null>(null)
    })

    constructor(props: Props) {
        super(props);

        this.state = {
            uploadedFile: null,
            addedDepartments: []
        }
    }

    deleteFromDepartment = (e: React.MouseEvent, department: DepartmentInterface) => {
        e.preventDefault();

        this.setState({
            addedDepartments: _.filter(this.state.addedDepartments, (d) => d.id !== department.id)
        })
    }

    addToDepartment = (e: React.MouseEvent, department: DepartmentInterface) => {
        e.preventDefault();

        this.setState({
            addedDepartments: [...this.state.addedDepartments, department]
        })
    }

    componentDidMount() {
        const { contractType } = this.props;
        const companyId = userStore.signedInUser.company.id;

        templateStore.getTemplates(contractType);
        departmentStore.getDepartmentsByCompany(companyId);
        locationStore.getLocationsByCompany(companyId);
    }

    componentWillUnmount() {
        templateStore.resetStore();
    }

    onShowModal = (id: string | null) => {
        if (id) {
            const entity = _.find(templateStore.templates, { id: id });

            if (entity) {
                this.setState({
                    addedDepartments: entity.departments
                })
            }
        }
    }

    resetState = () => {
        this.setState({
            addedDepartments: [],
            uploadedFile: null
        })
    }

    uploadFile = (document: ContractDocumentInterface) => {
        this.setState({
            uploadedFile: document
        })
    }

    openFile = async (e: React.MouseEvent, minioName: string) => {
        e.preventDefault();
        e.stopPropagation();

        const endpoint = await documentStore.getDocumentUrl(minioName) as DocumentUrlInterface;
        window.open(endpoint.url);
    }

    isNewEntity = () => {
        const form = this.form.$;
        return form && !!!form.id.$;
    }

    beforeSubmit = (template: TemplateInterface) => {
        const { contractType } = this.props;
        const { uploadedFile, addedDepartments } = this.state;

        template.companyId = userStore.signedInUser.company.id;
        template.contractType = contractType;

        if (uploadedFile) {
            template.document = uploadedFile;
            template.documentId = uploadedFile.id;
        }

        template.departments = addedDepartments;

    }

    render() {
        const { uploadedFile, addedDepartments } = this.state;
        const data = templateStore.templates || [];
        const departments = departmentStore.departments || [];
        const locations = locationStore.locations || [];
        const form = this.form.$;

        const orderedDepartments = _.groupBy(departments, 'locationId');

        let entity: TemplateInterface | undefined;
        if (!this.isNewEntity()) {
            entity = _.find(data, { id: this.form.$.id.$! })
        }

        const columns = [
            {
                Header: <TableHeader value={languageStore.get('name')} />,
                id: 'name',
                accessor: (d: TemplateInterface) => {
                    return d.name || '';
                }
            },
            {
                Header: <TableHeader value={languageStore.get('departments')} />,
                id: 'departments',
                accessor: (d: TemplateInterface) => {
                    const orderedDepartments = _.orderBy(d.departments, 'name');
                    return _.map(orderedDepartments, (d) => d.name).join(', ');
                }
            }
        ]

        return (
            <>
                <DetailsPage
                    fullWidth={true}
                    generalTitle={languageStore.get('templates')}
                    modalTitle={languageStore.get('template')}
                    beforeSubmit={this.beforeSubmit}
                    form={this.form}
                    columns={columns}
                    onShowModal={this.onShowModal}
                    onHideModal={this.resetState}
                    collection={data}
                    onUpdate={(template: TemplateInterface) => templateStore.updateTemplate(template)}
                    onSubmit={(template: TemplateInterface) => templateStore.createTemplate(template)}
                    onDelete={(id: string) => templateStore.deleteTemplate(id)}
                    customActionButton={{ onClick: () => navigationStore.push(`${window.location.pathname}/fields`), text: languageStore.get('availableFields') }}>

                    <ModalColumn>

                        <Input
                            fieldState={form.name}
                            id='name'
                            label={languageStore.get('name')} />

                        <label className='text-green-dark text-sm block font-bold mb-1'>{languageStore.get('template')}</label>

                        {
                            (this.isNewEntity() && !uploadedFile) &&
                            <UploadFile allowedFileTypes={['doc', 'docx']} onSubmit={this.uploadFile}>

                                <button onClick={(e) => e.preventDefault()} className='bg-green text-white py-2 px-8 rounded'>
                                    {languageStore.get('upload')}
                                </button>

                            </UploadFile>
                        }

                        {
                            uploadedFile &&
                            <p
                                className='text-black mt-2 cursor-pointer'>
                                <span onClick={(e) => this.openFile(e, uploadedFile!.minioName)}>
                                    {uploadedFile.fileName}
                                </span>
                                <span className='text-xs ml-4'>
                                    ({languageStore.get('readyToBeSaved')})
                        </span>
                            </p>
                        }

                        {
                            (entity && entity.document) &&
                            <p
                                className='text-black mt-2 cursor-pointer'>
                                <span onClick={(e) => this.openFile(e, entity!.document!.minioName)}>
                                    {entity.document.fileName}
                                </span>
                            </p>
                        }

                        <label className='text-green-dark text-sm block font-bold mb-1 mt-8'>{languageStore.get('departments')}</label>

                        {
                            Object.keys(orderedDepartments).map((locationId) => {

                                const dep = orderedDepartments[locationId];
                                const location = _.find(locations, { id: locationId }) as LocationInterface;

                                return dep.map((department: DepartmentInterface, index: number) => {

                                    const hasItem = !!_.find(addedDepartments, { id: department.id });

                                    let className = 'mt-2';
                                    if (!hasItem) {
                                        className += ' text-grey';
                                    }

                                    return (<div key={index} className={className}>
                                        {department.name} <span className='text-sm font-light'>({location ? location.name : ''})</span>

                                        {
                                            hasItem &&
                                            <button className='ml-2 px-2 py-1 text-xs text-red rounded bg-red-lightest hover:bg-red-lighter' onClick={(e) => this.deleteFromDepartment(e, department)}>X</button>
                                        }

                                        {
                                            !hasItem &&
                                            <button className='ml-2 px-2 py-1 text-xs text-green rounded bg-green-lightest hover:bg-green-lighter' onClick={(e) => this.addToDepartment(e, department)}>+</button>
                                        }
                                    </div>)
                                })
                            })
                        }

                    </ModalColumn>


                </DetailsPage>

                <div className='text-center mt-8 text-sm font-semibold'>
                    📄 {languageStore.get('getStartedWith')}&nbsp;
                    <a  
                        className='text-red'
                        href='https://appsource.microsoft.com/product/office/WA200003149'
                        target='_blank' 
                        rel='noreferrer'>
                        {languageStore.get('comaSystemForWord')}
                    </a>
                </div>
            </>
        )
    }

}

export default TemplatesPage;