import { observer } from "mobx-react";
import { languageStore, userStore } from "stores";
import { navigationStore } from "stores";

const ActivateTwoFactorBanner = observer(() => {

    const showBanner = userStore.signedInUser?.needsTwoFactorAuthSetup;
    if (!showBanner) return null;

    return (
        <div className='bg-black text-center text-white py-2'>
            <span className='mr-2'>🔒</span>
            {languageStore.get('activateTwoFactorToMakeYourAccountMoreSecure')}
            <span className='px-4'>-</span>
            <button 
                onClick={() => navigationStore.push('/activate-twofactor')}
                className='text-white font-semibold underline'>
                {languageStore.get('clickHereToGetStarted')}
            </button>
        </div>
    )

})

export default ActivateTwoFactorBanner;