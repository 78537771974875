import * as React from 'react';
import { observer } from 'mobx-react';
import { FieldState } from 'formstate';
import { languageStore } from 'stores';

interface Props {
    fieldState: FieldState<any>,
    label: string,
    placeholderText?: string,
    id: string,
    editMode?: boolean,
    className?: string,
    marginTop?: string,
    marginBottom?: string,
    description?: string
}

@observer class SelectTrueFalse extends React.Component<Props> {

    options = [
        {
            value: true,
            name: languageStore.get('yes')
        },
        {
            value: false,
            name: languageStore.get('no')
        }
    ]

    onChange(e: React.ChangeEvent<HTMLInputElement>) {
        const { fieldState } = this.props;
        const val = e.target.checked;

        fieldState.onChange(val);
    }

    render() {
        const { fieldState, id, description, label, editMode, className, marginBottom, marginTop } = this.props;
        const readableValue = fieldState.value === true ? languageStore.get('yes') : languageStore.get('no');

        if (editMode) {
            return (
                <div className={`py-2 px-1 ${className || ''}`} style={{ marginTop: marginTop || '32px', marginBottom: marginBottom || '40px' }}>
                    <input id={id} type='checkbox' checked={fieldState.value || false} onChange={(e) => this.onChange(e)} />
                    <label htmlFor={id} style={{ paddingLeft: '10px' }}>
                        {label} {fieldState.error && <span className="text-red text-xs pl-1">({fieldState.error})</span>}
                    </label>
                    {
                        description &&
                        <p className='text-sm text-grey-dark' style={{ marginLeft: '22px', marginTop: '5px' }}>
                            {description}
                        </p>
                    }

                </div>
            )
        }

        return (
            <>
                <dt className='mt-5 text-sm text-comablue-label font-semibold'>{label}</dt>
                <dd className='pt-1'>{readableValue}</dd>
            </>
        )
    }

}

export default SelectTrueFalse;