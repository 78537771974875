import * as React from 'react';
import { observer } from 'mobx-react';
import { FormState, FieldState } from 'formstate';
import { languageStore, departmentStore, DepartmentInterface, locationStore, LocationInterface, userStore, UserInterface, VerificationEntryInterface, authStore, CurrentContractStore } from 'stores';
import TableGreenHeader from 'components/Table/TableGreenHeader';
import ContractDetailsTable from 'components/ContractDetailsTable';
import { ModalColumn } from 'components/Modal';
import * as _ from 'lodash';
import * as Moment from 'moment-timezone';
import { formatDate } from 'helpers/DateHelper';

interface Props {
    store: CurrentContractStore<any>,
    hash: string
}

@observer class VerifiedBy extends React.Component<Props> {

    form = new FormState({
        id: new FieldState<string | null>(null),
        verifiedById: new FieldState<string | null>(null)
    })

    resetForm(id: string | null) {
        const { store } = this.props;

        if (id) {
            const entry = _.find(store.contract!.contractVerificationEntries, { id: id }) as VerificationEntryInterface;
            const form = this.form.$;
            
            form.id.reset(entry.id);
            form.verifiedById.reset(entry.verifiedById || null);
        }
    }

    onSubmit = async () => {
        const { store } = this.props;
        const form = this.form.$;
        const id = form.id.$;
        const verifiedById = form.verifiedById.$;

        if (id && !verifiedById) {
            const match = _.find(store.contract!.contractVerificationEntries, { id: id }) as VerificationEntryInterface;
            match.verifiedById = authStore.userId!;
            let timeZone = userStore.signedInUser!.timeZone.id;
            match.verifiedOn = Moment.utc().tz(timeZone).toISOString();
                    
            await store.updateVerificationEntry(match);
        }
    }

    componentDidMount() {
        departmentStore.getDepartments();
        locationStore.getLocations(false);
        userStore.getUsers(false);
    }

    render() {
        const { store, hash } = this.props;
        const data = store.contract!.contractVerificationEntries || [];
        const departments = departmentStore.allDepartments;
        const locations = locationStore.locations;
        const users = userStore.users;

        const columns = [
            {
                Header: <TableGreenHeader value={languageStore.get('department')} />,
                id: 'departmentId',
                accessor: (d: any) => {
                    const departmentId = d.contractVerification.departmentId;
                    const department = _.find(departments, { id: departmentId }) as DepartmentInterface;
                    return department ? department.name : '-';
                }
            },
            {
                Header: <TableGreenHeader value={languageStore.get('location')} />,
                id: 'locationId',
                accessor: (d: any) => {
                    const departmentId = d.contractVerification.departmentId;
                    const department = _.find(departments, { id: departmentId }) as DepartmentInterface;
                    if (!department) return '-';

                    const locationId = department.locationId;
                    const location = _.find(locations, { id: locationId }) as LocationInterface;
                    return location ? location.name : '-';
                }
            },
            {
                Header: <TableGreenHeader value={languageStore.get('verifiedBy')} />,
                id: 'verifiedById',
                accessor: (d: any) => {
                    const userId = d.verifiedById;
                    const user = _.find(users, { id: userId }) as UserInterface;
                    return user ? user.fullName : languageStore.get('notVerified');
                }
            },
            {
                Header: <TableGreenHeader value={languageStore.get('date')} />,
                id: 'verifiedOn',
                accessor: (d: any) => formatDate(d.verifiedOn)
            }
        ]

        const form = this.form.$;

        return (
            <ContractDetailsTable
                store={store}
                hash={hash}
                title={languageStore.get('verifiedBy')}
                modalTitle={languageStore.get('verifiedBy')}
                columns={columns}
                data={data.slice()}
                formState={this.form}
                onShowModal={(id: string | null) => this.resetForm(id)}
                hideAddButton={true}
                hideCloseButton={!!store.isDraft}
                onSubmit={this.onSubmit}>

                <ModalColumn>

                    {
                        form.verifiedById.$ &&
                        <p>
                            {languageStore.get('entryAlreadyVerified')}.
                        </p>
                    }

                    {
                        !form.verifiedById.$ &&
                        <p>
                            {languageStore.get('saveToVerifyContract')}.
                        </p>
                    }

                </ModalColumn>

            </ContractDetailsTable>
        )
    }

}

export default VerifiedBy;