import * as React from 'react';
import { observer } from 'mobx-react';
import { ADFSHostEnum } from 'stores';
import ADFS from '../ADFS';
import logoImg from './logo.jpg';

interface State {
    signInError: boolean
}

@observer class SefLoginPage extends React.Component<any, State> {

    resource = process.env.REACT_APP_FRONTEND_BASEURL!;
    redirectUri = process.env.REACT_APP_FRONTEND_BASEURL + '/login/sef';
    endpoint = process.env.REACT_APP_ADFS_SEF_ENDPOINT;
    clientId = process.env.REACT_APP_ADFS_SEF_CLIENT_ID;
    providerId = process.env.REACT_APP_ADFS_SEF_PROVIDER_ID!;
    //?response_type=code&client_id=${this.clientId}&resource=${encodeURIComponent(this.resource)}&redirect_uri=${encodeURIComponent(this.redirectUri)}&RedirectToIdentityProvider=AD+AUTHORITY`}
    render() {
        return (
            <ADFS
                logoImgSrc={logoImg}
                logoImgAlt='SEF'
                loginEndpoint={`${this.endpoint}&redirect_uri=${encodeURIComponent(this.redirectUri)}&RedirectToIdentityProvider=AD+AUTHORITY`}
                adfsHost={ADFSHostEnum.SEF}
                redirectUri={this.redirectUri}
                providerId={this.providerId}
            />
        )
    }
}

export default SefLoginPage;