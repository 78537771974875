import * as React from 'react';
import { observer } from 'mobx-react';
import { CurrentContractStore, CurrentContractBaseInterface, languageStore, ContractToContractReferenceInterface, ContractToContractInterface, navigationStore, linkContractStore, toastStore } from 'stores';
import TableGreenHeader from 'components/Table/TableGreenHeader';
import ContractSection from 'components/ContractSection';
import ContractDetailsGroup from 'components/ContractDetailsGroup';
import ReactTable from 'react-table';
import { Modal } from 'components/Modal';
import { canAccess, ModuleEnum } from 'helpers/ModuleHelper';
import { BaseContractTypeEnum } from 'stores/BaseStore';
import EmptyContractSection from 'components/EmptyContractSection';
import _ from 'lodash';
import { isFormStateDirty } from 'helpers/FormHelper';

interface Props {
    store: CurrentContractStore<CurrentContractBaseInterface>,
    hash: string
}

interface State {
    isShowModal: boolean
}

@observer class ContractToContract extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);

        this.state = {
            isShowModal: false
        }
    }

    getDTO(ref: ContractToContractReferenceInterface): ContractToContractInterface | null {
        if (ref.purchaseContractId) return ref.purchaseContract;
        else if (ref.salesContractId) return ref.salesContract;
        else if (ref.serviceContractId) return ref.serviceContract;
        else if (ref.staffContractId) return ref.staffContract;

        return null;
    }

    toggleEditMode = () => {
        const { hash, store } = this.props;
        // const hash = SectionEnum.FILES_CONTRACT;
        const editMode = store.isEditMode(hash);
        store.setEditMode(hash, !editMode)
    }

    getIdByRow = (row: ContractToContractReferenceInterface) => {
        if (row.purchaseContractId) {
            return row.purchaseContractId;
        }
        else if (row.salesContractId) {
            return row.salesContractId;
        }
        else if (row.serviceContractId) {
            return row.serviceContractId;
        }
        else if (row.staffContractId) {
            return row.staffContractId;
        }
        else return;
    }

    getContractTypeByRow = (row: ContractToContractReferenceInterface) => {
        if (row.purchaseContractId) {
            return BaseContractTypeEnum.PURCHASE;
        }
        else if (row.salesContractId) {
            return BaseContractTypeEnum.SALES;
        }
        else if (row.serviceContractId) {
            return BaseContractTypeEnum.SERVICE;
        }
        else if (row.staffContractId) {
            return BaseContractTypeEnum.STAFF;
        }
        else return;
    }

    gotoContract = (row: ContractToContractReferenceInterface) => {
        navigationStore.push(`/${this.getContractTypeByRow(row)}/${this.getIdByRow(row)}`, () => {
            const url = window.location.href;
            window.location.href = url;
        });
    }

    async componentDidMount() {
        const { store } = this.props;
        if (linkContractStore.linkContractIsActive) {
            if (linkContractStore.sourceId === store.contract!.id) {
                await store.linkContract(linkContractStore.targetType as BaseContractTypeEnum, linkContractStore.targetId!)

                toastStore.addToast({
                    'level': 'success',
                    'title': languageStore.get('success'),
                    'message': languageStore.get('contractLinked')
                })

                linkContractStore.resetStore();
            }
        }
    }

    hideModal = () => {
        this.setState({ isShowModal: false })
    }

    showModal = () => {
        const { store } = this.props;

        if (store.isDraft) {
            const anyDirtyForms = _.some(store.submitForms, (f) => isFormStateDirty(f.$));

            if (anyDirtyForms) {
                alert(languageStore.get('saveUnsavedChangesBeforeUsingThisFunction'));
                return;
            }
        }

        this.setState({ isShowModal: true })
    }

    onDelete = async (row: ContractToContractReferenceInterface) => {
        if (window.confirm(`${languageStore.get('areYouSureYouWantToDeleteReference')}?`)) {
            const { store } = this.props;
            await store.deleteLinkToContract(row.id);
        }
    }

    modalButton(title: string, gotoEndpoint: BaseContractTypeEnum) {

        const onClick = (e: React.MouseEvent) => {
            e.preventDefault();

            const { store } = this.props;
            const sourceType = store.contractEndpoint as BaseContractTypeEnum;
            const sourceId = store.contract!.id;

            linkContractStore.setSource(sourceType, sourceId);
            navigationStore.push(`/${gotoEndpoint}`);
        }


        return (
            <a
                href=' '
                onClick={onClick}
                className={`
            block 
            w-64 
            border 
            rounded 
            px-4 
            py-2 
            mb-4 
            text-center
            hover:bg-grey-lightest
            cursor-pointer`}>
                {title}
            </a>
        )
    }

    render() {
        const { isShowModal } = this.state;
        const { hash, store } = this.props;
        const data = store.contract!.contractContractReferences || [];
        const editMode = store.isEditMode(hash);

        const columns = [
            {
                Header: <TableGreenHeader value={languageStore.get('type')} />,
                id: 'type',
                accessor: (d: ContractToContractReferenceInterface) => {
                    if (d.purchaseContractId) return languageStore.get('purchaseContract');
                    else if (d.salesContractId) return languageStore.get('salesContract');
                    else if (d.serviceContractId) return languageStore.get('serviceContract');
                    else if (d.staffContractId) return languageStore.get('staffContract');
                    return null;
                }
            },
            {
                Header: <TableGreenHeader value={languageStore.get('identification')} />,
                id: 'identification',
                accessor: (d: ContractToContractReferenceInterface) => {
                    var dto = this.getDTO(d);
                    if (dto) {
                        return dto!.identification
                    }
                    else return null;

                }
            },
            {
                Header: <TableGreenHeader value={languageStore.get('description')} />,
                id: 'description',
                accessor: (d: ContractToContractReferenceInterface) => {
                    var dto = this.getDTO(d);
                    if (dto) {
                        return dto!.description
                    }
                    else return null;

                }
            },
            {
                Header: <TableGreenHeader value={languageStore.get('sum')} />,
                id: 'sum',
                accessor: (d: ContractToContractReferenceInterface) => {
                    var dto = this.getDTO(d);
                    if (dto) {
                        return dto!.sum
                    }
                    else return null;
                }
            },

        ]

        if (editMode) {
            columns.push({
                Header: <TableGreenHeader value={languageStore.get('action')} />,
                id: 'id',
                accessor: 'id',
                Cell: (props: any) => {
                    return (
                        <div>
                            <button className='py-1 px-2 border rounded hover:bg-grey-lighter' onClick={() => this.onDelete(props.original)}>{languageStore.get('delete')}</button>
                        </div>
                    )
                }
            } as any)
        }

        if (data && data.length === 0 && !isShowModal) {
            return <EmptyContractSection
                hash={hash}
                store={store}
                onAdd={this.showModal}
                title={languageStore.get('linkedContract')} />
        }

        return (
            <div>
                <ContractSection
                    noFlex={true}
                    onEdit={() => store.setEditMode(hash, !editMode)}
                    hash={hash}
                    canEdit={store.canEdit}
                    editMode={editMode}
                    hideSaveButton={true}>

                    <ContractDetailsGroup
                        fullWidth={true}
                        title={languageStore.get('linkedContracts')}>

                        <div className='flex-grow mt-5'>

                            <ReactTable
                                columns={columns}
                                className={editMode ? 'ReactTable-readonly' : ''}
                                data={data.slice()}
                                defaultPageSize={99999}
                                showPagination={false}
                                NoDataComponent={() => null}
                                minRows={0}
                                getTdProps={(state: any, rowInfo: any, column: any, instance: any) => {
                                    return {
                                        onClick: (e: React.MouseEvent, handleOriginal: any) => {

                                            if (!editMode) {
                                                this.gotoContract(rowInfo.original);
                                            }
                                        }
                                    };
                                }} />

                            {
                                editMode &&
                                <div className='mt-5'>

                                    <button onClick={this.showModal} className='bg-green text-white py-2 px-8 rounded'>
                                        {languageStore.get('add')}
                                    </button>

                                    {
                                        !store.isDraft &&
                                        <button onClick={this.toggleEditMode} className='text-grey-darker border ml-2 py-2 px-4 rounded'>
                                            {languageStore.get('close')}
                                        </button>
                                    }

                                </div>
                            }

                        </div>

                    </ContractDetailsGroup>

                </ContractSection>

                {
                    isShowModal &&
                    <div className='text-left'>
                        <Modal
                            title={languageStore.get('selectContractType')}
                            secondaryButton={{ title: languageStore.get('cancel'), onClick: this.hideModal }}>

                            <div className='mx-auto'>
                                {
                                    canAccess(ModuleEnum.PURCHASE) &&
                                    this.modalButton(languageStore.get('purchaseContract'), BaseContractTypeEnum.PURCHASE)
                                }

                                {
                                    canAccess(ModuleEnum.STAFF) &&
                                    this.modalButton(languageStore.get('staffContract'), BaseContractTypeEnum.STAFF)
                                }

                                {
                                    canAccess(ModuleEnum.SERVICE) &&
                                    this.modalButton(languageStore.get('serviceContract'), BaseContractTypeEnum.SERVICE)
                                }

                                {
                                    canAccess(ModuleEnum.SALES) &&
                                    this.modalButton(languageStore.get('salesContract'), BaseContractTypeEnum.SALES)
                                }
                            </div>

                        </Modal>
                    </div>
                }
            </div>
        )
    }

}

export default ContractToContract;