import * as React from 'react'
import ScrollIntoView from 'scroll-into-view';
import { navigationStore } from 'stores';

interface Props {
    children: React.ReactNode
}

class ScrollToAnchor extends React.Component<Props> {

    componentDidMount() {
        // Decode entities in the URL
        // Sometimes a URL like #/foo#bar will be encoded as #/foo%23bar
        // window.location.hash = decodeURIComponent(window.location.hash);
        const scrollToAnchor = () => {
            const hashParts = window.location.hash.split('#');

            if (hashParts.length > 1) {
                const hash = '#' + hashParts[1];

                var el: any = document.getElementById(hash);
                if (el) {
                    navigationStore.setIsScrolling(true);
                    navigationStore.setHash(hash);
                    ScrollIntoView(el, (type) => {
                        navigationStore.setIsScrolling(false);
                    });
                }
            }
        };
        scrollToAnchor();
        window.onhashchange = scrollToAnchor;
    }

    render() {
        return this.props.children;
    }

}

export default ScrollToAnchor;