import Country from "components/Forms/Country";
import Form from "components/Forms/Form";
import Input from "components/Forms/Input";
import { Modal, ModalColumn } from "components/Modal";
import { FieldState, FormState } from "formstate";
import { required } from "helpers/FormValidation";
import { observer } from "mobx-react";
import { useState } from "react";
import { languageStore, LocationInterface, locationStore, userStore } from "stores";

const AddLocationModal = observer(() => {
    const [showModal, setShowModal] = useState(false);

    const form = new FormState({
        name: new FieldState('').validators(required),
        countryId: new FieldState('').validators(required)
    })

    const onSubmit = async () => {
        const location = {
            companyId: userStore.signedInUser.company.id,
            name: form.$.name.$,
            id: '',
            countryId: form.$.countryId.$!,
            country: {}
        } as LocationInterface

        await locationStore.createCompanyLocation(location)
        
        form.reset();
        setShowModal(false);
    }

    return (
        <div className='text-left'>
            <button onClick={() => setShowModal(true)} className='bg-comablue-label text-white py-2 px-8 rounded'>
                {languageStore.get('add')}
            </button>
            {
                showModal &&
                <Form onValidSubmit={onSubmit} formState={form}>
                    <Modal
                        title={languageStore.get('location')}
                        primaryButton={{ title: languageStore.get('save') }}
                        secondaryButton={{ title: languageStore.get('cancel'), onClick: () => setShowModal(false) }}>

                        <ModalColumn>

                            <Input
                                fieldState={form.$.name}
                                id='location'
                                label={languageStore.get('name')} />

                            <Country
                                fieldState={form.$.countryId}
                                editMode={true} />

                        </ModalColumn>

                    </Modal>
                </Form>
            }
        </div>
    )
})

export default AddLocationModal;