import * as _ from 'lodash';

export function replaceOrAddObjectById(id: string, arr: Array<any>, obj: any): Array<any> {
    const index = _.findIndex(arr, { id: obj.id });

    if (index) {
        arr.splice(index, 1, obj);
        return arr;
    }
    else {
        arr.push(obj);
        return arr;
    }
}

export function syncObject(id: any, from: Array<any>, to: Array<any>) {
    const item = _.find(from, { id: id });
    if (item) {
        replaceOrAddObjectById(id, to, item);
    }
}

export function replaceById(id: string, arr: Array<any>, obj: any) {
    const index = _.findIndex(arr, { id: obj.id });

    if (index) {
        arr.splice(index, 1, obj);
    }

    return arr;
}