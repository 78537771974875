import * as React from 'react';
import { observer } from 'mobx-react';
import { languageStore, ContractDocumentInterface, CurrentContractStore, CurrentContractBaseInterface, addoTemplateStore, AddoTemplateInterface, DocumentSigningRecipientAuthenticationMethod, DocumentSigningRecipientSigningMethodEnum, DocumentSigningRecipientInterface, ContactPeopleInterface, toastStore, DashboardContractTypeEnum } from 'stores';
import { Modal, ModalColumn } from 'components/Modal';
import { FormState, FieldState } from 'formstate';
import Input from 'components/Forms/Input';
import Form from 'components/Forms/Form';
import { required, email } from 'helpers/FormValidation';
// import SelectTrueFalse from 'components/Forms/SelectTrueFalse';
import SelectTemplate from './SelectTemplate';
import * as _ from 'lodash';
import { isFormStateDirty } from 'helpers/FormHelper';
import AddByContact, { AddByContactTypeEnum } from './AddByContact';
import SelectDocuments from './SelectDocuments';
import SelectTrueFalse from 'components/Forms/SelectTrueFalse';

export interface SignDocumentRecipientInterface {
    name: string,
    email: string,
    authenticationMethod: DocumentSigningRecipientAuthenticationMethod,
    signingMethod: DocumentSigningRecipientSigningMethodEnum,
    cvr?: string
}

export interface RecipientFormStateInterface {
    name: FieldState<string>,
    email: FieldState<string | null>,
    cvr: FieldState<string>
}

interface Props {
    documents: Array<ContractDocumentInterface>,
    store: CurrentContractStore<CurrentContractBaseInterface>
}

interface State {
    showModal: boolean,
    isSubmitting: boolean,
    showAddContact: boolean,
    showAddContactVisible: boolean,
    selectedDocuments: Array<ContractDocumentInterface>
    step: StepEnum
}

enum StepEnum {
    SELECT_DOCUMENTS,
    ADD_RECIPIENTS
}

@observer class SignDocument extends React.Component<Props, State> {

    form = new FormState({
        templateId: new FieldState('').validators(required),
        signByRecipientOrder: new FieldState(false),
        recipients: new FormState({})
    });

    constructor(props: Props) {
        super(props);

        this.state = {
            showModal: false,
            isSubmitting: false,
            showAddContact: false,
            showAddContactVisible: false,
            selectedDocuments: [],
            step: StepEnum.SELECT_DOCUMENTS
        }
    }

    showModal = () => {
        const { store } = this.props;

        if (store.isDraft) {
            const anyDirtyForms = _.some(store.submitForms, (f) => isFormStateDirty(f.$));
            if (anyDirtyForms) {
                alert(languageStore.get('saveUnsavedChangesBeforeUsingThisFunction'));
                return;
            }
        }

        addoTemplateStore.getTemplates();
        this.setState({ showModal: true })
    }

    hideModal = () => {
        this.form.reset();
        this.form.$.recipients = new FormState({});
        this.setState({
            showModal: false,
            showAddContact: false,
            step: StepEnum.SELECT_DOCUMENTS,
            selectedDocuments: []
        })
    }

    onDocumentsSelected = (documents: Array<ContractDocumentInterface>) => {
        this.setState({
            selectedDocuments: documents,
            step: StepEnum.ADD_RECIPIENTS
        });
    }

    onSignDocument = async () => {
        const { store } = this.props;
        const { selectedDocuments } = this.state;
        const form = this.form.$;
        const recipientForm = form.recipients.$;
        const recipients: Array<DocumentSigningRecipientInterface> = [];
        const defaultTemplateId = form.templateId.$;

        Object.keys(recipientForm).forEach((key, index) => {

            const subForm = recipientForm[key];
            if (!subForm.$.name) return;

            const name = subForm.$.name.$;
            const email = subForm.$.email.$;
            const cvr = subForm.$.cvr.$;
            const cpr = subForm.$.cpr.$;

            const templateId = subForm.$.templateId.$;
            const template = _.find(addoTemplateStore.templates, { id: templateId });

            if (name && template) {
                let recipient = 
                {
                    name: name,
                    email: email,
                    authenticationMethod: template.authenticationMethod,
                    signingMethod: template.signingMethod,
                    cvr: templateId === process.env.REACT_APP_ADDO_TEMPLATE_REQUIRES_CVR_ID ? cvr : null,
                    cpr: templateId === process.env.REACT_APP_ADDO_TEMPLATE_REQUIRES_CPR_ID || templateId === process.env.REACT_APP_ADDO_TEMPLATE_REQUIRES_CPR_ID2 ? cpr : null,
                    signatureOrder: index
                };

                if(template.id === process.env.REACT_APP_ADDO_TEMPLATE_REQUIRES_CPR_ID || template.id === process.env.REACT_APP_ADDO_TEMPLATE_REQUIRES_CPR_ID2)
                {
                    recipient.email = "placeholder@deleteduser.dk";
                }

                recipients.push(
                    recipient
                )
            }
        })

        this.setState({ isSubmitting: true })

        try {
            await store.signDocuments(selectedDocuments, recipients, defaultTemplateId, {
                signByRecipientOrder: form.signByRecipientOrder.$
            });
            this.setState({ isSubmitting: false })
            store.setEditMode('#files_contract', false);

            this.hideModal();
        }
        catch (error) {
            this.setState({ isSubmitting: false })
        }
    }

    addRecipient = (nameVal: string = '', emailVal: string | null = null) => {
        const form = this.form.$;
        const recipientForm = form.recipients.$;

        recipientForm[Object.keys(recipientForm).length] = new FormState({
            name: new FieldState(nameVal).validators(required),
            email: new FieldState(emailVal).validators(email),
            templateId: new FieldState(form.templateId?.value || '').validators(required),
            cvr: new FieldState(''),
            cpr: new FieldState('')
        })
    }

    removeRecipient = (key: string) => {
        this.form.$.recipients.$[key] = new FormState({});
    }

    addContact = (contact: ContactPeopleInterface) => {
        if (!contact.email) {
            toastStore.addError(languageStore.get('contactHasNoEmail'));
            return;
        }

        this.addRecipient(contact.name, contact.email);
        toastStore.addToast({
            level: 'success',
            title: languageStore.get('success'),
            message: languageStore.get('added')
        })
    }

    buildRecipients = (templates: Array<AddoTemplateInterface>) => {
        console.log(process.env.REACT_APP_ADDO_TEMPLATE_REQUIRES_CPR_ID)
        console.log(process.env.REACT_APP_ADDO_TEMPLATE_REQUIRES_CPR_ID2)
        const recipients: any = [];

        Object.keys(this.form.$.recipients.$).forEach((key) => {
            const recipientFormEntity = this.form.$.recipients.$[key] as FormState<any>;
            if (!recipientFormEntity.$.name) return;

            recipients.push(
                <div key={key} className='mb-2 pt-8 border rounded p-4' style={{ paddingBottom: '-20px' }}>
                    <div style={{ marginBottom: '-4px' }}>
                        <Input
                            label={languageStore.get('name')}
                            id={'name' + key}
                            fieldState={recipientFormEntity.$.name}
                        />
                    </div>

                    {
                        process.env.REACT_APP_ADDO_TEMPLATE_REQUIRES_CPR_ID2 != recipientFormEntity.$.templateId.value &&
                        process.env.REACT_APP_ADDO_TEMPLATE_REQUIRES_CPR_ID != recipientFormEntity.$.templateId.value &&
                        <div style={{ marginBottom: '0' }}>
                            <Input
                                label={languageStore.get('email')}
                                id={'email' + key}
                                fieldState={recipientFormEntity.$.email}
                            />
                        </div>
                    }

                    <div style={{ marginBottom: '-4px' }}>
                        <SelectTemplate
                            label={languageStore.get('signingMethod')}
                            templates={templates}
                            fieldState={recipientFormEntity.$.templateId}
                        />
                    </div>

                    {
                        
                        process.env.REACT_APP_ADDO_TEMPLATE_REQUIRES_CVR_ID === recipientFormEntity.$.templateId.value &&
                        <div style={{ marginBottom: '-4px' }}>
                            <Input
                                required={true}
                                label={languageStore.get('vatNumber')}
                                id={'cvr' + key}
                                fieldState={recipientFormEntity.$.cvr}
                            />
                        </div>
                    }

                    {

                        (process.env.REACT_APP_ADDO_TEMPLATE_REQUIRES_CPR_ID2 === recipientFormEntity.$.templateId.value ||
                            process.env.REACT_APP_ADDO_TEMPLATE_REQUIRES_CPR_ID === recipientFormEntity.$.templateId.value) &&
                        <div style={{ marginBottom: '-4px' }}>
                            <Input
                                required={true}
                                label={languageStore.get('ssn') + " (XXXXXXXXXX)"}
                                id={'cpr' + key}
                                fieldState={recipientFormEntity.$.cpr}
                            />
                        </div>
                    }

                    <div className="text-right text-xs" style={{ marginTop: '-20px' }}>
                        <button
                            type='button'
                            className='underline text-grey-dark'
                            onClick={() => this.removeRecipient(key)}>
                            {languageStore.get('remove')}
                        </button>
                    </div>
                </div>
            )
        })

        return recipients;
    }

    render() {
        const { store, documents } = this.props;
        const { showModal, isSubmitting, showAddContactVisible, step, selectedDocuments } = this.state;
        const templates = addoTemplateStore.templates || [];
        let form = this.form.$;

        return (
            <>
                <button
                    onClick={this.showModal}
                    className='bg-comablue-label text-white py-2 px-8 rounded'>
                    {languageStore.get('sign')}
                </button>

                {
                    (showModal && step === StepEnum.SELECT_DOCUMENTS) &&
                    <SelectDocuments
                        documents={documents}
                        onCancel={this.hideModal}
                        onDocumentsSelected={this.onDocumentsSelected}
                    />
                }

                {
                    (showModal && step === StepEnum.ADD_RECIPIENTS) &&
                    <Form onValidSubmit={this.onSignDocument} formState={this.form}>
                        <Modal

                            title={languageStore.get('sign')}
                            primaryButton={!isSubmitting ? { title: languageStore.get('sendToEmails') } : undefined}
                            secondaryButton={!isSubmitting ? { title: languageStore.get('cancel'), onClick: this.hideModal } : undefined}>

                            <ModalColumn>
                                <div style={{ marginTop: '-35px' }} />
                                {
                                    !isSubmitting &&
                                    <>
                                        {
                                            _.orderBy(selectedDocuments, (d) => d.fileName.toLowerCase()).map((document, index) => {
                                                return (
                                                    <div
                                                        className='text-sm pb-4 font-semibold'
                                                        key={index}>{document.fileName}</div>
                                                )
                                            })
                                        }

                                        <div className='mb-4'>
                                            <SelectTemplate
                                                label={languageStore.get('template')}
                                                templates={templates}
                                                fieldState={form.templateId}
                                            />
                                        </div>

                                        <div className='bg-grey-light p-4'>
                                            <p className='whitespace-normal'>
                                                {languageStore.get('addOneOrMoreRecipientsBelow')}
                                            </p>

                                            <p className='mt-2 whitespace-normal'>
                                                {languageStore.get('rememberToAddYourselfIfYouShouldSignTheDocumentAlso')}.
                                            </p>
                                        </div>

                                        <div style={{ marginTop: '-18px', marginBottom: '-30px' }}>
                                            <SelectTrueFalse
                                                label={languageStore.get('signByRecipientOrder')}
                                                editMode={true}
                                                fieldState={form.signByRecipientOrder}
                                                id='signByRecipientOrder'
                                            />
                                        </div>

                                        {this.buildRecipients(templates)}

                                        <div className='mt-6'>
                                            {
                                                !showAddContactVisible &&
                                                <div className='mb-4 underline text-comablue-label cursor-pointer' onClick={() => this.addRecipient()}>+ {languageStore.get('addRecipientByEmail')}</div>
                                            }

                                            {
                                                store.contractType === DashboardContractTypeEnum.PURCHASE &&
                                                <AddByContact
                                                    onVisibilityToggled={(value) => this.setState({ showAddContactVisible: value })}
                                                    onContactSelected={this.addContact}
                                                    type={AddByContactTypeEnum.SUPPLIER}
                                                />
                                            }

                                            {
                                                store.contractType === DashboardContractTypeEnum.SALES &&
                                                <AddByContact
                                                    onVisibilityToggled={(value) => this.setState({ showAddContactVisible: value })}
                                                    onContactSelected={this.addContact}
                                                    type={AddByContactTypeEnum.CUSTOMER}
                                                />
                                            }

                                        </div>
                                    </>
                                }

                                {
                                    isSubmitting &&
                                    <p>
                                        {languageStore.get('sendingContractPleaseWait')}
                                    </p>
                                }

                            </ModalColumn>

                        </Modal>
                    </Form>

                }
            </>
        )
    }

}

export default SignDocument;