import * as React from 'react';
import { observer } from 'mobx-react';

export interface ExpandIconItemInterface {
    text: string,
    subText?: string,
    onClick: Function,
    icon?: React.ReactNode,
    className?: string,
    bold?: boolean,
    keepExpandedOnClick?: boolean
}

interface Props {
    icon: any,
    onExpand?: Function,
    onCollapse?: Function,
    notificationCount?: number,
    items: Array<ExpandIconItemInterface>
}

interface State {
    expanded: boolean
}

@observer class ExpandIcon extends React.Component<Props, State> {

    inputRef: React.RefObject<any>;

    constructor(props: Props) {
        super(props);

        this.inputRef = React.createRef();

        this.state = {
            expanded: false
        }
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClick, { capture: true });
    }
    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClick, { capture: true });
    }

    handleClick = (e: any) => {
        if (this.inputRef.current === e.target ||
            (this.inputRef.current && this.inputRef.current.contains(e.target))
        ) {
            return;
        }

        this.collapse();
    };

    expand = () => {
        this.setState({ expanded: true });

        const { onExpand } = this.props;
        if (onExpand) {
            onExpand();
        }
    }

    collapse = () => {
        this.setState({ expanded: false });

        const { onCollapse } = this.props;
        if (onCollapse) {
            onCollapse();
        }
    }

    itemClicked = (e: React.MouseEvent, func: Function, keepExpandedOnClick?: boolean) => {
        e.preventDefault();
        e.stopPropagation();

        func();

        if (!keepExpandedOnClick) {
            this.collapse();
        }
    }

    render() {
        const { expanded } = this.state;
        const { notificationCount, icon, items } = this.props;

        return (
            <div className='relative cursor-pointer' onClick={this.expand}>
                <img src={icon} width='17' height='20' className='mr-4 cursor-pointer' alt='' />
                {
                    (notificationCount! > 0 && !expanded) &&
                    <div className='NotificationBubble'>
                        {notificationCount}
                    </div>
                }

                {
                    expanded &&
                    <div
                        ref={this.inputRef}
                        style={{ left: '-240px', zIndex: 99999 }}
                        className='rounded shadow-md absolute mt-6 bg-white pin-t pin-l w-64'>
                        <ul className='list-reset'>
                            {
                                items.map((item: ExpandIconItemInterface, index: number) => {
                                    return (
                                        <li key={index} className={item.className}>
                                            <a href="# " onClick={(e) => this.itemClicked(e, item.onClick, item.keepExpandedOnClick)} className="text-sm no-underline px-4 py-3 block text-black hover:bg-grey-lightest">
                                                <div>

                                                    {item.icon}
                                                    <p className={`
                                                    inline 
                                                    ${item.icon ? 'ml-1' : ''}
                                                    ${item.bold ? 'font-bold' : ''}
                                                    `}>

                                                        {item.text}

                                                        {
                                                            item.subText &&
                                                            <span className='block mt-1 font-xs text-grey'>
                                                                {item.subText}
                                                            </span>
                                                        }
                                                        
                                                    </p>
                                                </div>
                                            </a>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                }

            </div>
        );
    }
}

export default ExpandIcon;