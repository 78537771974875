import * as React from 'react';
import { observer } from 'mobx-react';
import { currentServiceContractStore, languageStore, DashboardContractTypeEnum, customFieldStore, userStore } from 'stores';
import Menu from './Menu';
import Overview from './Overview';
import Customer from 'components/Forms/Customer';
import SpecialCircumstancesSection from './SpecialCircumstancesSection';
import Services from './Services';
import Documents from './Documents';
import Correspondence from './Correspondence';
import Notes from 'components/Notes';
import SectionEnum from './SectionEnum';
import Alarms from 'components/Alarms';
// import VerifiedBy from 'components/Forms/VerifiedBy';
import ContractMetaStatus from 'components/Forms/ContractMetaStatus';
import ContractDetailsTop from 'components/ContractDetailsTop';
import ContractToContract from 'components/ContractToContract';
import ContractDetailsContainer from 'components/ContractDetailsContainer';
import ContractDetailsPage from 'components/ContractDetailsPage';
import CustomFields from 'components/CustomFields';

interface Props {
    match?: any
}

@observer class ServiceContractDetailsPage extends React.Component<Props> {

    render() {
        return (
            <ContractDetailsPage store={currentServiceContractStore} contractId={this.props.match.params.id}>
                {
                    currentServiceContractStore.contract &&
                    <>
                        <ContractDetailsTop
                            helpUrl='https://help.comasystem.dk/service/new-service-contract'
                            backlinkTitle={languageStore.get('serviceContracts')}
                            title={languageStore.get('showServiceContract')}
                            store={currentServiceContractStore} />

                        <ContractDetailsContainer>

                            <Menu />
                            <Overview />
                            <Customer store={currentServiceContractStore} hash={SectionEnum.CUSTOMER} />
                            <Notes store={currentServiceContractStore} hash={SectionEnum.NOTES} />
                            <SpecialCircumstancesSection />
                            <Services />
                            <Alarms store={currentServiceContractStore} hash={SectionEnum.EXTRA_ALARMS} />
                            {/* <VerifiedBy store={currentServiceContractStore} hash={SectionEnum.VERIFIED_BY} /> */}
                            <Documents />
                            <ContractToContract store={currentServiceContractStore} hash={SectionEnum.LINKED_CONTRACTS} />
                            <Correspondence />

                            {
                                (userStore.signedInUser!.company.allowCustomFields && customFieldStore.hasCustomFields[DashboardContractTypeEnum.SERVICE]) &&
                                <CustomFields store={currentServiceContractStore} hash={SectionEnum.CUSTOM_FIELDS} />
                            }

                            <ContractMetaStatus
                                store={currentServiceContractStore}
                                hash={SectionEnum.STATUS}
                                type={DashboardContractTypeEnum.SERVICE} />

                        </ContractDetailsContainer>
                    </>
                }

            </ContractDetailsPage>
        )
    }

}

export default ServiceContractDetailsPage;