import * as React from 'react';
import { observer } from 'mobx-react';
import Breadcrumb from 'components/Breadcrumb';
import { languageStore, activityStore, navigationStore } from 'stores';
import TableHeader from 'components/Table/TableHeader';
import ReactTable from 'react-table';
import { formatDate } from 'helpers/DateHelper';
// import * as _ from 'lodash';

@observer class ActivitiesPage extends React.Component<any> {

    _PAGESIZE = 200;

    componentDidMount() {
        activityStore.getActivities(this.getUserId(), 1, this._PAGESIZE);
    }

    componentWillUnmount() {
        activityStore.resetStore();
    }

    getUserId() {
        return this.props.match.params.userId;
    }

    loadMoreActivities = () => {
        activityStore.getActivities(this.getUserId(), activityStore.activities!.currentPage + 1, this._PAGESIZE)
    }

    render() {
        const pagedResults = activityStore.activities;
        if (!pagedResults) return null;

        const columns = [
            {
                Header: <TableHeader value={languageStore.get('when')} />,
                id: 'operationTime',
                accessor: (d: any) => formatDate(d.operationTime, true),
                width: 150
            },
            {
                Header: <TableHeader value='IP' />,
                accessor: 'ip',
                width: 150
            },
            {
                Header: <TableHeader value={languageStore.get('details')} />,
                accessor: 'activityDetails'
            },
            {
                Header: <TableHeader value={languageStore.get('parameters')} />,
                accessor: 'parameters'
            },
            {
                Header: <TableHeader value={languageStore.get('status')} />,
                id: 'statusCode',
                width: 50,
                accessor: (d: any) => {
                    if (d.statusCode === 200 || d.statusCode === 201) {
                        return d.statusCode;
                    }

                    return <span className='text-red'>{d.statusCode}</span>;
                }
            }
        ]


        return (
            <div>
                <Breadcrumb
                    backlinkTitle={languageStore.get('superAdmin')}
                    backlinkFunc={() => navigationStore.goBack()}
                    title={`${languageStore.get('user')} ${languageStore.get('log', false)}`} />
                <div className='mt-10 mx-auto'>

                    <ReactTable
                        data={pagedResults.results.slice()}
                        columns={columns}
                        className='ReactTable-readonly'
                        defaultPageSize={99999}
                        sortable={false}
                        manual
                        showPagination={false}
                        NoDataComponent={() => null}
                        minRows={0} />

                    {
                        pagedResults.currentPage < pagedResults.pageCount &&
                        <div className='text-center mt-12'>
                            <button
                                className='px-8 py-2 border rounded hover:bg-grey-lightest'
                                onClick={this.loadMoreActivities}>
                                {languageStore.get('loadMoreEntries')}
                            </button>
                        </div>
                    }
                </div>
            </div>
        );
    }

}

export default ActivitiesPage;