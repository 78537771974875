import React from 'react';
import { observer } from 'mobx-react';
import { ContractDocumentInterface, DocumentSigningStatusEnum, languageStore } from 'stores';
import { formatDate } from 'helpers/DateHelper';
import { AddoHelper } from 'helpers/AddoHelper';
import _ from 'lodash';

interface Props {
    onCancelSigning: (e: React.MouseEvent, signingId: string) => void,
    onGetSigningStatus: (e: React.MouseEvent, signingId: string) => void,
    document: ContractDocumentInterface
}

@observer class SigningStatus extends React.Component<Props> {

    render() {
        const { document, onCancelSigning, onGetSigningStatus } = this.props;

        if (document.signings && document.signings.length > 0) {
            const orderedSignings = _.orderBy(document.signings, 'createdOn', 'desc');
            const lastSigning = orderedSignings[0];

            let className = '';

            switch (lastSigning.addoSigningStatus) {
                case DocumentSigningStatusEnum.Created:
                    className = 'text-orange';
                    break;
                case DocumentSigningStatusEnum.Expired:
                case DocumentSigningStatusEnum.Failed:
                case DocumentSigningStatusEnum.Rejected:
                case DocumentSigningStatusEnum.Stopped:
                    className = 'text-red-dark';
                    break;
                // case DocumentSigningStatusEnum.Completed:
                //     className = 'font-bold text-green-dark';
                //     break;
            }

            return (
                <span className={className}>
                    {`${AddoHelper.SigningStatusToText(lastSigning.addoSigningStatus)} (${formatDate(lastSigning.modifiedOn || lastSigning.createdOn, true)})`}
                    {
                        lastSigning.addoSigningStatus === DocumentSigningStatusEnum.Started &&
                        <>
                            <a href=" " onClick={(e) => onCancelSigning(e, lastSigning.id)} className='ml-2 text-xs text-red-lighter'>[{languageStore.get('cancel')}]</a>
                            <a href=" " onClick={(e) => onGetSigningStatus(e, lastSigning.id)} className='ml-2 text-xs text-grey-darker'>[{languageStore.get('status')}]</a>
                        </>

                    }
                </span>
            )
        }
        else {
            if (document.originalDocumentId) {
                return <span className='font-bold text-green-dark'>{languageStore.get('signed')}</span>
            }
            else return null;
        }
    }
}

export default SigningStatus;