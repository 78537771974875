import { BaseStore } from './BaseStore';
import { observable, action, makeObservable } from 'mobx';
import { CurrencyInterface } from './CurrencyStore';

export interface CountryInterface {
    "id": string,
    "isoCode": string,
    "name": string,
    "currencyId": string,
    "currency": CurrencyInterface
}

export class CountryStore extends BaseStore {
    @observable countries: Array<CountryInterface> = [];

    constructor() {
        super();

        makeObservable(this);
    }

    @action
    getCountries() {
        return this.get('countries', 'countries', false);
    }
}

export const countryStore = new CountryStore();