import * as React from 'react';
import { observer } from 'mobx-react';
import ContractDetailsItem from 'components/ContractDetailsItem';
import { languageStore, customerStore, CustomerInterface } from 'stores';
import { FieldState } from 'formstate';
import * as _ from 'lodash';

interface Props {
    customerId: string | null,
    fieldState: FieldState<any>,
    editMode?: boolean,
    onBlur?: (value: any) => void
}

@observer class CustomerVatNumber extends React.Component<Props> {

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        if (nextProps.customerId !== this.props.customerId) {
            const { fieldState } = this.props;

            if (nextProps.customerId) {
                const customer = _.find(customerStore.customers, { id: nextProps.customerId }) as CustomerInterface;
                const vatNumber = customer ? customer.vat : '';
                fieldState.onChange(vatNumber);
            }
            else {
                fieldState.onChange('');
            }
        }
    }

    render() {
        const { fieldState, editMode, onBlur } = this.props;

        return <ContractDetailsItem
            label={languageStore.get('vatNumber')}
            id='vatNumber'
            onBlur={() => onBlur ? onBlur(fieldState.$) : undefined}
            fieldState={fieldState}
            editMode={editMode}
        />
    }

}

export default CustomerVatNumber;