import { userStore, UserInterface, documentStore } from 'stores';

export const MAX_FILE_SIZE = 94371840; // 90 mb
export const MIN_FILE_SIZE = 5120;

export function getAllowedFileTypesBySignedInCompany() {
    const signedInUser = userStore.signedInUser as UserInterface;
    const company = signedInUser.company;
    const allowedFileTypes: Array<string> = [];


    if (!signedInUser) return allowedFileTypes;

    if (!company.dontAllowDocs) {
        allowedFileTypes.push('doc', 'docx');
    }

    if (!company.dontAllowExcel) {
        allowedFileTypes.push('xls', 'xlsx');
    }

    if (!company.dontAllowImages) {
        allowedFileTypes.push('png', 'jpeg', 'jpg');
    }

    if (!company.dontAllowPDF) {
        allowedFileTypes.push('pdf');
    }

    allowedFileTypes.push('sdo');

    return allowedFileTypes;
}

export function humanFileSize(size: number) {
    var i = Math.floor(Math.log(size) / Math.log(1024)) as number;
    const x = (size / Math.pow(1024, i)).toFixed(2) as any;
    return x * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
}

export async function uploadFile(file: File) {
    return new Promise(async (resolve, reject) => {
        const maxFileSize = MAX_FILE_SIZE;
        const minFileSize = MIN_FILE_SIZE;

        if (file.size > maxFileSize) {
            reject(`The file you are trying to upload is too big. Max file size is ${humanFileSize(maxFileSize)}.`);
        }

        if (file.size < minFileSize) {
            reject(`The file you are trying to upload is too small. Minimum file size is ${humanFileSize(minFileSize)}.`);
        }

        else {
            var ext = file!.name!.split('.')!.pop()!.toLowerCase();
            const allowedFileTypes = getAllowedFileTypesBySignedInCompany();

            if (allowedFileTypes.indexOf(ext) !== -1) {
                let formData = new FormData();
                formData.append('file', file);
                const document = await documentStore.uploadFile(formData);
                resolve(document);
                // onSubmit(document);
            }
            else {
                reject("We are not currently allowing files with the extension of " + ext + ".");
            }
        }
    })

}