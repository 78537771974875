import { languageStore } from 'stores';
import * as _ from 'lodash';
import { DropdownOptionInterface } from 'components/Forms/Dropdown';

export class ContractStatusHelper {

    static BasicContractStatus(includeDraft: boolean = false) {

        const values = [
            {
                value: 2,
                name: languageStore.get('offer')
            },
            {
                value: 3,
                name: languageStore.get('accepted')
            },
            {
                value: 4,
                name: languageStore.get('discontinued')
            },
            {
                value: 5,
                name: languageStore.get('deleted')
            },
            {
                value: 6,
                name: languageStore.get('renetiation')
            },
            {
                value: 7,
                name: languageStore.get('terminated')
            },
        ] as Array<DropdownOptionInterface>;

        if (includeDraft) {
            values.unshift({
                value: 1,
                name: languageStore.get('draft')
            })
        }

        return values;
    }

    static StaffContractStatus(includeDraft: boolean = false) {
        const values = [
            {
                value: 1,
                name: languageStore.get('hired')
            },
            {
                value: 2,
                name: languageStore.get('dismissed')
            },
            {
                value: 3,
                name: languageStore.get('rejected')
            },
            {
                value: 4,
                name: languageStore.get('resigned')
            },
            {
                value: 5,
                name: languageStore.get('cancelled')
            },
            {
                value: 6,
                name: languageStore.get('applicant')
            }
        ] as Array<DropdownOptionInterface>

        if (includeDraft) {
            values.unshift({
                value: 1,
                name: languageStore.get('draft')
            })
        }

        return values;
    }

    static ContractName(data: Array<DropdownOptionInterface>, value: string) {
        const val = _.find(data, { value: value }) as DropdownOptionInterface;
        return val ? val.name : null
    }

}