import Country from "components/Forms/Country";
import Form from "components/Forms/Form";
import Input from "components/Forms/Input";
import { FieldState, FormState } from "formstate";
import { required } from "helpers/FormValidation";
import { observer } from "mobx-react";
import { languageStore, locationStore, navigationStore, toastStore, userStore } from "stores";
import useCompanyLocationByParams from "../useCompanyLocationByParams";

const General = observer(() => {

    const location = useCompanyLocationByParams();
    if (!location) return null;

    const form = new FormState({
        name: new FieldState(location?.name).validators(required),
        countryId: new FieldState(location.countryId).validators(required)
    })

    const updateLocation = async () => {

        location.name = form.$.name.$;
        location.countryId = form.$.countryId.$;

        await locationStore.updateCompanyLocation(location);

        toastStore.addToast({
            level: 'success',
            title: languageStore.get('success'),
            message: languageStore.get('changesSaved')
        })
    }

    const deleteLocation = async () => {
        if (!window.confirm(`${languageStore.get('areYouSureYouWantToDelete')}?`)) return;

        await locationStore.deleteCompanyLocation(userStore.signedInUser.company.id!, location.id);
        navigationStore.push('/admin/locations');
    }

    return (
        <div className='mt-4'>
            <Form formState={form} onValidSubmit={updateLocation}>
                <div className='w-64'>
                    <Input
                        fieldState={form.$.name}
                        id='name'
                        label={languageStore.get('name')}
                    />

                    <Country
                        fieldState={form.$.countryId}
                        editMode={true} />
                </div>

                <button
                    className='bg-comablue text-white px-8 py-2 rounded'>
                    {languageStore.get('saveChanges')}
                </button>
                <button
                    className='ml-4 bg-red-dark text-white px-4 py-2 rounded inline'
                    type='button'
                    onClick={() => deleteLocation()}>
                    {languageStore.get('delete')}
                </button>
            </Form>
        </div>
    )
})

export default General;