import * as React from 'react';
import { observer } from 'mobx-react';
import Menu from 'components/PurchaseContractDetailsPage/Menu';
import { languageStore, customFieldStore, DashboardContractTypeEnum, userStore } from 'stores';
import Overview from 'components/PurchaseContractDetailsPage/Overview';
import { currentPurchaseContractStore as store } from 'stores';
import Supplier from './Supplier';
import FinanceAccount from 'components/Forms/FinanceAccount';
import Services from 'components/Forms/Services';
import Alarms from 'components/Alarms';
import VerifiedBy from 'components/Forms/VerifiedBy';
import Correspondence from './Correspondence';
import Notes from 'components/Notes';
import Documents from './Documents';
import SectionEnum from './SectionEnum';
import ContractMetaStatus from 'components/Forms/ContractMetaStatus';
import ContractDetailsTop from 'components/ContractDetailsTop';
import ContractToContract from 'components/ContractToContract';
import ContractDetailsContainer from 'components/ContractDetailsContainer';
import ContractDetailsPage from 'components/ContractDetailsPage';
import PurchaseContractSum from './PurchaseContractSum';
import CustomFields from 'components/CustomFields';

interface Props {
    location: any
    match?: any
}

@observer class PurchaseContractDetailsPage extends React.Component<Props> {
    
    render() {
        return (
            <ContractDetailsPage store={store} contractId={this.props.match.params.id}>

                {
                    store.contract &&
                    <>
                        <ContractDetailsTop
                            helpUrl='https://help.comasystem.dk/purchase/new-contract'
                            backlinkTitle={languageStore.get('purchaseContracts')}
                            title={languageStore.get('showPurchaseContract')}
                            store={store} />

                        <ContractDetailsContainer>

                            <Menu />
                            <Overview />
                            <Supplier />
                            <Notes store={store} hash={SectionEnum.NOTES} />
                            <FinanceAccount
                                onCreate={store.createFinanceAccount}
                                onUpdate={store.updateFinanceAccount}
                                onDelete={store.deleteFinanceAccount}
                                financeAccounts={store.contract!.contractFinanceAccounts}
                                store={store}
                                hash={SectionEnum.FINANCE_ACCOUNT} />
                            <Services
                                onCreate={store.createFinancialService}
                                onUpdate={store.updateFinancialService}
                                onDelete={store.deleteFinancialService}
                                services={store.contract!.financialServices}
                                store={store}
                                hash={SectionEnum.SERVICES} />

                            <PurchaseContractSum />
                            <Alarms store={store} hash={SectionEnum.EXTRA_ALARMS} />
                            <VerifiedBy store={store} hash={SectionEnum.VERIFIED_BY} />
                            <Documents />
                            <ContractToContract store={store} hash={SectionEnum.LINKED_CONTRACTS} />
                            <Correspondence />

                            {
                                (userStore.signedInUser!.company.allowCustomFields && customFieldStore.hasCustomFields[DashboardContractTypeEnum.PURCHASE]) &&
                                <CustomFields store={store} hash={SectionEnum.CUSTOM_FIELDS} />
                            }

                            <ContractMetaStatus
                                type={DashboardContractTypeEnum.PURCHASE}
                                store={store}
                                hash={SectionEnum.STATUS}
                            />

                        </ContractDetailsContainer>
                    </>
                }

            </ContractDetailsPage>
        )
    }

}

export default PurchaseContractDetailsPage;