import { action, observable, makeObservable } from "mobx";
import { BaseStore } from "./BaseStore";

export interface EconomicCustomerInterface {
    "address": string,
    "city": string,
    "corporateIdentificationNumber": string,
    "country": string,
    "currency": string,
    "customerGroup": {
        "customerGroupNumber": number
    },
    "customerNumber": number,
    "email": string,
    "mobilePhone": string,
    "name": string,
    "paymentTerms": {
        "paymentTermsNumber": number,
        "daysOfCredit": number
    },
    "vatZone": { 
        "vatZoneNumber": number, 
        "enabledForCustomer": boolean
    },
    "zip": string
}

export interface EconomicCustomerContactInterface {
    "customerContactNumber": number,
    "name": string
}

export interface EconomicEmployeeInterface {
    "employeeNumber": number,
    "name": string
}

export class EconomicStore extends BaseStore {
    @observable customers: Array<EconomicCustomerInterface> = [];
    @observable customerContacts: Array<EconomicCustomerContactInterface> = [];
    @observable employees: Array<EconomicEmployeeInterface> = [];

    constructor() {
        super();

        makeObservable(this);
    }

    @action
    resetStore() {
        this.customers = [];
        this.customerContacts = [];
        this.employees = [];
    }

    @action
    getCustomers() {
        return this.get(`economic/customers`, 'customers');
    }

    @action
    getCustomerContacts(customerId: string) {
        return this.get(`economic/customers/${customerId}/contacts`, 'customerContacts');
    }

    @action
    getEmployees() {
        return this.get(`economic/employees`, 'employees');
    }
}

export const economicStore = new EconomicStore();