import * as React from 'react';
import { observer } from 'mobx-react';

interface Props {
    title: string,
    onClick: Function,
    className?: string
}

@observer class InboxButton extends React.Component<Props> {

    render() {
        const { title, onClick, className } = this.props;

        return (
            <a
                href=' '
                onClick={e => onClick(e)}
                className={`
                no-underline
                block 
                text-comablue
                w-64 
                border 
                rounded 
                px-4 
                py-2 
                mb-4 
                text-center
                hover:bg-grey-lightest
                cursor-pointer
                ${className}
                `}>
                {title}
            </a>
        )
    }
}

export default InboxButton;