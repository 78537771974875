import * as React from 'react';
import { observer } from 'mobx-react';
import ContractDetailsItem from 'components/ContractDetailsItem';
import { languageStore, supplierStore, SupplierInterface } from 'stores';
import { FieldState } from 'formstate';
import * as _ from 'lodash';

interface Props {
    supplierId: string | null,
    fieldState: FieldState<any>,
    editMode?: boolean
}

@observer class SupplierName extends React.Component<Props> {

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        if (nextProps.supplierId !== this.props.supplierId) {
            const { fieldState } = this.props;

            if (nextProps.supplierId) {
                const supplier = _.find(supplierStore.suppliers, { id: nextProps.supplierId }) as SupplierInterface;
                const supplierName = supplier ? supplier.name : '';
                fieldState.onChange(supplierName);
            }
            else {
                fieldState.onChange('');
            }
        }
    }

    render() {
        const { fieldState, editMode } = this.props;

        return <ContractDetailsItem
            label={languageStore.get('companyName')}
            id='supplierName'
            fieldState={fieldState}
            editMode={editMode}
        />
    }

}

export default SupplierName;