import { BaseReportStore, ReportCompareTypeEnum, ReportCriteriaInterface } from './BaseReportStore';
import { action, makeObservable } from 'mobx';
import { ContractStatusHelper } from 'helpers/ContractStatusHelper';
import { formatDate } from 'helpers/DateHelper';
import { reportCriteriaBuilder } from 'helpers/ReportCriteriaBuilder';
import { ServiceStatusEnum } from './CurrentServiceContractStore';
import * as _ from 'lodash';
import { ContractTypeHelper } from 'helpers/ContractTypeHelper';
import { customFieldStore } from './CustomFieldStore';
import { DashboardContractTypeEnum } from './DashboardStore';

export class ServiceReportStore extends BaseReportStore {

    constructor() {
        super();
        makeObservable(this);
        this.contractEndpoint = 'service';
    }

    @action
    async setData() {
        const status = ContractStatusHelper.BasicContractStatus(true);
        const contractTypes = ContractTypeHelper.ContractTypes();

        const data: Array<ReportCriteriaInterface> = [
            {
                label: 'comaId',
                requestField: 'id',
                compareType: ReportCompareTypeEnum.TEXT,
                autoCompleteOnFocus: true
            },
            {
                label: 'serialNumber',
                requestField: 'serialNumber',
                compareType: ReportCompareTypeEnum.TEXT,
                autoCompleteOnFocus: true
            },
            {
                label: 'dealNumber',
                requestField: 'dealNumber',
                compareType: ReportCompareTypeEnum.TEXT,
                autoCompleteOnFocus: true
            },
            {
                label: 'department',
                requestField: 'department.name',
                compareType: ReportCompareTypeEnum.TEXT,
                autoCompleteOnFocus: true
            },
            {
                label: 'location',
                requestField: 'department.location.name',
                compareType: ReportCompareTypeEnum.TEXT,
                autoCompleteOnFocus: true
            },
            {
                label: 'contractType',
                requestField: 'contractType.name',
                customConverter: (d: any) => d.contractType ? ContractTypeHelper.ContractTypeName(contractTypes, d.contractType.name) : null,
                compareType: ReportCompareTypeEnum.DROPDOWN,
                dropdownOptions: contractTypes,
                tableVisibility: true,
                autoCompleteOnFocus: true
            },
            {
                label: 'category',
                requestField: 'category.name',
                compareType: ReportCompareTypeEnum.TEXT,
                tableVisibility: true,
                autoCompleteOnFocus: true
            },
            {
                label: 'subCategory',
                requestField: 'subCategory.name',
                compareType: ReportCompareTypeEnum.TEXT,
                tableVisibility: true,
                autoCompleteOnFocus: true
            },
            {
                label: 'status',
                requestField: 'contractStatus',
                customConverter: (d: any) => ContractStatusHelper.ContractName(status, d.contractStatus),
                compareType: ReportCompareTypeEnum.DROPDOWN,
                dropdownOptions: status
            },
            {
                label: 'customer',
                requestField: 'customer.name',
                compareType: ReportCompareTypeEnum.TEXT,
                autoCompleteOnFocus: true
            },
            {
                label: 'vatNumber',
                requestField: 'customer.vAT',
                compareType: ReportCompareTypeEnum.TEXT,
            },
            {
                label: 'creationDate',
                requestField: 'contractDateCreated',
                compareType: ReportCompareTypeEnum.DATE,
                customConverter: (d: any) => formatDate(d.contractDateCreated)
            },
            {
                label: 'serviceDate',
                requestField: 'serviceDate',
                compareType: ReportCompareTypeEnum.DATE,
                customConverter: (d: any) => formatDate(d.serviceDate)
            },
            {
                label: 'expirationDate',
                requestField: 'expirationDate',
                compareType: ReportCompareTypeEnum.DATE,
                customConverter: (d: any) => formatDate(d.expirationDate)
            },
            {
                label: 'responsible',
                requestField: 'responsibleUser.fullName',
                compareType: ReportCompareTypeEnum.TEXT,
                tableVisibility: true,
                autoCompleteOnFocus: true
            },
            {
                label: 'notes',
                requestField: 'notes.title',
                compareType: ReportCompareTypeEnum.TEXT
            },
            {
                label: 'description',
                requestField: 'description',
                compareType: ReportCompareTypeEnum.TEXT
            },
            {
                label: 'documentType',
                requestField: 'contractDocuments.documentType.name',
                compareType: ReportCompareTypeEnum.TEXT,
                customConverter: (d: any) => d.contractDocuments ? d.contractDocuments?.map((cd: any) => cd.documentType?.name || '').filter((x: any) => x).join(', ') : '',
                autoCompleteOnFocus: true
            },
            {
                label: 'currency',
                requestField: 'currency.code',
                compareType: ReportCompareTypeEnum.TEXT
            },
            {
                label: 'contractSum',
                requestField: 'contractSum',
                compareType: ReportCompareTypeEnum.NUMBER,
                customConverter: (d: any) => Number(d.contractSum).toLocaleString()
            },
        ]

        const customFields = this.getCustomFieldsAsReportCriteriaInterfaces(await customFieldStore.getCustomFields(DashboardContractTypeEnum.SERVICE));

        this.data = [...data, ...customFields];
    }

    @action
    private setCustomActiveServicesData() {
        this.data = [
            {
                label: 'name',
                requestField: 'serviceName',
                tableVisibility: true,
                compareType: ReportCompareTypeEnum.NONE
            },
            {
                label: 'placement',
                requestField: 'placement',
                tableVisibility: true,
                compareType: ReportCompareTypeEnum.NONE
            },
            {
                label: 'status',
                requestField: 'serviceStatus',
                tableVisibility: true,
                compareType: ReportCompareTypeEnum.NONE,
                customConverter: (d: any) => {
                    switch (d.serviceStatus) {
                        case ServiceStatusEnum.AGREED: return 'agreed';
                        case ServiceStatusEnum.DELETED_OR_HIDDEN: return 'deletedOrHidden';
                        default: return null;
                    }
                }
            },
            {
                label: 'location',
                requestField: 'locationName',
                tableVisibility: true,
                compareType: ReportCompareTypeEnum.NONE
            }
        ]
    }

    @action
    async getActiveServicesData() {
        this.setCustomActiveServicesData();
        reportCriteriaBuilder.addQueryString('serviceContractServices.service.name', '*');
        const rawData = await reportCriteriaBuilder.executeQuery(this.contractEndpoint);

        const prettyData: any = [];
        rawData.forEach((contract) => {
            const locationName = contract.department && contract.department.location ? contract.department.location.name : null;
            contract.serviceContractServices.forEach((service: any) => {
                service.id = contract.id // link to contract, when clicking
                service.locationName = locationName; // Add location name from contract to each row
                prettyData.push(service);
            })
        })

        this.results = _.orderBy(prettyData, 'name');
    }

}

export const serviceReportStore = new ServiceReportStore();