import Form from "components/Forms/Form";
import Input from "components/Forms/Input";
import { Modal, ModalColumn } from "components/Modal";
import DeleteButton from "components/Modal/DeleteButton";
import { FieldState, FormState } from "formstate";
import { required } from "helpers/FormValidation";
import { languageStore, LocationInterface, locationStore } from "stores";

interface Props {
    location: LocationInterface,
    onClose: () => void
}

const UpdateLocationModal = (props: Props) => {

    const form = new FormState({
        name: new FieldState(props.location.name).validators(required)
    })

    const updateLocation = async () => {
        const location = props.location;
        location.name = form.$.name.$;
        await locationStore.updateCompanyLocation(location);

        props.onClose();
    }

    const deleteLocation = async () => {
        if (!window.confirm(`${languageStore.get('areYouSureYouWantToDelete')}?`)) return;

        await locationStore.deleteCompanyLocation(props.location.companyId, props.location.id);

        props.onClose();
    }

    return (
        <Form formState={form} onValidSubmit={updateLocation}>
            <Modal
                title={languageStore.get('location')}
                primaryButton={{ title: languageStore.get('saveChanges') }}
                secondaryButton={{ title: languageStore.get('cancel'), onClick: props.onClose }}>

                <ModalColumn>
                    <Input
                        id='name'
                        label={languageStore.get('name')}
                        fieldState={form.$.name}
                        type='text' />
                </ModalColumn>

                <DeleteButton onDelete={deleteLocation} />

            </Modal>
        </Form>
    )

}

export default UpdateLocationModal;