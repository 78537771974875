import React from 'react';
import { observer } from 'mobx-react';
import { CompanyInterface, inboxesStore, inboxStore, userStore } from 'stores';
import SidebarNavigation from './SidebarNavigation';
import InboxMessages from './InboxMessages';

interface State {
    selectedInboxId: string | null
}

@observer class InboxPage extends React.Component<any, State> {

    constructor(props: any) {
        super(props);

        this.state = {
            selectedInboxId: null
        }
    }

    async componentDidMount() {
        const company = userStore.signedInUser!.company as CompanyInterface;
        if (company.allowInbox) {
            const res = await inboxesStore.getInboxes();
            if (res.results?.length > 0) {
                this.onInboxSelected(res.results[0].id);
            }
        }
    }

    onInboxSelected = async (inboxId: string | null) => {
        inboxStore.resetStore();

        if (inboxId) {
            await inboxStore.getInbox(inboxId);
            inboxStore.getMessages(inboxId, 1, 9999);
        }

        this.setState({ selectedInboxId: inboxId });
    }

    render() {
        const { selectedInboxId } = this.state;
        const inboxes = inboxesStore.inboxes;
        const messages = inboxStore.inbox?.messages;

        return (
            <div className='flex mt-8'>
                <SidebarNavigation
                    selectedInboxId={selectedInboxId}
                    onInboxSelected={this.onInboxSelected}
                    inboxes={inboxes?.results || []}
                />
                <div className='flex-1'>
                    {
                        (selectedInboxId && messages) &&
                        <InboxMessages
                            inboxId={selectedInboxId}
                            messages={messages}
                        />
                    }
                </div>
            </div>

        )
    }
}

export default InboxPage;