import * as React from 'react';
import { observer } from 'mobx-react';
import { FormState, FieldState } from 'formstate';
import { benefitStore, languageStore, locationStore, LocationInterface, BenefitInterface, userStore } from 'stores';
import TableHeader from 'components/Table/TableHeader';
import * as _ from 'lodash';
import { ModalColumn } from 'components/Modal';
import ContractDetailsItem from 'components/ContractDetailsItem';
import DetailsPage from '../DetailsPage';
import Location from 'components/Forms/Location';
import { required } from 'helpers/FormValidation';

@observer class BenefitsPage extends React.Component {

    form = new FormState({
        id: new FieldState<null | string>(null),
        name: new FieldState(null).validators(required),
        locationId: new FieldState(null).validators(required)
    })

    componentDidMount() {
        const companyId = userStore.signedInUser.company.id;
        locationStore.getLocationsByCompany(companyId);

        benefitStore.getAllBenefits();
    }

    onDeleteBenefit = async (benefitId: string) => {
        const ben = _.find(benefitStore.allBenefits, { id: benefitId }) as BenefitInterface;
        const locationId = ben.locationId;

        await benefitStore.deleteBenefit(locationId, benefitId);
    }

    render() {
        const data = benefitStore.allBenefits || [];
        const locations = locationStore.locations || [];
        const form = this.form.$;
        const isNewEntity = !!!form.id.$;

        const columns = [
            {
                Header: <TableHeader value={languageStore.get('name')} />,
                accessor: 'name'
            },
            {
                Header: <TableHeader value={languageStore.get('location')} />,
                id: 'locationId',
                accessor: (d: any) => {
                    const loc = _.find(locations, { id: d.locationId }) as LocationInterface;
                    return loc ? loc.name : '-';
                }
            },
        ]

        return (
            <DetailsPage
                fullWidth={true}
                generalTitle={languageStore.get('perks')}
                modalTitle={languageStore.get('perks')}
                form={this.form}
                onUpdate={(benefit: BenefitInterface) => benefitStore.updateBenefit(benefit)}
                onSubmit={(benefit: BenefitInterface) => benefitStore.createBenefit(benefit)}
                onDelete={(id: string) => this.onDeleteBenefit(id)}
                collection={data}
                columns={columns}>

                <ModalColumn>

                    <ContractDetailsItem
                        fieldState={form.name}
                        label={languageStore.get('name')}
                        id='name'
                        editMode={true} />

                    {
                        isNewEntity &&
                        <Location fieldState={form.locationId} editMode={true} />
                    }

                </ModalColumn>

            </DetailsPage>
        )
    }

}

export default BenefitsPage;