import { BaseStore } from './BaseStore';
import { observable, action, makeObservable } from 'mobx';
import { PagingResult } from './BaseContractsStore';
import { ContractDocumentInterface } from './CurrentContractStore';
import request from 'api/request';
import { DepartmentInterface } from './DepartmentStore';
import _ from 'lodash';
import { RoleInterface } from './RolesStore';

export interface InboxDomainInterface {
    "id": string,
    "name": string
}

export interface InboxMessageInterface {
    "id": string,
    "inboxId": string
    "createdOn": string,
    "sender": string,
    "subject": string,
    "documentId": string,
    "document": ContractDocumentInterface,
}

export interface InboxUsersWithAccessInterface {
    "id": string,
    "user": {
        "id": string,
        "fullName": string,
        "companyId": string,
        "departments": Array<DepartmentInterface>
    },
    "isInboxAdmin": boolean,
    "inboxId": string
}

export interface InboxInterface {
    "id": string,
    "name": string,
    "daysBeforeMessageDelete": number | null,
    "isPublic": boolean,
    "email": string,
    "domains": Array<InboxDomainInterface>,
    "requiredRoles": Array<RoleInterface>,
    "usersWithAccess": Array<InboxUsersWithAccessInterface>,
    "messages": PagingResult<InboxMessageInterface>
}

export class InboxStore extends BaseStore {
    @observable inbox: InboxInterface | null = null;

    constructor() {
        super();

        makeObservable(this);
    }

    @action
    getInbox(inboxId: string) {
        return this.get(`inboxes/${inboxId}`, 'inbox');
    }

    @action
    updateInbox(inbox: InboxInterface) {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await request.put(`inboxes/${inbox.id}`, inbox);
                if (res.data) {
                    this.inbox = res.data;
                    resolve(res.data);
                } else reject();
            }
            catch (error) {
                reject(error);
            }
        })
    }

    getMessages(inboxId: string, pagenumber: number, pagesize: number): Promise<void> {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await request.get(`inboxes/${inboxId}/messages?pagenumber=${pagenumber}&pagesize=${pagesize}`);
                if (this.inbox && res.data) {
                    if (pagenumber === 1) {
                        this.inbox.messages = res.data;
                    }
                    else {
                        const data = this.inbox.messages!.results;
                        const newData = data.concat(res.data.results);
                        this.inbox.messages = res.data;
                        this.inbox.messages.results = newData;
                    }

                    resolve();
                }
            }
            catch (error) {
                reject(error);
            }
        });
    }

    @action
    updateDomains(inboxId: string, domains: Array<string>) {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await request.put(`inboxes/${inboxId}/domains`, domains.map((d) => ({
                    name: d
                })));
                if (res.data) {
                    if (this.inbox) {
                        this.inbox.domains = res.data;
                    }
                    resolve(res.data);
                } else reject();
            }
            catch (error) {
                reject(error);
            }
        })
    }

    @action
    putRoles(inboxId: string, roles: Array<RoleInterface>) {
        return new Promise(async (resolve, reject) => {
            try {
                await request.put(`inboxes/${inboxId}/roles`, roles);
                this.inbox!.requiredRoles = roles || [];
                resolve(roles);
            }
            catch(error) {
                reject(error);
            }
        })
    }

    @action
    deleteMessage(inboxId: string, messageId: string) {
        return this.delete(`inboxes/${inboxId}/messages/${messageId}`, messageId, this.inbox ? this.inbox!.messages.results : [])
    }

    @action
    postUser(inboxId: string, userId: string, inboxAdmin: boolean = false) {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await request.post(`inboxes/${inboxId}/users`, {
                    isInboxAdmin: inboxAdmin,
                    user: {
                        id: userId
                    }
                });

                const user = res.data;

                if (this.inbox) {
                    this.inbox.usersWithAccess.push(user);
                }

                resolve(user);
            }
            catch (error) {
                reject(error);
            }

        })
    }

    @action
    putUser(inboxId: string, user: InboxUsersWithAccessInterface) {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await request.put(`inboxes/${inboxId}/users/${user.id}`, user);
                const updatedUser = res.data;

                if (this.inbox) {
                    const index = _.findIndex(this.inbox.usersWithAccess, { id: updatedUser.id });
                    if (index !== undefined) {
                        this.inbox.usersWithAccess.splice(index, 1, updatedUser);
                    }
                }

                resolve(updatedUser);
            }
            catch (error) {
                reject(error);
            }
        })
    }

    @action
    deleteUser(inboxId: string, userId: string) {
        return this.delete(`inboxes/${inboxId}/users/${userId}`, userId, this.inbox?.usersWithAccess || []);
    }

    @action
    deleteInbox(inboxId: string) {
        return this.delete(`inboxes/${inboxId}`, inboxId, []);
    }

    @action
    resetStore() {
        this.inbox = null;
    }
}

export const inboxStore = new InboxStore();