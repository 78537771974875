import * as React from 'react';
import { observer } from 'mobx-react';
import NotificationImg from './notifications.svg';
import NotificationFilledImg from './notifications-filled.svg';
import { userStore, notificationStore, languageStore } from 'stores';
import * as Moment from 'moment-timezone';
import ExpandIcon, { ExpandIconItemInterface } from '../ExpandIcon';
import { notificationItemsToExpandIcons } from './NotificationHelper';

import './style.css';

interface State {
    fetchingData: boolean,
    expanded: boolean
}

@observer class Notifications extends React.Component<any, State> {

    pageSize = 5;

    constructor(props: any) {
        super(props);

        this.state = {
            fetchingData: false,
            expanded: false
        }
    }

    onExpand = async () => {
        this.setState({ expanded: true, fetchingData: true });
        await notificationStore.getNotifications(1, this.pageSize);
        this.setState({ fetchingData: false });
        this.setLastseen();
    }

    onCollapse = async () => {
        notificationStore.resetStore();
        this.setState({ expanded: false });
    }

    setLastseen() {
        const now = Moment.utc(new Date());
        userStore.setLastseenNotifications(now);
    }

    showMoreItem() {
        if (notificationStore.notifications && notificationStore.notifications!.currentPage < notificationStore.notifications!.pageCount) {
            const item = {
                text: languageStore.get('showMoreNotifications') + '...',
                keepExpandedOnClick: true,
                bold: true,
                onClick: () => {
                    notificationStore.getNotifications(notificationStore.notifications!.currentPage + 1, this.pageSize);
                }
            } as ExpandIconItemInterface;

            return item;
        }

        return null;

    }

    render() {
        const { signedInUser } = userStore;
        const { fetchingData } = this.state;

        if (!signedInUser) return null;

        const hasUnseen = signedInUser.unseenNotifications > 0;

        const icon = hasUnseen ? NotificationFilledImg : NotificationImg;
        const notifications = notificationStore.notifications ? notificationStore.notifications.results : [];
        const items = notificationItemsToExpandIcons(notifications);

        if (!fetchingData && items.length === 0) {
            items.push({
                text: languageStore.get('noNotifications'),
                onClick: () => null
            })
        }
        else {
            const showMoreItem = this.showMoreItem();
            if (showMoreItem) {
                items.push(showMoreItem);
            }
        }

        return (
            <ExpandIcon
                // itemStyle={{ borderBottom: '1px solid #eaeaea' }}
                icon={icon}
                notificationCount={signedInUser.unseenNotifications}
                onCollapse={this.onCollapse}
                onExpand={this.onExpand}
                items={items} />

        )
    }
}

export default Notifications;