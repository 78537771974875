import Form from "components/Forms/Form";
import { FieldState, FormState } from "formstate";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { authStore, navigationStore, userStore, languageStore, toastStore, UserTwoFactorAuthTypeEnum } from "stores";
import logoImg from './logoColor.svg';
import QRCode from 'react-qr-code';
import Input from "components/Forms/Input";
import { required } from "helpers/FormValidation";

enum StepEnum {
    SELECT_TYPE,
    AUTHENTICATOR,
    MAIL
}

const ActivateTwoFactorPage = observer(() => {
    const [step, setStep] = useState(StepEnum.SELECT_TYPE);

    const form = new FormState({
        pinCode: new FieldState('').validators(required)
    })

    useEffect(() => {
        if (!authStore.isAuthenticated) {
            navigationStore.push('/login');
        }
        
        return () => {
            authStore.resetTwoFactorAuthSecret();
        }

    }, [])

    useEffect(() => {
        if (step === StepEnum.AUTHENTICATOR) {
            authStore.getTwoFactorAuthSecret(UserTwoFactorAuthTypeEnum.AUTHENTICATOR);
        }
        else if (step === StepEnum.MAIL) {
            authStore.getTwoFactorAuthSecret(UserTwoFactorAuthTypeEnum.MAIL);
        }
    }, [step])

    const onSubmit = async () => {
        const code = form.$.pinCode.$.replace(' ', '');

        const statusCode = await authStore.validateTwoFactorAuthCode(code);
        if (statusCode === 200) {
            await userStore.getSignedInUser();
            navigationStore.push('/');
        }
        else {
            toastStore.addError(languageStore.get('wrongCredentials'));
        }
    }

    const createQrUri = () => {
        const twoFactorAuthSecret = authStore.twoFactorAuthSecret;
        if (!twoFactorAuthSecret) return '';

        return `otpauth://totp/COMAsystem:${userStore.signedInUser?.email}?secret=${twoFactorAuthSecret}`;
    }

    const twoFactorAuthSecret = authStore.twoFactorAuthSecret;

    if (step === StepEnum.SELECT_TYPE) {
        return (
            <div className="font-sans text-sm rounded w-full max-w-lg mx-auto mt-20 px-8 pt-8 pb-8 mb-12">
                <img src={logoImg} alt="COMA System" className="mb-12" width={300} />
                <h1 className='mb-8'>{languageStore.get('activateTwoFactor')}</h1>
                <p className='mb-2'>
                    {languageStore.get('selectPreferredAuthenticationMethod')}:
                </p>
                <div>
                    <button
                        onClick={() => setStep(StepEnum.AUTHENTICATOR)}
                        type='button'
                        className='bg-grey-darkest hover:bg-grey-darker text-white py-2 px-8 rounded'>
                        App
                    </button>
                    <button
                        onClick={() => setStep(StepEnum.MAIL)}
                        type='button'
                        className='ml-4 mt-4 bg-grey-darkest hover:bg-grey-darker text-white py-2 px-8 rounded'>
                        Mail
                    </button>
                </div>
            </div>
        )
    }

    if (step === StepEnum.AUTHENTICATOR) {
        return (
            <div className="font-sans text-sm rounded w-full max-w-lg mx-auto mt-20 px-8 pt-8 pb-8 mb-12">
                <img src={logoImg} alt="COMA System" className="mb-12" width={300} />
                <h1 className='mb-8'>{languageStore.get('activateTwoFactor')}</h1>
                <h1 className='mb-2'>1)</h1>
                <p>
                    Download <b>Microsoft Authenticator</b> {languageStore.get('appForYourSmartphone')}.
                </p>
                <div className='mt-2'>
                    <button
                        onClick={() => window.open('https://apps.apple.com/app/microsoft-authenticator/id983156458')}
                        type='button'
                        className='bg-grey-darkest hover:bg-grey-darker text-white py-2 px-8 rounded'>
                        App Store (iOS)
                    </button>
                    <button
                        onClick={() => window.open('https://go.microsoft.com/fwlink/p/?LinkID=2168850')}
                        type='button'
                        className='ml-4 mt-4 bg-grey-darkest hover:bg-grey-darker text-white py-2 px-8 rounded'>
                        Google Play (Android)
                    </button>
                </div>

                <h1 className='mb-2 mt-12'>2)</h1>
                <p>
                    {languageStore.get('openAppAndScanQRCode')}.
                </p>

                {
                    twoFactorAuthSecret &&
                    <div className='mt-12'>
                        <QRCode value={createQrUri()} />
                    </div>

                }

                <h1 className='mb-2 mt-16'>3)</h1>
                <p className='mb-8'>
                    {languageStore.get('enterDigitsFromAppToCompleteTwoFactorLogin')}:
                </p>
                <Form onValidSubmit={onSubmit} formState={form}>
                    <Input
                        className='max-w-xs'
                        label={languageStore.get('code')}
                        fieldState={form.$.pinCode}
                        id='code'
                    />
                    <button type='submit' className='bg-comablue-label hover:bg-comablue text-white py-2 px-8 rounded'>
                        {languageStore.get('login')}
                    </button>
                </Form>
            </div>
        )
    }

    if (step === StepEnum.MAIL) {
        return (
            <div className="font-sans text-sm rounded w-full max-w-lg mx-auto mt-20 px-8 pt-8 pb-8 mb-12">
                <img src={logoImg} alt="COMA System" className="mb-12" width={300} />
                <h1 className='mb-8'>{languageStore.get('activateTwoFactor')}</h1>
                <p className='mb-8'>
                    {languageStore.get('enterDigitsFromMailToCompleteTwoFactorLogin')}:
                </p>
                <Form onValidSubmit={onSubmit} formState={form}>
                    <Input
                        className='max-w-xs'
                        label={languageStore.get('code')}
                        fieldState={form.$.pinCode}
                        id='code'
                    />
                    <button type='submit' className='bg-comablue-label hover:bg-comablue text-white py-2 px-8 rounded'>
                        {languageStore.get('login')}
                    </button>
                </Form>
            </div>
        )
    }

    return null;

})

export default ActivateTwoFactorPage;