import * as React from 'react';
import { observer } from 'mobx-react';
import CompaniesTable from './CompaniesTable';
import StatsBox from './StatsBox';

@observer class CompaniesPage extends React.Component {

    render() {
        return (
            <div className='mt-10 mx-auto'>
                <StatsBox />

                <CompaniesTable />
            </div>
        );
    }

}

export default CompaniesPage;