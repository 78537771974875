import React from 'react';
import { observer } from 'mobx-react';
import { FieldState } from 'formstate';
import { economicStore } from 'stores/EconomicStore';
import ContractDropdownItem from 'components/ContractDropdownItem';
import { languageStore } from 'stores';

interface Props {
    fieldState: FieldState<any>
}

@observer class SelectEmployee extends React.Component<Props> {

    componentDidMount() {
        economicStore.getEmployees();
    }

    render() {
        const { fieldState } = this.props;
        const employees = economicStore.employees;

        const options = employees.map((employee) => ({
            value: employee.employeeNumber,
            name: employee.name
        }))

        return (
            <ContractDropdownItem
                label={`${languageStore.get('employee')} (COMA)`}
                placeholderText={languageStore.get('select')}
                id='economicSalesEmployeeId'
                fieldState={fieldState}
                options={options}
                editMode={true} />
        )
    }
}

export default SelectEmployee;