import { BaseContractsStore, ContractStatusEnum, ViewByEnum, ContractNoteInterface } from './BaseContractsStore';

export interface StaffContractInterface {
    "id"?: string,
    "name"?: string,
    "departmentId"?: string,
    "departmentName"?: string,
    "locationId"?: string,
    "locationName"?: string,
    "employmentDate"?: string,
    "contractStatus"?: ContractStatusEnum,
    "staffAgreementName"?: string,
    "notes"?: Array<ContractNoteInterface>,
    "hasDocuments": true
}

export class StaffContractsStore extends BaseContractsStore<StaffContractInterface> {

    constructor() {
        super();
        
        this.viewBy = ViewByEnum.ALPHABETICAL;
        this.contractEndpoint = 'staff';
    }

}

export const staffContractsStore = new StaffContractsStore();