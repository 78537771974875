import * as React from 'react';
import { observer } from 'mobx-react';
import StepContainer from './StepContainer';
import { languageStore, createUserStore, departmentStore, locationStore, DepartmentInterface, navigationStore } from 'stores';
import { FormState, FieldState } from 'formstate';
import SelectTrueFalse from 'components/Forms/SelectTrueFalse';
import * as _ from 'lodash';

interface DataInterface {
    location: string,
    departments: Array<DepartmentInterface>
}

interface Props {
    afterSubmit: Function,
    companyId: string,
    locationIds: Array<string> | null
}

@observer class DepartmentRightsStep extends React.Component<Props> {

    form = new FormState({}) as any;

    componentDidMount() {
        const { companyId } = this.props;

        departmentStore.getDepartmentsByCompany(companyId);
        locationStore.getLocationsByCompany(companyId);
    }

    onSubmit = async () => {

        const departments = [];

        for (let id in this.form.$) {
            let value = this.form.$[id] as FieldState<boolean>;

            if (value.$ === true) {
                departments.push(id)
            }
        }

        const userId = createUserStore.user!.id;
        const companyId = this.props.companyId;
        await createUserStore.putDepartments(companyId, userId, departments);

        this.props.afterSubmit();
    }

    render() {
        const { locationIds } = this.props;
        const departments = departmentStore.departments || [];
        const orderedDepartments = _.orderBy(departments, 'name');
        const form = this.form.$;

        const data: Array<DataInterface> = [];
        _.orderBy(locationStore.locations, 'name').forEach((location) => {
            const deps = _.filter(orderedDepartments, (od) => {
                if (!locationIds) return false;
                return od.locationId === location.id && locationIds.indexOf(od.locationId) !== -1
            });

            if (deps.length > 0) {
                data.push({
                    location: location.name,
                    departments: deps
                })
            }
        })

        return (
            <StepContainer
                backLinkFunc={() => navigationStore.goBack()}
                backlinkTitle={languageStore.get('users')}
                title={languageStore.get('departmentRights')}
                form={this.form}
                onSubmit={this.onSubmit}>

                <div className='ml-4'>
                    {
                        data.map((entity, index) => {

                            return (
                                <div key={index} className='mb-8'>
                                    <h2>
                                        {entity.location}
                                    </h2>
                                    {
                                        entity.departments.map((department, index) => {
                                            const field = new FieldState(false);
                                            form[department.id] = field;

                                            return (
                                                <div key={index}>
                                                    <SelectTrueFalse
                                                        marginTop='0'
                                                        marginBottom='0'
                                                        id={department.id}
                                                        fieldState={field}
                                                        label={department.name}
                                                        editMode={true} />
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            )
                        })
                    }
                </div>

            </StepContainer>
        )
    }

}

export default DepartmentRightsStep;