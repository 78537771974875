import React, { useState } from 'react';
import { CustomerInterface, customerStore, languageStore, navigationStore, SupplierInterface, supplierStore } from 'stores';
import TableHeader from 'components/Table/TableHeader';
import ReactTable from 'react-table';
import { observer } from "mobx-react";
import * as _ from 'lodash';

export enum CustomerSupplierEnum {
    CUSTOMER,
    SUPPLIER
}

interface Props {
    type: CustomerSupplierEnum
    data: Array<SupplierInterface> | Array<CustomerInterface>
}

const SuppliersCustomersTable = observer((props: Props) => {
    const [searchQuery, setSearchQuery] = useState<string>('');

    const gotoItem = (id: string) => {

        if (props.type === CustomerSupplierEnum.CUSTOMER) {
            navigationStore.push(`/admin/customers/${id}`);
        }
        else {
            navigationStore.push(`/admin/suppliers/${id}`);
        }
    }

    const onCreateSupplier = () => {
        const name = window.prompt(languageStore.get('name'));
        if (!name) return;

        if (props.type === CustomerSupplierEnum.SUPPLIER) {
            supplierStore.postSupplier({ name: name } as any);
        }
        else {
            customerStore.postCustomer({ name: name } as any);
        }
    }

    const getColumns = () => {
        const { type } = props;

        const columns = [
            {
                Header: <TableHeader value={languageStore.get('name')} />,
                id: 'name',
                accessor: 'name'
            },
            {
                Header: <TableHeader value={languageStore.get('vatNumber')} />,
                id: 'vat',
                accessor: 'vat'
            }
        ]

        if (type === CustomerSupplierEnum.CUSTOMER) {
            columns.push({
                Header: <TableHeader value={languageStore.get('customerNumber')} />,
                id: 'customerNumber',
                accessor: 'customerNumber'
            })
        }
        
        if (type === CustomerSupplierEnum.SUPPLIER) {
            columns.push({
                Header: <TableHeader value={languageStore.get('supplierNumber')} />,
                id: 'supplierNumber',
                accessor: 'supplierNumber'
            })
        }
        
        

        return columns;
    }

    let data = props.data as any;
    if (searchQuery.length > 2) {
        data = _.filter(data, (u) => {
            return (u.name?.toLowerCase().indexOf(searchQuery?.toLowerCase()) !== -1
                || (u.vat !== null && u.vat.toLowerCase().indexOf(searchQuery?.toLowerCase()) !== -1)
                || (props.type === CustomerSupplierEnum.CUSTOMER && u.customerNumber !== null && u.customerNumber.toLowerCase().indexOf(searchQuery?.toLowerCase()) !== -1)
                || (props.type === CustomerSupplierEnum.SUPPLIER && u.supplierNumber !== null && u.supplierNumber.toLowerCase().indexOf(searchQuery?.toLowerCase()) !== -1)
            )
        })
    }

    return (
        <div>
            <div className='flex'>
                <div className='text-left flex-1'>
                    <input
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className='border py-1 pl-2  mr-4 pr-3 text-green-darker leading-normal rounded'
                        id='search'
                        type='text'
                        placeholder={`${languageStore.get('search')}...`}
                    />
                </div>

                <div className='text-right'>
                    <div className='mb-5 text-right inline-block'>
                        <button onClick={() => onCreateSupplier()} className='bg-comablue-label text-white py-2 px-8 rounded'>
                            {languageStore.get('add')}
                        </button>
                    </div>
                </div>
            </div>
            <div className='border rounded p-5'>
                <ReactTable
                    data={data.slice()}
                    columns={getColumns()}
                    defaultPageSize={99999}
                    showPagination={false}
                    defaultSorted={[{ id: 'name' }]}
                    NoDataComponent={() => null}
                    getTdProps={(state: any, rowInfo: any, column: any, instance: any) => {
                        return {
                            onClick: (e: React.MouseEvent, handleOriginal: any) => {
                                const id = rowInfo.original.id;
                                gotoItem(id);
                            }
                        };
                    }}
                    minRows={0} />
            </div>
        </div>
    )
})

export default SuppliersCustomersTable;