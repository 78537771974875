import React from 'react';
import { observer } from 'mobx-react';
import { CompanyUserInterface, companyUserStore, languageStore, userStore } from 'stores';
import { FieldState, FormState } from 'formstate';
import Form from 'components/Forms/Form';
import { Modal, ModalColumn } from 'components/Modal';
import SelectDate from 'components/Forms/SelectDate';
import moment from 'moment';

interface Props {
    onClose: (deleteNow: boolean) => void,
    user: CompanyUserInterface,
}

@observer class RequestDeleteUserModal extends React.Component<Props> {

    form = new FormState({
        date: new FieldState(this.props.user.deleteDate)
    });

    onSubmit = async () => {
        const { user, onClose } = this.props;

        const form = this.form.$;
        const date = form.date.$;

        const deleteNow = moment(date!).isSameOrBefore(moment());
        if (deleteNow) {
            if (userStore.signedInUser?.company?.hasBillingPlan) {
                await companyUserStore.deleteUser(user, true);
            }
        }
        else {
            const oldDeleteDate = user.deleteDate;
            try {
                user.deleteDate = date || null as any;
                await companyUserStore.updateUser(user);
            }
            catch(error) {
                user.deleteDate = oldDeleteDate;
            }

        }

        onClose(deleteNow);
    }

    render() {
        const { onClose } = this.props;
        const form = this.form.$;

        return (
            <Form onValidSubmit={this.onSubmit} formState={this.form}>
                <Modal
                    title={languageStore.get('requestDelete')}
                    primaryButton={{ title: languageStore.get('save') }}
                    secondaryButton={{ title: languageStore.get('cancel'), onClick: onClose }}>

                    <ModalColumn>
                        <SelectDate
                            label={languageStore.get('date')}
                            fieldState={form.date}
                        />
                    </ModalColumn>

                </Modal>
            </Form>
        )
    }
}

export default RequestDeleteUserModal;