import * as React from 'react';
import { observer } from 'mobx-react';
import StepContainer from './StepContainer';
import { languageStore, rolesStore, RoleInterface, createUserStore, userStore, navigationStore } from 'stores';
import { FormState, FieldState } from 'formstate';
import * as _ from 'lodash';
import SelectTrueFalse from 'components/Forms/SelectTrueFalse';
import { RolesHelper } from 'helpers/RolesHelper';

interface Props {
    afterSubmit: Function,
    companyId: string
}

@observer class AccessRightsStep extends React.Component<Props> {

    form = new FormState({});

    componentDidMount() {
        const companyId = this.props.companyId;
        rolesStore.getRoles(companyId);
    }

    onSubmit = async () => {
        let roles: Array<RoleInterface> = [];

        for (let id in this.form.$) {
            let value = this.form.$[id] as FieldState<boolean>;
            if (value.value === true) {
                const role = _.find(rolesStore.roles, { id: Number(id) }) as RoleInterface;
                roles.push(role);
            }
        }

        const companyId = this.props.companyId;
        const userId = createUserStore.user!.id;
        await createUserStore.putRoles(companyId, userId, roles);

        this.props.afterSubmit();
    }

    camelize(inputValue: string) {
        const returnVal = inputValue.replace(/\s+(.)/g, function (match, group) {
            return group.toUpperCase()
        })

        return returnVal.charAt(0).toLowerCase() + returnVal.slice(1); // First character should be lowercase
    }

    render() {
        const roles = rolesStore.roles || [];

        if (!roles) return null;

        const form = this.form.$;
        const availableRoles = RolesHelper.GetAvailableRoles(userStore.signedInUser);

        return (
            <StepContainer
                backLinkFunc={() => navigationStore.goBack()}
                backlinkTitle={languageStore.get('users')}
                title={languageStore.get('accessRights')}
                form={this.form}
                onSubmit={this.onSubmit}>

                <div className='ml-4'>
                    {
                        roles.map((role, index) => {
                            if (availableRoles.indexOf(Number(role.role)) === -1) {
                                // eslint-disable-next-line array-callback-return
                                return;
                            }

                            const field = new FieldState(false);
                            form[role.id] = field;

                            return (
                                <div key={index}>
                                    <SelectTrueFalse
                                        marginTop='0'
                                        marginBottom='0'
                                        id={'' + role.id}
                                        fieldState={field}
                                        label={languageStore.get(this.camelize(role.name))}
                                        editMode={true} />
                                </div>
                            );
                        })
                    }
                </div>

            </StepContainer>
        )
    }

}

export default AccessRightsStep;