import * as React from 'react';
import { observer } from 'mobx-react';
import { FormState, FieldState } from 'formstate';
import {
    languageStore, ContractNoteInterface, CurrentContractStore
} from 'stores';
import { ModalColumn } from 'components/Modal';
import Input from 'components/Forms/Input';
import TableGreenHeader from 'components/Table/TableGreenHeader';
import BaseContractTable from 'components/StaffContractDetailsPage/BaseContractTable';
import { required } from 'helpers/FormValidation';

interface Props {
    store: CurrentContractStore<any>,
    hash: string
}

@observer class Notes extends React.Component<Props> {

    form = new FormState({
        id: new FieldState<string | null>(null),
        title: new FieldState(null).validators(required),
        description: new FieldState(null)
    })

    render() {;
        const { store, hash } = this.props;
        const form = this.form.$;
        const columns = [
            {
                Header: <TableGreenHeader value={languageStore.get('title')} />,
                accessor: 'title'
            },
            {
                Header: <TableGreenHeader value={languageStore.get('description')} />,
                accessor: 'description'
            }
        ]

        return (
            <BaseContractTable
                form={this.form}
                onUpdate={(note: ContractNoteInterface) => store.updateNote(note)}
                onSubmit={(note: ContractNoteInterface) => store.createNote(note)}
                onDelete={(id: string) => store.deleteNote(id)}
                collection={store.contract!.notes}
                columns={columns}
                tableTitle={languageStore.get('notes')}
                modalTitle={languageStore.get('note')}
                store={store}
                hash={hash}>

                <ModalColumn>

                    <Input
                        fieldState={form.title}
                        id='note_title'
                        label={languageStore.get('title')} />

                    <Input
                        fieldState={form.description}
                        id='note_description'
                        label={languageStore.get('description')} />

                </ModalColumn>

            </BaseContractTable>
        )
    }

}

export default Notes;