import * as React from 'react';
import { observer } from 'mobx-react';
import {
    languageStore,
    ContractAlarmInterface,
    userStore,
    UserInterface,
    ContractAlarmTypeEnum,
    CurrentContractStore,
    CompanyInterface,
    toastStore
} from 'stores';
import TableGreenHeader from 'components/Table/TableGreenHeader';
import { ModalColumn } from 'components/Modal';
import { FormState, FieldState } from 'formstate';
import * as _ from 'lodash';
import ResponsibleUser from 'components/Forms/ResponsibleUser';
import ContractDateItem from 'components/ContractDateItem';
import Input from 'components/Forms/Input';
import { required } from 'helpers/FormValidation';
import BaseContractTable from 'components/StaffContractDetailsPage/BaseContractTable';
import { formatDate } from 'helpers/DateHelper';

interface Props {
    store: CurrentContractStore<any>,
    hash: string
}

@observer class Alarms extends React.Component<Props> {

    form = new FormState({
        id: new FieldState<string | null>(null),
        responsibleUserId: new FieldState(null).validators(required),
        date: new FieldState(null).validators(required),
        noticeDays: new FieldState(null),
        note: new FieldState(null),
        deactivateUntil: new FieldState(null)
    })

    componentDidMount() {
        // We need to make sure that every user is available,
        // to map responsible user id in table to correct full name.
        userStore.getUsers(false);
    }

    isSystemAlarm(alarmId: string) {
        return this.isAlarmType(alarmId, [ContractAlarmTypeEnum.EXPIRATION, ContractAlarmTypeEnum.RENEWAL, ContractAlarmTypeEnum.STAFF_RENEGOTIATION, ContractAlarmTypeEnum.SERVICE_SERVICEDATE]);
    }

    canDeleteBirthdayAlarm(alarmId: string) {
        const company = userStore.signedInUser!.company as CompanyInterface;
        const deactivateAutoBirthday = company.deactivteAutomaticBirthdayAlarms;
        const deactivateAutoSeniority = company.deactivteAutomaticBirthdayAlarms;

        if (this.isAlarmType(alarmId, [ContractAlarmTypeEnum.STAFF_BIRTHDAY])) {
            if (deactivateAutoBirthday) return false;
            else return true;
        }

        if (this.isAlarmType(alarmId, [ContractAlarmTypeEnum.STAFF_SENIORITY])) {
            if (deactivateAutoSeniority) return false;
            else return true;
        }

        return true;
    }

    isAlarmType(alarmId: string, alarmTypes: Array<ContractAlarmTypeEnum>) {
        const { store } = this.props;
        const alarm = _.find(store.contract!.alarms, { id: alarmId }) as ContractAlarmInterface;
        if (alarm && (alarmTypes.indexOf(alarm.type) !== -1)) {
            return true;
        }
        return false;
    }

    beforeSubmit = (obj: any) => {
        if (obj.noticeDays === null) {
            obj.noticeDays = 0;
        }

        // If we are editing an alarm, we need to maunally set the type,
        // to show the right alarm type in table overview.
        if (!obj.type && obj.id) {
            const { store } = this.props;
            const alarm = _.find(store.contract!.alarms, { id: obj.id }) as ContractAlarmInterface;
            if (alarm) {
                obj.type = alarm.type
            }
        }
    }

    render() {
        const { store, hash } = this.props;
        const users = userStore.users;
        const form = this.form.$;

        const columns = [
            {
                Header: <TableGreenHeader value={languageStore.get('type')} />,
                id: 'type',
                accessor: (d: any) => {
                    switch (d.type) {
                        case ContractAlarmTypeEnum.EXPIRATION: return languageStore.get('expiration');
                        case ContractAlarmTypeEnum.RENEWAL: return languageStore.get('renewal');
                        case ContractAlarmTypeEnum.STAFF_BIRTHDAY: return languageStore.get('birthday');
                        case ContractAlarmTypeEnum.STAFF_RENEGOTIATION: return languageStore.get('renegotiation');
                        case ContractAlarmTypeEnum.STAFF_SENIORITY: return languageStore.get('seniority');
                        case ContractAlarmTypeEnum.SERVICE_SERVICEDATE: return languageStore.get('serviceDate');
                        default: return languageStore.get('alarm');
                    }
                }
            },
            {
                Header: <TableGreenHeader value={languageStore.get('responsible')} />,
                id: 'responsibleUserId',
                accessor: (d: any) => {
                    const userId = d.responsibleUserId;
                    const user = _.find(users, { id: userId }) as UserInterface;
                    return user ? user.fullName : '-';
                }
            },
            {
                Header: <TableGreenHeader value={languageStore.get('date')} />,
                id: 'date',
                accessor: (d: any) => formatDate(d.date)
            },
            {
                Header: <TableGreenHeader value={languageStore.get('noticeDays')} />,
                accessor: 'noticeDays'
            },
            {
                Header: <TableGreenHeader value={languageStore.get('deactivateAlarmUntil')} />,
                id: 'deactivateUntil',
                accessor: (d: any) => formatDate(d.deactivateUntil)
            },
            {
                Header: <TableGreenHeader value={languageStore.get('note')} />,
                accessor: 'note'
            },
        ]

        let systemAlarm = false;
        let canDeleteBirthdayAlarm = false;
        if (form.id.$) {
            systemAlarm = this.isSystemAlarm(form.id.$);
            canDeleteBirthdayAlarm = this.canDeleteBirthdayAlarm(form.id.$);
        }

        return (
            <BaseContractTable
                form={this.form}
                beforeSubmit={this.beforeSubmit}
                onUpdate={(alarm: ContractAlarmInterface) => store.updateAlarm(alarm)}
                onSubmit={(alarm: ContractAlarmInterface) => store.createAlarm(alarm)}
                onDelete={(id: string) => {
                    if (systemAlarm || !canDeleteBirthdayAlarm) {
                        toastStore.addError(languageStore.get('birthdayAndSeniorityAlarmsNotDeletable'));
                        return;
                    }

                    store.deleteAlarm(id);
                }}
                // onDelete={(!systemAlarm && !canDeleteBirthdayAlarm) ? (id: string) => store.deleteAlarm(id) : undefined}
                collection={store.contract!.alarms}
                columns={columns}
                tableTitle={languageStore.get('alarms')}
                modalTitle={languageStore.get('alarm')}
                store={store}
                hash={hash}>

                <ModalColumn>

                    <ResponsibleUser
                        fieldState={form.responsibleUserId}
                        editMode={true} />

                    {
                        !systemAlarm &&
                        <>
                            <ContractDateItem
                                fieldState={form.date}
                                label={languageStore.get('date')}
                                id='date'
                                editMode={true} />

                            <Input
                                fieldState={form.noticeDays}
                                type='number'
                                id='noticeDays'
                                label={languageStore.get('noticeDays')} />
                        </>
                    }

                    <Input
                        fieldState={form.note}
                        id='note'
                        label={languageStore.get('note')} />

                    {
                        !systemAlarm &&
                        <ContractDateItem
                            fieldState={form.deactivateUntil}
                            label={languageStore.get('deactivateAlarmUntil')}
                            id='deactivateUntil'
                            editMode={true} />
                    }

                    {
                        systemAlarm &&
                        <p style={{ fontSize: '14px' }}>{languageStore.get('cantDeactivateSystemAlarmWarning')}</p>
                    }

                </ModalColumn>

            </BaseContractTable>
        )
    }

}

export default Alarms;