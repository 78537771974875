import * as React from 'react';
import { observer } from 'mobx-react';
import ContractDetailsItem from 'components/ContractDetailsItem';
import { languageStore, supplierStore, SupplierInterface } from 'stores';
import { FieldState } from 'formstate';
import * as _ from 'lodash';

interface Props {
    supplierId: string | null,
    fieldState: FieldState<any>,
    editMode?: boolean,
    onBlur?: (value: any) => void
}

@observer class VatNumber extends React.Component<Props> {

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        if (nextProps.supplierId !== this.props.supplierId) {
            const { fieldState } = this.props;

            if (nextProps.supplierId) {
                const supplier = _.find(supplierStore.suppliers, { id: nextProps.supplierId }) as SupplierInterface;
                const vatNumber = supplier ? supplier.vat : '';
                fieldState.onChange(vatNumber);
            }
            else {
                fieldState.onChange('');
            }
        }
    }

    render() {
        const { fieldState, editMode, onBlur } = this.props;

        return <ContractDetailsItem
            label={languageStore.get('vatNumber')}
            id='vatNumber'
            onBlur={() => onBlur ? onBlur(fieldState.$) : undefined}
            fieldState={fieldState}
            editMode={editMode}
        />
    }

}

export default VatNumber;