import * as React from 'react';
import { observer } from 'mobx-react';
import { FormState, FieldState } from 'formstate';
import { languageStore, locationStore, LocationInterface, serviceStore, ServiceInterface, DocumentTypeContractTypeEnum, userStore } from 'stores';
import TableHeader from 'components/Table/TableHeader';
import * as _ from 'lodash';
import { ModalColumn } from 'components/Modal';
import ContractDetailsItem from 'components/ContractDetailsItem';
import DetailsPage from '../DetailsPage';
import Location from 'components/Forms/Location';
import { required } from 'helpers/FormValidation';

interface Props {
    contractType: DocumentTypeContractTypeEnum
}

@observer class ServicesPage extends React.Component<Props> {

    form = new FormState({
        id: new FieldState<null | string>(null),
        name: new FieldState(null).validators(required),
        locationId: new FieldState(null).validators(required)
    })

    componentDidMount() {
        const companyId = userStore.signedInUser.company.id;
        locationStore.getLocationsByCompany(companyId);

        serviceStore.getAllServices();
    }

    onDeleteService = async (serviceId: string) => {
        const ser = _.find(serviceStore.allServices, { id: serviceId }) as ServiceInterface;
        const locationId = ser.locationId;
        await serviceStore.deleteService(locationId, serviceId);
    }

    onCreateService = async (service: ServiceInterface) => {
        service.contractType = this.props.contractType;
        await serviceStore.createService(service);
    }

    onUpdateService = async (service: ServiceInterface) => {
        service.contractType = this.props.contractType;
        await serviceStore.updateService(service);
    }

    render() {
        const data = serviceStore.allServices || [];
        const locations = locationStore.locations || [];
        const form = this.form.$;
        const isNewEntity = !!!form.id.$;

        const filteredData = data.filter((d) => {
            return d.contractType === this.props.contractType
        })

        const columns = [
            {
                Header: <TableHeader value={languageStore.get('name')} />,
                accessor: 'name'
            },
            {
                Header: <TableHeader value={languageStore.get('location')} />,
                id: 'locationId',
                accessor: (d: any) => {
                    const loc = _.find(locations, { id: d.locationId }) as LocationInterface;
                    return loc ? loc.name : '-';
                }
            },
        ]

        return (
            <DetailsPage
                fullWidth={true}
                generalTitle={languageStore.get('services')}
                modalTitle={languageStore.get('services')}
                form={this.form}
                onUpdate={(service: ServiceInterface) => this.onUpdateService(service)}
                onSubmit={(service: ServiceInterface) => this.onCreateService(service)}
                onDelete={(id: string) => this.onDeleteService(id)}
                collection={filteredData}
                columns={columns}>

                <ModalColumn>

                    <ContractDetailsItem
                        fieldState={form.name}
                        label={languageStore.get('name')}
                        id='name'
                        editMode={true} />

                    {
                        isNewEntity &&
                        <Location fieldState={form.locationId} editMode={true} />
                    }

                </ModalColumn>

            </DetailsPage>
        )
    }

}

export default ServicesPage;