import * as React from 'react';
import { observer } from 'mobx-react';
import * as queryString from 'query-string';
import { languageStore, authStore, redirectStore, navigationStore, ADFSHostEnum, toastStore } from 'stores';
import LoginTwoFactor from 'components/Forms/LoginTwoFactor';

interface Props {
    logoImgSrc?: any,
    logoImgAlt?: string,
    loginEndpoint: string,
    adfsHost: ADFSHostEnum,
    redirectUri: string,
    providerId: string,
    location?: any
}

enum StepEnum {
    LOGIN,
    TWOFACTOR
}

interface State {
    signInError: boolean,
    step: StepEnum
}

@observer class ADFS extends React.Component<Props, State> {

    state = { signInError: false, step: StepEnum.LOGIN }

    gotoADFSlogin = () => {
        this.setState({
            signInError: false
        }, () => {
            const { loginEndpoint } = this.props;
            window.location.href = loginEndpoint;
        })
    }

    async requestAccessToken(code: string) {
        try {
            const { redirectUri, providerId, adfsHost } = this.props;
            await authStore.loginByAdfs(code, redirectUri, providerId, adfsHost);

            if (!authStore.bearerToken) {
                this.setState({
                    step: StepEnum.TWOFACTOR
                })
            }
            else {
                this.onLoggedInSuccessfully();
            }
        }
        catch (error) {
            this.setState({
                signInError: true
            })
        }
    }

    onLoggedInSuccessfully = () => {
        if (redirectStore.redirectIsActive) {
            const location = redirectStore.location;
            redirectStore.resetStore();

            navigationStore.replace(location!);
        }
        else {
            navigationStore.replace('/');
        }
    }

    componentDidMount() {
        let params = queryString.parse(window.location.search);

        if (params.error) {
            this.setState({ signInError: true });
            return;
        }

        if (params.code) {
            // This is a callback from oAuth server. Make final call to exchange for token
            this.requestAccessToken(params.code as string);
        }
        else {
            this.gotoADFSlogin();
        }
    }

    onSubmitTwoFactorCode = async (code: string) => {
        try {
            const { adfsHost } = this.props;
            await authStore.loginByTwoFactor(authStore.twoFactorAuthToken!, code, adfsHost);
            this.onLoggedInSuccessfully();
        }
        catch (error) {
            toastStore.addError(languageStore.get('wrongCredentials'));
        }
    }

    render() {
        const { signInError, step } = this.state;
        const { logoImgSrc, logoImgAlt } = this.props;

        return (
            <>
                {
                    step === StepEnum.LOGIN &&
                    <div className="font-sans text-sm rounded w-full max-w-xs mx-auto mt-20 px-8 pt-8 pb-8">
                        <img src={logoImgSrc} alt={logoImgAlt} className='mb-12' />

                        {
                            !signInError &&
                            <p className='text-center'>
                                {languageStore.get('signingIn')}...
                            </p>
                        }

                        {
                            signInError &&
                            <div className='text-center'>
                                <p>
                                    {languageStore.get('wrongCredentialsOrNotAllowed')}.
                                </p>

                                <button onClick={this.gotoADFSlogin} className="mt-8 inline-block align-baseline text-grey-dark border p-2 rounded text-underline hover:text-grey-darker mt-4">
                                    {languageStore.get('backToLogin')}
                                </button>

                            </div>
                        }

                    </div>
                }

                {
                    step === StepEnum.TWOFACTOR &&
                    <LoginTwoFactor
                        onSubmit={this.onSubmitTwoFactorCode}
                    />
                }
            </>
        )
    }
}

export default ADFS;