import * as React from 'react';
import { observer } from 'mobx-react';

interface Props {
    value?: string,
    className?: string,
    children?: React.ReactNode
}

@observer class TableHeader extends React.Component<Props> {

    render() {
        const { className, value, children } = this.props;

        return (
            <div className={`
                text-left
                text-sm 
                font-semibold 
                text-grey-dark
                ${className}
            `}>
                {value}
                {children}
            </div>
        )
    }

}

export default TableHeader;