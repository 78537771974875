import * as React from 'react';
import { observer } from 'mobx-react';
import { navigationStore, authStore, redirectStore, toastStore, languageStore } from 'stores';

import Login from 'components/Forms/Login';
import LoginTwoFactor from 'components/Forms/LoginTwoFactor';

enum StepEnum {
    LOGIN,
    TWOFACTOR
}

interface State {
    step: StepEnum
}

@observer class LoginPage extends React.Component<any, State> {

    state = {
        step: StepEnum.LOGIN
    }

    onSubmitEmailPassword = async (email: string, password: string) => {
        try {
            await authStore.login(email, password);

            if (!authStore.bearerToken) {
                this.setState({
                    step: StepEnum.TWOFACTOR
                })
            }
            else {
                this.onLoggedInSuccessfully();
            }
        }
        catch (error) {
            toastStore.addError(languageStore.get('wrongCredentials'));
        }

    }

    onLoggedInSuccessfully = () => {
        if (redirectStore.redirectIsActive) {
            const location = redirectStore.location;
            redirectStore.resetStore();

            navigationStore.replace(location!);
        }
        else {
            navigationStore.replace('/');
        }
    }

    onSubmitTwoFactorCode = async (code: string) => {
        try {
            await authStore.loginByTwoFactor(authStore.twoFactorAuthToken!, code);
            this.onLoggedInSuccessfully();
        }
        catch (error) {
            toastStore.addError(languageStore.get('wrongCredentials'));
        }
    }

    render() {
        const { step } = this.state;

        return (
            <>
                {
                    step === StepEnum.LOGIN &&
                    <Login
                        onSubmit={this.onSubmitEmailPassword} />
                }

                {
                    step === StepEnum.TWOFACTOR &&
                    <LoginTwoFactor
                        onBackToLogin={() => window.location.reload()}
                        onSubmit={this.onSubmitTwoFactorCode} />
                }
            </>
        )
    }

}

export default LoginPage;