import * as React from 'react';
import { observer } from 'mobx-react';
import { DashboardContractTypeEnum, languageStore, navigationStore, dashboardStore, DocumentSigningInterface, DocumentSigningStatusEnum } from 'stores';
import ReactTable from 'react-table';
import TableHeader from 'components/Table/TableHeader';
import { formatDate } from 'helpers/DateHelper';

interface Props {
    contractType: DashboardContractTypeEnum,
    title: string,
    signingStatus: DocumentSigningStatusEnum
}

interface State {
    data: Array<DocumentSigningInterface>
}

@observer class Signings extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            data: []
        }
    }

    componentDidMount() {
        const { contractType } = this.props;

        this.getData(contractType);
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        if (nextProps.contractType !== this.props.contractType) {
            this.getData(nextProps.contractType);
        }
    }

    async getData(contractType: DashboardContractTypeEnum) {
        const { signingStatus } = this.props;
        const data = await dashboardStore.getSignings(contractType, signingStatus) as any;
        this.setState({ data: data });
    }

    gotoContractDetails = (row: any) => {
        const { contractType } = this.props;
        navigationStore.push(`/${this.getEndpointByContractType(contractType)}/${row.contractId}#files_contract`);
    }

    getEndpointByContractType(contractType: DashboardContractTypeEnum) {
        switch (contractType) {
            case DashboardContractTypeEnum.PURCHASE: return 'purchase';
            case DashboardContractTypeEnum.SALES: return 'sales';
            case DashboardContractTypeEnum.SERVICE: return 'service';
            case DashboardContractTypeEnum.STAFF: return 'staff';
        }
    }

    render() {
        const { title } = this.props;
        const { data } = this.state;
        if (!data || data.length === 0) return null;
        // const data = dashboardStore.signings || [];

        const columns = [
            {
                Header: <TableHeader value={languageStore.get('createdOn')} />,
                id: 'createdOn',
                accessor: (d: DocumentSigningInterface) => {
                    if (!d.createdOn) return null;
                    return formatDate(d.createdOn, true);
                }
            },
            {
                Header: <TableHeader value={languageStore.get('documentName')} />,
                id: 'document',
                accessor: (d: DocumentSigningInterface) => {
                    return d.documents[0].fileName;
                },
            }
        ]

        return (
            <div className='w-full border p-4 mt-4 rounded'>
                <p className='font-semibold mb-2'>
                    {title}
                </p>
                <ReactTable
                    data={data}
                    columns={columns}
                    showPagination={false}
                    sortable={false}
                    defaultPageSize={99999}
                    NoDataComponent={() => null}
                    manual // Sorting and pagination will be handled serverside
                    minRows={0}
                    getTdProps={(state: any, rowInfo: any, column: any, instance: any) => {
                        return {
                            onClick: (e: React.MouseEvent, handleOriginal: any) => {
                                this.gotoContractDetails(rowInfo.original);
                            }
                        };
                    }}
                />
            </div>
        )
    }

}

export default Signings;