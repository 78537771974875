import { observable, action, makeObservable } from "mobx";
import { languageStore } from "stores";

interface ToastInterface {
    message: string,
    title: string,
    level: string,
    position?: string
}

export class ToastStore {
    @observable toasts: Array<ToastInterface> = [];

    constructor() {
        makeObservable(this);
    }

    @action
    addError(message: string) {
        this.addToast({
            title: 'Error',
            level: 'error',
            message: message
        });
    }

    @action
    addToast(toast: ToastInterface) {
        if (!toast.position) {
            toast.position = 'tc';
        }

        this.toasts.push(toast);
    }

    addDefaultSuccessfulSaveToast() {
        this.addToast({
            level: 'success',
            title: languageStore.get('success'),
            message: languageStore.get('changesSaved')
        })
    }

    @action
    resetToasts() {
        this.toasts = [];
    }
}

export const toastStore = new ToastStore();