import * as React from 'react';

interface CellButtonProps {
    text: string,
    onClick: ((event: React.MouseEvent<HTMLButtonElement>) => void) | undefined,
    className?: string
}

const CellButton: React.SFC<CellButtonProps> = (props: CellButtonProps) => {
    const { onClick, text } = props;

    return (
        <button
            className={`py-1 px-2 border rounded hover:bg-grey-lighter ${props.className || ''}`}
            onClick={onClick}>
            {text}
        </button>
    )
}

export default CellButton;