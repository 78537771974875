import React from 'react';
import { observer } from 'mobx-react';
import { customerDetailsStore, customerStore, CustomerUsedInInterface, languageStore, navigationStore, supplierDetailsStore, supplierStore, SupplierUsedInInterface } from 'stores';
import { Modal, ModalColumn } from 'components/Modal';
import { CustomerSupplierEnum } from 'components/SuppliersCustomersTable';
import ContractBlock from './ContractBlock';

interface Props {
    type: CustomerSupplierEnum,
    id: string
}

interface State {
    isShowModal: boolean
}

@observer class DeleteSupplierCustomer extends React.Component<Props, State> {

    state = { isShowModal: false }

    hideModal = () => {
        this.setState({ isShowModal: false });
    }

    showModal = () => {
        this.setState({ isShowModal: true });
    }

    onDelete = async (e: React.MouseEvent) => {
        e.preventDefault();

        const { type, id } = this.props;
        if (type === CustomerSupplierEnum.SUPPLIER) {
            const res = await supplierDetailsStore.getUsedIn(id) as SupplierUsedInInterface;
            if (res.purchaseContracts.accessableContractIds.length > 0
                || res.purchaseContracts.inaccessableContractIds.length > 0) {
                this.showModal();
                return;
            }

            if (!window.confirm(`${languageStore.get('delete')}?`)) return;
            await supplierStore.deleteSupplier(id);
            navigationStore.push(`/admin/suppliers`);
        }
        else {
            const res = await customerDetailsStore.getUsedIn(id) as CustomerUsedInInterface;
            if (res.salesContracts.accessableContractIds.length > 0
                || res.salesContracts.inaccessableContractIds.length > 0
                || res.serviceContracts.accessableContractIds.length > 0
                || res.serviceContracts.inaccessableContractIds.length > 0) {
                this.showModal();
                return;
            }

            if (!window.confirm(`${languageStore.get('delete')}?`)) return;
            await customerStore.deleteCustomer(id);
            navigationStore.push(`/admin/customers`);
        }
    }

    render() {
        const { type } = this.props;
        const { isShowModal } = this.state;

        return (
            <div className='inline-block'>
                <span className='mr-2'>
                    <button
                        className='bg-red text-white py-2 px-8 rounded'
                        type='button'
                        onClick={this.onDelete}>
                        {languageStore.get('delete')}
                    </button>
                </span>

                {
                    isShowModal &&
                    <div className='text-left'>
                        <Modal
                            title={languageStore.get('usedBy')}
                            secondaryButton={{
                                title: languageStore.get('close'),
                                onClick: () => this.hideModal()
                            }}>

                            <ModalColumn>
                                {
                                    type === CustomerSupplierEnum.SUPPLIER &&
                                    <ContractBlock
                                        title={languageStore.get('purchaseContracts')}
                                        endpoint='purchase'
                                        accessibleContractIds={supplierDetailsStore.usedIn?.purchaseContracts.accessableContractIds || []}
                                        inaccessibleContractIds={supplierDetailsStore.usedIn?.purchaseContracts.inaccessableContractIds || []}
                                    />
                                }

                                {
                                    type === CustomerSupplierEnum.CUSTOMER &&
                                    <>
                                        <ContractBlock
                                            title={languageStore.get('salesContracts')}
                                            endpoint='sales'
                                            accessibleContractIds={customerDetailsStore.usedIn?.salesContracts.accessableContractIds || []}
                                            inaccessibleContractIds={customerDetailsStore.usedIn?.salesContracts.inaccessableContractIds || []}
                                        />
                                        <ContractBlock
                                            title={languageStore.get('serviceContracts')}
                                            endpoint='service'
                                            accessibleContractIds={customerDetailsStore.usedIn?.serviceContracts.accessableContractIds || []}
                                            inaccessibleContractIds={customerDetailsStore.usedIn?.serviceContracts.inaccessableContractIds || []}
                                        />
                                    </>
                                }
                            </ModalColumn>

                        </Modal>
                    </div>
                }
            </div>
        )
    }
}

export default DeleteSupplierCustomer;