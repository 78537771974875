import * as React from 'react';
import { observer } from 'mobx-react';
import * as _ from 'lodash';
import { FormState, FieldState } from 'formstate';
import ContractDropdownItem from 'components/ContractDropdownItem';
import { languageStore, ReportCriteriaInterface, BaseReportStore, ReportCompareTypeEnum } from 'stores';
import CompareDropdown, { ReportCompareEnum } from '../CompareDropdown';
import Input from '../Input';
import { required } from 'helpers/FormValidation';
import ContractDateItem from 'components/ContractDateItem';
import Dropdown from 'components/Forms/Dropdown';

interface Props {
    form: FormState<any>,
    index: number,
    store: BaseReportStore
}

interface State {
    compareType: ReportCompareTypeEnum,
    compareMethod: ReportCompareEnum | null
}

@observer class CriteriaLine extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            compareType: ReportCompareTypeEnum.NONE,
            compareMethod: null
        }
    }

    componentWillUnmount() {
        const { form, index } = this.props;
        delete (form.$[index]);
    }

    componentDidMount() {
        const { form, index, store } = this.props;
        form.$[index] = new FormState({
            requestField: new FieldState(null).validators(required),
            responseField: new FieldState(null),
            compareType: new FieldState(null),
            searchValue: new FieldState(null),
            id: new FieldState(null)
        })

        const entityForm = form.$[index] as FormState<any>;
        entityForm.$.requestField.onUpdate((fs: FieldState<any>) => {
            let data = _.find(store.data, { id: fs.$ }) as ReportCriteriaInterface;
            if (!data) {
                data = _.find(store.data, { requestField: fs.$ }) as ReportCriteriaInterface;
            }

            if (data) {
                // entityForm.$.searchValue.reset();
                // entityForm.$.compareType.reset(data.compareType);
                this.setState({
                    compareType: data.compareType
                })
            }
        })
    }

    render() {
        const { form, index, store } = this.props;
        const { compareType, compareMethod } = this.state;
        const data = store.data;

        if (!form.$[index]) return null;

        const opts = data.map((data) => {
            return {
                value: data.id || data.requestField,
                name: languageStore.get(data.label)
            }
        })



        let match = _.find(data, { id: form.$[index].$.requestField.value }) as ReportCriteriaInterface;
        if (!match) {
            match = _.find(data, { requestField: form.$[index].$.requestField.value }) as ReportCriteriaInterface;
        }

        return (
            <div className='flex' style={{ marginBottom: '-40px' }}>

                <div className='w-64'>
                    <ContractDropdownItem
                        className='mr-2'
                        label=''
                        id='requestField'
                        fieldState={form.$[index].$.requestField}
                        options={opts}
                        placeholderText={languageStore.get('selectField')}
                        editMode={true} />
                </div>

                <div className='w-32'>
                    <CompareDropdown
                        disabled={compareType === ReportCompareTypeEnum.NONE}
                        type={compareType}
                        beforeChange={(value: any) => this.setState({ compareMethod: value })}
                        fieldState={form.$[index].$.compareType} />
                </div>

                {
                    (match
                        && (compareType === ReportCompareTypeEnum.TEXT || compareType === ReportCompareTypeEnum.NUMBER)
                        && (compareMethod !== ReportCompareEnum.EXISTS && compareMethod !== ReportCompareEnum.DOES_NOT_EXIST)
                    ) &&
                    <Input
                        store={store}
                        type={compareType}
                        fieldState={form.$[index].$.searchValue}
                        requestField={match.requestField} />
                }

                {
                    compareType === ReportCompareTypeEnum.DATE &&
                    <div className='w-32'>
                        <ContractDateItem
                            label=''
                            id={index.toString()}
                            fieldState={form.$[index].$.searchValue}
                            editMode={true} />
                    </div>
                }

                {
                    compareType === ReportCompareTypeEnum.DROPDOWN &&
                    <div className='w-32 mt-8'>
                        <Dropdown
                            label=''
                            id={index.toString()}
                            fieldState={form.$[index].$.searchValue}
                            options={match.dropdownOptions!} />
                    </div>
                }

            </div>
        );
    }

}

export default CriteriaLine;