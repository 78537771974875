import { CurrencyInterface, currencyStore, CurrentContractStore } from "stores";
import _ from 'lodash';

export function formatCurrency(currencyCode?: string, value?: number) {
    if (!currencyCode || !value) return 0;

    if (Intl.NumberFormat) {
        try {
            return Intl.NumberFormat(undefined, { style: 'currency', currency: currencyCode }).format(value);
        }
        catch(error) {
            return value;
        }
    }

    else return value;
}

export function formatCurrencyByCurrentContract(store: CurrentContractStore<any>, value?: number) {
    if (store.contract && store.contract!.currencyId) {
        const currency = _.find(currencyStore.currencies, {id: store.contract!.currencyId}) as CurrencyInterface;
        
        if (currency) {
            return formatCurrency(currency.code, value);
        }
    }

    return value;
}