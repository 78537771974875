import { observer } from "mobx-react";
import { companyUserStore, languageStore, navigationStore, RoleEnum, toastStore, userStore, UserTwoFactorAuthTypeEnum } from "stores";
import InfoBlock from "./InfoBlock";
import { formatDate } from "helpers/DateHelper";
import useCompanyUserByParams from "../useCompanyUserByParams";
import { useState } from "react";
import RequestDeleteUserModal from "components/AdminPage/UsersPage/RequestDeleteUserModal";
import { isValidEmailByString } from "helpers/FormValidation";
import { hasRole } from "helpers/RoleHelper";

const General = observer(() => {
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const user = useCompanyUserByParams();
    if (!user) return null;

    const onToggleActive = (e: React.MouseEvent) => {
        e.preventDefault();

        user.disabled = !user.disabled;
        companyUserStore.updateUser(user);
    }

    const onToggleExternal = (e: React.MouseEvent) => {
        e.preventDefault();

        user.external = !user.external;
        companyUserStore.updateUser(user);
    }

    const deactivateTwoFactor = async () => {
        if (!window.confirm(languageStore.get('areYouSureYouWantToContinue') + '?')) return;

        await companyUserStore.resetTwoFactorAuthentication(user.companyId, user.id);
        user.twoFactorAuthType = UserTwoFactorAuthTypeEnum.NONE;
        companyUserStore.updateUser(user);

    }

    const updateUserEmail = async () => {
        const newEmail = window.prompt(languageStore.get('email'), user.email) || '';
        if (!newEmail) return;
        if (newEmail === user.email) return;

        if (isValidEmailByString(newEmail)) {
            const orgEmail = user.email;
            user.email = newEmail;

            try {
                await companyUserStore.updateUser(user);
                toastStore.addDefaultSuccessfulSaveToast();
            }
            catch (error) {
                user.email = orgEmail;
            }
        }
        else {
            toastStore.addError(languageStore.get('invalidEmail'));
        }
    }

    const requestDeleteUser = (companyId: string, userId: string) => {
        if (window.confirm(languageStore.get('areYouSureToWantToRequestUserDelete') + '.')) {
            companyUserStore.requestDeleteUser(companyId, userId);
        }
    }

    const onCloseDeleteUserModal = (deleteNow: boolean) => {
        if (deleteNow) {
            navigationStore.push('/admin/users');
        }
        else {
            setShowDeleteModal(false);
        }
    }

    return (
        <div>
            <InfoBlock
                label={languageStore.get('name')}
                value={user.fullName} />

            <InfoBlock
                label={languageStore.get('email')}
                value={
                    <span>
                        {user.email}
                        {
                            (hasRole(RoleEnum.PRIMARY_ADMIN || hasRole(RoleEnum.SUPER_ADMIN))) &&
                            <button onClick={updateUserEmail} className='ml-2 underline text-xs'>
                                {languageStore.get('edit')}
                            </button>
                        }
                    </span>
                } />

            <InfoBlock
                label={languageStore.get('lastLogin')}
                value={formatDate(user.lastLogIn) || '-'} />

            <InfoBlock
                label={languageStore.get('externalADFS')}
                value={
                    <span>
                        {user.external ? languageStore.get('yes') : languageStore.get('no')}
                        <button onClick={onToggleExternal} className='ml-2 underline text-xs'>
                            ({user.external ? languageStore.get('deactivate') : languageStore.get('activate')})
                        </button>
                    </span>
                }
            />

            <InfoBlock
                label={languageStore.get('status')}
                value={
                    <span>
                        {user.disabled ? languageStore.get('inactive') : languageStore.get('active')}
                        <button onClick={onToggleActive} className='ml-2 underline text-xs'>
                            ({user.disabled ? languageStore.get('activate') : languageStore.get('deactivate')})
                        </button>
                    </span>
                }
            />

            {
                user.twoFactorAuthType !== UserTwoFactorAuthTypeEnum.NONE &&
                <InfoBlock
                    label={languageStore.get('twoFactorAuthentication')}
                    value={
                        <span>
                            {languageStore.get('active')}
                            <button onClick={deactivateTwoFactor} className='ml-2 underline text-xs'>
                                {languageStore.get('deactivate')}
                            </button>
                        </span>
                    }
                />
            }

            {
                showDeleteModal &&
                <RequestDeleteUserModal user={user!} onClose={onCloseDeleteUserModal} />
            }

            <button
                className='bg-red-dark text-white mt-12 px-8 py-2 rounded'
                onClick={() => {
                    userStore.signedInUser.company.hasBillingPlan
                        ? setShowDeleteModal(true)
                        : requestDeleteUser(user.companyId, user.id)
                }}>
                {user.deleteDate
                    ? `${languageStore.get('deleted')}: ${formatDate(user.deleteDate)}`
                    : `${languageStore.get('delete')} ${languageStore.get('user', false)}`}
            </button>
        </div>
    )

})

export default General;