import { ContractFinancialCompanyServiceInterface, ContractFinancialCompanyServicePeriodEnum, CurrentContractBaseInterface, CurrentContractStore, userStore } from "stores";
import Moment from 'moment-timezone';
import { ServiceTypeEnum } from "./SelectServiceType";
import numeral from "numeral";

export enum ExpirationEnum {
    CONTRACT_EXPIRATION_DATE,
    CURRENT_YEAR,
    NEXT_YEAR
}

class NewServiceHelper {

    formatNumber = (value: any) => {
        const parsedValue = parseFloat(String(value).replace(/,/g, '')); // Remove thousand separators if present
        const number = numeral(parsedValue);
        return parsedValue % 1 === 0 ? number.format("0") : number.format("0.00");
    }
    
    calculateServiceSum = (
        store: CurrentContractStore<CurrentContractBaseInterface>,
        service: ContractFinancialCompanyServiceInterface,
        expiration: ExpirationEnum) => {
            //Temporary workaround
            const newService = { ...service };
            newService.price = this.formatNumber(service.price);
            service = newService;
        switch (expiration) {
            case ExpirationEnum.CONTRACT_EXPIRATION_DATE: {
                
                const timeZone = userStore.signedInUser!.timeZone.id;
                const expirationDate: Moment.Moment | null = (store.contract && store.contract.expirationDate) ? Moment.utc(store.contract.expirationDate).tz(timeZone) : null;
                const startDate = Moment.utc(service.startDate).tz(timeZone);
                if (!expirationDate) return 0;

                return this.calculateRange(store, service, startDate, expirationDate);
            }
            case ExpirationEnum.CURRENT_YEAR: return this.calculateRange(store, service, Moment().startOf('year'), Moment().endOf('year'));
            case ExpirationEnum.NEXT_YEAR: return this.calculateRange(store, service, Moment().startOf('year').add(1, 'year'), Moment().endOf('year').add(1, 'year'));
        }
    }

    calculateTotalServiceSum = (store: CurrentContractStore<CurrentContractBaseInterface>, services: Array<ContractFinancialCompanyServiceInterface>, expiration: ExpirationEnum) => {
        let totalSum = 0;

        services.forEach((service) => {
            totalSum += this.calculateServiceSum(store, service, expiration);
        })

        return totalSum;
    }

    calculateRange = (
        store: CurrentContractStore<CurrentContractBaseInterface>,
        service: ContractFinancialCompanyServiceInterface,
        startDate: Moment.Moment,
        endDate: Moment.Moment
    ) => {

        const timeZone = userStore.signedInUser!.timeZone.id;
        const contractExpirationDate: Moment.Moment | null = (store.contract && store.contract.expirationDate) ? Moment.utc(store.contract.expirationDate).tz(timeZone) : null;
        const serviceStartDate = Moment.utc(service.startDate).tz(timeZone);
        const billingInterval = service.billingInterval || 1;
        if (contractExpirationDate?.isBefore(startDate)) return 0;

        switch (service.serviceType) {
            case ServiceTypeEnum.ONE_TIME_PAYMENT:
                {
                    const serviceStartDate = Moment(service.startDate);
                    if (serviceStartDate.isSameOrAfter(startDate) && serviceStartDate.isSameOrBefore(endDate)) {
                        return service.quantity * Number(service.price);
                    } else return 0;
                }
            case ServiceTypeEnum.FIXED_PAYMENTS:
            case ServiceTypeEnum.RUNNING:
                {
                    const calcPeriods = (identifier: 'months' | 'years' | 'weeks') => {

                        let periodsBefore = 0;
                        let currentPeriods = 0;

                        if (contractExpirationDate && contractExpirationDate.isBefore(endDate)) {
                            endDate = contractExpirationDate;
                        }

                        const currentDate = serviceStartDate.clone();
                        while (currentDate.isSameOrBefore(endDate)) {
                            if (currentDate.isBefore(startDate)) {
                                periodsBefore += 1;
                            }
                            else if (currentDate.isSameOrAfter(startDate)) {
                                currentPeriods += 1;
                            }

                            currentDate.add(billingInterval, identifier);
                        }

                        return [periodsBefore, currentPeriods];
                    }

                    let calculatedPeriods = 0;

                    if (service.servicePeriod === ContractFinancialCompanyServicePeriodEnum.DELIVERIES) {
                        calculatedPeriods = service.periods || 1;
                    }
                    else {
                        const [periodsBefore, currentPeriods] = calcPeriods(
                            service.servicePeriod === ContractFinancialCompanyServicePeriodEnum.MONTHS ? 'months' :
                                service.servicePeriod === ContractFinancialCompanyServicePeriodEnum.YEARS ? 'years' :
                                    service.servicePeriod === ContractFinancialCompanyServicePeriodEnum.WEEKS ? 'weeks' :
                                        'months' // Fallback
                        );

                        const remainingPeriods = (service.periods * service.billingInterval || 1) - periodsBefore;

                        if (service.serviceType === ServiceTypeEnum.RUNNING || remainingPeriods >= currentPeriods) {
                            calculatedPeriods = currentPeriods;
                        }
                        else {
                            calculatedPeriods = remainingPeriods;
                        }

                        if (calculatedPeriods < 1) return 0;
                    }

                    const amount = service.quantity * Number(service.price);
                    const period = Math.round(calculatedPeriods);
                    return period * amount;

                }
        }

        return 0;

    }

}

export const newServiceHelper = new NewServiceHelper();