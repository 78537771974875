interface Props {
    onClick: () => void,
    label: string,
    isActive?: boolean,
    className?: string
}

const TabButton = (props: Props) => {

    const activeClass = 'rounded px-4 py-1 text-sm bg-red text-white';
    const inActiveClass = 'border rounded px-4 py-1 text-sm';

    return (
        <span className={props.className}>
            <button
                type='button'
                onClick={props.onClick}
                className={props.isActive ? activeClass : inActiveClass}>
                {props.label}
            </button>
        </span>
    )

}

export default TabButton;