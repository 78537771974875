import * as React from 'react';
import { observer } from 'mobx-react';
import { FieldState } from 'formstate';
import ContractDropdownItem from 'components/ContractDropdownItem';
import { languageStore } from 'stores';
import { EmploymentHelper } from 'helpers/EmploymentHelper';

interface Props {
    fieldState: FieldState<any>,
    editMode?: boolean
}

export enum EmploymentTypeEnum {
    APPRENTICE = 1,
    BY_THE_HOUR = 2,
    FULL_TIME = 3,
    INTERN = 4,
    PART_TIME = 5,
    PERMANENT = 6,
    STUDENT_AID = 7,
    TEMPORARY = 8,
    PUPIL = 9,
    CONSULTANT = 10,
    FLEXJOB = 11,
    FULL_TIME_VARYING_WEEKLY_WORKING_HOURS = 12,
    YOUNG_PEOPLE_UNDER_18_YEARS = 13
}

@observer class SelectEmploymentType extends React.Component<Props> {

    render() {
        const { fieldState, editMode } = this.props;

        const employmentOpts = EmploymentHelper.EmploymentTypes();

        return (
            <ContractDropdownItem
                label={languageStore.get('employmentType')}
                id='employmentType'
                placeholderText={languageStore.get('selectEmploymentType')}
                fieldState={fieldState}
                options={employmentOpts}
                type='number'
                editMode={editMode} />
        )
    }

}

export default SelectEmploymentType;