import * as React from 'react';
import Waypoint from 'react-waypoint';
import { navigationStore, languageStore } from 'stores';
import { observer } from 'mobx-react';
import { MdEdit } from 'react-icons/md';
import DraftRibbon from 'components/DraftRibbon';

import './style.css';

interface OptionalProps {
    waypointBottomOffset?: string,
    waypointTopOffset?: string,
    editMode?: boolean,
    hideSaveButton?: boolean,
    hideCancelButton?: boolean,
    showDraftRibbon?: boolean
    canEdit?: boolean
}

interface Props extends OptionalProps {
    hash: string,
    children: React.ReactNode,
    onEdit?: Function,
    noFlex?: boolean
}

@observer class ContractSection extends React.Component<Props> {

    public static defaultProps: OptionalProps = {
        waypointBottomOffset: '70%',
        waypointTopOffset: '29%'
    }

    private waypointEntered(hash: string) {
        if (!navigationStore.isScrolling) {
            navigationStore.setHash(hash);
        }
    }

    toggleEditMode(e: React.MouseEvent) {
        e.preventDefault();

        this.props.onEdit!();
    }

    render() {
        const { editMode, canEdit, hideSaveButton, hideCancelButton, showDraftRibbon, noFlex, onEdit, hash, children, waypointBottomOffset, waypointTopOffset } = this.props;
        const currentHash = navigationStore.currentHash;

        return (
            <div>
                <Waypoint scrollableAncestor={window} fireOnRapidScroll={false} bottomOffset={waypointBottomOffset} topOffset={waypointTopOffset} onEnter={() => this.waypointEntered(hash)}>
                    <div className='ContractSection' style={{
                        minWidth: '1080px',
                        position: 'relative',
                        // marginLeft: '16rem',
                        marginBottom: '4px',
                        background: currentHash === hash ? '#fafafa' : '#FDFDFD',
                        borderRight: '1px solid #f2f2f2',
                        borderTop: '1px solid #f2f2f2',
                        borderBottom: '1px solid #f2f2f2',
                        borderLeft: `2px solid ${currentHash === hash ? '#e4002b' : '#E3E3E3'}`
                    }}
                        id={hash}>
                        <div className={`${noFlex ? '' : ''}`}>
                            {
                                showDraftRibbon &&
                                <DraftRibbon />
                            }

                            <div className='py-3 pl-20 pb-10'>
                                {
                                    (canEdit && onEdit && !editMode) &&
                                    <div
                                        onClick={(e) => this.toggleEditMode(e)}
                                        className='text-center absolute cursor-pointer p-5'
                                        style={{ right: '0px', marginTop: '-10px', color: '#737070' }}>

                                        <MdEdit className='w-6 h-6' />
                                        <p className='text-sm'>
                                            {languageStore.get('edit')}
                                        </p>

                                    </div>
                                }

                                <div className={`${noFlex ? '' : 'flex'} mt-5`}>
                                    {children}
                                </div>
                            </div>
                        </div>

                        {
                            (canEdit && editMode && !hideSaveButton) &&
                            <div className='mb-10' style={{ paddingLeft: '5rem', marginTop: '-40px' }}>
                                <button type='submit' className='mt-5 bg-comablue-label text-white py-2 px-8 rounded'>
                                    {languageStore.get('saveChanges')}
                                </button>
                                {
                                    !hideCancelButton &&
                                    <button type='reset' className='mt-5 ml-2 border py-2 px-8 rounded'>
                                        {languageStore.get('cancel')}
                                    </button>
                                }
                            </div>
                        }

                    </div>
                </Waypoint>
            </div >

        );
    }

}

export default ContractSection;