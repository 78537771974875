import * as React from 'react';
import { observer } from 'mobx-react';
import BaseContract from 'components/StaffContractDetailsPage/BaseContract';
import { CurrentContractStore, languageStore, ContractStatusEnum, CurrentContractBaseInterface, StaffContractStatusEnum, DashboardContractTypeEnum, userStore, CompanyInterface } from 'stores';
import { FormState, FieldState } from 'formstate';
import ContractDetailsGroup from 'components/ContractDetailsGroup';
import ContractDetailsColumn from 'components/ContractDetailsColumn';
import ContractDateItem from 'components/ContractDateItem';
import ContractStatus from 'components/Forms/ContractStatus';
import ContractDetailsItem from 'components/ContractDetailsItem';
import * as Moment from 'moment-timezone';
import { ContractStatusHelper } from 'helpers/ContractStatusHelper';
import ContractTransactions from 'components/ContractTransactions';
import ContractStatusHistoryModal from 'components/ContractStatusHistoryModal';

interface Props {
    store: CurrentContractStore<CurrentContractBaseInterface>,
    hash: string,
    type: DashboardContractTypeEnum
}

@observer class ContractMetaStatus extends React.Component<Props> {

    form = new FormState({
        createdOn: new FieldState(this.props.store.contract!.createdOn),
        createdById: new FieldState(this.props.store.contract!.createdById),
        _createdByFullName: new FieldState(this.props.store.contract!._createdByFullName),
        modifiedOn: new FieldState(this.props.store.contract!.modifiedOn),
        modifiedById: new FieldState(this.props.store.contract!.modifiedById),
        _modifiedByFullName: new FieldState(this.props.store.contract!._modifiedByFullName),
        contractStatus: new FieldState(this.props.store.contract!.contractStatus),
        deletionDate: new FieldState(this.props.store.contract!.deletionDate),
        deletionProlongReason: new FieldState(this.props.store.contract!.deletionProlongReason),
        deletionNotice: new FieldState(this.props.store.contract!.deletionNotice),
    })

    constructor(props: Props) {
        super(props);
        const form = this.form.$;

        form.contractStatus.onUpdate((fs) => {
            let deleteTypeArray = this.getDeleteTypeArray();

            if (deleteTypeArray.indexOf(fs.$) !== -1) {
                if (!form.deletionDate.value) {

                    const deletionDate = Moment.utc().add(this.getDeletionDays(), 'days').toISOString();
                    form.deletionDate.onChange(deletionDate);
                }
                if (!form.deletionNotice.value) {
                    form.deletionNotice.onChange(90);
                }
            }
            else {
                form.deletionDate.reset(null);
                form.deletionNotice.reset(null);
            }
        });
    }

    getDeleteTypeArray() {
        const { type } = this.props;
        let deleteTypeArray: Array<Number> = [];

        if (type === DashboardContractTypeEnum.STAFF) {
            deleteTypeArray = [
                StaffContractStatusEnum.Applicant,
                StaffContractStatusEnum.Cancelled,
                StaffContractStatusEnum.Dismissed,
                StaffContractStatusEnum.Rejected,
                StaffContractStatusEnum.Resigned
            ]
        }
        else {
            deleteTypeArray = [
                ContractStatusEnum.Deleted,
                ContractStatusEnum.Terminated,
                ContractStatusEnum.Discontinued
            ];
        }

        return deleteTypeArray;
    }

    getDeletionDays = () => {
        const { type } = this.props;
        const fallbackDeletionDays = 1825;
        const company = userStore.signedInUser!.company as CompanyInterface;

        switch (type) {
            case DashboardContractTypeEnum.PURCHASE:
                return company.purchaseDefaultDaysBeforeDelete || fallbackDeletionDays;

            case DashboardContractTypeEnum.STAFF:
                return company.staffDefaultDaysBeforeDelete || fallbackDeletionDays;

            case DashboardContractTypeEnum.SERVICE:
                return company.serviceDefaultDaysBeforeDelete || fallbackDeletionDays;

            case DashboardContractTypeEnum.SALES:
                return company.salesDefaultDaysBeforeDelete || fallbackDeletionDays;
            default: return fallbackDeletionDays;
        }
    }

    getOptions() {
        const { type } = this.props;

        if (type === DashboardContractTypeEnum.STAFF) {
            return ContractStatusHelper.StaffContractStatus();
        }
        else {
            return ContractStatusHelper.BasicContractStatus();
        }
    }

    render() {
        const { store, hash, type } = this.props;
        const form = this.form.$;
        const editMode = store.isEditMode(hash);
        const showDelete = !store.isDraft && this.getDeleteTypeArray().indexOf(form.contractStatus.$) !== -1;

        return (
            <div>
                <BaseContract
                    store={store}
                    form={this.form}
                    sectionEnum={hash}>

                    <ContractDetailsGroup title={languageStore.get('status')}>

                        <ContractDetailsColumn>

                            <ContractDateItem
                                editMode={false}
                                fieldState={form.createdOn}
                                label={languageStore.get('createdOn')}
                                id='createdOn' />

                            {
                                !store.isDraft &&
                                <>
                                    <ContractStatus
                                        options={this.getOptions()}
                                        fieldState={form.contractStatus}
                                        editMode={editMode} />
                                    {
                                        !editMode &&
                                        <ContractStatusHistoryModal store={store} type={type} />
                                    }

                                </>
                            }

                        </ContractDetailsColumn>

                        <ContractDetailsColumn>

                            <ContractDetailsItem
                                label={languageStore.get('createdBy')}
                                id='createdBy'
                                fieldState={form._createdByFullName}
                                editMode={false} />

                            {
                                showDelete &&
                                <ContractDateItem
                                    editMode={editMode}
                                    fieldState={form.deletionDate}
                                    label={languageStore.get('deletionDate')}
                                    id='deletionDate' />
                            }

                        </ContractDetailsColumn>

                    </ContractDetailsGroup>

                    <ContractDetailsGroup title='&nbsp;'>

                        <ContractDetailsColumn>

                            <ContractDateItem
                                label={languageStore.get('lastModified')}
                                id='modifiedOn'
                                fieldState={form.modifiedOn}
                                editMode={false} />

                            {
                                showDelete &&
                                <ContractDetailsItem
                                    label={languageStore.get('noticeDays')}
                                    id='deletionNotice'
                                    fieldState={form.deletionNotice}
                                    type='number'
                                    editMode={editMode} />
                            }

                        </ContractDetailsColumn>

                        <ContractDetailsColumn>

                            <ContractDetailsItem
                                label={languageStore.get('modifiedBy')}
                                id='modifiedBy'
                                fieldState={form._modifiedByFullName}
                                editMode={false} />

                            {
                                showDelete &&
                                <ContractDetailsItem
                                    label={languageStore.get('deletionProlongReason')}
                                    id='deletionProlongReason'
                                    fieldState={form.deletionProlongReason}
                                    editMode={editMode} />
                            }

                        </ContractDetailsColumn>

                    </ContractDetailsGroup>
                </BaseContract>
                <ContractTransactions
                    store={store}
                    type={type}
                />
            </div>
        )
    }

}

export default ContractMetaStatus;