import * as React from 'react';
import { observer } from 'mobx-react';
import { FieldState } from 'formstate';
import ContractDateItem from 'components/ContractDateItem';
import { languageStore, toastStore } from 'stores';
import * as Moment from 'moment-timezone';

interface Props {
    fieldState: FieldState<any>,
    editMode?: boolean,
    expirationDate?: string
}

@observer class RenewalDate extends React.Component<Props> {

    beforeChange = (value: string) => {
        const { expirationDate } = this.props;
        if (value && expirationDate) {
            const rDate = Moment.utc(value);
            const eDate = Moment.utc(expirationDate);

            if (rDate.isAfter(eDate)) {
                toastStore.addToast({
                    level: 'warning',
                    title: languageStore.get('warning'),
                    message: languageStore.get('renewalIsAfterExpirationDate')
                })
            }
        }
    }

    render() {
        const { fieldState, editMode } = this.props;

        return (
            <ContractDateItem
                label={languageStore.get('renewalDate')}
                id='renewalDate'
                fieldState={fieldState}
                beforeChange={this.beforeChange}
                editMode={editMode} />
        )
    }

}

export default RenewalDate;

