import * as React from 'react';
import { observer } from 'mobx-react';
import ContractSection from 'components/ContractSection';
import ContractDetailsGroup from 'components/ContractDetailsGroup';
import ReactTable from 'react-table';
import Form from 'components/Forms/Form';
import { Modal } from 'components/Modal';
import { languageStore, CurrentContractStore, CurrentContractBaseInterface, currencyStore } from 'stores';
import { FormState } from 'formstate';
import EmptyContractSection from 'components/EmptyContractSection';
import * as _ from 'lodash';
import { formatCurrency } from 'helpers/CurrencyHelper';

interface Props {
    store?: CurrentContractStore<CurrentContractBaseInterface>,
    hash: string,
    title: string,
    subTitle?: React.ReactNode,
    modalTitle: string,
    columns: Array<any>,
    data: Array<any>,
    formState: FormState<any>,
    onShowModal?: Function,
    onClickRowWhenEditModeDisabled?: Function,
    onSubmit: Function,
    onDelete?: Function,
    hideAddButton?: boolean,
    hideCloseButton?: boolean,
    totalSum?: number,
    onHideModal?: Function
}

interface State {
    isShowModal: boolean
}

@observer class ContractDetailsTable extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            isShowModal: false
        }
    }

    showModal = async (id: Number | null) => {
        const { onShowModal } = this.props;
        if (onShowModal) {
            await onShowModal(id);
        }

        this.setState({ isShowModal: true });
    }

    hideModal = () => {
        const { onHideModal } = this.props;

        if (onHideModal) {
            onHideModal();
        }

        this.setState({ isShowModal: false });
    }

    toggleEditMode = () => {
        const { hash, store } = this.props;
        if (store) {
            const editMode = store.isEditMode(hash);
            store.setEditMode(hash, !editMode)
        }
    }

    onSubmit = async () => {
        await this.props.onSubmit();
        this.hideModal();
    }

    onDelete = async (e: React.MouseEvent) => {
        e.preventDefault();
        const { formState, onDelete } = this.props;
        if (!onDelete) return;

        if (window.confirm(`${languageStore.get('areYouSureYouWantToDelete')}?`)) {
            const id = formState.$.id.$;
            if (id) {
                await onDelete(id);
            }
        }

        this.hideModal();
    }

    render() {
        const { store, totalSum, title, subTitle, hideAddButton, onClickRowWhenEditModeDisabled, hideCloseButton, onDelete, columns, data, children, formState, modalTitle, hash } = this.props;
        const { isShowModal } = this.state;
        const editMode = store ? store.isEditMode(hash) : false;
        const isNewEntity = !!!this.props.formState.$.id.$;

        if (store && data && data.length === 0 && !isShowModal) {
            return <EmptyContractSection
                store={store!}
                hash={hash}
                hideAdd={hideAddButton}
                onAdd={this.showModal}
                title={title} />
        }

        let currencyCode = null;
        if (store && store.contract!.currencyId && currencyStore.currencies) {
            const currency = _.find(currencyStore.currencies, {id: store.contract!.currencyId});
            if (currency) {
                currencyCode = currency.code
            }
        }

        return (
            <div>
                <ContractSection
                    noFlex={true}
                    onEdit={() => store && store.setEditMode(hash, !editMode)}
                    hash={hash}
                    editMode={editMode}
                    canEdit={store ? store.canEdit : undefined}
                    hideSaveButton={true}>

                    <ContractDetailsGroup
                        fullWidth={true}
                        title={title}
                        subTitle={subTitle}>

                        <div className='flex-grow mt-5'>
                            <ReactTable
                                className={editMode || onClickRowWhenEditModeDisabled ? '' : 'ReactTable-readonly'}
                                columns={columns}
                                data={data}
                                showPagination={false}
                                defaultPageSize={99999}
                                NoDataComponent={() => null}
                                getTdProps={(state: any, rowInfo: any, column: any, instance: any) => {
                                    return {
                                        onClick: (e: React.MouseEvent, handleOriginal: any) => {
                                            const id = rowInfo.original.id;

                                            if (editMode) {
                                                this.showModal(id);
                                            }
                                            else {
                                                if (onClickRowWhenEditModeDisabled) {
                                                    onClickRowWhenEditModeDisabled(id);
                                                }
                                            }
                                        }
                                    };
                                }}
                                minRows={0} />

                            {
                                (totalSum !== undefined && currencyCode) &&
                                <p style={{ marginTop: '20px', marginLeft: '5px' }}>
                                    <strong>{languageStore.get('total')}: </strong>{formatCurrency(currencyCode, totalSum)}
                                </p>
                            }

                            {
                                editMode &&
                                <div className='mt-5'>
                                    {
                                        !hideAddButton &&
                                        <button onClick={() => this.showModal(null)} className='bg-comablue-label text-white py-2 px-8 rounded'>
                                            {languageStore.get('add')}
                                        </button>
                                    }

                                    {
                                        !hideCloseButton &&
                                        <button onClick={this.toggleEditMode} className='text-grey-darker border ml-2 py-2 px-4 rounded'>
                                            {languageStore.get('close')}
                                        </button>
                                    }


                                </div>
                            }

                        </div>
                    </ContractDetailsGroup>

                </ContractSection>

                {
                    isShowModal &&
                    <Form onValidSubmit={this.onSubmit} formState={formState}>
                        <Modal
                            title={modalTitle}
                            primaryButton={{ title: languageStore.get('save') }}
                            secondaryButton={{ title: languageStore.get('cancel'), onClick: this.hideModal }}>

                            {children}

                            {
                                (editMode && !isNewEntity && onDelete) &&
                                <a href=" " style={{ position: 'absolute', bottom: '45px', left: '40px' }} onClick={(e) => this.onDelete(e)}>{languageStore.get('delete')}</a>
                            }

                        </Modal>
                    </Form>
                }

            </div>
        );
    }
}

export default ContractDetailsTable;