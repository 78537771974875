import * as React from 'react';
import { observer } from 'mobx-react';
import { FormState, FieldState } from 'formstate';
import { required } from 'helpers/FormValidation';
import { financeAccountStore, languageStore, locationStore, LocationInterface, FinanceAccountInterface, userStore } from 'stores';
import TableHeader from 'components/Table/TableHeader';
import DetailsPage from '../DetailsPage';
import { ModalColumn } from 'components/Modal';
import Input from 'components/Forms/Input';
import Location from 'components/Forms/Location';
import * as _ from 'lodash';

@observer class AccountNumbersPage extends React.Component {

    form = new FormState({
        id: new FieldState<string | null>(null),
        accNumber: new FieldState(null),
        accTitle: new FieldState(null),
        locationId: new FieldState(null).validators(required)
    })

    componentDidMount() {
        financeAccountStore.getAccounts(true);

        const companyId = userStore.signedInUser.company.id;
        locationStore.getLocationsByCompany(companyId);
    }

    onDeleteAccount = async (accountId: string) => {
        const acc = _.find(financeAccountStore.accounts, { id: accountId }) as FinanceAccountInterface;
        const locationId = acc.locationId;

        await financeAccountStore.deleteAccount(locationId, accountId);
    }

    render() {
        const form = this.form.$;
        const isNewEntity = !!!form.id.$;
        const data = financeAccountStore.accounts || [];
        const locations = locationStore.locations || [];

        const columns = [
            {
                Header: <TableHeader value={languageStore.get('account')} />,
                accessor: 'accNumber'
            },
            {
                Header: <TableHeader value={languageStore.get('accountTitle')} />,
                accessor: 'accTitle'
            },
            {
                Header: <TableHeader value={languageStore.get('location')} />,
                id: 'location',
                accessor: (d: any) => {
                    const locationId = d.locationId;
                    const location = _.find(locations, { id: locationId }) as LocationInterface;
                    return location ? location.name : '-';
                }
            },
        ]

        return (
            <DetailsPage
                fullWidth={true}
                generalTitle={languageStore.get('accountNumbers')}
                modalTitle={languageStore.get('accountNumbers')}
                form={this.form}
                columns={columns}
                collection={data}
                onUpdate={(acc: FinanceAccountInterface) => financeAccountStore.updateAccount(acc)}
                onSubmit={(acc: FinanceAccountInterface) => financeAccountStore.createAccount(acc)}
                onDelete={(id: string) => this.onDeleteAccount(id)}>

                <ModalColumn>

                    <Input
                        fieldState={form.accNumber}
                        id='account'
                        label={languageStore.get('account')} />

                    <Input
                        fieldState={form.accTitle}
                        id='title'
                        label={languageStore.get('title')} />

                    {
                        isNewEntity &&
                        <Location fieldState={form.locationId} editMode={true} />
                    }

                </ModalColumn>


            </DetailsPage>
        )
    }

}

export default AccountNumbersPage;