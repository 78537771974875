import * as React from 'react';
import { observer } from 'mobx-react';
import { FieldState } from 'formstate';
import ContractDropdownItem from 'components/ContractDropdownItem';
import { languageStore, ContractFinancialCompanyServiceStatusEnum } from 'stores';

interface Props {
    fieldState: FieldState<any>
}

@observer class SelectStatus extends React.Component<Props> {

    options = [
        {
            value: ContractFinancialCompanyServiceStatusEnum.AGREED,
            name: languageStore.get('agreed')
        },
        {
            value: ContractFinancialCompanyServiceStatusEnum.DELETED_OR_HIDDEN,
            name: languageStore.get('deletedOrHidden')
        }
    ]

    render() {
        const { fieldState } = this.props;

        return <ContractDropdownItem
            label={languageStore.get('status')}
            placeholderText={languageStore.get('selectStatus')}
            id='serviceStatus'
            fieldState={fieldState}
            options={this.options}
            skipSort={true}
            type='number'
            editMode={true} />
    }

}

export default SelectStatus;