import { BaseStore } from './BaseStore';
import { observable, action, makeObservable } from 'mobx';
import { CompanyInterface } from './CompanyStore';
import request from 'api/request';

export interface CompanyAdminRoleInterface {
    "companyId": string,
    "companyName": string,
    "operationalCount": number
    "readCount": number
    "noRoleCount": number
}

export interface CompanyAdminStatsInterface {
    "companyCount": number
    "userCount": number
    "companyRoles": Array<CompanyAdminRoleInterface>
}

export class SuperAdminStore extends BaseStore {
    @observable companies: Array<CompanyInterface> = [];

    @observable stats: CompanyAdminStatsInterface | null = null;

    constructor() {
        super();

        makeObservable(this);
    }

    @action
    getCompanies(force: boolean = true) {
        return this.get('companies', 'companies', force);
    }

    @action
    getCompany(id: string) {
        return this.getById(`companies/${id}`, this.companies);
    }

    @action
    updateCompany(company: CompanyInterface) {
        return this.update(`companies/${company.id}`, company, this.companies);
    }

    @action
    getStats(force: boolean = true) {
        return this.get('dashboard/admin/stats', 'stats', force);
    }

    @action
    resyncElasticSeach(companyId: string) {
        return request.post(`companies/${companyId}/resync-elastic`);
    }

    @action
    deleteCompany(companyId: string) {
        return this.delete(`companies/${companyId}/hard`, companyId, this.companies);
    }
}

export const superAdminStore = new SuperAdminStore();