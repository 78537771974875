import React from 'react';
import { observer } from 'mobx-react';
import { ExpirationEnum } from '../ServiceHelper';

interface Props {
    activeExpiration: ExpirationEnum,
    expiration: ExpirationEnum
    onClick: (expiration: ExpirationEnum) => void,
    label: string
}

@observer class ToggleExpiration extends React.Component<Props> {

    render() {
        const { activeExpiration, expiration, onClick, label } = this.props;

        return (
            <button
                className={`${expiration !== activeExpiration ? 'underline text-red' : 'font-semibold'}`}
                onClick={(e) => onClick(expiration)}>{label}
            </button>
        )
    }
}

export default ToggleExpiration;