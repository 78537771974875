import * as React from 'react';
import { observer } from 'mobx-react';
import { FormState, FieldState } from 'formstate';
import { currentStaffContractStore as store, languageStore } from 'stores';
import BaseContract from '../BaseContract';
import SectionEnum from '../SectionEnum';
import ContractDetailsGroup from 'components/ContractDetailsGroup';
import ContractDetailsColumn from 'components/ContractDetailsColumn';
import ContractDateItem from 'components/ContractDateItem';
import SelectAgreement from './SelectAgreement';
import ContractDetailsItem from 'components/ContractDetailsItem';
import SelectTrueFalse from 'components/Forms/SelectTrueFalse';
import Currency from 'components/Forms/Currency';
import { required } from 'helpers/FormValidation';

@observer class Agreement extends React.Component {

    form = new FormState({
        agreementId: new FieldState(store.contract!.agreementId),
        salary: new FieldState(store.contract!.salary),
        hourlyWage: new FieldState(store.contract!.hourlyWage),
        bonusAgreement: new FieldState(store.contract!.bonusAgreement),
        bonusNegotiationDate: new FieldState(store.contract!.bonusNegotiationDate),
        employerShare: new FieldState(store.contract!.employerShare),
        additionalDeposits: new FieldState(store.contract!.additionalDeposits),
        pensionStartDate: new FieldState(store.contract!.pensionStartDate),
        employeeShare: new FieldState(store.contract!.employeeShare),
        pensionCompany: new FieldState(store.contract!.pensionCompany),
        healthInsurance: new FieldState(store.contract!.healthInsurance),
        validWorkPermit: new FieldState(store.contract!.validWorkPermit),
        currencyId: new FieldState(store.contract!.currencyId).validators(required),
        introCourse: new FieldState(store.contract!.introCourse),
    })

    render() {
        const form = this.form.$;
        const editMode = store.isEditMode(SectionEnum.AGREEMENT);

        return (
            <BaseContract
                store={store}
                form={this.form}
                sectionEnum={SectionEnum.AGREEMENT}>

                <ContractDetailsGroup title={languageStore.get('agreement')}>

                    <ContractDetailsColumn>

                        <SelectAgreement
                            store={store}
                            fieldState={form.agreementId}
                            editMode={editMode} />

                        <ContractDetailsItem
                            label={languageStore.get('salary')}
                            id='salary'
                            type='number'
                            fieldState={form.salary}
                            editMode={editMode} />

                        <SelectTrueFalse
                            label={languageStore.get('bonusAgreement')}
                            id='bonusAgreement'
                            fieldState={form.bonusAgreement}
                            editMode={editMode} />

                        <SelectTrueFalse
                            label={languageStore.get('introCourse')}
                            id='introCourse'
                            fieldState={form.introCourse}
                            editMode={editMode} />

                    </ContractDetailsColumn>

                    <ContractDetailsColumn>

                        <ContractDetailsItem
                            label={languageStore.get('hourlyWage')}
                            id='hourlyWage'
                            type='number'
                            fieldState={form.hourlyWage}
                            editMode={editMode} />


                        <Currency
                            store={store}
                            fieldState={form.currencyId}
                            editMode={editMode} />


                        <ContractDateItem
                            label={languageStore.get('bonusNegotiation')}
                            id='bonusNegotiationDate'
                            fieldState={form.bonusNegotiationDate}
                            editMode={editMode} />

                    </ContractDetailsColumn>

                </ContractDetailsGroup>

                <ContractDetailsGroup title={languageStore.get('retirementIssues')}>

                    <ContractDetailsColumn>

                        <ContractDetailsItem
                            label={languageStore.get('employerShare')}
                            id='employerShare'
                            fieldState={form.employerShare}
                            editMode={editMode} />

                        <ContractDetailsItem
                            label={languageStore.get('additionalDeposits')}
                            id='additionalDeposits'
                            fieldState={form.additionalDeposits}
                            editMode={editMode} />

                        <ContractDateItem
                            label={languageStore.get('pensionStartDate')}
                            id='pensionStartDate'
                            fieldState={form.pensionStartDate}
                            editMode={editMode} />

                    </ContractDetailsColumn>

                    <ContractDetailsColumn>

                        <ContractDetailsItem
                            label={languageStore.get('employeeShare')}
                            id='employeeShare'
                            fieldState={form.employeeShare}
                            editMode={editMode} />

                        <ContractDetailsItem
                            label={languageStore.get('pensionCompany')}
                            id='pensionCompany'
                            fieldState={form.pensionCompany}
                            editMode={editMode} />

                        <SelectTrueFalse
                            label={languageStore.get('healthInsurance')}
                            id='healthInsurance'
                            fieldState={form.healthInsurance}
                            editMode={editMode} />

                    </ContractDetailsColumn>

                </ContractDetailsGroup>


            </BaseContract>
        )
    }

}

export default Agreement;