import { BaseStore } from './BaseStore';
import { observable, action, makeObservable } from 'mobx';
import request from 'api/request';

export interface TransactionInterface {
    transactionId: string,
    createdOn: string,
    createdBy: {
        id: string,
        fullName: string
    }
}

export interface AuditChangeInterface {
    newIdentification: string,
    newValue: string,
    originalIdentification: string,
    originalValue: string,
    propName: string
}

export enum AuditActionEnum {
    INSERTED = "Insert",
    UPDATED = "Update",
    ARCHIVED = "SoftDelete",
    DELETED = "Delete"
}

export interface AuditInterface {
    auditedOn: string,
    auditedById?: string,
    action: AuditActionEnum
    changes?: string
}

export interface TransactionDetailsInterface {
    id: string,
    transactionId: string,
    purchaseContractId: string,
    staffContractId: string,
    salesContractId: string,
    serviceContractId: string,
    alarmAudits: Array<TransactionDetailsEntityInterface>,
    categoryAudits: Array<TransactionDetailsEntityInterface>,
    contactPersonAudits: Array<TransactionDetailsEntityInterface>,
    contractContactPersonAudits: Array<TransactionDetailsEntityInterface>,
    contractFinanceAccountAudits: Array<TransactionDetailsEntityInterface>,
    customerAudits: Array<TransactionDetailsEntityInterface>,
    documentAudits: Array<TransactionDetailsEntityInterface>,
    financialServiceAudits: Array<TransactionDetailsEntityInterface>,
    noteAudits: Array<TransactionDetailsEntityInterface>,
    purchaseContractAudits: Array<TransactionDetailsEntityInterface>,
    salesContractAudits: Array<TransactionDetailsEntityInterface>,
    serviceContractAudits: Array<TransactionDetailsEntityInterface>,
    serviceContractServiceAudits: Array<TransactionDetailsEntityInterface>,
    skillAudits: Array<TransactionDetailsEntityInterface>,
    staffAppraisalInterviewAudits: Array<TransactionDetailsEntityInterface>,
    staffBenefitAudits: Array<TransactionDetailsEntityInterface>,
    staffContractAudits: Array<TransactionDetailsEntityInterface>,
    staffEducationAudits: Array<TransactionDetailsEntityInterface>,
    staffEquipmentAudits: Array<TransactionDetailsEntityInterface>,
    staffSkillAudits: Array<TransactionDetailsEntityInterface>,
    subCategoryAudits: Array<TransactionDetailsEntityInterface>,
    supplierAudits: Array<TransactionDetailsEntityInterface>,
    contractCorrespondanceAudits: Array<TransactionDetailsEntityInterface>,
    contractDocumentAudits: Array<TransactionDetailsEntityInterface>,
}

export interface TransactionDetailsEntityInterface {
    entityId: string,
    action: string,
    auditedById: string,

}

export class TransactionsStore extends BaseStore {
    @observable transactions: Array<TransactionInterface> = [];
    @observable transactionDetails: Array<TransactionDetailsInterface> = [];

    transactionPromise: Promise<any> | null = null;
    @observable transactionKey: string | null = null;
    @observable requiresTransactionKey: boolean = false;
    @observable currentContractId: string | null = null;
    @observable contractEndpoint: string | null = null;

    constructor() {
        super();

        makeObservable(this);
    }

    @action
    getTransactions(contractId: string, endpoint: string) {
        return this.get(`contracts/${endpoint}/${contractId}/transactions`, 'transactions');
    }

    getTransactionDetails(endpoint: string, contractId: string, transactionKey: string) {
        return this.getById(`contracts/${endpoint}/${contractId}/transactions/${transactionKey}`, this.transactionDetails);
    }

    resetStore() {
        this.resetTransactions();
        this.resetTransactionDetails();
    }

    @action
    resetTransactions() {
        this.transactions = [];
    }

    @action
    resetTransactionDetails() {
        this.transactionDetails = [];
    }

    @action
    setTransactionKey(key: string) {
        this.transactionKey = key;
    }

    @action
    setRequiresTransactionKey(endpoint: string, contractId: string) {
        this.requiresTransactionKey = true;
        this.currentContractId = contractId;
        this.contractEndpoint = endpoint;
    }

    @action
    async getTransactionKey() {
        if (!this.transactionPromise) {
            this.transactionPromise = new Promise(async(resolve, reject) => {
                const res = await request.get(`contracts/${this.contractEndpoint}/${this.currentContractId}/transactionKey`);
                if (res.data) {
                    this.setTransactionKey(res.data.transactionId);
                    resolve('');
                }
                reject();
            })
        }
        return this.transactionPromise;
    }

    @action
    resetTransactionKey() {
        this.transactionKey = null;
        this.requiresTransactionKey = false;
        this.currentContractId = null;
        this.contractEndpoint = null;
        this.transactionPromise = null;
    }
}

export const transactionsStore = new TransactionsStore();