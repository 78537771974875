import { useEffect } from 'react';
import { observer } from "mobx-react";
import SuppliersCustomersTable, { CustomerSupplierEnum } from 'components/SuppliersCustomersTable';
import { customerStore } from 'stores';

const CustomersPage = observer(() => {

    useEffect(() => {
        customerStore.getCustomers();
    }, [])

    const customers = customerStore.customers || [];

    return (
        <div className='mx-auto'>
            <SuppliersCustomersTable
                data={customers}
                type={CustomerSupplierEnum.CUSTOMER}
            />
        </div>
    )
})

export default CustomersPage;