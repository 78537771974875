import * as React from 'react';
import { observer } from 'mobx-react';
import { FormState, FieldState } from 'formstate';
import { documentTypeStore, userStore, languageStore, DocumentTypeInterface } from 'stores';
import TableHeader from 'components/Table/TableHeader';
import DetailsPage from '../DetailsPage';
import { ModalColumn } from 'components/Modal';
import Input from 'components/Forms/Input';
import SelectTrueFalse from 'components/Forms/SelectTrueFalse';
import * as _ from 'lodash';
import { DocumentTypeContractTypeEnum } from 'stores';

interface Props {
    contractType: DocumentTypeContractTypeEnum
}

@observer class DocumentTypes extends React.Component<Props> {

    form = new FormState({
        id: new FieldState<string | null>(null),
        name: new FieldState(''),
        required: new FieldState(false)
    })

    componentDidMount() {
        documentTypeStore.getDocumentTypes(userStore.signedInUser.company.id);
    }

    beforeSubmit = (docType: DocumentTypeInterface) => {
        const { contractType } = this.props;
        docType.contractType = contractType;

        if (docType.required === null) {
            docType.required = false;
        }
    }

    render() {
        const { contractType } = this.props;
        const form = this.form.$;
        const data = _.filter(documentTypeStore.documentTypes || [], { contractType: contractType });
        const isNewEntity = !!!form.id.$;

        const columns = [
            {
                Header: <TableHeader value={languageStore.get('documentName')} />,
                accessor: 'name'
            },
            {
                Header: <TableHeader value={languageStore.get('required')} />,
                id: 'required',
                accessor: (d: DocumentTypeInterface) => {
                    return d.required ? languageStore.get('yes') : languageStore.get('no');
                }
            }
        ]

        return (
            <DetailsPage
                fullWidth={true}
                generalTitle={languageStore.get('documentTypes')}
                modalTitle={languageStore.get('documentType')}
                form={this.form}
                columns={columns}
                collection={data}
                beforeSubmit={this.beforeSubmit}
                onUpdate={(doc: DocumentTypeInterface) => documentTypeStore.updateDocumentType(userStore.signedInUser.company.id, doc)}
                onSubmit={(doc: DocumentTypeInterface) => documentTypeStore.createDocumentType(userStore.signedInUser.company.id, doc)}
                onDelete={(id: string) => documentTypeStore.deleteDocumentType(userStore.signedInUser.company.id, id)} >

                <ModalColumn>

                    <Input
                        fieldState={form.name}
                        id='name'
                        label={languageStore.get('documentName')} />

                    {
                        isNewEntity &&
                        <SelectTrueFalse
                            fieldState={form.required}
                            id='required'
                            editMode={true}
                            label={languageStore.get('required')} />
                    }


                </ModalColumn>

            </DetailsPage>
        )
    }

}

export default DocumentTypes;