import { action, makeObservable, observable } from "mobx";
import { BaseStore } from "./BaseStore"
import { InboxMessageInterface } from "./InboxStore";

export class InboxMessageStore extends BaseStore {

    @observable message: InboxMessageInterface | null = null;

    constructor() {
        super();

        makeObservable(this);
    }

    @action
    resetStore = () => {
        this.message = null;
    }

    @action
    getMessage = (inboxId: string, messageId: string) => {
        return this.get(`inboxes/${inboxId}/messages/${messageId}`, 'message');
    }

}

export const inboxMessageStore = new InboxMessageStore();