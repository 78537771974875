import * as React from 'react';
import { observer } from 'mobx-react';

interface Props {
    children: React.ReactNode
    width?: string
}

@observer class ContractDetailsColumn extends React.Component<Props> {


    render() {
        const { children, width } = this.props;
        var realWidth = "w-1/2";
        
        if(width)
        {
            realWidth = width;
        }

        return (
            <dl className={`${realWidth} pr-2`}>
                {children}
            </dl>
        )
    }

}

export default ContractDetailsColumn;