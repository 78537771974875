import { observer } from "mobx-react";
import { DocumentTypeContractTypeEnum, languageStore, navigationStore } from "stores";
import SidebarNavigation from 'components/AdminPage/SidebarNavigation';
import { useLocation, useParams } from "react-router";
import useCategoryByParams from "./useCategoryByParams";
import { categoryContractTypeToAdminRootEndpoint } from "helpers/CategoryHelper";

interface Props {
    children: React.ReactNode
}

const CategoryDetailsPage = observer((props: Props) => {
    const category = useCategoryByParams();
    const { pathname } = useLocation();
    const { id } = useParams<{ id: string }>();

    if (!category) return null;

    return (
        <div>
            <div className='text-left flex-1'>
                <h2 className='mt-2 mb-5'>
                    <button 
                        className='mr-2 text-red' 
                        onClick={() => navigationStore.push(categoryContractTypeToAdminRootEndpoint(category.contractType as DocumentTypeContractTypeEnum))}>
                        &larr;
                    </button>
                    {`${languageStore.get('edit')} ${languageStore.get('category', false)}`} ({category.name})
                </h2>
            </div>

            <div className='flex'>
                <SidebarNavigation
                    items={[
                        {
                            title: languageStore.get('category'),
                            defaultExpanded: true,
                            subItems: [
                                {
                                    label: languageStore.get('general'),
                                    onClick: () => navigationStore.push(`/admin/categories/${id}`),
                                    isSelected: pathname === `/admin/categories/${id}`
                                },
                                {
                                    label: languageStore.get('subCategories'),
                                    onClick: () => navigationStore.push(`/admin/categories/${id}/subcategories`),
                                    isSelected: pathname === `/admin/categories/${id}/subcategories`
                                }
                            ]
                        }
                    ]}
                />

                <div className='flex-1 ml-6'>
                    {props.children}
                </div>
            </div>
        </div>
    )

})

export default CategoryDetailsPage;