import DefaultButton from "components/Forms/DefaultButton";
import Form from "components/Forms/Form";
import { Modal, ModalColumn } from "components/Modal";
import SelectCompanyUser from "components/SelectCompanyUser";
import { FieldState, FormState } from "formstate";
import { required } from "helpers/FormValidation";
import { useState } from "react";
import { languageStore } from "stores";

interface Props {
    skipUserIds?: Array<string>,
    onSubmit: (userId: string) => void,
    companyId: string
}

const AddUserModal = (props: Props) => {
    const [showModal, setShowModal] = useState(false);

    const form = new FormState({
        userId: new FieldState('').validators(required)
    })

    const onSubmit = () => {
        props.onSubmit(form.$.userId.$);

        form.reset();
        setShowModal(false);
    }

    return (
        <div className='text-left'>
            <DefaultButton
                onClick={() => setShowModal(true)}
                label={languageStore.get('add')}
            />
            {
                showModal &&
                <Form onValidSubmit={onSubmit} formState={form}>
                    <Modal
                        title={languageStore.get('add')}
                        primaryButton={{ title: languageStore.get('save') }}
                        secondaryButton={{ title: languageStore.get('cancel'), onClick: () => setShowModal(false) }}>

                        <ModalColumn>
                            <SelectCompanyUser
                                fieldState={form.$.userId}
                                id='userId'
                                companyId={props.companyId}
                                label={languageStore.get('user')}
                                skipIds={props.skipUserIds}
                            />
                        </ModalColumn>
                    </Modal>
                </Form>
            }
        </div>
    )
}

export default AddUserModal;