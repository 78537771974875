import Form from "components/Forms/Form";
import SelectDate from "components/Forms/SelectDate";
import TableHeader from "components/Table/TableHeader";
import { FieldState, FormState } from "formstate";
import { formatDate } from "helpers/DateHelper";
import { required } from "helpers/FormValidation";
import { observer } from "mobx-react";
import moment from "moment";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ReactTable from "react-table";
import { companyUserStore, languageStore } from "stores";

const _PAGESIZE = 200;

const form = new FormState({
    from: new FieldState('').validators(required),
    to: new FieldState('').validators(required)
})

const Log = observer(() => {
    const { id } = useParams<{ id: string }>();
    const [fromDate, setFromDate] = useState<string | null>(null);
    const [toDate, setToDate] = useState<string | null>(null);

    useEffect(() => {
        companyUserStore.getActivityLogs(id, 1, _PAGESIZE);
        return () => {
            companyUserStore.resetStore();
        }
    }, [id])

    const loadMoreActivities = () => {
        companyUserStore.getActivityLogs(id, companyUserStore.activityLogs!.currentPage + 1, _PAGESIZE, fromDate || undefined, toDate || undefined);
    }

    const onSubmit = () => {
        const from = moment(form.$.from.$).startOf('day').toISOString();
        const to = moment(form.$.to.$).endOf('day').toISOString();

        setFromDate(from);
        setToDate(to);

        companyUserStore.getActivityLogs(id, 1, _PAGESIZE, from, to);
    }

    const onClearDate = () => {
        setFromDate(null);
        setToDate(null);
        form.reset();

        companyUserStore.getActivityLogs(id, 1, _PAGESIZE);
    }

    const pagedResults = companyUserStore.activityLogs;
    if (!pagedResults) return null;

    return (
        <div className='mt-10 mx-auto'>

            <Form onValidSubmit={onSubmit} formState={form}>
                <div className="flex items-center justify-center bg-grey-lightest p-4 mb-10">
                    <SelectDate
                        fieldState={form.$.from}
                        label={languageStore.get('from')}
                    />
                    <div className="mx-2">
                        <SelectDate
                            fieldState={form.$.to}
                            label={languageStore.get('to')}
                        />
                    </div>
                    <div style={{ marginTop: '8px' }}>
                        <button className="bg-comablue-label text-white py-3 px-8 rounded">{languageStore.get('filter')}</button>
                        {
                            (fromDate || toDate) &&
                            <button
                                onClick={onClearDate}
                                className='ml-2 border py-3 px-8 rounded'
                                type='button'>{languageStore.get('clear')}</button>
                        }
                    </div>
                </div>
            </Form>

            <ReactTable
                data={pagedResults.results.slice()}
                columns={[
                    {
                        Header: <TableHeader value={languageStore.get('when')} />,
                        id: 'operationTime',
                        accessor: (d: any) => formatDate(d.operationTime, true),
                        width: 120
                    },
                    {
                        Header: <TableHeader value='IP' />,
                        accessor: 'ip',
                        width: 110
                    },
                    {
                        Header: <TableHeader value={languageStore.get('details')} />,
                        accessor: 'activityDetails'
                    },
                    {
                        Header: <TableHeader value={languageStore.get('parameters')} />,
                        accessor: 'parameters'
                    },
                    {
                        Header: <TableHeader value={languageStore.get('status')} />,
                        id: 'statusCode',
                        width: 50,
                        accessor: (d: any) => {
                            if (d.statusCode === 200 || d.statusCode === 201) {
                                return d.statusCode;
                            }

                            return <span className='text-red'>{d.statusCode}</span>;
                        }
                    }
                ]}
                className='ReactTable-readonly'
                defaultPageSize={99999}
                sortable={false}
                manual
                showPagination={false}
                NoDataComponent={() => null}
                minRows={0} />

            {
                pagedResults.currentPage < pagedResults.pageCount &&
                <div className='text-center mt-12'>
                    <button
                        className='px-8 py-2 border rounded hover:bg-grey-lightest'
                        onClick={loadMoreActivities}>
                        {languageStore.get('loadMoreEntries')}
                    </button>
                </div>
            }
        </div>
    )
})

export default Log;