import React from 'react';
import { observer } from 'mobx-react';
import { Modal, ModalColumn } from 'components/Modal';
import { currencyStore, DashboardContractTypeEnum, dashboardStore, languageStore, navigationStore } from 'stores';
import { BaseContractTypeEnum } from 'stores/BaseStore';
import { forecastStore, ForecastInterface } from 'stores/ForecastStore';
import _ from 'lodash';
import ReactTable from 'react-table';
import TableGreenHeader from 'components/Table/TableGreenHeader';

interface Props {
    onClose: () => void,
    contractType: DashboardContractTypeEnum,
    year: number,
    month: number,
    currencyIndex: number
}

interface ResultInterface {
    contract: ForecastInterface,
    sum: number
}

interface State {
    results: Array<ResultInterface>,
}

@observer class ForecastModal extends React.Component<Props, State> {

    state = { results: [] }


    dashboardContractTypeToBaseContractType(contractType: DashboardContractTypeEnum) {
        if (contractType === DashboardContractTypeEnum.PURCHASE) return BaseContractTypeEnum.PURCHASE;
        else if (contractType === DashboardContractTypeEnum.STAFF) return BaseContractTypeEnum.STAFF;
        else if (contractType === DashboardContractTypeEnum.SALES) return BaseContractTypeEnum.SALES;
        else if (contractType === DashboardContractTypeEnum.SERVICE) return BaseContractTypeEnum.SERVICE;
        else return null;
    }

    async componentDidMount() {
        const { contractType, year, month, currencyIndex } = this.props;

        currencyStore.getCurrencies();

        let cType = this.dashboardContractTypeToBaseContractType(contractType);
        if (!cType) return;

        await dashboardStore.getForecasts(contractType, year, true);
        const contractsByCurrencyId = dashboardStore.forecast?.contractSumSumsByCurrencyId!;
        const monthData = contractsByCurrencyId[Object.keys(contractsByCurrencyId)[currencyIndex]][month];
        await forecastStore.getData(cType, Object.keys(monthData.contractSum));

        const results: Array<ResultInterface> = [];

        Object.keys(monthData.contractSum).forEach((contractId) => {
            results.push({
                contract: _.find(forecastStore.contracts, { id: contractId }) as ForecastInterface,
                sum: monthData.contractSum[contractId]
            })
        })

        this.setState({
            results: _.orderBy(results, 'sum', 'desc')
        })
    }

    componentWillUnmount() {
        this.setState({ results: [] })
    }

    render() {
        const { results } = this.state;
        const { onClose, contractType } = this.props;

        if (!results) return null;

        const columns = [
            {
                Header: <TableGreenHeader value={languageStore.get('location')} />,
                id: 'location',
                accessor: (d: ResultInterface) => d.contract?.department?.location?.name || ''
            },
            {
                Header: <TableGreenHeader value={languageStore.get('department')} />,
                id: 'department',
                accessor: (d: ResultInterface) => d.contract?.department?.name || ''
            },
            {
                Header: <TableGreenHeader value={languageStore.get('responsible')} />,
                id: 'responsibleUser',
                accessor: (d: ResultInterface) => d.contract?.responsibleUser?.fullName || ''
            },
            {
                Header: <TableGreenHeader value={languageStore.get('sum')} />,
                id: 'sum',
                accessor: (d: ResultInterface) => Number(d.sum).toLocaleString()
            },
        ]

        return (
            <Modal
                forceWide={true}
                title={languageStore.get('contracts')}
                secondaryButton={{ title: languageStore.get('close'), onClick: onClose }}>
                <ModalColumn>
                    <ReactTable
                        columns={columns}
                        data={results}
                        defaultPageSize={99999}
                        showPagination={false}
                        NoDataComponent={() => null}
                        minRows={0}
                        getTdProps={(state: any, rowInfo: any, column: any, instance: any) => {
                            return {
                                onClick: (e: React.MouseEvent, handleOriginal: any) => {
                                    const id = rowInfo.original.contract.id;
                                    const endpoint = this.dashboardContractTypeToBaseContractType(contractType);
                                    navigationStore.push(`/${endpoint}/${id}`);
                                }
                            }
                        }}
                    />
                </ModalColumn>
            </Modal>
        )
    }
}

export default ForecastModal;