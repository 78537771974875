import { hasLocationAndDepartmentAdminRole } from 'helpers/RoleHelper';
import { observable, action, makeObservable, computed } from 'mobx';
import { userStore } from 'stores';
import { BaseStore } from './BaseStore';
import { LocationInterface } from './LocationStore';

export interface DepartmentInterface {
    id: string,
    name: string,
    locationId: string,
    location: LocationInterface
}

export class DepartmentStore extends BaseStore {
    @observable departments: Array<DepartmentInterface> = [];
    @observable allDepartments: Array<DepartmentInterface> = [];

    constructor() {
        super();

        makeObservable(this);
    }

    @computed
    get departmentsByRole() {
        if (hasLocationAndDepartmentAdminRole()) {
            return this.departments;
        }
        else {
            return this.allDepartments;
        }
    }

    @action
    getDepartmentsByRole() {
        if (hasLocationAndDepartmentAdminRole()) {
            const company = userStore.signedInUser.company;
            return departmentStore.getDepartmentsByCompany(company.id);
        }
        else {
            return this.getDepartments();
        }
    }

    @action
    getDepartments() {
        this.get('departments', 'allDepartments', true);
    }

    @action
    getDepartmentsByCompany(companyId: string) {
        return this.get(`companies/${companyId}/departments`, 'departments');
    }

    getDepartmentsByUser(companyId: string, userId: string) {
        this.get(`companies/${companyId}/departments?userid=${userId}`, 'departments', true);
    }

    @action
    createDepartment(department: DepartmentInterface, collection: Array<DepartmentInterface>) {
        return this.create(`locations/${department.locationId}/departments`, department, collection);
    }

    @action
    updateDepartment(department: DepartmentInterface, collection: Array<DepartmentInterface>) {
        return this.update(`locations/${department.locationId}/departments/${department.id}`, department, collection);
    }

    @action
    deleteDepartment(locationId: string, departmentId: string, collection: Array<DepartmentInterface>) {
        return this.delete(`locations/${locationId}/departments/${departmentId}`, departmentId, collection);
    }

    @action
    resetStore() {
        this.departments = [];
        this.allDepartments = [];
    }
}

export const departmentStore = new DepartmentStore();