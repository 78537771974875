import * as React from 'react';
import { observer } from 'mobx-react';
import ProfileImg from './profile.svg';
import ExpandIcon from '../ExpandIcon';
import { userStore, navigationStore, languageStore, RoleEnum, authStore } from 'stores';
import { hasRole } from 'helpers/RoleHelper';

@observer class Profile extends React.Component {

    render() {
        if (!userStore.signedInUser) return null;

        let items = [
            {
                text: languageStore.get('editProfile'),
                onClick: () => navigationStore.push('/profile')
            }
        ];

        if (
            hasRole(RoleEnum.SUPER_ADMIN) ||
            hasRole(RoleEnum.SALES_ADMIN) ||
            hasRole(RoleEnum.SERVICE_ADMIN) ||
            hasRole(RoleEnum.PURCHASE_ADMIN) ||
            hasRole(RoleEnum.HR_ADMIN)
        ) {
            items.push({
                text: languageStore.get('administration'),
                onClick: () => navigationStore.push('/admin')
            });
        }

        items.push({
            text: languageStore.get('logOut'),
            onClick: () => authStore.logout()
        })

        return (
            <ExpandIcon icon={ProfileImg} items={items} />
        )
    }

}

export default Profile;