import * as React from 'react';
import { observer } from 'mobx-react';

interface Props {
    children: React.ReactNodeArray
}

@observer class ContractDetailsContainer extends React.Component<Props> {

    render() {
        const { children } = this.props;
        const windowHeight = Math.max(document!.documentElement!.clientHeight, window.innerHeight || 0);

        return (
            <div style={{ marginBottom: windowHeight }}>
                {children}
            </div>
        )
    }

}

export default ContractDetailsContainer;