import * as React from 'react';
import { observer } from 'mobx-react';
import { DashboardContractTypeEnum, languageStore, userStore } from 'stores';
import { canAccess, ModuleEnum } from 'helpers/ModuleHelper';
import { when } from 'mobx';
import HorizontalCategoryMenu from 'components/HorizontalCategoryMenu';

interface Props {
    onChange: Function,
    activeContractType: DashboardContractTypeEnum | null
}

@observer class SelectedContractType extends React.Component<Props> {

    componentDidMount() {
        when(() => userStore.signedInUser !== null, () => {
            const { onChange } = this.props;

            if (userStore.selectedContractType) {
                onChange(userStore.selectedContractType);
                return;
            }

            if (canAccess(ModuleEnum.PURCHASE)) {
                onChange(DashboardContractTypeEnum.PURCHASE);
            }
            else if (canAccess(ModuleEnum.STAFF)) {
                onChange(DashboardContractTypeEnum.STAFF);
            }
            else if (canAccess(ModuleEnum.SERVICE)) {
                onChange(DashboardContractTypeEnum.SERVICE);
            }
            else if (canAccess(ModuleEnum.SALES)) {
                onChange(DashboardContractTypeEnum.SALES);
            }
        })
    }

    render() {
        const { onChange, activeContractType } = this.props;

        const opts = [];

        if (canAccess(ModuleEnum.PURCHASE)) {
            opts.push({
                title: languageStore.get('purchase'),
                active: activeContractType === DashboardContractTypeEnum.PURCHASE,
                onClick: (item: any) => onChange(DashboardContractTypeEnum.PURCHASE)
            })
        }

        if (canAccess(ModuleEnum.STAFF)) {
            opts.push({
                title: languageStore.get('staff'),
                active: activeContractType === DashboardContractTypeEnum.STAFF,
                onClick: (item: any) => onChange(DashboardContractTypeEnum.STAFF)
            })
        }

        if (canAccess(ModuleEnum.SERVICE)) {
            opts.push({
                title: languageStore.get('service'),
                active: activeContractType === DashboardContractTypeEnum.SERVICE,
                onClick: (item: any) => onChange(DashboardContractTypeEnum.SERVICE)
            })
        }

        if (canAccess(ModuleEnum.SALES)) {
            opts.push({
                title: languageStore.get('sales'),
                active: activeContractType === DashboardContractTypeEnum.SALES,
                onClick: (item: any) => onChange(DashboardContractTypeEnum.SALES)
            })
        }

        if (opts.length < 2) {
            return null;
        }

        return (
            <HorizontalCategoryMenu
                size='large'
                items={opts} />
        )

    }

}

export default SelectedContractType;