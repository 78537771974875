import { BaseStore } from './BaseStore';
import { observable, action, makeObservable } from 'mobx';
import { LocationInterface } from './LocationStore';

export interface EducationInterface {
    id: string,
    name: string,
    locationId: string,
    location: LocationInterface
}

export class EducationStore extends BaseStore {
    @observable educations: Array<EducationInterface> = [];
    @observable allEducations: Array<EducationInterface> = [];

    constructor() {
        super();

        makeObservable(this);
    }

    @action
    getEducations(locationId: string) {
        this.get(`locations/${locationId}/educations`, 'educations');
    }

    @action
    getAllEducations() {
        return this.get('educations', 'allEducations');
    }

    @action
    createEducation(education: EducationInterface) {
        return this.create(`locations/${education.locationId}/educations`, education, this.allEducations);
    }

    @action
    updateEducation(education: EducationInterface) {
        return this.update(`locations/${education.locationId}/educations/${education.id}`, education, this.allEducations);
    }

    @action
    deleteEducation(locationId: string, educationId: string) {
        return this.delete(`locations/${locationId}/educations/${educationId}`, educationId, this.allEducations);
    }

    @action
    resetStore() {
        this.educations = [];
        this.allEducations = [];
    }
}

export const educationStore = new EducationStore();