import React from 'react';
import { observer } from 'mobx-react';
import { CompanyInterface, ContractDocumentInterface, CurrentContractBaseInterface, CurrentContractStore, languageStore, userStore } from 'stores';
import { MdLock } from 'react-icons/md';
import { MdLockOpen } from 'react-icons/md';

interface Props {
    document: ContractDocumentInterface,
    store: CurrentContractStore<CurrentContractBaseInterface>,
    allowAddo?: boolean,
    onDelete: (e: React.MouseEvent, id: string) => void,
    onOpenFile: (e: React.MouseEvent, id: string) => void,
    onToggleSignedLock: (contractId: string, value: boolean) => void
}

@observer class FileActions extends React.Component<Props> {

    render() {
        const { document, onDelete, onOpenFile, onToggleSignedLock, store } = this.props;
        const baseClass = 'py-1 px-2 border rounded hover:bg-grey-lighter';
        const signedInCompany = userStore.signedInUser.company as CompanyInterface;


        return (
            <div>
                <button
                    type='button'
                    className={baseClass}
                    disabled={!signedInCompany?.signedDocumentsAreDeletable && !!document.signedLock}
                    onClick={(e) => onDelete(e, document.id)}>{languageStore.get('delete')}
                </button>
                <button
                    className={baseClass + ' ml-4'}
                    onClick={(e) => onOpenFile(e, document.id)}>{languageStore.get('openDocument')}
                </button>
                {
                    (!signedInCompany.signedDocumentsAreDeletable 
                    && store.contract!.responsibleUserId === userStore.signedInUser.id)
                    &&
                    <button
                        className={baseClass + ' ml-4'}
                        type='button'
                        onClick={() => onToggleSignedLock(document.id, !!!document.signedLock)}>
                        {document.signedLock ? <MdLock /> : <MdLockOpen />}
                    </button>
                }
            </div>
        )
    }
}

export default FileActions;