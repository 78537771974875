import { BaseReportStore, ReportCompareTypeEnum, ReportCriteriaInterface } from './BaseReportStore';
import { action, makeObservable } from 'mobx';
import { ContractStatusHelper } from 'helpers/ContractStatusHelper';
import { EmploymentHelper } from 'helpers/EmploymentHelper';
import { formatDate } from 'helpers/DateHelper';
import { customFieldStore } from './CustomFieldStore';
import { DashboardContractTypeEnum } from './DashboardStore';


export class StaffReportStore extends BaseReportStore {

    constructor() {
        super();
        makeObservable(this);
        this.contractEndpoint = 'staff';
    }

    @action
    async setData() {
        const status = ContractStatusHelper.StaffContractStatus(true);
        const emForms = EmploymentHelper.EmploymentForms();
        const emTypes = EmploymentHelper.EmploymentTypes();

        const data: Array<ReportCriteriaInterface> = [
            {
                label: 'comaId',
                requestField: 'id',
                compareType: ReportCompareTypeEnum.TEXT,
                autoCompleteOnFocus: true
            },{
                label: 'location',
                requestField: 'department.location.name',
                compareType: ReportCompareTypeEnum.TEXT,
                autoCompleteOnFocus: true
            },
            {
                label: 'employeeNo',
                requestField: 'employeeNo',
                compareType: ReportCompareTypeEnum.TEXT,
                tableVisibility: true
            },
            {
                label: 'name',
                requestField: 'name',
                compareType: ReportCompareTypeEnum.TEXT,
                tableVisibility: true
            },
            {
                label: 'position',
                requestField: 'position',
                compareType: ReportCompareTypeEnum.TEXT,
                tableVisibility: true
            },
            {
                label: 'ssn',
                requestField: 'ssn',
                compareType: ReportCompareTypeEnum.TEXT
            },
            {
                label: 'status',
                requestField: 'contractStatus',
                customConverter: (d: any) => ContractStatusHelper.ContractName(status, d.contractStatus),
                compareType: ReportCompareTypeEnum.DROPDOWN,
                dropdownOptions: status
            },
            {
                label: 'employmentForm',
                requestField: 'employmentForm',
                customConverter: (d: any) => EmploymentHelper.EmploymentName(emForms, d.employmentForm),
                compareType: ReportCompareTypeEnum.DROPDOWN,
                dropdownOptions: emForms,
                tableVisibility: true
            },
            {
                label: 'employmentType',
                requestField: 'employmentType',
                customConverter: (d: any) => EmploymentHelper.EmploymentName(emTypes, d.employmentType),
                compareType: ReportCompareTypeEnum.DROPDOWN,
                dropdownOptions: emTypes,
                tableVisibility: true
            },
            {
                label: 'seniority',
                requestField: 'seniorityDate',
                compareType: ReportCompareTypeEnum.DATE,
                customConverter: (d: any) => formatDate(d.seniorityDate)
            },
            {
                label: 'birthday',
                requestField: 'birthday',
                compareType: ReportCompareTypeEnum.DATE,
                customConverter: (d: any) => formatDate(d.birthday)
            },
            {
                label: 'employmentDate',
                requestField: 'employmentDate',
                compareType: ReportCompareTypeEnum.DATE,
                customConverter: (d: any) => formatDate(d.employmentDate)
            },
            {
                label: 'renegotiationDate',
                requestField: 'renegotiationDate',
                compareType: ReportCompareTypeEnum.DATE,
                customConverter: (d: any) => formatDate(d.renegotiationDate)
            },
            {
                label: 'terminationDate',
                requestField: 'terminationDate',
                compareType: ReportCompareTypeEnum.DATE,
                customConverter: (d: any) => formatDate(d.terminationDate)
            },
            {
                label: 'resignationDate',
                requestField: 'resignationDate',
                compareType: ReportCompareTypeEnum.DATE,
                customConverter: (d: any) => formatDate(d.resignationDate)
            },
            {
                label: 'boss',
                requestField: 'boss.fullName',
                compareType: ReportCompareTypeEnum.TEXT,
                autoCompleteOnFocus: true
            },
            {
                label: 'department',
                requestField: 'department.name',
                compareType: ReportCompareTypeEnum.TEXT,
                autoCompleteOnFocus: true
            },
            {
                label: 'email',
                requestField: 'email',
                compareType: ReportCompareTypeEnum.TEXT,
                autoCompleteOnFocus: true
            },
            {
                label: 'phone',
                requestField: 'phone',
                compareType: ReportCompareTypeEnum.TEXT,
                autoCompleteOnFocus: false
            },
            {
                label: 'salary',
                requestField: 'salary',
                compareType: ReportCompareTypeEnum.NUMBER
            },
            {
                label: 'hourlyWage',
                requestField: 'hourlyWage',
                compareType: ReportCompareTypeEnum.NUMBER
            },
            {
                label: 'agreement',
                requestField: 'agreement.name',
                compareType: ReportCompareTypeEnum.TEXT,
                autoCompleteOnFocus: true
            },
            {
                label: 'country',
                requestField: 'countryId',
                compareType: ReportCompareTypeEnum.TEXT,
                autoCompleteOnFocus: true,
                customConverter: (d: any) => {
                    if (d.country) {
                        return d.country.name
                    }
                    else return null;
                }
            },
            {
                label: 'equipment',
                requestField: 'equipment.equipment.name',
                compareType: ReportCompareTypeEnum.TEXT,
                autoCompleteOnFocus: true,
                customConverter: (d: any) => {
                    if (d.equipment) {
                        return d.equipment.map((res: any) => res.equipment.name).join(', ')
                    }
                    else return null;
                }
            },
            {
                label: 'skill',
                requestField: 'skills.skill.name',
                compareType: ReportCompareTypeEnum.TEXT,
                autoCompleteOnFocus: true,
                customConverter: (d: any) => {
                    if (d.skills) {
                        return d.skills.map((res: any) => res.skill.name).join(', ')
                    }
                    else return null;
                }
            },
            {
                label: 'perk',
                requestField: 'benefits.benefit.name',
                compareType: ReportCompareTypeEnum.TEXT,
                autoCompleteOnFocus: true,
                customConverter: (d: any) => {
                    if (d.benefits) {
                        return d.benefits.map((res: any) => res.benefit.name).join(', ')
                    }
                    else return null;
                }
            },
            {
                label: 'educationLevel',
                requestField: 'educations.educationLevel.name',
                compareType: ReportCompareTypeEnum.TEXT,
                autoCompleteOnFocus: true,
                customConverter: (d: any) => {
                    if (d.educations) {
                        return d.educations.map((res: any) => res.educationLevel.name).join(', ')
                    }
                    else return null;
                }
            },
            {
                label: 'emergencyContact',
                requestField: 'contactPeople.name',
                compareType: ReportCompareTypeEnum.TEXT,
                autoCompleteOnFocus: true,
                customConverter: (d: any) => {
                    if (d.contactPeople) {
                        return d.contactPeople.map((res: any) => res.name).join(', ')
                    }
                    else return null;
                }
            },
            {
                label: 'documentType',
                requestField: 'contractDocuments.documentType.name',
                compareType: ReportCompareTypeEnum.TEXT,
                customConverter: (d: any) => d.contractDocuments ? d.contractDocuments?.map((cd: any) => cd.documentType?.name || '').filter((x: any) => x).join(', ') : '',
                autoCompleteOnFocus: true
            },
            {
                label: 'currency',
                requestField: 'currency.code',
                compareType: ReportCompareTypeEnum.TEXT
            }
        ]

        const customFields = this.getCustomFieldsAsReportCriteriaInterfaces(await customFieldStore.getCustomFields(DashboardContractTypeEnum.STAFF));

        this.data = [...data, ...customFields];
    }

    @action
    private setCustomBirthdayData() {
        this.data = [
            {
                label: 'employeeNo',
                requestField: 'employeeNo',
                tableVisibility: true,
                compareType: ReportCompareTypeEnum.NONE
            },
            {
                label: 'name',
                requestField: 'name',
                tableVisibility: true,
                compareType: ReportCompareTypeEnum.NONE
            },
            {
                label: 'birthday',
                requestField: 'birthday',
                tableVisibility: true,
                compareType: ReportCompareTypeEnum.NONE,
                customConverter: (d: any) => formatDate(d.birthday)
            },
            {
                label: 'years',
                requestField: 'years',
                tableVisibility: true,
                compareType: ReportCompareTypeEnum.NONE
            },
        ]
    }

    @action
    private setCustomAnniversaryData() {
        this.data = [
            {
                label: 'employeeNo',
                requestField: 'employeeNo',
                tableVisibility: true,
                compareType: ReportCompareTypeEnum.NONE
            },
            {
                label: 'name',
                requestField: 'name',
                tableVisibility: true,
                compareType: ReportCompareTypeEnum.NONE
            },
            {
                label: 'seniority',
                requestField: 'seniorityDate',
                tableVisibility: true,
                compareType: ReportCompareTypeEnum.NONE,
                customConverter: (d: any) => formatDate(d.seniorityDate)
            },
            {
                label: 'years',
                requestField: 'years',
                tableVisibility: true,
                compareType: ReportCompareTypeEnum.NONE
            },
        ]
    }

    @action
    private setCustomPhoneListData() {
        this.data = [
            {
                label: 'name',
                requestField: 'name',
                tableVisibility: true,
                compareType: ReportCompareTypeEnum.NONE
            },
            {
                label: 'address',
                requestField: 'address',
                tableVisibility: true,
                compareType: ReportCompareTypeEnum.NONE
            },
            {
                label: 'city',
                requestField: 'postCode',
                tableVisibility: true,
                compareType: ReportCompareTypeEnum.NONE,
                customConverter: (d: any) => (d.postCode || '') + ' ' + (d.city || '')
            },
            {
                label: 'phone',
                requestField: 'phone',
                tableVisibility: true,
                compareType: ReportCompareTypeEnum.NONE
            },
            {
                label: 'email',
                requestField: 'email',
                tableVisibility: true,
                compareType: ReportCompareTypeEnum.NONE
            },
            {
                label: 'emergencyContact',
                requestField: 'emergencyContactName',
                tableVisibility: false,
                compareType: ReportCompareTypeEnum.NONE,
                customConverter: (d: any) => `${d.emergencyContactName || '-'} ${d.emergencyContractPhone ? `(${d.emergencyContractPhone})` : ''}`
            },
        ]
    }

    @action
    getBirthdayData() {
        this.setCustomBirthdayData();
        return this.get(`reports/birthdays`, 'results');
    }

    @action
    getPhoneListData() {
        this.setCustomPhoneListData();
        return this.get(`reports/phonelist`, 'results');
    }

    @action
    getAnniversaryData() {
        this.setCustomAnniversaryData();
        return this.get(`reports/anniversaries`, 'results');
    }

}

export const staffReportStore = new StaffReportStore();