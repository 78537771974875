import * as React from 'react';
import { observer } from 'mobx-react';
import { serviceStore, ServiceInterface, languageStore, CurrentContractStore, DocumentTypeContractTypeEnum } from 'stores';
import { FieldState } from 'formstate';
import { DropdownOptionInterface } from 'components/Forms/Dropdown';
import ContractDropdownItem from 'components/ContractDropdownItem';

interface Props {
    fieldState: FieldState<any>,
    store: CurrentContractStore<any>
}

@observer class SelectService extends React.Component<Props> {

    componentDidMount() {
        const { store } = this.props;
        const { currentLocationId } = store;

        if (currentLocationId) {
            let endpoint = null;
            switch (store.contractEndpoint) {
                case 'purchase': endpoint = DocumentTypeContractTypeEnum.PURCHASE; break;
                case 'staff': endpoint = DocumentTypeContractTypeEnum.STAFF; break;
                case 'sales': endpoint = DocumentTypeContractTypeEnum.SALES; break;
                case 'service': endpoint = DocumentTypeContractTypeEnum.SERVICE; break;
                default: break;
            }

            if (endpoint) {
                serviceStore.getServices(currentLocationId, endpoint);
            }
        }
        else {
            serviceStore.resetServices();
        }
    }

    render() {
        const { fieldState, store } = this.props;
        const locationId = store.currentLocationId;
        let options: Array<DropdownOptionInterface> = [];

        serviceStore.services && serviceStore.services.forEach((service: ServiceInterface) => {
            if (locationId === service.locationId) {
                options.push({
                    value: service.id,
                    name: service.name
                })
            }
        });

        return <ContractDropdownItem
            label={languageStore.get('service')}
            placeholderText={languageStore.get('selectService')}
            id='serviceId'
            fieldState={fieldState}
            options={options}
            editMode={true} />
    }

}

export default SelectService;