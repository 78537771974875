import * as React from 'react';
import { observer } from 'mobx-react';
import { FormState, FieldState } from 'formstate';
import { ContactPeopleInterface, ContactPeopleTypeEnum, currentStaffContractStore as store, languageStore } from 'stores';
import SectionEnum from '../SectionEnum';
import ContractDetailsItem from 'components/ContractDetailsItem';
import { email, required } from 'helpers/FormValidation';
import TableGreenHeader from 'components/Table/TableGreenHeader';
import BaseContractTable from '../BaseContractTable';
import { ModalColumn } from 'components/Modal';
import SelectTrueFalse from 'components/Forms/SelectTrueFalse';
import SelectContactType from './SelectContactType';
import * as _ from 'lodash';

@observer class EmergencyContact extends React.Component {

    form = new FormState({
        id: new FieldState<string | null>(null),
        name: new FieldState(null).validators(required),
        type: new FieldState(null),
        email: new FieldState(null).validators(email),
        phone: new FieldState(null),
        isPrimary: new FieldState(null)
    })

    beforeSubmit = (contact: ContactPeopleInterface) => {
        if (!contact.isPrimary) {
            contact.isPrimary = false;
        }
    }

    render() {
        const form = this.form.$;
        let contactPeople = store.contract!.contactPeople || [];
        contactPeople = _.orderBy(contactPeople, 'name');

        const columns = [
            {
                Header: <TableGreenHeader value={languageStore.get('name')} />,
                accessor: 'name'
            },
            {
                Header: <TableGreenHeader value={languageStore.get('relation')} />,
                id: 'type',
                accessor: (d: any) => {
                    switch (d.type) {
                        case ContactPeopleTypeEnum.Unspecified: return '';
                        case ContactPeopleTypeEnum.Wife: return languageStore.get('wifeHusband');
                        case ContactPeopleTypeEnum.Girlfriend: return languageStore.get('girlfriend');
                        case ContactPeopleTypeEnum.Mother: return languageStore.get('mother');
                        case ContactPeopleTypeEnum.Father: return languageStore.get('father');
                        case ContactPeopleTypeEnum.Manager: return languageStore.get('manager');
                        case ContactPeopleTypeEnum.Brother: return languageStore.get('brother');
                        case ContactPeopleTypeEnum.Sister: return languageStore.get('sister');
                        case ContactPeopleTypeEnum.Son: return languageStore.get('son');
                        case ContactPeopleTypeEnum.Daughter: return languageStore.get('daughter');
                        default: return '';
                    }
                }
            },
            {
                Header: <TableGreenHeader value={languageStore.get('email')} />,
                accessor: 'email'
            },
            {
                Header: <TableGreenHeader value={languageStore.get('phone')} />,
                accessor: 'phone'
            },
            {
                Header: <TableGreenHeader value={languageStore.get('primary')} />,
                id: 'isPrimary',
                accessor: (d: any) => {
                    return d.isPrimary === true ? languageStore.get('yes') : languageStore.get('no')
                }
            },
        ]

        return <BaseContractTable
            form={this.form}
            onUpdate={(contact: ContactPeopleInterface) => store.updateContact(contact)}
            onSubmit={(contact: ContactPeopleInterface) => store.createContact(contact)}
            beforeSubmit={(contact: ContactPeopleInterface) => this.beforeSubmit(contact)}
            onDelete={(id: string) => store.deleteContact(id)}
            collection={contactPeople as any}
            columns={columns}
            tableTitle={languageStore.get('emergencyContact')}
            modalTitle={languageStore.get('emergencyContact')}
            store={store}
            hash={SectionEnum.EMERGENCY_CONTACT}>

            <ModalColumn>
                <ContractDetailsItem
                    label={languageStore.get('name')}
                    id='emergencyName'
                    fieldState={form.name}
                    editMode={true} />

                
                <SelectContactType
                    fieldState={form.type}
                    editMode={true}
                />
                    
                <ContractDetailsItem
                    label={languageStore.get('email')}
                    id='emergencyEmail'
                    fieldState={form.email}
                    editMode={true} />

                <ContractDetailsItem
                    label={languageStore.get('phone')}
                    id='emergencyPhone'
                    fieldState={form.phone}
                    editMode={true} />

                <SelectTrueFalse
                    label={languageStore.get('primary')}
                    id='emergencyPrimary'
                    fieldState={form.isPrimary}
                    editMode={true} />
            </ModalColumn>

        </BaseContractTable>
    }

}

export default EmergencyContact;