import { observer } from "mobx-react";
import { languageStore, navigationStore, RoleEnum } from "stores";
import SidebarNavigation from 'components/AdminPage/SidebarNavigation';
import { useLocation, useParams } from "react-router";
import useCompanyUserByParams from "./useCompanyUserByParams";
import { hasRole } from "helpers/RoleHelper";

interface Props {
    children: React.ReactNode
}

const UserDetailsPage = observer((props: Props) => {
    const user = useCompanyUserByParams();
    const { pathname } = useLocation();
    const { id } = useParams<{ id: string }>();

    const getSidebarSubItems = (currentPathname: string, userId: string) => {
        const items = [
            {
                label: languageStore.get('userInformation'),
                onClick: () => navigationStore.push(`/admin/users/${id}`),
                isSelected: currentPathname === `/admin/users/${id}`
            },
            {
                label: languageStore.get('accessRights'),
                onClick: () => navigationStore.push(`/admin/users/${id}/accessrights`),
                isSelected: currentPathname === `/admin/users/${id}/accessrights`
            },
            {
                label: languageStore.get('locations'),
                onClick: () => navigationStore.push(`/admin/users/${id}/locations`),
                isSelected: currentPathname === `/admin/users/${id}/locations`
            },
            {
                label: languageStore.get('departments'),
                onClick: () => navigationStore.push(`/admin/users/${id}/departments`),
                isSelected: currentPathname === `/admin/users/${id}/departments`
            },
            {
                label: languageStore.get('contracts'),
                onClick: () => navigationStore.push(`/admin/users/${id}/contracts`),
                isSelected: currentPathname === `/admin/users/${id}/contracts`
            },
            {
                label: `${languageStore.get('reassign')} ${languageStore.get('contracts', false)}`,
                onClick: () => navigationStore.push(`/admin/users/${id}/reassign`),
                isSelected: currentPathname === `/admin/users/${id}/reassign`
            }
        ];

        if (hasRole(RoleEnum.PRIMARY_ADMIN)) {
            items.push({
                label: languageStore.get('log'),
                onClick: () => navigationStore.push(`/admin/users/${id}/log`),
                isSelected: currentPathname === `/admin/users/${id}/log`
            })
        }

        return items;
    }

    if (!user) return null;

    return (
        <div>
            <div className='text-left flex-1'>
                <h2 className='mt-2 mb-5'>
                    <button className='mr-2 text-red' onClick={() => navigationStore.push('/admin/users')}>
                        &larr;
                    </button>
                    {`${languageStore.get('edit')} ${languageStore.get('user', false)}`} ({user?.fullName})
                </h2>
            </div>

            <div className='flex'>
                <SidebarNavigation
                    items={[
                        {
                            title: languageStore.get('user'),
                            defaultExpanded: true,
                            subItems: getSidebarSubItems(pathname, id)
                        }
                    ]}
                />

                <div className='flex-1 ml-6'>
                    {props.children}
                </div>
            </div>
        </div>
    )
})

export default UserDetailsPage;