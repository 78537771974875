import * as React from 'react';
import { observer } from 'mobx-react';
import Breadcrumb from 'components/Breadcrumb';
import { CurrentContractStore, CurrentContractBaseInterface, languageStore } from 'stores';
import SaveDraftButton from 'components/SaveDraftButton';
import Tags from 'components/Tags';
import PrimaryContractLinks from 'components/PrimaryContractLinks';
import CancelDraftButton from 'components/CancelDraftButton';
import './style.css';
import ContractId from './ContractId';


interface Props {
    backlinkTitle: string,
    title: string,
    store: CurrentContractStore<CurrentContractBaseInterface>,
    helpUrl: string
}

@observer class ContractDetailsTop extends React.Component<Props> {



    render() {
        const { backlinkTitle, title, store, helpUrl } = this.props;

        return (
            <div className='flex z-50 bg-white pb-2 pt-2' style={store.isDraft ? { position: 'sticky', top: 0, borderBottom: '1px solid #eff2f5' } : undefined}>
                <div className='w-1/2'>
                    <Breadcrumb
                        backlinkTitle={backlinkTitle}
                        backlinkTo={`/${store.contractEndpoint}`}
                        title={title}>
                    </Breadcrumb>

                    <PrimaryContractLinks store={store} />
                    <ContractId store={store} />
                </div>
                <div className='w-1/2 text-right'>
                    {
                        !store.isDraft &&
                        <Tags store={store} />
                    }

                    {
                        store.isDraft &&
                        <a
                            href={helpUrl}
                            target='_blank'
                            rel='noreferrer'
                            className=' help-button text-white py-2 rounded mr-6 no-underline'>
                            {languageStore.get('help')}
                        </a>
                    }

                    <CancelDraftButton store={store} />
                    <SaveDraftButton store={store} />
                </div>
            </div>
        )
    }

}

export default ContractDetailsTop;