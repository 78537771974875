import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { DepartmentInterface, departmentStore } from "stores";
import _ from 'lodash';

const useUserRoleDepartmentByParams = () => {
    const [department, setDepartment] = useState<null | DepartmentInterface>(null);
    const { id } = useParams<{ id: string }>();

    useEffect(() => {
        const getDepartment = async() => {
            await departmentStore.getDepartmentsByRole();
        }

        getDepartment();

        return () => {
            departmentStore.resetStore();
        }
    }, [id])

    useEffect(() => {
        const dep = _.find(departmentStore.departmentsByRole, { id: id });
        setDepartment(dep || null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [departmentStore.departmentsByRole])

    return department;
}

export default useUserRoleDepartmentByParams;