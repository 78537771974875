import { observer } from "mobx-react";
import { departmentStore, locationStore, languageStore, userStore, navigationStore } from 'stores';
import TableHeader from 'components/Table/TableHeader';
import * as _ from 'lodash';
import { hasLocationAndDepartmentAdminRole } from 'helpers/RoleHelper';
import TableDetails from '../TableDetails';
import { useEffect, useState } from 'react';
import AddDepartmentModal from './AddDepartmentModal';
import ReactTable from 'react-table';
import SubTableNoDataComponent from 'components/SubTableNoDataComponent';

const DepartmentsPage = observer(() => {
    const [ data, setData ] = useState<Array<any>>([]);

    const locations = locationStore.locations;
    const departments = departmentStore.departmentsByRole;

    useEffect(() => {
        departmentStore.getDepartmentsByRole();

        if (hasLocationAndDepartmentAdminRole()) {
            const company = userStore.signedInUser.company;
            locationStore.getLocationsByCompany(company.id);
        }
        else {
            locationStore.getLocations(true);
        }

        return () => {
            departmentStore.resetStore();
        }
    }, [])

    useEffect(() => {
        const mappedData = _.clone(locations);
        departments.forEach((dep) => {
            let loc = _.find(mappedData, { id: dep.locationId }) as any;
            if (!loc) return;
            
            if (loc.subItems === undefined) {
                loc.subItems = [];
            }

            // Somewhere there is an edge case, where the same department in rare cases can appear multiple times.
            // (Asana #1202153114301238). Make sure we filter out any duplicates.
            if (!_.some(loc.subItems, (s) => s.id === dep.id)) {
                loc.subItems.push(dep);
            }
        })

        setData(mappedData);

    }, [locations, departments])

    return (
        <TableDetails
            actions={
                <AddDepartmentModal
                    departmentsCollection={departmentStore.departmentsByRole}
                    restrictLocationsByCompanyId={hasLocationAndDepartmentAdminRole() ? userStore.signedInUser.company.id : undefined}
                />
            }
            data={data.slice()}
            columns={[
                {
                    Header: <TableHeader value={languageStore.get('location')} />,
                    id: 'name',
                    accessor: 'name'
                }
            ]}
            filterBy={['name']}
            onRowClicked={() => null} // Make cells appear clickable
            subComponent={row => <ReactTable
                style={{ marginLeft: '35px' }}
                data={row.original.subItems?.slice()}
                columns={[
                    {
                        Header: <TableHeader value={languageStore.get('department')} />,
                        id: 'name',
                        accessor: 'name'
                    }
                ]}
                defaultPageSize={99999}
                defaultSorted={[{ id: 'name' }]}
                showPagination={false}
                NoDataComponent={() => <SubTableNoDataComponent />}
                getTdProps={(state: any, rowInfo: any, column: any, instance: any) => {
                    return {
                        onClick: (e: React.MouseEvent, handleOriginal: any) => {
                                const id = rowInfo.original.id;
                                navigationStore.push(`/admin/departments/${id}`)
                        }
                    };
                }}
                minRows={0} />}
        />
    )

})

export default DepartmentsPage;