import TableGreenHeader from "components/Table/TableGreenHeader";
import ReactTable from "react-table";
import { CustomFieldInterface, languageStore } from "stores";

interface Props {
    title?: string,
    data: Array<CustomFieldInterface>,
    description?: string
}

const FieldGroup = (props: Props) => {
    return (
        <div className='p-4 mt-12 border rounded'>
            {
                props.title &&
                <h3 className='mb-4'>
                    {props.title}
                </h3>
            }

            {
                props.description &&
                <p className='mb-4'>
                    {props.description}
                </p>
            }

            <div>
                <ReactTable
                    className='ReactTable-readonly'
                    data={props.data}
                    columns={[
                        {
                            Header: <TableGreenHeader value={languageStore.get('key')} />,
                            accessor: 'key',
                            width: 280
                        },
                        {
                            Header: <TableGreenHeader value={languageStore.get('description')} />,
                            accessor: 'description'
                        },
                    ]}
                    showPagination={false}
                    sortable={true}
                    defaultPageSize={99999}
                    minRows={0}
                />
            </div>
        </div>
    )
}

export default FieldGroup;