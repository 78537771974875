import { linkContractStore, navigationStore } from 'stores';
import { BaseContractTypeEnum } from 'stores/BaseStore';

export function redirectToSourceContractIfActive(targetEndpoint: string, targetId: string) {
    if (linkContractStore.linkContractIsActive) {
        linkContractStore.setTarget(targetEndpoint as BaseContractTypeEnum, targetId);

        const sourceEndpoint = linkContractStore.sourceType;
        const sourceId = linkContractStore.sourceId;

        navigationStore.push(`/${sourceEndpoint}/${sourceId}#linked_contracts`);

        return;
    }
}