import React from 'react';
import { observer } from 'mobx-react';
import { CurrentContractBaseInterface, CurrentContractStore, languageStore, toastStore } from 'stores';

interface Props {
    store: CurrentContractStore<CurrentContractBaseInterface>
}

interface State {
    showId: boolean
}

@observer class ContractId extends React.Component<Props, State> {

    state = { showId: false }

    inputRef: any = React.createRef();

    copyText = () => {
        const input = this.inputRef.current;
        input.focus();
        document.execCommand('copy');
        toastStore.addToast({
            title: languageStore.get('copiedToClipboard'),
            level: 'success',
            message: ''
        })
    }

    render() {
        const { store } = this.props;
        const { showId } = this.state;

        const id = store.contract?.id
        if (!id) {
            return null;
        }
        
        if (!showId) {
            return (
                <button className='mt-2 text-sm' onClick={() => this.setState({ showId: true })}>
                    {languageStore.get('show')} {languageStore.get('comaId')}
                </button>
            )
        }

        return (
            <div className='mt-2 text-sm'>
                <input
                    type="text"
                    defaultValue={id}
                    onFocus={(event) => event.target.select()}
                    style={{ width: '250px' }}
                    readOnly={true}
                    ref={this.inputRef}
                />

                <span className='cursor-pointer border ml-1 p-1 rounded' onClick={() => this.copyText()}>
                    {languageStore.get('copy')}
                </span>
            </div>
        )
    }
}

export default ContractId;