import * as React from 'react';
import { observer } from 'mobx-react';
import { FieldState } from 'formstate';
import ContractDropdownItem from 'components/ContractDropdownItem';

interface Props {
    availableValues: Array<string>,
    fieldState: FieldState<string>,
    label: string,
    editMode?: boolean,
    id: string,
    multiple?: boolean
}

@observer class CustomFieldDropdown extends React.Component<Props> {

    render() {
        const { availableValues, fieldState, label, editMode, id, multiple } = this.props;

        const options = availableValues.map((value) => ({
            value: value,
            name: value
        }));

        return (
            <ContractDropdownItem
                label={label}
                placeholderText='-'
                fieldState={fieldState}
                multiple={multiple}
                options={options}
                id={id}
                editMode={editMode} />
        )
    }
}

export default CustomFieldDropdown;