import * as React from 'react';
import { observer } from 'mobx-react';
import { FieldState } from 'formstate';
import ContractDropdownItem from 'components/ContractDropdownItem';
import { DropdownOptionInterface } from 'components/Forms/Dropdown';
import * as _ from 'lodash';
import {
    languageStore as lanStore,
    supplierStore,
    SupplierInterface,
    customerStore,
    CustomerInterface
} from 'stores';

interface Props {
    customerId?: string,
    supplierId?: string,
    fieldState: FieldState<any>,
    fallbackValue?: string,
    editMode?: boolean
}

@observer class SelectedContact extends React.Component<Props> {

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        const { fieldState, supplierId, customerId } = this.props;

        // When supplier is changed, we need to check if the current contact (fieldState)
        // is a child of the new supplier. If not, set the state to null (--- new contact ---)
        // as default.
        if (nextProps.supplierId !== supplierId && fieldState.value) {
            if (supplierId) {
                const supplier = _.find(supplierStore.suppliers, { id: nextProps.supplierId }) as SupplierInterface;
                if (supplier) {
                    const index = _.findIndex(supplier.contactPeople, { id: fieldState.value });
                    if (!index) {
                        fieldState.onChange(null);
                    }
                }
            }
            else if (customerId) {
                const customer = _.find(customerStore.customers, { id: nextProps.customerId }) as CustomerInterface;
                if (customer) {
                    const index = _.findIndex(customer.contactPeople, { id: fieldState.value });
                    if (!index) {
                        fieldState.onChange(null);
                    }
                }
            }
        }
    }

    render() {
        const { supplierId, customerId, fieldState, fallbackValue, editMode } = this.props;

        let availContacts: Array<DropdownOptionInterface> = [];

        if (supplierId) {
            const supplier = _.find(supplierStore.suppliers, { id: supplierId }) as SupplierInterface;
            supplier && supplier.contactPeople.forEach((contact) => {
                availContacts.push({
                    value: contact.id,
                    name: contact.name
                })
            })
        }
        else if (customerId) {
            const customer = _.find(customerStore.customers, { id: customerId }) as CustomerInterface;
            customer && customer.contactPeople.forEach((contact) => {
                availContacts.push({
                    value: contact.id,
                    name: contact.name
                })
            })
        }

        return <ContractDropdownItem
            label={lanStore.get('selectContactPerson')}
            id='contactPerson'
            placeholderText={`--- ${lanStore.get('newContactPerson')} ---`}
            fieldState={fieldState}
            options={availContacts}
            editMode={editMode}
            fallbackValue={fallbackValue}
        />
    }

}

export default SelectedContact;