import { languageStore } from 'stores';
import { Validator } from 'formstate';

export const required = (val: any) => !val && languageStore.t('required');
export const requiredNotNull = (val: any) => (val === null || val === undefined) && languageStore.get('required');

export const isValidEmailByString = (value: string) => {
    if (!value) return false;

    // Src : http://emailregex.com/
    if (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g.exec(value)) {
        return true;
    }
    else return false;

}

export const email: Validator<string | null | undefined> = (value: string | null | undefined) => {
    // Empty values are not invalid emails
    if (!value) return null;
    if (required(email)) return null;
    value = value.trim();

    if (!isValidEmailByString(value)) {
        return languageStore.t('invalidEmail');
    }
    return null;
}

export const validPassword: Validator<string | null | undefined> = (value: string | null | undefined) => {
    // Empty values are not invalid emails
    if (!value) return null;

    value = value.trim();

    // At least one uppercase, one lowercase, one numeric and one special char. Must be min 8 long
    if (!/(?=^.{8,}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&amp;*()_+}{&quot;:;'?/&gt;.&lt;,])(?!.*\s).*$/g.exec(value)) {
        return languageStore.t('invalidPassword');
    }

    return null;
}

export const isNumber = (val: any) =>  {

    if (!val) return null;

    if (isNaN(val.toString().split(",").join(""))) {
        return languageStore.t('onlyNumbers');
    }

    
    return null;
}

export const isInteger = (val: any) => {

    if (!val) return null;
    
    if (!Number.isInteger(Number(val))) {
        return languageStore.t('onlyIntegers');
    }

    return null;
}