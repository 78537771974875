import * as React from 'react';
import { observer } from 'mobx-react';
import * as _ from 'lodash';
import { languageStore, tagStore, CurrentContractStore, CurrentContractBaseInterface } from 'stores';
import './style.css';

const ReactTags = require('react-tag-autocomplete');

export interface TagInterface {
    id: string,
    name: string
}

interface Props {
    store: CurrentContractStore<CurrentContractBaseInterface>
}

interface State {
    tags: Array<TagInterface>
}

@observer class Tags extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            tags: []
        }
    }

    componentDidMount() {
        tagStore.getTags();

        const storeTags = this.props.store.contract!.tags;

        if (storeTags) {
            this.setState({
                tags: storeTags
            })
        }

    }

    addTag = (tag: TagInterface) => {
        const { store } = this.props;
        const { tags } = this.state;

        store.createTag({ id: tag.id, name: tag.name });

        if (!_.find(tags, { id: tag.id })) {
            this.setState({
                tags: [...tags, tag]
            })
        }
    }

    deleteTag = (id: number) => {
        const { store } = this.props;

        if (!store.canEdit) {
            return;
        }

        const tags = this.state.tags.slice(0);

        const tag = tags[id];
        if (tag) {
            const { store } = this.props;
            store.deleteTag(tag.id);

            tags.splice(id, 1);
            this.setState({ tags });
        }
    }

    render() {
        const { tags } = this.state;
        const { store } = this.props;
        const allSuggestions = tagStore.tags;
        if (!allSuggestions || allSuggestions.length === 0) return null;

        const filteredSuggestions = allSuggestions.filter((suggestion) => {
            return !_.find(tags, { id: suggestion.id });
        })

        return (
            <ReactTags
                autoresize={false}
                autofocus={false}
                tags={tags}
                allowBackspace={false}
                inputAttributes={{ disabled: store.canEdit ? false : true }}
                placeholder={`${languageStore.get('addTag')}...`}
                suggestions={filteredSuggestions}
                handleAddition={this.addTag}
                handleDelete={this.deleteTag} />
        )
    }

}

export default Tags;