import { action, observable, makeObservable } from "mobx";
import { BaseStore } from "./BaseStore";

export interface PlanInterface {
    "id": string,
    "maxSeats": number,
    "pricePrMonth": number,
    "economicPlanId": string,
    "signingRetailPrQty": number,
    "signingCostPrQty": number,
    "EconomicSigningId": string
}

export class PlanStore extends BaseStore {
    @observable plans: Array<PlanInterface> = [];

    constructor() {
        super();

        makeObservable(this);
    }

    @action
    resetStore() {
        this.plans = [];
    }

    @action
    getPlans() {
        return this.get(`plans`, 'plans');
    }

    @action
    updatePlan(plan: PlanInterface) {
        return this.update(`plans/${plan.id}`, plan, this.plans);
    }
}

export const planStore = new PlanStore();