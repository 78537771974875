import React from 'react';
import { observer } from 'mobx-react';
import { canAccess, ModuleEnum } from 'helpers/ModuleHelper';
import { languageStore, navigationStore, RoleEnum } from 'stores';
import { inboxesStore } from 'stores';
import InboxesTable from './InboxesTable';
import { hasRole } from 'helpers/RoleHelper';

@observer class AdminInboxesPage extends React.Component {

    componentDidMount() {
        if (!canAccess(ModuleEnum.INBOX)) {
            navigationStore.push('/admin');
            return;
        }

        inboxesStore.getInboxes();
    }

    render() {
        const inboxes = inboxesStore.inboxes?.results || [];

        return (
            <div className='mx-auto'>
                {
                    hasRole(RoleEnum.INBOX) &&
                    <div className='mb-5 text-right block'>
                        <button onClick={() => navigationStore.push('/admin/create-inbox')} className='bg-comablue-label text-white py-2 px-8 rounded'>
                            {languageStore.get('add')}
                        </button>
                    </div>
                }
                <InboxesTable data={inboxes} />
            </div>
        )
    }
}

export default AdminInboxesPage;