import * as React from 'react';
import { observer } from 'mobx-react';
import SpecialCircumstances from 'components/SpecialCircumstances';
import { FormState, FieldState } from 'formstate';
import { currentServiceContractStore as store } from 'stores';
import SectionEnum from '../SectionEnum';
import BaseContract from 'components/StaffContractDetailsPage/BaseContract';

@observer class SpecialCircumstancesSection extends React.Component {

    form = new FormState({
        specialCircumstances: new FieldState<boolean>(store.contract!.specialCircumstances),
        specialCircumstancesDiscription: new FieldState(store.contract!.specialCircumstancesDiscription || ''),
        warranty: new FieldState(store.contract!.warranty),
        warrantyDescription: new FieldState(store.contract!.warrantyDescription || '')
    })

    render() {
        const editMode = !!store.isEditMode(SectionEnum.SPECIAL);

        return (
            <BaseContract
                store={store}
                form={this.form}
                sectionEnum={SectionEnum.SPECIAL}>

                <SpecialCircumstances 
                    hasWarranty={true} 
                    hasSpecialCircumstances={true} 
                    form={this.form} 
                    editMode={editMode} />
                
            </BaseContract>
        );
    }

}

export default SpecialCircumstancesSection;