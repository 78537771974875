import * as React from 'react';
import { observer } from 'mobx-react';
import StepContainer from './StepContainer';
import { languageStore, locationStore, createUserStore, LocationInterface, RoleEnum, navigationStore } from 'stores';
import { FormState, FieldState } from 'formstate';
import SelectTrueFalse from 'components/Forms/SelectTrueFalse';
import * as _ from 'lodash';
import { hasRole } from 'helpers/RoleHelper';

interface Props {
    afterSubmit: Function,
    companyId: string
}

@observer class LocationRightsStep extends React.Component<Props> {

    form = new FormState({}) as any;

    componentDidMount() {
        const { companyId } = this.props;

        if (hasRole(RoleEnum.SUPER_ADMIN)) {
            locationStore.getLocationsByCompany(companyId);
        }
        else {
            locationStore.getLocations(true);
        }
    }

    onSubmit = async () => {

        const locations = [];

        for (let id in this.form.$) {
            let value = this.form.$[id] as FieldState<boolean>;

            if (value.$ === true) {
                locations.push(id)
            }
        }

        const userId = createUserStore.user!.id;
        await createUserStore.putLocations(this.props.companyId, userId, locations);

        this.props.afterSubmit(locations);
    }

    componentWillUnmount() {
        locationStore.resetStore();
    }

    render() {
        const locations = locationStore.locations || [];

        let orderedLocations = _.orderBy(locations, 'name');
        orderedLocations = _.orderBy(orderedLocations, 'country.name')
        const form = this.form.$;

        const locByCountry: Array<LocationInterface> = [];

        orderedLocations.forEach((loc) => {
            const countryName = loc.country.name;
            if (!locByCountry[countryName]) {
                locByCountry[countryName] = [];
            }
            locByCountry[countryName].push(loc);
        })

        return (
            <StepContainer
                backLinkFunc={() => navigationStore.goBack()}
                backlinkTitle={languageStore.get('users')}
                title={languageStore.get('locationRights')}
                form={this.form}
                onSubmit={this.onSubmit}>

                <div className='w-full flex p-2 mb-6'>

                    {
                        Object.keys(locByCountry).map((countryName, index) => {
                            return (
                                <div className='w-1/2 px-2' key={index}>
                                    <h2 className='my-2'>
                                        {countryName}
                                    </h2>
                                    {
                                        locByCountry[countryName].map((loc: LocationInterface, index: number) => {
                                            const field = new FieldState(false);
                                            form[loc.id] = field;

                                            return (
                                                <div key={index}>
                                                    <SelectTrueFalse
                                                        marginTop='0px'
                                                        marginBottom='0px'
                                                        id={loc.id}
                                                        fieldState={field}
                                                        label={loc.name}
                                                        editMode={true} />
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            )
                        })
                    }

                </div>

            </StepContainer>
        )
    }

}

export default LocationRightsStep;