import * as React from 'react';
import { observer } from 'mobx-react';
import { FormState, FieldState } from 'formstate';
import { userStore, skillStore, StaffSkillInterface, documentStore, DocumentUrlInterface, languageStore, SkillInterface, ContractDocumentInterface } from 'stores';
import { currentStaffContractStore as store } from 'stores';
import TableGreenHeader from 'components/Table/TableGreenHeader';
import { formatUser, formatStaffContractDetailsStatus } from 'helpers/FormatHelper';
import BaseContractTable from '../BaseContractTable';
import SectionEnum from '../SectionEnum';
import { ModalColumn } from 'components/Modal';
import * as _ from 'lodash';
import SelectSkill from './SelectSkill';
import SelectUser from '../Overview/SelectUser';
import UploadFile from 'components/Forms/UploadFile';
import SelectStaffContractDetailsStatus from '../SelectStaffContractDetailsStatus';
import { reaction, IReactionDisposer } from 'mobx';
import { required } from 'helpers/FormValidation';

interface State {
    documents: Array<ContractDocumentInterface>
}

@observer class Skills extends React.Component<any, State> {

    form = new FormState({
        id: new FieldState<string | null>(null),
        skillId: new FieldState(null).validators(required),
        accountableUserId: new FieldState(null).validators(required),
        staffContractDetailsStatus: new FieldState(null).validators(required),
        // documentId: new FieldState<string | null>(null),
    })

    reactionDisposer: IReactionDisposer | any;

    constructor(props: any) {
        super(props);

        this.state = {
            documents: []
        }
    }

    componentDidMount() {
        userStore.getUsers(false);

        if (store.contract && store.contract.department) {
            const locationId = store.contract!.department.locationId;
            skillStore.getSkills(locationId);
        }

        this.reactionDisposer = reaction(() => store.currentLocationId, (locationId) => {
            if (locationId === null) {
                skillStore.resetStore();
                return;
            }

            if (
                locationId && store.contract &&
                ((store.contract.department !== null && store.contract.department.locationId !== locationId) ||
                (store.contract && store.contract!.department === null))) {
                skillStore.getSkills(locationId!);
            }
        })

    }

    componentWillUnmount() {
        this.reactionDisposer();
    }

    openFile = async (e: React.MouseEvent, minioName: string) => {
        e.preventDefault();
        e.stopPropagation();

        const endpoint = await documentStore.getDocumentUrl(minioName) as DocumentUrlInterface;
        window.open(endpoint.url);
    }

    uploadFile = async (document: ContractDocumentInterface) => {
        this.setState({ documents: [...this.state.documents, document] });
    }

    onClose = () => {
        this.setState({ documents: [] })
    }

    onCreateSkill = async (skill: StaffSkillInterface) => {
        const createdSkill = await store.createSkill(skill) as StaffSkillInterface;
        const skillId = createdSkill.id;

        await this.addDocumentsToSkill(skillId);
    }

    addDocumentsToSkill = async (skillId: string) => {
        const { documents } = this.state;

        for (const doc of documents) {
            await store.addDocumentToSkill(skillId, doc);
        }
    }

    onUpdateSkill = async (skill: StaffSkillInterface) => {
        await store.updateSkill(skill);
        await this.addDocumentsToSkill(skill.id);
    }

    deleteDocument = async (skillId: string, documentId: string) => {
        if (window.confirm(languageStore.get('areYouSureYouWantToArchiveDocument'))) {
            await store.removeDocumentFromSkill(skillId, documentId);
        }
    }

    render() {
        const { documents } = this.state;
        const data = store.contract!.skills || [];
        const users = userStore.users;
        const skills = skillStore.skills || [];
        const form = this.form.$;

        let existingDocs: Array<ContractDocumentInterface> = [];
        if (form.id.$) {
            const skill = _.find(data, { id: form.id.$ }) as StaffSkillInterface;
            if (skill) {
                existingDocs = skill.documents || [];
            }
        }

        const columns = [
            {
                Header: <TableGreenHeader value={languageStore.get('skill')} />,
                id: 'skill',
                accessor: (d: any) => {
                    const skill = _.find(skills, { id: d.skillId }) as SkillInterface;
                    return skill ? skill.name : '-';
                }
            },
            {
                Header: <TableGreenHeader value={languageStore.get('responsible')} />,
                id: 'accountableUserId',
                accessor: (d: any) => formatUser(users, d.accountableUserId)
            },
            {
                Header: <TableGreenHeader value={languageStore.get('status')} />,
                id: 'status',
                accessor: (d: any) => formatStaffContractDetailsStatus(d.staffContractDetailsStatus)
            }
        ]

        return (
            <BaseContractTable
                form={this.form}
                onUpdate={this.onUpdateSkill}
                onSubmit={this.onCreateSkill}
                onDelete={(id: string) => store.deleteSkill(id)}
                collection={data}
                onClose={this.onClose}
                columns={columns}
                tableTitle={languageStore.get('skill')}
                modalTitle={languageStore.get('skill')}
                store={store}
                hash={SectionEnum.SKILL}>

                <ModalColumn>

                    <SelectSkill
                        fieldState={form.skillId}
                        editMode={true} />

                    <SelectUser
                        label={languageStore.get('responsible')}
                        id='accountableUserId'
                        fieldState={form.accountableUserId}
                        editMode={true} />

                    <label className='text-green-dark text-sm block font-bold mb-1'>{languageStore.get('files')}</label>

                    {
                        existingDocs.map((document, index) => {
                            return (
                                <p
                                    key={index}
                                    className='text-black mt-2 cursor-pointer'>
                                    <span onClick={(e) => this.openFile(e, document!.minioName)}>
                                        {document.fileName}
                                    </span>
                                    <span className='text-xs ml-4 text-red' onClick={() => this.deleteDocument(form.id.value!, document.id)}>
                                        ({languageStore.get('delete')})
                                    </span>
                                </p>
                            )
                        })
                    }

                    {
                        documents.map((document, index) => {
                            return (
                                <p
                                    key={index}
                                    className='text-black mt-2 cursor-pointer'>
                                    <span onClick={(e) => this.openFile(e, document!.minioName)}>
                                        {document.fileName}
                                    </span>
                                    <span className='text-xs ml-4'>
                                        ({languageStore.get('readyToBeSaved')})
                                    </span>
                                </p>
                            )
                        })
                    }

                    <UploadFile onSubmit={this.uploadFile}>

                        <button onClick={(e) => e.preventDefault()} className='bg-comablue-label text-white py-2 px-8 rounded block mt-2'>
                            {languageStore.get('upload')}
                        </button>

                    </UploadFile>



                    <SelectStaffContractDetailsStatus
                        fieldState={form.staffContractDetailsStatus}
                        editMode={true} />

                </ModalColumn>

            </BaseContractTable>
        )
    }

}

export default Skills;