import TableDetails from "components/AdminPage/TableDetails";
import DefaultButton from "components/Forms/DefaultButton";
import TableHeader from "components/Table/TableHeader";
import { observer } from "mobx-react";
import { adminCategoryDetailsStore, languageStore, SubCategoryInterface, toastStore } from "stores";
import useCategoryByParams from "../useCategoryByParams";

const Subcategories = observer(() => {
    const category = useCategoryByParams();
    if (!category) return null;

    const onCreateSubcategory = async () => {
        const name = window.prompt(languageStore.get('name'));
        if (!name) return;

        const subCategory = {
            name: name,
            categoryId: category.id
        } as SubCategoryInterface;

        await adminCategoryDetailsStore.createSubCategory(category.locationId!, subCategory);
        toastStore.addDefaultSuccessfulSaveToast();
    }

    const onDeleteSubcategory = async (subCategory: SubCategoryInterface) => {
        if (!window.confirm(`${languageStore.get('areYouSureYouWantToDelete')}?`)) return;

        await adminCategoryDetailsStore.deleteSubCategory(category.locationId!, category.id, subCategory.id);
        toastStore.addDefaultSuccessfulSaveToast();
    }

    const onRenameSubcategory = async (subCategory: SubCategoryInterface) => {
        const name = window.prompt(languageStore.get('name'), subCategory.name);
        if (!name || name === subCategory.name) return;

        subCategory.name = name;
        await adminCategoryDetailsStore.updateSubcategory(category.locationId!, subCategory);
        toastStore.addDefaultSuccessfulSaveToast();
    }

    const columns = [
        {
            Header: <TableHeader value={languageStore.get('name')} />,
            id: 'name',
            accessor: 'name'
        },
        {
            Header: null,
            id: 'actions',
            width: 180,
            accessor: (d: SubCategoryInterface) => {
                return (
                    <>
                        <button
                            className='ml-6 mr-6 px-2 py-1 text-xs text-grey-darkest rounded bg-grey-light hover:bg-red-lighter'
                            onClick={(e) => onRenameSubcategory(d)}>
                            {languageStore.get('edit')}
                        </button>
                        <button
                            className='px-2 py-1 text-xs text-red rounded bg-red-lightest hover:bg-red-lighter'
                            onClick={(e) => onDeleteSubcategory(d)}>
                            {languageStore.get('remove')}
                        </button>
                    </>
                )
            }
        }
    ]

    return (
        <TableDetails
            actions={
                <DefaultButton
                    onClick={onCreateSubcategory}
                    label={languageStore.get('add')}
                />
            }
            data={category.subCategories.slice()}
            columns={columns}
            filterBy={['name']}
        />
    )

})

export default Subcategories;