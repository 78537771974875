import * as React from 'react';
import { observer } from 'mobx-react';
import ContractDetailsItem from 'components/ContractDetailsItem';
import { CustomerInterface, SupplierInterface } from 'stores';
import { FieldState } from 'formstate';
import * as _ from 'lodash';

interface Props {
    customerId: string | null,
    fieldState: FieldState<any>,
    editMode?: boolean,
    field: string,
    label: string,
    collection: Array<CustomerInterface> | Array<SupplierInterface>,
    onClick?: () => void
}

@observer class CustomerField extends React.Component<Props> {

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        if (nextProps.customerId !== this.props.customerId) {
            const { fieldState, field, collection } = this.props;

            if (nextProps.customerId) {
                const customer = _.find(collection, { id: nextProps.customerId });
                const f = customer ? customer[field] : '';
                fieldState.onChange(f);
            }
            else {
                fieldState.onChange('');
            }
        }
    }

    render() {
        const { fieldState, editMode, label, field, onClick } = this.props;

        return <ContractDetailsItem
            label={label}
            id={field}
            fieldState={fieldState}
            editMode={editMode}
            onClick={onClick}
        />
    }

}

export default CustomerField;