import { BaseStore } from './BaseStore';
import { observable, action, makeObservable } from 'mobx';

export interface TimeZoneInterface {
    "id": string,
    "standardName": string,
    "utcOffset": string
}

export class TimeZoneStore extends BaseStore {
    @observable timeZones: Array<TimeZoneInterface> = [];

    constructor() {
        super();

        makeObservable(this);
    }

    @action
    getTimeZones() {
        this.get('users/timezones', 'timeZones', false);
    }
}

export const timeZoneStore = new TimeZoneStore();