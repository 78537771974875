import * as React from 'react';
import { observer } from 'mobx-react';
import './style.css';
import { loadingStore } from 'stores/LoadingStore';
import { reaction } from 'mobx';

interface State {
    show: boolean
}

@observer class LoadingBar extends React.Component<any, State> {

    timer: NodeJS.Timeout | any;

    constructor(props: any) {
        super(props);

        this.state = {
            show: false
        }
    }

    componentDidMount() {
        reaction(() => loadingStore.isLoading, () => {
            if (loadingStore.isLoading) {
                this.timer = setTimeout(() => {
                    this.setState({ show: true })
                }, 200)
            }
            else {
                clearTimeout(this.timer);
                this.setState({ show: false });
            }

        });
    }

    render() {
        const { show } = this.state;
        if (!show) return null;

        return (
            <>
            <div className="loadingBar-cover" />
            <div className="loadingBar-container hidden xl:block">
                <div className="loadingBar"><div></div><div></div><div></div><div></div></div>
            </div>
            </>
        )
    }

}

export default LoadingBar;