import { BaseContractsStore, ContractStatusEnum, ViewByEnum, ContractNoteInterface } from './BaseContractsStore';

export interface PurchaseContractInterface {
    "id"?: string,
    "categoryId"?: string,
    "categoryName"?: string,
    "subCategoryId"?: string,
    "subCategoryName"?: string,
    "status"?: ContractStatusEnum,
    "supplierId"?: string,
    "supplierName"?: string,
    "responsibleUserId"?: string,
    "responsibleUserFullName"?: string,
    "description"?: string,
    "contractDateCreated": null,
    "renewalDate"?: string,
    "expirationDate"?: string,
    "departmentId"?: string,
    "departmentName"?: string,
    "locationId"?: string,
    "locationName"?: string,
    "hasDocuments"?: boolean
    "notes"?: Array<ContractNoteInterface>,
}

export class PurchaseContractsStore extends BaseContractsStore<PurchaseContractInterface> {

    constructor() {
        super();
        
        this.viewBy = ViewByEnum.CATEGORY;
        this.contractEndpoint = 'purchase';
    }

}

export const purchaseContractsStore = new PurchaseContractsStore();