import * as React from 'react';
import { observer } from 'mobx-react';
import ContractDetailsItem from 'components/ContractDetailsItem';
import { supplierStore, SupplierInterface, ContactPeopleInterface, customerStore, CustomerInterface } from 'stores';
import { FieldState } from 'formstate';
import * as _ from 'lodash';

interface Props {
    supplierId?: string,
    customerId?: string,
    contactId?: string,
    label: string,
    id: string,
    fieldState: FieldState<any>,
    editMode?: boolean,
    onUpdateFieldState: Function
}

@observer class SelectedContactDetails extends React.Component<Props> {

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        const { fieldState, onUpdateFieldState } = this.props;

        if (nextProps.supplierId !== this.props.supplierId ||
            nextProps.customerId !== this.props.customerId) {
            fieldState.onChange('');
            return;
        }

        if (nextProps.contactId !== this.props.contactId) {

            if (nextProps.contactId) {
                if (nextProps.supplierId) {
                    const supplier = _.find(supplierStore.suppliers, { id: nextProps.supplierId }) as SupplierInterface;
                    const contact = _.find(supplier.contactPeople, { id: nextProps.contactId }) as ContactPeopleInterface;
                    onUpdateFieldState(contact);
                }
                else if (nextProps.contactId) {
                    const customer = _.find(customerStore.customers, { id: nextProps.customerId }) as CustomerInterface;
                    const contact = _.find(customer.contactPeople, { id: nextProps.contactId }) as ContactPeopleInterface;
                    onUpdateFieldState(contact);
                }
            }
            else {
                fieldState.onChange('');
            }
        }
    }

    render() {
        const { label, id, fieldState, editMode } = this.props;

        return <ContractDetailsItem
            label={label}
            id={id}
            fieldState={fieldState}
            editMode={editMode}
        />
    }

}

export default SelectedContactDetails;