import request from "api/request";

export class GlobalSupportStore {

    sendTicket = (subject: string, bodyHtml: string) => {
        return new Promise(async (resolve, reject) => {
            try {
                await request.post(`support/new-ticket`, {
                    subject: subject,
                    body: bodyHtml,
                    isHtml: true
                });

                resolve(true);
            }
            catch(error) {
                reject(error);
            }
        })
    }

}

export const globalSupportStore = new GlobalSupportStore();