import * as React from 'react';
import { observer } from 'mobx-react';

import NotesImg from './notes.svg';
import FilesImg from './files.svg';
import { navigationStore, ContractNoteInterface } from 'stores';
import SectionEnum from 'components/PurchaseContractDetailsPage/SectionEnum';

interface Props {
    contractType: string,
    contractId: number,
    notes?: Array<ContractNoteInterface>,
    hasFiles?: boolean
}

interface State {
    showNotes: boolean
}

@observer class FilesNotesCell extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            showNotes: false
        }
    }

    toggleNotes() {
        this.setState({ showNotes: !this.state.showNotes });
    }

    gotoContract(section: SectionEnum) {
        const { contractType, contractId } = this.props;

        navigationStore.push(`${contractType}/${contractId}/${section}`);
    }

    onNotesClicked(e: React.MouseEvent) {
        e.stopPropagation();

        this.gotoContract(SectionEnum.NOTES);
    }

    onFilesClicked(e: React.MouseEvent) {
        e.stopPropagation();

        this.gotoContract(SectionEnum.FILES_CONTRACT);
    }

    render() {
        const { hasFiles, notes } = this.props;
        const { showNotes } = this.state;

        return (
            <div>
                {
                    notes && notes.length > 0 &&
                    <span>
                        <img
                            alt=''
                            src={NotesImg}
                            className='mx-1 hover:bg-grey hover:cursor-pointer'
                            onMouseEnter={e => this.toggleNotes()}
                            onMouseLeave={e => this.toggleNotes()}
                            onClick={e => this.onNotesClicked(e)} />

                        {
                            showNotes &&
                            <div style={{zIndex: 99999}} className={`
                            absolute
                            bg-white
                            rounded
                            border
                            p-4
                            `}>

                                {
                                    notes.map((note: ContractNoteInterface, index: number) => {
                                        return (
                                            <p key={index} className='max-w-md relative whitespace-normal mb-2'>
                                                <b>{note.title}</b><br />
                                                {note.description}
                                            </p>
                                        )
                                    })
                                }
                            </div>
                        }

                    </span>

                }

                {
                    hasFiles &&
                    <img
                        alt=''
                        src={FilesImg}
                        className='mx-1 hover:bg-grey hover:cursor-pointer'
                        onClick={e => this.onFilesClicked(e)} />
                }
            </div>
        );
    }

}

export default FilesNotesCell;