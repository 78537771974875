import FilesNotesCell from '../FilesNotesCell';
import TableHeader from 'components/Table/TableHeader';
import * as _ from 'lodash';
import { ViewByEnum } from 'stores';
import { getPurchaseColumns } from './purchaseColumns';
import { getStaffColumns, getStaffInterviewColumns, getStaffEquipmentColumns, getStaffBenefitsColumns } from './staffColumns';
import { getServiceColumns } from './serviceColumns';
import { getSalesColumns } from './salesColumns';

class ColumnHelper {

    getFilesNotesColumn(contractEndpoint: string) {
        const filesNotesColumn = {
            Header: <TableHeader />,
            id: 'filesNotes',
            sortable: false,
            filterable: false,
            Cell: (row: any) => {
                const item = row.original;

                return <FilesNotesCell
                    notes={item.notes}
                    hasFiles={item.hasDocuments}
                    contractId={item.id}
                    contractType={contractEndpoint} />
            },
            width: 45,
        }

        return filesNotesColumn;
    }

    get(filteredColumns: Array<string>, allColumns: Array<any>, contractEndpoint: string): Array<any> {
        let returnVal: Array<any> = [];

        // Always add filesNotes column first
        returnVal.push(this.getFilesNotesColumn(contractEndpoint));

        filteredColumns.forEach((col) => {
            var res = _.find(allColumns, (c) => c.id === col);
            returnVal.push(res);
        })

        return returnVal;
    }

    private getPurchaseColumns(view: ViewByEnum, contractEndpoint: string) {
        switch (view) {
            case ViewByEnum.EXPIRATION_DATE:
                return this.get([
                    'dealNumber',
                    'supplierName',
                    'responsibleUserFullName',
                    'description',
                    'contractDateCreated',
                    'status',
                    'departmentName',
                    'locationName',
                    'expirationDate',
                    'expirationDays'
                ], getPurchaseColumns(), contractEndpoint);
            case ViewByEnum.CATEGORY:
                return this.get([
                    'dealNumber',
                    'subCategoryName',
                    'description',
                    'renewalDate',
                    'expirationDate',
                    'status',
                    'departmentName',
                    'locationName',
                ], getPurchaseColumns(), contractEndpoint);

            case ViewByEnum.SUPPLIER:
                return this.get([
                    'dealNumber',
                    'categoryName',
                    'subCategoryName',
                    'description',
                    'renewalDate',
                    'expirationDate',
                    'status',
                    'departmentName',
                    'locationName',
                ], getPurchaseColumns(), contractEndpoint);

            case ViewByEnum.LOCATION:
                return this.get([
                    'dealNumber',
                    'categoryName',
                    'subCategoryName',
                    'supplierName',
                    'description',
                    'renewalDate',
                    'expirationDate',
                    'status',
                    'departmentName',
                ], getPurchaseColumns(), contractEndpoint);

            case ViewByEnum.DEPARTMENT:
                return this.get([
                    'dealNumber',
                    'categoryName',
                    'subCategoryName',
                    'supplierName',
                    'description',
                    'renewalDate',
                    'expirationDate',
                    'status',
                    'locationName',
                ], getPurchaseColumns(), contractEndpoint);

            case ViewByEnum.RESPONSIBLE:
                return this.get([
                    'dealNumber',
                    'supplierName',
                    'categoryName',
                    'subCategoryName',
                    'renewalDate',
                    'expirationDate',
                    'status',
                    'departmentName',
                    'locationName',
                ], getPurchaseColumns(), contractEndpoint);

            default: return [];
        }
    }

    getStaffColumns(view: ViewByEnum, contractEndpoint: string) {
        switch (view) {
            case ViewByEnum.RENEGOTIATION_DATE:
                return this.get([
                    'name',
                    'departmentName',
                    'employmentDate',
                    'staffAgreementName',
                    'contractStatus',
                    'locationName',
                    'renegotiationDate',
                    'daysToRenegotiation'
                ], getStaffColumns(), contractEndpoint)
            case ViewByEnum.ALPHABETICAL:
                return this.get([
                    'name',
                    'departmentName',
                    'employmentDate',
                    'staffAgreementName',
                    'contractStatus',
                    'locationName'
                ], getStaffColumns(), contractEndpoint)

            case ViewByEnum.LOCATION:
                return this.get([
                    'name',
                    'employmentDate',
                    'staffAgreementName',
                    'contractStatus',
                    'departmentName'
                ], getStaffColumns(), contractEndpoint)

            case ViewByEnum.DEPARTMENT:
                return this.get([
                    'name',
                    'employmentDate',
                    'staffAgreementName',
                    'contractStatus',
                    'locationName'
                ], getStaffColumns(), contractEndpoint)

            case ViewByEnum.INTERVIEWS:
                return this.get([
                    'fullName',
                    'heldOn',
                    'status'
                ], getStaffInterviewColumns(), contractEndpoint)

            case ViewByEnum.EQUIPMENT:
                return this.get([
                    'fullName',
                    'handedOut',
                    'creatorName',
                    'status'
                ], getStaffEquipmentColumns(), contractEndpoint)

            case ViewByEnum.BENEFITS:
                return this.get([
                    'fullName',
                    'handedOut',
                    'creatorName',
                    'status'
                ], getStaffBenefitsColumns(), contractEndpoint)

            default: return [];
        }

    }

    getServiceColumns(view: ViewByEnum, contractEndpoint: string) {
        switch (view) {
            case ViewByEnum.SERVICE_DATE:
                return this.get([
                    'dealNumber',
                    'categoryName',
                    'subCategoryName',
                    'description',
                    'expirationDate',
                    'status',
                    'locationName',
                    'serviceDate',
                    'daysToService'
                ], getServiceColumns(), contractEndpoint)

            case ViewByEnum.CATEGORY:
                return this.get([
                    'dealNumber',
                    'subCategoryName',
                    'description',
                    'customerName',
                    'serviceDate',
                    'expirationDate',
                    'status',
                    'locationName'
                ], getServiceColumns(), contractEndpoint)

            case ViewByEnum.CUSTOMER:
                return this.get([
                    'dealNumber',
                    'dealNumber',
                    'subCategoryName',
                    'description',
                    'serviceDate',
                    'expirationDate',
                    'status',
                    'locationName'
                ], getServiceColumns(), contractEndpoint)

            case ViewByEnum.LOCATION:
                return this.get([
                    'dealNumber',
                    'categoryName',
                    'subCategoryName',
                    'customerName',
                    'serviceDate',
                    'expirationDate',
                    'status'
                ], getServiceColumns(), contractEndpoint)

            case ViewByEnum.DEPARTMENT:
                return this.get([
                    'dealNumber',
                    'categoryName',
                    'subCategoryName',
                    'customerName',
                    'serviceDate',
                    'expirationDate',
                    'status',
                    'locationName'
                ], getServiceColumns(), contractEndpoint)

            case ViewByEnum.RESPONSIBLE:
                return this.get([
                    'dealNumber',
                    'categoryName',
                    'subCategoryName',
                    'customerName',
                    'serviceDate',
                    'expirationDate',
                    'status',
                    'locationName'
                ], getServiceColumns(), contractEndpoint)

            default: return [];
        }
    }

    getSalesColumns(view: ViewByEnum, contractEndpoint: string) {
        switch (view) {
            case ViewByEnum.EXPIRATION_DATE:
                return this.get([
                    'salesNumber',
                    'categoryName',
                    'subCategoryName',
                    'customerName',
                    'description',
                    'renewalDate',
                    'status',
                    'departmentName',
                    'locationName',
                    'expirationDate',
                    'daysToExpiration'
                ], getSalesColumns(), contractEndpoint)

            case ViewByEnum.CATEGORY:
                return this.get([
                    'salesNumber',
                    'subCategoryName',
                    'customerName',
                    'description',
                    'renewalDate',
                    'expirationDate',
                    'status',
                    'departmentName',
                    'locationName'
                ], getSalesColumns(), contractEndpoint)

            case ViewByEnum.CUSTOMER:
                return this.get([
                    'salesNumber',
                    'categoryName',
                    'subCategoryName',
                    'description',
                    'renewalDate',
                    'expirationDate',
                    'status',
                    'departmentName',
                    'locationName'
                ], getSalesColumns(), contractEndpoint)

            case ViewByEnum.LOCATION:
                return this.get([
                    'salesNumber',
                    'categoryName',
                    'subCategoryName',
                    'customerName',
                    'description',
                    'renewalDate',
                    'expirationDate',
                    'status',
                    'departmentName'
                ], getSalesColumns(), contractEndpoint)

            case ViewByEnum.DEPARTMENT:
                return this.get([
                    'salesNumber',
                    'categoryName',
                    'subCategoryName',
                    'customerName',
                    'description',
                    'renewalDate',
                    'expirationDate',
                    'status',
                    'locationName'
                ], getSalesColumns(), contractEndpoint)

            case ViewByEnum.RESPONSIBLE:
                return this.get([
                    'salesNumber',
                    'customerName',
                    'subCategoryName',
                    'renewalDate',
                    'expirationDate',
                    'status',
                    'departmentName',
                    'locationName'
                ], getSalesColumns(), contractEndpoint)

            default: return [];
        }
    }

    getColumnsByView(view: ViewByEnum, contractEndpoint: string) {

        switch (contractEndpoint) {
            case 'purchase': return this.getPurchaseColumns(view, contractEndpoint);
            case 'staff': return this.getStaffColumns(view, contractEndpoint);
            case 'service': return this.getServiceColumns(view, contractEndpoint);
            case 'sales': return this.getSalesColumns(view, contractEndpoint);

            default: return [];
        }
    }
}

const columnHelper = new ColumnHelper();
export default columnHelper;