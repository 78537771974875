import * as React from 'react';
import { observer } from 'mobx-react';
import GeneralInformationStep from './GeneralInformationStep';

@observer class CreateNewCompanyPage extends React.Component {

    render() {
        return (
            <GeneralInformationStep />
        )
    }

}

export default CreateNewCompanyPage;