import Form from "components/Forms/Form";
import Input from "components/Forms/Input";
import Location from "components/Forms/Location";
import { Modal, ModalColumn } from "components/Modal";
import { FieldState, FormState } from "formstate";
import { required } from "helpers/FormValidation";
import { observer } from "mobx-react";
import { useState } from "react";
import { DepartmentInterface, departmentStore, languageStore, navigationStore } from "stores";

interface Props {
    departmentsCollection: Array<DepartmentInterface>,
    restrictLocationsByCompanyId?: string
}

const AddDepartmentModal = observer((props: Props) => {
    const [showModal, setShowModal] = useState(false);

    const form = new FormState({
        name: new FieldState('').validators(required),
        locationId: new FieldState('').validators(required)
    })

    const onSubmit = async () => {
        const department = {
            name: form.$.name.$,
            locationId: form.$.locationId.$
        } as DepartmentInterface

        const res = await departmentStore.createDepartment(department, props.departmentsCollection) as DepartmentInterface;

        form.reset();
        setShowModal(false);
        navigationStore.push(`/admin/departments/${res.id}`);
    }

    return (
        <div className='text-left'>
            <button onClick={() => setShowModal(true)} className='bg-comablue-label text-white py-2 px-8 rounded'>
                {languageStore.get('add')}
            </button>
            {
                showModal &&
                <Form onValidSubmit={onSubmit} formState={form}>
                    <Modal
                        title={languageStore.get('department')}
                        primaryButton={{ title: languageStore.get('save') }}
                        secondaryButton={{ title: languageStore.get('cancel'), onClick: () => setShowModal(false) }}>

                        <ModalColumn>

                            <Input
                                fieldState={form.$.name}
                                id='department'
                                label={languageStore.get('name')} />

                            <Location
                                fieldState={form.$.locationId}
                                editMode={true}
                                companyId={props.restrictLocationsByCompanyId}
                            />

                        </ModalColumn>

                    </Modal>
                </Form>
            }
        </div>
    )
})

export default AddDepartmentModal;