import { languageStore } from "stores"

interface Props {
    onDelete: () => void
}

const DeleteButton = (props: Props) => {
    return (
        <button
            className='ml-4 bg-red-dark text-white px-4 py-2 rounded inline'
            style={{ position: 'absolute', bottom: '33px', left: '24px' }}
            type='button'
            onClick={() => props.onDelete()}>
            {languageStore.get('delete')}
        </button>
    )
}

export default DeleteButton;