import * as React from 'react';
import { observer } from 'mobx-react';
import { FieldState } from 'formstate';
import Moment from 'moment-timezone';
import Flatpickr from 'react-flatpickr';
import { formatDate } from 'helpers/DateHelper';

import './style.css';


interface Props {
    label: string,
    fieldState: FieldState<any>,
    id?: string,
    editMode?: boolean,
    beforeChange?: Function
}

@observer class ContractDateItem extends React.Component<Props> {

    onChange = (value: any) => {
        const { beforeChange } = this.props;
        const val = value[0];

        if (beforeChange) {
            beforeChange(val);
        }

        this.props.fieldState.onChange(val);
    }

    render() {
        const { label, fieldState, id, editMode } = this.props;

        let readableDate = '-'
        if (Moment.utc(fieldState.value).isValid()) {
            readableDate = formatDate(fieldState.value) || '';
        }

        if (editMode && fieldState && id) {
            const editValue = fieldState.value ? Moment.utc(fieldState.value).toISOString() : undefined;

            return (
                <div
                    style={{ height: '41px', border: fieldState.error ? '1px solid #e4002b' : undefined }}
                    id={id} className={`relative border py-2 px-2 rounded mb-6 mt-8`}>
                    <Flatpickr
                        options={{
                            dateFormat: 'd/m/Y', allowInput: true, onClose: (selectedDates, dateStr, instance) => {
                                if (!dateStr) {
                                    this.props.fieldState.onChange(null);
                                    return;
                                }

                                const newDate = Moment(dateStr, 'DD/MM/YYYY');
                                const currentDate = Moment(this.props.fieldState.value, 'DD/MM/YYYY')

                                if (!newDate.isSame(currentDate)) {
                                    this.onChange([newDate.toDate()]);
                                }
                            }
                        }}
                        className='w-full bg-transparent text-green-darker leading-normal rounded'
                        value={editValue} />
                    <label className="absolute block text-comablue-label pin-t leading-normal cursor-pointer Dropdown-label-floating" htmlFor={id}>
                        {label} {fieldState.error && <span className="text-red text-xs pl-1">({fieldState.error})</span>}
                    </label>
                </div>
            )
            // return <Input className='mt-8' fieldState={fieldState} id={id} label={label} />
        }

        return (
            <>
                <dt className='mt-5 text-sm text-comablue-label font-semibold'>{label}</dt>
                <dd className='pt-1'>{readableDate || '-'}</dd>
            </>
        )
    }

}

export default ContractDateItem;