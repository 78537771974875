import ShowOnPrint from "components/ShowOnPrint";
import { useState } from "react";
import ReactTable, { Column, SubComponentFunction } from "react-table";
import { languageStore } from "stores";
import _ from 'lodash';

interface Props {
    onSearch?: (searchQuery: string) => void,
    actions?: React.ReactNode,
    columns: Array<Column>,
    data: Array<any>,
    onRowClicked?: (rowId: string) => void,
    filterBy?: Array<string>,
    subComponent?: SubComponentFunction
}

const TableDetails = (props: Props) => {
    const [searchQuery, setSearchQuery] = useState<string>('');

    const expandSubcomponentOnRowClicked = (state: any, rowInfo: any | undefined, col: any, instance: any): object => {
        return {
            onClick: async (e: React.MouseEvent) => {
                const { expanded } = state;
                const path = rowInfo.nestingPath[0];
                const diff = { [path]: expanded[path] ? false : true };

                instance.setState({
                    expanded: {
                        ...expanded,
                        ...diff
                    }
                });

            }
        }
    }


    let data = props.data;
    if (props.filterBy && searchQuery.length > 2) {
        data = _.filter(data, (entity) => {
            return _.some(props.filterBy, (val) => {
                return entity[val].toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1
            })
        })
    }

    return (
        <div className={`w-full mx-auto`}>

            {
                (props.filterBy || props.actions) &&
                <div className='flex mb-5'>
                    <div className='text-left flex-1'>
                        {
                            props.filterBy &&
                            <input
                                onChange={(e) => setSearchQuery(e.target.value)}
                                className='border py-1 pl-2  mr-4 pr-3 text-green-darker leading-normal rounded'
                                id='search'
                                type='text'
                                placeholder={`${languageStore.get('search')}...`}
                            />
                        }

                    </div>

                    <div className='text-right flex-1'>
                        <div className='text-right inline-block'>
                            {props.actions}
                        </div>
                    </div>
                </div>
            }

            <div className='border'>
                <ShowOnPrint>
                    <ReactTable
                        className={!props.onRowClicked ? 'ReactTable-readonly' : ''}
                        data={data.slice()}
                        columns={props.columns}
                        defaultPageSize={99999}
                        defaultSorted={props.columns[0]?.id ? [{ id: props.columns[0]?.id }] : undefined}
                        showPagination={false}
                        NoDataComponent={() => null}
                        SubComponent={props.subComponent}
                        getTrProps={props.subComponent ? expandSubcomponentOnRowClicked : undefined}
                        getTdProps={(state: any, rowInfo: any, column: any, instance: any) => {
                            return {
                                onClick: (e: React.MouseEvent, handleOriginal: any) => {
                                    if (props.subComponent) return;
                                    
                                    if (props.onRowClicked) {
                                        const id = rowInfo.original.id;
                                        props.onRowClicked(id);
                                    }
                                }
                            };
                        }}
                        minRows={0} />
                </ShowOnPrint>
            </div>
        </div>
    )

}

export default TableDetails;