import * as React from 'react';

interface Props {
    children?: React.ReactNode
}

const Column: React.SFC = (props: Props) => {
    return (
        <div className='w-1/2 inline-block p-4'>
            {props.children}
        </div>
    )
}

export default Column;