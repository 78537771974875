import * as React from 'react';
import { observer } from 'mobx-react';
import { Modal, ModalColumn } from 'components/Modal';
import { languageStore, CompanyUserInterface, DepartmentInterface, departmentStore, companyUserStore, locationStore } from 'stores';
import * as _ from 'lodash';

interface Props {
    onClose: () => void,
    user: CompanyUserInterface | null
}

interface State {
    selectedIds: Array<string>
}

@observer class DepartmentsModal extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            selectedIds: []
        }
    }

    componentDidMount() {
        const { user } = this.props;
        departmentStore.getDepartmentsByCompany(user!.companyId);
        locationStore.getLocationsByCompany(user!.companyId);

        this.setState({
            selectedIds: user!.departments.map((d) => d.id)
        })
    }

    deleteFromDepartment(e: React.MouseEvent, department: DepartmentInterface) {
        e.preventDefault();

        this.setState({
            selectedIds: this.state.selectedIds.filter((depId) => depId !== department.id)
        })

    }

    addToDepartment(e: React.MouseEvent, department: DepartmentInterface) {
        e.preventDefault();

        const depIds = this.state.selectedIds;
        depIds.push(department.id);

        this.setState({
            selectedIds: depIds
        })
    }

    onSave = async () => {
        const { user } = this.props;

        const departments = departmentStore.departments.filter((d) => this.state.selectedIds.indexOf(d.id) !== -1);
        await companyUserStore.setDepartments(user!.companyId, user!.id, departments);

        this.onClose();
    }

    onClose = async () => {
        const { onClose } = this.props;

        this.setState({ selectedIds: [] });

        onClose();
    }

    render() {
        const { user } = this.props;
        const { selectedIds } = this.state;

        if (!user) return null;

        const departments = departmentStore.departments || [];
        const locations = locationStore.locations || [];

        return (
            <Modal
                title={languageStore.get('departments')}
                primaryButton={{title: languageStore.get('save'), onClick: this.onSave }}
                secondaryButton={{ title: languageStore.get('close'), onClick: this.onClose }}>

                <ModalColumn>

                    {
                        departments.map((department: DepartmentInterface, index: number) => {

                            const hasItem = selectedIds.indexOf(department.id) !== -1;
                            const location = _.find(locations, { id: department.locationId });

                            let className = 'mt-2';
                            if (!hasItem) {
                                className += ' text-grey';
                            }

                            return (<div key={index} className={className}>
                                {department.name} {location ? `(${location.name})` : null}

                                {
                                    hasItem &&
                                    <button className='ml-2 px-2 py-1 text-xs text-red rounded bg-red-lightest hover:bg-red-lighter' onClick={(e) => this.deleteFromDepartment(e, department)}>{languageStore.get('remove')}</button>
                                }

                                {
                                    !hasItem &&
                                <button className='ml-2 px-2 py-1 text-xs text-green rounded bg-green-lightest hover:bg-green-lighter' onClick={(e) => this.addToDepartment(e, department)}>{languageStore.get('add')}</button>
                                }
                            </div>)
                        })
                    }

                </ModalColumn>

            </Modal>
        )
    }

}

export default DepartmentsModal;