enum SectionEnum {

    OVERVIEW = '#overview',
    EMERGENCY_CONTACT = '#emergency_contact',
    DATES = '#dates',
    AGREEMENT = '#agreement',
    APPLICANT_CV = '#applicantcv',
    APPRAISAL_INTERVIEWS = '#appraisal_interviews',
    EDUCATION_DETAILS = '#education_details',
    BENEFITS = '#benefits',
    EQUIPMENT = '#equipment',
    SKILL = '#skill',
    NOTES = '#notes',
    EXTRA_ALARMS = '#extra_alarms',
    FILES_CONTRACT = '#files_contract',
    FILES_CORRESPONDENCE = '#files_correspondence',
    VERIFIED_BY = '#verified_by',
    STATUS = '#status',
    LINKED_CONTRACTS = '#linked_contracts',
    CUSTOM_FIELDS = '#custom_fields'
}

export default SectionEnum;