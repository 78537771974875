import { BaseStore } from './BaseStore';
import { observable, action, makeObservable } from 'mobx';
import { DocumentTypeContractTypeEnum } from './BaseContractsStore';
import { DashboardContractTypeEnum } from './DashboardStore';


export interface DocumentTypeInterface {
    "id": string,
    "contractType": DocumentTypeContractTypeEnum | DashboardContractTypeEnum,
    "name": string,
    "required": boolean,
    "orderPriority": number,
    "companyId": string,
}

export class DocumentTypeStore extends BaseStore {
    @observable documentTypes: Array<DocumentTypeInterface> = [];

    constructor() {
        super();

        makeObservable(this);
    }

    @action
    getDocumentTypes(companyId: string) {
        return this.get(`companies/${companyId}/documentTypes`, 'documentTypes');
    }

    @action
    createDocumentType(companyId: string, documentType: DocumentTypeInterface) {
        return this.create(`companies/${companyId}/documentTypes`, documentType, this.documentTypes);
    }

    @action
    updateDocumentType(companyId: string, documentType: DocumentTypeInterface) {
        return this.update(`companies/${companyId}/documentTypes/${documentType.id}`, documentType, this.documentTypes);
    }

    @action
    deleteDocumentType(companyId: string, documentTypeId: string) {
        return this.delete(`companies/${companyId}/documentTypes/${documentTypeId}`, documentTypeId, this.documentTypes);
    }
}

export const documentTypeStore = new DocumentTypeStore();