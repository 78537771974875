import { BaseStore } from './BaseStore';
import { observable, action, makeObservable } from 'mobx';
import { LocationInterface } from './LocationStore';

export interface SkillInterface {
    "id": string,
    "name": string,
    "locationId": string,
    "location": LocationInterface
}

export class SkillStore extends BaseStore {
    @observable skills: Array<SkillInterface> = [];
    @observable allSkills: Array<SkillInterface> = [];

    constructor() {
        super();

        makeObservable(this);
    }

    @action
    getSkills(locationId: string) {
        this.get(`locations/${locationId}/skills`, 'skills');
    }

    @action
    getAllSkills() {
        return this.get('skills', 'allSkills');
    }

    @action
    createSkill(skill: SkillInterface) {
        return this.create(`locations/${skill.locationId}/skills`, skill, this.allSkills);
    }

    @action
    updateSkill(skill: SkillInterface) {
        return this.update(`locations/${skill.locationId}/skills/${skill.id}`, skill, this.allSkills);
    }

    @action
    deleteSkill(locationId: string, skillId: string) {
        return this.delete(`locations/${locationId}/skills/${skillId}`, skillId, this.allSkills);
    }

    @action
    resetStore() {
        this.skills = [];
        this.allSkills = [];
    }
}

export const skillStore = new SkillStore();