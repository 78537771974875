import * as React from 'react';
import { observer } from 'mobx-react';
import ContractDetailsGroup from 'components/ContractDetailsGroup';
import ContractDetailsColumn from 'components/ContractDetailsColumn';
import ContractDetailsItem from 'components/ContractDetailsItem';
import { FormState, FieldState } from 'formstate';
import { languageStore, CurrentContractStore, CurrentContractBaseInterface } from 'stores';
import SpecialCircumstances from 'components/SpecialCircumstances';
import Currency from '../Currency';
import BaseContract from 'components/StaffContractDetailsPage/BaseContract';
import SelectTrueFalse from '../SelectTrueFalse';
import { required, requiredNotNull } from 'helpers/FormValidation';

interface T extends CurrentContractBaseInterface {
    "contractSum": number,
    "paymentTerms": string,
    "indexAdjustment": number,
    "specialCircumstances": boolean,
    "specialCircumstancesDiscription": string,
    "warranty"?: boolean,
    "warrantyDescription"?: string
}

interface Props {
    store: CurrentContractStore<T>,
    hash: string,
    hasWarranty: boolean
}

interface State {
    showIndexAdjustment: boolean
}

@observer class ContractSum extends React.Component<Props, State> {

    form = new FormState({
        contractSum: new FieldState(this.props.store.contract!.contractSum).validators(requiredNotNull),
        paymentTerms: new FieldState<string | null>(this.props.store.contract!.paymentTerms),
        indexAdjustment: new FieldState<number | null>(this.props.store.contract!.indexAdjustment),
        showIndexAdjustment: new FieldState<boolean>(!!this.props.store.contract!.indexAdjustment && this.props.store.contract!.indexAdjustment > 0),
        specialCircumstances: new FieldState<boolean>(this.props.store.contract!.specialCircumstances),
        specialCircumstancesDiscription: new FieldState(this.props.store.contract!.specialCircumstancesDiscription || ''),
        warranty: new FieldState(this.props.store.contract!.warranty),
        warrantyDescription: new FieldState(this.props.store.contract!.warrantyDescription || ''),
        currencyId: new FieldState(this.props.store.contract!.currencyId).validators(required)
    })

    constructor(props: Props) {
        super(props);

        this.form.$.showIndexAdjustment.onUpdate((fs) => {
            if (fs.value === false) {
                this.form.$.indexAdjustment.reset(null);
            }
        })
    }

    render() {
        const { store, hash, hasWarranty } = this.props;
        const form = this.form.$;
        const editMode = store.isEditMode(hash);

        return (
            <BaseContract
                store={store}
                form={this.form}
                sectionEnum={hash}>

                <ContractDetailsGroup title={languageStore.get('contractSum')}>

                    <ContractDetailsColumn>

                        <ContractDetailsItem
                            label={languageStore.get('contractSum')}
                            id='contractSum'
                            type='number'
                            fieldState={form.contractSum}
                            editMode={editMode} />


                        <ContractDetailsItem
                            label={languageStore.get('paymentTerms')}
                            id='paymentTerms'
                            fieldState={form.paymentTerms}
                            editMode={editMode} />

                        <Currency
                            store={store}
                            fieldState={form.currencyId}
                            editMode={editMode} />

                    </ContractDetailsColumn>

                    <ContractDetailsColumn>

                        {
                            editMode &&
                            <SelectTrueFalse
                                id='showIndexAdjustment'
                                fieldState={form.showIndexAdjustment}
                                label={`${languageStore.get('indexAdjustment')}?`}
                                editMode={true} />
                        }

                        {
                            (!editMode && !form.showIndexAdjustment.value) &&
                            <>
                                <dt className='mt-5 text-sm text-comablue-label font-semibold truncate'>{languageStore.get('indexAdjustment')}</dt>
                                <dd className='pt-1 break-words'>{languageStore.get('no')}</dd>
                            </>
                        }

                        {
                            ((!editMode && form.indexAdjustment.value && form.indexAdjustment.value > 0) || (editMode && form.showIndexAdjustment.value === true)) &&
                            <ContractDetailsItem
                                label={languageStore.get('indexAdjustment')}
                                id='indexAdjustment'
                                type='number'
                                fieldState={form.indexAdjustment}
                                editMode={editMode} />
                        }



                    </ContractDetailsColumn>

                </ContractDetailsGroup>

                <SpecialCircumstances
                    hasSpecialCircumstances={true}
                    hasWarranty={hasWarranty}
                    form={this.form}
                    editMode={!!editMode} />

            </BaseContract>
        )

    }

}

export default ContractSum;