import * as React from 'react';
import { observer } from 'mobx-react';
import { languageStore } from 'stores';
import ContractDetailsGroup from 'components/ContractDetailsGroup';
import ContractDetailsColumn from 'components/ContractDetailsColumn';
import SelectTrueFalse from 'components/Forms/SelectTrueFalse';
import ContractDetailsItem from 'components/ContractDetailsItem';
import { FormState } from 'formstate';

interface Props {
    editMode: boolean,
    form: FormState<any>,
    hasSpecialCircumstances: boolean,
    hasWarranty: boolean
}

@observer class SpecialCircumstances extends React.Component<Props> {

    render() {
        const { editMode, form, hasSpecialCircumstances, hasWarranty } = this.props;

        return (
            <ContractDetailsGroup title={languageStore.get('specialCircumstances')}>

                {
                    hasSpecialCircumstances &&
                    <ContractDetailsColumn>

                        <SelectTrueFalse
                            label={languageStore.get('specialCircumstances')}
                            id='specialCircumstances'
                            fieldState={form.$.specialCircumstances}
                            editMode={editMode} />

                        {
                            form.$.specialCircumstances.value &&
                            <ContractDetailsItem
                                label={languageStore.get('description')}
                                id='specialCircumstancesDiscription'
                                fieldState={form.$.specialCircumstancesDiscription}
                                textArea={true}
                                editMode={editMode} />
                        }

                    </ContractDetailsColumn>
                }

                {
                    hasWarranty &&
                    <ContractDetailsColumn>

                        <SelectTrueFalse
                            label={languageStore.get('warranty')}
                            id='warranty'
                            fieldState={form.$.warranty}
                            editMode={editMode} />

                        {
                            form.$.warranty.value &&
                            <ContractDetailsItem
                                label={languageStore.get('description')}
                                id='warrantyDescription'
                                fieldState={form.$.warrantyDescription}
                                textArea={true}
                                editMode={editMode} />
                        }

                    </ContractDetailsColumn>

                }

            </ContractDetailsGroup>
        )
    }

}

export default SpecialCircumstances;