import * as React from 'react';
import { languageStore, navigationStore, FullPurchaseContractInterface, currentStaffContractStore, FullStaffContractInterface, currentServiceContractStore, FullServiceContractInterface, currentSalesContractStore, FullSalesContractInterface, RoleEnum, ContractStatusEnum, StaffContractStatusEnum } from 'stores';
import { observer } from 'mobx-react';
import { currentPurchaseContractStore } from 'stores';
import ExpandButton from 'components/Header/ExpandButton';
import { BaseContractTypeEnum } from 'stores/BaseStore';
import { canAccess, ModuleEnum } from 'helpers/ModuleHelper';
import { hasRole } from 'helpers/RoleHelper';

interface StateInterface {
    visibleMenu: boolean
}

@observer class NewContractButton extends React.Component<any, StateInterface> {


    async createContract(type: BaseContractTypeEnum) {

        let contract;
        switch(type) {
            case BaseContractTypeEnum.PURCHASE:
            currentPurchaseContractStore.resetContract();
            contract = await currentPurchaseContractStore.postDraftContract(ContractStatusEnum.Draft) as FullPurchaseContractInterface;
            currentPurchaseContractStore.setIsDraftJustCreated(true);
            break;

            case BaseContractTypeEnum.STAFF:
            currentStaffContractStore.resetContract();
            contract = await currentStaffContractStore.postDraftContract(StaffContractStatusEnum.Draft) as FullStaffContractInterface;
            currentStaffContractStore.setIsDraftJustCreated(true);
            break;

            case BaseContractTypeEnum.SERVICE:
            currentServiceContractStore.resetContract();
            contract = await currentServiceContractStore.postDraftContract(ContractStatusEnum.Draft) as FullServiceContractInterface;
            currentServiceContractStore.setIsDraftJustCreated(true);
            break;

            case BaseContractTypeEnum.SALES:
            currentSalesContractStore.resetContract();
            contract = await currentSalesContractStore.postDraftContract(ContractStatusEnum.Draft) as FullSalesContractInterface;
            currentSalesContractStore.setIsDraftJustCreated(true);
            break;

        }

        if (contract) {
            navigationStore.push(`/${type}/${contract.id}`);
        }
    }

    render() {
        let items = [];

        if (canAccess(ModuleEnum.PURCHASE) && (hasRole(RoleEnum.PURCHASE_WRITE) || hasRole(RoleEnum.PURCHASE_ADMIN))) {
            items.push({
                text: languageStore.get('purchase'),
                onClick: () => this.createContract(BaseContractTypeEnum.PURCHASE)
            })
        }

        if (canAccess(ModuleEnum.STAFF) && (hasRole(RoleEnum.HR_WRITE) || hasRole(RoleEnum.HR_ADMIN))) {
            items.push({
                text: languageStore.get('staff'),
                onClick: () => this.createContract(BaseContractTypeEnum.STAFF)
            })
        }

        if (canAccess(ModuleEnum.SALES) && (hasRole(RoleEnum.SALES_WRITE) || hasRole(RoleEnum.SALES_ADMIN))) {
            items.push({
                text: languageStore.get('sales'),
                onClick: () => this.createContract(BaseContractTypeEnum.SALES)
            })
        }

        if (canAccess(ModuleEnum.SERVICE) && (hasRole(RoleEnum.SERVICE_WRITE) || hasRole(RoleEnum.SERVICE_ADMIN))) {
            items.push({
                text: languageStore.get('service'),
                onClick: () => this.createContract(BaseContractTypeEnum.SERVICE)
            })
        }

        if (items.length === 0) return null;
        
        return (
            <ExpandButton text={languageStore.get('newContract')} items={items} />
        )
    }
}

export default NewContractButton;