import * as React from 'react';
import { observer } from 'mobx-react';
import { languageStore, contractsUpForDeletionStore, DashboardContractTypeEnum, navigationStore } from 'stores';
import ReactTable from 'react-table';
import TableHeader from 'components/Table/TableHeader';
import { formatDate } from 'helpers/DateHelper';

@observer class ContractsUpForDeletionPage extends React.Component {

    componentDidMount() {
        contractsUpForDeletionStore.getContracts();
    }

    gotoContract = (entity: any) => {
        let endpointType = null;
        switch (entity.contractType) {
            case DashboardContractTypeEnum.PURCHASE: {
                endpointType = 'purchase';
                break;
            }
            case DashboardContractTypeEnum.STAFF: {
                endpointType = 'staff';
                break;
            }
            case DashboardContractTypeEnum.SALES: {
                endpointType = 'sales';
                break;
            }
            case DashboardContractTypeEnum.SERVICE: {
                endpointType = 'service';
                break;
            }
        }

        if (endpointType) {
            navigationStore.push(`/${endpointType}/${entity.id}`);
        }
    }


    render() {
        const contracts = contractsUpForDeletionStore.contracts || [];

        const columns = [
            {
                Header: <TableHeader value={languageStore.get('contractType')} />,
                id: 'contractType',
                accessor: (d: any) => {
                    switch (d.contractType) {
                        case DashboardContractTypeEnum.PURCHASE: return languageStore.get('purchase');
                        case DashboardContractTypeEnum.STAFF: return languageStore.get('staff');
                        case DashboardContractTypeEnum.SALES: return languageStore.get('sales');
                        case DashboardContractTypeEnum.SERVICE: return languageStore.get('service');
                        default: return null;
                    }
                }
            },
            {
                Header: <TableHeader value={languageStore.get('companyName')} />,
                accessor: 'companyName',
            },
            {
                Header: <TableHeader value={languageStore.get('deletionDate')} />,
                id: 'deletionDate',
                accessor: (d: any) => formatDate(d.deletionDate)
            },
            {
                Header: <TableHeader value={languageStore.get('deletionNotice')} />,
                accessor: 'deletionNotice'
            },
            {
                Header: <TableHeader value={languageStore.get('deletionProlongReason')} />,
                accessor: 'deletionProlongReason'
            },
            {
                Header: <TableHeader value={languageStore.get('files')} />,
                id: 'files',
                accessor: (d: any) => d.fileNames.join(', ')
            },
        ]

        return (
            <div className='mt-10 mx-auto'>
                <div className='border rounded p-5'>
                    <ReactTable
                        data={contracts.slice()}
                        columns={columns}
                        defaultPageSize={99999}
                        showPagination={false}
                        NoDataComponent={() => null}
                        getTdProps={(state: any, rowInfo: any, column: any, instance: any) => {
                            return {
                                onClick: (e: React.MouseEvent, handleOriginal: any) => {
                                    const entity = rowInfo.original;
                                    this.gotoContract(entity);
                                }
                            };
                        }}
                        minRows={0} />
                </div>
            </div>

        )
    }
}

export default ContractsUpForDeletionPage;