import React from 'react';
import { observer } from 'mobx-react';
import { FieldState } from 'formstate';
import { economicStore } from 'stores/EconomicStore';
import ContractDropdownItem from 'components/ContractDropdownItem';
import { languageStore } from 'stores';

interface Props {
    fieldState: FieldState<any>,
    customerId?: string,
    id: string,
    label: string
}

@observer class SelectCustomerContact extends React.Component<Props> {

    componentDidMount() {
        const { customerId } = this.props;
        if (customerId) {
            economicStore.getCustomerContacts(customerId);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        if (nextProps.customerId && nextProps.customerId !== this.props.customerId) {
            economicStore.getCustomerContacts(nextProps.customerId);
            this.props.fieldState.onChange(null);
        }
    }

    render() {
        const { fieldState, label, id } = this.props;
        const contacts = economicStore.customerContacts;

        const options = contacts.map((contact) => ({
            value: contact.customerContactNumber,
            name: contact.name
        }))

        return (
            <ContractDropdownItem
                label={label}
                placeholderText={languageStore.get('select')}
                id={id}
                fieldState={fieldState}
                options={options}
                editMode={true} />
        )
    }
}

export default SelectCustomerContact;