import * as React from 'react';
import { observer } from 'mobx-react';
import { Modal, ModalColumn } from 'components/Modal';
import { languageStore, locationStore, LocationInterface, categoryStore, CreateCategoryInterface, DocumentTypeContractTypeEnum, CreateSubCategoryInterface } from 'stores';
import { FormState, FieldState } from 'formstate';
import Country from 'components/Forms/Country';
import Input from 'components/Forms/Input';
import Form from 'components/Forms/Form';
import { required } from 'helpers/FormValidation';
import SelectTrueFalse from 'components/Forms/SelectTrueFalse';
import * as _ from 'lodash';

interface Props {
    onClose: Function,
    companyId: string
}

@observer class CreateLocationModal extends React.Component<Props> {

    form = new FormState({
        name: new FieldState('').validators(required),
        countryId: new FieldState('').validators(required),
        defaultCategories: new FieldState(false)
    })

    getSubCategories(names: Array<string>) {
        const val = [] as Array<CreateSubCategoryInterface>;
        
        names.forEach((name) => {
            val.push({name: _.upperFirst(name)});
        })

        return val;
    }

    getCategory(name: string, subCategories: Array<string>) {
        return {
            name: _.upperFirst(name),
            contractType: DocumentTypeContractTypeEnum.PURCHASE,
            subCategories: this.getSubCategories(subCategories)
        }
    }

    getDefaultCategories(): Array<CreateCategoryInterface> {
        return [
            this.getCategory(
                'energi',
                ['el', 'naturgas', 'fyringsolie']
            ),
            this.getCategory(
                'kommunikation',
                ['fastnet telefoni','mobil telefoni','fastlinje bredbånd','mobilt bredbånd','telefonanlæg','køb af mobiltelefoner']
            ),
            this.getCategory(
                'forsikring/pension',
                ['ejendom','inventar','kørende materiel','sundhedsforsikring','pensioner']
            ),
            this.getCategory(
                'IT',
                ['drift/hosting/backup','server/storage','køb af hardware og tilbehør','informationsskærme','software licenser','videokonferenceudstyr','nødstrøm','dokumenthåndtering/scanning af dokument','IT-sikkerhed(beskyttelse)','konsulentydelser']
            ),
            this.getCategory(
                'kopi/print',
                ['kopimaskiner/printere','papir/toner','serviceeftersyn']
            ),
            this.getCategory(
                'grafisk/tryksager/layout',
                ['tryksager','brevpapir/kuverter','grafisk design','website']
            ),
            this.getCategory(
                'markedsføring',
                ['annoncering (trykte medier/online','søgeoptimering']
            ),
            this.getCategory(
                'administration',
                ['kontorartikler']
            ),
            this.getCategory(
                'facility service',
                ['rengøring / vinduespolering','renovation','kontorbeplantning','alarm/sikring/vagter/overvågningskamera','skadedyrsservice','elevatordrift','grønne arealer/anlægsgartner']
            ),
            this.getCategory(
                'hotel, rejser og møder',
                ['hotel','rejse(fly/taxa)','konference- og mødeomkostninger']
            ),
            this.getCategory(
                'ventilation',
                ['køling/aircondition']
            ),
            this.getCategory(
                'auto drift',
                ['leasing','service','køb/salg af biler','benzin/diesel/transportdiesel','flaskegas']
            ),
            this.getCategory(
                'transport/distribution/lager',
                ['transport/fragt/flytninger','distribution/logistik/forsendelse','lager/lagerhotel']
            ),
            this.getCategory(
                'reklamegaver/gaveartikler',
                ['reklamegaver, give-aways m.v.','vingaver/blomster m.v.']
            ),
            this.getCategory(
                'personale',
                ['arbejdstøj','kaffeautomater/vand','catering/kantinedrift','frugtordning','personalegaver']
            ),
            this.getCategory(
                'vask',
                ['linnedservice','arbejdstøj','måtter']
            ),
            this.getCategory(
                'hygiejneprodukter',
                ['papirprodukter','sæber m.v. (person/vaskemaskine og opvaskere)','dispensere']
            ),
            this.getCategory(
                'emballage',
                ['emballage (pap, plastik, gummi)']
            ),
            this.getCategory(
                'husleje',
                ['huslejekontrakter']
            ),
            this.getCategory(
                'kontormøbler',
                ['kontormøbler']
            ),
            this.getCategory(
                'økonomi',
                ['bank (bankrelaterede ydelser)','revision','bogholderi/bogføringsassistance','kreditforeningslån','regnskabssystemer (ERP)']
            ),
            this.getCategory(
                'telemarketing',
                ['møder og analyser','telefonvagt og service']
            ),
            this.getCategory(
                'uddannelse/træning',
                ['salg/ledelse/kommunikation']
            ),
            this.getCategory(
                'inkasso',
                ['inkassoomkostninger']
            ),
            this.getCategory(
                'optimering',
                ['energioptimering','håndværksomkostninger']
            ),
        ]
    }

    createLocation = async () => {
        const form = this.form.$;

        const { companyId, onClose } = this.props;

        const location = {
            companyId: companyId,
            name: form.name.$,
            id: '',
            countryId: form.countryId.$,
            country: {}
        } as LocationInterface

        const loc = await locationStore.createCompanyLocation(location) as LocationInterface;

        if (form.defaultCategories.$ === true) {
            await categoryStore.createCategories(loc, this.getDefaultCategories())
        }

        onClose();
    }

    render() {
        const { onClose } = this.props;
        const form = this.form.$;

        return (
            <Form formState={this.form} onValidSubmit={this.createLocation}>
                <Modal
                    title={languageStore.get('createLocation')}
                    primaryButton={{ title: languageStore.get('create') }}
                    secondaryButton={{ title: languageStore.get('close'), onClick: onClose }}>

                    <ModalColumn>

                        <Input
                            id='name'
                            label={languageStore.get('name')}
                            fieldState={form.name}
                            type='text' />

                        <Country
                            fieldState={form.countryId}
                            editMode={true} />

                        <SelectTrueFalse
                            fieldState={form.defaultCategories}
                            label={languageStore.get('defaultCategories')}
                            id='defaultCategories'
                            editMode={true} />

                    </ModalColumn>

                </Modal>
            </Form>
        )

    }

}

export default CreateLocationModal;