import { observable, action, makeObservable } from 'mobx';
import request from 'api/request';
import * as Moment from 'moment-timezone';
import { RoleInterface } from './RolesStore';
import * as _ from 'lodash';
import { CompanyInterface, companyStore } from './CompanyStore';
import { DepartmentInterface } from './DepartmentStore';
import { LanguageEnum } from './LanguageStore';
import { TimeZoneInterface } from './TimeZoneStore';
import { BaseStore } from './BaseStore';
import { UserTwoFactorAuthTypeEnum } from './AuthStore';
import { DashboardContractTypeEnum } from './DashboardStore';

export interface UserInterface {
    "fullName": string,
    "email": string,
    "title": string,
    "phone": string,
    "skypeName": string,
    "mobile": string,
    "address": string,
    "description": string,
    "language": LanguageEnum,
    "location": string,
    "id": string,
    "company": CompanyInterface,
    "roles": Array<RoleInterface>,
    "lastSeenNotifications": string,
    "unseenNotifications": number,
    "passwordExpires": string,
    "allowSuperAdminLogin": boolean,
    "departments": Array<DepartmentInterface>,
    "timeZoneId": string,
    "timeZone": TimeZoneInterface,
    "needsTwoFactorAuthSetup": boolean,
    "twoFactorAuthType": UserTwoFactorAuthTypeEnum,
    "twoFactorAuthVerified": boolean
}

interface SaveableInterface {
    signedInUser: UserInterface | any;
}

export enum ForceNewPasswordDismissTypeEnum {
    LOG_OUT,
    CANCEL
}

export class UserStore extends BaseStore {

    @observable users: Array<UserInterface> = new Array<UserInterface>();
    @observable signedInUser: UserInterface | any;
    @observable forceNewPasswordForSignedInUserToContinue: boolean = false;
    @observable forceNewPasswordDismissType: ForceNewPasswordDismissTypeEnum = ForceNewPasswordDismissTypeEnum.LOG_OUT;
    @observable selectedContractType: DashboardContractTypeEnum | null = null;

    constructor() {
        super('UserStore');
        makeObservable(this);

        this.initSessionStorage(this, ['signedInUser']);
    }

    @action
    forceNewPasswordForSignedInUser(value: boolean, forceNewPasswordDismissType: ForceNewPasswordDismissTypeEnum = ForceNewPasswordDismissTypeEnum.LOG_OUT) {
        this.forceNewPasswordForSignedInUserToContinue = value;
        this.forceNewPasswordDismissType = forceNewPasswordDismissType
    }

    @action
    getUsers(force: boolean = false) {
        return this.get('users', 'users', force);
    }

    @action
    async updateSignedInUserCompany(company: CompanyInterface) {
        const data = await companyStore.updateCompany(company);
        this.signedInUser!.company = data;
    }

    @action
    getSignedInUser() {
        return new Promise(async (resolve, reject) => {
            try {
                const me = await request.get('users/me');
                const signedInUser = me.data;
                this.signedInUser = signedInUser;
                resolve(signedInUser);
            }
            catch (error) {
                reject(error);
            }
        })
    }

    @action
    getUserById(id: string) {
        return new Promise(async (resolve, reject) => {

            try {
                await this.getUsers(false);

                let user = _.find(this.users, { id: id }) as UserInterface;
                if (!user) {

                    try {
                        const res = await request.get(`users/${id}`);
                        user = res.data;

                        if (user) {
                            this.users.push(user);
                        }
                    }
                    catch(error) {

                    }
                }
                resolve(user);
            }
            catch (error) {
                reject(error);
            }
        })
    }

    updateSignedInUser(user: UserInterface) {
        if (user.id !== this.signedInUser.id) return;

        const res = this.update(`users/${user.id}`, user, this.users);
        this.signedInUser = user;
        return res;
    }

    @action
    setSelectedContractType(contractType: DashboardContractTypeEnum) {
        this.selectedContractType = contractType;
    }

    setLastseenNotifications(lastSeen: Moment.Moment) {
        return new Promise(async (resolve, reject) => {
            if (!this.signedInUser) {
                reject('Signed in user not available');
            }

            try {
                const userId = this.signedInUser.id;
                const res = await request.put(`users/${userId}/notifications/setlastseen`, lastSeen, {
                    headers: {
                        '_byPassTransactionKey': 'true'
                    }
                });

                if (res.status === 200) {
                    this.signedInUser.lastSeenNotifications = lastSeen.toISOString();
                    this.signedInUser.unseenNotifications = 0;

                    resolve(this.signedInUser);
                }
            }
            catch (error) {
                reject(error);
            }
        });
    }

    setSaveableData(data: SaveableInterface) {
        this.signedInUser = data.signedInUser;
    }

    protected getSaveableData(): SaveableInterface {
        return {
            signedInUser: this.signedInUser
        }
    }

}

export const userStore = new UserStore();