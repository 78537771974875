import * as React from 'react';
import { observer } from 'mobx-react';
import { languageStore, superAdminStore, CompanyAdminRoleInterface } from 'stores';
import * as _ from 'lodash';

@observer class StatsBox extends React.Component {

    componentDidMount() {
        superAdminStore.getStats();
    }

    render() {
        const stats = superAdminStore.stats;
        if (!stats) return null;

        const opCount = _.sum(_.map(stats.companyRoles, (d: CompanyAdminRoleInterface) => d.operationalCount));
        const readCount = _.sum(_.map(stats.companyRoles, (d: CompanyAdminRoleInterface) => d.readCount));
        const noRoleCount = _.sum(_.map(stats.companyRoles, (d: CompanyAdminRoleInterface) => d.noRoleCount));

        return (
            <div className="text-center flex mb-10">

                <div className='w-1/2 text-center p-5 flex border mx-auto'>
                    <div className='border-r w-1/2 flex'>
                        <div className='w-1/2 text-center p-5'>
                            <h1 className='text-5xl'>
                                {stats.companyCount || 0}
                            </h1>
                            <p>
                                {languageStore.get('companies')}
                            </p>
                        </div>
                        <div className='w-1/2 text-center p-5'>

                            <h1 className='text-5xl'>
                                {stats.userCount || 0}
                            </h1>
                            <p>
                                {languageStore.get('users')}
                            </p>
                        </div>
                    </div>
                    <div className='w-1/2 text-left pl-6 my-auto'>
                        <p className='pb-2'>
                            {languageStore.get('operational')}
                            <span className='float-right'>{opCount}</span>
                        </p>
                        <p className='pb-2'>
                            {languageStore.get('read')}
                            <span className='float-right'>{readCount}</span>
                        </p>
                        <p>
                            {languageStore.get('noRole')}
                            <span className='float-right'>{noRoleCount}</span>
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}

export default StatsBox;