import * as React from 'react';
import ReactTable from 'react-table';
import { observer } from 'mobx-react';
import { PagingResult, PurchaseContractInterface, BaseContractsStore, linkContractStore } from 'stores';
import { navigationStore } from 'stores';
import columnHelper from './ColumnHelper';
import { redirectToSourceContractIfActive } from 'components/ContractToContract/ContractToContractHelper';

interface Props {
    id: number,
    store: BaseContractsStore<any>,
    onSubRowClicked?: Function,
    defaultSortingId?: string
}

@observer class SubTable extends React.Component<Props, any> {

    private bottomOfTableDivRef: React.RefObject<HTMLDivElement>;
    readonly pageSize = 50;
    _isMounted = false;

    set isMounted(value: boolean) {
        this._isMounted = value;
    }

    get isMounted() {
        return this._isMounted;
    }

    constructor(props: any) {
        super(props);

        this.state = {
            contracts: {},
            fetchingData: false,
            orderBy: null,
            order: 'asc',
            lastViewBy: null
        }

        this.bottomOfTableDivRef = React.createRef<HTMLDivElement>();
    }

    componentWillUnmount() {
        this.isMounted = false;
    }

    componentDidMount() {
        this.isMounted = true;
        // eslint-disable-line
        window.onscroll = async () => {
            const { contracts, fetchingData, orderBy, order } = this.state;
            const { id, store } = this.props;
            const currentContract = contracts[id];


            if (!fetchingData
                && currentContract && currentContract.results.length > 0
                && this.bottomOfTableIsInViewport()
                && currentContract.currentPage < currentContract.pageCount
            ) {
                this.setState({ fetchingData: true });

                await store.getContracts(store.viewBy, id, currentContract.currentPage + 1, this.pageSize, orderBy, order, store.onlyMine, store.expiredOnly);
                const diff = { [id]: store.contracts.get(id) };

                this.setState({
                    fetchingData: false,
                    contracts: {
                        ...contracts,
                        ...diff
                    }
                })
            }
        }
    }

    bottomOfTableIsInViewport(offset = 0) {
        if (!this.bottomOfTableDivRef) return false;
        const element = this.bottomOfTableDivRef.current as Element;
        if (!element) return; // If component has been unmounted, element is undefined
        const top = element.getBoundingClientRect().top;
        return (top + offset) >= 0 && (top - offset) <= window.innerHeight;
    }

    async onFetchData(tableState: any, id: any) {
        const { contracts } = this.state;
        const { store, defaultSortingId } = this.props;
        let orderBy = defaultSortingId || tableState.columns[1].id;
        let order = 'asc';

        // We only sort by one column at a time
        if (tableState.sorted.length === 1) {
            const firstCol = tableState.sorted[0];
            orderBy = firstCol.id;
            order = firstCol.desc ? 'desc' : 'asc'
        }

        let pageNumber = 1;
        let data = null;
        do {
            
            await store.getContracts(store.viewBy, id, pageNumber, this.pageSize, orderBy, order, store.onlyMine, store.expiredOnly);
            data = store.contracts.get(id) as PagingResult<PurchaseContractInterface>;

            const diff = { [id]: store.contracts.get(id) };

            if (this.isMounted) {
                this.setState({
                    orderBy: orderBy,
                    order: order,
                    contracts: {
                        ...contracts,
                        ...diff
                    }
                })
            }

            pageNumber++;
        }
        while (pageNumber < data.pageCount && this.bottomOfTableIsInViewport())

    }

    gotoContractDetails(row: any) {
        const { store, onSubRowClicked } = this.props;

        if (linkContractStore.linkContractIsActive) {
            redirectToSourceContractIfActive(store.contractEndpoint, row.id);
            return;
        }

        if (onSubRowClicked) {
            onSubRowClicked(row);
        }
        else {
            navigationStore.push(`/${store.contractEndpoint}/${row.id}`);
        }
    }

    render() {
        const { id, store, defaultSortingId } = this.props;
        const { contracts } = this.state;
        const currentContract = contracts[id];
        const columns = columnHelper.getColumnsByView(store.viewBy, store.contractEndpoint).slice();

        return (
            <div style={{ paddingLeft: '35px', paddingBottom: '20px' }}>
                <ReactTable
                    data={currentContract ? currentContract.results : []}
                    columns={columns}
                    showPagination={false}
                    minRows={0}
                    defaultPageSize={99999}
                    NoDataComponent={() => null}
                    defaultSorted={[{ id: defaultSortingId ? defaultSortingId : columns[2].id }]}
                    collapseOnDataChange={false}
                    manual // Sorting and pagination will be handled serverside
                    onFetchData={(state: any, instance: any) => {
                        // This is the first time we expand a sub group
                        if (!this.state.lastViewBy) {
                            this.onFetchData(state, id);
                            this.setState({ lastViewBy: store.viewBy });
                        }
                        // The group is already expanded, and user is re-ordering
                        else if (this.state.lastViewBy === store.viewBy) {
                            this.onFetchData(state, id)
                        }
                        // User is navigating to other group. Set View and don't get data
                        else {
                            this.setState({ lastViewBy: store.viewBy });
                        }
                    }}
                    getTdProps={(state: any, rowInfo: any, column: any, instance: any) => {
                        return {
                            onClick: (e: React.MouseEvent, handleOriginal: any) => {
                                this.gotoContractDetails(rowInfo.original);
                            }
                        };
                    }}
                />
                <div ref={this.bottomOfTableDivRef} />
            </div>

        );
    }
}

export default SubTable;