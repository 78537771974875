import * as React from 'react';
import { observer } from 'mobx-react';
import Button from '../Button';
import { languageStore, navigationStore, HeaderEnum } from 'stores';

@observer class PrintButton extends React.Component {

    print = () => {
        window.print();
    }

    render() {
        const ah = navigationStore.activeHeader;

        if (
            ah === HeaderEnum.Dashboard ||
            ah === HeaderEnum.Purchase ||
            ah === HeaderEnum.Staff ||
            ah === HeaderEnum.Service ||
            ah === HeaderEnum.Sales ||
            ah === HeaderEnum.Reports) {
                
            return (
                <Button className='mr-4' text={languageStore.get('print')} onClick={() => this.print()} />
            )
        }
        else return null;

    }

}

export default PrintButton;