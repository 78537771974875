import * as React from 'react';
import { observer } from 'mobx-react';
import { navigationStore } from 'stores';

interface Props {
    sections: Array<Section>
}

interface Item {
    title: string,
    hash: string
}

interface Section {
    title: string,
    items: Array<Item>
}

@observer class ContractDetailsMenu extends React.Component<Props> {

    private renderSection(section: Section, key: number): JSX.Element {
        const currentHash = navigationStore.currentHash;

        return (
            <section key={key}>
                <h3 className='text-sm'>
                    {section.title}
                </h3>
                <ul className='list-reset'>
                    {
                        section.items.map((item, index) => {
                            const isActive = currentHash === item.hash;
                            return this.renderSectionItem(item.title, item.hash, isActive, index);
                        })
                    }
                </ul>
            </section>
        );
    }

    private renderSectionItem(title: string, hash: string, isActive: boolean, index: number): JSX.Element {
        const activeClass = 'font-semibold text-red no-underline';
        const defaultClass = 'no-underline text-black';

        return (
            <li className='ml-1 my-2 text-sm' key={index}>
                <a href={hash} className={isActive ? activeClass : defaultClass}>{title}</a>
            </li>
        );
    }

    render() {
        const { sections } = this.props;

        return (
            <nav className='pt-4 w-64 absolute xl:fixed bg-white'>
                {
                    sections.map((section, index) => {
                        return this.renderSection(section, index);
                    })
                }
            </nav>
        )
    }

}

export default ContractDetailsMenu;