import * as React from 'react';

export interface HorizontalCategoryMenuItem {
    title: string,
    active?: boolean,
    onClick: Function,
    payload?: string | boolean
}

interface Props {
    size: 'small' | 'large',
    items: Array<HorizontalCategoryMenuItem>,
    disabled?: boolean
}

class HorizontalCategoryMenu extends React.Component<Props> {

    render() {
        const { size, items, disabled } = this.props;

        return (
            <ul className={`list-reset border rounded w-auto inline-block ${disabled ? 'invisible' : ""}`}>
                {
                    items.map((item: HorizontalCategoryMenuItem, index) => {
                        let className = 'inline-block px-4';

                        if (size === 'large') {
                            className += ' px-4 py-2 text-sm'
                        }
                        else {
                            className += ' px-2 py-1 text-xs'
                        }

                        if (item.active) {
                            className += ' bg-red rounded text-white cursor-default';
                        }
                        else {
                            className += ' hover:bg-grey-lighter cursor-pointer';
                        }

                        const itemClicked = () => {
                            item.onClick(item);
                        }

                        return (
                            <li key={index} className={className} onClick={!disabled ? itemClicked : undefined}>{item.title}</li>
                        )
                    })
                }

            </ul>
        )
    }

}

export default HorizontalCategoryMenu;