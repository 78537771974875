import * as React from 'react';
import { observer } from 'mobx-react';
import { Modal, ModalColumn } from 'components/Modal';
import { languageStore, CompanyUserInterface, companyUserStore, locationStore, LocationInterface } from 'stores';
import * as _ from 'lodash';
import { hasLocationAndDepartmentAdminRole } from 'helpers/RoleHelper';

interface Props {
    onClose: () => void,
    user: CompanyUserInterface | null
}

interface State {
    selectedIds: Array<string>
}

@observer class LocationsModal extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            selectedIds: []
        }
    }

    componentDidMount() {
        const { user } = this.props;
        if (hasLocationAndDepartmentAdminRole()) {
            locationStore.getLocationsByCompany(user!.companyId);
        }
        else {
            locationStore.getLocations(true);
        }

        this.setState({
            selectedIds: user!.locations.map((l) => l.id)
        })
    }

    deleteFromLocation(e: React.MouseEvent, location: LocationInterface) {
        e.preventDefault();

        this.setState({
            selectedIds: this.state.selectedIds.filter((locId) => locId !== location.id)
        })

        // const { user } = this.props;
        // companyUserStore.deleteFromLocation(user!.companyId, user!.id, location);
    }

    addToLocation(e: React.MouseEvent, location: LocationInterface) {
        e.preventDefault();

        const locIds = this.state.selectedIds;
        locIds.push(location.id);
        
        this.setState({
            selectedIds: locIds
        })
        // const { user } = this.props;
        // companyUserStore.addToLocation(user!.companyId, user!.id, location);
    }

    onSave = async () => {
        const { user } = this.props;

        const locations = locationStore.locations.filter((l) => this.state.selectedIds.indexOf(l.id) !== -1);
        await companyUserStore.setLocations(user!.companyId, user!.id, locations);

        this.onClose();
    }

    onClose = async () => {
        const { onClose } = this.props;

        this.setState({ selectedIds: [] });

        onClose();
    }

    render() {
        const { user } = this.props;
        const { selectedIds } = this.state;
        if (!user) return null;

        const locations = _.orderBy(locationStore.locations, 'name');

        return (
            <Modal
                title={languageStore.get('locations')}
                primaryButton={{title: languageStore.get('save'), onClick: this.onSave }}
                secondaryButton={{ title: languageStore.get('close'), onClick: this.onClose }}>

                <ModalColumn>

                    {
                        locations.map((location: LocationInterface, index: number) => {

                            const hasItem = selectedIds.indexOf(location.id) !== -1;

                            let className = 'mt-2';
                            if (!hasItem) {
                                className += ' text-grey';
                            }

                            return (
                                <div key={index} className={className}>
                                    {location.name}

                                    {
                                        hasItem &&
                                        <button className='ml-2 px-2 py-1 text-xs text-red rounded bg-red-lightest hover:bg-red-lighter' onClick={(e) => this.deleteFromLocation(e, location)}>{languageStore.get('remove')}</button>
                                    }

                                    {
                                        !hasItem &&
                                        <button className='ml-2 px-2 py-1 text-xs text-green rounded bg-green-lightest hover:bg-green-lighter' onClick={(e) => this.addToLocation(e, location)}>{languageStore.get('add')}</button>
                                    }
                                </div>
                            )
                        })
                    }

                </ModalColumn>

            </Modal>
        )
    }

}

export default LocationsModal;