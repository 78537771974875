import * as React from 'react';
import { observer } from 'mobx-react';
import { languageStore, locationStore, LocationInterface } from 'stores';
import ReactTable from 'react-table';
import TableHeader from 'components/Table/TableHeader';
import CreateLocationModal from './CreateLocationModal';
import UpdateLocationModal from './UpdateLocationModal';

interface Props {
    companyId: string
}

enum ActiveModalEnum {
    NONE,
    CREATE_LOCATION,
    UPDATE_LOCATION
}

interface State {
    activeModal: ActiveModalEnum,
    activeLocation: LocationInterface | null
}

@observer class Locations extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);

        this.state = {
            activeModal: ActiveModalEnum.NONE,
            activeLocation: null
        }
    }

    componentDidMount() {
        const { companyId } = this.props;
        locationStore.getLocationsByCompany(companyId);
    }

    componentWillUnmount() {
        locationStore.resetStore();
    }

    onEditLocation = async (location: LocationInterface) => {
        this.setState({
            activeLocation: location,
            activeModal: ActiveModalEnum.UPDATE_LOCATION
        })
    }

    onCreateLocation = () => {
        this.setState({
            activeModal: ActiveModalEnum.CREATE_LOCATION
        })
    }

    hideModal = () => {
        this.setState({
            activeModal: ActiveModalEnum.NONE,
            activeLocation: null
        })
    }

    render() {
        const { companyId } = this.props;
        const { activeModal, activeLocation } = this.state;
        const locations = locationStore.locations;

        const columns = [
            {
                Header: <TableHeader value={languageStore.get('name')} />,
                accessor: 'name'
            },
        ]

        return (
            <div className='mt-10 mx-auto'>
                <div className='mb-8'>
                    <h2 className='inline'>
                        {languageStore.get('locations')}
                    </h2>
                    <div className='float-right'>
                        <button onClick={() => this.onCreateLocation()} className='bg-comablue-label text-white py-2 px-8 rounded'>
                            {languageStore.get('createLocation')}
                        </button>
                    </div>
                </div>

                <div className='border rounded p-5'>
                    <ReactTable
                        data={locations.slice()}
                        columns={columns}
                        defaultPageSize={99999}
                        showPagination={false}
                        NoDataComponent={() => null}
                        getTdProps={(state: any, rowInfo: any, column: any, instance: any) => {
                            return {
                                onClick: (e: React.MouseEvent, handleOriginal: any) => {
                                    e.preventDefault();

                                    this.onEditLocation(rowInfo.original);
                                }
                            };
                        }}
                        minRows={0} />
                </div>

                {
                    activeModal === ActiveModalEnum.CREATE_LOCATION &&
                    <CreateLocationModal
                        companyId={companyId}
                        onClose={this.hideModal} />
                }

                {
                    (activeModal === ActiveModalEnum.UPDATE_LOCATION && activeLocation) &&
                    <UpdateLocationModal
                        location={activeLocation}
                        onClose={this.hideModal}
                    />
                }
            </div>
        )
    }

}

export default Locations;