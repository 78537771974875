import * as React from 'react';
import { observer } from 'mobx-react';
import { FieldState } from 'formstate';
import ContractDropdownItem from 'components/ContractDropdownItem';
import {
    languageStore as lanStore,
    customerStore
} from 'stores';
import { DropdownOptionInterface } from 'components/Forms/Dropdown';

interface Props {
    fieldState: FieldState<any>,
    fallbackValue?: string,
    editMode?: boolean,
    placeholder?: string,
    skipIds?: Array<string>
}

@observer class SelectedCustomer extends React.Component<Props> {

    componentDidMount() {
        customerStore.getCustomers();
    }

    render() {
        const { fieldState, fallbackValue, editMode, placeholder, skipIds } = this.props;

        let availCustomers: Array<DropdownOptionInterface> = [];
        customerStore.customers && customerStore.customers.forEach((customer) => {
            if (!skipIds?.includes(customer.id!)) {
                availCustomers.push({
                    value: customer.id,
                    name: customer.name
                })
            }
        })

        return <ContractDropdownItem
            label={lanStore.get('selectCustomer')}
            id='supplier'
            placeholderText={placeholder || `--- ${lanStore.get('newCustomer')} ---`}
            fieldState={fieldState}
            options={availCustomers}
            editMode={editMode}
            fallbackValue={fallbackValue}
        />
    }

}

export default SelectedCustomer;