import { userStore, RoleEnum } from 'stores';
import { hasRole } from './RoleHelper';

export enum ModuleEnum {
    PURCHASE,
    SALES,
    STAFF,
    SERVICE,
    INBOX
}

export function hasModule(module: ModuleEnum) {
    if (!userStore.signedInUser) return false;

    const company = userStore.signedInUser.company;

    switch (module) {
        case ModuleEnum.PURCHASE:
            return company.hasPurchaseModul;
        case ModuleEnum.SALES:
            return company.hasSalesModul;
        case ModuleEnum.STAFF:
            return company.hasStaffModul;
        case ModuleEnum.SERVICE:
            return company.hasServiceModul;
        case ModuleEnum.INBOX:
            return company.allowInbox;
        default:
            return false;
    }
}

export function canAccess(module: ModuleEnum) {
    if (!hasModule(module)) return false;
    if (hasRole(RoleEnum.SUPER_ADMIN)) return true;

    switch (module) {
        case ModuleEnum.PURCHASE:
            return hasRole(RoleEnum.PURCHASE_ADMIN) ||
                hasRole(RoleEnum.PURCHASE_READ) ||
                hasRole(RoleEnum.PURCHASE_WRITE)
        case ModuleEnum.SALES:
            return hasRole(RoleEnum.SALES_ADMIN) ||
                hasRole(RoleEnum.SALES_READ) ||
                hasRole(RoleEnum.SALES_WRITE)
        case ModuleEnum.STAFF:
            return hasRole(RoleEnum.HR_ADMIN) ||
                hasRole(RoleEnum.HR_READ) ||
                hasRole(RoleEnum.HR_WRITE)
        case ModuleEnum.SERVICE:
            return hasRole(RoleEnum.SERVICE_ADMIN) ||
                hasRole(RoleEnum.SERVICE_READ) ||
                hasRole(RoleEnum.SERVICE_WRITE)
        case ModuleEnum.INBOX:
            return hasRole(RoleEnum.INBOX)
        default:
            return false;
    }
}