import { BaseStore } from './BaseStore';
import { observable, action, makeObservable } from 'mobx';
import { DocumentSigningRecipientAuthenticationMethod, DocumentSigningRecipientSigningMethodEnum } from './CurrentContractStore';

export interface AddoTemplateInterface {
    "id": string,
    "authenticationMethod": DocumentSigningRecipientAuthenticationMethod,
    "signingMethod": DocumentSigningRecipientSigningMethodEnum,
    "friendlyName": "string"
}

export class AddoTemplateStore extends BaseStore {
    @observable templates: Array<AddoTemplateInterface> = [];

    constructor() {
        super();

        makeObservable(this);
    }

    @action
    resetStore() {
        this.templates = [];
    }

    @action
    getTemplates() {
        this.get('templates/from-addo', 'templates');
    }
}

export const addoTemplateStore = new AddoTemplateStore();