import { observable, action, makeObservable } from 'mobx';
import request from 'api/request';
import * as _ from 'lodash';

export interface ContractNoteInterface {
    "title"?: string,
    "description"?: string,
    "id": string
}

export interface PagingResult<T> {
    "currentPage": number,
    "firstRowOnPage": number,
    "lastRowOnPage": number,
    "pageCount": number,
    "pageSize": number,
    "results": Array<T>,
    "rowCount": number,
    // Local metadata to keep track of order
    "_orderBy"?: string,
    "_order"?: string
}

export interface GroupInterface {
    name: string,
    id: number
}

export enum ViewByEnum {
    // Purchase contracts
    SUPPLIER = 'supplier',
    // Staff contracts
    ALPHABETICAL = 'alphabetical',
    INTERVIEWS = 'appraisalinterviews',
    EQUIPMENT = 'equipment',
    BENEFITS = 'benefits',
    // Service
    SERVICE = 'service',
    // Shared
    CUSTOMER = 'customer',
    DEPARTMENT = 'department',
    EXPIRATION = 'expiration',
    CATEGORY = 'groupedCategory',
    RESPONSIBLE = 'responsible',
    LOCATION = 'location',
    // EXPIRATION
    RENEGOTIATION_DATE = 'renegotiationDate',
    SERVICE_DATE = 'serviceDate',
    EXPIRATION_DATE = 'expirationDate'
}

export enum DocumentTypeContractTypeEnum {
    PURCHASE = 1,
    STAFF = 2,
    SERVICE = 3,
    SALES = 4
}

export enum ContractStatusEnum {
    Draft = 1,
    Offer = 2,
    AcceptedInOperation = 3,
    Discontinued = 4,
    Deleted = 5,
    Renetiation = 6,
    Terminated = 7
}

export class BaseContractsStore<T> {
    @observable onlyMine: boolean = false;
    @observable expiredOnly: boolean = false;
    @observable viewBy: ViewByEnum = ViewByEnum.ALPHABETICAL;
    @observable groups: Array<GroupInterface> = [];
    @observable contracts = new Map<number, PagingResult<T>>();
    @observable filteredContracts: Array<T> | null = null;
    @observable selectedStatus: Number | null = null;

    @observable searchQuery: string | null = null;
    @observable searchQueryIsJsonFormat: boolean = false;
    @observable searchHasMorePages: boolean = false;

    contractEndpoint: string = '';

    constructor() {
        makeObservable(this);
    }

    @action
    setSelectedStatus(status: Number | null) {
        this.selectedStatus = status;
    }

    @action
    getContracts(viewBy: ViewByEnum, id: number, pageNumber: number, pageSize: number, orderBy: string, order: string, onlyMine: boolean = false, expiredOnly = false) {
        return new Promise(async (resolve, reject) => {
            let tempid: any = id;
            let idType;
            switch (viewBy) {
                case ViewByEnum.CATEGORY:
                    if(!id || id == undefined)
                    {
                        tempid = 0;
                    }
                    idType = 'categoryName';
                    break;
                case ViewByEnum.DEPARTMENT:
                    idType = 'departmentId';
                    break;
                case ViewByEnum.SUPPLIER:
                    idType = 'supplierId';
                    break;
                case ViewByEnum.RESPONSIBLE:
                    idType = 'responsibleUserId';
                    break;
                case ViewByEnum.ALPHABETICAL:
                    idType = 'alphabetical';
                    break;
                case ViewByEnum.INTERVIEWS:
                    idType = 'groupId';
                    break;
                case ViewByEnum.EQUIPMENT:
                    idType = 'equipmentId';
                    break;
                case ViewByEnum.BENEFITS:
                    idType = 'benefitId';
                    break;
                case ViewByEnum.CUSTOMER:
                    idType = 'customerId';
                    break;
                case ViewByEnum.SERVICE:
                    idType = 'serviceId';
                    break;
                case ViewByEnum.EXPIRATION_DATE:
                    idType = 'expirationDate';
                    break;
                case ViewByEnum.RENEGOTIATION_DATE:
                    idType = 'renegotiationDate';
                    break;
                case ViewByEnum.SERVICE_DATE:
                    idType = 'serviceDate';
                    break;
                case ViewByEnum.LOCATION:
                    idType = 'locationId';
                    break;
                default:
                    throw new Error('ViewBy not implemented');

            }

            try {
                if(isNaN(tempid))
                {
                    tempid = encodeURIComponent(String(tempid));
                }

                let endpoint = `contracts/${this.contractEndpoint}?pagenumber=${pageNumber}&pagesize=${pageSize}&${idType}=${tempid}&viewBy=${viewBy}&orderBy=${orderBy}&order=${order}&onlyMine=${onlyMine}&expiredOnly=${expiredOnly}`;

                if (viewBy === ViewByEnum.INTERVIEWS ||
                    viewBy === ViewByEnum.EQUIPMENT ||
                    viewBy === ViewByEnum.BENEFITS || 
                    viewBy === ViewByEnum.SERVICE) {
                    endpoint = `contracts/${this.contractEndpoint}/${viewBy}?pagenumber=${pageNumber}&pagesize=${pageSize}&${idType}=${id}&orderBy=${orderBy}&order=${order}`;
                }

                if (this.selectedStatus) {
                    endpoint += `&statuses=${this.selectedStatus}`;
                }

                const res = await request.get(endpoint);
                const contract = this.contracts.get(id) as PagingResult<T>;
                res.data._order = order;
                res.data._orderBy = orderBy;

                // If we have requested the next available page
                // and the sorting stays the same, we should append data.
                // NOTE: _orderBy and _order are metadata, that we append
                // to the local object, to keep state of current order
                if (
                    contract
                    && contract.currentPage === pageNumber - 1
                    && contract._orderBy === res.data._orderBy
                    && contract._order === res.data._order
                ) {

                    res.data.results = [...contract.results, ...res.data.results];
                    this.contracts.set(id, res.data);

                }
                else {
                    this.contracts.set(id, res.data);
                }

                resolve(res.data);
            }
            catch (error) {
                reject(error)
            }
        })
    }

    @action
    search(query: string, pageSize: number): Promise<void> {
        return new Promise(async (resolve, reject) => {
            if (!query) {
                this.filteredContracts = null;
                this.searchQuery = null;
                this.searchQueryIsJsonFormat = false;
                resolve();
                return;
            }

            try {
                let filteredContracts = new Array<T>();

                const res: any = await request.post(`contracts/${this.contractEndpoint}/search`, {
                    searchBy: query,
                    pageSize: pageSize,
                    pageNumber: 1,
                    isJsonFormat: this.searchQueryIsJsonFormat,
                });

                res.data.results.forEach((searchRes: any) => {
                    const contract = searchRes.source;
                    filteredContracts.push(contract);
                })

                this.filteredContracts = filteredContracts;
                this.searchQuery = query;

                if (res.data.pageCount > 1) {
                    this.searchHasMorePages = true;
                }
                else {
                    this.searchHasMorePages = false;
                }

                resolve(res.data);
                
            }
            catch(error) {
                reject(error);
            }

        })
    }

    @action
    setSearchQuery(query: string, isJsonFormat: boolean = false) {
        this.searchQuery = query;
        this.searchQueryIsJsonFormat = isJsonFormat;
    }

    @action
    async getGroups(viewBy: ViewByEnum, onlyMine: boolean = false, expiredOnly = false) {
        try {
            let endpoint = `contracts/${this.contractEndpoint}/groups?viewBy=${viewBy}&onlyMine=${onlyMine}&expiredOnly=${expiredOnly}`;

            if (viewBy === ViewByEnum.INTERVIEWS ||
                viewBy === ViewByEnum.EQUIPMENT ||
                viewBy === ViewByEnum.BENEFITS ||
                viewBy === ViewByEnum.SERVICE) {
                endpoint = `contracts/${this.contractEndpoint}/${viewBy}/groups`;
            }

            if (this.selectedStatus) {
                endpoint += `&statuses=${this.selectedStatus}`;
            }

            let res = await request.get(endpoint);
            let groups: Array<GroupInterface> = [];
            res.data.forEach((group: any) => {
                groups.push({
                    name: group.name,
                    id: group.id
                })
            })

            const namedGroups = _.filter(groups, (g) => !!g.name);
            this.groups = namedGroups;
        }
        catch (error) {

        }
    }

    @action
    setViewBy(viewBy: ViewByEnum) {
        this.viewBy = viewBy;
    }

    @action
    setOnlyMine(value: boolean) {
        this.onlyMine = value;
    }

    @action
    setExpiredOnly(value: boolean) {
        this.expiredOnly = value;
    }
}