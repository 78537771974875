import { BaseStore } from './BaseStore';
import { observable, action, makeObservable } from 'mobx';
import request from 'api/request';
import { DocumentSigningStatusEnum } from './CurrentContractStore';

export interface LastModifiedInterface {
    "contractType": number,
    "purchaseContractId": string,
    "staffContractId": string,
    "salesContractId": string,
    "serviceContractId": string,
    "identification": string,
    "changeTime": string,
    "alarm": {
        "id": string,
        "date": string,
        "noticeDays": 0,
        "note": string,
        "type": 0,
        "responsibleUserId": string,
        "purchaseContractId": string,
        "salesContractId": string,
        "serviceContractId": string,
        "staffContractId": string
    }
}

export interface UnverifiedInterface {
    "contractType": number,
    "purchaseContractId": string,
    "staffContractId": string,
    "salesContractId": string,
    "serviceContractId": string,
    "identification": string,
    "changeTime": string,
    "alarm": {
        "id": string,
        "date": string,
        "noticeDays": number,
        "note": string,
        "type": number,
        "responsibleUserId": string,
        "purchaseContractId": string,
        "salesContractId": string,
        "serviceContractId": string,
        "staffContractId": string
    }
}

export interface DistributionInterface {
    "key": number,
    "count": number,
    "name": string,
    "groupBy": string
}

export interface NearRenewalInterface {
    "contractType": number,
    "purchaseContractId": string,
    "staffContractId": string,
    "salesContractId": string,
    "serviceContractId": string,
    "identification": string,
    "changeTime": string,
    "alarm": {
        "id": string,
        "date": string,
        "noticeDays": number,
        "note": string,
        "type": number,
        "responsibleUserId": string,
        "purchaseContractId": string,
        "salesContractId": string,
        "serviceContractId": string,
        "staffContractId": string
    }
}

export interface DraftInterface {
    "contractType": number,
    "purchaseContractId": string,
    "staffContractId": string,
    "salesContractId": string,
    "serviceContractId": string,
    "identification": string,
    "changeTime": string,
    "alarm": {
        "id": string,
        "date": string,
        "noticeDays": number,
        "note": string,
        "type": number,
        "responsibleUserId": string,
        "purchaseContractId": string,
        "salesContractId": string,
        "serviceContractId": string,
        "staffContractId": string
    }
}

export interface OverviewInterface {
    "month": number,
    "count": number
}

export interface StatusInterface {
    "activeContracts": number,
    "activeContractSumByCurrencyId": Object,
    "expiredContracts": number,
    "expiredContractSumByCurrencyId": Object
}

export enum DashboardContractTypeEnum {
    PURCHASE = 1,
    STAFF = 2,
    SERVICE = 3,
    SALES = 4
}

export enum OverviewTypeEnum {
    RENEWAL = 0,
    EXPIRY = 1,
    RENEGOTIATION = 2,
    SERVICE = 3
}

export interface ForecastInterface {
    contractSumSumsByCurrencyId: Object
}

export class DashboardStore extends BaseStore {
    @observable lastModified: Array<LastModifiedInterface> = [];
    @observable unverified: Array<UnverifiedInterface> = [];
    @observable distribution: Array<DistributionInterface> = [];
    @observable nearrenewal: Array<NearRenewalInterface> = [];
    @observable drafts: Array<DraftInterface> = [];
    @observable overview: Array<OverviewInterface> = [];
    @observable status: StatusInterface | null = null;
    @observable forecast: ForecastInterface | null = null;

    constructor() {
        super();

        makeObservable(this);
    }

    @action
    getLastModified() {
        this.get('dashboard/lastmodified', 'lastModified');
    }

    @action
    getUnverified() {
        this.get('dashboard/unverified', 'unverified');
    }

    @action
    getDistribution(contractType: DashboardContractTypeEnum, groupBy: string, onlyMine: boolean = false) {
        this.get(`dashboard/distribution?contractType=${contractType}&groupBy=${groupBy}&onlyMine=${onlyMine}`, 'distribution');
    }

    @action
    getNearRenewal() {
        this.get('dashboard/nearrenewal', 'nearrenewal');
    }

    @action
    getForecasts(contractType: DashboardContractTypeEnum, year: number, detailed: boolean = false) {
        return this.get(`dashboard/forecast?contractType=${contractType}&year=${year}&detailed=${detailed}`, 'forecast');
    }

    @action
    getDrafts(contractType: DashboardContractTypeEnum, howMany: number): Promise<void> {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await request.get(`dashboard/drafts?contractType=${contractType}&pageNumber=1&pageSize=${howMany}`);
                this.drafts = res.data.results;

                resolve();
            }
            catch (error) {
                reject(error);
            }
        })
    }

    @action
    getOverview(contractType: DashboardContractTypeEnum, overviewType: OverviewTypeEnum, onlyMine: boolean = false) {
        this.get(`dashboard/overview?contractType=${contractType}&overviewType=${overviewType}&onlyMine=${onlyMine}`, 'overview');
    }

    @action
    getStatus(contractType: DashboardContractTypeEnum) {
        this.get(`dashboard/status?contractType=${contractType}`, 'status');
    }

    @action
    getSignings(contractType: DashboardContractTypeEnum, signingStatus: DocumentSigningStatusEnum, howMany: number = 999): Promise<void> {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await request.get(`dashboard/signing-statuses?contractType=${contractType}&pageNumber=1&pageSize=${howMany}&addoSigningStatus=${signingStatus}`);
                resolve(res.data.results);
            }
            catch(error) {
                reject(error);
            }
        })

    }
}

export const dashboardStore = new DashboardStore();