import React from 'react';
import { observer } from 'mobx-react';
import { inboxStore, InboxUsersWithAccessInterface, languageStore, userStore } from 'stores';
import TableHeader from 'components/Table/TableHeader';
import DetailsPage from 'components/AdminPage/DetailsPage';
import { FieldState, FormState } from 'formstate';
import { ModalColumn } from 'components/Modal';
import _ from 'lodash';
import SelectTrueFalse from 'components/Forms/SelectTrueFalse';
import SelectCompanyUser from './SelectCompanyUser';

interface Props {
    inboxId: string,
    data: Array<InboxUsersWithAccessInterface>
}

@observer class InboxUsersTable extends React.Component<Props> {

    form = new FormState({
        id: new FieldState<string | null>(null),
        userId: new FieldState(''),
        isInboxAdmin: new FieldState(false)
    })

    beforeSubmit = (data: any) => {
        const user = _.find(this.props.data, (d) => d.id === data.id)?.user || null;
        if (user) {
            data.user = user;
        }

        if (data.isInboxAdmin === null) {
            data.isInboxAdmin = false;
        }
    }

    render() {
        const { data, inboxId } = this.props;
        const form = this.form.$;
        const isNewEntity = !!!form.id.$;

        const columns = [
            {
                Header: <TableHeader value={languageStore.get('name')} />,
                id: 'name',
                accessor: (d: InboxUsersWithAccessInterface) => d.user.fullName
            },
            {
                Header: <TableHeader value={languageStore.get('administrator')} />,
                id: 'administrator',
                accessor: (d: InboxUsersWithAccessInterface) => d.isInboxAdmin ? languageStore.get('yes') : languageStore.get('no')
            }
        ]

        const selectedUser = _.find(this.props.data, (d) => d.id === form.id.value)?.user || null;

        return (
            <DetailsPage
                generalTitle={languageStore.get('specificUserAccess')}
                modalTitle={selectedUser ? selectedUser.fullName : languageStore.get('specificUserAccess')}
                form={this.form}
                columns={columns}
                collection={data as any}
                beforeSubmit={this.beforeSubmit}
                onUpdate={(data: InboxUsersWithAccessInterface) => inboxStore.putUser(inboxId, data)}
                onSubmit={(data: any) => inboxStore.postUser(inboxId, data.userId, data.isInboxAdmin)}
                onDelete={(id: string) => inboxStore.deleteUser(inboxId, id)}>

                <ModalColumn>

                    {
                        isNewEntity &&
                        <SelectCompanyUser
                            skipUserIds={inboxStore.inbox?.usersWithAccess.map((u) => u.user.id) || []}
                            id='userAccess-userId'
                            label={languageStore.get('selectUser')}
                            fieldState={form.userId}
                            companyId={userStore.signedInUser!.company.id}
                        />
                    }

                    <SelectTrueFalse
                        id='userAccess-isInboxAdmin'
                        label={languageStore.get('administrator')}
                        fieldState={form.isInboxAdmin}
                        editMode={true}
                    />

                </ModalColumn>

            </DetailsPage>
        )
    }
}

export default InboxUsersTable;