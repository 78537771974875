interface Props {
    label: string,
    value: string | React.ReactNode
}

const InfoBlock = (props: Props) => {
    return (
        <>
            <dt className='mt-5 text-sm font-semibold'>{props.label}</dt>
            <dd>{props.value}</dd>
        </>
    )
}

export default InfoBlock;