import { BaseStore } from './BaseStore';
import { observable, action, makeObservable } from 'mobx';

export enum RoleEnum {
    HR_ADMIN = 1,
    HR_READ = 2,
    HR_WRITE = 3,
    PRIMARY_ADMIN = 4,
    PURCHASE_ADMIN = 5,
    PURCHASE_READ = 6,
    PURCHASE_WRITE = 7,
    SALES_ADMIN = 8,
    SALES_READ = 9,
    SALES_WRITE = 10,
    SERVICE_ADMIN = 11,
    SERVICE_READ = 12,
    SERVICE_WRITE = 13,
    SUPER_ADMIN = 14,
    INBOX = 15,
}

export interface RoleInterface {
    "id": string,
    "name": string,
    "role": RoleEnum
}


export class RolesStore extends BaseStore {
    @observable roles: Array<RoleInterface> = [];

    constructor() {
        super();

        makeObservable(this);
    }

    @action
    getRoles(companyId: string) {
        this.get(`companies/${companyId}/roles`, 'roles', true);
    }
}

export const rolesStore = new RolesStore();