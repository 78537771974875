import { BaseReportStore, ReportCompareTypeEnum, ReportCriteriaInterface } from './BaseReportStore';
import { action, makeObservable } from 'mobx';
import { ContractStatusHelper } from 'helpers/ContractStatusHelper';
import { formatDate } from 'helpers/DateHelper';
import { ContractTypeHelper } from 'helpers/ContractTypeHelper';
import { customFieldStore } from './CustomFieldStore';
import { DashboardContractTypeEnum } from './DashboardStore';


export class SalesReportStore extends BaseReportStore {

    constructor() {
        super();
        makeObservable(this);
        this.contractEndpoint = 'sales';
    }

    @action
    async setData() {
        const status = ContractStatusHelper.BasicContractStatus(true);
        const contractTypes = ContractTypeHelper.ContractTypes();

        const data: Array<ReportCriteriaInterface> = [
            {
                label: 'comaId',
                requestField: 'id',
                compareType: ReportCompareTypeEnum.TEXT,
                autoCompleteOnFocus: true
            },
            {
                label: 'dealNumber',
                requestField: 'salesNumber',
                compareType: ReportCompareTypeEnum.TEXT,
                autoCompleteOnFocus: true
            },
            {
                label: 'location',
                requestField: 'department.location.name',
                compareType: ReportCompareTypeEnum.TEXT,
                autoCompleteOnFocus: true
            },
            {
                label: 'contractType',
                requestField: 'contractType.name',
                customConverter: (d: any) => d.contractType ? ContractTypeHelper.ContractTypeName(contractTypes, d.contractType.name) : null,
                compareType: ReportCompareTypeEnum.DROPDOWN,
                dropdownOptions: contractTypes,
                tableVisibility: true,
                autoCompleteOnFocus: true
            },
            {
                label: 'category',
                requestField: 'category.name',
                compareType: ReportCompareTypeEnum.TEXT,
                tableVisibility: true,
                autoCompleteOnFocus: true
            },
            {
                label: 'subCategory',
                requestField: 'subCategory.name',
                compareType: ReportCompareTypeEnum.TEXT,
                tableVisibility: true,
                autoCompleteOnFocus: true
            },
            {
                label: 'department',
                requestField: 'department.name',
                compareType: ReportCompareTypeEnum.TEXT,
                autoCompleteOnFocus: true
            },
            {
                label: 'currency',
                requestField: 'currency.code',
                compareType: ReportCompareTypeEnum.TEXT
            },
            {
                label: 'specialCircumstances',
                requestField: 'specialCircumstancesDiscription',
                compareType: ReportCompareTypeEnum.TEXT
            },
            {
                label: 'contractSum',
                requestField: 'contractSum',
                compareType: ReportCompareTypeEnum.NUMBER,
                customConverter: (d: any) => Number(d.contractSum).toLocaleString()
            },
            {
                label: 'status',
                requestField: 'contractStatus',
                customConverter: (d: any) => ContractStatusHelper.ContractName(status, d.contractStatus),
                compareType: ReportCompareTypeEnum.DROPDOWN,
                dropdownOptions: status
            },
            {
                label: 'services',
                requestField: 'financialServices.service.name',
                compareType: ReportCompareTypeEnum.TEXT,
                customConverter: (d: any) => d.financialServices.map((fs: any) => fs.service?.name || '').join(', '),
                autoCompleteOnFocus: true
            },
            {
                label: 'creationDate',
                requestField: 'contractDateCreated',
                compareType: ReportCompareTypeEnum.DATE,
                customConverter: (d: any) => formatDate(d.contractDateCreated)
            },
            {
                label: 'renewalDate',
                requestField: 'renewalDate',
                compareType: ReportCompareTypeEnum.DATE,
                customConverter: (d: any) => formatDate(d.renewalDate)
            },
            {
                label: 'expirationDate',
                requestField: 'expirationDate',
                compareType: ReportCompareTypeEnum.DATE,
                customConverter: (d: any) => formatDate(d.expirationDate)
            },
            {   
                label: 'customer',
                requestField: 'customer.name',
                compareType: ReportCompareTypeEnum.TEXT,
                tableVisibility: true,
                autoCompleteOnFocus: true
            },
            {   
                label: 'vatNumber',
                requestField: 'customer.vAT',
                compareType: ReportCompareTypeEnum.TEXT,
            },
            {
                label: 'responsible',
                requestField: 'responsibleUser.fullName',
                compareType: ReportCompareTypeEnum.TEXT,
                tableVisibility: true,
                autoCompleteOnFocus: true
            },
            {
                label: 'notes',
                requestField: 'notes.title',
                compareType: ReportCompareTypeEnum.TEXT
            },
            {
                label: 'description',
                requestField: 'description',
                compareType: ReportCompareTypeEnum.TEXT
            },
            {
                label: 'documentType',
                requestField: 'contractDocuments.documentType.name',
                compareType: ReportCompareTypeEnum.TEXT,
                customConverter: (d: any) => d.contractDocuments ? d.contractDocuments?.map((cd: any) => cd.documentType?.name || '').filter((x: any) => x).join(', ') : '',
                autoCompleteOnFocus: true
            },
        ]

        const customFields = this.getCustomFieldsAsReportCriteriaInterfaces(await customFieldStore.getCustomFields(DashboardContractTypeEnum.SALES));

        this.data = [...data, ...customFields];
    }

}

export const salesReportStore = new SalesReportStore();