import TableHeader from 'components/Table/TableHeader';
import { languageStore, ContractStatusEnum, userStore } from 'stores';
import { formatDate } from 'helpers/DateHelper';
import Moment from 'moment-timezone';

export function getSalesColumns() {
    return [
        {
            Header: <TableHeader value={languageStore.get('daysToExpiration')} />,
            id: 'daysToExpiration',
            accessor: (d: any) => {
                const expirationDate = d.expirationDate;
                if (!expirationDate) return null;
                let timeZone = userStore.signedInUser!.timeZone.id;
                const days = Moment(expirationDate).utc().tz(timeZone).diff(Moment().utc().tz(timeZone), 'days') + 1;
                if (days < 1) {
                    return <span className='text-red'>{days}</span>
                }
                else return days;
            }            
        },
        {
            id: 'salesNumber',
            Header: <TableHeader value={languageStore.get('dealNumber')} />,
            accessor: 'salesNumber'
        },
        {
            id: 'categoryName',
            Header: <TableHeader value={languageStore.get('category')} />,
            accessor: 'categoryName'
        },
        {
            id: 'subCategoryName',
            Header: <TableHeader value={languageStore.get('subCategory')} />,
            accessor: 'subCategoryName'
        },
        {
            id: 'customerName',
            Header: <TableHeader value={languageStore.get('customer')} />,
            accessor: 'customerName'
        },
        {
            id: 'description',
            Header: <TableHeader value={languageStore.get('description')} />,
            accessor: 'description'
        },
        {
            id: 'renewalDate',
            Header: <TableHeader value={languageStore.get('renewalDate')} />,
            accessor: (d: any) => formatDate(d.renewalDate)
        },
        {
            id: 'departmentName',
            Header: <TableHeader value={languageStore.get('department')} />,
            accessor: 'departmentName'
        },
        {
            id: 'expirationDate',
            Header: <TableHeader value={languageStore.get('expirationDate')} />,
            accessor: (d: any) => formatDate(d.expirationDate)
        },
        {
            id: 'status',
            Header: <TableHeader value={languageStore.get('status')} />,
            accessor: (d: any) => {
                switch (d.status) {
                    case ContractStatusEnum.AcceptedInOperation: return languageStore.get('acceptedInOperation');
                    case ContractStatusEnum.Deleted: return languageStore.get('deleted');
                    case ContractStatusEnum.Discontinued: return languageStore.get('discontinued');
                    case ContractStatusEnum.Draft: return languageStore.get('draft');
                    case ContractStatusEnum.Offer: return languageStore.get('offer');
                    case ContractStatusEnum.Renetiation: return languageStore.get('renetiation');
                    case ContractStatusEnum.Terminated: return languageStore.get('terminated');
                    default: return null;
                }
            }
        },
        {
            id: 'locationName',
            Header: <TableHeader value={languageStore.get('location')} />,
            accessor: 'locationName'
        }
    ]
}