import * as React from 'react';
import { observer } from 'mobx-react';
import { CompanyInterface, languageStore, navigationStore, superAdminStore } from 'stores';
import { Modal, ModalColumn } from 'components/Modal';
import { FormState, FieldState } from 'formstate';
import { required } from 'helpers/FormValidation';
import Form from 'components/Forms/Form';
import Input from 'components/Forms/Input';

interface Props {
    company: CompanyInterface
}

interface State {
    showModal: boolean
}


@observer class DeleteCompanyModal extends React.Component<Props, State> {

    deleteKey = 'I-WANT-TO-DELETE-COMPANY';

    form = new FormState({
        confirmKey: new FieldState('').validators(required)
    })

    constructor(props: Props) {
        super(props);

        this.state = {
            showModal: false
        }
    }

    showModal = () => {
        this.setState({ showModal: true })
    }

    hideModal = () => {
        this.setState({ showModal: false })
    }

    onSubmit = async () => {
        const { company } = this.props;
        const inputtedKey = this.form.$.confirmKey.$;

        if (inputtedKey !== this.deleteKey) {
            alert('Wrong key.');
            return;
        }

        if (window.confirm('Are you absolutely sure you want to delete this company? This cannot be undone')) {
            await superAdminStore.deleteCompany(company.id);
            navigationStore.push(`/superadmin/companies`);
        }
    }

    render() {
        const { company } = this.props;
        const { showModal } = this.state;

        return (
            <span>
                <button
                    onClick={this.showModal}
                    className='ml-6 bg-red-dark text-white mt-4 px-4 py-2 rounded'>
                    {languageStore.get('deleteCompany')}
                </button>

                {
                    showModal &&
                    <Form formState={this.form} onValidSubmit={this.onSubmit}>
                        <Modal
                            title={`${languageStore.get('delete')} ${company.name}`}
                            primaryButton={{ title: languageStore.get('delete') }}
                            secondaryButton={{ title: languageStore.get('cancel'), onClick: this.hideModal }}>

                            <ModalColumn>

                                <p className='mb-4'>
                                    PLEASE NOTE: Users, contracts and everything regarding this company will be completely deleted from the system.
                                </p>

                                <p>
                                    {`Type ${this.deleteKey} in the box below to delete company.`}
                                </p>

                                <div className='mt-12' />
                                <Input 
                                    fieldState={this.form.$.confirmKey} 
                                    label='Enter key' 
                                    id='confirmKey'
                                />
    
                        </ModalColumn>
                        </Modal>
                    </Form>
                }

            </span>

        )


    }

}

export default DeleteCompanyModal;