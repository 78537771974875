import * as React from 'react';
import { observer } from 'mobx-react';
import { Modal } from 'components/Modal';
import { CurrentContractStore, CurrentContractBaseInterface, languageStore, navigationStore } from 'stores';

interface Props {
    store: CurrentContractStore<CurrentContractBaseInterface>
}

interface State {
    isShowModal: boolean
}

@observer class CancelDraftButton extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);

        this.state = {
            isShowModal: false
        }
    }

    hideModal = () => {
        this.setState({ isShowModal: false })
    }

    showModal = () => {
        this.setState({ isShowModal: true })
    }

    gotoOverview = (e: React.MouseEvent) => {
        e.preventDefault();

        const { store } = this.props;
        store.setIsDraftJustCreated(false);

        navigationStore.push(`/${store.contractEndpoint}`);;
    }

    deleteDraft = async (e: React.MouseEvent) => {
        e.preventDefault();

        const { store } = this.props;

        if (window.confirm(languageStore.get('areYouSureYouWantToDeleteThisDraft') + '?')) {
            await store.deleteContract();
            this.gotoOverview(e);
        }
    }

    render() {
        const { store } = this.props;
        const { isShowModal } = this.state;
        if (!store.isDraft || !store.canEdit) return null;

        return (
            <>
                <button onClick={this.showModal} className='text-black py-2 px-4 rounded border mr-2'>
                    {languageStore.get('cancel')}
                </button>

                {
                    isShowModal &&
                    <span className='text-left'>
                        <Modal
                            centerButtons={true}
                            title={`${languageStore.get(languageStore.get('doYouWantToKeepThisDraft'))}?`}
                            secondaryButton={{ title: languageStore.get('cancel'), onClick: this.hideModal }}>

                            <div className='mx-auto'>
                                <a
                                    href=' '
                                    onClick={(e => this.gotoOverview(e))}
                                    className={`
                                                    block 
                                                    w-64 
                                                    rounded 
                                                    px-4 
                                                    py-2 
                                                    mb-4 
                                                    text-white
                                                    text-center
                                                    bg-green
                                                    hover:bg-green-dark
                                                    cursor-pointer`}>
                                    {languageStore.get('keepDraft')}
                                </a>
                                <a
                                    href=' '
                                    onClick={(e => this.deleteDraft(e))}
                                    className={`
                                                    block 
                                                    w-64 
                                                    rounded 
                                                    px-4 
                                                    py-2 
                                                    mb-4
                                                    text-white
                                                    bg-red-light
                                                    text-center
                                                    hover:bg-red
                                                    cursor-pointer`}>
                                    {languageStore.get('delete')}
                                </a>
                            </div>

                        </Modal>
                    </span>
                }
            </>
        )
    }

}

export default CancelDraftButton;