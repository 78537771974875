import * as React from 'react';
import { observer } from 'mobx-react';
import Breadcrumb from 'components/Breadcrumb';
import { languageStore } from 'stores';
import { FormState } from 'formstate';
import Form from 'components/Forms/Form';


interface Props {
    form: FormState<any>,
    onSubmit: Function,
    children?: React.ReactNode,
    title: string,
    breadCrumbTitle?: string,
    backlinkTitle?: string,
    // backlinkTo?: string,
    backLinkFunc: () => void
}

@observer class StepContainer extends React.Component<Props> {

    render() {
        const { breadCrumbTitle, backlinkTitle, backLinkFunc, form, onSubmit, children, title } = this.props;

        return (
            <div>
                <Breadcrumb
                    backlinkFunc={backLinkFunc}
                    backlinkTitle={backlinkTitle}
                    title={breadCrumbTitle || languageStore.get('addUser')} />

                <Form onValidSubmit={onSubmit} formState={form}>
                    <div className='w-1/2 m-auto' style={{ maxWidth: '900px' }}>

                        <h1 className='mt-5 m-4'>
                            {title}
                        </h1>
                        <div className='mt-10 flex'>
                            {children}
                        </div>

                        <div className='text-right'>
                            <button
                                className='bg-comablue-label text-white py-2 px-8 m-4 rounded'
                                type='submit'>
                                {languageStore.get('saveAndNext')}
                        </button>
                        </div>

                    </div>

                </Form>

            </div>
        )
    }

}

export default StepContainer;