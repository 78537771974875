import * as React from 'react';
import { observer } from 'mobx-react';
import { languageStore, CompanyInterface, financeAccountStore, toastStore, locationStore } from 'stores';
import UploadFile from 'components/Forms/UploadFile';
import { Modal, ModalColumn } from 'components/Modal';

interface Props {
    company: CompanyInterface
}

interface State {
    showModal: boolean
}


@observer class UploadFinanceAccounts extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            showModal: false
        }
    }

    showModal = () => {
        this.setState({ showModal: true })
    }

    hideModal = () => {
        this.setState({ showModal: false })
    }

    uploadFile = async (file: File) => {
        const { company } = this.props;
        let formData = new FormData();
        formData.append('file', file);

        try {
            if (locationStore.locations.length === 0) {
                await locationStore.getLocationsByCompany(company.id);
            }

            if (locationStore.locations.length === 0) {
                toastStore.addError(languageStore.get('noLocationsCreated'));
                this.hideModal();
                return;
            }

            const ids: any = [];

            locationStore.locations.forEach((loc) => {
                ids.push({
                    id: loc.id
                })
            });

            formData.append('selectedLocations', JSON.stringify(ids));

            await financeAccountStore.uploadFinanceAccounts(company.id, formData);
            toastStore.addToast({
                level: 'success',
                title: languageStore.get('success'),
                message: languageStore.get('financeAccountsCreated')
            })
        }
        catch (error) {
        }

        this.hideModal();

    }

    render() {
        const { showModal } = this.state;

        return (
            <span>
                <button
                    onClick={this.showModal}
                    className='ml-2 hover:bg-grey-lightest mt-4 px-4 py-2 border rounded'>
                    {languageStore.get('addFinanceAccounts')}
                </button>

                {
                    showModal &&
                    <Modal
                        title={`${languageStore.get('upload')} CSV`}
                        secondaryButton={{ title: languageStore.get('cancel'), onClick: this.hideModal }}>

                        <ModalColumn>

                            <UploadFile 
                                onSubmit={this.uploadFile} 
                                customUpload={true}
                                allowedFileTypes={['csv']}>

                                <p className='font-bold'>
                                    File structure:
                                </p>
                                <p className='mb-4'>
                                    accountNo,accountName,accountNo,accountName (etc)
                                </p>

                                <button
                                    onClick={(e) => e.preventDefault()}
                                    className='hover:bg-grey-lightest mt-4 px-4 py-2 border rounded'>
                                    {languageStore.get('selectFile')}
                                </button>
                            </UploadFile>

                        </ModalColumn>
                    </Modal>
                }

            </span>

        )


    }

}

export default UploadFinanceAccounts;