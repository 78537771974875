enum SectionEnum {
    OVERVIEW = '#overview',
    CUSTOMER = '#customer',
    NOTES = '#notes',
    FINANCE_ACCOUNT = '#finance_account',
    SERVICES = '#services',
    CONTRACT_SUM = '#contract_sum',
    EXTRA_ALARMS = '#extra_alarms',
    VERIFIED_BY = '#verifiedby',
    FILES_CONTRACT = '#files_contract',
    FILES_CORRESPONDENCE = '#files_correspondence',
    STATUS = '#status',
    LINKED_CONTRACTS = '#linked_contracts',
    CUSTOM_FIELDS = '#custom_fields'
}

export default SectionEnum;