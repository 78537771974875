import { BaseStore } from './BaseStore';
import { observable, action, makeObservable } from 'mobx';
import request from 'api/request';
import { PagingResult } from './BaseContractsStore';

export interface ActivityInterface {
    "id": string,
    "ip": string,
    "operationTime": string,
    "parameters": string,
    "activityDetails": string,
    "activityType": string,
    "businessEntity": string,
    "statusCode": number,
    "userId": string
}

export class ActivityStore extends BaseStore {
    @observable activities: PagingResult<ActivityInterface> | null = null;

    constructor() {
        super();
        makeObservable(this);
    }

    // @observable entities: Array<string> = [];
    // @observable types: Array<String> = [];

    @action
    getActivities(userId: string, pagenumber: number, pagesize: number): Promise<void> {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await request.get(`activities?userId=${userId}&pagenumber=${pagenumber}&pagesize=${pagesize}`);
                if (pagenumber === 1) {
                    this.activities = res.data;
                }
                else {
                    const data = this.activities!.results;
                    const newData = data.concat(res.data.results)
                    this.activities = res.data;
                    this.activities!.results = newData;
                }

                resolve();
            }
            catch(error) {
                reject(error);
            }
        })
    }

    // @action
    // getEntities() {
    //     return this.get('activities/entities', 'entities');
    // }

    // @action
    // getTypes() {
    //     return this.get('activities/types', 'types');
    // }

    @action
    resetStore() {
        this.activities = null;
        // this.entities = [];
        // this.types = [];
    }
}

export const activityStore = new ActivityStore();