import * as React from 'react';
import { observer } from 'mobx-react';
import { languageStore, CurrentContractStore, CurrentContractBaseInterface, templateStore, DocumentTypeContractTypeEnum, TemplateInterface, ContractDocumentInterface, documentStore, DocumentUrlInterface } from 'stores';
import { Modal, ModalColumn } from 'components/Modal';
import * as _ from 'lodash';
import InboxButton from 'components/InboxPage/InboxButton';
import { isFormStateDirty } from 'helpers/FormHelper';

interface Props {
    store: CurrentContractStore<CurrentContractBaseInterface>,
    contractType: DocumentTypeContractTypeEnum,
}

interface State {
    showModal: boolean,
    document: ContractDocumentInterface | null,
    documentUrl: string | null
}

@observer class CreateFileByTemplateButton extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            showModal: false,
            document: null,
            documentUrl: null
        }
    }

    componentDidMount() {
        const { contractType } = this.props;
        templateStore.getTemplates(contractType as any);
    }

    showModal = () => {
        const { store } = this.props;


        if (store.isDraft) {
            const anyDirtyForms = _.some(store.submitForms, (f) => isFormStateDirty(f.$));
            if (anyDirtyForms) {
                alert(languageStore.get('saveUnsavedChangesBeforeUsingThisFunction'));
                return;
            }
        }

        this.setState({ showModal: true });
    }

    hideModal = () => {
        this.setState({
            showModal: false,
            document: null,
            documentUrl: null
        });
    }

    onCreateFile = async (template: TemplateInterface) => {
        const { store } = this.props;
        const contractId = store.contract!.id;

        const fileName = window.prompt(languageStore.get('filename'), template.name);

        const document = await templateStore.createDocumentFromTemplate(template.id, contractId, fileName || template.name);
        const docUrl = await documentStore.getDocumentUrl(document.minioName) as DocumentUrlInterface;

        this.setState({
            document: document,
            documentUrl: docUrl.url
        });
    }

    onDeleteGeneratedDocument = async (e: React.MouseEvent) => {
        const { document } = this.state;
        e.preventDefault();

        if (document) {
            await documentStore.deleteDocument(document.id);
        }

        this.hideModal();
    }

    onLinkDocumentToContract = async (e: React.MouseEvent) => {
        e.preventDefault();

        const { store } = this.props;
        const { document } = this.state;

        if (document) {
            await store.addDocumentToContract(document, 'documents', store.contract!.contractDocuments)
        }

        this.hideModal();

    }

    openDocument = (e: React.MouseEvent) => {
        e.preventDefault();
        const { documentUrl } = this.state;
        if (documentUrl) {
            window.open(documentUrl);
        }
    }

    render() {
        const { store } = this.props;
        const { showModal, document, documentUrl } = this.state;

        const currentDepartmentId = store.currentDepartmentId;
        const templates = _.filter(templateStore.templates, (t) => {
            return _.some(t.departments, { id: currentDepartmentId! });
        });

        return (
            <>
                <button
                    onClick={this.showModal}
                    className='bg-comablue-label text-white py-2 px-8 rounded'>
                    {languageStore.get('createFromTemplate')}
                </button>

                {
                    showModal &&
                    <Modal
                        centerButtons={true}
                        title={languageStore.get('templates')}
                        secondaryButton={!document ? { title: languageStore.get('cancel'), onClick: this.hideModal } : undefined}>

                        {
                            !document &&
                            <ModalColumn>
                                {
                                    templates.length === 0 &&
                                    <p>
                                        {languageStore.get('thereAreNoTemplatesForThisDepartment')}
                                    </p>
                                }

                                {
                                    templates.map((template, index) => {
                                        return (
                                            <button
                                                onClick={(e => this.onCreateFile(template))}
                                                key={index}
                                                className={`
                                                    block 
                                                    w-64 
                                                    border 
                                                    rounded 
                                                    px-4 
                                                    py-2 
                                                    mb-4 
                                                    text-center
                                                    hover:bg-grey-lightest
                                                    mx-auto
                                                    cursor-pointer`}>
                                                {template.name}
                                            </button>
                                        )
                                    })
                                }


                            </ModalColumn>
                        }

                        {
                            document &&
                            <>
                                <ModalColumn>
                                    <div className='mx-auto'>
                                        <InboxButton title={languageStore.get('addToContract')} onClick={(e: React.MouseEvent) => this.onLinkDocumentToContract(e)} />
                                        <InboxButton title={languageStore.get('cancel')} onClick={(e: React.MouseEvent) => this.onDeleteGeneratedDocument(e)} />
                                    </div>
                                </ModalColumn>

                                <ModalColumn>
                                    <iframe className='rounded border' height={480} src={documentUrl!} allowFullScreen title='createfilewindow' />
                                    <InboxButton title={languageStore.get('openFile')} onClick={(e: React.MouseEvent) => this.openDocument(e)} className='mt-4 mx-auto' />
                                </ModalColumn>
                            </>
                        }

                    </Modal>
                }

            </>



        )
    }

}

export default CreateFileByTemplateButton;