import { observable, action, makeObservable } from 'mobx';
import request from 'api/request';
import { BaseStore } from './BaseStore';
import { PagingResult } from './BaseContractsStore';

export enum NotificationFieldEnum {
    ALARM = 0,
    RENEWAL = 1,
    EXPIRATION = 2,
    STAFF_BIRTHDAY = 3,
    STAFF_SENIORITY = 4,
    STAFF_RENEGOTIATION = 5,
    SERVICE_SERVICEDATE = 6
}

export enum ContractTypeEnum {
    PURCHASE_CONTRACT = "PurchaseContract",
    STAFF_CONTRACT = "StaffContract",
    SALES_CONTRACT = "SalesContract",
    SERVICE_CONTRACT = "ServiceContract"
}

export interface NotificationInterface {
    "type": number
    "entityType": ContractTypeEnum,
    "entityId": string
    "fieldType": string,
    "alarmType": NotificationFieldEnum
    "fieldId": string
    "noticeDays": number
    "createdOn": string,
    "seenDate": string,
    "userId": string,
    "note": string,
    "emailBody": string,
    "user": {
        "id": string
        "fullName": string
    },
    "id": string
}

export class NotificationStore extends BaseStore {
    @observable notifications: PagingResult<NotificationInterface> | null = null;

    constructor() {
        super();

        makeObservable(this);
    }

    @action
    getNotifications(pageNumber: number, pageSize: number) {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await request.get(`notifications?pageNumber=${pageNumber}&pageSize=${pageSize}`);
                const existingResults = this.notifications ? this.notifications.results : [];

                res.data.results = [...existingResults, ...res.data.results];
                this.notifications = res.data;

                resolve(res.data);
            }
            catch (error) {
                reject(error);
            }
        })
    }

    @action
    updateNotification(notification: NotificationInterface) {
        return this.update(`notifications/${notification.id}`, notification, this.notifications!.results, undefined, {
            headers: {
                _byPassTransactionKey: 'true'
            }
        });
    }

    @action
    resetStore() {
        this.notifications = null;
    }
}

export const notificationStore = new NotificationStore();