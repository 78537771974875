import { userStore, RoleEnum } from 'stores';
import * as _ from 'lodash';

export function hasRole(role: RoleEnum) {
    if (!userStore.signedInUser) return false;

    return !!_.find(userStore.signedInUser.roles, { role: Number(role) });
}

export function hasLocationAndDepartmentAdminRole() {
    const company = userStore.signedInUser.company;
    return hasRole(RoleEnum.SUPER_ADMIN) || (hasRole(RoleEnum.PRIMARY_ADMIN) && company.hasBillingPlan);
}