import { observable, action, makeObservable } from 'mobx';
import { BaseStore, BaseContractTypeEnum } from './BaseStore';

export interface ContractTypeInterface {
    "name": string,
    "eType": number,
    "locationId": string,
    "id": string
}

export class ContractTypeStore extends BaseStore {
    @observable contractTypes: Array<ContractTypeInterface> = [];

    constructor() {
        super();

        makeObservable(this);
    }

    @action
    getContractTypes(contractType: BaseContractTypeEnum, locationId: string) {
        this.get(`contracts/${contractType}/types?locationId=${locationId}`, 'contractTypes');
    }

    @action
    resetStore() {
        this.contractTypes = [];
    }
}

export const contractTypeStore = new ContractTypeStore();