import * as React from 'react'
import { observer } from 'mobx-react';
import { uploadFile } from 'helpers/FileHelper';
import { toastStore } from 'stores';

interface Props {
    onSubmit: Function,
    active?: boolean
}

interface State {
    drag: boolean
}

@observer class DragAndDrop extends React.Component<Props, State> {

    dragCounter: number = 0;
    dropRef: React.RefObject<any>

    constructor(props: Props) {
        super(props);

        this.state = {
            drag: false
        }

        this.dropRef = React.createRef();
    }

    handleDrag = (e: DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
    }

    handleDragIn = (e: DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
        const { active } = this.props;

        if (active !== undefined && active === false) {
            return;
        }

        this.dragCounter++;
        if (e.dataTransfer && e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            this.setState({ drag: true });
        }
    }

    handleDragOut = (e: DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
        this.dragCounter--;
        if (this.dragCounter === 0) {
            this.setState({ drag: false });
        }
    }

    handleDrop = async (e: DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({ drag: false });
        if (e.dataTransfer && e.dataTransfer.files && e.dataTransfer.files.length > 0) {

            // e.dataTransfer.files is a FileList, which is not an array
            // even though they have common similarities.
            // We cast to array to be able to iterate, and use for of, to await each upload
            for (let file of Array.from(e.dataTransfer.files)) {
                try {
                    const doc = await uploadFile(file);
                    await this.props.onSubmit(doc);
                }
                catch (error) {
                    toastStore.addError(error);
                }
            }

            e.dataTransfer.clearData();
            this.dragCounter = 0;

        }
    }

    componentDidMount() {
        let div = this.dropRef.current as HTMLElement;
        div.addEventListener('dragenter', this.handleDragIn, { capture: true });
        div.addEventListener('dragleave', this.handleDragOut, { capture: true });
        div.addEventListener('dragover', this.handleDrag, { capture: true });
        div.addEventListener('drop', this.handleDrop, { capture: true });
    }

    componentWillUnmount() {
        let div = this.dropRef.current as HTMLElement;
        div.removeEventListener('dragenter', this.handleDragIn);
        div.removeEventListener('dragleave', this.handleDragOut);
        div.removeEventListener('dragover', this.handleDrag);
        div.removeEventListener('drop', this.handleDrop);
    }

    render() {
        return (
            <div
                ref={this.dropRef}
            >
                {this.state.drag &&
                    <div
                        style={{
                            border: 'dashed grey 4px',
                            backgroundColor: 'rgba(255,255,255,.8)',
                            position: 'absolute',
                            top: 0,
                            bottom: 0,
                            left: 0,
                            right: 0,
                            zIndex: 9999
                        }}
                    >
                        <div
                            style={{
                                position: 'absolute',
                                top: '50%',
                                right: 0,
                                left: 0,
                                textAlign: 'center',
                                color: 'grey',
                                fontSize: 36
                            }}
                        >

                        </div>
                    </div>
                }
                {this.props.children}
            </div>
        )
    }
}
export default DragAndDrop