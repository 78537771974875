import * as React from 'react';
import { observer } from 'mobx-react';
import { FieldState } from 'formstate';
import ContractDropdownItem from 'components/ContractDropdownItem';
import {
    languageStore as lanStore,
    supplierStore
} from 'stores';
import { DropdownOptionInterface } from 'components/Forms/Dropdown';

interface Props {
    fieldState: FieldState<any>,
    fallbackValue?: string,
    editMode?: boolean,
    placeholder?: string,
    skipIds?: Array<string>
}

@observer class SelectedSupplier extends React.Component<Props> {

    componentDidMount() {
        supplierStore.getSuppliers();
    }

    render() {
        const { fieldState, fallbackValue, editMode, placeholder, skipIds } = this.props;

        let availSuppliers: Array<DropdownOptionInterface> = [];
        supplierStore.suppliers && supplierStore.suppliers.forEach((supplier) => {
            if (!skipIds?.includes(supplier.id!)) {
                availSuppliers.push({
                    value: supplier.id,
                    name: supplier.name
                })
            }
        })

        return <ContractDropdownItem
            label={lanStore.get('selectSupplier')}
            id='supplier'
            placeholderText={placeholder || `--- ${lanStore.get('newSupplier')} ---`}
            fieldState={fieldState}
            options={availSuppliers}
            editMode={editMode}
            fallbackValue={fallbackValue}
        />
    }

}

export default SelectedSupplier;