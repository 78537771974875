import * as React from 'react';
import { observer } from 'mobx-react';
import { ADFSHostEnum } from 'stores';
import ADFS from '../ADFS';

interface State {
    signInError: boolean
}

@observer class SiccadaniaLoginPage extends React.Component<any, State> {

    resource = process.env.REACT_APP_FRONTEND_BASEURL!;
    redirectUri = process.env.REACT_APP_FRONTEND_BASEURL + '/login/sicca';
    endpoint = process.env.REACT_APP_ADFS_SICCADANIA_ENDPOINT;
    clientId = process.env.REACT_APP_ADFS_SICCADANIA_CLIENT_ID;
    providerId = process.env.REACT_APP_ADFS_SICCADANIA_PROVIDER_ID!;

    render() {
        return (
            <ADFS
                loginEndpoint={`${this.endpoint}/adfs/oauth2/authorize?response_type=code&client_id=${this.clientId}&resource=${encodeURIComponent(this.resource)}&redirect_uri=${encodeURIComponent(this.redirectUri)}`}
                adfsHost={ADFSHostEnum.SICCADANIA}
                redirectUri={this.redirectUri}
                providerId={this.providerId}
            />
        )
    }
}

export default SiccadaniaLoginPage;