import * as React from 'react';
import { observer } from 'mobx-react';
import { languageStore, ContractDocumentInterface, documentStore, DocumentUrlInterface, documentTypeStore, userStore, DocumentTypeInterface, DocumentTypeContractTypeEnum, CurrentContractStore, CurrentContractBaseInterface } from 'stores';
import TableGreenHeader from 'components/Table/TableGreenHeader';
import * as _ from 'lodash';
import UploadFile from 'components/Forms/UploadFile';
import ContractSection from 'components/ContractSection';
import ContractDetailsGroup from 'components/ContractDetailsGroup';
import ReactTable from 'react-table';
import { DropdownOptionInterface } from 'components/Forms/Dropdown';
import { formatDate } from 'helpers/DateHelper';
import DragAndDrop from 'components/DragAndDrop';
import CreateFileByTemplateButton from 'components/CreateFileByTemplateButton';
import { addoSigningStatusStore } from 'stores/AddoSigningStatusStore';
import AddoSigningStatusModal from 'components/AddoSigningStatusModal';
import { toJS } from 'mobx';
import SigningStatus from './SigningStatus';
import FileActions from './FileActions';
import SignDocument from 'components/SignDocument';
import FileIcon from 'components/FileIcon';

interface Props {
    title: string,
    hash: string,
    files: Array<ContractDocumentInterface>,
    onAddFileToContract: Function,
    onDeleteFileFromContract: Function,
    hasDocumentTypes?: boolean,
    onUpdateFile?: Function,
    contractType?: DocumentTypeContractTypeEnum,
    store: CurrentContractStore<CurrentContractBaseInterface>,
    allowAddo?: boolean,
    allowTemplates?: boolean,
    editDescription?: React.ReactNode
}

@observer class Files extends React.Component<Props> {



    toggleEditMode = () => {
        const { hash, store } = this.props;
        // const hash = SectionEnum.FILES_CONTRACT;
        const editMode = store.isEditMode(hash);
        store.setEditMode(hash, !editMode)
    }

    openFileById = async (e: React.MouseEvent, id: string) => {
        e.stopPropagation();

        const { files } = this.props;
        const file = _.find(files, { id: id }) as ContractDocumentInterface;
        const endpoint = await documentStore.getDocumentUrl(file.minioName) as DocumentUrlInterface;

        window.open(endpoint.url);
    }

    uploadFile = async (document: ContractDocumentInterface) => {
        const { onAddFileToContract } = this.props;
        await onAddFileToContract(document);
    }

    updateFileName = (file: ContractDocumentInterface) => {
        const newName = prompt(languageStore.get('filename'), file.fileName);
        if (!newName || newName === file.fileName) return;

        const { store } = this.props;
        file.fileName = newName;
        store.updateDocument(file, this.props.files);
    }

    onToggleSignedLock = (contractId: string, value: boolean) => {
        const { files, store } = this.props;
        const file = _.find(files, { id: contractId }) as ContractDocumentInterface;

        file.signedLock = value;
        store.updateDocument(file, this.props.files);
    }

    onDelete = async (e: React.MouseEvent, id: string) => {
        e.stopPropagation();

        if (window.confirm(languageStore.get('areYouSureYouWantToArchiveDocument') + '?')) {
            const { files, onDeleteFileFromContract } = this.props;
            const document = _.find(files, { id: id }) as ContractDocumentInterface;
            // await store.deleteDocument(document);
            await onDeleteFileFromContract(document);
        }
    }

    onDocumentTypeChanged = async (fileId: string, documentTypeId: string | null) => {
        const { files, onUpdateFile, store } = this.props;
        const file = _.find(files, { id: fileId }) as ContractDocumentInterface;

        if (documentTypeId === '-') {
            file.documentTypeId = null;
        }
        else {
            file.documentTypeId = documentTypeId;
        }

        await onUpdateFile!(file);

        // This is a hack, to force rerender of the entire component,
        // from top of the component tree.
        store.setEditMode(this.props.hash, false);
        store.setEditMode(this.props.hash, true);
    }

    componentDidMount() {
        const { hasDocumentTypes } = this.props;

        if (hasDocumentTypes) {
            documentTypeStore.getDocumentTypes(userStore.signedInUser.company.id);
        }

    }

    cancelSigning = async (e: React.MouseEvent, signingId: string) => {
        e.preventDefault();
        e.stopPropagation();

        if (window.confirm(`${languageStore.get('areYouSureYouWantToCancelThisSigning')}?`)) {
            const { store } = this.props;
            store.cancelDocumentSigning(signingId).then(() => {
                window.location.reload();
            })
        }

    }

    getSigningStatus = async (e: React.MouseEvent, signingId: string) => {
        e.preventDefault();
        e.stopPropagation();

        const { store } = this.props;
        addoSigningStatusStore.getContractSigning(store.contractEndpoint, store.contract!.id, signingId);
        addoSigningStatusStore.getContractSigningStatus(store.contractEndpoint, store.contract!.id, signingId);
    }

    private expandSubcomponentOnRowClicked(state: any, rowInfo: any | undefined, col: any, instance: any): object {
        return {
            onClick: async (e: React.MouseEvent) => {
                const { expanded } = state;
                const path = rowInfo.nestingPath[0];
                const diff = { [path]: expanded[path] ? false : true };

                instance.setState({
                    expanded: {
                        ...expanded,
                        ...diff
                    }
                });

            }
        }
    }

    /**
     * Tree structure. Files with originalDocumentId are signed documents
     * and should by default be top of tree. Rest of the files should be children of those
     * entries, og added to root if no matches
     */
    mapFileTree = (files: Array<ContractDocumentInterface>) => {
        const formattedData: Array<ContractDocumentInterface> = [];

        files.forEach((entry: any) => {

            if (entry.originalDocumentId) {
                const originalFile = _.find(files, (f) => f.id === entry.originalDocumentId);
                if (originalFile) {
                    if (!entry.children) {
                        entry.children = [];
                    }
                    entry.children.push(originalFile);
                }
            }

            formattedData.push(entry);
        })

        return formattedData;
    }

    buildColumns = (
        store: CurrentContractStore<CurrentContractBaseInterface>,
        editMode?: boolean,
        hasDocumentTypes?: boolean,
        contractType?: DocumentTypeContractTypeEnum,
        allowAddo?: boolean
    ) => {
        const documentTypes = documentTypeStore.documentTypes;

        const columns = [
            {
                expander: true,
                width: 20,
                Expander: ({ isExpanded, ...rest }: any) => {
                    if (rest.original.children?.length > 0) {
                        return (
                            <div>{isExpanded ? <span>-</span> : <span>+</span>}</div>
                        );
                    }
                    return <span />;
                }
            },
            {
                Header: <TableGreenHeader value={languageStore.get('filename')} />,
                accessor: 'fileName',
                Cell: (props: any) => {
                    const file = props.original;
                    if (editMode) {
                        return (
                            <span
                                className='underline cursor-pointer'
                                onClick={() => this.updateFileName(file)}
                            >{file.fileName}
                            </span>
                        )
                    }
                    else {
                        return <span><FileIcon extension={file.extension} /> <span className='ml-1'>{file.fileName}</span></span>;
                    }
                }
            },
            {
                Header: <TableGreenHeader value={languageStore.get('date')} />,
                id: 'createdOn',
                accessor: (d: any) => {
                    return formatDate(d.createdOn);
                }
            }
        ];

        if (hasDocumentTypes) {
            if (editMode) {
                columns.push(
                    {
                        Header: <TableGreenHeader value={languageStore.get('documentType')} />,
                        id: 'documentTypeId',
                        accessor: 'documentTypeId',
                        Cell: (props: any) => {

                            let opts = [
                                {
                                    value: null,
                                    name: '-'
                                } as DropdownOptionInterface
                            ]

                            documentTypes.forEach((docType) => {
                                if (docType.contractType === contractType)
                                    opts.push({
                                        value: docType.id,
                                        name: docType.name
                                    })
                            })

                            return (
                                <select className="w-full py-2 bg-white text-green-darker leading-normal rounded" value={props.value || undefined} onChange={(e) => this.onDocumentTypeChanged(props.original.id, e.target.value)} >
                                    {
                                        opts.map((opt: DropdownOptionInterface, index: number) => {
                                            return <option value={opt.value} key={index}>{opt.name}</option>
                                        })
                                    }
                                </select>
                            )
                        }
                    } as any
                )
            }
            else {
                columns.push(
                    {
                        Header: <TableGreenHeader value={languageStore.get('documentType')} />,
                        id: 'documentTypeId',
                        accessor: (d: any) => {
                            const docType = _.find(documentTypes, { id: d.documentTypeId }) as DocumentTypeInterface;
                            return docType ? docType.name : '-';
                        }
                    }
                )
            }
        }

        if (editMode) {
            columns.push({
                Header: <TableGreenHeader value={languageStore.get('action')} />,
                id: 'id',
                accessor: 'id',
                Cell: (props: any) => {

                    const document = props.row._original as ContractDocumentInterface;
                    return <FileActions
                        document={document}
                        onToggleSignedLock={this.onToggleSignedLock}
                        store={store}
                        allowAddo={allowAddo}
                        onDelete={this.onDelete}
                        onOpenFile={this.openFileById}
                    />

                }
            } as any)
        }
        else {
            if (allowAddo) {
                columns.push({
                    Header: <TableGreenHeader value={languageStore.get('signingStatus')} />,
                    id: 'signingStatus',
                    Cell: (props: any) => {
                        const document = props.row._original as ContractDocumentInterface;
                        return <SigningStatus
                            document={document}
                            onCancelSigning={this.cancelSigning}
                            onGetSigningStatus={this.getSigningStatus}
                        />
                    }
                } as any)
            }
        }

        return columns;
    }

    render() {
        const { hash, files, store, title, hasDocumentTypes, contractType, allowAddo, allowTemplates, editDescription } = this.props;
        const data = this.mapFileTree(toJS(files));

        // const hash = SectionEnum.FILES_CONTRACT;
        const editMode = store.isEditMode(hash);
        const columns = this.buildColumns(store, editMode, hasDocumentTypes, contractType, allowAddo);

        return (
            <div>
                <ContractSection
                    noFlex={true}
                    onEdit={() => store.setEditMode(hash, !editMode)}
                    hash={hash}
                    canEdit={store.canEdit}
                    editMode={editMode}
                    hideSaveButton={true}>

                    <DragAndDrop onSubmit={this.uploadFile} active={store.canEdit || false}>

                        <ContractDetailsGroup
                            fullWidth={true}
                            title={title}>
                            <div className='flex-grow mt-5'>
                                {
                                    (editMode && editDescription) &&
                                    <div className='mb-8 text-sm' style={{ marginTop: '-10px' }}>
                                        {editDescription}
                                    </div>
                                }

                                <ReactTable
                                    columns={columns}
                                    className={editMode ? 'ReactTable-readonly' : ''}
                                    data={data}
                                    defaultPageSize={99999}
                                    showPagination={false}
                                    NoDataComponent={() => null}
                                    minRows={0}
                                    SubComponent={row => {

                                        const columns = [{
                                            // Header: <TableGreenHeader value={languageStore.get('original')} />,
                                            id: 'fileName',
                                            accessor: (r: any) => {
                                                return <span className='text-grey-dark'>{r.fileName}</span>
                                            }
                                        }]

                                        if (editMode) {
                                            columns.push({
                                                // Header: null,
                                                id: 'id',
                                                accessor: 'id',
                                                width: 296,
                                                Cell: (props: any) => {

                                                    const document = props.row._original as ContractDocumentInterface;

                                                    return <FileActions
                                                        document={document}
                                                        store={store}
                                                        onToggleSignedLock={this.onToggleSignedLock}
                                                        allowAddo={allowAddo}
                                                        onDelete={this.onDelete}
                                                        onOpenFile={this.openFileById}
                                                    />
                                                }
                                            } as any)
                                        }

                                        return (
                                            <div style={{ marginLeft: '30px', marginBottom: '28px', marginTop: 0 }}>
                                                <ReactTable
                                                    minRows={0}
                                                    defaultPageSize={99999}
                                                    showPagination={false}
                                                    NoDataComponent={() => null}
                                                    getTdProps={(state: any, rowInfo: any, column: any, instance: any) => {
                                                        return {
                                                            onClick: (e: React.MouseEvent, handleOriginal: any) => {
                                                                if (!editMode) {
                                                                    const id = rowInfo.original.id;
                                                                    this.openFileById(e, id);
                                                                }
                                                            }
                                                        };
                                                    }}
                                                    columns={columns}
                                                    data={row.original.children} />
                                            </div>
                                        )
                                    }

                                    }
                                    getTrProps={this.expandSubcomponentOnRowClicked}
                                    getTdProps={(state: any, rowInfo: any, column: any, instance: any) => {
                                        return {
                                            onClick: (e: React.MouseEvent, handleOriginal: any) => {
                                                if (!column.expander) {
                                                    e.stopPropagation();
                                                }

                                                if (column.expander && !rowInfo.original.children) {
                                                    return;
                                                }

                                                if (!editMode && !column.expander) {
                                                    const id = rowInfo.original.id;
                                                    this.openFileById(e, id);
                                                }
                                            }
                                        };
                                    }} />

                                {
                                    editMode &&
                                    <div className='mt-5 flex'>

                                        <UploadFile onSubmit={this.uploadFile}>

                                            <button className='bg-comablue-label text-white py-2 px-8 rounded'>
                                                {languageStore.get('upload')}
                                            </button>

                                        </UploadFile>

                                        {
                                            (allowTemplates && editMode) &&
                                            <div className='ml-2'>
                                                <CreateFileByTemplateButton
                                                    store={store!}
                                                    contractType={contractType!} />
                                            </div>
                                        }

                                        {
                                            allowAddo &&
                                            <div className='ml-2'>
                                                <SignDocument documents={files} store={store} />
                                            </div>
                                        }

                                        {
                                            !store.isDraft &&
                                            <button onClick={this.toggleEditMode} className='text-grey-darker border ml-2 py-2 px-4 rounded'>
                                                {languageStore.get('close')}
                                            </button>
                                        }

                                    </div>
                                }

                            </div>
                        </ContractDetailsGroup>
                    </DragAndDrop>

                </ContractSection>

                {
                    addoSigningStatusStore.status &&
                    <AddoSigningStatusModal />
                }

            </div >


        )


    }

}

export default Files;