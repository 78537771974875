import * as React from 'react';
import Button from '../Button';

interface Item {
    text: string,
    onClick: Function
}

interface Props {
    text: string,
    items: Array<Item>
}

interface State {
    visibleMenu: boolean
}

class ExpandButton extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);

        this.state = {
            visibleMenu: false
        }
    }

    toggleMenu = () => {
        this.setState({ visibleMenu: !this.state.visibleMenu });
    }

    hideMenu = () => {
        this.setState({ visibleMenu: false });
    }

    itemClicked(e: React.MouseEvent, item: Item) {
        e.preventDefault();

        item.onClick();
        this.hideMenu();
    }

    render() {
        const { visibleMenu } = this.state;
        const { text, items } = this.props;

        return (
            <div className="relative" onClick={this.toggleMenu} style={{ zIndex: 999 }}>
                <Button active={visibleMenu} text={text} className={`lg:mr-4 ${visibleMenu ? 'border-transparent text-black bg-white' : ''}`} />
                {
                    visibleMenu &&
                    <div onMouseLeave={this.hideMenu} className="rounded shadow-md absolute mt-8 bg-white pin-t pin-l min-w-full">
                        <ul className="list-reset">
                            {
                                items.map((item: Item, index: number) => {
                                    return (
                                        <li key={index}>
                                            <a href=" " onClick={(e) => this.itemClicked(e, item)} className="text-base no-underline px-4 py-3 block text-black hover:bg-grey-lightest">
                                                {item.text}
                                            </a>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                }

            </div>
        )
    }
}

export default ExpandButton;