import React from 'react';
import { observer } from 'mobx-react';
import { CustomerSupplierEnum } from 'components/SuppliersCustomersTable';
import { customerStore, languageStore, navigationStore, supplierStore } from 'stores';
import { Modal, ModalColumn } from 'components/Modal';
import SelectedCustomer from 'components/Forms/Customer/SelectedCustomer';
import { FieldState, FormState } from 'formstate';
import SelectedSupplier from 'components/PurchaseContractDetailsPage/Supplier/SelectedSupplier.tsx';
import { required } from 'helpers/FormValidation';
import Form from 'components/Forms/Form';

interface Props {
    type: CustomerSupplierEnum,
    id: string
}

interface State {
    isShowModal: boolean
}

@observer class MergeSupplierCustomer extends React.Component<Props, State> {

    state = { isShowModal: false }

    form = new FormState({
        mergeInto: new FieldState('').validators(required)
    })

    hideModal = () => {
        this.setState({ isShowModal: false });
    }

    showModal = (e: React.MouseEvent) => {
        e.preventDefault();

        this.setState({ isShowModal: true });
    }

    onMerge = async () => {
        const { type, id } = this.props;
        const mergeInto = this.form.$.mergeInto.$;

        if (type === CustomerSupplierEnum.CUSTOMER) {
            await customerStore.mergeCustomer(id, mergeInto);
        }
        else {
            await supplierStore.mergeSupplier(id, mergeInto);
        }

        this.hideModal();
        navigationStore.push(`/admin/${type === CustomerSupplierEnum.CUSTOMER ? 'customers' : 'suppliers'}`)
    }

    render() {
        const { type, id } = this.props;
        const { isShowModal } = this.state;
        const form = this.form.$;

        return (
            <div className='inline-block'>
                <span className='my-4'>
                    <button
                        className='bg-grey-darkest text-white py-2 px-8 my-4 rounded'
                        type='button'
                        onClick={this.showModal}>
                        {languageStore.get('merge')}
                    </button>
                </span>

                {
                    isShowModal &&
                    <div className='text-left'>
                        <Form onValidSubmit={this.onMerge} formState={this.form}>
                            <Modal
                                title={languageStore.get('mergeInto')}
                                primaryButton={{ title: languageStore.get('merge') }}
                                secondaryButton={{
                                    title: languageStore.get('close'),
                                    onClick: () => this.hideModal()
                                }}>

                                <ModalColumn>
                                    {
                                        type === CustomerSupplierEnum.CUSTOMER &&
                                        <SelectedCustomer
                                            skipIds={[id]}
                                            fieldState={form.mergeInto}
                                            editMode={true}
                                            placeholder={languageStore.get('select')}
                                        />
                                    }

                                    {
                                        type === CustomerSupplierEnum.SUPPLIER &&
                                        <SelectedSupplier
                                            skipIds={[id]}
                                            fieldState={form.mergeInto}
                                            editMode={true}
                                            placeholder={languageStore.get('select')}
                                        />
                                    }

                                </ModalColumn>

                            </Modal>
                        </Form>
                    </div>
                }
            </div>
        )
    }
}

export default MergeSupplierCustomer;