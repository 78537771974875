import React from 'react';
import { observer } from 'mobx-react';
import { FieldState, FormState } from 'formstate';
import { BillingInterface, BillingIntervalEnum, CompanyInterface, languageStore, PlanInterface, superAdminStore } from 'stores';
import Form from 'components/Forms/Form';
import { Modal, ModalColumn } from 'components/Modal';
import SelectPlan from './SelectPlan';
import Dropdown from 'components/Forms/Dropdown';
import SelectDate from 'components/Forms/SelectDate';
import SelectTrueFalse from 'components/Forms/SelectTrueFalse';
import Input from 'components/Forms/Input';
import { required } from 'helpers/FormValidation';
import SelectCustomer from './SelectCustomer';
import SelectCustomerContact from './SelectCustomerContact';
import SelectEmployee from './SelectEmployee';

interface Props {
    company: CompanyInterface
}

interface State {
    showModal: boolean
}

@observer class BillingModal extends React.Component<Props, State> {

    state = { showModal: false }

    form = new FormState({
        id: new FieldState<string | null>(this.props.company.billing?.id || null),
        economicId: new FieldState(this.props.company.economicId || '').validators(required),
        interval: new FieldState<BillingIntervalEnum>(this.props.company.billing?.interval || 0),
        invoicePlanFrom: new FieldState(this.props.company.billing?.invoicePlanFrom || '').validators(required),
        planInvoicedTo: new FieldState(this.props.company.billing?.planInvoicedTo || ''),
        adjustPlanOnNextInvoice: new FieldState<boolean>(this.props.company.billing?.adjustPlanOnNextInvoice || false),
        planDisabled: new FieldState<boolean>(this.props.company.billing?.planDisabled || false),
        plan: new FieldState<string | null>(this.props.company.billing?.plan?.id || null).validators(required),
        planDiscount: new FieldState<number>(this.props.company.billing?.planDiscount || 0),
        planLocked: new FieldState<boolean>(this.props.company.billing?.planLocked || false),
        invoiceSigningsFrom: new FieldState(this.props.company.billing?.invoiceSigningsFrom || ''),
        signingsDisabled: new FieldState<boolean>(this.props.company.billing?.signingsDisabled || false),
        signingsInvoicedTo: new FieldState(this.props.company.billing?.signingsInvoicedTo || ''),
        signingRetailPrQty: new FieldState(this.props.company.billing?.signingRetailPrQty || null),
        economicAttentionId: new FieldState(this.props.company?.economicAttentionId || null),
        economicCustomerContactId: new FieldState(this.props.company?.economicCustomerContactId || null),
        economicSalesEmployeeId: new FieldState(this.props.company?.economicSalesEmployeeId || null),
    })

    showModal = () => {
        this.setState({ showModal: true })
    }

    hideModal = () => {
        this.setState({ showModal: false })
    }

    onSubmit = async () => {
        const { company } = this.props;
        const form = this.form.$;

        let billing = company.billing;
        if (!billing) billing = {} as BillingInterface;

        if (!billing.planInvoicedTo && form.planInvoicedTo.$) {
            if (!window.confirm(`${languageStore.get('areYouSureYouWantToSetPlanInvoicedTo')}?`)) {
                return;
            }
        }

        // planInvoicedTo can only be set once (from null to value)
        if (!billing.planInvoicedTo) {
            billing.planInvoicedTo = form.planInvoicedTo.$;
        }

        billing.id = company.billing?.id;
        billing.interval = form.interval.$!;
        billing.invoicePlanFrom = form.invoicePlanFrom.$;
        billing.adjustPlanOnNextInvoice = form.adjustPlanOnNextInvoice.$;
        billing.planDisabled = form.planDisabled.$;
        billing.plan = { id: form.plan.$! } as PlanInterface;
        billing.planDiscount = form.planDiscount.$!;
        billing.planLocked = form.planLocked.$;
        billing.signingsDisabled = form.signingsDisabled.$;
        billing.invoiceSigningsFrom = form.invoiceSigningsFrom.$;
        billing.signingRetailPrQty = form.signingRetailPrQty.$;

        // If new entity and invoiceSigningsFrom not filled (maybe due to it being invisible, when Addo module is not enabled)
        // set a shadow value of the same date as invoicePlanFrom (required)
        if (!billing.id && !billing.invoiceSigningsFrom) {
            billing.invoiceSigningsFrom = form.invoicePlanFrom.$;
        }

        company.economicId = form.economicId.$;
        company.economicAttentionId = form.economicAttentionId.$;
        company.economicCustomerContactId = form.economicCustomerContactId.$;
        company.economicSalesEmployeeId = form.economicSalesEmployeeId.$;

        company.billing = billing;

        await superAdminStore.updateCompany(company);
        this.hideModal();
    }

    render() {
        const { company } = this.props;
        const { showModal } = this.state;
        const form = this.form.$;
        const isNewEntity = !!!form.id.$;

        return (
            <span>
                {
                    !showModal &&
                    <button
                        onClick={this.showModal}
                        className='ml-2 hover:bg-grey-lightest mt-4 px-4 py-2 border rounded'>
                        {languageStore.get('plan')}
                    </button>
                }

                {
                    showModal &&
                    <Form onValidSubmit={this.onSubmit} formState={this.form}>
                        <Modal
                            title={languageStore.get('plan')}
                            primaryButton={{ title: languageStore.get('saveChanges') }}
                            secondaryButton={{ title: languageStore.get('cancel'), onClick: this.hideModal }}>

                            <ModalColumn>

                                <SelectCustomer
                                    fieldState={form.economicId} />

                                {
                                    form.economicId.value &&
                                    <>
                                        <SelectCustomerContact
                                            label={`${languageStore.get('attention')} (${languageStore.get('customer')})`}
                                            id='economicAttentionId'
                                            fieldState={form.economicAttentionId}
                                            customerId={form.economicId.value}
                                        />

                                        <SelectCustomerContact
                                            label={`${languageStore.get('contact')} (${languageStore.get('customer')})`}
                                            id='economicCustomerContactId'
                                            fieldState={form.economicCustomerContactId}
                                            customerId={form.economicId.value}
                                        />
                                    </>
                                }

                                <SelectEmployee
                                    fieldState={form.economicSalesEmployeeId}
                                />

                                <SelectPlan
                                    disabled={!isNewEntity && !!company.billing?.planInvoicedTo}
                                    fieldState={form.plan}
                                />

                                <Input
                                    id='planDiscount'
                                    type='number'
                                    label={languageStore.get('planDiscountPercentage')}
                                    fieldState={form.planDiscount}
                                />

                                <SelectDate
                                    allowInput={false}
                                    selectMonthOnly={true}
                                    label={languageStore.get('invoicePlanFrom')}
                                    fieldState={form.invoicePlanFrom} />

                                <Dropdown
                                    id='interval'
                                    label={languageStore.get('interval')}
                                    fieldState={form.interval}
                                    options={[
                                        {
                                            value: BillingIntervalEnum.QUARTERLY,
                                            name: languageStore.get('quarterly')
                                        },
                                        {
                                            value: BillingIntervalEnum.YEARLY,
                                            name: languageStore.get('yearly')
                                        }
                                    ]}
                                />

                                {
                                    !company.billing?.planInvoicedTo &&
                                    <SelectDate
                                        allowInput={false}
                                        selectMonthOnly={true}
                                        label={languageStore.get('planInvoicedTo')}
                                        fieldState={form.planInvoicedTo} />
                                }

                                {
                                    company.billing?.planInvoicedTo &&
                                    <Input
                                        readOnly={true}
                                        id='planInvoicedTo'
                                        label={languageStore.get('planInvoicedTo')}
                                        fieldState={form.planInvoicedTo}
                                    />
                                }

                                <SelectTrueFalse
                                    editMode={true}
                                    label={languageStore.get('planLocked')}
                                    fieldState={form.planLocked}
                                    id='planLocked'
                                />

                                {
                                    !form.planLocked.value &&
                                    <div style={{ marginTop: '-40px' }}>
                                        <SelectTrueFalse
                                            editMode={true}
                                            label={languageStore.get('adjustPlanOnNextInvoice')}
                                            fieldState={form.adjustPlanOnNextInvoice}
                                            id='adjustPlanOnNextInvoice'
                                        />
                                    </div>
                                }

                                <div style={{ marginTop: '-40px' }}>
                                    <SelectTrueFalse
                                        editMode={true}
                                        label={languageStore.get('planDisabled')}
                                        fieldState={form.planDisabled}
                                        id='planDisabled'
                                    />
                                </div>

                                {
                                    company.allowAddo &&
                                    <>
                                        <h3 className='mt-8'>{languageStore.get('signings')}</h3>

                                        <SelectDate
                                            allowInput={false}
                                            selectMonthOnly={true}
                                            label={languageStore.get('invoiceSigningsFrom')}
                                            fieldState={form.invoiceSigningsFrom} />

                                        {
                                            company.billing?.signingsInvoicedTo &&
                                            <Input
                                                fieldState={form.signingsInvoicedTo}
                                                label={languageStore.get('signingsInvoicedTo')}
                                                id='signingsInvoicedTo'
                                            />
                                        }

                                        <Input
                                            id='signingRetailPrQty'
                                            type='number'
                                            label={`${languageStore.get('overwrite')} ${languageStore.get('signingRetailPrQty', false)}`}
                                            fieldState={form.signingRetailPrQty}
                                        />

                                        <SelectTrueFalse
                                            editMode={true}
                                            label={languageStore.get('signingsDisabled')}
                                            fieldState={form.signingsDisabled}
                                            id='signingsDisabled'
                                        />
                                    </>
                                }
                            </ModalColumn>

                        </Modal>
                    </Form>
                }
            </span>
        )
    }
}

export default BillingModal;