import * as React from 'react';

interface Props {
    /** Label as appearing on button */
    text: string,
    /** Append additional CSS classes */
    className?: string,
    onClick?: Function,
    active?: boolean,
    activeBackgroundClassName?: string,
    activeTextClassName?: string
}

/** Transparent button to be used in Header */
class Button extends React.PureComponent<Props, object> {

    onClick(e: React.MouseEvent) {
        e.preventDefault();

        if (this.props.onClick) {
            this.props.onClick();
        }
    }

    render() {
        const { text, className, active, activeBackgroundClassName, activeTextClassName } = this.props;

        let btnClass = `
            uppercase
            tracking-wide
            inline-block
            text-xs
            px-4
            py-2
            no-underline
            leading-none
            border
            rounded 
            border-white
            hover:border-transparent
            hover:text-black
            hover:bg-white
            mt-0
            ${active ? (activeTextClassName || 'text-black') : 'text-white'}
            ${active ? (activeBackgroundClassName || 'bg-white') : ''}
        `;

        if (className) {
            btnClass += ' ' + className;
        }

        return (
            <div>
                <a href=" " className={btnClass} onClick={(e) => this.onClick(e)}>{text}</a>
            </div>
        )
    }

}

export default Button;