import * as React from 'react';
import Header from 'components/Header';
import { navigationStore, authStore, redirectStore, userStore } from 'stores';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import ForceRelogin from 'components/ForceRelogin';
import ForceNewPassword from 'components/ForceNewPassword';
import GlobalSupportOverlay from 'components/GlobalSupportOverlay';
import ActivateTwoFactorBanner from 'components/ActivateTwoFactorBanner';

interface Props {
    location: {
        pathname: string,
        hash: string,
        search: string
    }
}

@observer class DashboardPage extends React.Component<Props> {

    componentDidMount() {
        if (!authStore.isAuthenticated) {
            const { location } = this.props;
            const queryParams = this.getQueryStringParams(this.props.location.search);

            if (queryParams['providerId']) {
                authStore.setAdfsHostByProviderId(queryParams['providerId']);
            }

            redirectStore.setLocation(location.pathname + location.hash);
            navigationStore.push(authStore.loginPage);
        }

        if (userStore.signedInUser?.needsTwoFactorAuthSetup) {
            navigationStore.push('/activate-twofactor')
        }
    }

    getQueryStringParams = (query: string) => {
        return query
            ? (/^[?#]/.test(query) ? query.slice(1) : query)
                .split('&')
                .reduce((params, param) => {
                    let [key, value] = param.split('=');
                    params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
                    return params;
                }, {}
                )
            : {}
    };

    render() {
        const { children } = this.props;

        if (!authStore.isAuthenticated) {
            return null;
        }

        return (
            <div className="DashboardPage" style={{ minWidth: '1400px' }}>
                <ActivateTwoFactorBanner />
                <Header />
                <div className="my-4 mx-5">
                    {children}
                </div>
                <ForceRelogin />
                <ForceNewPassword />
                <GlobalSupportOverlay />
            </div>
        )
    }

}

export default withRouter(DashboardPage as any);