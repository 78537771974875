import * as React from 'react';
import { observer } from 'mobx-react';
import { FormState, FieldState } from 'formstate';
import { locationStore, languageStore, LocationInterface, skillStore, SkillInterface, userStore } from 'stores';
import TableHeader from 'components/Table/TableHeader';
import { required } from 'helpers/FormValidation';
import DetailsPage from '../DetailsPage';
import * as _ from 'lodash';
import { ModalColumn } from 'components/Modal';
import ContractDetailsItem from 'components/ContractDetailsItem';
import Location from 'components/Forms/Location';

@observer class SkillPage extends React.Component {

    form = new FormState({
        id: new FieldState<null | string>(null),
        name: new FieldState(null).validators(required),
        locationId: new FieldState(null).validators(required)
    })

    componentDidMount() {
        const companyId = userStore.signedInUser.company.id;
        locationStore.getLocationsByCompany(companyId);

        skillStore.getAllSkills();
    }

    onDelete = async (id: string) => {
        const sk = _.find(skillStore.allSkills, { id: id }) as SkillInterface;
        const locationId = sk.locationId;

        await skillStore.deleteSkill(locationId, id);
    }

    render() {
        const data = skillStore.allSkills || [];
        const locations = locationStore.locations || [];
        const form = this.form.$;
        const isNewEntity = !!!form.id.$;

        const columns = [
            {
                Header: <TableHeader value={languageStore.get('name')} />,
                accessor: 'name'
            },
            {
                Header: <TableHeader value={languageStore.get('location')} />,
                id: 'locationId',
                accessor: (d: any) => {
                    const loc = _.find(locations, { id: d.locationId }) as LocationInterface;
                    return loc ? loc.name : '-';
                }
            },
        ]

        return (
            <DetailsPage
                fullWidth={true}
                generalTitle={languageStore.get('skills')}
                modalTitle={languageStore.get('skills')}
                form={this.form}
                onUpdate={(skill: SkillInterface) => skillStore.updateSkill(skill)}
                onSubmit={(skill: SkillInterface) => skillStore.createSkill(skill)}
                onDelete={(id: string) => this.onDelete(id)}
                collection={data}
                columns={columns}>

                <ModalColumn>

                    <ContractDetailsItem
                        fieldState={form.name}
                        label={languageStore.get('name')}
                        id='name'
                        editMode={true} />

                    {
                        isNewEntity &&
                        <Location fieldState={form.locationId} editMode={true} />
                    }

                </ModalColumn>

            </DetailsPage>
        )
    }

}

export default SkillPage;