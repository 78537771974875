import { observer } from "mobx-react";
import { useEffect } from "react";
import { customFieldStore, DashboardContractTypeEnum, languageStore, navigationStore } from "stores";
import _ from 'lodash';
import FieldGroup from "./FieldGroup";

interface Props {
    contractType: DashboardContractTypeEnum
}

const TemplateFieldsPage = observer((props: Props) => {

    const { contractType } = props;
    useEffect(() => {
        customFieldStore.getCustomFields(contractType);

        return () => {
            customFieldStore.resetStore();
        }
    }, [contractType])

    const fields = customFieldStore.customFields;
    const generalFields = _.filter(fields, f => f.arrayKey === null);

    const collectionFields = _.filter(fields, f => f.arrayKey !== null);
    const groupedFields = _.groupBy(collectionFields, f => f.arrayKeyTitle);

    return (
        <div>
            <h2 className='mt-2 mb-5'>
                <button className='mr-2 text-red' onClick={() => navigationStore.goBack()}>
                    &larr;
                </button>
                {languageStore.get('fields')}
            </h2>

            <FieldGroup title={languageStore.get('general')} data={generalFields} />

            {
                Object.keys(groupedFields).map((fieldName, index) => {

                    return (
                        <FieldGroup
                            title={fieldName}
                            key={index}
                            data={groupedFields[fieldName]}
                            description={languageStore.get('useToDefineTableRow')}
                        />
                    )
                })
            }

        </div>
    )

})

export default TemplateFieldsPage;