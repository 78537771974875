import { BaseStore } from './BaseStore';
import { action, observable, makeObservable } from 'mobx';
import { DashboardContractTypeEnum } from './DashboardStore';
import request from 'api/request';
import { userStore } from './UserStore';

export enum CustomFieldTypeEnum {
    STRING = 0,
    STRING_MAX = 1,
    INT = 2,
    DECIMAL = 3,
    CHECKBOX = 4,
    DROPDOWN = 5,
    MULTIPLE_CHOICE = 6
}

export interface CustomFieldSchemaInterface {
    "id"?: string,
    "label": string,
    "key": string,
    "fieldType": CustomFieldTypeEnum,
    "desciption": string,
    "companyId": string,
    "categoryId": string | null,
    "departmentId": string | null,
    "locationId": string | null,
    "contractType": DashboardContractTypeEnum,
    "availableValues": Array<string>
}

export enum CustomFieldTemplateFieldTypeEnum {
    STANDARD = 0,
    CUSTOM = 1
}

export interface CustomFieldInterface {
    "id": string,
    "key": string,
    "description": string,
    "arrayKey"?: string | null,
    "arrayKeyTitle"?: string | null,
    "templateFieldType": CustomFieldTemplateFieldTypeEnum,
    "customFieldSchema": CustomFieldSchemaInterface
}

export class CustomFieldStore extends BaseStore {
    @observable customFields: Array<CustomFieldInterface> = [];
    @observable hasCustomFields: Object = {};

    constructor() {
        super();

        makeObservable(this);
    }

    @action
    resetStore() {
        this.customFields = [];
        this.hasCustomFields = {};
    }

    @action
    getCustomFields(contractType: DashboardContractTypeEnum) {
        if (userStore.signedInUser && !userStore.signedInUser.company.allowCustomFields) {
            return Promise.resolve([]);
        }
        
        return this.get(`customfields?contractType=${contractType}`, 'customFields');
    }

    @action
    async getHasCustomFields(contractType: DashboardContractTypeEnum) {
        if (userStore.signedInUser && !userStore.signedInUser.company.allowCustomFields) {
            return;
        }

        return new Promise(async (resolve, reject) => {            
            const res = await request.get(`customfields/type-has-fields?contracttype=${contractType}`);

            if (res.status === 200) {
                this.hasCustomFields[contractType] = true;
                resolve(true); 
            }
            else {
                this.hasCustomFields[contractType] = false;
                reject(false);
            }
        })
    }

    @action
    createCustomField(customField: CustomFieldSchemaInterface) {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await request.post(`customfields`, customField);

                if (res.data) {
                    const schema = res.data as CustomFieldSchemaInterface;
                    
                    this.customFields.push(
                        {
                            id: res.data.id,
                            customFieldSchema: schema,
                            description: schema.desciption,
                            key: schema.key,
                            templateFieldType: CustomFieldTemplateFieldTypeEnum.CUSTOM
                        }
                    )
                }
            }
            catch(error) {
                reject(error);
            }
        })
    }

    @action
    updateCustomField(customField: CustomFieldSchemaInterface) {
        return this.update(`customfields/${customField.id}`, customField, null, this.customFields);
    }

    @action
    deleteCustomField(customFieldId: string) {
        return this.delete(`customfields/${customFieldId}`, customFieldId, this.customFields);
    }
}

export const customFieldStore = new CustomFieldStore();