import { BaseStore } from './BaseStore';
import { observable, action, makeObservable } from 'mobx';
import { LocationInterface } from './LocationStore';

export interface EquipmentInterface {
    "id": string,
    "name": string,
    "locationId": string,
    "location": LocationInterface
}

export class EquipmentStore extends BaseStore {
    @observable equipment: Array<EquipmentInterface> = [];
    @observable allEquipment: Array<EquipmentInterface> = [];

    constructor() {
        super();

        makeObservable(this);
    }

    @action
    getEquipment(locationId: string) {
        this.get(`locations/${locationId}/equipment`, 'equipment');
    }

    @action
    getAllEquipment() {
        return this.get('equipment', 'allEquipment');
    }

    @action
    createEquipment(equipment: EquipmentInterface) {
        return this.create(`locations/${equipment.locationId}/equipment`, equipment, this.allEquipment);
    }

    @action
    updateEquipment(equipment: EquipmentInterface) {
        return this.update(`locations/${equipment.locationId}/equipment/${equipment.id}`, equipment, this.allEquipment);
    }

    @action
    deleteEquipment(locationId: string, equipmentId: string) {
        return this.delete(`locations/${locationId}/equipment/${equipmentId}`, equipmentId, this.allEquipment);
    }

    @action
    resetStore() {
        this.equipment = [];
        this.allEquipment = [];
    }
}

export const equipmentStore = new EquipmentStore();