import { EmploymentFormEnum } from 'components/StaffContractDetailsPage/Overview/SelectEmploymentForm';
import { languageStore } from 'stores';
import { DropdownOptionInterface } from 'components/Forms/Dropdown';
import { EmploymentTypeEnum } from 'components/StaffContractDetailsPage/Overview/SelectEmploymentType';
import * as _ from 'lodash';

export class EmploymentHelper {

    static EmploymentForms() {
        return [
            {
                value: EmploymentFormEnum.MANAGEMENT_CONTRACT,
                name: languageStore.get('managementContract')
            },
            {
                value: EmploymentFormEnum.PAID_BY_THE_HOUR,
                name: languageStore.get('paidByTheHour')
            },
            {
                value: EmploymentFormEnum.PROJECT_EMPLOYEE,
                name: languageStore.get('projectEmployee')
            },
            {
                value: EmploymentFormEnum.SALARIED_TERMS,
                name: languageStore.get('salariedTerms')
            },
            {
                value: EmploymentFormEnum.SALARIED_WORKER,
                name: languageStore.get('salariedWorker')
            },
            {
                value: EmploymentFormEnum.CONSULTANT,
                name: languageStore.get('consultant')
            },
            {
                value: EmploymentFormEnum.NON_SALARIED_EMPLOYEE_MONTHLY_PAID,
                name: languageStore.get('nonSalariedEmployeeMonthlyPaid')
            },
            {
                value: EmploymentFormEnum.FREELANCE,
                name: languageStore.get('freelance')
            }
        ] as Array<DropdownOptionInterface>;
    }

    static EmploymentTypes() {
        return [
            {
                value: EmploymentTypeEnum.APPRENTICE,
                name: languageStore.get('apprentice')
            },
            {
                value: EmploymentTypeEnum.BY_THE_HOUR,
                name: languageStore.get('byTheHour')
            },
            {
                value: EmploymentTypeEnum.FULL_TIME,
                name: languageStore.get('fullTime')
            },
            {
                value: EmploymentTypeEnum.INTERN,
                name: languageStore.get('intern')
            },
            {
                value: EmploymentTypeEnum.PART_TIME,
                name: languageStore.get('partTime')
            },
            {
                value: EmploymentTypeEnum.PERMANENT,
                name: languageStore.get('permanent')
            },
            {
                value: EmploymentTypeEnum.STUDENT_AID,
                name: languageStore.get('studentAid')
            },
            {
                value: EmploymentTypeEnum.PUPIL,
                name: languageStore.get('pupil')
            },
            {
                value: EmploymentTypeEnum.TEMPORARY,
                name: languageStore.get('temporary')
            },
            {
                value: EmploymentTypeEnum.CONSULTANT,
                name: languageStore.get('consultant')
            },
            {
                value: EmploymentTypeEnum.FLEXJOB,
                name: languageStore.get('flexjob')
            },
            {
                value: EmploymentTypeEnum.FULL_TIME_VARYING_WEEKLY_WORKING_HOURS,
                name: languageStore.get('fullTimeVaryingWeeklyWorkingHours')
            },
            {
                value: EmploymentTypeEnum.YOUNG_PEOPLE_UNDER_18_YEARS,
                name: languageStore.get('youngPeopleUnder18years')
            }

        ] as Array<DropdownOptionInterface>
    }

    static EmploymentName(data: Array<DropdownOptionInterface>, value: number) {
        const val = _.find(data, { value: value }) as DropdownOptionInterface;
        return val ? val.name : null
    }
}