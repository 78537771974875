import * as React from 'react';
import { observer } from 'mobx-react';
import { FieldState } from 'formstate';
import ContractDropdownItem from 'components/ContractDropdownItem';
import { languageStore, currencyStore, CurrentContractStore, CurrentContractBaseInterface, locationStore, LocationInterface } from 'stores';
import { DropdownOptionInterface } from 'components/Forms/Dropdown';
import { reaction, IReactionDisposer } from 'mobx';
import * as _ from 'lodash';

interface Props {
    fieldState: FieldState<any>,
    editMode?: boolean,
    store: CurrentContractStore<CurrentContractBaseInterface>
}

@observer class Currency extends React.Component<Props> {

    reactionDisposer: IReactionDisposer | any;

    componentDidMount() {
        const { store, fieldState } = this.props;
        currencyStore.getCurrencies();

        this.reactionDisposer = reaction(() => store.currentLocationId, (locationId) => {
            if (locationId && !fieldState.value) {
                const location = _.find(locationStore.locations, { id: locationId }) as LocationInterface;
                if (location) {
                    const currencyId = location.country.currencyId;
                    fieldState.reset(currencyId);
                }
            }
        })
    }

    componentWillUnmount() {
        this.reactionDisposer();
    }

    render() {
        const { fieldState, editMode } = this.props;

        let opts: Array<DropdownOptionInterface> = [];
        currencyStore.currencies.forEach((currency) => {
            opts.push({
                value: currency.id,
                name: currency.name
            })
        })

        return (
            <ContractDropdownItem
                label={languageStore.get('currency')}
                id='currency'
                fieldState={fieldState}
                placeholderText={languageStore.get('select')}
                options={opts}
                editMode={editMode} />
        )
    }

}

export default Currency;