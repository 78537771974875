import { BaseStore } from './BaseStore';
import { observable, action, makeObservable } from 'mobx';
import { LocationInterface } from './LocationStore';
import { DocumentTypeContractTypeEnum } from './BaseContractsStore';

export interface ServiceInterface {
    id: string,
    name: string
    contractType: DocumentTypeContractTypeEnum,
    description: string,
    locationId: string,
    location: LocationInterface
}

export class ServiceStore extends BaseStore {
    @observable services: Array<ServiceInterface> = [];
    @observable allServices: Array<ServiceInterface> = [];

    constructor() {
        super();

        makeObservable(this);
    }

    @action
    getServices(locationId: string, contractType: DocumentTypeContractTypeEnum) {
        return this.get(`locations/${locationId}/services?contractType=${contractType}`, 'services');
    }

    @action
    getAllServices() {
        this.get('services', 'allServices');
    }

    @action
    createService(service: ServiceInterface) {
        return this.create(`locations/${service.locationId}/services`, service, this.allServices);
    }

    @action
    updateService(service: ServiceInterface) {
        return this.update(`locations/${service.locationId}/services/${service.id}`, service, this.allServices);
    }

    @action
    deleteService(locationId: string, serviceId: string) {
        return this.delete(`locations/${locationId}/services/${serviceId}`, serviceId, this.allServices);
    }

    @action
    resetServices() {
        this.services = [];
        this.allServices = [];
    }
}

export const serviceStore = new ServiceStore();