import * as React from 'react';
import { observer } from 'mobx-react';
import { DashboardContractTypeEnum, dashboardStore, languageStore, DraftInterface, navigationStore } from 'stores';
import TableHeader from 'components/Table/TableHeader';
import ReactTable from 'react-table';
import { formatDate } from 'helpers/DateHelper';
import { BaseContractTypeEnum } from 'stores/BaseStore';

interface Props {
    contractType: DashboardContractTypeEnum
}

@observer class Drafts extends React.Component<Props> {

    componentDidMount() {
        const { contractType } = this.props;
        this.getData(contractType);
    }

    getData(contractType: DashboardContractTypeEnum) {
        dashboardStore.getDrafts(contractType, 99);
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        if (nextProps.contractType !== this.props.contractType) {
            this.getData(nextProps.contractType);
        }
    }

    gotoContractDetails = (row: DraftInterface) => {
        navigationStore.push(`/${this.getContractTypeByRow(row)}/${this.getIdByRow(row)}`);
    }

    getContractTypeByRow = (row: DraftInterface) => {
        if (row.purchaseContractId) {
            return BaseContractTypeEnum.PURCHASE;
        }
        else if (row.salesContractId) {
            return BaseContractTypeEnum.SALES;
        }
        else if (row.serviceContractId) {
            return BaseContractTypeEnum.SERVICE;
        }
        else if (row.staffContractId) {
            return BaseContractTypeEnum.STAFF;
        }
        else return;
    }

    getIdByRow = (row: DraftInterface) => {
        if (row.purchaseContractId) {
            return row.purchaseContractId;
        }
        else if (row.salesContractId) {
            return row.salesContractId;
        }
        else if (row.serviceContractId) {
            return row.serviceContractId;
        }
        else if (row.staffContractId) {
            return row.staffContractId;
        }
        else return;
    }

    render() {
        const drafts = dashboardStore.drafts || [];
        if (!drafts || drafts.length === 0) return null;

        const columns = [
            {
                Header: <TableHeader value={languageStore.get('lastModified')} />,
                id: 'changeTime',
                accessor: (d: DraftInterface) => {
                    if (!d.changeTime) return null;
                    return formatDate(d.changeTime, true);
                }
            },
            {
                Header: <TableHeader value={languageStore.get('identification')} />,
                accessor: 'identification'
            }
        ]

        return (
            <div className='w-full border p-4 mt-4 rounded'>
                <p className='font-semibold mb-2'>
                    {languageStore.get('yourDrafts')}
                </p>
                <ReactTable
                    data={drafts}
                    columns={columns}
                    showPagination={false}
                    sortable={false}
                    defaultPageSize={99999}
                    NoDataComponent={() => null}
                    manual // Sorting and pagination will be handled serverside
                    minRows={0}
                    getTdProps={(state: any, rowInfo: any, column: any, instance: any) => {
                        return {
                            onClick: (e: React.MouseEvent, handleOriginal: any) => {
                                this.gotoContractDetails(rowInfo.original);
                            }
                        };
                    }}
                />
            </div>
        )
    }

}

export default Drafts;