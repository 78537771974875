import * as React from 'react';
import { observer } from 'mobx-react';
import { FieldState } from 'formstate';
import ContractDropdownItem from 'components/ContractDropdownItem';
import { languageStore, superAdminStore } from 'stores';
import { DropdownOptionInterface } from 'components/Forms/Dropdown';

interface Props {
    fieldState: FieldState<any>,
    label: string,
    id: string,
    beforeChange?: Function,
    skipIds?: Array<string>
}

@observer class SelectCompany extends React.Component<Props> {

    componentDidMount() {
        superAdminStore.getCompanies();
    }

    render() {
        const { fieldState, label, id, beforeChange, skipIds } = this.props;

        let opts: Array<DropdownOptionInterface> = [];
        superAdminStore.companies.forEach((company) => {
            if (skipIds?.includes(company.id)) return;
            
            opts.push({
                value: company.id,
                name: company.name
            })
        })

        return (
            <ContractDropdownItem
                label={label}
                id={id}
                placeholderText={languageStore.get('selectCompany')}
                fieldState={fieldState}
                options={opts}
                beforeChange={beforeChange}
                editMode={true} />
        )
    }

}

export default SelectCompany;