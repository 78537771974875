import * as React from 'react';
import { observer } from 'mobx-react';
import ReactTable from 'react-table';
import { languageStore, navigationStore, PagingResult } from 'stores';
import { inboxStore, InboxMessageInterface } from 'stores/InboxStore';
import TableGreenHeader from 'components/Table/TableGreenHeader';
import { formatDate } from 'helpers/DateHelper';
import ShowOnPrint from 'components/ShowOnPrint';

interface Props {
    inboxId: string,
    messages: PagingResult<InboxMessageInterface>
}

@observer class InboxMessages extends React.Component<Props> {

    _PAGESIZE = 200;

    constructor(props: any) {
        super(props);

        this.state = {
            message: null
        }
    }

    loadMoreMessages = () => {
        const { inboxId } = this.props;
        inboxStore.getMessages(inboxId, inboxStore.inbox!.messages.currentPage + 1, this._PAGESIZE);
    }

    render() {
        const { messages, inboxId } = this.props;
        if (!messages) return null;

        if (messages.results.length === 0) {
            return (
                <div className='text-center mt-12'>
                    <h1>
                        {languageStore.get('thereAreNoMessagesInInbox')}.
                    </h1>
                    <button className='hover:bg-grey-lightest mt-8 px-4 py-2 border rounded' onClick={() => window.location.reload()}>
                        {languageStore.get('refresh')}
                    </button>
                </div>
            )
        }

        const columns = [
            {
                Header: <TableGreenHeader value={languageStore.get('date')} />,
                id: 'createdOn',
                width: 180,
                accessor: (d: any) => formatDate(d.createdOn)
            },
            {
                Header: <TableGreenHeader value={languageStore.get('sender')} />,
                accessor: 'sender'
            },
            {
                Header: <TableGreenHeader value={languageStore.get('subject')} />,
                accessor: 'subject'
            },
            {
                Header: <TableGreenHeader value={languageStore.get('filename')} />,
                accessor: 'document.fileName'
            }
        ]

        return (
            <div>
                <ShowOnPrint>
                    <div className='text-right mb-6'>
                        <button className='hover:bg-grey-lightest mt-8 px-4 py-2 border rounded' onClick={() => window.location.reload()}>
                            {languageStore.get('refresh')}
                        </button>
                    </div>
                    <ReactTable
                        data={messages.results.slice()}
                        columns={columns}
                        defaultPageSize={99999}
                        sortable={false}
                        manual
                        showPagination={false}
                        NoDataComponent={() => null}
                        minRows={0}
                        getTdProps={(state: any, rowInfo: any, column: any, instance: any) => {
                            return {
                                onClick: (e: React.MouseEvent, handleOriginal: any) => {
                                    const id = rowInfo.original.id;
                                    navigationStore.push(`inbox/${inboxId}/messages/${id}`)
                                }
                            }
                        }} />
                </ShowOnPrint>

                {
                    messages.currentPage < messages.pageCount &&
                    <div className='text-center mt-12'>
                        <button
                            className='px-8 py-2 border rounded hover:bg-grey-lightest'
                            onClick={this.loadMoreMessages}>
                            {languageStore.get('loadMore')}
                        </button>
                    </div>
                }

            </div>

        )
    }

}

export default InboxMessages;