import { CurrentContractStore, CurrentContractBaseInterface } from './CurrentContractStore';
import { action, makeObservable } from 'mobx';
import { CustomerInterface } from './CustomerStore';
import { ContactPeopleInterface } from './SupplierStore';
import request from 'api/request';

interface StoreInterface extends CurrentContractBaseInterface {
    "customerId": string,
    "customer": CustomerInterface,
    "contactPeople": Array<ContactPeopleInterface>
}

export abstract class HasCustomerContractStore<T extends StoreInterface> extends CurrentContractStore<T> {
    
    constructor() {
        super();

        makeObservable(this);
    }

    @action
    addCustomerToContract(customer: CustomerInterface) {
        return new Promise(async (resolve, reject) => {
            try {
                this.contract!.customerId = customer.id!;
                request.put(`contracts/${this.contractEndpoint}/${this.contract!.id}`, this.contract!);

                this.contract!.customer = customer;
                resolve(customer);
            }
            catch (error) {
                reject(error);
            }
        });
    }

    @action
    addContactPersonToContract(contact: ContactPeopleInterface): Promise<void> {
        return new Promise(async (resolve, reject) => {
            try {
                await request.post(`contracts/${this.contractEndpoint}/${this.contract!.id}/contacts`, contact);
                if (this.contract) {
                    this.contract.contactPeople = new Array<ContactPeopleInterface>(contact);
                }
                resolve();
            }
            catch (error) {
                reject(error);
            }
        })
    }
}