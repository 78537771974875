import { SidebarNavigationPropsItemInterface } from "components/AdminPage/SidebarNavigation";
import { languageStore, navigationStore, RoleEnum } from "stores";
import { canAccess, ModuleEnum } from "./ModuleHelper";
import { hasLocationAndDepartmentAdminRole, hasRole } from "./RoleHelper";

export class AdminMenuHelper {

    static getSuperAdminMenu(pathname: string): SidebarNavigationPropsItemInterface | undefined {
        if (!hasRole(RoleEnum.SUPER_ADMIN)) return;

        const subItems = [
            {
                label: languageStore.get('companies'),
                onClick: () => navigationStore.push('/superadmin/companies'),
                isSelected: pathname === '/superadmin/companies'
            },
            {
                label: languageStore.get('plans'),
                onClick: () => navigationStore.push('/superadmin/plans'),
                isSelected: pathname === '/superadmin/plans'
            },
            {
                label: languageStore.get('contractsUpForDeletion'),
                onClick: () => navigationStore.push('/superadmin/contractsupfordeletion'),
                isSelected: pathname === '/superadmin/contractsupfordeletion'
            }
        ]

        if (process.env.REACT_APP_API_HEALTH_ENDPOINT) {
            subItems.push({
                label: languageStore.get('healthCheck'),
                onClick: () => window.open(process.env.REACT_APP_API_HEALTH_ENDPOINT),
                isSelected: false
            })
        }

        return {
            title: `${languageStore.get('superAdmin')} 🚀`,
            subItems: subItems
        }
    }

    static getCommonMenu(pathname: string): SidebarNavigationPropsItemInterface | undefined {
        if (!hasRole(RoleEnum.SUPER_ADMIN) &&
            !hasRole(RoleEnum.PURCHASE_ADMIN) &&
            !hasRole(RoleEnum.HR_ADMIN) &&
            !hasRole(RoleEnum.SALES_ADMIN) &&
            !hasRole(RoleEnum.SERVICE_ADMIN)) return;

        const subItems = [
            {
                label: languageStore.get('administration'),
                onClick: () => navigationStore.push('/admin'),
                isSelected: pathname === '/admin'
            },
            {
                label: languageStore.get('globalSettings'),
                onClick: () => navigationStore.push('/admin/company'),
                isSelected: pathname === '/admin/company'
            },
            {
                label: languageStore.get('users'),
                onClick: () => navigationStore.push('/admin/users'),
                isSelected: pathname.startsWith('/admin/users')
            },
            hasLocationAndDepartmentAdminRole() && {
                label: languageStore.get('locations'),
                onClick: () => navigationStore.push('/admin/locations'),
                isSelected: pathname.startsWith('/admin/locations')
            },
            {
                label: languageStore.get('departments'),
                onClick: () => navigationStore.push('/admin/departments'),
                isSelected: pathname.startsWith('/admin/departments')
            },
            {
                label: languageStore.get('tags'),
                onClick: () => navigationStore.push('/admin/tags'),
                isSelected: pathname === '/admin/tags'
            },
        ]

        if (canAccess(ModuleEnum.INBOX)) {
            subItems.push({
                label: languageStore.get('inboxes'),
                onClick: () => navigationStore.push('/admin/inboxes'),
                isSelected: pathname === '/admin/inboxes'
            })
        }

        return {
            title: languageStore.get('common'),
            defaultExpanded: true,
            subItems: subItems
        }
    }

    static getPurchaseMenu(pathname: string, allowTemplates: boolean, allowCustomFields: boolean): SidebarNavigationPropsItemInterface | undefined {
        if (!hasRole(RoleEnum.PURCHASE_ADMIN) && !canAccess(ModuleEnum.PURCHASE)) return;

        const subItems = [
            {
                label: languageStore.get('categories'),
                onClick: () => navigationStore.push('/admin/purchasecategories'),
                isSelected: pathname === '/admin/purchasecategories'
            },
            {
                label: languageStore.get('suppliers'),
                onClick: () => navigationStore.push('/admin/suppliers'),
                isSelected: pathname === '/admin/suppliers'
            },
            {
                label: languageStore.get('accountNumbers'),
                onClick: () => navigationStore.push('/admin/accountnumbers'),
                isSelected: pathname === '/admin/accountnumbers'
            },
            {
                label: languageStore.get('documentTypes'),
                onClick: () => navigationStore.push('/admin/purchasedocumenttypes'),
                isSelected: pathname === '/admin/purchasedocumenttypes'
            },
            {
                label: languageStore.get('verifiedByDepartments'),
                onClick: () => navigationStore.push('/admin/verifiedbydepartments'),
                isSelected: pathname === '/admin/verifiedbydepartments'
            },
            {
                label: languageStore.get('services'),
                onClick: () => navigationStore.push('/admin/purchaseservices'),
                isSelected: pathname === '/admin/purchaseservices'
            }
        ]

        if (allowTemplates) {
            subItems.push({
                label: languageStore.get('templates'),
                onClick: () => navigationStore.push('/admin/purchasetemplates'),
                isSelected: pathname === '/admin/purchasetemplates'
            })
        }

        if (allowCustomFields) {
            subItems.push({
                label: languageStore.get('customFields'),
                onClick: () => navigationStore.push('/admin/purchasecustomfields'),
                isSelected: pathname === '/admin/purchasecustomfields'
            })
        }

        return {
            title: languageStore.get('purchaseContract'),
            subItems: subItems
        }
    }

    static getStaffMenu(pathname: string, allowTemplates: boolean, allowCustomFields: boolean): SidebarNavigationPropsItemInterface | undefined {
        if (!hasRole(RoleEnum.HR_ADMIN) && !canAccess(ModuleEnum.STAFF)) return;

        const subItems = [
            {
                label: languageStore.get('perks'),
                onClick: () => navigationStore.push('/admin/benefits'),
                isSelected: pathname === '/admin/benefits'
            },
            {
                label: languageStore.get('agreements'),
                onClick: () => navigationStore.push('/admin/agreements'),
                isSelected: pathname === '/admin/agreements'
            },
            {
                label: languageStore.get('equipment'),
                onClick: () => navigationStore.push('/admin/equipment'),
                isSelected: pathname === '/admin/equipment'
            },
            {
                label: languageStore.get('educations'),
                onClick: () => navigationStore.push('/admin/educations'),
                isSelected: pathname === '/admin/educations'
            },
            {
                label: languageStore.get('documentTypes'),
                onClick: () => navigationStore.push('/admin/staffdocumenttypes'),
                isSelected: pathname === '/admin/staffdocumenttypes'
            },
            {
                label: languageStore.get('skills'),
                onClick: () => navigationStore.push('/admin/skills'),
                isSelected: pathname === '/admin/skills'
            }
        ]

        if (allowTemplates) {
            subItems.push(
                {
                    label: languageStore.get('templates'),
                    onClick: () => navigationStore.push('/admin/stafftemplates'),
                    isSelected: pathname === '/admin/stafftemplates'
                }
            )
        }

        if (allowCustomFields) {
            subItems.push(
                {
                    label: languageStore.get('customFields'),
                    onClick: () => navigationStore.push('/admin/staffcustomfields'),
                    isSelected: pathname === '/admin/staffcustomfields'
                }
            )
        }

        return {
            title: languageStore.get('staffContract'),
            subItems: subItems
        }
    }

    static getServiceMenu(pathname: string, allowTemplates: boolean, allowCustomFields: boolean): SidebarNavigationPropsItemInterface | undefined {
        if (!hasRole(RoleEnum.SERVICE_ADMIN) && !canAccess(ModuleEnum.SERVICE)) return;

        const subItems = [
            {
                label: languageStore.get('categories'),
                onClick: () => navigationStore.push('/admin/servicecategories'),
                isSelected: pathname === '/admin/servicecategories'
            },
            {
                label: languageStore.get('documentTypes'),
                onClick: () => navigationStore.push('/admin/servicedocumenttypes'),
                isSelected: pathname === '/admin/servicedocumenttypes'
            },
            {
                label: languageStore.get('services'),
                onClick: () => navigationStore.push('/admin/serviceservices'),
                isSelected: pathname === '/admin/serviceservices'
            }
        ]

        if (allowTemplates) {
            subItems.push(
                {
                    label: languageStore.get('templates'),
                    onClick: () => navigationStore.push('/admin/servicetemplates'),
                    isSelected: pathname === '/admin/servicetemplates'
                }
            )
        }

        if (allowCustomFields) {
            subItems.push(
                {
                    label: languageStore.get('customFields'),
                    onClick: () => navigationStore.push('/admin/servicecustomfields'),
                    isSelected: pathname === '/admin/servicecustomfields'
                }
            )
        }

        return {
            title: languageStore.get('serviceContract'),
            subItems: subItems
        }
    }

    static getSalesMenu(pathname: string, allowTemplates: boolean, allowCustomFields: boolean): SidebarNavigationPropsItemInterface | undefined {
        if (!hasRole(RoleEnum.SALES_ADMIN) && !canAccess(ModuleEnum.SALES)) return;

        const subItems = [
            {
                label: languageStore.get('categories'),
                onClick: () => navigationStore.push('/admin/salescategories'),
                isSelected: pathname === '/admin/salescategories'
            },
            {
                label: languageStore.get('customers'),
                onClick: () => navigationStore.push('/admin/customers'),
                isSelected: pathname === '/admin/customers'
            },
            {
                label: languageStore.get('documentTypes'),
                onClick: () => navigationStore.push('/admin/salesdocumenttypes'),
                isSelected: pathname === '/admin/salesdocumenttypes'
            },
            {
                label: languageStore.get('services'),
                onClick: () => navigationStore.push('/admin/salesservices'),
                isSelected: pathname === '/admin/salesservices'
            }
        ]

        if (allowTemplates) {
            subItems.push(
                {
                    label: languageStore.get('templates'),
                    onClick: () => navigationStore.push('/admin/salestemplates'),
                    isSelected: pathname === '/admin/salestemplates'
                }
            )
        }

        if (allowCustomFields) {
            subItems.push(
                {
                    label: languageStore.get('customFields'),
                    onClick: () => navigationStore.push('/admin/salescustomfields'),
                    isSelected: pathname === '/admin/salescustomfields'
                }
            )
        }

        return {
            title: languageStore.get('salesContract'),
            subItems: subItems
        }
    }

}