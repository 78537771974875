import { action, makeObservable, observable } from "mobx";
import { BaseStore } from "./BaseStore";
import { MainCategoryInterface } from "./CategoryStore";
import { SubCategoryInterface } from "./SubCategoryStore";

export class AdminCategoryDetailsStore extends BaseStore {

    @observable category: MainCategoryInterface | null = null;

    constructor() {
        super();

        makeObservable(this);
    }

    @action
    resetStore = () => {
        this.category = null;
    }

    @action
    getCategory = (categoryId: string) => {
        return this.get(`categories/${categoryId}`, 'category');
    }

    @action
    deleteSubCategory(locationId: string, categoryId: string, subCategoryId: string) {
        return this.delete(`locations/${locationId}/categories/${categoryId}/subcategories/${subCategoryId}`, subCategoryId, this.category?.subCategories || []);
    }

    @action
    createSubCategory(locationId: string, category: SubCategoryInterface) {
        return this.create(`locations/${locationId}/categories/${category.categoryId}/subcategories`, category, this.category?.subCategories || []);
    }

    @action
    updateSubcategory(locationId: string, category: SubCategoryInterface) {
        return this.update(`locations/${locationId}/categories/${category.categoryId}/subcategories/${category.id}`, category, this.category?.subCategories || []);
    }
}

export const adminCategoryDetailsStore = new AdminCategoryDetailsStore();