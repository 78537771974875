import React, { useEffect, useState } from 'react';
import SidebarNavigationItem from './SidebarNavigationItem';

export interface SidebarNavigationPropsSubItemInterface {
    label: string,
    onClick: () => void,
    isSelected?: boolean
}

export interface SidebarNavigationPropsItemInterface {
    defaultExpanded?: boolean,
    title: string,
    subItems: Array<SidebarNavigationPropsSubItemInterface>
}

export interface SidebarNavigationPropsInterface {
    defaultExpanded?: boolean,
    background?: string,
    items: Array<SidebarNavigationPropsItemInterface>
}

const SidebarNavigation = (props: SidebarNavigationPropsInterface) => {
    const [isExpanded, setIsExpanded] = useState([] as Array<Number>);

    useEffect(() => {
        const expanded: Array<Number> = [];

        props.items.forEach((item, index) => {
            if (props.defaultExpanded || item.defaultExpanded) {
                expanded.push(index);
            }
            else {
                item.subItems.forEach((subItem) => {
                    if (subItem.isSelected) {
                        expanded.push(index);
                    }
                })
            }
        })

        setIsExpanded(expanded);

    }, [props.defaultExpanded, props.items])

    const toggleExpandCollapse = (index: number) => {
        if (isExpanded.indexOf(index) !== -1) {
            setIsExpanded(isExpanded.filter((val) => val !== index))
        }
        else {
            setIsExpanded([...isExpanded, index])
        }
    }

    return (
        <div className='w-48 mr-4 p-4' style={{ background: props.background || '#f9f9f9' }}>
            {
                props.items.map((item, index) => (
                    <React.Fragment key={index}>
                        <button
                            className={`${isExpanded.indexOf(index) !== -1 ? 'mb-0' : 'mb-4'} cursor-pointer font-semibold w-full text-left`}
                            onClick={() => toggleExpandCollapse(index)}>
                            <h3 className={`text-sm`}>
                                {item.title}
                            </h3>
                        </button>

                        {
                            isExpanded.indexOf(index) !== -1 &&
                            <ul className='list-reset text-sm mb-4'>
                                {
                                    item.subItems.map((entity, index) => (
                                        <SidebarNavigationItem
                                            key={index}
                                            label={entity.label}
                                            onClick={entity.onClick}
                                            isSelected={entity.isSelected}
                                        />
                                    ))
                                }
                            </ul>
                        }

                    </React.Fragment>
                ))
            }

        </div>
    )
}

export default SidebarNavigation;