import { FieldState, FormState } from "formstate";
import { observer } from "mobx-react";
import { useEffect } from "react";
import { companyUserStore, languageStore, UserReassignDepartmentInterface } from "stores";
import useCompanyUserByParams from "../useCompanyUserByParams";
import _ from 'lodash';
import ResponsibleUser from "components/Forms/ResponsibleUser";
import Form from "components/Forms/Form";

const Reassign = observer(() => {
    const user = useCompanyUserByParams();
    const form = new FormState({});

    useEffect(() => {
        if (user) {
            companyUserStore.getAssignedDepartments(user.companyId, user.id);
            const companyId = user.companyId;
            companyUserStore.getUsers(companyId);
        }

        return () => {
            companyUserStore.resetAssignedDepartments();
        }
    }, [user])

    if (!user) return null;

    const onSubmit = async () => {
        let data: Array<UserReassignDepartmentInterface> = [];

        for (let departmentId in form.$) {
            let value = form.$[departmentId] as FieldState<string>;
            const userId = value.$;
            if (userId) {
                data.push({
                    departmentId: departmentId,
                    reassingToUserId: userId
                })
            }
        }

        if (data.length > 0) {
            await companyUserStore.reassignDepartment(user.companyId, user.id, data);
            window.location.reload();
        }

    }

    const departments = companyUserStore.assignedDepartments || [];

    const filteredDepartments: Array<React.ReactNode> = [];

    departments.forEach((dep) => {
        let field = new FieldState(null);
        form.$[dep.id] = field;

        let label = `${dep.name} (${dep.location.name})`

        if (!_.find(user.departments, { id: dep.id })) {
            label += ` *${languageStore.get('notInDepartment')}*`
        }

        filteredDepartments.push(
            <ResponsibleUser
                companyId={user.companyId}
                skipUserId={user.id}
                departmentIds={[dep.id]}
                key={dep.id}
                label={label}
                fieldState={field}
                force={false}
                editMode={true} />
        )
    })

    return (
        <Form onValidSubmit={onSubmit} formState={form}>
            <div className='w-64'>
                {
                    filteredDepartments.length === 0 &&
                    <div>
                        {languageStore.get('noDepartmentsToReassign')}
                    </div>
                }

                {filteredDepartments}
            </div>

            {
                filteredDepartments.length > 0 &&
                <div className='mt-8'>
                    <button type="submit" className="bg-comablue-label hover:bg-comablue text-white py-2 px-8 rounded">
                        {languageStore.get('saveChanges')}
                    </button>
                </div>
            }

        </Form>
    )
})

export default Reassign;