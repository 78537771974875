import * as React from 'react';
import { observer } from 'mobx-react';
import { FormState, FieldState } from 'formstate';
import { currentServiceContractStore as store, languageStore, ServiceContractServiceInterface, ServiceStatusEnum, serviceStore, ServiceInterface } from 'stores';
import TableGreenHeader from 'components/Table/TableGreenHeader';
import BaseContractTable from 'components/StaffContractDetailsPage/BaseContractTable';
import SectionEnum from '../SectionEnum';
import { ModalColumn } from 'components/Modal';
import * as _ from 'lodash';
import ContractDetailsItem from 'components/ContractDetailsItem';
import SelectService from './SelectService';
import SelectServiceStatus from './SelectServiceStatus';
import { required } from 'helpers/FormValidation';

@observer class Services extends React.Component {

    form = new FormState({
        id: new FieldState<string | null>(null),
        serviceId: new FieldState(null).validators(required),
        placement: new FieldState(null),
        model: new FieldState(null),
        inspection: new FieldState(null),
        presureTestning: new FieldState(null),
        remark: new FieldState(null),
        serviceStatus: new FieldState(null).validators(required)
    })

    beforeSubmit = (ent: any) => {
        // ServiceName is mapped by GET requests,
        // but because we are dynamically appending the object to
        // collection and using that ref in our tables, we need to
        // manually map serviceName for new entities
        const service = _.find(serviceStore.services, { id: ent.serviceId }) as ServiceInterface;
        ent.serviceName = service.name;
    }

    render() {
        const data = store.contract!.serviceContractServices || [];
        const form = this.form.$;
        const isNewEntity = !!!form.id.$;

        const columns = [
            {
                Header: <TableGreenHeader value={languageStore.get('service')} />,
                id: 'service',
                accessor: 'serviceName'
            },
            {
                Header: <TableGreenHeader value={languageStore.get('placement')} />,
                accessor: 'placement'
            },
            {
                Header: <TableGreenHeader value={languageStore.get('model')} />,
                accessor: 'model'
            },
            {
                Header: <TableGreenHeader value={languageStore.get('inspection')} />,
                accessor: 'inspection'
            },
            {
                Header: <TableGreenHeader value={languageStore.get('presureTesting')} />,
                accessor: 'presureTestning'
            },
            {
                Header: <TableGreenHeader value={languageStore.get('remark')} />,
                accessor: 'remark'
            },
            {
                Header: <TableGreenHeader value={languageStore.get('status')} />,
                id: 'status',
                accessor: (d: any) => {
                    switch (d.serviceStatus) {
                        case ServiceStatusEnum.AGREED: return languageStore.get('agreed');
                        case ServiceStatusEnum.DELETED_OR_HIDDEN: return languageStore.get('deletedOrHidden');
                        default: return null;
                    }
                }
            },
        ]

        return (
            <BaseContractTable
                form={this.form}
                onUpdate={(service: ServiceContractServiceInterface) => store.updateService(service)}
                onSubmit={(service: ServiceContractServiceInterface) => store.createService(service)}
                onDelete={(id: string) => store.deleteService(id)}
                collection={data}
                beforeSubmit={this.beforeSubmit}
                columns={columns}
                tableTitle={languageStore.get('services')}
                modalTitle={languageStore.get('services')}
                store={store}
                hash={SectionEnum.SERVICES}>

                <ModalColumn>

                    <SelectService
                        store={store}
                        fieldState={form.serviceId}
                        editMode={isNewEntity} />

                    <ContractDetailsItem
                        fieldState={form.placement}
                        label={languageStore.get('placement')}
                        id='placement'
                        editMode={true} />

                    <ContractDetailsItem
                        fieldState={form.model}
                        label={languageStore.get('model')}
                        id='model'
                        editMode={true} />

                    <ContractDetailsItem
                        fieldState={form.inspection}
                        label={languageStore.get('inspection')}
                        id='inspection'
                        editMode={true} />

                    <ContractDetailsItem
                        fieldState={form.presureTestning}
                        label={languageStore.get('pressureTesting')}
                        id='pressureTestning'
                        editMode={true} />

                    <ContractDetailsItem
                        fieldState={form.remark}
                        label={languageStore.get('remark')}
                        id='remark'
                        editMode={true} />

                    <SelectServiceStatus
                        fieldState={form.serviceStatus}
                        editMode={true} />

                </ModalColumn>

            </BaseContractTable>
        )
    }

}

export default Services;