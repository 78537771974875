import * as React from 'react';
import { observer } from 'mobx-react';
import { FormState, FieldState } from 'formstate';
import { UserInterface, languageStore, createUserStore, navigationStore } from 'stores';
import StepContainer from './StepContainer';
import Column from './StepContainer/Column';
import Input from 'components/Forms/Input';
import { required, email } from 'helpers/FormValidation';
import SelectTimeZone from 'components/Forms/SelectTimeZone';
import moment from 'moment';

interface Props {
    afterSubmit: Function,
    companyId: string
}

@observer class UserInformationStep extends React.Component<Props> {

    form = new FormState({
        email: new FieldState('').validators(required, email),
        repeatEmail: new FieldState('').validators(required),
        fullName: new FieldState('').validators(required),
        timeZoneId: new FieldState(moment.tz.guess()).validators(required)
    })

    onSubmit = async () => {
        const form = this.form.$;

        if (form.email.$ !== form.repeatEmail.$) {
            alert(languageStore.get('emailMustBeMatching'))
            return;
        }

        const user = {
            email: form.email.$,
            fullName: form.fullName.$,
            timeZoneId: form.timeZoneId.$
        } as UserInterface

        await createUserStore.createUser(this.props.companyId, user);

        this.props.afterSubmit();
    }

    render() {
        const form = this.form.$;

        return (
            <StepContainer
                backLinkFunc={() => navigationStore.goBack()}
                backlinkTitle={languageStore.get('users')}
                title={languageStore.get('userInformation')}
                form={this.form}
                onSubmit={this.onSubmit}>

                <Column>
                    <Input fieldState={form.fullName} id='fullName' label={languageStore.get('fullName')} />
                    <Input fieldState={form.email} id='email' label={languageStore.get('email')} />
                    <Input fieldState={form.repeatEmail} id='repeatEmail' label={languageStore.get('repeatEmail')} />
                </Column>
                <Column>
                    <div style={{ marginTop: '-32px' }}>
                        <SelectTimeZone fieldState={form.timeZoneId} />
                    </div>

                </Column>

            </StepContainer>
        )
    }

}

export default UserInformationStep;