import request from 'api/request';
import { LanguageEnum } from './LanguageStore';
import { PlanInterface } from './PlanStore';

export enum BillingIntervalEnum {
    QUARTERLY = 0,
    YEARLY = 1
}

export interface BillingInterface {
    "id"?: string,
    "interval": BillingIntervalEnum,
    "invoicePlanFrom": string,
    "planInvoicedTo": string,
    "adjustPlanOnNextInvoice": boolean,
    "planDisabled": boolean,
    "plan": PlanInterface,
    "planDiscount": number,
    "planLocked": boolean,
    "invoiceSigningsFrom": string,
    "signingsInvoicedTo": string,
    "signingsDisabled": boolean,
    "signingRetailPrQty": number | null
}

export interface CompanyInterface {
    "name": string,
    "key": string,
    "isActive": boolean,
    "minioBucketName": string,
    "id": string,
    "defaultLanguage": LanguageEnum | null,
    "allowAddo": boolean,
    "allowCustomFields": boolean,
    "allowTemplates": boolean,
    "hasBillingPlan": boolean,
    "hasPurchaseModul": boolean,
    "hasSalesModul": boolean,
    "hasServiceModul": boolean,
    "hasStaffModul": boolean,
    "hasAssetManagementModul": boolean,
    "dontAllowDocs": boolean,
    "dontAllowExcel": boolean,
    "dontAllowImages": boolean,
    "dontAllowPDF": boolean,
    "economicAttentionId": string | null,
    "economicCustomerContactId": number | null,
    "economicId": string | null,
    "economicSalesEmployeeId": string | null,
    "allowInbox": boolean,
    "inboxId": string,
    "needsResync": boolean,
    "deleteDateReminder": string,
    "twoFactorAuthRequired": boolean,
    "billing": BillingInterface | null,
    "purchaseDefaultDaysBeforeDelete": number | null,
    "staffDefaultDaysBeforeDelete": number | null,
    "serviceDefaultDaysBeforeDelete": number | null,
    "salesDefaultDaysBeforeDelete": number | null,
    "webhookBaseUrl": string,
    "signedDocumentsAreDeletable": boolean,
    "numberOfDaysBeforeLogDelete": number,
    "deactivteAutomaticBirthdayAlarms": boolean,
    "deactivteAutomaticSeniorityAlarms": boolean
}

export class CompanyStore {

    updateCompany(company: CompanyInterface) {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await request.put(`companies/${company.id}`, company);
                resolve(res.data);
            }
            catch (error) {
                reject(error);
            }
        })
    }

}

export const companyStore = new CompanyStore();