import Form from "components/Forms/Form";
import Input from "components/Forms/Input";
import { FieldState, FormState } from "formstate";
import { required } from "helpers/FormValidation";
import { observer } from "mobx-react";
import { departmentStore, languageStore, navigationStore, toastStore } from "stores";
import useUserRoleDepartmentByParams from "../useUserRoleDepartmentByParams";

const General = observer(() => {

    const department = useUserRoleDepartmentByParams();
    if (!department) return null;

    const form = new FormState({
        name: new FieldState(department.name).validators(required)
    })

    const updateDepartment = async () => {
        department.name = form.$.name.$;

        await departmentStore.updateDepartment(department, departmentStore.departmentsByRole);

        toastStore.addToast({
            level: 'success',
            title: languageStore.get('success'),
            message: languageStore.get('changesSaved')
        })
    }

    const deleteDepartment = async () => {
        if (!window.confirm(`${languageStore.get('areYouSureYouWantToDelete')}?`)) return;

        await departmentStore.deleteDepartment(department.locationId, department.id, departmentStore.departmentsByRole);
        navigationStore.push('/admin/departments');
    }

    return (
        <div className='mt-4'>
            <Form formState={form} onValidSubmit={updateDepartment}>
                <div className='w-64'>
                    <Input
                        fieldState={form.$.name}
                        id='name'
                        label={languageStore.get('name')}
                    />
                </div>

                <button
                    className='bg-comablue text-white px-8 py-2 rounded'>
                    {languageStore.get('saveChanges')}
                </button>
                <button
                    className='ml-4 bg-red-dark text-white px-4 py-2 rounded inline'
                    type='button'
                    onClick={() => deleteDepartment()}>
                    {languageStore.get('delete')}
                </button>
            </Form>
        </div>
    )
})

export default General;