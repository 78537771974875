import * as React from 'react';
import { observer } from 'mobx-react';
import { FieldState } from 'formstate';
import ContractDropdownItem from 'components/ContractDropdownItem';
import { languageStore, timeZoneStore } from 'stores';
import * as _ from 'lodash';

interface Props {
    fieldState: FieldState<any>
}

@observer class SelectTimeZone extends React.Component<Props> {

    componentDidMount() {
        timeZoneStore.getTimeZones();
    }

    render() {
        const { fieldState } = this.props;

        let opts = timeZoneStore.timeZones.map((tz) => {
            return {
                value: tz.id,
                name: tz.id
            }
        })

        opts = _.orderBy(opts, 'name');

        return <ContractDropdownItem
            label={languageStore.get('timeZone')}
            id='timeZone'
            fieldState={fieldState}
            options={opts}
            placeholderText={languageStore.get('select')}
            skipSort={true}
            editMode={true} />
    }

}

export default SelectTimeZone;