import blankSvg from './blank.svg';
import docSvg from './doc.svg';
import docxSvg from './docx.svg';
import xlsSvg from './xls.svg';
import xlsxSvg from './xlsx.svg';
import pngSvg from './png.svg';
import jpegSvg from './jpeg.svg';
import jpgSvg from './jpg.svg';
import pdfSvg from './pdf.svg';

const FileIcon = (props: { extension: string }) => {

    let imgSrc = blankSvg;

    switch (props.extension) {
        case '.doc':
            imgSrc = docSvg;
            break;
        case '.docx':
            imgSrc = docxSvg;
            break;
        case '.xls':
            imgSrc = xlsSvg;
            break;
        case '.xlsx':
            imgSrc = xlsxSvg;
            break;
        case '.png':
            imgSrc = pngSvg;
            break;
        case '.jpeg':
            imgSrc = jpegSvg;
            break;
        case '.jpg':
            imgSrc = jpgSvg;
            break;
        case '.pdf':
            imgSrc = pdfSvg;
            break;
    }

    return <img src={imgSrc} alt='blank' height={12} width={12} />

}

export default FileIcon;