import * as React from 'react';
import { observer } from 'mobx-react';
import { CurrentContractStore } from 'stores';
import { FormState } from 'formstate';
import { mapField } from 'helpers/FormState';
import Form from 'components/Forms/Form';
import ContractSection from 'components/ContractSection';

interface Props {
    store: CurrentContractStore<any>,
    form: FormState<any>,
    sectionEnum: string,
    children: React.ReactNode,
    showDraftRibbon?: boolean
    customObjectMapping?: Function,
    beforeSubmit?: () => void,
    hideCancelButton?: boolean,
    noFlex?: boolean
}

@observer class BaseContract extends React.Component<Props> {

    mapContract(values: Array<string>) {
        const { form, store } = this.props;

        values.forEach((value) => {
            store.contract![value] = mapField(form.$[value], store.contract![value]);
        })
    }

    onSubmit = (): Promise<void> => {
        return new Promise(async (resolve, reject) => {
            const { form, store, sectionEnum, customObjectMapping, beforeSubmit } = this.props;
            const res = await form.validate();
            if (res.hasError) {
                reject();
                return;
            }

            if (beforeSubmit) {
                beforeSubmit();
            }

            if (customObjectMapping) {
                customObjectMapping();
            }
            else {
                this.mapContract(Object.keys(form.$));
            }

            // If store is draft, every onSubmit function on page
            // will be added to a Promise.all function, and afterwards
            // the contract will be saved. So we only need to save in this function
            // if the contract is not in draft mode, where one button should save all promises.
            if (!store.isDraft) {
                await store.putContract(store.contract!);
            }

            store.setEditMode(sectionEnum, false);
            resolve();

        })
    }

    componentDidMount() {
        const { store, form } = this.props;

        if (store.isDraft) {
            store.addSubmitPromise(this.onSubmit);
            store.addSubmitForm(form);
        }

        // validate form on first render, to highlight required fields.
        form.validate();
    }

    onReset = () => {
        const { store, sectionEnum } = this.props;
        store.setEditMode(sectionEnum, false);
    }

    render() {
        const { form, store, sectionEnum, children, showDraftRibbon, hideCancelButton, noFlex } = this.props;

        var realNoFlex = false;

        if(noFlex !== undefined && noFlex !== null)
        {
            realNoFlex = noFlex;
        }
        const editMode = store.isEditMode(sectionEnum);

        return (
            <Form onValidSubmit={this.onSubmit} onReset={this.onReset} formState={form}>

                <ContractSection
                    noFlex={realNoFlex}
                    hash={sectionEnum}
                    onEdit={() => store.setEditMode(sectionEnum, !editMode)}
                    editMode={editMode}
                    canEdit={store.canEdit}
                    hideSaveButton={store.isDraft}
                    hideCancelButton={hideCancelButton}
                    showDraftRibbon={showDraftRibbon}>

                    {children}

                </ContractSection>

            </Form>
        )
    }

}

export default BaseContract;