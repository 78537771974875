import { observer } from "mobx-react";
import { languageStore } from "stores";

const SubTableNoDataComponent = observer(() => (
    <div style={{
        color: '#8795a1',
        fontSize: '14px',
        fontStyle: 'italic'
    }}>
        {languageStore.get('noData')}...
    </div>
))

export default SubTableNoDataComponent;