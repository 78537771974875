import * as React from 'react';
import { observer } from 'mobx-react';
import { FormState, FieldState } from 'formstate';
import { userStore, languageStore, benefitStore, BenefitInterface, StaffBenefitsInterface } from 'stores';
import { currentStaffContractStore as store } from 'stores';
import TableGreenHeader from 'components/Table/TableGreenHeader';
import { formatDate } from 'helpers/DateHelper';
import * as _ from 'lodash';
import { formatUser, formatStaffContractDetailsStatus } from 'helpers/FormatHelper';
import BaseContractTable from '../BaseContractTable';
import SectionEnum from '../SectionEnum';
import { ModalColumn } from 'components/Modal';
import SelectBenefit from './SelectBenefit';
import ContractDetailsItem from 'components/ContractDetailsItem';
import ContractDateItem from 'components/ContractDateItem';
import SelectUser from '../Overview/SelectUser';
import SelectStaffContractDetailsStatus from '../SelectStaffContractDetailsStatus';
import { required } from 'helpers/FormValidation';
import { reaction, IReactionDisposer } from 'mobx';
import numeral from 'numeral';

@observer class Benefits extends React.Component {

    form = new FormState({
        id: new FieldState<string | null>(null),
        price: new FieldState(null),
        benefitId: new FieldState(null).validators(required),
        taxationValue: new FieldState(null),
        handedOut: new FieldState(null).validators(required),
        handedIn: new FieldState(null),
        accountableUserId: new FieldState(null).validators(required),
        note: new FieldState(null),
        staffContractDetailsStatus: new FieldState(null).validators(required)
    })

    reactionDisposer: IReactionDisposer | any;

    componentDidMount() {
        userStore.getUsers(false);

        if (store.contract && store.contract.department) {
            const locationId = store.contract!.department.locationId;
            benefitStore.getBenefits(locationId);
        }

        this.reactionDisposer = reaction(() => store.currentLocationId, (locationId) => {
            if (!locationId) {
                benefitStore.resetStore();
                return;
            }
            
            if (locationId && store.contract && 
                ((store.contract.department !== null && store.contract.department.locationId !== locationId) ||
                (store.contract && store.contract!.department === null))) {

                benefitStore.getBenefits(locationId!);
            }
        })
    }

    componentWillUnmount() {
        this.reactionDisposer();
    }

    formatNumber = (value: any) => {
        
        if(!value)
        {
            return "";   
        }

        const parsedValue = parseFloat(String(value));
        var number = numeral(parsedValue);
        return number.format("0,0.00")
    }
    
    render() {
        
        const data = store.contract!.benefits || [];
        const users = userStore.users;
        const benefits = benefitStore.benefits || [];
        const form = this.form.$;


        const columns = [
            {
                Header: <TableGreenHeader value={languageStore.get('perk')} />,
                id: 'benefitId',
                accessor: (d: any) => {
                    const benefit = _.find(benefits, { id: d.benefitId }) as BenefitInterface;
                    return benefit ? benefit.name : '-';
                }
            },
            {
                Header: <TableGreenHeader value={languageStore.get('taxationValue')} />,
                id: 'taxationValue',
                accessor: (d: any) => {
                    const benefit = _.find(data, { id: d.id }) as StaffBenefitsInterface;
                    return benefit.taxationValue ? this.formatNumber(benefit.taxationValue) : '';
                }
            },
            {
                Header: <TableGreenHeader value={languageStore.get('price')} />,
                id: 'price',
                accessor: (d: any) => {
                    const benefit = _.find(data, { id: d.id }) as StaffBenefitsInterface;
                    return benefit.price ? this.formatNumber(benefit.price) : '';
                }
            },
            {
                Header: <TableGreenHeader value={languageStore.get('handedOut')} />,
                id: 'handedOut',
                accessor: (d: any) => formatDate(d.handedOut)
            },
            {
                Header: <TableGreenHeader value={languageStore.get('handedIn')} />,
                id: 'handedIn',
                accessor: (d: any) => formatDate(d.handedIn)
            },
            {
                Header: <TableGreenHeader value={languageStore.get('note')} />,
                accessor: 'note'
            },
            {
                Header: <TableGreenHeader value={languageStore.get('responsible')} />,
                id: 'accountableUserId',
                accessor: (d: any) => formatUser(users, d.accountableUserId)
            },
            {
                Header: <TableGreenHeader value={languageStore.get('status')} />,
                id: 'status',
                accessor: (d: any) => formatStaffContractDetailsStatus(d.staffContractDetailsStatus)
            },
        ]

        return (
            <BaseContractTable
                form={this.form}
                onUpdate={(benefit: StaffBenefitsInterface) => store.updateBenefit(benefit)}
                onSubmit={(benefit: StaffBenefitsInterface) => store.createBenefit(benefit)}
                onDelete={(id: string) => store.deleteBenefit(id)}
                collection={data}
                columns={columns}
                tableTitle={languageStore.get('perks')}
                modalTitle={languageStore.get('perks')}
                store={store}
                hash={SectionEnum.BENEFITS}>

                <ModalColumn>

                    <ContractDetailsItem
                        fieldState={form.price}
                        label={languageStore.get('price')}
                        id='price'
                        type='number'
                        editMode={true} />

                    <SelectBenefit
                        fieldState={form.benefitId}
                        editMode={true} />

                    <ContractDetailsItem
                        fieldState={form.taxationValue}
                        label={languageStore.get('taxationValue')}
                        id='taxationValue'
                        type='number'
                        editMode={true} />

                    <ContractDetailsItem
                        fieldState={form.note}
                        label={languageStore.get('note')}
                        id='note'
                        editMode={true} />

                </ModalColumn>

                <ModalColumn>

                    <ContractDateItem
                        fieldState={form.handedOut}
                        label={languageStore.get('handedOut')}
                        id='handedOut'
                        editMode={true} />

                    <ContractDateItem
                        fieldState={form.handedIn}
                        label={languageStore.get('handedIn')}
                        id='handedIn'
                        editMode={true} />

                    <SelectUser
                        fieldState={form.accountableUserId}
                        label={languageStore.get('responsible')}
                        id='accountableUserId'
                        editMode={true} />

                    <SelectStaffContractDetailsStatus
                        fieldState={form.staffContractDetailsStatus}
                        editMode={true} />

                </ModalColumn>

            </BaseContractTable>
        )
    }

}

export default Benefits;