import * as React from 'react';
import { observer } from 'mobx-react';
import { FormState, FieldState } from 'formstate';
import { currentServiceContractStore as store, DashboardContractTypeEnum, languageStore } from 'stores';
import SectionEnum from '../SectionEnum';
import BaseContract from 'components/StaffContractDetailsPage/BaseContract';
import ContractDetailsGroup from 'components/ContractDetailsGroup';
import ContractDetailsColumn from 'components/ContractDetailsColumn';
import Location from 'components/Forms/Location';
import ContractDetailsItem from 'components/ContractDetailsItem';
import ResponsibleUser from 'components/Forms/ResponsibleUser';
import { required } from 'helpers/FormValidation';
import { BaseContractTypeEnum } from 'stores/BaseStore';
import ContractType from 'components/Forms/ContractType';
import MainCategory from 'components/Forms/MainCategory';
import Department from 'components/Forms/Department';
import SubCategory from 'components/Forms/SubCategory';
import ContractDateItem from 'components/ContractDateItem';
import { addDaysIfNotSet } from 'helpers/DateHelper';
import Currency from 'components/Forms/Currency';
import SelectTrueFalse from 'components/Forms/SelectTrueFalse';

@observer class Overview extends React.Component {

    form = new FormState({
        locationId: new FieldState(store.contract!.department && store.contract!.department.locationId).validators(required),
        departmentId: new FieldState(store.contract!.departmentId).validators(required),
        contractTypeId: new FieldState(store.contract!.contractTypeId).validators(required),
        categoryId: new FieldState(store.contract!.categoryId).validators(required),
        subCategoryId: new FieldState(store.contract!.subCategoryId).validators(required),
        dealNumber: new FieldState(store.contract!.dealNumber),
        serialNumber: new FieldState(store.contract!.serialNumber),
        description: new FieldState(store.contract!.description),
        responsibleUserId: new FieldState(store.contract!.responsibleUserId).validators(required),

        contractDateCreated: new FieldState(store.contract!.contractDateCreated),
        serviceDate: new FieldState(store.contract!.serviceDate),
        expirationDate: new FieldState(store.contract!.expirationDate),
        expirationNoticeDays: new FieldState(store.contract!.expirationNoticeDays),
        indexAdjustment: new FieldState(store.contract!.indexAdjustment),
        currencyId: new FieldState(store.contract!.currencyId).validators(required),
        contractSum: new FieldState(store.contract!.contractSum),
        serviceNoticeDays: new FieldState(store.contract!.serviceNoticeDays),
        requireDocumentTypes: new FieldState(store.contract!.requireDocumentTypes)
    })

    onExpirationDateChanged = (value: any) => {
        addDaysIfNotSet(value, this.form.$.serviceDate, -90);
    }

    beforeSubmit = () => {
        if (!this.form.$.contractSum.value) {
            this.form.$.contractSum.reset(0);
            store.contract!.contractSum = 0;
        }
    }

    render() {
        const form = this.form.$;
        const editMode = store.isEditMode(SectionEnum.OVERVIEW);

        return (
            <BaseContract
                store={store}
                form={this.form}
                beforeSubmit={() => this.beforeSubmit()}
                showDraftRibbon={store.isDraft}
                sectionEnum={SectionEnum.OVERVIEW}>

                <ContractDetailsGroup title={languageStore.get('overview')}    >
                    <ContractDetailsColumn>

                        <Location
                            store={store}
                            fieldState={form.locationId}
                            editMode={editMode}
                        />

                        <ContractType
                            fieldState={form.contractTypeId}
                            locationId={form.locationId.value}
                            fallbackValue={store.contract!.contractType && store.contract!.contractType.name}
                            editMode={editMode}
                            contractType={BaseContractTypeEnum.SERVICE}
                        />

                        <MainCategory
                            fieldState={form.categoryId}
                            locationId={form.locationId.value}
                            fallbackValue={store.contract!.category && store.contract!.category.name}
                            editMode={editMode}
                            contractType={BaseContractTypeEnum.SERVICE}
                            dashContractType={DashboardContractTypeEnum.SERVICE}

                        />

                        <ContractDetailsItem
                            label={languageStore.get('description')}
                            id='description'
                            fieldState={form.description}
                            editMode={editMode}
                            textArea={true}
                        />

                        <ContractDetailsItem
                            label={languageStore.get('indexAdjustment')}
                            id='indexAdjustment'
                            type='number'
                            fieldState={form.indexAdjustment}
                            editMode={editMode} />

                        <SelectTrueFalse
                            label={languageStore.get('requiredDocuments')}
                            id='requiredDocuments'
                            fieldState={form.requireDocumentTypes}
                            editMode={store.isDraft && editMode} />

                    </ContractDetailsColumn>

                    <ContractDetailsColumn>

                        <Department
                            store={store}
                            locationId={form.locationId.value}
                            fieldState={form.departmentId}
                            fallbackValue={store.contract!.department && store.contract!.department.name}
                            editMode={editMode} />

                        <ContractDetailsItem
                            label={languageStore.get('dealNumber')}
                            id='dealNumber'
                            fieldState={form.dealNumber}
                            editMode={editMode} />

                        <SubCategory
                            fieldState={form.subCategoryId}
                            mainCategoryId={form.categoryId.value}
                            fallbackValue={store.contract!.subCategory && store.contract!.subCategory.name}
                            editMode={editMode} />

                        <ContractDetailsItem
                            label={languageStore.get('serialNumber')}
                            id='serialNumber'
                            fieldState={form.serialNumber}
                            editMode={editMode} />

                        <ResponsibleUser
                            showAlarmWarning={true}
                            fieldState={form.responsibleUserId}
                            fallbackValue={store.contract!.responsibleUser && store.contract!.responsibleUser.fullName}
                            editMode={editMode} />

                        <Currency
                            store={store}
                            fieldState={form.currencyId}
                            editMode={editMode} />

                        <ContractDetailsItem
                            label={languageStore.get('contractSum')}
                            id='contractSum'
                            type='number'
                            fieldState={form.contractSum}
                            editMode={editMode} />

                    </ContractDetailsColumn>

                </ContractDetailsGroup>

                <ContractDetailsGroup title={languageStore.get('dates')}>

                    <ContractDetailsColumn>

                        <ContractDateItem
                            fieldState={form.contractDateCreated}
                            label={languageStore.get('creationDate')}
                            id='createdOn'
                            editMode={editMode} />

                        <ContractDateItem
                            beforeChange={this.onExpirationDateChanged}
                            fieldState={form.expirationDate}
                            label={languageStore.get('expirationDate')}
                            id='expireOn'
                            editMode={editMode} />

                        <ContractDateItem
                            fieldState={form.serviceDate}
                            label={languageStore.get('serviceDate')}
                            id='serviceDate'
                            editMode={editMode} />

                    </ContractDetailsColumn>

                    <ContractDetailsColumn>

                        <div style={{ marginTop: editMode ? '6.5rem' : '5rem' }}>
                            <ContractDetailsItem
                                fieldState={form.expirationNoticeDays}
                                label={languageStore.get('expirationNoticeDays')}
                                id='expirationNoticeDays'
                                editMode={editMode} />

                            <ContractDetailsItem
                                fieldState={form.serviceNoticeDays}
                                label={languageStore.get('serviceNoticeDays')}
                                id='serviceNoticeDays'
                                editMode={editMode} />
                        </div>

                    </ContractDetailsColumn>

                </ContractDetailsGroup>

            </BaseContract>
        )
    }

}

export default Overview;