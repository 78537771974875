import { BaseStore } from './BaseStore';
import { observable, action, makeObservable } from 'mobx';
import { CompanyInterface } from './CompanyStore';
import request from 'api/request';

export class CreateCompanyStore extends BaseStore {
    @observable company: CompanyInterface | null = null;

    constructor() {
        super();

        makeObservable(this);
    }

    @action
    createCompany(company: CompanyInterface) {
        return new Promise(async(resolve, reject) => {
            try {
                const res = await request.post('/companies', company);
                const createdCompany = res.data;
                this.company = createdCompany;
                resolve(createdCompany);
            }
            catch(error) {
                reject(error);
            }
        })
    }

    @action
    duplicateCompany(sourceCompanyId: string, newCompanyName: string) {
        return new Promise(async(resolve, reject) => {
            try {
                const res = await request.post(`/companies/${sourceCompanyId}/duplicate`, {
                    newCompanyName: newCompanyName,
                    emailChanges: []
                });

                const createdCompany = res.data;
                this.company = createdCompany;
                resolve(createdCompany);
            }
            catch(error) {
                reject(error);
            }
        })
    }
}

export const createCompanyStore = new CreateCompanyStore();