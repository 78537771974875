import * as React from 'react';
import { observer } from 'mobx-react';
import { FormState, FieldState } from 'formstate';
import { languageStore, CompanyInterface, navigationStore } from 'stores';
import Input from 'components/Forms/Input';
import { required } from 'helpers/FormValidation';
import StepContainer from 'components/AdminPage/UsersPage/CreateNewUserPage/StepContainer';
import Column from 'components/AdminPage/UsersPage/CreateNewUserPage/StepContainer/Column';
import { createCompanyStore } from 'stores/CreateCompanyStore';
import SelectTrueFalse from 'components/Forms/SelectTrueFalse';
import SelectLanguage from 'components/ProfilePage/Profile/SelectLanguage';

@observer class GeneralInformationStep extends React.Component {

    form = new FormState({
        name: new FieldState('').validators(required),
        hasPurchaseModul: new FieldState(false),
        hasStaffModul: new FieldState(false),
        hasSalesModul: new FieldState(false),
        hasServiceModul: new FieldState(false),
        defaultLanguage: new FieldState(null)
    })

    onSubmit = async () => {
        const form = this.form.$;

        const company = {
            name: form.name.$,
            hasPurchaseModul: form.hasPurchaseModul.$,
            hasStaffModul: form.hasStaffModul.$,
            hasSalesModul: form.hasSalesModul.$,
            hasServiceModul: form.hasServiceModul.$,
            defaultLanguage: form.defaultLanguage.$

        } as CompanyInterface

        const createdCompany = await createCompanyStore.createCompany(company) as CompanyInterface;
        navigationStore.push(`/superadmin/companies/${createdCompany.id}`);
    }

    render() {
        const form = this.form.$;

        return (
            <StepContainer
                backLinkFunc={() => navigationStore.goBack()}
                backlinkTitle={languageStore.get('companies')}
                breadCrumbTitle={languageStore.get('createCompany')}
                title={languageStore.get('createCompany')}
                form={this.form}
                onSubmit={this.onSubmit}>

                <Column>
                    <Input fieldState={form.name} id='email' label={languageStore.get('companyName')} />

                    <h2 className='mt-16'>Modules</h2>
                    <SelectTrueFalse
                        marginTop='10px'
                        marginBottom='0px'
                        id='purchase'
                        label={languageStore.get('purchase')}
                        fieldState={form.hasPurchaseModul}
                        editMode={true} />
                    <SelectTrueFalse
                        marginTop='0px'
                        marginBottom='0px'
                        id='staff'
                        label={languageStore.get('staff')}
                        fieldState={form.hasStaffModul}
                        editMode={true} />
                    <SelectTrueFalse
                        marginTop='0px'
                        marginBottom='0px'
                        id='service'
                        label={languageStore.get('service')}
                        fieldState={form.hasServiceModul}
                        editMode={true} />
                    <SelectTrueFalse
                        marginTop='0px'
                        marginBottom='0px'
                        id='sales'
                        label={languageStore.get('sales')}
                        fieldState={form.hasSalesModul}
                        editMode={true} />
                </Column>
                <Column>
                    <div style={{ marginTop: '-31px' }}>
                        <SelectLanguage hideDefault={true} fieldState={form.defaultLanguage} />
                    </div>

                </Column>

            </StepContainer>
        )
    }

}

export default GeneralInformationStep;