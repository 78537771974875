import * as React from 'react';
import { observer } from 'mobx-react';
import { CurrentContractStore, CurrentContractBaseInterface, transactionsStore, linkInboxStore, inboxStore, toastStore, languageStore, customFieldStore } from 'stores';
import ScrollToAnchor from 'components/ScrollToAnchor';
import ShowOnPrint from 'components/ShowOnPrint';
import { Prompt } from 'react-router-dom';
import { isFormStateDirty } from 'helpers/FormHelper';
import _ from 'lodash';

interface Props {
    contractId: string,
    store: CurrentContractStore<CurrentContractBaseInterface>,
    children: React.ReactNode
}

@observer class ContractDetailsPage extends React.Component<Props> {

    componentDidMount() {
        const { contractId, store } = this.props;

        store.getContract(contractId)
            .then(() => {
                transactionsStore.setRequiresTransactionKey(store.contractEndpoint, contractId);

                if (linkInboxStore.linkInboxIsActive) {
                    store.addDocumentToContract(linkInboxStore.sourceInboxMessage!.document, 'documents', store.contract!.contractDocuments);
                    inboxStore.deleteMessage(linkInboxStore.sourceInboxMessage!.inboxId, linkInboxStore.sourceInboxMessage!.id);
                    linkInboxStore.resetSource();

                    toastStore.addToast({
                        level: 'success',
                        title: languageStore.get('success'),
                        message: languageStore.get('fileAddedToContract')
                    })
                }
            })

        customFieldStore.getHasCustomFields(store.contractType!);
    }

    componentWillUnmount() {
        const { store } = this.props;

        store.resetContract();
        transactionsStore.resetTransactionKey();
    }

    render() {
        const { children, store } = this.props;
        if (!store.contract) return null;

        return (
            <ScrollToAnchor>
                <ShowOnPrint>
                    <Prompt
                        message={(location, action) => {

                            if (!store.isDraft) {
                                return true;
                            }

                            if (location.pathname.startsWith(`/${store.contractEndpoint}`) && location.pathname.length > `/store/${store.contractEndpoint}`.length) {
                                return true;
                            }

                            const anyDirtyForms = _.some(store.submitForms, (f) => isFormStateDirty(f.$));
                            if (!anyDirtyForms) {
                                if (store.isDraftJustCreated) {
                                    const deleteDraft = window.confirm(languageStore.get('draftWillBeDeleted'));
                                    if (deleteDraft) {
                                        store.deleteContract();
                                    } else return false;
                                }
                                return true;
                            }

                            return languageStore.get('unsavedChangesWarning');
                        }}
                    />
                    {children}
                </ShowOnPrint>
            </ScrollToAnchor>
        )
    }

}

export default ContractDetailsPage;