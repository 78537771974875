import { UserInterface, RoleEnum } from 'stores';
import * as _ from 'lodash';

enum RoleTypeEnum {
    PURCHASE = 'PURCHASE',
    HR = 'HR',
    SALES = 'SALES',
    SERVICE = 'SERVICE',
}

export class RolesHelper {

    static GetAvailableRoles(user: UserInterface) {
        let roles: Array<RoleEnum> = [];

        if (this.HasRole(user, RoleEnum.SUPER_ADMIN)) {
            roles = Object.keys(RoleEnum).map((key) => {
                return RoleEnum[key]
            });
        }
        else {
            roles = this.AddSufficientRoles(roles, user, RoleTypeEnum.PURCHASE);
            roles = this.AddSufficientRoles(roles, user, RoleTypeEnum.HR);
            roles = this.AddSufficientRoles(roles, user, RoleTypeEnum.SALES);
            roles = this.AddSufficientRoles(roles, user, RoleTypeEnum.SERVICE);

            if (this.HasRole(user, RoleEnum.INBOX)) {
                roles = [...roles, RoleEnum.INBOX];
            }
        }
        
        return _.uniq(roles);
    }

    static AddSufficientRoles(roles: Array<RoleEnum>, user: UserInterface, type: RoleTypeEnum) {

        if (this.HasRole(user, RoleEnum[`${type}_ADMIN`])) {
            roles = [...roles,
            RoleEnum[`${type}_ADMIN`],
            RoleEnum[`${type}_WRITE`],
            RoleEnum[`${type}_READ`]
            ];
        }
        else {
            if (this.HasRole(user, RoleEnum[`${type}_READ`])) {
                roles = [...roles,
                RoleEnum[`${type}_READ`]
                ];
            }

            if (this.HasRole(user, RoleEnum[`${type}_WRITE`])) {
                roles = [...roles,
                RoleEnum[`${type}_WRITE`],
                RoleEnum[`${type}_READ`]
                ];
            }
        }

        return roles;
    }

    static HasRole(user: UserInterface, role: RoleEnum) {
        const match = _.find(user.roles, { role: role });
        return !!match;
    }

}