enum SectionEnum {
    OVERVIEW = '#overview',
    CUSTOMER = '#customer',
    SPECIAL = '#special',
    SERVICES = '#services',
    NOTES = '#notes',
    EXTRA_ALARMS = '#extra_alarms',
    FILES_CONTRACT = '#files_contract',
    FILES_CORRESPONDENCE = '#files_correspondence',
    VERIFIED_BY = '#verified_by',
    STATUS = '#status',
    LINKED_CONTRACTS = '#linked_contracts',
    CUSTOM_FIELDS = '#custom_fields'
}

export default SectionEnum;