import * as React from 'react';
import { observer } from 'mobx-react';
import { FieldState } from 'formstate';
import { serviceStore, DocumentTypeContractTypeEnum, languageStore, CurrentContractStore } from 'stores';
import { DropdownOptionInterface } from 'components/Forms/Dropdown';
import ContractDropdownItem from 'components/ContractDropdownItem';

interface Props {
    fieldState: FieldState<any>,
    fallbackValue?: string,
    editMode?: boolean,
    store: CurrentContractStore<any>
}

@observer class SelectService extends React.Component<Props> {

    componentDidMount() {
        const { store } = this.props;
        const { currentLocationId } = store;
        if (currentLocationId) {
            serviceStore.getServices(currentLocationId, DocumentTypeContractTypeEnum.SERVICE);
        }
        else {
            serviceStore.resetServices();
        }
    }

    render() {
        const { fieldState, fallbackValue, editMode } = this.props;

        let opts: Array<DropdownOptionInterface> = [];
        serviceStore.services && serviceStore.services.forEach((service) => {
            opts.push({
                value: service.id,
                name: service.name
            })
        })

        return <ContractDropdownItem
            label={languageStore.get('service')}
            id='service'
            placeholderText={languageStore.get('selectService')}
            fieldState={fieldState}
            options={opts}
            editMode={editMode}
            fallbackValue={fallbackValue}
        />
    }

}

export default SelectService