import * as React from 'react';
import { observer } from 'mobx-react';
import logoImg from './logo.svg';
import { ADFSHostEnum } from 'stores';
import ADFS from '../ADFS';

interface State {
    signInError: boolean
}

@observer class JPPolLoginPage extends React.Component<any, State> {

    resource = process.env.REACT_APP_FRONTEND_BASEURL!;
    redirectUri = process.env.REACT_APP_FRONTEND_BASEURL + '/login/jp';
    endpoint = process.env.REACT_APP_ADFS_JP_ENDPOINT;
    clientId = process.env.REACT_APP_ADFS_JP_CLIENT_ID;
    providerId = process.env.REACT_APP_ADFS_JP_PROVIDER_ID!;

    render() {
        return (
            <ADFS
                logoImgSrc={logoImg}
                logoImgAlt='JP|POL'
                loginEndpoint={`${this.endpoint}/adfs/oauth2/authorize?response_type=code&client_id=${this.clientId}&resource=${encodeURIComponent(this.resource)}&redirect_uri=${encodeURIComponent(this.redirectUri)}`}
                adfsHost={ADFSHostEnum.JP_POL}
                redirectUri={this.redirectUri}
                providerId={this.providerId}
            />
        )
    }
}

export default JPPolLoginPage;