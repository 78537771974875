import { ViewByEnum, BaseContractsStore, ContractNoteInterface, ContractStatusEnum } from './BaseContractsStore';

export interface SalesContractInterface {
    "id": string,
    "categoryId": string,
    "categoryName": string,
    "subCategoryId": string,
    "subCategoryName": string,
    "status": ContractStatusEnum,
    "customerId": string,
    "customerName": string,
    "responsibleUserId": string,
    "responsibleUserFullName": string,
    "description": string,
    "contractDateCreated": string,
    "renewalDate": string,
    "expirationDate": string,
    "departmentId": string,
    "departmentName": string,
    "locationId": string,
    "locationName": string,
    "notes": Array<ContractNoteInterface>,
    "hasDocuments": boolean
}

export class SalesContractsStore extends BaseContractsStore<SalesContractInterface> {

    constructor() {
        super();

        this.viewBy = ViewByEnum.CATEGORY;
        this.contractEndpoint = 'sales';
    }

}

export const salesContractsStore = new SalesContractsStore();