import Dropdown from "components/Forms/Dropdown";
import { FieldState, FormState } from "formstate";
import { required } from "helpers/FormValidation";
import _ from "lodash";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { BaseCustomerSupplierInterface, ContactPeopleInterface, customerStore, languageStore, supplierStore } from "stores";

export enum AddByContactTypeEnum {
    SUPPLIER,
    CUSTOMER
}

interface Props {
    onContactSelected: (contact: ContactPeopleInterface) => void
    type: AddByContactTypeEnum,
    onVisibilityToggled?: (value: boolean) => void
}

const AddByContact = observer((props: Props) => {
    const [showAddContact, setShowAddContact] = useState(false);
    const [selectedSource, setSelectedSource] = useState<null | BaseCustomerSupplierInterface>(null);

    const form = new FormState({
        supplierId: new FieldState<null | string>(null),
        contactId: new FieldState<null | string>(null).validators(required),
    })

    const { type } = props;
    useEffect(() => {
        switch (type) {
            case AddByContactTypeEnum.SUPPLIER:
                supplierStore.getSuppliers(false);
                break;
            case AddByContactTypeEnum.CUSTOMER:
                customerStore.getCustomers(false);
                break;
            default: break;
        }
    }, [type])

    const { onVisibilityToggled } = props;
    useEffect(() => {
        if (onVisibilityToggled) {
            onVisibilityToggled(showAddContact);
        }
    }, [showAddContact, onVisibilityToggled])

    const onSourceChanged = (supplierId: string) => {
        const supplier = _.find(getContactPeopleSourceOptions(), (s: BaseCustomerSupplierInterface) => s.id === supplierId) as BaseCustomerSupplierInterface;
        if (supplier) {
            setSelectedSource(supplier);
        }
    }

    const onContactSelected = (contactId: string) => {
        const contact = _.find(selectedSource?.contactPeople, (contact) => contact.id === contactId);

        if (contact) {
            props.onContactSelected(contact);
        }

        setShowAddContact(false);
    }

    const getContactPeopleSourceOptions = () => {
        switch (props.type) {
            case AddByContactTypeEnum.SUPPLIER: return supplierStore.suppliers as Array<BaseCustomerSupplierInterface>;
            case AddByContactTypeEnum.CUSTOMER: return customerStore.customers as Array<BaseCustomerSupplierInterface>;
            default: return [];
        }
    }

    const suppliers = supplierStore.suppliers;
    if (!suppliers) return null;

    return (
        <div>
            {
                !showAddContact &&
                <div className='mb-4 underline text-comablue-label cursor-pointer' onClick={() => setShowAddContact(true)}>+ {languageStore.get('addRecipientFromContacts')}</div>
            }

            {
                showAddContact &&
                <div className="mt-12 mb-16 pt-8 border rounded p-4">
                    <Dropdown
                        fieldState={form.$.supplierId}
                        label={languageStore.get(props.type === AddByContactTypeEnum.CUSTOMER ? 'customer' : 'supplier')}
                        id='supplier'
                        beforeChange={(val: string) => onSourceChanged(val)}
                        options={getContactPeopleSourceOptions().map((entity: BaseCustomerSupplierInterface) => ({
                            value: entity.id,
                            name: entity.name
                        }))}
                    />

                    {
                        selectedSource &&
                        <Dropdown
                            fieldState={form.$.contactId}
                            placeholderText={languageStore.get('selectContactPerson')}
                            label={languageStore.get('contact')}
                            id='contact'
                            beforeChange={onContactSelected}
                            options={selectedSource.contactPeople.map((contact) => ({
                                value: contact.id,
                                name: contact.name
                            }))}
                        />
                    }

                    <div className="text-right text-xs" style={{ marginTop: '-20px' }}>
                        <button
                            type='button'
                            className='underline text-grey-dark'
                            onClick={() => setShowAddContact(false)}>
                            {languageStore.get('cancel')}
                        </button>
                    </div>

                </div>
            }

        </div>
    )
})

export default AddByContact;