import { ViewByEnum, BaseContractsStore, ContractNoteInterface, ContractStatusEnum } from './BaseContractsStore';

export interface ServiceContractInterface {
    "id": string,
    "categoryId": string,
    "categoryName": string,
    "subCategoryId": string,
    "subCategoryName": string,
    "status": ContractStatusEnum,
    "customerId": string,
    "customerName": string,
    "responsibleUserId": string,
    "responsibleUserFullName": string,
    "description": string,
    "contractDateCreated": string,
    "renewalDate": string,
    "expirationDate": string,
    "departmentId": string,
    "departmentName": string,
    "locationId": string,
    "locationName": string,
    "notes": Array<ContractNoteInterface>,
    "hasDocuments": boolean,
    "serialNumber": string,
    "serviceDate": string,
    "address": string,
    "postCode": string,
    "city": string
}

export class ServiceContractsStore extends BaseContractsStore<ServiceContractInterface> {

    constructor() {
        super();

        this.viewBy = ViewByEnum.CATEGORY;
        this.contractEndpoint = 'service';
    }

}

export const serviceContractsStore = new ServiceContractsStore();