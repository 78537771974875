import * as React from 'react';
import { observer } from 'mobx-react';
import { FormState, FieldState } from 'formstate';
import { required } from 'helpers/FormValidation';
import { languageStore, tagStore, userStore, TagInterface } from 'stores';
import TableHeader from 'components/Table/TableHeader';
import * as _ from 'lodash';
import Input from 'components/Forms/Input';
import DetailsPage from '../DetailsPage';
import { ModalColumn } from 'components/Modal';

@observer class TagsPage extends React.Component {

    form = new FormState({
        id: new FieldState(null),
        name: new FieldState('').validators(required),
        companyId: new FieldState(null)
    })

    componentDidMount() {
        tagStore.getTags();
    }

    createTag = (tag: TagInterface) => {
        const companyId = userStore.signedInUser!.company.id;
        tag.companyId = companyId;
        tagStore.createTag(tag);
    }

    deleteTag = (tagId: string) => {
        const tag = _.find(tagStore.tags, { id: tagId }) as TagInterface;
        tagStore.deleteTag(tag);
    }

    render() {
        const form = this.form.$;
        const data = tagStore.tags || [];

        const columns = [
            {
                Header: <TableHeader value={languageStore.get('name')} />,
                accessor: 'name'
            }
        ]

        return (
            <DetailsPage
                fullWidth={true}
                generalTitle={languageStore.get('tags')}
                modalTitle={languageStore.get('tag')}
                form={this.form}
                columns={columns}
                collection={data}
                onUpdate={(tag: TagInterface) => tagStore.updateTag(tag)}
                onSubmit={(tag: TagInterface) => this.createTag(tag)}
                onDelete={(id: string) => this.deleteTag(id)}>

                <ModalColumn>

                    <Input
                        fieldState={form.name}
                        id='name'
                        label={languageStore.get('name')} />

                </ModalColumn>


            </DetailsPage>
        )
    }

}

export default TagsPage;