import { observable, action, makeObservable } from 'mobx';
import { BaseStore } from './BaseStore';
import request from 'api/request';
import * as _ from 'lodash';
import { ContactPeopleInterface, SupplierInterface } from './SupplierStore';
import { UsedInInterface } from './CustomerDetailsStore';

export interface SupplierUsedInInterface {
    "purchaseContracts": UsedInInterface
}

export class SupplierDetailsStore extends BaseStore {
    @observable supplier: SupplierInterface | null = null;
    @observable usedIn: SupplierUsedInInterface | null = null;

    constructor() {
        super();

        makeObservable(this);
    }

    @action
    resetStore() {
        this.supplier = null;
        this.usedIn = null;
    }

    @action
    getUsedIn(supplierId: string) {
        return this.get(`suppliers/${supplierId}/used-in`, 'usedIn');
    }

    @action
    getSupplier(id: string) {
        return this.get(`suppliers/${id}`, 'supplier');
    }

    @action
    postSupplier(supplier: SupplierInterface): Promise<SupplierInterface> {
        return this.post('suppliers', 'suppliers', supplier);
    }

    @action
    putSupplier(supplier: SupplierInterface): Promise<SupplierInterface> {
        return this.put(`suppliers/${supplier.id}`, 'suppliers', supplier);
    }

    @action
    postContact(contact: ContactPeopleInterface): Promise<ContactPeopleInterface> {
        return new Promise(async (resolve, reject) => {
            contact.supplierId = this.supplier?.id;

            try {
                const res = await request.post(`suppliers/${contact.supplierId!}/contacts`, contact);
                const addedContact = res.data;

                if (this.supplier) {
                    this.supplier.contactPeople.push(addedContact);
                }

                resolve(addedContact);
            }
            catch (error) {
                reject(error);
            }

        })

    }

    @action
    putContact(contact: ContactPeopleInterface): Promise<ContactPeopleInterface> {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await request.put(`suppliers/${contact.supplierId!}/contacts/${contact.id}`, contact);
                const updatedContact = res.data;

                if (this.supplier) {
                    const index = _.findIndex(this.supplier.contactPeople, { id: updatedContact.id });
                    if (index !== undefined) {
                        this.supplier.contactPeople.splice(index, 1, updatedContact);
                    }
                }

                resolve(updatedContact);
            }
            catch (error) {
                reject(error);
            }
        })
    }

    @action
    deleteContact(contactId: string) {
        return this.delete(`suppliers/${this.supplier!.id}/contacts/${contactId}`, contactId, this.supplier?.contactPeople || []);
    }
}

export const supplierDetailsStore = new SupplierDetailsStore();
