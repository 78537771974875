import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { companyUserStore, DashboardContractTypeEnum, DepartmentInterface, departmentStore, languageStore, LocationInterface, locationStore, toastStore, UserContractsInterface } from "stores";
import useCompanyUserByParams from "../useCompanyUserByParams";
import _ from 'lodash';
import ReactTable from "react-table";
import TabButton from "./TabButton";

const Contracts = observer(() => {
    const [selectedUserContracts, setSelectedUserContracts] = useState<Array<UserContractsInterface>>([]);
    const [selectedContractType, setSelectedContractType] = useState<DashboardContractTypeEnum>(DashboardContractTypeEnum.PURCHASE);

    const user = useCompanyUserByParams();

    useEffect(() => {
        if (user) {
            companyUserStore.getAvailableUserContracts(user!.companyId, user!.id);
            departmentStore.getDepartmentsByCompany(user!.companyId);
            locationStore.getLocationsByCompany(user!.companyId);
            setSelectedUserContracts(user?.userContracts || []);
        }

        return () => {
            companyUserStore.resetAvailableUserContracts();
        }
    }, [user])

    const deleteFromContract = (e: React.MouseEvent, contract: UserContractsInterface) => {
        e.preventDefault();
        e.stopPropagation();

        setSelectedUserContracts(selectedUserContracts.filter((selectedContract) =>
            (selectedContract.purchaseContractId && selectedContract.purchaseContractId !== contract.purchaseContractId) ||
            (selectedContract.staffContractId && selectedContract.staffContractId !== contract.staffContractId) ||
            (selectedContract.salesContractId && selectedContract.salesContractId !== contract.salesContractId) ||
            (selectedContract.serviceContractId && selectedContract.serviceContractId !== contract.serviceContractId)
        ))
    }

    const addToContract = (e: React.MouseEvent, contract: UserContractsInterface) => {
        e.preventDefault();
        e.stopPropagation();

        const contracts = _.clone(selectedUserContracts);
        contracts.push(contract);

        setSelectedUserContracts(contracts);
    }

    const addDepartment = (e: React.MouseEvent, departmentId: string) => {
        e.preventDefault();
        e.stopPropagation();

        const depContracts = _.filter(companyUserStore.availableUserContracts, (c) => c.departmentId === departmentId);

        setSelectedUserContracts(selectedUserContracts.concat(depContracts));

        toastStore.addToast({
            level: 'success',
            title: languageStore.get('success'),
            message: languageStore.get('added')
        })
    }

    const onSave = async () => {
        await companyUserStore.setUserContracts(user!.companyId, user!.id, selectedUserContracts);

        toastStore.addToast({
            level: 'success',
            title: languageStore.get('success'),
            message: languageStore.get('changesSaved')
        })
    }

    const filterBy = (arrVal: UserContractsInterface, otherVal: UserContractsInterface) => {
        return (arrVal.contractType === otherVal.contractType
            && arrVal.purchaseContractId === otherVal.purchaseContractId
            && arrVal.salesContractId === otherVal.salesContractId
            && arrVal.serviceContractId === otherVal.serviceContractId
            && arrVal.staffContractId === otherVal.staffContractId)
    }

    const expandSubcomponentOnRowClicked = (state: any, rowInfo: any | undefined, col: any, instance: any) => {
        return {
            onClick: async (e: React.MouseEvent) => {
                const { expanded } = state;
                const path = rowInfo.nestingPath[0];
                const diff = { [path]: expanded[path] ? false : true };

                instance.setState({
                    expanded: {
                        ...expanded,
                        ...diff
                    }
                });

            }
        }
    }

    const getContractsBySelectedContractType = () => {
        let availableContracts: Array<UserContractsInterface> = companyUserStore.availableUserContracts;
        const everyContract = _.orderBy(availableContracts, 'identification');

        switch (selectedContractType) {
            case DashboardContractTypeEnum.PURCHASE: return _.filter(everyContract, (c: UserContractsInterface) => c.purchaseContractId !== null);
            case DashboardContractTypeEnum.STAFF: return _.filter(everyContract, (c: UserContractsInterface) => c.staffContractId !== null);
            case DashboardContractTypeEnum.SERVICE: return _.filter(everyContract, (c: UserContractsInterface) => c.serviceContractId !== null);
            case DashboardContractTypeEnum.SALES: return _.filter(everyContract, (c: UserContractsInterface) => c.salesContractId !== null);
        }
    }

    return (
        <div>
            <div className='mb-8 flex items-center'>
                <div className='flex-1'>
                    <TabButton
                        label={languageStore.get('purchase')}
                        onClick={() => setSelectedContractType(DashboardContractTypeEnum.PURCHASE)}
                        isActive={selectedContractType === DashboardContractTypeEnum.PURCHASE}
                        className='mr-2'
                    />
                    <TabButton
                        label={languageStore.get('staff')}
                        onClick={() => setSelectedContractType(DashboardContractTypeEnum.STAFF)}
                        isActive={selectedContractType === DashboardContractTypeEnum.STAFF}
                        className='mr-2'
                    />
                    <TabButton
                        label={languageStore.get('service')}
                        onClick={() => setSelectedContractType(DashboardContractTypeEnum.SERVICE)}
                        isActive={selectedContractType === DashboardContractTypeEnum.SERVICE}
                        className='mr-2'
                    />
                    <TabButton
                        label={languageStore.get('sales')}
                        onClick={() => setSelectedContractType(DashboardContractTypeEnum.SALES)}
                        isActive={selectedContractType === DashboardContractTypeEnum.SALES}
                        className='mr-2'
                    />
                </div>
                <div>
                    <button type="submit" onClick={(e) => onSave()} className="bg-comablue-label hover:bg-comablue text-white py-2 px-8 rounded">
                        {languageStore.get('saveChanges')}
                    </button>
                </div>
            </div>
            <ReactTable
                data={locationStore.locations}
                showPagination={false}
                sortable={false}
                defaultPageSize={99999}
                minRows={0}
                getTrProps={expandSubcomponentOnRowClicked}
                collapseOnDataChange={false}
                columns={[{
                    Header: null,
                    id: 'name',
                    accessor: (d: LocationInterface) => <div className='font-semibold'>{d.name}</div>
                }]}
                SubComponent={row =>
                    <div className='mb-2 ml-6'>
                        <ReactTable
                            data={_.filter(departmentStore.departments, (d) => d.locationId === row.original.id)}
                            showPagination={false}
                            sortable={false}
                            defaultPageSize={99999}
                            collapseOnDataChange={false}
                            minRows={0}
                            getTrProps={expandSubcomponentOnRowClicked}
                            columns={[{
                                Header: null,
                                id: 'departmentId',
                                accessor: (d: DepartmentInterface) => {
                                    return (
                                        <div className="flex">
                                            <div className="flex-1 font-semibold">
                                                {d.name}
                                            </div>
                                        </div>
                                    )
                                }
                            }]}
                            SubComponent={(row: any) => {
                                const data = _.filter(getContractsBySelectedContractType(), (c) => c.departmentId === row.original.id);
                                
                                return (
                                    <div className='mb-8' style={{ marginLeft: '35px' }}>
                                        <div>
                                            <ReactTable
                                                data={data}
                                                showPagination={false}
                                                sortable={false}
                                                defaultPageSize={99999}
                                                minRows={0}
                                                NoDataComponent={() => null}
                                                collapseOnDataChange={false}
                                                columns={[{
                                                    Header: null,
                                                    id: 'departmentId',
                                                    accessor: (contract: UserContractsInterface) => {
                                                        const hasItem = _.filter(selectedUserContracts, (c: UserContractsInterface) => {
                                                            return filterBy(c, contract);
                                                        }).length > 0;

                                                        return (
                                                            <div className="flex">
                                                                <div className="flex-1">
                                                                    <p>{contract.identification}</p>
                                                                    {
                                                                        contract.description &&
                                                                        <p className='font-xs text-grey-dark'>{contract.description}</p>
                                                                    }
                                                                </div>
                                                                <div>
                                                                    {
                                                                        hasItem &&
                                                                        <button type='button' className='ml-2 px-2 py-1 text-xs text-red rounded bg-red-lightest hover:bg-red-lighter' onClick={(e) => deleteFromContract(e, contract)}>{languageStore.get('remove')}</button>
                                                                    }

                                                                    {
                                                                        !hasItem &&
                                                                        <button type='button' className='ml-2 px-2 py-1 text-xs text-green rounded bg-green-lightest hover:bg-green-lighter' onClick={(e) => addToContract(e, contract)}>{languageStore.get('add')}</button>
                                                                    }
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                }]}
                                            />
                                        </div>
                                        {
                                            data.length > 0 &&
                                            <div className='text-right mr-1 mt-4'>
                                                <button type='button' className='ml-2 px-2 py-1 text-xs rounded bg-grey-lightest text-grey-darker' onClick={(e) => addDepartment(e, row.original.id)}>{languageStore.get('addAll')}</button>
                                            </div>
                                        }
                                    </div>
                                )
                            }}
                        />
                    </div>}
            />

        </div>
    )
})

export default Contracts;