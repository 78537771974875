import * as React from 'react';
import { staffContractsStore, ViewByEnum, languageStore, navigationStore } from 'stores';
import { observer } from 'mobx-react';
import ContractsPage, { ContractPageGroupInterface } from 'components/ContractsPage';
import SectionEnum from 'components/StaffContractDetailsPage/SectionEnum';
import { ContractStatusHelper } from 'helpers/ContractStatusHelper';

@observer class StaffContractsPage extends React.Component {

    goto = (section: SectionEnum, contractId: string) => {
        navigationStore.push(`/staff/${contractId}/${section}`)
    }

    render() {

        const groups = [
            {
                title: languageStore.get('alphabetical'),
                enum: ViewByEnum.ALPHABETICAL,
            },
            {
                title: languageStore.get('location'),
                enum: ViewByEnum.LOCATION
            },
            {
                title: languageStore.get('department'),
                enum: ViewByEnum.DEPARTMENT,
            },
            {
                title: languageStore.get('interviews'),
                enum: ViewByEnum.INTERVIEWS,
                onSubRowClicked: (row: any) => this.goto(SectionEnum.APPRAISAL_INTERVIEWS, row.staffContractId),
                disableFilterButtons: true
            },
            {
                title: languageStore.get('equipment'),
                enum: ViewByEnum.EQUIPMENT,
                onSubRowClicked: (row: any) => this.goto(SectionEnum.EQUIPMENT, row.staffContractId),
                disableFilterButtons: true
            },
            {
                title: languageStore.get('perks'),
                enum: ViewByEnum.BENEFITS,
                onSubRowClicked: (row: any) => this.goto(SectionEnum.BENEFITS, row.staffContractId),
                disableFilterButtons: true
            },
            {
                title: languageStore.get('expiration'),
                enum: ViewByEnum.RENEGOTIATION_DATE,
                defaultSortingId: 'renegotiationDate'
            },
        ] as Array<ContractPageGroupInterface>

        const statuses = ContractStatusHelper.StaffContractStatus().map((status) => ({
            title: status.name,
            enum: status.value
        }))

        return (
            <ContractsPage
                store={staffContractsStore}
                groups={groups}
                statuses={statuses}
            />
        )
    }

}

export default StaffContractsPage;