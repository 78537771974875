import * as React from 'react';
import { observer } from 'mobx-react';
import { FormState } from 'formstate';
import * as _ from 'lodash';
import { CurrentContractStore } from 'stores';
import ContractDetailsTable from 'components/ContractDetailsTable';
import { mapField } from 'helpers/FormState';

interface T {
    id: string
}

interface Props {
    form: FormState<any>,
    onUpdate: Function,
    onSubmit: Function,
    onDelete?: Function,
    collection: Array<T>,
    columns: Array<any>,
    tableTitle: string,
    tableSubTitle?: React.ReactNode,
    modalTitle: string,
    store?: CurrentContractStore<any>,
    children: React.ReactNode,
    hash: string,
    beforeSubmit?: Function,
    totalSum?: number,
    onClose?: Function
}

@observer class BaseContractTable extends React.Component<Props> {

    resetFormByValues(values: Array<string>, haystack: any) {
        const form = this.props.form.$;

        values.forEach((value) => {
            form[value].reset(haystack ? haystack[value] : null);
        })
    }

    resetForm = (id: string | null) => {
        const { form, collection } = this.props;
        const entity = id ? _.find(collection, { id: id }) : null;
        this.resetFormByValues(Object.keys(form.$), entity)
    }

    onSubmit = (): Promise<void> => {
        return new Promise(async (resolve, reject) => {
            const { form, onSubmit, beforeSubmit, onUpdate, collection } = this.props;

            const id = form.$.id.$;
            const match = _.find(collection, { id: id });
            let obj = {};
            Object.keys(form.$).forEach((key) => {
                obj[key] = mapField(form.$[key], match ? match[key] : null);
            })

            if (beforeSubmit) {
                beforeSubmit(obj);
            }

            if (id) {
                await onUpdate(obj);
            }
            else {
                await onSubmit(obj);
            }

            resolve();
        })
    }

    render() {
        const { collection, onClose, totalSum, hash, columns, form, tableTitle, tableSubTitle, modalTitle, store, children, onDelete } = this.props;

        return (

            <ContractDetailsTable
                store={store}
                hash={hash}
                totalSum={totalSum}
                title={tableTitle}
                subTitle={tableSubTitle}
                modalTitle={modalTitle}
                columns={columns}
                data={collection.slice()}
                formState={form}
                onShowModal={(id: string | null) => this.resetForm(id)}
                onSubmit={this.onSubmit}
                hideCloseButton={store && !!store.isDraft}
                onDelete={onDelete ? (id: string) => onDelete(id) : undefined }
                onHideModal={onClose}>

                {children}

            </ContractDetailsTable>
        )
    }

}

export default BaseContractTable;