import { observable, action, makeObservable } from 'mobx';
import { BaseStore } from './BaseStore';
import { DepartmentInterface } from './DepartmentStore';

export interface VerificationInterface {
    "contractType": number
    "department": DepartmentInterface
    "departmentId": string,
    "locationId": string,
    "id": string
}


export class VerificationStore extends BaseStore {
    @observable verifications: Array<VerificationInterface> = [];

    constructor() {
        super();

        makeObservable(this);
    }

    @action
    getVerifications(force: boolean = false) {
        this.get('verifications', 'verifications', force);
    }

    @action
    createVerification(verification: VerificationInterface) {
        return this.create(`locations/${verification.locationId}/verifications`, verification, this.verifications);
    }

    @action
    updateVerification(verification: VerificationInterface) {
        return this.update(`locations/${verification.locationId}/verifications/${verification.id}`, verification, this.verifications);
    }

    @action
    deleteVerification(locationId: string, verificationId: string) {
        return this.delete(`locations/${locationId}/verifications/${verificationId}`, verificationId, this.verifications);
    }

    @action
    resetStore() {
        this.verifications = [];
    }
}

export const verificationStore = new VerificationStore();