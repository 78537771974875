import * as React from 'react';
import { observer } from 'mobx-react';
import { FormState, FieldState } from 'formstate';
import { languageStore, CompanyInterface, navigationStore } from 'stores';
import Input from 'components/Forms/Input';
import { required } from 'helpers/FormValidation';
import StepContainer from 'components/AdminPage/UsersPage/CreateNewUserPage/StepContainer';
import Column from 'components/AdminPage/UsersPage/CreateNewUserPage/StepContainer/Column';
import { createCompanyStore } from 'stores/CreateCompanyStore';
import SelectCompany from 'components/SelectCompany';

@observer class DuplicateCompanyStep extends React.Component {

    form = new FormState({
        newCompanyName: new FieldState('').validators(required),
        sourceCompanyId: new FieldState('').validators(required)
    })

    onSubmit = async () => {
        const form = this.form.$;

        const createdCompany = await createCompanyStore.duplicateCompany(form.sourceCompanyId.$, form.newCompanyName.$) as CompanyInterface;
        navigationStore.push(`/superadmin/companies/${createdCompany.id}`);
    }

    render() {
        const form = this.form.$;

        return (
            <StepContainer
                backLinkFunc={() => navigationStore.goBack()}
                backlinkTitle={languageStore.get('companies')}
                breadCrumbTitle={languageStore.get('duplicateCompany')}
                title={languageStore.get('duplicateCompany')}
                form={this.form}
                onSubmit={this.onSubmit}>

                <Column>
                    <SelectCompany 
                        label={languageStore.get('originalCompany')}
                        id='selectCompany'
                        fieldState={form.sourceCompanyId}  />
                    <Input fieldState={form.newCompanyName} id='email' label={languageStore.get('newCompanyName')} />
                </Column>
            </StepContainer>
        )
    }

}

export default DuplicateCompanyStep;