import * as React from 'react';
import { observer } from 'mobx-react';
import { DashboardContractTypeEnum, DocumentSigningStatusEnum, languageStore, userStore } from 'stores';
import ExpireRenewalWidget from './ExpireRenewalWidget';
import Drafts from './Drafts';
import ForecastWidget from './ForecastWidget';
import SelectedContractType from 'components/SelectedContractType';
import ShowOnPrint from 'components/ShowOnPrint';
import Signings from './Signings';
import ActiveExpiredDoughnutWidgets from './ActiveExpiredDoughnutWidgets';
import DistributionDoughnutWidget from './DistributionDoughnutWidget';

interface State {
    contractType: DashboardContractTypeEnum | null
}

@observer class OverviewPage extends React.Component<any, State> {

    constructor(props: any) {
        super(props);

        this.state = {
            // contractType: DashboardContractTypeEnum.PURCHASE
            contractType: null
        }
    }

    onContractTypeChanged = (value: DashboardContractTypeEnum) => {
        this.setState({
            contractType: value
        })
    }

    render() {
        const { contractType } = this.state;

        return (
            <div>
                <div className='mb-10'>
                    <SelectedContractType
                        activeContractType={contractType}
                        onChange={this.onContractTypeChanged} />

                </div>

                {
                    contractType &&
                    <>

                        <ShowOnPrint compact={true}>
                            <div className='flex'>
                                <div className='w-1/3'>
                                    <ExpireRenewalWidget contractType={contractType} />
                                    {
                                        userStore.signedInUser.company.allowAddo &&
                                        <>
                                            <Signings
                                                title={languageStore.get('signingStatuses')}
                                                contractType={contractType}
                                                signingStatus={DocumentSigningStatusEnum.Started}
                                            />
                                            <Signings
                                                title={languageStore.get('signingsRejected')}
                                                contractType={contractType}
                                                signingStatus={DocumentSigningStatusEnum.Rejected}
                                            />
                                        </>
                                    }
                                    <Drafts contractType={contractType} />
                                </div>
                                <div className='w-2/3'>
                                    <div className='w-1/1 flex' style={{ marginTop: '34px' }}>
                                        <div className='w-1/3'>
                                            <DistributionDoughnutWidget contractType={contractType} />
                                        </div>
                                        {
                                            contractType !== DashboardContractTypeEnum.STAFF &&
                                            <ActiveExpiredDoughnutWidgets contractType={contractType} />
                                        }
                                    </div>
                                    {
                                        (contractType === DashboardContractTypeEnum.PURCHASE || contractType === DashboardContractTypeEnum.SALES) &&
                                        <div className='w-1/1'>
                                            <ForecastWidget contractType={contractType} />
                                        </div>
                                    }
                                </div>
                            </div>
                        </ShowOnPrint>
                    </>
                }

            </div>

        );
    }

}

export default OverviewPage;