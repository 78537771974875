import * as React from 'react';
import { observer } from 'mobx-react';
import Form from 'components/Forms/Form';
import { FormState, FieldState } from 'formstate';
import { currentPurchaseContractStore as store, languageStore, SupplierInterface, ContactPeopleInterface, supplierStore } from 'stores';
import ContractSection from 'components/ContractSection';
import SectionEnum from '../SectionEnum';
import ContractDetailsGroup from 'components/ContractDetailsGroup';
import ContractDetailsColumn from 'components/ContractDetailsColumn';
import SelectedSupplier from './SelectedSupplier.tsx';
import * as _ from 'lodash';
import SelectedContact from './SelectedContact';
import { mapField } from 'helpers/FormState';
import SupplierName from './SupplierName';
import VatNumber from './VatNumber';
import SelectedContactDetails from './SelectedContactDetails';
import { required, email } from 'helpers/FormValidation';
import CustomerField from 'components/Forms/Customer/CustomerField';
import { CVRApiInterface, getCVRdetails } from 'helpers/CVRApiHelper';
import Country from 'components/Forms/Country';

@observer class Supplier extends React.Component {

    form = new FormState({
        supplierId: new FieldState(store.contract!.supplierId),
        supplierName: new FieldState(store.contract!.supplier && store.contract!.supplier.name).validators(required),
        supplierNumber: new FieldState(store.contract!.supplier && store.contract!.supplier.supplierNumber),
        vat: new FieldState(store.contract!.supplier && store.contract!.supplier.vat),
        address: new FieldState(store.contract!.supplier && store.contract!.supplier.address),
        zipCode: new FieldState(store.contract!.supplier && store.contract!.supplier.zipCode),
        city: new FieldState(store.contract!.supplier && store.contract!.supplier.city),
        url: new FieldState(store.contract!.supplier && store.contract!.supplier.url),
        countryId: new FieldState(store.contract!.supplier && store.contract!.supplier.countryId),
        contact: new FormState({
            id: new FieldState(store.contract!.contactPeople && store.contract!.contactPeople[0].id),
            name: new FieldState(store.contract!.contactPeople && store.contract!.contactPeople[0].name),
            title: new FieldState(store.contract!.contactPeople && store.contract!.contactPeople[0].title),
            email: new FieldState<string | null>(store.contract!.contactPeople && store.contract!.contactPeople[0].email).validators(email),
            phone: new FieldState(store.contract!.contactPeople && store.contract!.contactPeople[0].phone),
            mobile: new FieldState(store.contract!.contactPeople && store.contract!.contactPeople[0].mobile),
        })
    })

    contactHasFieldsForSaving() {
        const contact = this.form.$.contact.$;

        return (
            !!contact.name.$ ||
            !!contact.title.$ ||
            !!contact.email.$ ||
            !!contact.phone.$ ||
            !!contact.mobile.$
        )
    }

    onSubmit = (): Promise<void> => {
        return new Promise(async (resolve, reject) => {
            const res = await this.form.validate();
            if (res.hasError) {
                reject();
                return;
            }

            const form = this.form.$;
            const contract = store.contract!;
            const isNewSupplier = !!!form.supplierId.$;

            let supplier: SupplierInterface = {
                id: isNewSupplier ? null : mapField(form.supplierId, contract.supplierId),
                name: mapField(form.supplierName, contract.supplier && contract.supplier.name),
                vat: mapField(form.vat, contract.supplier && contract.supplier.vat),
                address: mapField(form.address, contract.supplier && contract.supplier.address),
                zipCode: mapField(form.zipCode, contract.supplier && contract.supplier.zipCode),
                city: mapField(form.city, contract.supplier && contract.supplier.city),
                url: mapField(form.url, contract.supplier && contract.supplier.url),
                countryId: mapField(form.countryId, contract.supplier && contract.supplier.countryId),
                supplierNumber: mapField(form.supplierNumber, contract.supplier && contract.supplier.supplierNumber),
                contactPeople: []
            }

            const isNewContact = isNewSupplier || !!!form.contact.$.id.$;
            let contact: ContactPeopleInterface = {
                id: isNewSupplier || isNewContact ? null : form.contact.$.id.$,
                name: form.contact.$.name.$,
                title: form.contact.$.title.$,
                email: !!form.contact.$.email.$ ? form.contact.$.email.$ : null,
                phone: form.contact.$.phone.$,
                mobile: form.contact.$.mobile.$,
            }

            // Add or update supplier backend
            if (isNewSupplier) {
                const addedSupplier = await supplierStore.postSupplier(supplier);
                supplier.id = addedSupplier.id;
            }
            else {
                supplierStore.putSupplier(supplier);
            }

            // Link supplier to contract
            if (isNewSupplier || supplier.id !== contract.supplierId) {
                store.contract!.supplier = supplier;
                store.contract!.supplierId = supplier.id!;

                // If store is draft, every onSubmit function on page
                // will be added to a Promise.all function, and afterwards
                // the contract will be saved. So we only need to save in this function
                // if the contract is not in draft mode, where one button should save all promises.
                if (!store.isDraft) {
                    await store.putContract(store.contract!);
                }

            }

            // Link contact to supplier
            contact.supplierId = supplier.id;
            let shouldAddContactToContract = true;

            if (isNewContact) {
                if (this.contactHasFieldsForSaving()) {
                    const addedContact = await supplierStore.postContact(contact);
                    contact.id = addedContact.id;
                }
                else {
                    shouldAddContactToContract = false;
                }
            }
            else {
                supplierStore.putContact(contact);
            }

            // Finally add contact to the contract active on screen
            if (shouldAddContactToContract && !_.find(store.contract!.contactPeople, { id: contact.id })) {
                store.addContactPersonToContract(contact!);
            }

            store.setEditMode(SectionEnum.SUPPLIER, false);
            resolve();
        })
    }

    componentDidMount() {
        if (store.isDraft) {
            store.addSubmitPromise(this.onSubmit);
            store.addSubmitForm(this.form);
        }

        // validate form on first render, to highlight required fields.
        this.form.validate();
    }

    onReset = () => {
        store.setEditMode(SectionEnum.SUPPLIER, false);
    }

    onVatBlur = async (vatValue: string) => {
        const form = this.form.$;

        if (vatValue.length === 8) {
            const existingSupplier = _.find(supplierStore.suppliers, { vat: vatValue });
            if (existingSupplier && existingSupplier.id !== form.supplierId.$) {
                if (window.confirm(languageStore.get('CVRAlreadyInUseUseInstead'))) {
                    form.supplierId.onChange(existingSupplier.id!);
                    return;
                }
            }

            const res = await getCVRdetails(vatValue) as CVRApiInterface;
            if (res && res.name !== form.supplierName.value) {
                if (window.confirm(languageStore.get('confirmCVRApi'))) {
                    form.supplierName.onChange(res.name);
                    form.address.onChange(res.address);
                    form.zipCode.onChange(res.zipcode);
                    form.city.onChange(res.city);
                }
            }
        }
    }

    render() {
        const form = this.form.$;
        const editMode = store.isEditMode(SectionEnum.SUPPLIER);

        const emailComponent = <SelectedContactDetails
            label={languageStore.get('email')}
            id='contactPersonEmail'
            fieldState={form.contact.$.email}
            editMode={editMode}
            supplierId={form.supplierId.value}
            contactId={form.contact.$.id.value!}
            onUpdateFieldState={(contact: ContactPeopleInterface) => {
                form.contact.$.email.onChange(contact ? contact.email : '');
            }} />

        const phoneComponent = <SelectedContactDetails
            label={languageStore.get('phone')}
            id='contactPersonPhone'
            fieldState={form.contact.$.phone}
            editMode={editMode}
            supplierId={form.supplierId.value}
            contactId={form.contact.$.id.value!}
            onUpdateFieldState={(contact: ContactPeopleInterface) => {
                form.contact.$.phone.onChange(contact ? contact.phone : '');
            }} />

        const mobileComponent = <SelectedContactDetails
            label={languageStore.get('mobile')}
            id='contactPersonMobile'
            fieldState={form.contact.$.mobile}
            editMode={editMode}
            supplierId={form.supplierId.value}
            contactId={form.contact.$.id.value!}
            onUpdateFieldState={(contact: ContactPeopleInterface) => {
                form.contact.$.mobile.onChange(contact ? contact.mobile : '');
            }} />

        const vatNumberComponent = <VatNumber
            supplierId={form.supplierId.value}
            fieldState={form.vat}
            onBlur={this.onVatBlur}
            editMode={editMode} />

        const supplierNumberComponent = <CustomerField
            customerId={form.supplierId.value}
            fieldState={form.supplierNumber}
            field='supplierNumber'
            label={languageStore.get('supplierNumber')}
            editMode={editMode}
            collection={supplierStore.suppliers}
        />

        const addressComponent = <CustomerField
            customerId={form.supplierId.value}
            fieldState={form.address}
            field='address'
            label={languageStore.get('address')}
            editMode={editMode}
            collection={supplierStore.suppliers}
        />

        const zipCodeComponent = <CustomerField
            customerId={form.supplierId.value}
            fieldState={form.zipCode}
            field='zipCode'
            label={languageStore.get('zipCode')}
            editMode={editMode}
            collection={supplierStore.suppliers}
        />

        const cityComponent = <CustomerField
            customerId={form.supplierId.value}
            fieldState={form.city}
            field='city'
            label={languageStore.get('city')}
            editMode={editMode}
            collection={supplierStore.suppliers}
        />

        const urlComponent = <CustomerField
            customerId={form.supplierId.value}
            fieldState={form.url}
            field='url'
            label={languageStore.get('url')}
            editMode={editMode}
            collection={supplierStore.suppliers}
            onClick={() => window.open(form.url.value?.startsWith('http') ? form.url.value : `http://${form.url.value}`)}
        />

        const countryComponent = <Country
            fieldState={form.countryId}
            editMode={editMode} 
        />

        return (
            <Form onValidSubmit={this.onSubmit} onReset={this.onReset} formState={this.form}>
                <ContractSection
                    canEdit={store.canEdit}
                    hash={SectionEnum.SUPPLIER}
                    onEdit={() => store.setEditMode(SectionEnum.SUPPLIER, !editMode)}
                    editMode={editMode}
                    hideSaveButton={!!store.isDraft}>

                    <ContractDetailsGroup title={languageStore.get('supplier')}>

                        {
                            editMode &&
                            <ContractDetailsColumn>
                                <SelectedSupplier fieldState={form.supplierId} fallbackValue={store.contract!.supplier && store.contract!.supplier.name} editMode={editMode} />
                            </ContractDetailsColumn>
                        }

                        <ContractDetailsColumn>

                            {editMode && vatNumberComponent}

                            <SupplierName
                                supplierId={form.supplierId.value}
                                fieldState={form.supplierName}
                                editMode={editMode} />

                            {
                                editMode &&
                                <>
                                    {supplierNumberComponent}
                                    {addressComponent}
                                    {zipCodeComponent}
                                    {cityComponent}
                                    {countryComponent}
                                    {urlComponent}
                                </>
                            }

                        </ContractDetailsColumn>

                        {
                            !editMode &&
                            <ContractDetailsColumn>
                                {vatNumberComponent}
                                {supplierNumberComponent}
                                {addressComponent}
                                {zipCodeComponent}
                                {cityComponent}
                                {countryComponent}
                                {urlComponent}
                            </ContractDetailsColumn>
                        }

                    </ContractDetailsGroup>

                    <ContractDetailsGroup title={languageStore.get('contactInformation')}>
                        {
                            editMode &&
                            <ContractDetailsColumn>
                                <SelectedContact
                                    fieldState={form.contact.$.id}
                                    supplierId={form.supplierId.value}
                                    fallbackValue={store.contract!.contactPeople && store.contract!.contactPeople[0].name}
                                    editMode={editMode} />
                            </ContractDetailsColumn>
                        }

                        <ContractDetailsColumn>
                            <SelectedContactDetails
                                label={languageStore.get('name')}
                                id='contactPersonName'
                                fieldState={form.contact.$.name}
                                editMode={editMode}
                                supplierId={form.supplierId.value}
                                contactId={form.contact.$.id.value!}
                                onUpdateFieldState={(contact: ContactPeopleInterface) => {
                                    form.contact.$.name.onChange(contact ? contact.name : '');
                                }} />

                            <SelectedContactDetails
                                label={languageStore.get('title')}
                                id='contactPersonTitle'
                                fieldState={form.contact.$.title}
                                editMode={editMode}
                                supplierId={form.supplierId.value}
                                contactId={form.contact.$.id.value!}
                                onUpdateFieldState={(contact: ContactPeopleInterface) => {
                                    form.contact.$.title.onChange(contact ? contact.title : '');
                                }} />

                            {
                                editMode &&
                                <>
                                    {emailComponent}
                                    {phoneComponent}
                                    {mobileComponent}
                                </>
                            }

                        </ContractDetailsColumn>

                        {
                            !editMode &&
                            <ContractDetailsColumn>
                                {emailComponent}
                                {phoneComponent}
                                {mobileComponent}
                            </ContractDetailsColumn>
                        }

                    </ContractDetailsGroup>

                </ContractSection>
            </Form>
        )

    }

}

export default Supplier;