import * as React from 'react';
import { NotificationInterface, NotificationFieldEnum, languageStore, navigationStore, ContractTypeEnum, notificationStore, userStore } from 'stores';
import { ExpandIconItemInterface } from '../ExpandIcon';
import * as Moment from 'moment-timezone';

import { MdAlarm } from 'react-icons/md';
import { MdInfo } from 'react-icons/md';
import { MdLabel } from 'react-icons/md';
import { formatDate } from 'helpers/DateHelper';

export function notificationItemsToExpandIcons(notifications: Array<NotificationInterface>) {
    let items: Array<ExpandIconItemInterface> = [];

    notifications.forEach((notification: NotificationInterface) => {
        items.push(notificationToExpandIcon(notification));
    });

    return items;
}

function notificationToExpandIcon(notification: NotificationInterface): ExpandIconItemInterface {

    const notificationType = notification.alarmType;

    return {
        icon: getIcon(notificationType),
        text: getText(notification),
        subText: formatDate(notification.createdOn, true),
        onClick: getOnClick(notification),
        className: getClassName(notification),
        bold: getBold(notification)
    } as ExpandIconItemInterface;

}

function contractTypeToEndpoint(contractType: ContractTypeEnum) {
    switch (contractType) {
        case ContractTypeEnum.PURCHASE_CONTRACT: return 'purchase';
        case ContractTypeEnum.STAFF_CONTRACT: return 'staff';
        case ContractTypeEnum.SALES_CONTRACT: return 'sales';
        case ContractTypeEnum.SERVICE_CONTRACT: return 'service';
    }
}

function getClassName(notification: NotificationInterface) {

    let className = '';

    // Location has not been seen
    if (!!!notification.seenDate) {
        className += 'bg-grey-lighter';
    }

    return className;
}

function getBold(notification: NotificationInterface) {
    const lastSeenUser = Moment.utc(userStore.signedInUser.lastSeenNotifications);
    return lastSeenUser.isBefore(notification.createdOn);
}

function getOnClick(notification: NotificationInterface) {

    let contractType = contractTypeToEndpoint(notification.entityType);

    return async () => {
        notification.seenDate = Moment.utc().toISOString();
        await notificationStore.updateNotification(notification);

        navigationStore.push(`/${contractType}/${notification.entityId}`, () => {
            window.location.reload();
        });
    }
}

function getText(notification: NotificationInterface) {
    switch (notification.alarmType) {
        case NotificationFieldEnum.ALARM:
            return notification.emailBody || getAlarmText(notification.note);

        case NotificationFieldEnum.RENEWAL:
            return notification.emailBody || languageStore.get('contractToBeRenewed');

        case NotificationFieldEnum.EXPIRATION:
            return notification.emailBody || languageStore.get('contractWillExpire');

        case NotificationFieldEnum.SERVICE_SERVICEDATE:
            return notification.emailBody || languageStore.get('upcomingServiceDate');

        case NotificationFieldEnum.STAFF_BIRTHDAY:
            return notification.emailBody || languageStore.get('upcomingBirthday');

        case NotificationFieldEnum.STAFF_RENEGOTIATION:
            return notification.emailBody || languageStore.get('contractToBeRenegotiated');

        case NotificationFieldEnum.STAFF_SENIORITY:
            return notification.emailBody || languageStore.get('upcomingAnniversary');

        default:
            return notification.emailBody || '-';
    }

}

function getAlarmText(note?: string) {
    if (!note) {
        return languageStore.get('contractAlarm');
    }
    else {
        return languageStore.get('contract') + ": " + note;
    }
}

function getIcon(notificationType: NotificationFieldEnum): React.ReactNode {
    return null; // Temporarily disable icons

    // eslint-disable-next-line no-unreachable
    const style = {
        width: '14px',
        height: '14px',
    } as React.CSSProperties;

    switch (notificationType) {
        case NotificationFieldEnum.ALARM:
            return <MdAlarm style={style} />

        case NotificationFieldEnum.EXPIRATION:
            return <MdLabel style={style} />

        case NotificationFieldEnum.RENEWAL:
            return <MdInfo style={style} />

        default:
            return <MdInfo style={style} />
    }
}