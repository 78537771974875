import * as React from 'react';
import { observer } from 'mobx-react';
import { Modal, ModalColumn } from 'components/Modal';
import { languageStore, transactionsStore, TransactionDetailsInterface, TransactionInterface, AuditInterface, AuditActionEnum, AuditChangeInterface, CurrentContractStore, DashboardContractTypeEnum } from 'stores';
import * as _ from 'lodash';
import { formatDate } from 'helpers/DateHelper';
import { formatNote, formatAlarm, formatCategory, formatContactPerson, formatContractContactPerson, formatFinanceAccount, formatCustomer, formatDocument, formatFinancialService, formatBaseContract, formatServiceContractService, formatSkill, formatStaffAppraisalInterview, formatStaffBenefit, formatEducation, formatEquipment, formatStaffSkill, formatSubCategory, formatSupplier, formatTag } from 'helpers/TransactionHelper';
import { ContractStatusHelper } from 'helpers/ContractStatusHelper';

interface Props {
    store: CurrentContractStore<any>,
    type: DashboardContractTypeEnum
}

interface State {
    showModal: boolean,
    transactionId?: string | null
}

@observer class ContractTransactions extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            showModal: false,
            transactionId: null
        }
    }

    showModal = () => {
        const { store } = this.props;
        const contractId = store.contract!.id;
        transactionsStore.getTransactions(contractId, store.contractEndpoint);

        this.setState({ showModal: true });
    }

    hideModal = () => {
        this.setState({ showModal: false, transactionId: null });

        transactionsStore.resetStore();
    }

    showTransactionDetails = (e: React.MouseEvent, transactionId: string) => {
        e.preventDefault();

        const { store } = this.props;
        const contractId = store.contract!.id;
        transactionsStore.getTransactionDetails(store.contractEndpoint, contractId, transactionId);

        this.setState({
            transactionId: transactionId
        })
    }

    getStatusByContractType = (contractStatusNumber: number) => {
        const { type } = this.props;

        let data = ContractStatusHelper.BasicContractStatus(true);

        if (type === DashboardContractTypeEnum.STAFF) {
            data = ContractStatusHelper.StaffContractStatus(true);
        }

        const result = _.find(data, d => d.value === contractStatusNumber);
        if (!result) return '-';

        return result.name;
    }

    private readableLogUpdated(change: AuditChangeInterface) {
        let logText = `${languageStore.get('updated')} `;

        if (change.propName.toLowerCase() === 'contractstatus') {
            const originalValue = this.getStatusByContractType(Number(change.originalValue));
            const newValue = this.getStatusByContractType(Number(change.newValue));
            logText += `${change.propName} ${originalValue ? 'from ' + originalValue : ''} to ${newValue}`;
        }
        else if (change.propName.endsWith('Id')) {
            logText += `${change.originalIdentification || change.propName.slice(0, -2)} to ${change.newIdentification}`;
        }
        else {
            logText += `${change.propName} ${change.originalValue ? 'from ' + change.originalValue : ''} to ${change.newValue}`;
        }

        return logText;
    }

    private readableLogInsertedRemoved(collection: string, entity: any) {
        let logText = null;

        switch (collection) {
            case 'alarmAudits':
                logText = formatAlarm(entity);
                break;
            case 'categoryAudits':
                logText = formatCategory(entity);
                break;
            case 'contactPersonAudits':
                logText = formatContactPerson(entity);
                break;
            case 'contractContactPersonAudits':
                logText = formatContractContactPerson();
                break;
            case 'noteAudits':
                logText = formatNote(entity);
                break;
            case 'contractFinanceAccountAudits':
                logText = formatFinanceAccount(entity);
                break;
            case 'customerAudits':
                logText = formatCustomer(entity);
                break;
            case 'documentAudits':
                logText = formatDocument(entity);
                break;
            case 'financialServiceAudits':
                logText = formatFinancialService(entity);
                break;
            case 'purchaseContractAudits':
            case 'salesContractAudits':
            case 'serviceContractAudits':
            case 'staffContractAudits':
                logText = formatBaseContract();
                break;
            case 'serviceContractServiceAudits':
                logText = formatServiceContractService(entity);
                break;
            case 'skillAudits':
                logText = formatSkill(entity);
                break;
            case 'staffAppraisalInterviewAudits':
                logText = formatStaffAppraisalInterview(entity);
                break;
            case 'staffBenefitAudits':
                logText = formatStaffBenefit(entity);
                break;
            case 'staffEducationAudits':
                logText = formatEducation(entity);
                break;
            case 'staffEquipmentAudits':
                logText = formatEquipment(entity);
                break;
            case 'staffSkillAudits':
                logText = formatStaffSkill();
                break;
            case 'subCategoryAudits':
                logText = formatSubCategory(entity);
                break;
            case 'supplierAudits':
                logText = formatSupplier(entity);
                break;
            case 'contractTagAudits':
                logText = formatTag(entity);
        }

        return logText;
    }

    readableDetails(details: TransactionDetailsInterface) {

        let entries: React.ReactNodeArray = [];

        Object.keys(details).forEach((key: string, headIndex) => {
            const entry = details[key];
            if (entry instanceof Array) {
                entry.forEach((val, subIndex) => {
                    const auditEntry = val as AuditInterface;
                    let logText = null;

                    const index = `${headIndex}-${subIndex}`;

                    switch (auditEntry.action) {
                        case AuditActionEnum.UPDATED:
                            const changesArray = JSON.parse(auditEntry.changes!);

                            changesArray && changesArray.forEach((change: AuditChangeInterface, changeIndex: number) => {
                                logText = this.readableLogUpdated(change);
                                entries.push(this.addLogEntry(logText, `${index}-${changeIndex}`));
                            })

                            break;

                        case AuditActionEnum.INSERTED:
                            logText = `${languageStore.get('added')} `;
                            logText += this.readableLogInsertedRemoved(key, val);
                            entries.push(this.addLogEntry(logText, index));
                            break;

                        case AuditActionEnum.DELETED:
                            logText = `${languageStore.get('deleted')} `;
                            logText += this.readableLogInsertedRemoved(key, val);
                            entries.push(this.addLogEntry(logText, index));
                            break;

                        case AuditActionEnum.ARCHIVED:
                            logText = `${languageStore.get('archived')} `;
                            logText += this.readableLogInsertedRemoved(key, val);
                            entries.push(this.addLogEntry(logText, index));
                            break;
                    }

                });
            }
        })

        return entries;
    }

    addLogEntry(logText: string, index: number | string) {
        return <p className='text-sm mb-2' key={index}>{logText}.</p>
    }

    render() {
        const { store } = this.props;
        if (store.isDraft) return null;
        
        const { showModal, transactionId } = this.state;
        const transactions = transactionsStore.transactions;
        const tDetails = transactionId ? _.find(transactionsStore.transactionDetails, { transactionId: transactionId }) as TransactionDetailsInterface : null;

        return (
            <div>

                <div className='text-right'>
                    <button onClick={this.showModal} className='text-xs mt-4 border py-2 px-4 rounded hover:bg-grey-lightest'>
                        {languageStore.get('viewHistory')}
                </button>
                </div>


                {
                    showModal &&
                    <Modal
                        title={languageStore.get('history')}
                        secondaryButton={{ title: languageStore.get('close'), onClick: this.hideModal }}>

                        <ModalColumn>
                            <ul className='list-reset overflow-y-scroll'>
                                {
                                    transactions.map((transaction: TransactionInterface, index: number) => {
                                        const date = formatDate(transaction.createdOn, true);
                                        const isActive = transactionId === transaction.transactionId;
                                        const activeClass = 'no-underline text-black font-bold text-sm';
                                        const inactiveClass = 'no-underline text-grey-darker cursor-pointer text-sm';

                                        return <li
                                            key={index}
                                            className='mb-2'>
                                            <a href=' ' className={isActive ? activeClass : inactiveClass}
                                                onClick={(e) => this.showTransactionDetails(e, transaction.transactionId)}>
                                                {date} <br />{transaction.createdBy.fullName}
                                            </a>
                                        </li>
                                    })
                                }
                            </ul>
                        </ModalColumn>
                        <ModalColumn>
                            {
                                tDetails && this.readableDetails(tDetails)
                            }
                        </ModalColumn>

                    </Modal>

                }

            </div>

        )
    }

}

export default ContractTransactions;