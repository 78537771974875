import * as React from 'react';
import { observer } from 'mobx-react';
import UserInformationStep from './UserInformationStep';
import { navigationStore, createUserStore, userStore } from 'stores';
import AccessRightsStep from './AccessRightsStep';
import LocationRightsStep from './LocationRightsStep';
import DepartmentRightsStep from './DepartmentRightsStep';

enum StepEnum {
    USER_INFORMATION = 0,
    ACCESS_RIGHTS = 1,
    LOCATION_RIGHTS = 2,
    DEPARTMENT_RIGHTS = 3
}

interface State {
    step: StepEnum,
    locationIds: Array<string> | null
}

@observer class CreateNewUserPage extends React.Component<any, State> {

    constructor(props: any) {
        super(props);

        this.state = {
            step: StepEnum.USER_INFORMATION,
            locationIds: null
        }
    }

    componentDidMount() {
        const { step } = this.state;

        if (step > StepEnum.USER_INFORMATION && !!!createUserStore.user) {
            this.setState({ step: StepEnum.USER_INFORMATION });
        }
    }

    nextStep = (locationIds: Array<string> | undefined) => {
        const { step } = this.state;

        let nextStep;

        if (step > StepEnum.USER_INFORMATION && !!!createUserStore.user) {
            nextStep = StepEnum.USER_INFORMATION;
            this.setState({ locationIds: null })
        }
        else if (step < StepEnum.DEPARTMENT_RIGHTS) {
            if (locationIds) {
                this.setState({ locationIds: locationIds });
            }

            nextStep = step + 1;
        }
        else {
            // we're done. To back to user overview
            const queryCompanyId = this.props.match.params.companyId;
            if (queryCompanyId) {
                navigationStore.push(`/superadmin/companies/${queryCompanyId}`);
            }
            else {
                navigationStore.push('/admin/users');
            }

            return;
        }

        this.setState({
            step: nextStep
        })
    }

    render() {
        const { step, locationIds } = this.state;
        const paramsCompanyId = this.props.match.params.companyId;

        const companyId = paramsCompanyId || userStore.signedInUser.company.id;

        return (
            <div>

                {
                    step === StepEnum.USER_INFORMATION &&
                    <UserInformationStep companyId={companyId} afterSubmit={this.nextStep} />
                }

                {
                    step === StepEnum.ACCESS_RIGHTS &&
                    <AccessRightsStep companyId={companyId} afterSubmit={this.nextStep} />
                }

                {
                    step === StepEnum.LOCATION_RIGHTS &&
                    <LocationRightsStep companyId={companyId} afterSubmit={this.nextStep} />
                }

                {
                    step === StepEnum.DEPARTMENT_RIGHTS &&
                    <DepartmentRightsStep companyId={companyId} afterSubmit={this.nextStep} locationIds={locationIds} />
                }

            </div>
        )
    }

}

export default CreateNewUserPage;