import { BaseStore } from './BaseStore';
import { observable, action, makeObservable } from 'mobx';

export interface CurrencyInterface {
    "id": string,
    "name": string,
    "code": string,
    "symbol": string,
    "displayFormat": string
}

export class CurrencyStore extends BaseStore {
    @observable currencies: Array<CurrencyInterface> = [];

    constructor() {
        super();

        makeObservable(this);
    }

    @action
    getCurrencies() {
        return this.get('currencies', 'currencies', false);
    }
}

export const currencyStore = new CurrencyStore();