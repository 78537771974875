import * as React from 'react';
import { observer } from 'mobx-react';
import { languageStore, departmentStore, DepartmentInterface, locationStore, LocationInterface } from 'stores';
import * as _ from 'lodash';
import ReactTable from 'react-table';
import TableHeader from 'components/Table/TableHeader';
import CreateDepartmentModal from './CreateDepartmentModal';
import UpdateDepartmentModal from './UpdateDepartmentModal';
// import CreateLocationModal from './CreateLocationModal';

interface Props {
    companyId: string
}

enum ActiveModalEnum {
    NONE,
    CREATE_DEPARTMENT,
    UPDATE_DEPARTMENT
}

interface State {
    activeModal: ActiveModalEnum,
    activeDepartment: DepartmentInterface | null
}

@observer class Departments extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);

        this.state = {
            activeModal: ActiveModalEnum.NONE,
            activeDepartment: null
        }
    }

    componentDidMount() {
        const { companyId } = this.props;
        departmentStore.getDepartmentsByCompany(companyId);
        locationStore.getLocationsByCompany(companyId);
    }

    componentWillUnmount() {
        departmentStore.resetStore();
    }

    updateDepartment = async (department: DepartmentInterface) => {
        this.setState({
            activeDepartment: department,
            activeModal: ActiveModalEnum.UPDATE_DEPARTMENT
        })
    }

    onCreateLocation = () => {
        this.setState({
            activeModal: ActiveModalEnum.CREATE_DEPARTMENT
        })
    }

    hideModal = () => {
        this.setState({
            activeModal: ActiveModalEnum.NONE,
            activeDepartment: null
        })
    }

    render() {
        const { companyId } = this.props;
        const { activeModal, activeDepartment } = this.state;
        const departments = departmentStore.departments;
        const locations = locationStore.locations || [];

        const columns = [
            {
                Header: <TableHeader value={languageStore.get('name')} />,
                accessor: 'name'
            },
            {
                Header: <TableHeader value={languageStore.get('location')} />,
                id: 'location',
                accessor: (d: any) => {
                    const locationId = d.locationId;
                    const location = _.find(locations, { id: locationId }) as LocationInterface;
                    return location ? location.name : '-';
                }
            }
        ]

        return (
            <div className='mt-10 mx-auto'>
                <div className='mb-8'>
                    <h2 className='inline'>
                        {languageStore.get('departments')}
                    </h2>
                    <div className='float-right'>
                        <button onClick={() => this.onCreateLocation()} className='bg-comablue-label text-white py-2 px-8 rounded'>
                            {languageStore.get('createDepartment')}
                        </button>
                    </div>
                </div>

                <div className='border rounded p-5'>
                    <ReactTable
                        data={departments.slice()}
                        columns={columns}
                        defaultPageSize={99999}
                        showPagination={false}
                        NoDataComponent={() => null}
                        getTdProps={(state: any, rowInfo: any, column: any, instance: any) => {
                            return {
                                onClick: (e: React.MouseEvent, handleOriginal: any) => {
                                    e.preventDefault();
                                    this.updateDepartment(rowInfo.original);
                                }
                            };
                        }}
                        minRows={0} />
                </div>

                {
                    activeModal === ActiveModalEnum.CREATE_DEPARTMENT &&
                    <CreateDepartmentModal
                        companyId={companyId}
                        onClose={this.hideModal} />
                }

                {
                    (activeModal === ActiveModalEnum.UPDATE_DEPARTMENT && activeDepartment) &&
                    <UpdateDepartmentModal
                        department={activeDepartment}
                        onClose={this.hideModal}
                    />
                }
            </div>
        )
    }

}

export default Departments;