import React from 'react';
import { observer } from 'mobx-react';
import DetailsPage from 'components/AdminPage/DetailsPage';
import { ContactPeopleInterface, customerDetailsStore, languageStore, supplierDetailsStore } from 'stores';
import { FieldState, FormState } from 'formstate';
import { required } from 'helpers/FormValidation';
import TableHeader from 'components/Table/TableHeader';
import { ModalColumn } from 'components/Modal';
import Input from 'components/Forms/Input';
import { CustomerSupplierEnum } from 'components/SuppliersCustomersTable';

interface Props {
    data: Array<ContactPeopleInterface>,
    type: CustomerSupplierEnum
}

@observer class ContactPeopleTable extends React.Component<Props> {

    form = new FormState({
        id: new FieldState<string | null>(null),
        name: new FieldState('').validators(required),
        title: new FieldState(''),
        email: new FieldState(''),
        phone: new FieldState(''),
        mobile: new FieldState(''),
        supplierId: new FieldState<string | null>(null),
        customerId: new FieldState<string | null>(null)
    })

    render() {
        const { type, data } = this.props;
        const form = this.form.$;
        const isSupplier = type === CustomerSupplierEnum.SUPPLIER;
        // const isNewEntity = !!!form.id.$;

        const columns = [
            {
                Header: <TableHeader value={languageStore.get('name')} />,
                accessor: 'name'
            }
        ]

        return (
            <DetailsPage
                generalTitle={languageStore.get('contactPerson')}
                modalTitle={languageStore.get('contactPerson')}
                form={this.form}
                columns={columns}
                collection={data as any}
                onUpdate={(data: ContactPeopleInterface) => isSupplier ? supplierDetailsStore.putContact(data) : customerDetailsStore.putContact(data)}
                onSubmit={(data: ContactPeopleInterface) => isSupplier ? supplierDetailsStore.postContact(data) : customerDetailsStore.postContact(data)}
                onDelete={(contactId: string) => isSupplier ? supplierDetailsStore.deleteContact(contactId) : customerDetailsStore.deleteContact(contactId)}>

                <ModalColumn>

                    <Input
                        fieldState={form.name}
                        id='contact-name'
                        label={languageStore.get('name')}
                    />

                    <Input
                        fieldState={form.title}
                        id='contact-title'
                        label={languageStore.get('title')}
                    />

                    <Input
                        fieldState={form.email}
                        id='contact-email'
                        label={languageStore.get('email')}
                    />

                    <Input
                        fieldState={form.phone}
                        id='contact-phone'
                        label={languageStore.get('phone')}
                    />

                    <Input
                        fieldState={form.mobile}
                        id='contact-mobile'
                        label={languageStore.get('mobile')}
                    />

                </ModalColumn>

            </DetailsPage>
        )
    }
}

export default ContactPeopleTable;