import { BaseStore } from './BaseStore';
import { ContactPeopleInterface } from './SupplierStore';
import { observable, action, makeObservable } from 'mobx';
import request from 'api/request';
import * as _ from 'lodash';
import { CustomerInterface } from './CustomerStore';

export interface UsedInInterface {
    "accessableContractIds": Array<string>,
    "inaccessableContractIds": Array<string>
}

export interface CustomerUsedInInterface {
    "salesContracts": UsedInInterface,
    "serviceContracts": UsedInInterface
}

export class CustomerDetailsStore extends BaseStore {
    @observable customer: CustomerInterface | null = null;
    @observable usedIn: CustomerUsedInInterface | null = null;

    constructor() {
        super();

        makeObservable(this);
    }

    @action
    resetStore() {
        this.customer = null;
        this.usedIn = null;
    }

    @action
    getUsedIn(customerId: string) {
        return this.get(`customers/${customerId}/used-in`, 'usedIn');
    }

    @action
    getCustomers() {
        return this.get('customers', 'customers');
    }

    @action
    getCustomer(id: string) {
        return this.get(`customers/${id}`, 'customer');
    }

    @action
    postCustomer(customer: CustomerInterface): Promise<CustomerInterface> {
        return this.post('customers', 'customer', customer);
    }

    @action
    putCustomer(customer: CustomerInterface): Promise<CustomerInterface> {
        return this.put(`customers/${customer.id}`, 'customer', customer, false);
    }

    @action
    postContact(contact: ContactPeopleInterface): Promise<ContactPeopleInterface> {
        return new Promise(async (resolve, reject) => {
            contact.customerId = this.customer?.id;

            try {
                const res = await request.post(`customers/${contact.customerId!}/contacts`, contact);
                const addedContact = res.data;

                if (this.customer) {
                    this.customer.contactPeople.push(addedContact);
                }

                resolve(addedContact);
            }
            catch(error) {
                reject(error);
            }

        })

    }

    @action
    putContact(contact: ContactPeopleInterface): Promise<ContactPeopleInterface> {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await request.put(`customers/${contact.customerId!}/contacts/${contact.id}`, contact);
                const updatedContact = res.data;

                if (this.customer) {
                    const index = _.findIndex(this.customer.contactPeople, { id: updatedContact.id });
                    if (index !== undefined) {
                        this.customer.contactPeople.splice(index, 1, updatedContact);
                    }
                }

                resolve(updatedContact);
            }
            catch (error) {
                reject(error);
            }
        })
    }

    @action
    deleteContact(contactId: string) {
        return this.delete(`customers/${this.customer!.id}/contacts/${contactId}`, contactId, this.customer?.contactPeople || []);
    }
}

export const customerDetailsStore = new CustomerDetailsStore();