import { observer } from "mobx-react";
import { languageStore } from "stores";

interface Props {
    contractId: string
}

const EmailCorrespondence = observer((props: Props) => {

    const email = `${props.contractId}@${process.env.REACT_APP_CORRESPONDENCE_DOMAIN}`;

    return (
        <span>
            <b>TIP</b>: {languageStore.get('emailCorrespondenceTo')} <a className='text-comablue-label' href={`mailto:${email}`}>{email}</a> {languageStore.get('toAttach', false)}.
            <div className='mt-2'>
                {languageStore.get('correspondenceFilesWillBeIgnored')}
            </div>
        </span>
    )
})

export default EmailCorrespondence;