import * as React from 'react';
import ContractDetailsMenu from 'components/ContractDetailsMenu';
import SectionEnum from 'components/PurchaseContractDetailsPage/SectionEnum';
import { languageStore, customFieldStore, DashboardContractTypeEnum, userStore } from 'stores';
import { observer } from 'mobx-react';

@observer class Menu extends React.Component {

    render() {
        const miscItems = [
            {
                title: languageStore.get('status'),
                hash: SectionEnum.STATUS
            }
        ];

        if (userStore.signedInUser!.company.allowCustomFields && customFieldStore.hasCustomFields[DashboardContractTypeEnum.PURCHASE]) {
            miscItems.unshift({
                title: languageStore.get('customFields'),
                hash: SectionEnum.CUSTOM_FIELDS
            })
        };

        return (
            <ContractDetailsMenu
                sections={[
                    {
                        title: languageStore.get('general'),
                        items: [
                            {
                                title: languageStore.get('overview'),
                                hash: SectionEnum.OVERVIEW
                            },
                            {
                                title: languageStore.get('supplier'),
                                hash: SectionEnum.SUPPLIER
                            },
                            {
                                title: languageStore.get('notes'),
                                hash: SectionEnum.NOTES
                            }
                        ]
                    },
                    {
                        title: languageStore.get('economy'),
                        items: [
                            {
                                title: languageStore.get('financeAccount'),
                                hash: SectionEnum.FINANCE_ACCOUNT
                            },
                            {
                                title: languageStore.get('services'),
                                hash: SectionEnum.SERVICES
                            },
                            {
                                title: languageStore.get('contractSum'),
                                hash: SectionEnum.CONTRACT_SUM
                            }
                        ]
                    },
                    {
                        title: languageStore.get('administration'),
                        items: [
                            {
                                title: languageStore.get('alarms'),
                                hash: SectionEnum.EXTRA_ALARMS
                            },
                            {
                                title: languageStore.get('verifiedBy'),
                                hash: SectionEnum.VERIFIED_BY
                            }
                        ]
                    },
                    {
                        title: languageStore.get('files'),
                        items: [
                            {
                                title: languageStore.get('contracts'),
                                hash: SectionEnum.FILES_CONTRACT
                            },
                            {
                                title: languageStore.get('linkedContracts'),
                                hash: SectionEnum.LINKED_CONTRACTS
                            },
                            {
                                title: languageStore.get('correspondence'),
                                hash: SectionEnum.FILES_CORRESPONDENCE
                            }
                        ]
                    },
                    {
                        title: languageStore.get('misc'),
                        items: miscItems
                    }
                ]} />
        )
    }

}

export default Menu;