import * as React from 'react';
import { observer } from 'mobx-react';
import { FieldState } from 'formstate';
import ContractDropdownItem from 'components/ContractDropdownItem';
import { languageStore, equipmentStore } from 'stores';
import { DropdownOptionInterface } from 'components/Forms/Dropdown';
import { currentStaffContractStore as store } from 'stores';
import { reaction, IReactionDisposer } from 'mobx';

interface Props {
    fieldState: FieldState<any>,
    editMode?: boolean
}

@observer class SelectEquipment extends React.Component<Props> {

    reactionDisposer: IReactionDisposer | any;

    componentDidMount() {
        if (store.contract && store.contract.department) {
            const locationId = store.contract!.department.locationId;
            equipmentStore.getEquipment(locationId);
        }

        this.reactionDisposer = reaction(() => store.currentLocationId, (locationId) => {
            if (locationId && store.contract && 
                ((store.contract.department !== null && store.contract.department.locationId !== locationId) ||
                (store.contract && store.contract!.department === null))) {

                equipmentStore.getEquipment(locationId!);
            }
        })
    }

    componentWillUnmount() {
        this.reactionDisposer();
    }

    render() {
        const { fieldState, editMode } = this.props;

        let opts: Array<DropdownOptionInterface> = [];
        equipmentStore.equipment.forEach((eq) => {
            opts.push({
                value: eq.id,
                name: eq.name
            })
        })

        return (
            <ContractDropdownItem
                label={languageStore.get('equipment')}
                id='equipment'
                placeholderText={languageStore.get('selectEquipment')}
                fieldState={fieldState}
                options={opts}
                editMode={editMode} />
        )
    }

}

export default SelectEquipment;