import TableDetails from "components/AdminPage/TableDetails";
import TableHeader from "components/Table/TableHeader";
import { observer } from "mobx-react";
import { useEffect } from "react";
import { useParams } from "react-router";
import { CompanyUserInterface, companyUserStore, languageStore, navigationStore, toastStore, userStore } from "stores";
import _ from 'lodash';
import AddUserModal from "components/AddUserModal";
import useCompanyLocationByParams from "../useCompanyLocationByParams";

const Users = observer(() => {
    const location = useCompanyLocationByParams();
    const { id } = useParams<{ id: string }>();

    useEffect(() => {
        const me = userStore.signedInUser;
        companyUserStore.getUsers(me.company.id);
    }, [id])

    const onRemoveUserFromLocation = async (user: CompanyUserInterface) => {
        if (!window.confirm(`${languageStore.get('areYouSureYouWantToDelete')}?`)) return;

        const locations = _.filter(user.locations, (l) => l.id !== id);
        await companyUserStore.setLocations(user.companyId, user.id, locations);

        toastStore.addDefaultSuccessfulSaveToast();
    }

    const onAddUserToLocation = async (userId: string) => {
        const user = _.find(companyUserStore.users, u => u.id === userId);
        if (!user || !location) return;

        const locations = [...user.locations, location];
        await companyUserStore.setLocations(user.companyId, user.id, locations);

        toastStore.addDefaultSuccessfulSaveToast();
    }

    const columns = [
        {
            Header: <TableHeader value={languageStore.get('name')} />,
            id: 'fullName',
            accessor: (d: CompanyUserInterface) => {
                return (
                    <span>
                        {d.fullName}
                        {
                            d.disabled &&
                            <span className='font-semibold inline text-red text-xs'><br />{languageStore.get('inactive')}</span>
                        }
                    </span>
                )
            }
        },
        {
            Header: <TableHeader value={languageStore.get('email')} />,
            accessor: 'email'
        },
        {
            Header: null,
            id: 'actions',
            width: 180,
            accessor: (d: CompanyUserInterface) => {
                return (
                    <>
                        <button
                            className='ml-2 px-2 py-1 text-xs text-red rounded bg-red-lightest hover:bg-red-lighter'
                            onClick={(e) => onRemoveUserFromLocation(d)}>
                            {languageStore.get('remove')}
                        </button>
                        <button
                            className='ml-6 px-2 py-1 text-xs text-grey-darkest rounded bg-grey-light hover:bg-red-lighter'
                            onClick={(e) => navigationStore.push(`/admin/users/${d.id}`)}>
                            {languageStore.get('goTo')} &rarr;
                        </button>
                    </>
                )
            }
        }
    ]

    const data = _.filter(companyUserStore.users, (u) => _.some(u.locations, (l) => l.id === id))

    return (
        <TableDetails
            actions={
                <AddUserModal
                    companyId={location?.companyId!}
                    onSubmit={onAddUserToLocation}
                    skipUserIds={data.map((d) => d.id)}
                />
            }
            data={_.orderBy(data, 'fullName')}
            columns={columns}
            filterBy={['fullName']}
        />
    )
})

export default Users;