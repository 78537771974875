import React from 'react';
import { observer } from 'mobx-react';
import { InboxListInterface } from 'stores/InboxesStore';
import TableHeader from 'components/Table/TableHeader';
import { languageStore, navigationStore } from 'stores';
import ReactTable from 'react-table';

interface Props {
    data: Array<InboxListInterface>
}

@observer class InboxesTable extends React.Component<Props> {

    render() {
        const { data } = this.props;

        const columns = [
            {
                Header: <TableHeader value={languageStore.get('name')} />,
                accessor: 'name'
            },
            {
                Header: <TableHeader value={languageStore.get('email')} />,
                accessor: 'email'
            },
            {
                Header: <TableHeader value={languageStore.get('public')} />,
                width: 90,
                id: 'isPublic',
                accessor: (d: InboxListInterface) => d.isPublic ? languageStore.get('yes') : languageStore.get('no')
            }
        ]

        return (
            <div className='border rounded p-5'>
                <ReactTable
                    data={data.slice()}
                    columns={columns}
                    defaultPageSize={99999}
                    showPagination={false}
                    defaultSorted={[{ id: 'name' }]}
                    NoDataComponent={() => null}
                    getTdProps={(state: any, rowInfo: any, column: any, instance: any) => {
                        return {
                            onClick: (e: React.MouseEvent, handleOriginal: any) => {
                                const inbox = rowInfo.original as InboxListInterface;
                                const id = inbox.id;

                                if (!inbox.canEdit) {
                                    alert(languageStore.get('notAccessible'));
                                    return;
                                }

                                navigationStore.push(`/admin/inboxes/${id}`);
                            }
                        };
                    }}
                    minRows={0} />
            </div>
        )
    }
}

export default InboxesTable;