import * as React from 'react';
import { observer } from 'mobx-react';
import { FieldState } from 'formstate';
import ContractDropdownItem from 'components/ContractDropdownItem';
import { languageStore, CustomFieldTypeEnum } from 'stores';

interface Props {
    fieldState: FieldState<any>
}

@observer class SelectCustomFieldType extends React.Component<Props> {

    options = [
        {
            value: CustomFieldTypeEnum.STRING,
            name: languageStore.get('string')
        },
        {
            value: CustomFieldTypeEnum.STRING_MAX,
            name: languageStore.get('longString')
        },
        {
            value: CustomFieldTypeEnum.INT,
            name: languageStore.get('integer')
        },
        {
            value: CustomFieldTypeEnum.DECIMAL,
            name: languageStore.get('decimal')
        },
        {
            value: CustomFieldTypeEnum.CHECKBOX,
            name: languageStore.get('checkbox')
        },
        {
            value: CustomFieldTypeEnum.DROPDOWN,
            name: languageStore.get('dropdown')
        },
        {
            value: CustomFieldTypeEnum.MULTIPLE_CHOICE,
            name: languageStore.get('multipleChoice')
        }
    ]

    render() {
        const { fieldState } = this.props;

        return <ContractDropdownItem
            label={languageStore.get('type')}
            id='fieldType'
            type='number'
            fieldState={fieldState}
            options={this.options}
            skipSort={true}
            editMode={true} />
    }

}

export default SelectCustomFieldType;