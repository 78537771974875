import React from 'react';
import { observer } from 'mobx-react';
import { languageStore, navigationStore } from 'stores';

interface Props {
    title: string,
    endpoint: string,
    accessibleContractIds: Array<string>,
    inaccessibleContractIds: Array<string>
}

@observer class ContractBlock extends React.Component<Props> {

    onContractIdClicked = (e: React.MouseEvent, id: string) => {
        const { endpoint } = this.props;
        navigationStore.push(`/${endpoint}/${id}`);
    }

    render() {
        const { title, accessibleContractIds, inaccessibleContractIds } = this.props;

        return (
            <>
                <h1 className='text-lg mb-2 mt-4'>{title}</h1>
                {
                    accessibleContractIds.map((id, index) => {
                        return (
                            <a
                                key={index}
                                href=" "
                                className='block mb-2' onClick={(e) => this.onContractIdClicked(e, id)}>{languageStore.get('contract')} #{index + 1}</a>
                        )
                    })
                }

                {
                    inaccessibleContractIds.length > 0 &&
                    <div className='block mb-2'>+{inaccessibleContractIds.length} {languageStore.get('notAccessible')}</div>
                }
            </>
        )
    }
}

export default ContractBlock;