import React from 'react';
import { observer } from 'mobx-react';
import Breadcrumb from 'components/Breadcrumb';
import { inboxesStore, InboxInterface, inboxStore, languageStore, navigationStore, toastStore } from 'stores';
import { FieldState, FormState } from 'formstate';
import { required } from 'helpers/FormValidation';
import Form from 'components/Forms/Form';
import Input from 'components/Forms/Input';
import SelectTrueFalse from 'components/Forms/SelectTrueFalse';
import InboxRoles from './InboxRoles';
import InboxUsersTable from './InboxUsersTable';

interface Props {
    createMode: boolean
}

@observer class AdminInboxPage extends React.Component<Props> {

    form = new FormState({
        name: new FieldState('').validators(required),
        daysBeforeMessageDelete: new FieldState<number | null>(null),
        isPublic: new FieldState<boolean>(false),
        domains: new FieldState('')
    })

    getIdByParams = () => {
        const props = this.props as any;
        return props.match.params.id;
    }

    async componentDidMount() {
        const { createMode } = this.props;

        if (!createMode) {
            const id = this.getIdByParams();
            const data = await inboxStore.getInbox(id) as InboxInterface;

            const form = this.form.$;
            form.name.reset(data.name)
            form.daysBeforeMessageDelete.reset(data.daysBeforeMessageDelete);
            form.isPublic.reset(data.isPublic);
            // Domains are stored prefixed with @, but we don't need to show that to the end user
            form.domains.reset(data.domains.map((d) => d.name.replace('@', '')).join(',').trim());
        }
    }

    onSubmit = async () => {
        const { createMode } = this.props;
        const form = this.form.$;

        if (createMode) {
            const newInbox = await inboxesStore.createInbox({
                name: form.name.$,
                daysBeforeMessageDelete: form.daysBeforeMessageDelete.$,
                isPublic: form.isPublic.$
            }) as InboxInterface;

            await this.updateDomains(newInbox.id, form.domains.$);
            navigationStore.push(`/admin/inboxes/${newInbox.id}`)
        }
        else {
            const inbox = inboxStore.inbox;
            if (!inbox) return;

            inbox.name = form.name.$;
            inbox.daysBeforeMessageDelete = form.daysBeforeMessageDelete.$;
            inbox.isPublic = form.isPublic.$;

            await inboxStore.updateInbox(inbox);
            await this.updateDomains(this.getIdByParams(), form.domains.$);
            toastStore.addToast({ 
                level: 'success', 
                title: languageStore.get('success'),
                message: languageStore.get('changesSaved')
            });
        }

    }

    updateDomains = async (inboxId: string, commaSeparatedDomainString: string) => {
        // Domains are stored prefixed with @, but we don't need to show that to the end user. Append before saving
        let domains = commaSeparatedDomainString ? commaSeparatedDomainString.split(',') : [];
        domains = commaSeparatedDomainString ? commaSeparatedDomainString.split(',') : [];
        domains = domains.map((d) => `@${d.trim()}`);
        await inboxStore.updateDomains(inboxId, domains);
    }

    onDelete = async (e: React.MouseEvent) => {
        e.preventDefault();
        if (!window.confirm(`${languageStore.get('areYouSureYouWantToDelete')}?`)) return;

        const inboxId = this.getIdByParams();
        await inboxStore.deleteInbox(inboxId);

        navigationStore.push(`/admin/inboxes`)
    }

    render() {
        const { createMode } = this.props;
        if (!createMode && !inboxStore.inbox) return null;
        const inbox = inboxStore.inbox!;

        const form = this.form.$;
        const inboxId = this.getIdByParams();

        return (
            <div>
                <Breadcrumb
                    backlinkTo='/admin/inboxes'
                    backlinkTitle={languageStore.get('inboxes')}
                    title={languageStore.get('edit')} />
                <Form onValidSubmit={this.onSubmit} formState={this.form}>
                    <div className='w-1/2 m-auto'>
                        <h1 className='mt-5 m-4'>
                            {languageStore.get('details')}
                        </h1>
                        {
                            !createMode &&
                            <p className='px-4 text-sm'>
                                <span className='font-semibold mr-6'>{languageStore.get('email')}:</span>
                                {inbox.email}
                            </p>
                        }
                        <div className='mt-10 flex'>
                            <div className='w-1/2 inline-block p-4'>
                                <Input
                                    fieldState={form.name}
                                    id='name'
                                    label={languageStore.get('name')}
                                />
                                {
                                    form.isPublic.value === false &&
                                    <Input
                                        fieldState={form.domains}
                                        id='domains'
                                        label={`${languageStore.get('domains')} (domain1.com,domain2.com)`}
                                    />
                                }
                            </div>
                            <div className='w-1/2 inline-block p-4'>
                                <Input
                                    fieldState={form.daysBeforeMessageDelete}
                                    id='daysBeforeMessageDelete'
                                    label={languageStore.get('daysBeforeMessageDelete')}
                                />
                                <SelectTrueFalse
                                    label={languageStore.get('anyDomainIsValid')}
                                    fieldState={form.isPublic}
                                    editMode={true}
                                    id='isPublic' />
                            </div>
                        </div>
                        <div className='text-right'>
                            <a className='mr-6' href=" " onClick={(e) => this.onDelete(e)}>{languageStore.get('delete')}</a>
                            <button
                                className='bg-comablue-label text-white py-2 px-8 m-4 rounded'
                                type='submit'>
                                {languageStore.get('saveChanges')}
                            </button>
                        </div>
                    </div>
                </Form>
                {
                    !createMode &&
                    <>
                        <div className='p-4'>
                            <div className='w-1/2 m-auto border-t border-gray-300 mt-8 pt-8'></div>
                            <InboxRoles
                                inboxId={inboxId}
                                roles={inbox.requiredRoles}
                            />
                            <div className='w-1/2 m-auto border-t border-gray-300 mt-8 pt-8' />
                        </div>
                        <div>
                            <div className='w-1/2 m-auto'>
                                <h1 className='mt-5'>
                                    {languageStore.get('specificUserAccess')}
                                </h1>
                            </div>
                            <div className='px-4'>
                                <InboxUsersTable
                                    inboxId={inboxId}
                                    data={inbox.usersWithAccess}
                                />
                            </div>
                        </div>
                    </>
                }
            </div>
        )
    }
}

export default AdminInboxPage;