import * as React from 'react';
import { observer } from 'mobx-react';
import { BaseReportStore, languageStore } from 'stores';
import ResultsTable from 'components/ReportsPage/ResultsTable';
import { Modal, ModalColumn } from 'components/Modal';
import * as _ from 'lodash';
import { CsvDataService } from './CsvDataService';

var jsPDF = require('jspdf');
require('jspdf-autotable');

interface Props {
    store: BaseReportStore
}

interface State {
    showModal: boolean
}

@observer class ReportResultsPage extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            showModal: false
        }
    }

    showModal = () => {
        this.setState({ showModal: true })
    }

    hideModal = () => {
        this.setState({ showModal: false })
    }

    createPDF = () => {
        const { store } = this.props;

        let doc = new jsPDF();
        const columns: Array<string> = [];
        const data: Array<Array<string>> = [];

        store.data.forEach((entry) => {
            if (entry.tableVisibility) {
                columns.push(languageStore.get(entry.label));
            }
        })

        store.results.forEach((res) => {
            const row: Array<string> = [];
            store.data.forEach((entry) => {
                if (entry.tableVisibility) {
                    row.push(entry.customConverter ? entry.customConverter(res) : _.get(res, entry.requestField) || '');
                }
            })

            data.push(row);
        })

        doc.autoTable({
            head: [columns],
            body: data
        });

        doc.save('report.pdf');
    }

    createCSV = () => {
        const { store } = this.props;

        const data: Array<any> = [];

        store.results.forEach((res) => {
            const row: object = {};

            store.data.forEach((entry) => {
                if (entry.tableVisibility) {
                    row[languageStore.get(entry.label)] = entry.customConverter ? entry.customConverter(res) : _.get(res, entry.requestField) || '';
                }
            })

            data.push(row);
        })

        CsvDataService.exportToCsv('report.csv', data);
    }

    backToReports = () => {
        const { store } = this.props;
        store.resetStore();
        store.setData();
    }

    render() {
        const { store } = this.props;
        const { showModal } = this.state;
        const data = _.orderBy(store.data, (d) => languageStore.get(d.label));

        return (
            <div>
                <div className='w-full mt-10 text-right'>
                    <div className='inline-block text-right'>
                        <button onClick={this.showModal} className='bg-comablue-label text-white py-2 px-8 rounded'>{languageStore.get('visibleColumns')}</button>
                    </div>
                    <div className='inline-block text-right ml-2'>
                        <button onClick={this.createPDF} className='bg-comablue-label text-white py-2 px-8 rounded'>{languageStore.get('export')} (PDF)</button>
                    </div>
                    <div className='inline-block text-right ml-2'>
                        <button onClick={this.createCSV} className='bg-comablue-label text-white py-2 px-8 rounded'>{languageStore.get('export')} (CSV)</button>
                    </div>
                    <div className='inline-block text-right ml-2'>
                        <button onClick={this.backToReports} className='bg-red text-white py-2 px-8 rounded'>{languageStore.get('close')}</button>
                    </div>
                </div>

                <ResultsTable store={store!} />

                {
                    showModal &&
                    <Modal
                        title={languageStore.get('columns')}
                        secondaryButton={{ title: languageStore.get('close'), onClick: this.hideModal }}>

                        <ModalColumn>
                            {
                                data.map((entry, index) => {
                                    const hasItem = entry.tableVisibility

                                    let className = 'mt-2';
                                    if (!hasItem) {
                                        className += ' text-grey';
                                    }


                                    return (
                                        <div key={index} className={className}>
                                            {languageStore.get(entry.label)}

                                            {
                                                hasItem &&
                                                <button className='ml-2 px-2 py-1 text-xs text-red rounded bg-red-lightest hover:bg-red-lighter' onClick={(e) => store.setVisibility(entry.id || entry.requestField, false)}>X</button>
                                            }

                                            {
                                                !hasItem &&
                                                <button className='ml-2 px-2 py-1 text-xs text-green rounded bg-green-lightest hover:bg-green-lighter' onClick={(e) => store.setVisibility(entry.id || entry.requestField, true)}>+</button>
                                            }
                                        </div>
                                    )
                                })
                            }
                        </ModalColumn>
                    </Modal>
                }
            </div>

        )
    }

}

export default ReportResultsPage;