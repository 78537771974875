import * as React from 'react';
import { observer } from 'mobx-react';
import { FormState } from 'formstate';
import Form from 'components/Forms/Form';
import CriteriaLine from './CriteriaLine';
import { SearchCriteriaInterface, purchaseReportStore, DashboardContractTypeEnum, staffReportStore, BaseReportStore, serviceReportStore, salesReportStore, languageStore, userStore, toastStore } from 'stores';
import SelectedContractType from 'components/SelectedContractType';
import ReportResultsPage from 'components/ReportResultsPage';

interface State {
    criteriaCount: number,
    contractType: DashboardContractTypeEnum | null,
    store: BaseReportStore | null
}

@observer class ReportsPage extends React.Component<any, State> {

    form = new FormState({})

    constructor(props: any) {
        super(props);

        this.state = {
            criteriaCount: 1,
            contractType: null,
            store: null,
        }
    }

    onContractTypeChanged = (value: DashboardContractTypeEnum) => {
        let store: any = null;
        userStore.setSelectedContractType(value);

        switch (value) {
            case DashboardContractTypeEnum.PURCHASE:
                store = purchaseReportStore;
                break;
            case DashboardContractTypeEnum.STAFF:
                store = staffReportStore;
                break;
            case DashboardContractTypeEnum.SERVICE:
                store = serviceReportStore;
                break;
            case DashboardContractTypeEnum.SALES:
                store = salesReportStore;
                break;
        }

        if (store) {
            if (store.results.length === 0) {
                store.resetStore();
                store.setData();
            }

            this.setState({
                criteriaCount: 0,
            }, () => {
                this.setState({
                    contractType: value,
                    criteriaCount: 1,
                    store: store,
                })
            });
        }

    }

    onSubmit = async () => {
        const { store } = this.state;
        const form = this.form.$;

        const criterias: Array<SearchCriteriaInterface> = [];

        Object.keys(form).forEach((index) => {
            const entity = form[index] as FormState<any>;

            if (Object.keys(entity.$).length > 0 && (entity.$.searchValue.$ || entity.$.compareType.$ > 2)) {
                const criteria = {
                    requestField: entity.$.requestField.$,
                    compareType: entity.$.compareType.$,
                    searchValue: entity.$.searchValue.$
                } as SearchCriteriaInterface;
                criterias.push(criteria);
            }
        });
        if (store && criterias.length > 0) {
            try {
                await store.getResults(criterias);                
            }
            catch(error) {
                toastStore.addError(languageStore.get('badRequest'));
            }
        }
    }

    getCriterias = () => {
        const { criteriaCount } = this.state;
        let values = [];
        for (let i: number = 0; i < criteriaCount; i++) {
            const store = this.state.store;
            if (store) {
                values.push(<CriteriaLine store={store} form={this.form} index={i} key={i} />)
            }
        }

        return values;
    }

    addCriteria = () => {
        this.setState({ criteriaCount: this.state.criteriaCount + 1 })
    }

    removeCriteria = () => {
        this.setState({ criteriaCount: this.state.criteriaCount - 1 });
    }

    getBirthdayData = async () => {
        await staffReportStore.getBirthdayData();
    }

    getPhoneListData = async () => {
        await staffReportStore.getPhoneListData();
    }

    getAnniversaryData = async () => {
        await staffReportStore.getAnniversaryData();
    }

    getActiveServices = async () => {
        await serviceReportStore.getActiveServicesData();
    }

    render() {
        const { criteriaCount, contractType, store } = this.state;

        return (
            <div>
                <div className='mb-6'>
                    <SelectedContractType
                        activeContractType={contractType}
                        onChange={this.onContractTypeChanged} />
                </div>

                {
                    (!store?.results || store?.results.length === 0) &&
                    <div>
                        {
                            contractType === DashboardContractTypeEnum.STAFF &&
                            <div className='border rounded mt-2 p-4 mb-6'>
                                <p className='text-grey-dark mb-2'>
                                    {languageStore.get('predefined')}:
                                </p>
                                <button className='hover:bg-grey-lighter border rounded px-4 py-1' onClick={this.getBirthdayData}>{languageStore.get('upcomingBirthdays')}</button>
                                <button className='hover:bg-grey-lighter border rounded px-4 py-1 ml-2' onClick={this.getAnniversaryData}>{languageStore.get('upcomingAnniversaries')}</button>
                                <button className='hover:bg-grey-lighter border rounded px-4 py-1 ml-2' onClick={this.getPhoneListData}>{languageStore.get('phoneList')}</button>
                            </div>
                        }

                        {
                            contractType === DashboardContractTypeEnum.SERVICE &&
                            <div className='border rounded mt-2 p-4 mb-6'>
                                <p className='text-grey-dark mb-2'>
                                    {languageStore.get('predefined')}:
                                </p>
                                <button className='hover:bg-grey-lighter border rounded px-4 py-1' onClick={this.getActiveServices}>{languageStore.get('services')}</button>
                            </div>
                        }

                        <Form onValidSubmit={this.onSubmit} formState={this.form}>
                            <div className='border rounded p-4'>
                                <p className='text-grey-dark' style={{ marginBottom: '-24px' }}>
                                    {languageStore.get('custom')}:
                                </p>
                                <div className='mb-6'>
                                    {this.getCriterias()}
                                </div>

                                <button onMouseDown={this.addCriteria} className='px-8 py-2 border rounded'>{languageStore.get('addCriteria')}</button>

                                {
                                    criteriaCount > 1 &&
                                    <button onMouseDown={this.removeCriteria} className='ml-2 px-8 py-2 border rounded'>{languageStore.get('removeCriteria')}</button>
                                }

                                <button className='ml-2 px-8 py-2 bg-comablue-label text-white py-2 px-8 rounded' type='submit'>{languageStore.get('createReport')}</button>
                            </div>

                        </Form>
                    </div>
                }

                {
                    (store && store?.results.length > 0) &&
                    <ReportResultsPage store={store} />
                }

            </div>
        );
    }

}

export default ReportsPage;