import React from 'react';
import { observer } from 'mobx-react';
import { languageStore, planStore } from 'stores';
import { FieldState } from 'formstate';
import Dropdown from 'components/Forms/Dropdown';
import _ from 'lodash';

interface Props {
    fieldState: FieldState<any>,
    disabled?: boolean
}

@observer class SelectPlan extends React.Component<Props> {

    componentDidMount() {
        planStore.getPlans();
    }

    render() {
        const { fieldState, disabled } = this.props;
        const plans = planStore.plans || [];

        let options = plans.map((p) => ({
            value: p.id,
            name: p.maxSeats.toString()
        }))

        options = _.orderBy(options, [{'name': Number}]) as any;

        return (
            <Dropdown
                disabled={disabled}
                placeholderText={languageStore.get('plan')}
                label={`${languageStore.get('plan')} (${languageStore.get('maxSeats')})`}
                fieldState={fieldState}
                options={options}
                skipSort={true}
                id='selectPlan'
            />
        )
    }
}

export default SelectPlan;