import * as React from 'react';
import ReactTable from 'react-table';
import { observer } from 'mobx-react';
import SubTable from 'components/ContractsTable/SubTable';
import { BaseContractsStore, languageStore } from 'stores';

import 'react-table/react-table.css';
import './style.css';
import ShowOnPrint from 'components/ShowOnPrint';

interface Props {
    store: BaseContractsStore<any>,
    onSubRowClicked?: Function,
    defaultSortingId?: string
}

interface State {
    isLoading: boolean
}

@observer class ContractsTable extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            isLoading: true
        }
    }

    async componentDidMount() {
        const { store } = this.props;
        await store.getGroups(store.viewBy, store.onlyMine);
        this.setState({ isLoading: false });
    }

    private expandSubcomponentOnRowClicked(state: any, rowInfo: any | undefined, col: any, instance: any): object {
        return {
            onClick: async (e: React.MouseEvent) => {
                const { expanded } = state;
                const path = rowInfo.nestingPath[0];
                const diff = { [path]: expanded[path] ? false : true };

                instance.setState({
                    expanded: {
                        ...expanded,
                        ...diff
                    }
                });

            }
        }
    }

    render() {
        const { isLoading } = this.state;
        const { store, onSubRowClicked, defaultSortingId } = this.props;
        const columns = [
            {
                Header: null,
                accessor: 'name'
            }
        ]

        // let expandedRows = undefined;
        // if (store.groups && store.groups.length === 1) {
        //     expandedRows = { 0: true };
        // }

        return (
            <ShowOnPrint>
                <ReactTable
                    data={store.groups}
                    columns={columns}
                    showPagination={false}
                    sortable={false}
                    // expanded={expandedRows}
                    defaultPageSize={99999}
                    NoDataComponent={() => !isLoading ? <h1 className='text-center font-light mt-32 text-grey'>{languageStore.get('noContracts')}.</h1> : null}
                    manual // Sorting and pagination will be handled serverside
                    minRows={0}
                    getTrProps={this.expandSubcomponentOnRowClicked}
                    SubComponent={row => <SubTable onSubRowClicked={onSubRowClicked} store={store} id={row.original.id} defaultSortingId={defaultSortingId} />}
                />
            </ShowOnPrint>
        );
    }

}

export default ContractsTable;