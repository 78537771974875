import * as React from 'react';
import { observer } from 'mobx-react';
import { navigationStore, HeaderEnum, languageStore, userStore, CompanyInterface, inboxesStore } from 'stores';
import Button from '../Button';
import _ from 'lodash';

import './style.css';

interface Props {
    onClick: Function
}

@observer class InboxHeaderButton extends React.Component<Props> {

    componentDidMount() {
        const company = userStore.signedInUser!.company as CompanyInterface;
        if (company.allowInbox) {
            inboxesStore.getInboxes();
        }
    }

    render() {
        const { onClick } = this.props;
        const inboxes = inboxesStore.inboxes;

        let hasUnseen = false;
        if (inboxes?.results && inboxes.results.length > 0) {
            const unseenMessageCount = _.sum(inboxes.results.map((i) => i.messageCount))
            hasUnseen = unseenMessageCount > 0;
        }
        else return null;

        return (
            <Button
                activeTextClassName={hasUnseen ? 'text-white' : undefined}
                activeBackgroundClassName={hasUnseen ? 'InboxHeaderButtonActiveBackground' : undefined}
                active={hasUnseen || navigationStore.activeHeader === HeaderEnum.Inbox}
                className='mr-4' text={languageStore.get('inbox')}
                onClick={onClick} />
        )

    }

}

export default InboxHeaderButton;