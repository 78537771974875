import { observable, action, computed, makeObservable } from 'mobx';
import SaveableStore from './SaveableStore';

interface SaveableInterface {
    location: string | null
}

export class RedirectStore extends SaveableStore {

    @observable location: string | null = null;

    constructor() {
        super('RedirectStore');
        makeObservable(this);

        this.initSessionStorage(this, ['location']);
    }

    @action
    setLocation(location: string) {
        this.location = location;
    }

    @action
    resetStore() {
        this.location = null;
    }

    @computed
    get redirectIsActive() {
        return !!this.location;
    }

    protected setSaveableData(data: SaveableInterface): void {
        this.location = data.location;
    }

    protected getSaveableData(): SaveableInterface {
        return {
            location: this.location
        }
    }

}

export const redirectStore = new RedirectStore();