import * as React from 'react';
import { observer } from 'mobx-react';
import { CurrentContractStore, CurrentContractBaseInterface, languageStore, CustomFieldSchemaInterface, CustomFieldTypeEnum, CurrentContractCustomFieldInterface, customFieldStore, userStore } from 'stores';
import { FormState, FieldState } from 'formstate';
import BaseContract from 'components/StaffContractDetailsPage/BaseContract';
import ContractDetailsGroup from 'components/ContractDetailsGroup';
import ContractDetailsColumn from 'components/ContractDetailsColumn';
import ContractDetailsItem from 'components/ContractDetailsItem';
import EmptyContractSection from 'components/EmptyContractSection';
import * as _ from 'lodash';
import SelectTrueFalse from 'components/Forms/SelectTrueFalse';
//import { isNumber, isInteger } from 'helpers/FormValidation';
import CustomFieldDropdown from './CustomFieldDropdown';

interface Props {
    store: CurrentContractStore<CurrentContractBaseInterface>,
    hash: string
}

@observer class CustomFields extends React.Component<Props> {

    form = new FormState({})

    numberOfFields = 0;

    initializeCustomFields = async () => {
        const { store } = this.props;
        await store.initializeCustomFields();
        this.mapFormByContract();
    }

    customObjectMapping = () => {
        const { store } = this.props;
        const form = this.form.$;

        const fields = [...store.contract!.customFields];

        Object.keys(form).forEach((fieldId) => {
            const fs = form[fieldId] as FieldState<string | boolean>;
            const entity = _.find(fields, { id: fieldId }) as CurrentContractCustomFieldInterface;

            if (entity.customFieldSchema.fieldType === CustomFieldTypeEnum.CHECKBOX) {
                entity.value = fs.$ === true ? '1' : '0';
            }
            else {
                entity.value = fs.$ as string;
            }
        })

        store.contract!.customFields = fields;
    }

    componentDidMount() {
        this.mapFormByContract();
    }

    mapFormByContract() {
        const { store } = this.props;
        const form = this.form.$;

        store.contract!.customFields.forEach((field) => {
            let value: string | boolean | null = field.value;

            if (field.customFieldSchema.fieldType === CustomFieldTypeEnum.CHECKBOX) {
                value = value === "1" ? true : false;
            }

            form[field.id] = new FieldState(value);
        })
    }

    render() {
        const { store, hash } = this.props;

        const hasCustomFields = userStore.signedInUser!.company.allowCustomFields && customFieldStore.hasCustomFields[store.contractType!];
        if (!hasCustomFields) return null;

        const fields = store.contract!.customFields || [];
        const form = this.form.$;
        const editMode = store.isEditMode(hash);
        if (fields.length === 0) {
            return (
                <EmptyContractSection
                    store={store}
                    hash={hash}
                    title={languageStore.get('customFields')}
                    onAdd={this.initializeCustomFields} />
            )
        }

        return (
            <BaseContract
                noFlex={true}
                store={store}
                form={this.form}
                customObjectMapping={this.customObjectMapping}
                sectionEnum={hash}>

                <ContractDetailsGroup fullWidth={true} title={languageStore.get('customFields')}>


                        {
                            Object.keys(form).map((fieldId, index) => {
                                const fs = form[fieldId] as FieldState<string>;

                                const entity = _.find(store.contract!.customFields, { id: fieldId }) as any;
                                if (!entity) return null;

                                const schema = entity.customFieldSchema as CustomFieldSchemaInterface;
                                const fieldType = schema.fieldType;

                                if (fieldType === CustomFieldTypeEnum.CHECKBOX) {
                                    this.numberOfFields++;
                                    return (
                                        <ContractDetailsColumn key={index} width={"w-1/4"}>
                                        <SelectTrueFalse
                                            key={index}
                                            label={schema.label}
                                            id={'checkbox-' + this.numberOfFields}
                                            fieldState={fs}
                                            editMode={editMode} />
                                        </ContractDetailsColumn>
                                    )
                                }
                                else {
                                    if (fieldType === CustomFieldTypeEnum.INT) {
                                        this.numberOfFields++;
                                        <ContractDetailsColumn key={index} width={"w-1/4"}>
                                            fs.validators(isInteger);
                                        </ContractDetailsColumn>
                                    }
                                    else if (fieldType === CustomFieldTypeEnum.DECIMAL) {
                                        this.numberOfFields++;
                                        <ContractDetailsColumn width={"w-1/4"}>
                                            fs.validators(isNumber);
                                        </ContractDetailsColumn>
                                    }

                                    if (fieldType !== CustomFieldTypeEnum.DROPDOWN && fieldType !== CustomFieldTypeEnum.MULTIPLE_CHOICE) {
                                        this.numberOfFields++;
                                        return (
                                            <ContractDetailsColumn key={index} width={"w-1/4"}>
                                                <ContractDetailsItem
                                                    key={index}
                                                    label={schema.label}
                                                    id={fieldId}
                                                    type={fieldType === CustomFieldTypeEnum.INT || fieldType === CustomFieldTypeEnum.DECIMAL ? 'number' : 'text'}
                                                    fieldState={fs}
                                                    editMode={editMode}
                                                    textArea={fieldType === CustomFieldTypeEnum.STRING_MAX}
                                                />
                                            </ContractDetailsColumn>
                                        )
                                    }
                                    else if(fieldType === CustomFieldTypeEnum.MULTIPLE_CHOICE) {
                                        this.numberOfFields++;
                                        return (
                                            <ContractDetailsColumn key={index} width={"w-1/4"}>
                                                <CustomFieldDropdown
                                                    key={index}
                                                    label={schema.label}
                                                    id={fieldId}
                                                    fieldState={fs}
                                                    multiple={true}
                                                    availableValues={schema.availableValues}
                                                    editMode={editMode} />
                                            </ContractDetailsColumn>
                                        )
                                    }
                                    else {
                                        // This is a dropdown
                                        this.numberOfFields++;
                                        return (
                                            <ContractDetailsColumn key={index} width={"w-1/4"}>
                                                <CustomFieldDropdown
                                                    key={index}
                                                    label={schema.label}
                                                    id={fieldId}
                                                    fieldState={fs}
                                                    availableValues={schema.availableValues}
                                                    editMode={editMode} />
                                            </ContractDetailsColumn>
                                        )
                                    }
                                }
                            })
                        }


                </ContractDetailsGroup>

            </BaseContract>
        )
    }

}

export default CustomFields;