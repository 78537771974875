import { locationStore, languageStore, userStore, navigationStore } from 'stores';
import TableHeader from 'components/Table/TableHeader';
import { useEffect } from 'react';
import AddLocationModal from './AddLocationModal';
import { observer } from "mobx-react";
import TableDetails from '../TableDetails';

const LocationsPage = observer(() => {
    useEffect(() => {
        const companyId = userStore.signedInUser.company.id;
        locationStore.getLocationsByCompany(companyId);
    }, [])

    const columns = [
        {
            Header: <TableHeader value={languageStore.get('name')} />,
            id: 'name',
            accessor: 'name'
        }
    ]

    return (
        <TableDetails
            actions={<AddLocationModal />}
            data={locationStore.locations.slice()}
            columns={columns}
            filterBy={['name']}
            onRowClicked={(rowId) => navigationStore.push(`/admin/locations/${rowId}`)}
        />
    )
})

export default LocationsPage;