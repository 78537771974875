import * as React from 'react';
import { observer } from 'mobx-react';
import { FormState, FieldState } from 'formstate';
import { verificationStore, languageStore, VerificationInterface, locationStore, LocationInterface, departmentStore, DepartmentInterface, DocumentTypeContractTypeEnum, userStore } from 'stores';
import TableHeader from 'components/Table/TableHeader';
import DetailsPage from '../DetailsPage';
import * as _ from 'lodash';
import { ModalColumn } from 'components/Modal';
import Location from 'components/Forms/Location';
import { required } from 'helpers/FormValidation';
import Department from 'components/Forms/Department';

@observer class VerifiedByDepartmentsPage extends React.Component {

    form = new FormState({
        id: new FieldState<string | null>(null),
        departmentId: new FieldState(null).validators(required),
        locationId: new FieldState(null).validators(required),
    })

    componentDidMount() {
        verificationStore.getVerifications(true);

        const companyId = userStore.signedInUser.company.id;
        locationStore.getLocationsByCompany(companyId);

        departmentStore.getDepartments();
    }

    componentWillUnmount() {
        verificationStore.resetStore();
    }

    onDeleteVerification = async (verificationId: string) => {
        const ver = _.find(verificationStore.verifications, { id: verificationId }) as VerificationInterface;
        const locationId = ver.locationId;

        await verificationStore.deleteVerification(locationId, verificationId);
    }

    onCreateVerification = (verification: VerificationInterface) => {
        verification.contractType = DocumentTypeContractTypeEnum.PURCHASE;
        verificationStore.createVerification(verification);
    }

    render() {
        const data = verificationStore.verifications || [];
        const form = this.form.$;
        const isNewEntity = !!!form.id.$;
        const locations = locationStore.locations || [];
        const departments = departmentStore.allDepartments || [];

        const filteredData = data.filter((d) => {
            return d.contractType === DocumentTypeContractTypeEnum.PURCHASE
        });

        const columns = [
            {
                Header: <TableHeader value={languageStore.get('department')} />,
                id: 'department',
                accessor: (d: VerificationInterface) => {
                    const departmentId = d.departmentId;
                    const department = _.find(departments, { id: departmentId }) as DepartmentInterface;
                    return department ? department.name : '-';
                }
            },
            {
                Header: <TableHeader value={languageStore.get('location')} />,
                id: 'location',
                accessor: (d: VerificationInterface) => {
                    const locationId = d.locationId;
                    const location = _.find(locations, { id: locationId }) as LocationInterface;
                    return location ? location.name : '-';
                }
            },
        ]

        return (
            <DetailsPage
                fullWidth={true}
                generalTitle={languageStore.get('verifiedByDepartments')}
                modalTitle={languageStore.get('verifiedByDepartment')}
                form={this.form}
                columns={columns}
                collection={filteredData}
                hidePrimaryModalButton={!isNewEntity}
                onSubmit={(ver: VerificationInterface) => this.onCreateVerification(ver)}
                onDelete={(id: string) => this.onDeleteVerification(id)}>

                <ModalColumn>

                    <Location
                        fieldState={form.locationId}
                        disabled={!isNewEntity}
                        editMode={true} />

                    <Department
                        disabled={!isNewEntity}
                        fieldState={form.departmentId}
                        locationId={form.locationId.value}
                        editMode={true} />

                </ModalColumn>

            </DetailsPage>
        );
    }

}

export default VerifiedByDepartmentsPage;