import * as React from 'react';
import { observer } from 'mobx-react';
import { FieldState } from 'formstate';
import ContractDropdownItem from 'components/ContractDropdownItem';
import {
    languageStore as lanStore,
    contractTypeStore,
    ContractTypeInterface,
    languageStore,
} from 'stores';
import { DropdownOptionInterface } from 'components/Forms/Dropdown';
import { BaseContractTypeEnum } from 'stores/BaseStore';

interface Props {
    fieldState: FieldState<any>,
    locationId?: string,
    fallbackValue?: string,
    editMode?: boolean,
    contractType: BaseContractTypeEnum
}

@observer class ContractType extends React.Component<Props> {

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        if (nextProps.locationId !== this.props.locationId) {
            nextProps.fieldState.onChange('');

            if (nextProps.locationId) {
                contractTypeStore.getContractTypes(this.props.contractType, nextProps.locationId);
            }
            else {
                contractTypeStore.resetStore();
            }
        }
    }

    componentDidMount() {
        const { locationId, contractType } = this.props;

        if (locationId) {
            contractTypeStore.getContractTypes(contractType, locationId);
        }
    }

    componentWillUnmount() {
        contractTypeStore.resetStore();
    }

    render() {
        const { fieldState, fallbackValue, editMode } = this.props;

        let availTypes: Array<DropdownOptionInterface> = [];
        contractTypeStore.contractTypes && contractTypeStore.contractTypes.forEach((contractType: ContractTypeInterface) => {

            let lookUpName = 'contractType_' + contractType.name.toLowerCase().replace(/\s/g, '');

            let translation = languageStore.get(lookUpName);
            // If we don't have a translation for this contract type, simply use the default name
            if (translation.indexOf('ContractType_') !== -1) {
                translation = contractType.name
            }

            availTypes.push({
                value: contractType.id,
                name: translation
            })
        })

        return <ContractDropdownItem
            label={lanStore.get('contractType')}
            id='contractType'
            placeholderText={lanStore.get('selectContractType')}
            fieldState={fieldState}
            options={availTypes}
            editMode={editMode}
            fallbackValue={fallbackValue}
        />

    }

}

export default ContractType;