import { reportCriteriaBuilder } from "helpers/ReportCriteriaBuilder";
import { action, observable, makeObservable } from "mobx";
import { BaseContractTypeEnum } from 'stores/BaseStore';

export interface ForecastInterface {
    "id": string,
    "supplier": { "name": string },
    "category": { "name": string },
    "subCategory": { "name": string },
    "responsibleUser": { "fullName": string },
    "department": { "name": string, "location": { name: string } }
}

export class ForecastStore {
    @observable contracts: Array<ForecastInterface> = [];

    constructor() {
        makeObservable(this);
    }

    @action
    resetStore() {
        this.contracts = [];
    }

    @action
    getData = async (contractType: BaseContractTypeEnum, idArray: Array<string>) => {
        reportCriteriaBuilder.addIdArrayQuery(idArray);
        reportCriteriaBuilder.setFilters([
            'id',
            'supplier.name',
            'category.name',
            'subCategory.name',
            'responsibleUser.fullName',
            'department.name',
            'department.location.name'
        ]);
        const rawData = await reportCriteriaBuilder.executeQuery(contractType);
        this.contracts = rawData;
    }
}

export const forecastStore = new ForecastStore();