import * as React from 'react';
import { observer } from 'mobx-react';
import { FieldState } from 'formstate';
import ContractDropdownItem from 'components/ContractDropdownItem';
import { languageStore, agreementStore, CurrentContractStore, CurrentContractBaseInterface } from 'stores';
import { DropdownOptionInterface } from 'components/Forms/Dropdown';
import { reaction, IReactionDisposer } from 'mobx';

interface Props {
    fieldState: FieldState<any>,
    editMode?: boolean,
    store: CurrentContractStore<CurrentContractBaseInterface>
}

@observer class SelectAgreement extends React.Component<Props> {

    reactionDisposer: IReactionDisposer | any;

    componentDidMount() {
        // Get available agreements
        const { store } = this.props;

        if (store.currentLocationId) {
            agreementStore.getAgreementsByLocationId(store.currentLocationId);
        }

        this.reactionDisposer = reaction(() => store.currentLocationId, (locationId) => {
            if (locationId) {
                agreementStore.getAgreementsByLocationId(locationId!);
            }
        })

    }

    componentWillUnmount() {
        this.reactionDisposer();
    }

    render() {
        const { fieldState, editMode, store } = this.props;

        let availAgreements: Array<DropdownOptionInterface> = [];

        agreementStore.agreements.forEach((agreement) => {
            if (agreement.locationId === store.currentLocationId) {
                availAgreements.push({
                    value: agreement.id,
                    name: agreement.name
                });
            }
        })

        return (
            <ContractDropdownItem
                label={languageStore.get('agreement')}
                id='agreement'
                placeholderText={languageStore.get('selectAgreementType')}
                fieldState={fieldState}
                options={availAgreements}
                editMode={editMode} />
        )
    }

}

export default SelectAgreement;