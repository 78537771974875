import * as React from 'react';
import { observer } from 'mobx-react';
import { FieldState } from 'formstate';
import ContractDropdownItem from 'components/ContractDropdownItem';
import { languageStore } from 'stores';
import { EmploymentHelper } from 'helpers/EmploymentHelper';

interface Props {
    fieldState: FieldState<any>,
    editMode?: boolean
}

export enum EmploymentFormEnum {
    MANAGEMENT_CONTRACT = 1,
    PAID_BY_THE_HOUR = 2,
    PROJECT_EMPLOYEE = 3,
    SALARIED_TERMS = 4,
    SALARIED_WORKER = 5,
    CONSULTANT = 6,
    NON_SALARIED_EMPLOYEE_MONTHLY_PAID = 7,
    FREELANCE = 8,
}

@observer class SelectEmploymentForm extends React.Component<Props> {

    render() {
        const { fieldState, editMode } = this.props;

        const employmentOpts = EmploymentHelper.EmploymentForms();

        return (
            <ContractDropdownItem
                label={languageStore.get('employmentForm')}
                id='employmentForm'
                placeholderText={languageStore.get('selectEmploymentForm')}
                fieldState={fieldState}
                options={employmentOpts}
                type='number'
                editMode={editMode} />
        )
    }

}

export default SelectEmploymentForm;