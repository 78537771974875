import * as React from 'react';
import { observer } from 'mobx-react';
import { FieldState } from 'formstate';
import ContractDropdownItem from 'components/ContractDropdownItem';
import { languageStore, LanguageEnum } from 'stores';

interface Props {
    fieldState: FieldState<any>,
    hideDefault?: boolean
}

@observer class SelectLanguage extends React.Component<Props> {

    options = [
        {
            value: LanguageEnum.NOT_SET,
            name: 'DEFAULT'
        },
        {
            value: LanguageEnum.ENGLISH,
            name: 'EN'
        },
        {
            value: LanguageEnum.DANISH,
            name: 'DK'
        },
        {
            value: LanguageEnum.NORWEGIAN,
            name: 'NO'
        },
        {
            value: LanguageEnum.SWEDISH,
            name: 'SE'
        },
        {
            value: LanguageEnum.FINNISH,
            name: 'FI'
        },
        {
            value: LanguageEnum.FRENCH,
            name: 'FR'
        },
        {
            value: LanguageEnum.GERMAN,
            name: 'DE'
        },
        {
            value: LanguageEnum.SPANISH,
            name: 'ES'
        },
    ]

    render() {
        const { fieldState, hideDefault } = this.props;

        let opts = this.options;

        if (hideDefault) {
            opts.shift(); // remove first entry (default)
        }

        return <ContractDropdownItem
            label={languageStore.get('language')}
            id='language'
            fieldState={fieldState}
            options={opts}
            skipSort={true}
            type='number'
            editMode={true} />
    }

}

export default SelectLanguage;