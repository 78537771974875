import TableHeader from 'components/Table/TableHeader';
import { ContractStatusEnum, languageStore, userStore } from 'stores';
import Moment from 'moment-timezone';
import { formatDate } from 'helpers/DateHelper';

export function getPurchaseColumns() {
    return [
        {
            Header: <TableHeader value={languageStore.get('daysToExpiration')} />,
            id: 'expirationDays',
            accessor: (d: any) => {
                const expirationDate = d.expirationDate;
                if (!expirationDate) return null;
                let timeZone = userStore.signedInUser!.timeZone.id;
                const days = Moment(expirationDate).utc().tz(timeZone).diff(Moment().utc().tz(timeZone), 'days');
                if (days < 1) {
                    return <span className='text-red'>{days}</span>
                }
                else return days;
            }
        },
        {
            Header: <TableHeader value={languageStore.get('dealNumber')} />,
            id: 'dealNumber',
            accessor: 'dealNumber'
        },
        {
            Header: <TableHeader value={languageStore.get('category')} />,
            id: 'categoryName',
            accessor: 'categoryName'
        },
        {
            Header: <TableHeader value={languageStore.get('responsible')} />,
            id: 'responsibleUserFullName',
            accessor: 'responsibleUserFullName'
        },
        {
            Header: <TableHeader value={languageStore.get('creationDate')} />,
            id: 'contractDateCreated',
            accessor: (d: any) => formatDate(d.contractDateCreated)
        },
        {
            Header: <TableHeader value={languageStore.get('subCategory')} />,
            id: 'subCategoryName',
            accessor: 'subCategoryName'
        },
        {
            Header: <TableHeader value={languageStore.get('supplier')} />,
            id: 'supplierName',
            accessor: 'supplierName'
        },
        {
            Header: <TableHeader value={languageStore.get('description')} />,
            id: 'description',
            accessor: 'description'
        },
        {
            Header: <TableHeader value={languageStore.get('renewal')} />,
            id: 'renewalDate',
            accessor: (d: any) => formatDate(d.renewalDate)
        },
        {
            Header: <TableHeader value={languageStore.get('expiration')} />,
            id: 'expirationDate',
            accessor: (d: any) => formatDate(d.expirationDate)
        },
        {
            Header: <TableHeader value={languageStore.get('status')} />,
            id: 'status',
            accessor: (d: any) => {
                switch (d.status) {
                    case ContractStatusEnum.AcceptedInOperation:
                        return languageStore.get('acceptedInOperation');
                    case ContractStatusEnum.Deleted:
                        return languageStore.get('deleted');
                    case ContractStatusEnum.Discontinued:
                        return languageStore.get('discontinued');
                    case ContractStatusEnum.Draft:
                        return languageStore.get('draft');
                    case ContractStatusEnum.Offer:
                        return languageStore.get('offer');
                    case ContractStatusEnum.Renetiation:
                        return languageStore.get('renetiation');
                    case ContractStatusEnum.Terminated:
                        return languageStore.get('terminated');
                    default:
                        return null;

                }
            }
        },
        {
            Header: <TableHeader value={languageStore.get('department')} />,
            id: 'departmentName',
            accessor: 'departmentName'
        },
        {
            Header: <TableHeader value={languageStore.get('location')} />,
            id: 'locationName',
            accessor: 'locationName'
        }
    ]
}