import React from 'react';
import { observer } from 'mobx-react';
import { languageStore, PlanInterface, planStore } from 'stores';
import DetailsPage from 'components/AdminPage/DetailsPage';
import { FieldState, FormState } from 'formstate';
import TableHeader from 'components/Table/TableHeader';
import { ModalColumn } from 'components/Modal';
import Input from 'components/Forms/Input';
import { required } from 'helpers/FormValidation';

@observer class PlansPage extends React.Component {

    form = new FormState({
        id: new FieldState<string | null>(null),
        maxSeats: new FieldState<number | null>(null).validators(required),
        pricePrMonth: new FieldState<number | null>(null),
        economicPlanId: new FieldState<string | null>(null).validators(required),
        signingRetailPrQty: new FieldState<number | null>(null),
        signingCostPrQty: new FieldState<number | null>(null),
        economicSigningId: new FieldState<string | null>(null).validators(required),
    })

    componentDidMount() {
        planStore.getPlans();
    }

    onUpdate(plan: PlanInterface) {
        if (plan.pricePrMonth === null) {
            plan.pricePrMonth = 0;
        }

        if (plan.signingRetailPrQty === null) {
            plan.signingRetailPrQty = 0;
        }

        if (plan.signingCostPrQty === null) {
            plan.signingCostPrQty = 0;
        }

        planStore.updatePlan(plan);
    }

    render() {
        const data = planStore.plans || [];
        const form = this.form.$;

        const columns = [
            {
                Header: <TableHeader value={languageStore.get('maxSeats')} />,
                accessor: 'maxSeats'
            },
            {
                Header: <TableHeader value={languageStore.get('pricePrMonth')} />,
                accessor: 'pricePrMonth'
            }
        ]

        return (
            <DetailsPage
                generalTitle={languageStore.get('plans')}
                modalTitle={languageStore.get('plan')}
                form={this.form}
                columns={columns}
                collection={data}
                onUpdate={(plan: PlanInterface) => this.onUpdate(plan)}>

                <ModalColumn>
                    <Input
                        type='number'
                        id='maxSeats'
                        label={languageStore.get('maxSeats')}
                        fieldState={form.maxSeats}
                        readOnly={true} />

                    <Input
                        type='number'
                        id='pricePrMonth'
                        label={languageStore.get('pricePrMonth')}
                        fieldState={form.pricePrMonth} />

                    <Input
                        id='economicPlanId'
                        label={languageStore.get('economicPlanId')}
                        fieldState={form.economicPlanId} />

                    <Input
                        type='number'
                        id='signingRetailPrQty'
                        label={languageStore.get('signingRetailPrQty')}
                        fieldState={form.signingRetailPrQty} />

                    <Input
                        type='number'
                        id='signingCostPrQty'
                        label={languageStore.get('signingCostPrQty')}
                        fieldState={form.signingCostPrQty} />

                    <Input
                        id='economicSigningId'
                        label={languageStore.get('economicSigningId')}
                        fieldState={form.economicSigningId} />
                </ModalColumn>

            </DetailsPage>
        )
    }
}

export default PlansPage;