import * as React from 'react';
import { observer } from 'mobx-react';
import { FieldState } from 'formstate';
import ContractDropdownItem from 'components/ContractDropdownItem';
import { languageStore } from 'stores';

interface Props {
    fieldState: FieldState<any>
}

@observer class SelectInterval extends React.Component<Props> {

    options = [
        {
            value: 1,
            name: languageStore.get('every')
        },
        {
            value: 2,
            name: languageStore.get('everyOther')
        },
        {
            value: 3,
            name: languageStore.get('everyThird')
        },
        {
            value: 4,
            name: languageStore.get('everyFourth')
        },
        {
            value: 5,
            name: languageStore.get('everyFifth')
        },
        {
            value: 6,
            name: languageStore.get('everySixth')
        },
        {
            value: 7,
            name: languageStore.get('everySeventh')
        },
        {
            value: 8,
            name: languageStore.get('everyEigth')
        },
        {
            value: 9,
            name: languageStore.get('everyNinth')
        },
        {
            value: 10,
            name: languageStore.get('everyTenth')
        },
        {
            value: 11,
            name: languageStore.get('everyEleventh')
        },
    ]

    render() {
        const { fieldState } = this.props;

        return <ContractDropdownItem
            label={languageStore.get('billingInterval')}
            placeholderText={languageStore.get('selectInterval')}
            id='billingInterval'
            fieldState={fieldState}
            options={this.options}
            skipSort={true}
            editMode={true} />
    }

}

export default SelectInterval;