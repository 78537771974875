import { observable, action, computed, makeObservable } from 'mobx';
import SaveableStore from 'stores/SaveableStore';
import request from 'api/request';
import { userStore } from './UserStore';
import { navigationStore } from './NavigationStore';
import { Cache } from 'api/Cache';

export enum UserTwoFactorAuthTypeEnum {
    NONE = 0,
    AUTHENTICATOR = 1,
    MAIL = 2
}

export interface UserTokenInterface {
    bearerToken: string | null,
    expires: string | null,
    id: string | null,
    email: string | null,
    adfsHost: ADFSHostEnum | null,
    twoFactorAuthToken: string | null,
    twoFactorAuthTokenExpiresAt: string | null,
    twoFactorAuthType: UserTwoFactorAuthTypeEnum | null
}

export enum ADFSHostEnum {
    NONE            = -1,
    JP_POL          = 0,
    VAEKSTFONDEN    = 1,
    SICCADANIA      = 2,
    NOMECO          = 3,
    SEF             = 4
}

export class AuthStore extends SaveableStore {

    @observable bearerToken: string | null = null;
    @observable expires: string | null = null;
    @observable userId: string | null = null;
    @observable forceReloginToContinue: boolean = false;
    @observable email: string | null = null;
    @observable adfsHost: ADFSHostEnum | null = null;

    @observable twoFactorAuthToken: string | null = null;
    @observable twoFactorAuthTokenExpiresAt: string | null = null;
    @observable twoFactorAuthType: UserTwoFactorAuthTypeEnum | null = null;

    @observable twoFactorAuthSecret: string | null = null;

    constructor() {
        super('AuthStore');
        makeObservable(this);

        this.initSessionStorage(this, [
            'bearerToken',
            'expires',
            'userId',
            'email',
            'adfsHost',
            'renewToken',
            'twoFactorAuthSecret'
        ]);
    }

    @computed
    get isAuthenticated() {
        return !!this.userId;
    }

    @computed
    get loginPage() {
        switch (this.adfsHost) {
            case ADFSHostEnum.JP_POL:
                return '/login/jp';
            case ADFSHostEnum.VAEKSTFONDEN:
                return '/login/vf';
            case ADFSHostEnum.SICCADANIA:
                return '/login/sicca';
            case ADFSHostEnum.NOMECO:
                return '/login/nomeco';
            case ADFSHostEnum.SEF:
                return '/login/sef';

            case ADFSHostEnum.NONE:
            default:
                return '/login';
        }
    }

    loginByAdfs(code: string, redirectUri: string, providerId: string, adfsHost: ADFSHostEnum) {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await request.post('auth/adfs',
                    {
                        providerId: providerId,
                        code: code,
                        redirectUri: redirectUri
                    },
                    {
                        headers: {
                            _byPassTransactionKey: 'true'
                        }
                    }
                )

                this.adfsHost = adfsHost;
                const data: UserTokenInterface = res.data;
                this.setSaveableData(data);
                if (this.bearerToken) {
                    await userStore.getSignedInUser();
                }
                resolve(data);
            }
            catch (error) {
                reject();
            }

        })
    }

    @action
    setAdfsHost(host: ADFSHostEnum) {
        this.adfsHost = host;
    }

    @action
    setAdfsHostByProviderId(providerId: string) {
        switch (providerId) {
            case process.env.REACT_APP_ADFS_JP_PROVIDER_ID:
                this.setAdfsHost(ADFSHostEnum.JP_POL);
                break;
            case process.env.REACT_APP_ADFS_VF_PROVIDER_ID:
                this.setAdfsHost(ADFSHostEnum.VAEKSTFONDEN);
                break;
            case process.env.REACT_APP_ADFS_SICCADANIA_PROVIDER_ID:
                this.setAdfsHost(ADFSHostEnum.SICCADANIA);
                break;
            case process.env.REACT_APP_ADFS_NOMECO_PROVIDER_ID:
                this.setAdfsHost(ADFSHostEnum.NOMECO);
                break;
            case process.env.REACT_APP_ADFS_SEF_PROVIDER_ID:
                this.setAdfsHost(ADFSHostEnum.SEF);
                break;
            default: break;
        }
    }

    @action
    login(username: string, password: string) {
        return new Promise((resolve, reject) => {
            request.post('auth/token',
                {
                    username: username,
                    password: password
                },
                {
                    headers: {
                        _byPassTransactionKey: 'true'
                    }
                }
            )
                .then(async (res) => {
                    const data: UserTokenInterface = res.data;
                    this.setSaveableData(data);
                    this.adfsHost = ADFSHostEnum.NONE;
                    if (data.bearerToken) {
                        await userStore.getSignedInUser();
                    }
                    resolve(data);
                })
                .catch((error) => reject());
        })
    }

    @action
    loginByTwoFactor(token: string, code: string, adfsHost: ADFSHostEnum = ADFSHostEnum.NONE) {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await request.post('auth/2fa', {
                    twoFactorAuthToken: token,
                    twoFactorAuthCode: code
                },
                    {
                        headers: {
                            _byPassTransactionKey: 'true'
                        }
                    })

                const data: UserTokenInterface = res.data;
                this.setSaveableData(data);
                this.adfsHost = adfsHost;
                await userStore.getSignedInUser();
                resolve(data);
            }
            catch (error) {
                reject();
            }
        })
    }

    @action
    loginAsUser(userId: string) {
        return new Promise((resolve, reject) => {
            request.post('auth/usersupport', {
                id: userId
            })
                .then(async (res) => {
                    const data: UserTokenInterface = res.data;
                    this.setSaveableData(data);
                    await userStore.getSignedInUser();
                    resolve(data);
                })
                .catch((error) => reject());
        })
    }

    @action
    refreshToken(): Promise<UserTokenInterface> {
        return new Promise((resolve, reject) => {
            request.get('auth/refresh-token')
                .then((res) => {
                    const data: UserTokenInterface = res.data;
                    this.setSaveableData(data);
                    resolve(data);
                })
                .catch((error) => {
                    reject();
                });
        })
    }

    @action
    getTwoFactorAuthSecret(authType: UserTwoFactorAuthTypeEnum) {
        return new Promise(async (resolve, reject) => {
            const res = await request.post('auth/activate-2fa', {
                twoFactorAuthType: authType
            },
                {
                    headers: {
                        _byPassTransactionKey: 'true'
                    }
                })

            if (res.status === 200) {
                this.twoFactorAuthSecret = res.data?.twoFactorAuthSecret || null;
                resolve(res.data);
            } else reject();
        })
    }

    @action
    resetTwoFactorAuthSecret() {
        this.twoFactorAuthSecret = null;
    }

    @action
    validateTwoFactorAuthCode(code: string) {
        return new Promise(async (resolve, reject) => {

            const res = await request.post('auth/validate-2fa', {
                twoFactorAuthCode: code
            }, {
                validateStatus: (status: number) => true // Bypass default error handling (toasts)
            })

            resolve(res.status);
        })
    }

    resetUserData() {
        this.setSaveableData({
            bearerToken: null,
            expires: null,
            id: null,
            email: null,
            adfsHost: null,
            twoFactorAuthToken: null,
            twoFactorAuthTokenExpiresAt: null,
            twoFactorAuthType: null
        })
        userStore.setSaveableData({ signedInUser: null });
        Cache.reset();
    }

    @action
    logout() {
        this.resetUserData();
        navigationStore.push('/login');
    }

    @action
    forceRelogin(value: boolean) {
        this.forceReloginToContinue = value;
    }

    setSaveableData(data: UserTokenInterface) {
        this.bearerToken = data.bearerToken;
        this.expires = data.expires;
        this.userId = data.id
        this.email = data.email
        this.adfsHost = data.adfsHost;
        this.twoFactorAuthToken = data.twoFactorAuthToken;
        this.twoFactorAuthTokenExpiresAt = data.twoFactorAuthTokenExpiresAt;
        this.twoFactorAuthType = data.twoFactorAuthType;
    }

    protected getSaveableData(): UserTokenInterface {
        let data: UserTokenInterface = {
            bearerToken: this.bearerToken,
            expires: this.expires,
            id: this.userId,
            email: this.email,
            adfsHost: this.adfsHost,
            twoFactorAuthToken: this.twoFactorAuthToken,
            twoFactorAuthTokenExpiresAt: this.twoFactorAuthTokenExpiresAt,
            twoFactorAuthType: this.twoFactorAuthType
        }

        return data;
    }

    resetPassword(email: string): Promise<void> {
        return new Promise((resolve, reject) => {
            try {
                request.post('auth/reset-password', {
                    email: email
                })

                resolve();
            }
            catch (error) {
                reject(error);
            }
        });
    }

    setPassword(token: string, password: string): Promise<void> {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await request.post('auth/set-password', {
                    token: token,
                    password: password
                })

                if (res.status === 200) {
                    resolve();
                }

                reject();
            }
            catch (error) {
                reject(error);
            }
        });
    }

    setNewPassword(currentPassword: string, newPassword: string) {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await request.post('/auth/change-password', {
                    currentPassword: currentPassword,
                    newPassword: newPassword
                }, {
                    headers: {
                        _byPassTransactionKey: 'true'
                    }
                });

                if (res.status === 200) {
                    resolve(res.data);
                }

                reject();
            }
            catch (error) {
                reject(error);
            }
        });
    }
}

export const authStore = new AuthStore();