import { observable, action, makeObservable } from 'mobx';
import { DashboardContractTypeEnum } from './DashboardStore';
import { BaseStore } from './BaseStore';
import request from 'api/request';

interface ContractUpForDeletionInterface {
    "contractType": DashboardContractTypeEnum,
    "id": string,
    "contractStatus": number,
    "companyName": string,
    "deletionDate": string,
    "deletionNotice": number,
    "deletionProlongReason": string,
    "fileNames": Array<string>
}

export class ContractsUpForDeletionStore extends BaseStore {
    @observable contracts: Array<ContractUpForDeletionInterface> = [];

    constructor() {
        super();

        makeObservable(this);
    }

    @action
    getContracts() {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await request.get('contracts/upfordeletion?pagenumber=1&pagesize=99')
                if (res.data && res.data.results) {
                    this.contracts = res.data.results;
                    resolve(res.data.results);
                }
                else reject();
            }
            catch(error) {
                reject(error);
            }
        })
    }
}

export const contractsUpForDeletionStore = new ContractsUpForDeletionStore();