import TableDetails from "components/AdminPage/TableDetails";
import TableHeader from "components/Table/TableHeader";
import { observer } from "mobx-react";
import { DepartmentInterface, departmentStore, languageStore, navigationStore, toastStore, userStore } from "stores";
import _ from 'lodash';
import { useParams } from "react-router";
import DefaultButton from "components/Forms/DefaultButton";
import { useEffect } from "react";

const Departments = observer(() => {
    const { id } = useParams<{ id: string }>();

    useEffect(() => {
        const me = userStore.signedInUser;
        departmentStore.getDepartmentsByCompany(me.company.id)
    }, [id])

    const onCreateDepartment = async () => {
        const name = window.prompt(languageStore.get('name'));
        if (!name) return;

        const department = {
            locationId: id,
            name: name
        } as DepartmentInterface;

        await departmentStore.createDepartment(department, departmentStore.departments);
        toastStore.addDefaultSuccessfulSaveToast();
    }

    const onDeleteDepartment = async (department: DepartmentInterface) => {
        if (!window.confirm(`${languageStore.get('areYouSureYouWantToDelete')}?`)) return;

        await departmentStore.deleteDepartment(department.locationId, department.id, departmentStore.departments);
        toastStore.addDefaultSuccessfulSaveToast();
    }

    const columns = [
        {
            Header: <TableHeader value={languageStore.get('name')} />,
            id: 'name',
            accessor: 'name'
        },
        {
            Header: null,
            id: 'actions',
            width: 180,
            accessor: (d: DepartmentInterface) => {
                return (
                    <>
                        <button
                            className='px-2 py-1 text-xs text-red rounded bg-red-lightest hover:bg-red-lighter'
                            onClick={(e) => onDeleteDepartment(d)}>
                            {languageStore.get('remove')}
                        </button>
                        <button
                            className='ml-6 px-2 py-1 text-xs text-grey-darkest rounded bg-grey-light hover:bg-red-lighter'
                            onClick={(e) => navigationStore.push(`/admin/departments/${d.id}`)}>
                            {languageStore.get('goTo')} &rarr;
                        </button>

                    </>
                )
            }
        }
    ]

    return (
        <TableDetails
            actions={
                <DefaultButton
                    onClick={onCreateDepartment}
                    label={languageStore.get('add')}
                />
            }
            data={_.filter(departmentStore.departments, (dep) => dep.locationId === id)}
            columns={columns}
            filterBy={['name']}
        />
    )
})

export default Departments;