import { action, computed, makeObservable } from 'mobx';
import request from 'api/request';
import { ContractTypeInterface, ContactPeopleInterface } from 'stores';
import { DepartmentInterface } from './DepartmentStore';
import { SupplierInterface } from 'stores/SupplierStore';
import { ContractStatusEnum } from 'stores';
import { CurrentContractStore, ContractDocumentInterface, ContractAlarmInterface, VerificationEntryInterface, ContractFinancialCompanyServiceInterface, ContractFinanceAccountInterface, ContractToContractReferenceInterface, CurrentContractBaseInterface } from './CurrentContractStore';
import { ContractNoteInterface } from './BaseContractsStore';
import { MainCategoryInterface } from './CategoryStore';
import { SubCategoryInterface } from './SubCategoryStore';
import { hasRole } from 'helpers/RoleHelper';
import { RoleEnum } from './RolesStore';
import { DashboardContractTypeEnum } from './DashboardStore';

export enum FileTypeEnum {
    CONTRACT_DOCUMENT,
    CORRESPONDENCE_DOCUMENT
}

export interface FullPurchaseContractInterface extends CurrentContractBaseInterface {
    "renewalDate": string,
    "expirationDate": string,
    "renewalNoticeDays": string,
    "expirationNoticeDays": string,
    "dealNumber": string,
    "warranty": boolean,
    "warrantyDescription": string,
    "importanceLevel": number,
    "supplierId": string,
    "supplier": SupplierInterface,
    "financialServices": Array<ContractFinancialCompanyServiceInterface>,
    "contractFinanceAccounts": Array<ContractFinanceAccountInterface>,
    "description": string,
    "contractSum": number,
    "indexAdjustment": number,
    "paymentTerms": string,
    "contractDateCreated": string,
    "specialCircumstances": boolean,
    "specialCircumstancesDiscription": string,
    "contractStatus": ContractStatusEnum,
    "contractTypeId": string,
    "contractType": ContractTypeInterface,
    "categoryId": string,
    "category": MainCategoryInterface,
    "subCategoryId": string,
    "subCategory": SubCategoryInterface,
    "responsibleUserId": string,
    "responsibleUser": {
        "id": string,
        "fullName": string,
        "companyId": string
    },
    "contactPeople": Array<ContactPeopleInterface>,
    "departmentId": string,
    "department": DepartmentInterface,
    "notes": Array<ContractNoteInterface>,
    "alarms": Array<ContractAlarmInterface>,
    "contractDocuments": Array<ContractDocumentInterface>,
    "contractCorrespondances": Array<ContractDocumentInterface>,
    "contractVerificationEntries": Array<VerificationEntryInterface>,
    "contractToContractReferences": Array<ContractToContractReferenceInterface>
}

export class CurrentPurchaseContractStore extends CurrentContractStore<FullPurchaseContractInterface> {

    constructor() {
        super();

        makeObservable(this);

        this.contractEndpoint = 'purchase';
        this.contractType = DashboardContractTypeEnum.PURCHASE;
    }

    @action
    addSupplierToContract(supplier: SupplierInterface) {
        return new Promise(async (resolve, reject) => {
            try {
                this.contract!.supplierId = supplier.id!;
                request.put(`contracts/purchase/${this.contract!.id}`, this.contract!);

                this.contract!.supplier = supplier;
                resolve(supplier);
            }
            catch (error) {
                reject(error);
            }
        });
    }

    @action
    addContactPersonToContract(contact: ContactPeopleInterface): Promise<void> {
        return new Promise(async (resolve, reject) => {
            try {
                await request.post(`contracts/purchase/${this.contract!.id}/contacts`, contact);
                if (this.contract) {
                    this.contract.contactPeople = new Array<ContactPeopleInterface>(contact);
                }
                resolve();
            }
            catch (error) {
                reject(error);
            }
        })
    }

    @action
    createFinanceAccount = (financeAccount: ContractFinanceAccountInterface) => {
        return this.create(`contracts/${this.contractEndpoint}/${this.contract!.id}/financeaccounts`, financeAccount, this.contract!.contractFinanceAccounts);
    }

    @action
    updateFinanceAccount = (financeAccount: ContractFinanceAccountInterface) => {
        return this.update(`contracts/${this.contractEndpoint}/${this.contract!.id}/financeaccounts/${financeAccount.id}`, financeAccount, this.contract!.contractFinanceAccounts);
    }

    @action
    deleteFinanceAccount = (id: string) => {
        return this.delete(`contracts/${this.contractEndpoint}/${this.contract!.id}/financeaccounts/${id}`, id, this.contract!.contractFinanceAccounts);
    }

    @action
    createFinancialService = (service: ContractFinancialCompanyServiceInterface) => {
        return this.create(`contracts/${this.contractEndpoint}/${this.contract!.id}/services`, service, this.contract!.financialServices);
    }

    @action
    updateFinancialService = (service: ContractFinancialCompanyServiceInterface) => {
        return this.update(`contracts/${this.contractEndpoint}/${this.contract!.id}/services/${service.id}`, service, this.contract!.financialServices);
    }

    @action
    deleteFinancialService = (id: string) => {
        return this.delete(`contracts/${this.contractEndpoint}/${this.contract!.id}/services/${id}`, id, this.contract!.financialServices);
    }

    @computed
    get isDraft() {
        return !!this.contract && this.contract.contractStatus === ContractStatusEnum.Draft;
    }

    @computed
    get canEdit() {
        return hasRole(RoleEnum.PURCHASE_WRITE) || hasRole(RoleEnum.PURCHASE_ADMIN);
    }

}

export const currentPurchaseContractStore = new CurrentPurchaseContractStore();